import { Activity } from '../reducer/processActivities';

const processActivityEvent = {
    get: 'GET_PROCESS_ACTIVITY',
    getSuccess: 'GET_PROCESS_ACTIVITY_SUCCESS',
    getFailure: 'GET_PROCESS_ACTIVITY_FAILURE',
};

const processActivityEventCreator = {
    getProcessActivities: (processId: string, successCb: (data: Activity[]) => void, errorCb: () => void) => ({
        type: processActivityEvent.get,
        payload: { processId },
        cb: successCb,
        errorCb,
    }),
};

export { processActivityEvent, processActivityEventCreator };
