import { createSelector } from 'reselect';
import { RootState } from '../../../../../reducers/rootReducer';
import createRecordWithManyValuePaths from 'casetivity-shared-js/lib/viewConfigSchema/traverseGetData/createRecordWithManyPaths';
import traverseGetData from 'casetivity-shared-js/lib/viewConfigSchema/traverseGetData';
import { createGetEntities } from './getEntities';

interface RecordSelectorProps {
    record: { id: string | number; entityType: string };
    viewName: string;
    overrideFieldsInExpressions?: string[];
}
const emptyObj = {};
const createRecordSelector = () => {
    const getEntities = createGetEntities();
    return createSelector(
        (state: RootState, props: RecordSelectorProps) => state.entityVisibility[props.viewName] || emptyObj,
        (state: RootState, props: RecordSelectorProps) => props.overrideFieldsInExpressions,
        getEntities,
        (state: RootState, props: RecordSelectorProps) => state.viewConfig,
        (state: RootState, props: RecordSelectorProps) => (props.record ? props.record.id : null),
        (state: RootState, props: RecordSelectorProps) => (props.record ? props.record.entityType : null),
        (config, overrideFieldsInExpressions, entities, viewConfig, id, entityType) => {
            if (!id || !entityType) {
                return emptyObj;
            }
            // this could be optimized.
            const allFieldsRequired: string[] =
                overrideFieldsInExpressions ||
                Object.values(config).flatMap(c =>
                    (c as any).flatMap(co => [
                        ...co.fieldsRequired,
                        ...Object.keys(co.valuesetFieldsRequired || {}).map(f => `${f}Id`),
                    ]),
                );
            const pathsAndValues = Object.assign(
                {
                    id,
                    entityType,
                },
                ...allFieldsRequired.map(fr => ({
                    [fr]: traverseGetData(viewConfig, fr, { id, entityType }, entities).fold(null, a => a),
                })),
            );
            return createRecordWithManyValuePaths(pathsAndValues);
        },
    );
};
export default createRecordSelector;
