import * as taskForm from '../actions';
import { isActionOf } from 'typesafe-actions';
import { RootState } from 'reducers/rootReducer';
import { Epic } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { Services } from 'sideEffect/services';
import { filter, map, catchError, flatMap, withLatestFrom } from 'rxjs/operators';
import { of, concat } from 'rxjs';
import getValuesetsForFormAction from './getValuesetsForFormAction';
import getRefOnesForFormActions from './getRefOnesForFormActions';

const fetchTaskFormFlow: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) =>
    action$.pipe(
        filter(isActionOf(taskForm.getTaskForm)),
        withLatestFrom(state$.pipe(map(state => state.viewConfig))),
        flatMap(([action, viewConfig]) =>
            concat(
                of(taskForm.getTaskFormLoading(action.payload.taskId)),
                services.getTaskForm(action.payload.taskId).pipe(
                    flatMap(response => {
                        const actions = [
                            getValuesetsForFormAction(response, viewConfig),
                            ...getRefOnesForFormActions(response, viewConfig),
                            taskForm.getTaskFormSuccess(action.payload.taskId, response),
                        ];
                        return concat(...actions.map(action => of(action)));
                    }),
                    catchError(err => {
                        console.error(err);
                        return of(taskForm.getTaskFormFailure(action.payload.taskId, err));
                    }),
                ),
            ),
        ),
    );
export default fetchTaskFormFlow;
