import React from 'react';
import * as fieldTypes from '../fieldTypes';
// import RefmanyMultiselectInput from './components/RefmanyMultiselect';
import RefManyMultiselect from '../display/components/RefmanyMultiselect';
import generateMemberField from './generateMemberField';
import { Field, EntityRefManyField } from '../translation/types/index';
import { getConfigProperty } from './fieldUtils/index';
import { getAriaSupportProperties } from 'fieldFactory/ariaSupport';
import EvaluateInEntityContext from 'expressions/components/EvaluateExpressionInEntityContext';

const isEntityRefManyField = (fieldDefinition: Field): fieldDefinition is EntityRefManyField =>
    fieldDefinition._dataSource === 'Entity' && fieldDefinition.type === fieldTypes.REFMANY_MULTISELECT;

export default (
    // inner field component level
    fieldDefinition: Field,
    viewConfig,
    configuration,
    liveProps,
) => {
    let propConfiguration: any = {
        // tslint:disable-line
        originalDefinition: fieldDefinition.originalDefinition,
        source: fieldDefinition.name,
        label: fieldDefinition.label,
        row: fieldDefinition.row,
        span: fieldDefinition.span,
        column: fieldDefinition.column,
    };
    let RComponent;
    // treating this as something that may be extended with more datatypes in the future
    if (
        fieldDefinition.type === fieldTypes.REFMANY_MULTISELECT ||
        fieldDefinition.type === fieldTypes.REFMANY_JOIN_MULTISELECT
    ) {
        const hasCreate = isEntityRefManyField(fieldDefinition)
            ? getConfigProperty('hasCreate')(fieldDefinition).getOrElse(true)
            : true;
        const hasEdit = isEntityRefManyField(fieldDefinition)
            ? getConfigProperty('hasEdit')(fieldDefinition).getOrElse(true)
            : true;
        const noClick = isEntityRefManyField(fieldDefinition)
            ? getConfigProperty('noClick')(fieldDefinition).getOrElse(false)
            : false;
        const openTo = isEntityRefManyField(fieldDefinition)
            ? getConfigProperty('openTo')(fieldDefinition).getOrElse('show')
            : false;
        const includeTopCreateButton = isEntityRefManyField(fieldDefinition)
            ? getConfigProperty<{ includeTopCreateButton?: boolean }>('includeTopCreateButton')(
                  fieldDefinition,
              ).getOrElse(false)
            : false;

        RComponent = props => {
            return (
                <EvaluateInEntityContext expression={hasCreate} defaultOnException={true}>
                    {({ expressionResult }) => <RefManyMultiselect {...props} hasCreate={expressionResult} />}
                </EvaluateInEntityContext>
            );
        };
        propConfiguration = {
            ...propConfiguration,
            hasPopoverSearch: false, // fieldDefinition.type === fieldTypes.REFMANY_MULTISELECT,
            hasTable: true,
            ariaInputProps: {},
        };
        const ariaSupportProps = getAriaSupportProperties(fieldDefinition.row, fieldDefinition.column, fieldDefinition);

        if (ariaSupportProps && ariaSupportProps.labelledBy) {
            propConfiguration.ariaInputProps['aria-labelledby'] = ariaSupportProps.labelledBy;
            propConfiguration.renderLabel = false;
        }

        return (
            <RComponent
                {...propConfiguration}
                openTo={openTo}
                includeTopCreateButton={includeTopCreateButton}
                config={isEntityRefManyField(fieldDefinition) ? fieldDefinition.config : undefined}
                //                 hasCreate={hasCreate}
                hasEdit={hasEdit}
                noClick={noClick}
                key={`${propConfiguration.source}-refmany (${propConfiguration.label})`}
                addField={true}
                dontConnect={true}
                {...liveProps}
            />
        );
    }
    return generateMemberField(fieldDefinition, viewConfig, configuration, liveProps);
};
