import React from 'react';
import { Card, CardHeader } from '@material-ui/core';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import SsgAppBarMobile from '../../components/SsgAppBarMobile';
import { getProcessDefinitions as getProcessDefinitionsAction } from 'bpm/processDefinitions/actions';
import { dropzoneStyle } from 'components/custom/BulkUpload';
import { Helmet } from 'react-helmet';
import { storageController } from 'storage';
const config = require('../../config.js');

export const UploadDeploymentComponent = props => {
    const doUpload = (files, replaceDefinitions, replaceForms) => {
        const data = new FormData();
        data.append('file', files[0]);
        const request = new Request(
            `${config.API_URL}bpm/deployment?replaceDefinitions=${replaceDefinitions}&replaceForms=${replaceForms}`,
            {
                method: 'POST',
                body: data,
                credentials: 'same-origin',
                headers: new Headers({
                    Authorization: `Bearer ${storageController.getToken()}`,
                    Cookie: `${window.document.cookie}`,
                }),
            },
        );
        fetch(request).then(response => {
            if (response.status < 200 || response.status >= 300) {
                // TODO: show error somewhere, notification?
                console.log(response.statusText);
            }
            response.json().then(data => {
                console.log(data);
                props.getProcessDefinitions();
                // TODO: show success somewhere, notification?
            });
        });
    };
    const onDrop = files => doUpload(files, false, false);
    const onDropReplace = files => doUpload(files, true, true);
    const onDropReplaceNewForms = files => doUpload(files, true, false);
    return (
        <div>
            <Helmet>
                <title>Upload Processes</title>
            </Helmet>
            {<SsgAppBarMobile title="Upload New Deployment" />}
            <Card>
                <CardHeader title="Upload New Deployment" />
                <div style={{ padding: '15px' }}>
                    <Dropzone multiple={false} onDrop={onDrop}>
                        {({ getRootProps, getInputProps }) => (
                            <section>
                                <div {...getRootProps()} style={dropzoneStyle}>
                                    <input {...getInputProps({ 'aria-label': 'Upload File' })} />
                                    <p>Drag Deployment File Here</p>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </div>
            </Card>
            {<SsgAppBarMobile title="Replace Deployment" />}
            <Card>
                <CardHeader title="Replace Deployment" />
                <div style={{ padding: '15px' }}>
                    <Dropzone multiple={false} onDrop={onDropReplace}>
                        {({ getRootProps, getInputProps }) => (
                            <section>
                                <div {...getRootProps()} style={dropzoneStyle}>
                                    <input {...getInputProps({ 'aria-label': 'Upload File' })} />
                                    <p>Drag Deployment File Here</p>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </div>
            </Card>
            {<SsgAppBarMobile title="Replace Deployment, New Forms" />}
            <Card>
                <CardHeader title="Replace Deployment, New Forms" />
                <div style={{ padding: '15px' }}>
                    <Dropzone multiple={false} onDrop={onDropReplaceNewForms}>
                        {({ getRootProps, getInputProps }) => (
                            <section>
                                <div {...getRootProps()} style={dropzoneStyle}>
                                    <input {...getInputProps({ 'aria-label': 'Upload File' })} />
                                    <p>Drag Deployment File Here</p>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </div>
            </Card>
        </div>
    );
};

const ConnectedUDC = connect(
    (state, ownProps) => Object.assign({}, ownProps, { viewConfig: state.viewConfig }),
    {
        getProcessDefinitions: getProcessDefinitionsAction,
    },
)(UploadDeploymentComponent);
export default ConnectedUDC;
