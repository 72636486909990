import { Field } from '../../translation/types';
import { isEntityDataField, getConfigProperty } from '../../input/fieldUtils';

export const getDefaultCode = (fieldDefinition: Field) => {
    if (isEntityDataField(fieldDefinition)) {
        return getConfigProperty<{ defaultCode?: string }>('defaultCode')(fieldDefinition).getOrElse(undefined);
    }
    // no default value specified for fromFlowable
    return undefined;
};
