import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { getValueSetCodesRequiredForEntity, getPossibleMatchName } from '../utils/viewConfigUtils';
import { loadValueSets as loadValueSetsAction } from '../../../actions/valueSetsActions';
import PossibleMatchView from '../genericPossibleMatchView/PossibleMatchList';
import { setAsTopView, unsetAsTopView } from '../../../popoverStackManagement/actions';
import { RootState } from '../../../reducers/rootReducer';

interface GenericPossibleMatchesProps {
    formId?: string;
    viewName: string;
    title?: null | string;
    match: { params: { id: string } };
    editInPopover?: boolean;
    onDataChange?: () => void;
    onIdChanged?: (id: string) => void;
}
const mapStateToProps = (state: RootState, props: GenericPossibleMatchesProps) => ({
    viewConfig: state.viewConfig,
    resource: state.viewConfig.views[props.viewName].entity,
});
const dispatchToProps = {
    loadValueSets: loadValueSetsAction,
    setAsTopView,
    unsetAsTopView,
};

class GenericPossibleMatchesComponent extends Component<
    GenericPossibleMatchesProps & ReturnType<typeof mapStateToProps> & typeof dispatchToProps
> {
    componentDidMount() {
        if (this.props.formId) {
            this.props.setAsTopView(this.props.formId);
        }
        this.props.loadValueSets(
            getValueSetCodesRequiredForEntity(this.props.viewConfig, this.props.viewName).map(valueSetCode => ({
                valueSet: valueSetCode,
            })),
        );
    }

    componentWillUnmount() {
        if (this.props.formId) {
            this.props.unsetAsTopView(this.props.formId);
        }
    }
    render() {
        const { resource, viewConfig, title } = this.props;
        const displayName = typeof title === 'undefined' ? getPossibleMatchName(viewConfig, resource) : title;
        return <PossibleMatchView {...this.props} title={displayName} />;
    }
}
const enhance = compose(
    connect(
        mapStateToProps,
        dispatchToProps,
    ),
);

const GenericPossibleMatches: React.SFC<GenericPossibleMatchesProps> = enhance(GenericPossibleMatchesComponent);
GenericPossibleMatches.displayName = 'GenericPossibleMatches';
export default GenericPossibleMatches;
