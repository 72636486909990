import { RootState } from '../../../../../reducers/rootReducer';
import { fromPredicate, fromNullable, fromEither } from 'fp-ts/lib/Option';
import { tryCatch } from 'fp-ts/lib/Either';
import { SpelCompiledExpression } from '../../../../../expressions/evaluate';
import { mapOption, flatten } from 'fp-ts/lib/Array';

import { SpelExpressionEvaluator } from 'spel2js';
import { getExpressionsFromConfig } from '../../../../../expressions/expressionArrays/';
import getValueSetCodeLiterals from '../../../../../expressions/getFieldsInAst/getValuesetCodeLiterals';
import removeMethodHashes from 'casetivity-shared-js/lib/spel/getFieldsInAst/removeMethodHashes';
import { FormFieldConfigs, FormFieldUnion } from '../../../../../fieldFactory/translation/fromFlowable/types';
import uniq from 'lodash/uniq';
import { getValidationFromConfig } from '../../../../../expressions/formValidation/evaluateFromJson';
import { getValuesetCodesFromAddressWidgetConfig } from 'components/generics/utils/viewConfigUtils';
import ViewConfig from 'reducers/ViewConfigType';

const getValuesetLiteralsFromExpression = (exp: string) => {
    const compiledExpression = (SpelExpressionEvaluator.compile(exp) as SpelCompiledExpression)._compiledExpression;
    return getValueSetCodeLiterals(exp)(compiledExpression);
};
const getValuesetLiteralsInExpressionArray = (expression: string) =>
    fromEither(
        tryCatch(
            () => {
                const expressions = getExpressionsFromConfig(expression);
                return expressions
                    .map(expressionStrings =>
                        expressionStrings.map(removeMethodHashes).flatMap(getValuesetLiteralsFromExpression),
                    )
                    .getOrElse([]);
            },
            (e: Error) => {
                // prints errors for the failed SPEL compilation
                console.error('Error parsing SPEL entityConfig validation expression', e); // tslint:disable-line
                return e;
            },
        ),
    );

const getFieldValidationVSLiterals = (fields: FormFieldUnion[]) =>
    flatten(
        mapOption(fields, f =>
            fromNullable(f.params)
                .map(p => p.configs)
                .chain(fromNullable)
                .map(c => c.validation)
                .chain(fromPredicate<string>(Boolean))
                .chain(cStr => fromEither(getValidationFromConfig(cStr)))
                .map(exprConfs => exprConfs.map(({ expression }) => removeMethodHashes(expression)))
                .map(exprs => exprs.flatMap(getValuesetLiteralsFromExpression)),
        ),
    );
const getValuesetExpressionArrayLiterals = (property: ('editable' | 'visibility') & keyof FormFieldConfigs) => (
    fields: FormFieldUnion[],
) =>
    flatten(
        mapOption(fields, f =>
            fromNullable(f.params)
                .map(p => p.configs)
                .chain(fromNullable)
                .map(c => c[property])
                .chain(fromPredicate(Boolean))
                .chain(getValuesetLiteralsInExpressionArray),
        ),
    );
const getForTableFields = (fn: (fields: FormFieldUnion[]) => string[]) => (fds: FormFieldUnion[]) =>
    fds.flatMap(f => {
        if (f.type === 'table') {
            return fn(f.params.columnObj);
        }
        return [];
    });

const mapGroupsToValuesetGroupStrRep = (valueSet: string, groupStr: string) => {
    return groupStr
        .split(',')
        .map(g => g.trim())
        .map(g => `${valueSet}.${g}`);
};

const getValuesetFields = (fields: FormFieldUnion[], viewConfig?: ViewConfig) =>
    fields.flatMap(f => {
        if (f.type === 'value-set-radiobox') {
            if (f.params.group) {
                return mapGroupsToValuesetGroupStrRep(
                    f.params.valueSet /* Remove everything after this. */ || (f.params as any).singleSelectValueSet,
                    f.params.group,
                );
            }
            return [f.params.valueSet /* Remove everything after this. */ || (f.params as any).singleSelectValueSet];
        }
        if (f.type === 'value-set-multi-checkbox' || f.type === 'value-set-multi-select') {
            if (f.params.group) {
                return mapGroupsToValuesetGroupStrRep(f.params.multiSelectValueSet, f.params.group);
            }
            return [f.params.multiSelectValueSet];
        }
        if (f.type === 'value-set-dropdown' || f.type === 'valuesset-suggest') {
            if (f.params.group) {
                return mapGroupsToValuesetGroupStrRep(f.params.valueSet, f.params.group);
            }
            return [f.params.valueSet];
        }
        if (f.type === 'address' && viewConfig) {
            const addrConfigStr = f.params.address.configs;
            if (addrConfigStr) {
                return getValuesetCodesFromAddressWidgetConfig(addrConfigStr, viewConfig);
            }
        }
        return [];
    });
type TaskForm = RootState['taskForms'][0];
const fetchTaskFormValueSets = (taskForm: TaskForm, viewConfig?: ViewConfig) => {
    const { fields, outcomes } = taskForm;
    const fieldValuesets = getValuesetFields(fields, viewConfig);

    const tableValuesets = getForTableFields(getValuesetFields)(fields);
    const tableValidationLiterals = getForTableFields(getFieldValidationVSLiterals)(fields);
    const tableVisibilityLiterals = getForTableFields(getValuesetExpressionArrayLiterals('visibility'))(fields);
    const tableEditableLiterals = getForTableFields(getValuesetExpressionArrayLiterals('editable'))(fields);

    const editabilityVSLiterals = getValuesetExpressionArrayLiterals('editable')(fields);
    const visibilityVSLiterals = getValuesetExpressionArrayLiterals('visibility')(fields);
    const outcomesVsLiterals = (property: 'editable' | 'visibility') =>
        flatten(
            mapOption(outcomes, o =>
                fromNullable(o.configs)
                    .map(c => c[property])
                    .chain(fromPredicate(Boolean))
                    .chain(getValuesetLiteralsInExpressionArray),
            ),
        );
    const outcomeVisVSLiterals = outcomesVsLiterals('editable');
    const outcomeEditVSLiterals = outcomesVsLiterals('visibility');
    /*
            TODO get validations in nested table fields
    */
    const fieldValidationLiterals = getFieldValidationVSLiterals(fields);

    return uniq([
        ...fieldValuesets,
        ...tableValuesets,
        ...editabilityVSLiterals,
        ...visibilityVSLiterals,
        ...outcomeVisVSLiterals,
        ...outcomeEditVSLiterals,
        ...fieldValidationLiterals,
        ...tableValidationLiterals,
        ...tableVisibilityLiterals,
        ...tableEditableLiterals,
    ]);
};
export default fetchTaskFormValueSets;
