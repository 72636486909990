import { EntityBase, GenericCrudArgs } from 'sideEffect/services';
import { concat, of } from 'rxjs';
import { fetchStart } from 'actions/aor/fetchActions';
import { stripCallbacksFromPayload } from '../../../stripCallbacks';
import {
    ErrorsCbs,
    CreateUpdateFailureActionCreators,
    CreateUpdateSuccessActionCreators,
    CreateUpdateInitialActions,
} from '../../../../types';
import {
    FlowOptions,
    FlowContext,
    getResponseAndThrowErrorForNon200,
    handleErrors,
    callSuccessCb,
    flatMapResponseEntityToSuccessActions,
} from '../shared';

export interface CreateUpdateFlowOptions<D> extends FlowOptions<D> {
    failureAction: CreateUpdateFailureActionCreators;
    successAction: CreateUpdateSuccessActionCreators;
    errorsCbs: ErrorsCbs;
    successCb: (id?: string, data?: EntityBase) => void;
}

export interface CreateUpdateFlowContext extends FlowContext {
    initialRequestPayload: CreateUpdateInitialActions['payload'];
    restUrl: string;
}

const crudCreateUpdateFlow = <D>(
    requestArgs: GenericCrudArgs<D>,
    params: CreateUpdateFlowOptions<D>,
    context: CreateUpdateFlowContext,
) => {
    return concat(
        of(fetchStart()),
        params.service(requestArgs).pipe(
            getResponseAndThrowErrorForNon200,
            callSuccessCb(params.successCb),
            flatMapResponseEntityToSuccessActions(params.successAction, context),
            handleErrors(params.errorsCbs, err =>
                params.failureAction(err, stripCallbacksFromPayload(context.initialRequestPayload)),
            ),
        ),
    );
};
export default crudCreateUpdateFlow;
