import * as colors from '@material-ui/core/colors';
import grey from '@material-ui/core/colors/grey';
import { createMuiTheme } from '@material-ui/core/styles';
import updateRwtStylesheet from './rwtStyleInjector';

type color = keyof typeof colors;
const getV1InitialTheme = (primary: color, secondary: color, error: color, rebuildRWTStylesheet: boolean = false) => {
    const primaryColor = colors[primary];
    const secondaryColor = colors[secondary];
    const errorColor = colors[error];
    if (rebuildRWTStylesheet) {
        updateRwtStylesheet(primaryColor[900]);
    }

    const v1Palette = {
        primary: {
            light: primaryColor[800],
            main: primaryColor[900],
            dark: primaryColor[1000],
        },
        secondary: {
            light: secondaryColor[300],
            main: secondaryColor[500],
            dark: secondaryColor[700],
        },
        error: {
            light: errorColor[400],
            main: errorColor[700],
            dark: errorColor[800],
        },
    };
    const themeV1Init = {
        palette: v1Palette,
        overrides: {
            /* Input style class overrides below */
            MuiButton: {
                outlined: {
                    '&$disabled': {
                        color: secondaryColor[600],
                    },
                },
                label: {
                    textTransform: 'capitalize' as 'capitalize',
                },
            },
            MuiCard: {
                root: {
                    overflow: 'unset', // prevents dropdowns from getting cut off
                },
            },
            MuiFormLabel: {
                /*
                    Explains why "focused: { color: 'red' }" doesn't work as an override here.
                    https://github.com/mui-org/material-ui/issues/11244#issuecomment-386792253
                */
                root: {
                    color: grey[900], // dark label by default
                    '&$disabled': {
                        color: grey[900],
                    },
                    '&$focused': {
                        color: v1Palette.primary.main, // primary color when focused.
                    },
                    '&$error': {
                        color: grey[900], // dark label by default
                    },
                },
            },
            MuiMenuItem: {
                root: {
                    '&:focus': {
                        backgroundColor: '#DEDEDE',
                    },
                },
            },
            MuiInput: {
                root: {
                    backgroundColor: grey[100],
                    '&$disabled': {
                        backgroundColor: 'transparent',
                    },
                },
                underline: {
                    borderBottomColor: 'red',
                    '&:hover:not($disabled):after': {
                        borderBottomColor: v1Palette.primary.main, // selected AND hovered
                    },
                    /* '&:hover:not($disabled):before': {
                        borderBottomColor: 'green',
                    }, */
                    /* '&:before': {
                        borderBottomColor: grey[900], // resting underline color (not focused or hovered)
                    }, */
                    '&:hover:not($disabled):not($error):not($focused):before': {
                        borderBottomColor: v1Palette.primary.main, // hovered but not focused (and no error)
                    },
                },
            },
            MuiInputBase: {
                root: {
                    '&$disabled': {
                        color: 'rgba(0, 0, 0, 0.65)',
                    },
                },
            },
            /*
            MuiTableRow: {
                root: {
                    '&$hover': {
                        backgroundColor: '#E0E0E0',
                    },
                },
            },
            */
            MuiFormHelperText: {
                error: {
                    whiteSpace: 'pre-wrap' as 'pre-wrap',
                    // fontFamily: 'monospace'
                },
            },
            MuiFormControl: {
                root: {
                    zIndex: 'unset' as const,
                },
            },
        },
    };
    const V1Theme = createMuiTheme(themeV1Init); // tslint:disable-line
    if (!V1Theme.overrides) {
        V1Theme.overrides = {};
    }
    V1Theme.overrides.MuiInputLabel = {
        shrink: {
            [V1Theme.breakpoints.up('sm')]: {
                top: '-.6em',
            },
            [V1Theme.breakpoints.down('sm')]: {
                top: '-.7em',
            },
            // position: 'relative',
        },
    };
    return V1Theme;
};

export default getV1InitialTheme;
