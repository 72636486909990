/* eslint jsx-a11y/anchor-is-valid: 0 */
/* eslint no-script-url: 0 */
import React, { useContext, useEffect, useState, useCallback } from 'react';
import { processContext } from 'bpm/components/processContext';
import casetivityViewContext from 'util/casetivityViewContext';
import { formContext } from 'bpm/components/TaskDetail/TaskForm/FormContext';
import { useSelector, useDispatch } from 'react-redux';
import { saveAs } from 'file-saver';
import { RootState } from 'reducers/rootReducer';
import { getPrintTemplateByName } from 'printTemplate/getPrintTemplatesByName/actions';
import { CircularProgress } from '@material-ui/core';
import { refreshJwt, getHeaders, getUrl } from 'sideEffect/services';
import { ajax, AjaxError } from 'rxjs/ajax';
import Warning from '@material-ui/icons/Warning';
import { getDisplayText, getFileName } from 'printTemplate/component/PrintTemplateField';

interface ServerTemplatedPrintTemplateField {
    resource: string;
    printTemplateName: string;
    record?: {
        id: string;
        title?: string;
        entityType: string;
        config?: string;
    };
}
type FetchState =
    | {
          type: 'NOT_FETCHING';
      }
    | {
          type: 'FETCHING_1';
      }
    | {
          type: 'ERROR';
      }
    | {
          type: 'SUCCESS';
      };
const ServerTemplatedPrintTemplateField = (props: ServerTemplatedPrintTemplateField) => {
    const [state, setState] = useState<FetchState>({ type: 'NOT_FETCHING' });
    const viewContext = useContext(casetivityViewContext);
    const { taskId: taskInstanceId } = useContext(processContext);
    const { fieldValues: formData } = useContext(formContext);
    const dispatch = useDispatch();
    const printTemplateRecord = useSelector((state: RootState) => state.printTemplates.byName[props.printTemplateName]);
    const printTemplateId = printTemplateRecord && printTemplateRecord.id;
    const entityId = props.record && props.record.id;
    const fileName = getFileName(printTemplateRecord, props.record);
    useEffect(() => {
        if (taskInstanceId || viewContext === 'START_FORM') {
            dispatch(getPrintTemplateByName(props.printTemplateName));
        }
    }, [taskInstanceId, viewContext, dispatch, props.printTemplateName]);

    useEffect(() => {
        if (state.type === 'FETCHING_1') {
            const $ajax = refreshJwt(
                ajax({
                    url: getUrl('api/generate-print-template'),
                    method: 'POST',
                    headers: {
                        ...getHeaders(),
                        'Content-Type': 'application/json',
                    },
                    responseType: 'blob',
                    withCredentials: true,
                    body: {
                        printTemplateId,
                        entityId,
                        taskInstanceId,
                        type: 'pdf',
                        formData,
                    },
                }),
            );
            const subscription = $ajax.subscribe(
                res => {
                    if (res.status >= 200 && res.status < 300) {
                        setState({ type: 'SUCCESS' });
                        saveAs(res.response, `${fileName}.pdf`);
                    } else {
                        setState({ type: 'ERROR' });
                    }
                },
                (error: AjaxError) => {
                    console.error(error);
                    setState({ type: 'ERROR' });
                },
            );
            return () => {
                if (!subscription.closed) {
                    subscription.unsubscribe();
                }
            };
        }
    }, [printTemplateId, taskInstanceId, entityId, formData, state.type, fileName]);

    const fetchAndDownload = useCallback(() => {
        setState({ type: 'FETCHING_1' });
    }, [setState]);
    return (
        <div style={{ paddingBottom: '.75em', paddingTop: '.5em', paddingLeft: '.5em', paddingRight: '.5em' }}>
            {state.type === 'FETCHING_1' ? (
                <CircularProgress style={{ height: 15, width: 15 }} />
            ) : state.type === 'ERROR' ? (
                <Warning color="error" />
            ) : null}
            <a href="javascript:;" onClick={fetchAndDownload}>
                {getDisplayText(printTemplateRecord)}
            </a>{' '}
        </div>
    );
};

export default ServerTemplatedPrintTemplateField;
