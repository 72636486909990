/*
    The logic here is as follows:
    currentAddressId FIXES-> currentAddress.<any>
    currentAddress.<any> DOESNOTFIX-> currentAddress.Id

    so
    matched part of .*Id matches start of [field to check]
*/
const isSet = (fixedFilter: string, field: string) =>
    fixedFilter.endsWith('Id') ? field.startsWith(fixedFilter.slice(0, -'Id'.length)) : field.startsWith(fixedFilter);

export const permanentFilterFixesFieldValue = (filter: {}) => (field: string) => {
    const filterKeys = Object.keys(filter);
    /*
    all permutations:
    1. exact
    currentAddressId: currentAddressId
    currentAddress.name : currentAddress.name
    2. nested
    currentAddressId : currentAddress.name
    */
    // console.log('filter', filter, field,
    // filterKeys.map(filterKey =>
    // `  ${filterKey}: ${isSet(filterKey, field)}  `).reduce((p, c) => `${p} ${c}`, '')); // tslint:disable-line
    return filterKeys.some(filterKey => isSet(filterKey, field));
};
export default permanentFilterFixesFieldValue;
