import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import AssignedOpenTaskList from 'bpm/components/TaskDrawer/AssignedOpenedTasksList';
import GenericList from 'components/generics/genericList';
import getListRenderer from 'components/generics/genericList/renderList';
import { push as pushAction } from 'connected-react-router';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core';
import { RootState } from 'reducers/rootReducer';

const styles = (theme: Theme) =>
    createStyles({
        headerCell: {
            position: 'sticky',
            zIndex: 3,
            backgroundColor: theme.palette.background.paper,
            top: 0,
        },
        listResults: {
            position: 'relative',
            overflowY: 'auto',
            maxHeight: 270,
            '@media print': {
                overflowY: 'unset',
                maxHeight: 'unset',
            },
        },
    });
interface MyActiveTasksWidgetProps {
    useTableView: boolean;
}

const mapStateToProps = (state: RootState, props: MyActiveTasksWidgetProps) => {
    return {
        currentUserLogin: state.viewConfig && state.viewConfig.user && state.viewConfig.user.login,
    };
};

const dispatches = {
    redirect: pushAction,
};
type Dispatches = typeof dispatches;

interface MyActiveTasksWidgetComponentProps
    extends Dispatches,
        MyActiveTasksWidgetProps,
        ReturnType<typeof mapStateToProps>,
        WithStyles<typeof styles> {}
interface MyActiveTasksWidgetState {
    location: {
        pathname: string;
        search: string;
    };
}
class MyActiveTasksWidgetComponent extends Component<MyActiveTasksWidgetComponentProps, MyActiveTasksWidgetState> {
    state: MyActiveTasksWidgetState = {
        location: {
            pathname: '/task-instances',
            search: '',
        },
    };
    static renderer = getListRenderer({}, {});
    render() {
        return this.props.useTableView ? (
            <div style={{ height: '100%' }}>
                <GenericList
                    useCard={false}
                    actions={null}
                    onRowSelect={([task]: any) => {
                        this.props.redirect(
                            task.processInstanceId
                                ? `/processes/${task.processInstanceId}/tasks/${task.id}/start`
                                : `/tasks/${task.id}`,
                        );
                    }}
                    filter={{ 'assignee.login': `${this.props.currentUserLogin}`, endTime__NOT_EMPTY: false }}
                    hasCreate={false}
                    multiSelectable={true}
                    updateUrlFromFilter={false}
                    viewName={'_TASK_LIST_DASHBOARD'}
                    formId={'_TASK_LIST'}
                    showFilters={false}
                    resource={'TaskInstance'}
                    showImmediately={true}
                    perPage={'25'}
                    renderList={r =>
                        getListRenderer(this.props.classes, {})({
                            ...r,
                            ariaProps: { ...r.ariaProps, 'aria-label': 'My Tasks' },
                        })
                    }
                    location={this.state.location}
                    fakePush={location => {
                        this.setState(state => ({ ...state, location }));
                    }}
                />
            </div>
        ) : (
            <AssignedOpenTaskList open={false} />
        );
    }
}

const enhance = compose(
    connect(
        mapStateToProps,
        dispatches,
    ),
    withStyles(styles),
);

const MyActiveTasksWidget: React.ComponentType<MyActiveTasksWidgetProps> = enhance(MyActiveTasksWidgetComponent);
export default MyActiveTasksWidget;
