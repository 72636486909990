import { checkLastRequestTime } from '../actions';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';

const ONE_MINUTE = 60 * 1000;
const CHECK_INTERVAL_DEFAULT = 1.5 * ONE_MINUTE;
const CheckLastRequestRunner = () => {
    const dispatch = useDispatch();
    const isOnline = useSelector((state: RootState) => state.connectivityStatus);
    useEffect(() => {
        if (isOnline) {
            const checkLastRequestPollInterval = setInterval(() => {
                dispatch(checkLastRequestTime());
            }, (window as any).CASETIVITY_CHECKLASTREQUESTTIME_INTERVAL || CHECK_INTERVAL_DEFAULT);
            return () => {
                clearInterval(checkLastRequestPollInterval);
            };
        }
    }, [isOnline]); // eslint-disable-line
    return null;
};

export default CheckLastRequestRunner;
