import React, { Component } from 'react';
import DebouncedTextInput from '../DebouncedTextInput';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import { Theme, createStyles, withStyles, WithStyles, FormHelperText } from '@material-ui/core';
import HtmlDisplay from 'fieldFactory/display/components/HtmlDisplay';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import uniqueId from 'lodash/uniqueId';

const styles = (theme: Theme) => createStyles({});

interface EditorCProps extends WithStyles<typeof styles> {
    source?: string;
    input: WrappedFieldInputProps;
    meta: WrappedFieldMetaProps;
    disabled?: boolean;
    label?: string;
}
class EditorC extends Component<EditorCProps> {
    private errorMessageId = uniqueId('html-wysiwyg-errormsg');
    private element: HTMLDivElement;
    constructor(props) {
        super(props);
        this.element = null;
    }
    handleChange = this.props.input.onChange;
    handleBlur = this.props.input.onBlur;
    getValue = () => this.props.input.value;
    componentDidUpdate(prevProps, prevState, snapshot) {
        const error = Boolean(this.props.meta && this.props.meta.touched && this.props.meta.error);
        const prevError = Boolean(prevProps.meta && prevProps.meta.touched && prevProps.meta.error);
        if (error !== prevError) {
            this.setAttrsByError(error);
        }
    }
    setAttrsByError = (error: boolean) => {
        const InputProps: React.InputHTMLAttributes<HTMLDivElement> = {
            'aria-invalid': error,
            'aria-describedby': error ? this.errorMessageId : undefined,
        };
        Object.entries(InputProps).forEach(([k, v]) => {
            if (v) {
                const typ = document.createAttribute(k);
                typ.value = v;
                this.element.attributes.setNamedItem(typ);
            } else if (this.element.attributes.getNamedItem(k)) {
                this.element.attributes.removeNamedItem(k);
            }
        });
    };
    render() {
        const {
            meta: { touched, error },
            disabled,
            label,
        } = this.props;

        return (
            <div>
                {disabled ? (
                    <HtmlDisplay
                        html={this.props.input.value}
                        id={'disabled-displayed-html-for-' + this.props.source}
                    />
                ) : (
                    <DebouncedTextInput<string>
                        debounceTime={400}
                        emptyInitialValue={this.props.input.value || ''}
                        input={{
                            onChange: this.handleChange,
                            onBlur: this.handleBlur,
                            value: this.getValue(),
                        }}
                        renderInput={({ value, onChange, onBlur }) => (
                            <CKEditor
                                editor={ClassicEditor}
                                disabled={disabled}
                                data={value}
                                onInit={editor => {
                                    this.element = editor.ui.view.element;
                                    this.setAttrsByError(touched && error);
                                    this.element.firstElementChild.textContent = label;
                                }}
                                onChange={(evt, editor) => {
                                    onChange(editor.getData());
                                }}
                                onBlur={(evt, editor) => {
                                    onBlur(editor.getData());
                                }}
                            />
                        )}
                    />
                )}
                {touched && error && (
                    <FormHelperText id={this.errorMessageId} error={true}>
                        Error: {error}
                    </FormHelperText>
                )}
            </div>
        );
    }
}

export default withStyles(styles)(EditorC);
