import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles, Theme, Collapse, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        error: {
            textAlign: 'center',
            backgroundColor: theme.palette.error.dark,
            color: 'white',
        },
        connected: {
            textAlign: 'center',
            backgroundColor: '#43A047',
            color: 'white',
        },
    }),
);

const Banner: React.SFC<{ isOnline: boolean }> = props => {
    const { isOnline } = props;
    const classes = useStyles(props);
    // normalizedSuccess: either already online, or turned online and banner can be removed
    // after wait
    const [normalizedSuccess, setNormalizedSuccess] = useState(isOnline);
    useEffect(() => {
        if (isOnline) {
            const t = setTimeout(() => {
                setNormalizedSuccess(true);
            }, 3000);
            return () => {
                clearTimeout(t);
            };
        }
        if (!isOnline) {
            setNormalizedSuccess(false);
        }
    }, [isOnline, setNormalizedSuccess]);
    return (
        <div role="alert">
            <Collapse in={!isOnline}>
                <div className={classes.error}>
                    <Typography variant="h6">No Connection</Typography>
                </div>
            </Collapse>
            <Collapse in={isOnline && !normalizedSuccess}>
                <div className={classes.connected}>
                    <Typography variant="h6">Back Online</Typography>
                </div>
            </Collapse>
        </div>
    );
};
export default Banner;
