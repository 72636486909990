import React from 'react';
import NumberField from './components/aor/NumberField';
import EmailField from './components/aor/EmailField';
import BooleanField from './components/aor/BooleanField';
import TextField from './components/aor/TextField';
import get from 'lodash/get';
import grey from '@material-ui/core/colors/grey';
import ReactOverflowTooltip from 'react-overflow-tooltip';
import ReferenceField from './components/aor/ReferenceField';
import ListSelect from '../input/components/ListSelect';
import {
    getValueSetForFieldExpr,
    getRefEntityName,
    applyNoWrap,
    fieldIsSortable,
    getCustomViewName,
} from '../../components/generics/utils/viewConfigUtils/index';
import ValueSetField from './components/ValueSetField';
import ValueSetListField from './components/ValueSetListField';
import * as fieldTypes from '../fieldTypes';
import DateField from './components/DateField';
import { TextField as MITF } from '@material-ui/core';
import DateTimeField from './components/DateTimeField';
import RefmanyMultiselect from './components/RefmanyMultiselect';
import RefManyMultiSelectIdsList from '../input/components/RefmanyMultiselectIdsList';
import expressionElement from './expressionElement';
import {
    isDisabledEntityField,
    /* getHtmlId, */ getLabelledBy,
    getConfigProperty,
    isFieldFromFlowable,
} from '../input/fieldUtils';
import FieldSubscriber from './experimental/FieldSubscriber';
import MultiCheckbox from '../input/components/MultiCheckbox';
import ValuesetOneCheckbox from '../input/components/ValuesetOneCheckbox';
import getDisplayAddress from '../input/components/Address/getDisplayAddress';
import FormHelperTextNoErr from '../input/components/TextField/FormHelperTextNoErr';
import { fromNullable, tryCatch } from 'fp-ts/lib/Either';
import Checkbox from '../display/components/DisplayCheckbox';
import { rowIdContext } from '../../components/generics/genericList/renderList';
import Currency from './components/CurrencyDisplay';
import Percentage from './components/PercentDisplay';
import { getAriaSupportProperties } from '../ariaSupport';
import { getShowViewName, getEditViewName, getViewName } from 'fieldFactory/input/fieldUtils/reference';
import DmsDoc from 'fieldFactory/input/components/DmsDoc';
import { DataSource } from 'fieldFactory/translation/types';

const Present = fieldDefinition => (
    { addUnderlineAndMinHeight, hoverableEllipses } = { addUnderlineAndMinHeight: true, hoverableEllipses: true },
) => BaseComponent => props => {
    const u = undefined;
    const toReturn = (
        <div>
            <rowIdContext.Consumer>
                {id => (
                    <div
                        /*
                        REMOVED_TEST_ID
                        id={
                        id ? `${getHtmlId(fieldDefinition)}:${id}` : getHtmlId(fieldDefinition) */
                        {...props.ariaInputProps}
                        style={{
                            overflow: hoverableEllipses ? 'hidden' : u,
                            textOverflow: hoverableEllipses ? 'ellipsis' : u,
                            borderBottom: addUnderlineAndMinHeight
                                ? `1px ${props.disabled ? 'dotted' : 'solid'} ${grey[400]}`
                                : u,
                            minHeight: addUnderlineAndMinHeight ? '1.3em' : u,
                        }}
                    >
                        <BaseComponent {...props} />
                    </div>
                )}
            </rowIdContext.Consumer>
            {fieldDefinition.description && <FormHelperTextNoErr>{fieldDefinition.description}</FormHelperTextNoErr>}
        </div>
    );
    if (hoverableEllipses) {
        return <ReactOverflowTooltip title={get(props.record, props.source)}>{toReturn}</ReactOverflowTooltip>;
    }
    return toReturn;
};

const generateField = (fieldDefinition, viewConfig, c, liveProps) => {
    const t = fieldTypes;
    const ariaSupportProps = getAriaSupportProperties(fieldDefinition.row, fieldDefinition.column, fieldDefinition);
    const ariaInputProps = {};
    let renderLabel = true;
    if (ariaSupportProps && ariaSupportProps.labelledBy) {
        ariaInputProps['aria-labelledby'] = ariaSupportProps.labelledBy;
        renderLabel = false;
    }
    let RComponent;
    let propConfiguration = {
        originalDefinition: fieldDefinition.originalDefinition,
        addLabel: renderLabel,
        row: fieldDefinition.row,
        column: fieldDefinition.column,
        span: fieldDefinition.span,
        source: fieldDefinition.name,
        label: fieldDefinition.label,
        ariaInputProps,
        sortable: tryCatch(() => {
            const { configuredEntity } = fieldDefinition;
            return configuredEntity && fieldIsSortable(viewConfig, configuredEntity, fieldDefinition.name, 'POP_LAST');
        }).getOrElse(undefined),
    };
    if (fieldDefinition.htmlConfig) {
        propConfiguration = {
            ...propConfiguration,
            alignSelf: true,
            addLabel: false,
        };
        return expressionElement(fieldDefinition, { ...propConfiguration, liveProps });
    }
    if (fieldDefinition.type === fieldTypes.ADDRESS_VERIFICATION) {
        return getDisplayAddress(propConfiguration, fieldDefinition, liveProps);
    }

    const wrap = Present(fieldDefinition)(c);
    switch (fieldDefinition.type) {
        case t.HTML_EXPRESSION:
            /*
            console.error('HTML expression field without an htmlConfig given: ', fieldDefinition);
            return <br />;
            */
            propConfiguration = {
                ...propConfiguration,
                alignSelf: true,
                addLabel: false,
            };
            RComponent = props =>
                expressionElement(fieldDefinition, {
                    ...propConfiguration,
                    ...props,
                    ...liveProps,
                    id: `${fieldDefinition._dataSource === DataSource.FLOWABLE ? 'flowable' : ''}expression:r${
                        fieldDefinition.row
                    }c${fieldDefinition.column}`,
                });
            break;
        case t.TEXT:
            RComponent = wrap(TextField);
            break;
        case t.MULTILINE_TEXT: {
            const noWrap = applyNoWrap(fieldDefinition.config);
            const lineWrap = noWrap ? 'pre' : 'pre-wrap';
            const overflowOption = noWrap ? 'auto' : 'visible';
            const display = noWrap ? 'block' : 'unset';
            const overflowWrap = noWrap ? 'normal' : 'break-word';
            propConfiguration = {
                ...propConfiguration,
                elStyle: { display, overflow: overflowOption, whiteSpace: lineWrap, overflowWrap },
            };

            RComponent = wrap(TextField);
            break;
        }
        case t.PERCENT: {
            RComponent = wrap(Percentage);
            break;
        }
        case t.CURRENCY:
            propConfiguration = {
                ...propConfiguration,
                disabled: true,
            };
            if (propConfiguration.addLabel) {
                propConfiguration.ariaInputProps['aria-label'] = propConfiguration.label;
            }
            RComponent = Currency;
            break;
        case t.INTEGER:
        case t.FLOAT:
            RComponent = wrap(NumberField);
            break;
        case t.TOGGLE:
            RComponent = wrap(BooleanField);
            propConfiguration = {
                ...propConfiguration,
                elStyle: { margin: 'unset' },
            };
            break;
        case t.CHECKBOX:
            propConfiguration = {
                ...propConfiguration,
                elStyle: { margin: 'unset' },
                addLabel: false,
                addUnderlineAndMinHeight: false,
                hideLabel: c.hideCheckboxLabel,
            };
            RComponent = Present(fieldDefinition)({ addUnderlineAndMinHeight: false, getOwnData: true })(Checkbox);
            break;
        case t.DATE:
            RComponent = wrap(DateField);
            break;
        case t.DATETIME:
            RComponent = wrap(DateTimeField);
            break;
        case t.ZONE_DATE:
            RComponent = props => <div>ZONE_DATE is unsupported.</div>;
            break;
        case t.FILE_UPLOAD:
            RComponent = DmsDoc;
            propConfiguration.type = 'Display(Entity)';
            break;
        case t.EMAIL:
            RComponent = wrap(EmailField);
            break;
        case t.VALUESET_SELECT:
            RComponent = wrap(ValueSetField);
            propConfiguration = {
                fetchOwnData: false,
                ...propConfiguration,
                valueSetCode: getValueSetForFieldExpr(
                    viewConfig,
                    fieldDefinition.configuredEntity,
                    fieldDefinition.name,
                ),
                source: `${fieldDefinition.name}Id`,
            };
            break;
        case t.VALUESET_CHECKBOX: {
            RComponent = ValuesetOneCheckbox;
            const confEither = fromNullable({})(fieldDefinition.config).chain(conf => tryCatch(() => JSON.parse(conf)));
            propConfiguration = {
                fetchOwnData: false,
                ...propConfiguration,
                labelledBy: getLabelledBy(fieldDefinition),
                addLabel: false, // label is part of the display here.,
                renderLabel: liveProps.isForShow ? renderLabel : false,
                sortable: false,
                valueSetCode: getValueSetForFieldExpr(
                    viewConfig,
                    fieldDefinition.configuredEntity,
                    fieldDefinition.name,
                ),
                source: `${fieldDefinition.name}Id`,
                disabled: true,
                direction: confEither.map(conf => conf.direction).getOrElse(undefined),
            };
            break;
        }
        case t.VALUESET_MULTISELECT:
            RComponent = wrap(ValueSetListField);
            propConfiguration = {
                fetchOwnData: false,
                ...propConfiguration,
                sortable: false,
                valueSetCode: getValueSetForFieldExpr(
                    viewConfig,
                    fieldDefinition.configuredEntity,
                    fieldDefinition.name,
                ),
                source: `${fieldDefinition.name}Ids`,
            };
            break;
        case t.REFONE_JOIN_SELECT:
        case t.ENTITY_TYPEAHEAD:
        case t.REFONE_SELECT: {
            const refEntityName = getRefEntityName(
                viewConfig,
                fieldDefinition.configuredEntity,
                fieldDefinition.name,
                'POP_LAST',
            );
            // const refEntityLabel = getRefEntityLabel(viewConfig, fieldDefinition.configuredEntity, fieldDefinition.name);
            propConfiguration = {
                fetchOwnData: false,
                ...propConfiguration,
                reference: refEntityName,
                source: `${fieldDefinition.name}Id`,
                allowEmpty: true,
            };

            propConfiguration.showViewName = getShowViewName(fieldDefinition);
            propConfiguration.editViewName = getEditViewName(fieldDefinition);

            if (c.disabledInputField) {
                // Since it's disabled (meaning, on an edit/create page),
                // we need to provide our own 'underline' to the component
                // (on show views underlines are added to all inputs by default.)
                RComponent = wrap(props => (
                    <ReferenceField
                        {...props}
                        linkType="popover"
                        renderField={({ record, source }) => (
                            <MITF
                                margin="none"
                                InputLabelProps={{ disabled: true }}
                                value={record && record.title}
                                disabled
                                fullWidth
                            />
                        )}
                    />
                ));
            } else {
                // don't provide underline- use Aor TextField for now.
                RComponent = wrap(props => (
                    <ReferenceField
                        {...props}
                        linkType={liveProps.isForSearch ? 'editIfPermittedOtherwiseShow' : 'popover'}
                        renderField={({ record, source }) => (
                            <React.Fragment>
                                <TextField record={record} source="title" />
                                <span className="casetivity-off-screen">Opens a modal dialog</span>
                            </React.Fragment>
                        )}
                    />
                ));
            }

            break;
        }
        case t.DMS_DOCUMENT: {
            RComponent = DmsDoc;
            propConfiguration.type = 'Display(Entity)';
            break;
        }
        case t.LIST: {
            RComponent = ListSelect;
            propConfiguration = {
                ...propConfiguration,
                ...fieldDefinition.params,
                disabled: true,
            };
            break;
        }
        case t.REFMANY_JOIN_MULTISELECT:
        case t.REFMANY_MULTISELECT: {
            RComponent = RefmanyMultiselect;

            const hasCreate = getConfigProperty('hasCreate')(fieldDefinition).getOrElse(false);
            const openTo = getConfigProperty('openTo')(fieldDefinition).getOrElse('show');

            propConfiguration = {
                ...propConfiguration,
                // match: configuration.match,
                hasCreate,
                openTo,
                config: fieldDefinition.config,
                addLabel: false,
                hasTable: true,
                renderLabel,
                label: renderLabel ? fieldDefinition.label : undefined,
            };
            break;
        }
        case t.REFMANY_MULTISELECT_IDSLIST:
        case t.REFMANYMANY_CHIP: {
            RComponent = RefManyMultiSelectIdsList;
            propConfiguration = {
                ...propConfiguration,
                type: 'Display(FromEntity)',
                renderer: fieldDefinition.type === t.REFMANYMANY_CHIP ? 'CHIP' : 'LIST',
                source: `${propConfiguration.source}Ids`,
                reference:
                    fieldDefinition.configuredEntity &&
                    getRefEntityName(viewConfig, fieldDefinition.configuredEntity, fieldDefinition.name, 'POP_LAST'),
                sortable: false,
                allowEmpty: true,
                addLabel: false,
            };
            const getViewNameOf = type =>
                getViewName(fieldDefinition) ||
                getCustomViewName(type, false)(
                    isFieldFromFlowable(fieldDefinition)
                        ? propConfiguration.reference
                        : getRefEntityName(
                              viewConfig,
                              fieldDefinition.configuredEntity,
                              fieldDefinition.name,
                              'TRAVERSE_PATH',
                          ),
                    viewConfig,
                    fieldDefinition.config,
                );
            propConfiguration.viewName = getViewNameOf('LIST');
            propConfiguration.editViewName = getViewNameOf('EDIT');
            propConfiguration.showViewName = getViewNameOf('SHOW');
            break;
        }
        case t.VALUESET_MULTICHECKBOX: {
            RComponent = MultiCheckbox;
            propConfiguration = {
                ...propConfiguration,
                source: `${propConfiguration.source}Ids`,
                addLabel: false,
                disabled: true,
                label: renderLabel ? fieldDefinition.label : undefined,
            };
            break;
        }
        default:
            console.log(`uncaught field: ${JSON.stringify(fieldDefinition)}`);
            return null;
    }
    if (
        c.getOwnData &&
        fieldDefinition.type !== t.REFMANY_JOIN_MULTISELECT &&
        fieldDefinition.type !== t.REFMANY_MULTISELECT &&
        fieldDefinition.type !== t.EXPRESSION
    ) {
        const InnerComponent = RComponent;
        RComponent = class WrappedInSubscription extends React.Component {
            shouldComponentUpdate(nextProps) {
                if (this.props.record !== nextProps.record || this.props.source !== nextProps.source) {
                    return true;
                }
                return false;
            }
            render() {
                const props = this.props;
                return (
                    <FieldSubscriber
                        subscribeToEntireRecord={
                            fieldDefinition.type === t.FILE_UPLOAD || fieldDefinition.type === t.ADDRESS_VERIFICATION
                        }
                        defaultValue={c.defaultValue}
                        {...props}
                        id={(props.record || {}).id}
                        renderDisplayField={({ record }) => <InnerComponent {...props} record={record} />}
                    />
                );
            }
        };
    }
    if (!propConfiguration.ariaInputProps['aria-labelledby'] && typeof propConfiguration.label === 'string') {
        propConfiguration.ariaInputProps['aria-label'] = propConfiguration.label;
    }
    return (
        <RComponent
            disabled={isDisabledEntityField(fieldDefinition, liveProps, viewConfig) || undefined}
            {...propConfiguration}
            fieldInstanceIdentifier={fieldDefinition.fieldInstanceIdentifier || fieldDefinition.source}
            key={`${propConfiguration.source}-${propConfiguration.label}`}
            {...liveProps}
        />
    );
};

export default generateField;
