import * as widgetTypes from '../../../../components/generics/utils/widgetTypes';
import { AddressWidgetConfig, IAddressWidgetConfig } from './types';
import { ThrowReporter } from 'io-ts/lib/ThrowReporter';
import { configValidationsByWidgetType, addFieldItemConfigValidation } from '../../../../validationsRegistry';

const example1: IAddressWidgetConfig = {
    fieldMapping: {
        casetivityHouse: 'strNum',
        casetivityStreet: 'myFieldNameInFormRepresentingSteetName',
        casetivityUnit: 'Apartment number',
        casetivityStateCode: 'state',
        casetivityStateId: 'stateId',
        casetivityCityCode: 'city',
        casetivityCityId: 'cityId',
        casetivityZip: 'zip',
    },
    validationMessage: 'Please Verify before saving',
    verificationRequired: 'ALWAYS',
    manualVerificationAllowed: 'ALWAYS',
    useAddressEntityValidations: true,
    showVerificationStatus: true,
};

const example2: IAddressWidgetConfig = {
    fieldMapping: {
        casetivityLine1: 'line-1',
        casetivityLine2: 'line2',
        casetivityStateCode: 'state',
        casetivityCityCode: 'city',
        casetivityZip: 'zip',
    },
    validationMessage: 'Verify address before saving',
    verificationRequired: 'WHEN_NOT_EMPTY',
    manualVerificationAllowed: 'ENDPOINT_REACHED',
    useAddressEntityValidations: false,
};
const exampleText = `See Below.
Detailed example:

${JSON.stringify(example1, null, 2)}

Line1 and line2 example:

${JSON.stringify(example2, null, 2)}`;

const validation: (typeof configValidationsByWidgetType)[keyof typeof configValidationsByWidgetType] = config => {
    if (!config) {
        return null;
    }
    try {
        const addrConfig = JSON.parse(config);
        const decodeResult = AddressWidgetConfig.decode(addrConfig);
        try {
            ThrowReporter.report(decodeResult);
            return null;
        } catch (e) {
            // return e; // tslint:disable-line
            return `Invalid Address Configuration. ${exampleText}`;
        }
    } catch (e) {
        return `invalid json ${exampleText}`;
    }
};

addFieldItemConfigValidation(widgetTypes.ADDRESS, validation);
