import { useDispatch } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { crudGetOne as crudGetOneAction } from 'sideEffect/crud/getOne/actions';
import { crudUpdate as crudUpdateAction } from 'sideEffect/crud/update/actions';
import { adjustForSubmit } from 'components/generics/genericEdit/EditForm';
import { submitAction } from '../submitAction';
import useViewConfig from 'util/hooks/useViewConfig';
import { useContext, useCallback } from 'react';
import { formContext } from 'components/generics/form/EntityFormContext';
import { processPageRefreshContext } from 'bpm/components/ProcessDetail/ProcessPage';
import useWorkingState from './useWorkingState';

const useCascadingSave = () => {
    const { clearWorkingState, setWorkingState, workingState } = useWorkingState();
    const dispatch = useDispatch();
    const viewConfig = useViewConfig();
    const fc = useContext(formContext);
    const entityInitialValues = fc.initialValues as {
        id: string;
        entityVersion: number;
        entityType: string;
    };
    const { refresh: refreshProcessPage } = useContext(processPageRefreshContext);
    const dirtyEntityFormValues = fc.dirtyValues;
    // TODO:
    // lets use the 'StartForm' save strategy where we use capture props on a state update, so 'save' callback can no depend on anything other than setState
    const cascadingSave = useCallback(
        (
            submissionType: 'save' | 'submit',
            _outcome: string | undefined,
            _outcomeDisplay: string | undefined,
            taskId: string,
            formDefinition: RootState['taskForms'][0],
            taskFormValues: {
                _outcome?: string;
                submissionType?: 'submit' | 'save';
            },
            fieldVisibility: {
                [field: string]: boolean;
            },
            entitySaveErrorCbs: {
                // code can be a number or '*'.
                '*': () => void;
                [code: number]: () => void;
            },
            taskFormErrorCb: () => void,
            onSuccess: (response?: {
                warning?: string;
                nextTaskId?: string;
                processComplete: boolean;
                error?: string;
            }) => void,
        ) => {
            const { entityType, id } = entityInitialValues;
            const getDispatchFormAction = (postEntitySave: boolean, refreshPageAfter: boolean = false) => () => {
                setWorkingState({
                    stage: postEntitySave ? 'WORKING_TASKFORM' : 'WORKING_TASKFORM_NO_ENTITY',
                    submissionType,
                });
                const taskValues = {
                    values: {
                        ...taskFormValues,
                        submissionType,
                        _outcome,
                        _outcomeDisplay,
                    },
                    fieldVisibility,
                };
                const handleError = () => {
                    setWorkingState({
                        stage: postEntitySave ? 'TASKFORM_FAILED' : 'TASKFORM_FAILED_NO_ENTITY',
                        submissionType,
                    });
                    setImmediate(() => {
                        taskFormErrorCb();
                        if (refreshPageAfter) {
                            refreshProcessPage();
                        }
                    });
                };
                dispatch(
                    submitAction(
                        taskValues,
                        taskId,
                        formDefinition,
                        response => {
                            const completeSuccess = _response => {
                                clearWorkingState();
                                onSuccess(_response);
                                if (refreshPageAfter) {
                                    refreshProcessPage();
                                }
                            };
                            if (response && response.warning) {
                                setWorkingState({
                                    stage: 'TASK_SAVE_WARNING',
                                    submissionType,
                                    taskFormResubmit: {
                                        warningMessage: response.warning,
                                        onCancel: taskFormErrorCb,
                                        resubmit: () => {
                                            setWorkingState({
                                                stage: postEntitySave
                                                    ? 'WORKING_TASKFORM'
                                                    : 'WORKING_TASKFORM_NO_ENTITY',
                                                submissionType,
                                            });
                                            setImmediate(() => {
                                                dispatch(
                                                    submitAction(
                                                        { ...taskValues, overrideWarning: true },
                                                        taskId,
                                                        formDefinition,
                                                        completeSuccess,
                                                        handleError,
                                                    ),
                                                );
                                            });
                                        },
                                    },
                                });
                            } else {
                                completeSuccess(response);
                            }
                        },
                        handleError,
                    ),
                );
            };
            if (Object.keys(dirtyEntityFormValues).length > 0) {
                setWorkingState({ stage: 'WORKING_ENTITY', submissionType });
                const adjustedSaveValues = adjustForSubmit(
                    viewConfig,
                    formDefinition.viewName ||
                        viewConfig.entities[entityInitialValues.entityType].defaultViews.EDIT.name,
                    dirtyEntityFormValues,
                    entityInitialValues,
                );
                dispatch(
                    crudUpdateAction({
                        resource: entityType,
                        data: { id, ...adjustedSaveValues, partialUpdate: true },
                        previousData: entityInitialValues,
                        cb: responseId => {
                            if (responseId !== id) {
                                getDispatchFormAction(true, true)();
                            } else {
                                getDispatchFormAction(true)();
                            }
                        },
                        errorsCbs: {
                            409: () => {
                                dispatch(
                                    crudGetOneAction({
                                        resource: entityType,
                                        id,
                                        view: `${entityType}Edit`,
                                        cb: (responseId, responseData) => {
                                            if (`${id}` !== `${responseId}`) {
                                                refreshProcessPage();
                                                // Dedupe happened.
                                                // Need to referesh the AppCase/Task/Process, to figure out the new linkedEntity.
                                            }
                                        },
                                    }),
                                );
                            },
                            ...entitySaveErrorCbs,
                            '*': () => {
                                setWorkingState({ stage: 'ENTITY_FAILED', submissionType });
                                const providedCb = entitySaveErrorCbs['*'];
                                if (providedCb) {
                                    setImmediate(() => {
                                        providedCb();
                                    });
                                }
                            },
                        },
                    }),
                );
            } else {
                getDispatchFormAction(false)();
            }
        },
        [
            clearWorkingState,
            dirtyEntityFormValues,
            dispatch,
            viewConfig,
            entityInitialValues,
            refreshProcessPage,
            setWorkingState,
        ],
    );
    return [workingState, clearWorkingState, cascadingSave] as const;
};
export default useCascadingSave;
