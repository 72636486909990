import { API_URL } from 'config';
import { storageController } from 'storage';

// has to be lazy so we get the token from storage later
const createRefreshTokenRequest = () =>
    new Request(`${API_URL}authenticate/refresh`, {
        method: 'GET',
        credentials: 'same-origin',
        headers: new Headers({
            Authorization: `Bearer ${storageController.getToken()}`,
            Cookie: `${window.document.cookie}`,
            Accept: 'application/json',
            'Content-type': 'application/json',
        }),
    });
export default createRefreshTokenRequest;
