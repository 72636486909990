import * as React from 'react';
import Add from '@material-ui/icons/Add';
import { Button, Dialog, DialogContent } from '@material-ui/core';
import CreateTaskForm from './CreateTaskForm';

type propsType = {
    processId: string | null;
};

type stateType = {
    open: boolean;
};

class PopoverCreateTask extends React.Component<propsType, stateType> {
    constructor(props: propsType) {
        super(props);
        this.state = {
            open: false,
        };
    }
    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { processId } = this.props;
        if (!processId) {
            return null;
        }
        return (
            <span>
                <Button variant="text" color="primary" onClick={this.handleOpen}>
                    Create Task
                    <Add />
                </Button>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    TransitionProps={
                        {
                            // https://github.com/dequelabs/axe-core/issues/146
                            role: 'presentation',
                        } as any
                    }
                >
                    <DialogContent>
                        <CreateTaskForm processId={processId} handleClose={this.handleClose} />
                    </DialogContent>
                </Dialog>
            </span>
        );
    }
}

export default PopoverCreateTask;
