import React from 'react';
import { connect } from 'react-redux';

import Refresh from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';

import { load } from 'viewConfig/actions';
import { RootState } from 'reducers/rootReducer';
import { CircularProgress } from '@material-ui/core';
import { createSelector } from 'reselect';

const RefreshViewConfigButton = ({ mobile, label, refresh, isLoading, isSuper }) => {
    if (!isSuper) {
        return null;
    }
    if (mobile) {
        return (
            <MenuItem disabled={isLoading} onClick={refresh}>
                {label}
                <Refresh />
            </MenuItem>
        );
    } else {
        return (
            <IconButton disabled={isLoading} aria-label="Refresh Viewconfig" color="inherit" onClick={refresh}>
                {isLoading ? <CircularProgress style={{ color: 'white' }} /> : <Refresh />}
            </IconButton>
        );
    }
};
const makeMapStateToProps = () => {
    const isSuper = createSelector(
        (state: RootState) => state.viewConfig && state.viewConfig.user && state.viewConfig.user.roles,
        roles => {
            return roles && roles.indexOf('ROLE_SUPER') !== -1;
        },
    );
    return (state: RootState) => {
        return {
            isSuper: isSuper(state),
            isLoading: state.viewConfigIsLoading,
        };
    };
};

const enhance = connect(
    makeMapStateToProps,
    {
        refresh: load,
    },
);
RefreshViewConfigButton.defaultProps = {
    mobile: false,
    label: '',
};

export default enhance(RefreshViewConfigButton);
