const processInstanceEventType = {
    getProcessInstances: 'GET_PROCESS_INSTANCES',
    getProcessInstancesSuccess: 'GET_PROCESS_INSTANCES_SUCCESS',
    getProcessInstancesFailure: 'GET_PROCESS_INSTANCES_FAILURE',
};

const processInstanceEventCreator = {
    getProcessInstances: (filter = {}) => ({
        type: processInstanceEventType.getProcessInstances,
        payload: filter,
    }),
};

export { processInstanceEventType, processInstanceEventCreator };
