import React from 'react';
import PasswordResetPage from './Page';
import { connect } from 'react-redux';
import { PasswordResetTextField, PasswordResetStatus, renderActions } from './shared';
import { Formik } from 'formik';
import * as config from 'config';
import { CardContent, Typography } from '@material-ui/core';
import { RootState } from 'reducers/rootReducer';
import { storageController } from 'storage';

interface FormData {
    login: string;
    password1: string;
    password2: string;
}
const mapStateToProps = (state: RootState) => {
    return {
        isAdmin:
            state.viewConfig &&
            (state.viewConfig.user.roles.indexOf('ROLE_ADMIN') !== -1 ||
                state.viewConfig.user.roles.indexOf('ROLE_SUPER') !== -1),
    };
};
const SelfReset = (props: ReturnType<typeof mapStateToProps>) => {
    if (!props.isAdmin) {
        return <div>You do not have access to this page.</div>;
    }
    return (
        <PasswordResetPage
            showTitle={false}
            backdrop="plain"
            renderForm={({ attemptAction }) => state => {
                return (
                    <Formik
                        initialValues={{ login: '', password1: '', password2: '' }}
                        validate={(values: FormData) => {
                            let errors: Partial<FormData> = {};
                            if (!values.login) {
                                errors.login = 'Required';
                            }
                            if (!values.password1) {
                                errors.password1 = 'Required';
                            }
                            if (!values.password2) {
                                errors.password2 = 'Required';
                            }
                            if (values.password1 && values.password2 && values.password1 !== values.password2) {
                                errors.password2 = 'Password must match';
                            }
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            attemptAction({
                                lazyRequest: () =>
                                    fetch(`${config.BACKEND_BASE_URL}api/user-password`, {
                                        method: 'POST',
                                        body: JSON.stringify({
                                            login: values.login,
                                            password: values.password1,
                                        }),
                                        credentials: 'same-origin',
                                        headers: new Headers({
                                            Accept: 'application/json',
                                            'Content-Type': 'application/json',
                                            Authorization: `Bearer ${storageController.getToken()}`,
                                            Cookie: `${window.document.cookie}`,
                                        }),
                                    }),
                            });
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            /* and other goodies */
                        }) => (
                            <form autoComplete="off" onSubmit={handleSubmit}>
                                <CardContent>
                                    <Typography variant="h5" component="h2">
                                        Reset Password
                                    </Typography>
                                    <br />
                                    <PasswordResetTextField
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        value={values.login}
                                        name="login"
                                        label="User Login"
                                        error={errors.login}
                                        touched={touched.login}
                                    />
                                    <PasswordResetTextField
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        value={values.password1}
                                        name="password1"
                                        label="New Password"
                                        error={errors.password1}
                                        touched={touched.password1}
                                        type="password"
                                    />
                                    <PasswordResetTextField
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        value={values.password2}
                                        name="password2"
                                        label="Please re-enter password"
                                        error={errors.password2}
                                        touched={touched.password2}
                                        type="password"
                                    />
                                    <PasswordResetStatus state={state} />
                                </CardContent>
                                {renderActions(state)}
                            </form>
                        )}
                    </Formik>
                );
            }}
        />
    );
};

export default connect(mapStateToProps)(SelfReset);
