import { Action } from 'redux';

export interface TaskFormSubmitPayload {
    taskId: string;
    requestBody: {};
}
export interface TaskFormSubmitAction extends Action {
    payload: TaskFormSubmitPayload;
    cb?: Function;
    errorCb?: () => void;
}

/**
 * [0]: taskId,
 * [1]: form representation
 * treating this as a 2-tuple, but maybe an object would be better due to added names
 */
export type TaskFormSavePayload = [string, { formId: string; values: {} }];
export interface TaskFormSaveAction extends Action {
    payload: TaskFormSavePayload;
}

export type TaskFormGetPayload = string | number;
export interface TaskFormGetAction extends Action {
    payload: TaskFormGetPayload;
}

const taskFormEventType = {
    get: 'GET_TASK_FORM',
    getSuccess: 'GET_TASK_FORM_SUCCESS',
    getFailure: 'GET_TASK_FORM_FAILURE',

    save: 'SAVE_TASK_FORM',
    saveSuccess: 'SAVE_TASK_FORM_SUCCESS',
    saveFailure: 'SAVE_TASK_FORM_FAILURE',

    submit: 'SUBMIT_TASK_FORM',
    submitSuccess: 'SUBMIT_TASK_FORM_SUCCESS',
    submitFailure: 'SUBMIT_TASK_FORM_FAILURE',
} as const;

const taskFormEventCreator = {
    saveTaskForm: (
        taskId: string,
        formId: string,
        formData: { values: {}; entityFieldValues?: {} },
        cb: () => void = () => null,
        errorCb: () => void = () => null,
    ) => {
        const payload = { formId, ...formData };
        return {
            type: taskFormEventType.save,
            payload: [taskId, payload],
            cb,
            errorCb,
        };
    },
    submitTaskForm: (
        taskId: string,
        formId: string,
        formData: { values: {}; entityFieldValues?: {} },
        outcome = 'Complete',
        outcomeDisplay = 'Complete',
        cb: (response: { nextTaskId?: string; processComplete: boolean; error: string }) => void = () => null,
        errorCb: () => void = () => null,
    ): TaskFormSubmitAction => {
        const requestBody = { formId, ...formData, outcome, outcomeDisplay };
        return {
            type: taskFormEventType.submit,
            payload: {
                taskId,
                requestBody,
            },
            cb,
            errorCb,
        };
    },
};

export { taskFormEventType, taskFormEventCreator };
