import React, { useEffect } from 'react';
import GlobalAlertsBannerComponent from 'global-alerts/components/BannerComponent';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { getPrivateGlobalAlerts } from '../actions';

const PrivateGlobalAlerts = props => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getPrivateGlobalAlerts());
    }, []); // eslint-disable-line
    const privateAlerts = useSelector((state: RootState) => {
        return state.globalAlerts.privateAlerts;
    });
    return privateAlerts.fold(
        null,
        null,
        err => null,
        data => (data ? <GlobalAlertsBannerComponent globalAlerts={data} /> : null),
    );
};
export default PrivateGlobalAlerts;
