import { DashboardCreateEventType } from '../actions/dashboardCreateActions';

const initialState = {};

export default function dashboardCreateReducer(state = initialState, action) {
    switch (action.type) {
        case DashboardCreateEventType.CreateDefinition:
            return {
                ...state,
                widgets: {
                    ...state.widgets,
                    [action.id]: {
                        type: action.widgetType,
                        title: action.title,
                        definition: action.definition,
                    },
                },
            };
        case DashboardCreateEventType.UpdateLayout:
            return {
                ...state,
                layout: action.layout,
            };
        case DashboardCreateEventType.DeleteDefinition:
            return {
                ...state,
                widgets: Object.keys(state.widgets).reduce((result, key) => {
                    if (key !== action.id) {
                        result[key] = state.widgets[key];
                    }
                    return result;
                }, {}),
            };
        case DashboardCreateEventType.SetEdited:
            return {
                ...state,
                currentEdited: action.id,
            };
        case DashboardCreateEventType.clearAll:
            return {};
        default:
            return state;
    }
}
