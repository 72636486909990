import { GlobalAlert } from './definitions';
import { RemoteData, pending, success, failure, initial } from '@devexperts/remote-data-ts';
import { AjaxError } from 'rxjs/ajax';
import { RootAction } from 'actions/rootAction';
import { getType } from 'typesafe-actions';
import { getPublicGlobalAlertsSuccess, getPublicGlobalAlerts, getPublicGlobalAlertsFailure } from './public/actions';
import produce from 'immer';
import { combineReducers } from 'redux';
import {
    getPrivateGlobalAlerts,
    getPrivateGlobalAlertsSuccess,
    getPrivateGlobalAlertsFailure,
} from './private/actions';

interface GlobalAlertsById {
    [id: string]: GlobalAlert;
}

type PublicAlerts = RemoteData<AjaxError, string[]>;
type PrivateAlerts = RemoteData<AjaxError, string[]>;

const globalAlertsById = (state: GlobalAlertsById = {}, action: RootAction): GlobalAlertsById => {
    switch (action.type) {
        case getType(getPrivateGlobalAlertsSuccess):
        case getType(getPublicGlobalAlertsSuccess): {
            return produce(state, draftState => {
                const { globalAlerts = [] } = action.payload;
                (globalAlerts || []).forEach(globalAlert => {
                    draftState[globalAlert.id] = globalAlert;
                });
                return draftState;
            });
        }
        default:
            return state;
    }
};

const publicAlerts = (state: PublicAlerts = initial, action: RootAction): PublicAlerts => {
    switch (action.type) {
        case getType(getPublicGlobalAlerts): {
            if (!state.isSuccess()) {
                return pending;
            }
            return state;
        }
        case getType(getPublicGlobalAlertsSuccess): {
            const { globalAlerts = [] } = action.payload;
            return success((globalAlerts || []).map(globalAlert => globalAlert.id));
        }
        case getType(getPublicGlobalAlertsFailure): {
            if (!state.isSuccess()) {
                return failure(action.error);
            }
            return state;
        }
        default:
            return state;
    }
};

const privateAlerts = (state: PrivateAlerts = initial, action: RootAction): PrivateAlerts => {
    switch (action.type) {
        case getType(getPrivateGlobalAlerts): {
            if (!state.isSuccess()) {
                return pending;
            }
            return state;
        }
        case getType(getPrivateGlobalAlertsSuccess): {
            const { globalAlerts = [] } = action.payload;
            return success((globalAlerts || []).map(globalAlert => globalAlert.id));
        }
        case getType(getPrivateGlobalAlertsFailure): {
            if (!state.isSuccess()) {
                return failure(action.error);
            }
            return state;
        }
        default:
            return state;
    }
};

const globalAlerts = combineReducers({
    globalAlertsById,
    publicAlerts,
    privateAlerts,
});
export default globalAlerts;
