import ViewConfig from '../../reducers/ViewConfigType';
import { fromEither, Option } from 'fp-ts/lib/Option';
import { tryCatch } from 'fp-ts/lib/Either';
import { mapOption } from 'fp-ts/lib/Array';
import { SpelCompiledExpression /* SpelNode */ } from '../../expressions/evaluate';
import { parsingOrValidationErrMsg } from '../../expressions/formValidation';
import getFieldsInAst from 'casetivity-shared-js/lib/spel/getFieldsInAst';
import { SpelExpressionEvaluator } from 'spel2js';
import { parseConfig } from '../../expressions/entityViewConfig/parse';
import { getExpressionsFromConfig } from '../../expressions/expressionArrays/';
import setupValuesetFieldsRequired from '../../viewConfigCalculations/util/setupValuesetFieldsRequired';
import getValueSetCodeLiterals from 'expressions/getFieldsInAst/getValuesetCodeLiterals';
import removeMethodHashes from 'casetivity-shared-js/lib/spel/getFieldsInAst/removeMethodHashes';
import { EntityViewConfig } from 'expressions/entityViewConfig/type';

// validationExp: [{ fieldName: exp, ... }, ...] JSON stringified
const makeGetExp = (validationType: keyof EntityViewConfig & ('visibleField' | 'editableField')) => (
    key: string,
    resource: string,
    viewConfig: ViewConfig,
    viewConfiguration: Option<string>,
) =>
    viewConfiguration
        .map(parseConfig)
        .map(e => e.map(c => c[validationType]))
        // prints errors for the failed configs
        .map(e =>
            e.mapLeft(error => {
                console.error(parsingOrValidationErrMsg(error)); // tslint:disable-line
                return error;
            }),
        )
        .chain(fromEither)
        .map(v => v || [])
        .map((v: { [f: string]: string }[]): { [fieldName: string]: string[] } =>
            // v is an array of objects (fieldname: expression)
            v
                .map((o): { [f: string]: string[] } =>
                    Object.assign({}, ...Object.entries(o).map(([k, e]) => ({ [k]: [e] }))),
                )
                .reduce(
                    (prev, curr) =>
                        Object.assign(
                            {},
                            ...Object.entries(curr).map(([k, exps]) => ({
                                ...prev,
                                [k]: prev[k] ? [...prev[k], ...exps] : exps,
                            })),
                        ),
                    {},
                ),
        )
        .map(v => {
            // Either empty object or array object
            return mapOption(
                Object.entries(v).flatMap(([f, exps]) => exps.map(e => [f, e])),
                ([fieldName, expression]) =>
                    fromEither(
                        tryCatch(
                            () => {
                                const expressions = getExpressionsFromConfig(expression);
                                const [fields, valuesetLiterals] = expressions
                                    .map(expressionStrings =>
                                        expressionStrings.map(removeMethodHashes).flatMap(exp => {
                                            const compiledExpression = (SpelExpressionEvaluator.compile(
                                                exp,
                                            ) as SpelCompiledExpression)._compiledExpression;
                                            return [
                                                getFieldsInAst(exp)(compiledExpression),
                                                getValueSetCodeLiterals(exp)(compiledExpression),
                                            ] as [string[], string[]];
                                        }),
                                    )
                                    .fold(l => [], r => r) as [string[], string[]];
                                return {
                                    expression,
                                    fieldName,
                                    fieldsRequired: fields,
                                    valuesetLiterals,
                                };
                            },
                            (e: Error) => {
                                // prints errors for the failed SPEL compilation
                                console.error('Error parsing SPEL entityConfig validation expression', e); // tslint:disable-line
                                return e;
                            },
                        ).chain(setupValuesetFieldsRequired(viewConfig, resource, expression)),
                    ),
            );
        })
        .map((v): { [fieldName: string]: typeof v } =>
            v.reduce(
                (prev, curr) => ({
                    ...prev,
                    [curr.fieldName]: prev[curr.fieldName] ? [...prev[curr.fieldName], curr] : [curr],
                }),
                {},
            ),
        )
        .map(v => ({ [key]: v }));

export default makeGetExp;
