import { CRUD_CREATE, CRUD_CREATE_FAILURE, CRUD_CREATE_SUCCESS } from './constants';
import { createCustomAction } from 'typesafe-actions';
import { ErrorsCbs } from '../types';
import { EntityBase } from 'sideEffect/services';
import { Subtract } from 'utility-types';
import { NormalizedResponse } from '../util/normalizeEntityResponse';

interface CreateParams {
    resource: string;
    data: {};
    restUrl?: string; // for overriding the default from viewConfig.
    // redirectTo?: 'edit' | 'show' | 'list' | null;
    cb?: <T extends EntityBase>(id?: string, data?: T) => void;
    errorsCbs?: ErrorsCbs;
}
export const crudCreate = createCustomAction(CRUD_CREATE, type => {
    const defaultArgs: Partial<CreateParams> = {
        // redirectTo: 'edit',
        errorsCbs: {},
    };
    return (args: CreateParams) => {
        const mergedArgs: CreateParams = {
            ...defaultArgs,
            ...args,
        };
        return {
            type,
            payload: mergedArgs,
        };
    };
});
type cleanedPayload = Subtract<CreateParams, Pick<CreateParams, 'cb' | 'errorsCbs'>>;
export const crudCreateFailure = createCustomAction(CRUD_CREATE_FAILURE, type => {
    return (error: Error, requestPayload: cleanedPayload) => {
        return {
            type,
            payload: error,
            requestPayload,
        };
    };
});

export const crudCreateSuccess = createCustomAction(CRUD_CREATE_SUCCESS, type => {
    return <T>(data: NormalizedResponse<T, string> | null, requestPayload: cleanedPayload) => {
        return {
            type,
            payload: {
                data,
            },
            requestPayload,
        };
    };
});
