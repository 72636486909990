import { GenericListWithDefaultProps } from '../../components/generics/genericList';
import { parse } from 'query-string';
import React, { Context } from 'react';
import { StateDropdown, CaseTypeDropdown, AssignedDropdown } from './components/dropdowns';
import { Filter } from './types';
import { getPartialFilterFromState, getStateFromFilter } from './util';
import compose from 'recompose/compose';
import { push as pushAction } from 'connected-react-router';
import { merge } from '../replaceInLocation';
import { connect } from 'react-redux';
import memoizeOne from 'memoize-one';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import { Subtract } from 'utility-types';
import RaPagination from '../../components/generics/genericList/RaPagination';
import { RootState } from '../../reducers/rootReducer';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';
import ContentAdd from '@material-ui/icons/Add';
import { IconButton, Button, CardActions } from '@material-ui/core';
// import { withStyles } from '@material-ui/core/styles';
import DeveloperMode from '@material-ui/icons/DeveloperMode';
import ProcessListFilter from './Filter';
import InnerProcessList from './components/ListWithFields';
import { ById as ProcessDefinitionsById } from 'bpm/processDefinitions/reducer';
import removeNullAndUndefinedKeys from '../../util/removeNullAndUndefinedKeys';
import removeEmptyStrings from '../../util/removeEmptyStrings';
import UserIsSuper from 'components/UserIsSuper';
import { getPotentialUsers } from 'bpm/potentialUsers/actions';
import { PotentialUser } from 'bpm/potentialUsers/reducer';
import { getForceDatagridNotReady, stripDelaySearchFromQueryString } from 'bpm2/util';
import { Helmet } from 'react-helmet';
import DeferredSpinner from 'components/DeferredSpinner';
import { SUBMIT_SEARCH_TEXT } from 'components/generics/genericList/List';
import _merge from 'lodash/merge';
import searchIsDifferentOnlyBySortOrPage from './util/searchIsDifferentOnlyBySort';
import { startProcess } from 'bpm/create-process-instance/actions';
import { createGetPotentialUsersByIdSelector } from 'bpm2/TaskList';

type ConnectedProps = Pick<
    GenericListWithDefaultProps,
    'loadValueSets' | 'viewConfig' | 'setAsTopView' | 'unsetAsTopView' | 'fields' | 'width'
>;

type FixedProps = Pick<GenericListWithDefaultProps, 'viewName' | 'hasCreate' | 'resource'>;

type GenericListProps = Subtract<GenericListWithDefaultProps, ConnectedProps & FixedProps> & {
    processDefinitionKey?: string;
    disableCaseTypeChange?: boolean;
    interceptLocationChange?: (location: string) => void;
};

const getFilterFromParams = memoizeOne((key, assignee, state) => ({
    'processInstance.businessKey': key,
    ...(assignee ? { 'processInstance.tasks.assignee.id': assignee } : {}),
    ...getPartialFilterFromState(state),
}));

interface Users {
    [login: string]: PotentialUser;
}
interface TopRowInfo {
    processDefinitionKey?: string;
    parsedFilter?: Filter;
    users?: Users;
    processDefinitions?: ProcessDefinitionsById;
    showHiddenFeatures: boolean;
    bufferedLocationSearch?: string;
}
const ToprowContext: Context<TopRowInfo> = React.createContext<TopRowInfo>({ showHiddenFeatures: false });

class OnlyUpdateForChange<T> extends React.Component<{ value: T }> {
    shouldComponentUpdate(nextProps: { value: T }) {
        return this.props.value !== nextProps.value;
    }
    render() {
        return this.props.children;
    }
}

const getViewName = pdKey => `_APPCASE_PROC_LIST${pdKey ? `:${pdKey}` : ''}`;
interface ProcessListComponentProps extends GenericListProps {
    processDefinitions: RootState['bpm']['processDefinitions']['byId'];
    push: (loc: string) => void;
    pushSearch: (loc: string) => void;
    currentUserId: string;
    users: Users;
    showHiddenFeatures: boolean;
    setShowHiddenFeatures: (show: boolean) => void;
    createProcessInstance: (piid: string) => void;
}
interface ProcessListComponentState {
    bufferedLocationSearch: string;
    externalLocationSearch: string;
    filters: {};
    forceRefreshKey: number;
}
const processListControlledFilters: (keyof Filter)[] = [
    'processInstance.businessKey',
    'assignee_ANY',
    'processInstance.endTime__NOT_EMPTY',
    'processInstance.tasks.assignee.id',
    'processInstance.tasks.assignee.id__NOT_EMPTY',
];
const removeProcessFilters = (f: {}) => {
    return Object.assign(
        {},
        ...Object.entries(f).map(([k, v]) => {
            if ((processListControlledFilters as string[]).indexOf(k) === -1) {
                return { [k]: v };
            }
            return {};
        }),
    );
};

const getParsedFilter = (state: ProcessListComponentState): Filter | undefined => {
    const filter: string | undefined =
        state.bufferedLocationSearch && (parse(state.bufferedLocationSearch) || {}).filter;
    return filter ? JSON.parse(filter) : undefined;
};
const getOnlyRelevantFilter = (state: ProcessListComponentState) => {
    const parsedFilter = getParsedFilter(state);
    return (
        parsedFilter &&
        Object.assign(
            {},
            ...Object.entries(parsedFilter).map(([k, v]: [keyof Filter, unknown]) =>
                processListControlledFilters.indexOf(k) !== -1 ? { [k]: v } : {},
            ),
        )
    );
};
class ProcessListComponent extends React.Component<ProcessListComponentProps, ProcessListComponentState> {
    state: ProcessListComponentState = {
        bufferedLocationSearch: this.props.location.search,
        externalLocationSearch: this.props.location.search,
        filters: {},
        forceRefreshKey: 0,
    };
    static getDerivedStateFromProps(
        props: ProcessListComponentProps,
        state: ProcessListComponentState,
    ): ProcessListComponentState | null {
        if (props.location.search !== state.externalLocationSearch) {
            const current = props.location.search ? parse(props.location.search) : {};
            const currentFilter = current.filter ? JSON.parse(current.filter) : {};
            const f1 = {
                ...state,
                filters: currentFilter,
                bufferedLocationSearch: props.location.search,
                externalLocationSearch: props.location.search,
            };
            if (searchIsDifferentOnlyBySortOrPage(props.location.search, state.externalLocationSearch)) {
                // lets keep whatever our previous temporary filters were so we don't lose them
                f1.filters = _merge(f1.filters, state.filters);
            }
            // const newFilter = getOnlyRelevantFilter(f1);
            return f1;
            /* return {
                ...f1,
                filters: newFilter
            };
            */
        }
        return null;
    }
    setFilters = (filters: {}) => {
        this.setState({ filters });
    };
    submitFilters = (values = this.state.filters) => {
        this.fakePush(
            merge(stripDelaySearchFromQueryString(this.state.bufferedLocationSearch), values, undefined, 'STRIP_PAGE'),
            values,
        );
    };
    clearFilters = () => {
        this.setState({ filters: {} });
    };
    bufferMergeFilterIntoLocation = (filter: Filter) => {
        this.setState(prevState => ({
            ...prevState,
            bufferedLocationSearch: merge(prevState.bufferedLocationSearch, filter, undefined, 'STRIP_PAGE'),
        }));
    };
    bufferChangeProcessDefinitionKey = (pdKey: string) => {
        this.setState(prevState => ({
            ...prevState,
            bufferedLocationSearch: merge(
                prevState.bufferedLocationSearch,
                {
                    'processInstance.businessKey': pdKey,
                },
                // when this argument is given, all other keys in the original filter are removed.
                // We do this to unset custom filters from our url when changing filter configs
                processListControlledFilters,
                true,
            ),
        }));
    };
    fakePush = (_loc: any, filters?: {}) => {
        // tslint:disable-line
        const onlyRelevantFilter = getOnlyRelevantFilter(this.state);
        if (onlyRelevantFilter) {
            const filtersToKeep = filters
                ? Object.keys(
                      removeNullAndUndefinedKeys(
                          removeEmptyStrings({
                              ...removeProcessFilters(filters || {}),
                              ...onlyRelevantFilter,
                          }),
                      ),
                  )
                : [];
            const newLoc = merge(
                typeof _loc === 'string'
                    ? stripDelaySearchFromQueryString(_loc)
                    : stripDelaySearchFromQueryString(_loc.search),
                onlyRelevantFilter,
                filtersToKeep,
                false,
            );
            this.pushSearchOrRefresh(newLoc);
        } else {
            this.pushSearchOrRefresh(_loc as any); // tslint:disable-line
        }
    };
    refresh = () => {
        this.setState(state => ({ ...state, forceRefreshKey: state.forceRefreshKey + 1 }));
    };
    pushSearchOrRefresh = (loc: string | { search: string }) => {
        const stripLeadingQm = (str: string) => (str && str.startsWith('?') ? str.slice(1) : str);
        const { pushSearch } = this.props;
        const { externalLocationSearch } = this.state;
        const queryString =
            typeof loc === 'string'
                ? stripDelaySearchFromQueryString(loc)
                : stripDelaySearchFromQueryString(loc.search);
        if (stripLeadingQm(queryString) !== stripLeadingQm(externalLocationSearch)) {
            pushSearch(queryString);
        } else {
            this.refresh();
        }
    };
    getInnerLocation = (location: ProcessListComponentProps['location'] = this.props.location) => {
        const { currentUserId } = this.props;
        const parsedFilter: Filter | undefined = getParsedFilter(this.state);
        const assignee = parsedFilter ? parsedFilter['processInstance.tasks.assignee.id'] : undefined;
        const unassigned = parsedFilter ? parsedFilter.assignee_ANY : undefined;
        return assignee
            ? location
            : unassigned
            ? {
                  ...location,
                  search: stripDelaySearchFromQueryString(merge(location.search, { assignee_ANY: undefined })),
              }
            : {
                  ...location,
                  search: stripDelaySearchFromQueryString(
                      merge(location.search, { 'processInstance.tasks.assignee.id': currentUserId }),
                  ),
              };
    };
    getSubmitSearchToSeeResults() {
        return (
            <div
                style={{
                    textAlign: 'center',
                    padding: '2em',
                    color: 'black',
                }}
            >
                {SUBMIT_SEARCH_TEXT}
            </div>
        );
    }
    getForceDatagridNotReady = () => {
        return getForceDatagridNotReady(this.props.location.search);
    };
    render() {
        const {
            showHiddenFeatures,
            currentUserId,
            createProcessInstance,
            processDefinitions,
            setShowHiddenFeatures,
            renderList,
            referencedFromEntity,
            users,
        } = this.props;

        const parsedFilter: Filter | undefined = getParsedFilter(this.state);
        const processDefinitionKey = parsedFilter ? parsedFilter['processInstance.businessKey'] : undefined;
        const assignee = parsedFilter ? parsedFilter['processInstance.tasks.assignee.id'] : undefined;
        const unassigned = parsedFilter ? parsedFilter.assignee_ANY : undefined;
        const state = getStateFromFilter(parsedFilter);
        return (
            <ToprowContext.Provider
                value={{
                    bufferedLocationSearch: this.state.bufferedLocationSearch,
                    processDefinitionKey,
                    parsedFilter,
                    users,
                    processDefinitions,
                    showHiddenFeatures,
                }}
            >
                <Helmet>
                    <title>Search Cases</title>
                </Helmet>
                {/* this needs to update when buffered state changed */}
                <OnlyUpdateForChange
                    value={`${this.state.externalLocationSearch}:${this.state.forceRefreshKey}${
                        this.getForceDatagridNotReady() ? 'notready' : ''
                    }`}
                >
                    {/* We need to prevent this list from updating unless external location changes */}
                    <InnerProcessList
                        forceDatagridNotReady={this.getForceDatagridNotReady()}
                        resultHeadingText="Search Results"
                        renderActions={() => (
                            <ToprowContext.Consumer>
                                {({
                                    showHiddenFeatures: showHidden,
                                    processDefinitions: pds,
                                    processDefinitionKey: pdk,
                                }) => (
                                    <CardActions
                                        style={{ zIndex: 2, display: 'inline-block', float: 'right', padding: 0 }}
                                    >
                                        {// for creating cases of the selected type
                                        showHidden && pdk && pds && pds[pdk] && pds[pdk].starterUser && (
                                            <Button
                                                variant="text"
                                                color="primary"
                                                onClick={() => createProcessInstance(pds[pdk].id)}
                                            >
                                                {`Create ${pds[pdk].name}`}
                                                <ContentAdd />
                                            </Button>
                                        )}
                                        <UserIsSuper>
                                            <IconButton
                                                id="toggle-hidden-features"
                                                aria-label="toggle-hidden-features"
                                                style={{ opacity: 0.1 }}
                                                onClick={() => setShowHiddenFeatures(!showHidden)}
                                            >
                                                <DeveloperMode />
                                            </IconButton>
                                        </UserIsSuper>
                                    </CardActions>
                                )}
                            </ToprowContext.Consumer>
                        )}
                        key={`${this.state.forceRefreshKey}:${showHiddenFeatures}:${
                            this.getForceDatagridNotReady() ? 'nr' : ''
                        }`}
                        usePrevWhenLoading={true}
                        renderWhileNoPrevAndSpinnerDeferred={this.getSubmitSearchToSeeResults}
                        keyForPrev={`${processDefinitionKey}:${assignee}:${state}`}
                        viewName={getViewName(processDefinitionKey)}
                        hasCreate={false}
                        title={'Cases'}
                        resource={'AppCase'}
                        updateUrlFromFilter={false}
                        fakePush={this.fakePush}
                        location={this.getInnerLocation()}
                        processDefinitionKey={processDefinitionKey}
                        showType={true}
                        showFilters={false}
                        filter={getFilterFromParams(
                            processDefinitionKey,
                            unassigned ? undefined : assignee ? assignee : currentUserId,
                            state,
                        )}
                        formId={getViewName(processDefinitionKey)}
                        renderPagination={args => {
                            return (
                                <ToprowContext.Consumer>
                                    {({ bufferedLocationSearch }) => {
                                        if (bufferedLocationSearch !== this.state.externalLocationSearch) {
                                            return <div />;
                                        }
                                        return <RaPagination {...args} />;
                                    }}
                                </ToprowContext.Consumer>
                            );
                        }}
                        renderNoResults={({ getDefaultNoResults, listIsLoading }) => {
                            return (
                                <ToprowContext.Consumer>
                                    {({ bufferedLocationSearch }) => {
                                        if (bufferedLocationSearch !== this.state.externalLocationSearch) {
                                            return this.getSubmitSearchToSeeResults();
                                        }
                                        if (listIsLoading) {
                                            return (
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <div>{getDefaultNoResults()}</div>
                                                    <div style={{ marginLeft: '.5em', marginTop: '7px' }}>
                                                        <DeferredSpinner
                                                            renderSpinner={() => (
                                                                <div
                                                                    style={{
                                                                        height: 50,
                                                                        width: 50,
                                                                        overflow: 'hidden',
                                                                    }}
                                                                >
                                                                    <div className="loader">Search In Progress...</div>
                                                                </div>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        }
                                        return getDefaultNoResults();
                                    }}
                                </ToprowContext.Consumer>
                            );
                        }}
                        renderList={args => {
                            return (
                                <ToprowContext.Consumer>
                                    {({ bufferedLocationSearch }) => {
                                        const { defaultRenderer, ...rest } = args;
                                        if (bufferedLocationSearch !== this.state.externalLocationSearch) {
                                            return this.getSubmitSearchToSeeResults();
                                        }
                                        return renderList ? renderList(args) : defaultRenderer(rest);
                                    }}
                                </ToprowContext.Consumer>
                            );
                        }}
                        renderFilter={({ filterValues, setFilters, submitFilters, clearFilters, permanentFilter }) => (
                            <ToprowContext.Consumer>
                                {({
                                    processDefinitionKey: pdkey,
                                    parsedFilter: pf,
                                    users: usrs,
                                    showHiddenFeatures: showHidden,
                                }) => (
                                    <div style={{ width: '100%', paddingTop: 0 }}>
                                        <h2>Search Criteria</h2>
                                        <div>
                                            <div
                                                style={{
                                                    paddingLeft: '0.25em',
                                                    display: 'inline-flex',
                                                    flexDirection: 'row',
                                                    flexWrap: 'wrap',
                                                }}
                                            >
                                                <CaseTypeDropdown
                                                    disabled={this.props.disableCaseTypeChange}
                                                    processDefinitionKey={pdkey}
                                                    onChange={this.bufferChangeProcessDefinitionKey}
                                                />
                                                <span key="divider0" style={{ width: 32 }} />
                                                <StateDropdown
                                                    key="statedropdown"
                                                    filter={pf}
                                                    onChange={this.bufferMergeFilterIntoLocation}
                                                />
                                                {showHidden ? (
                                                    <React.Fragment>
                                                        <span style={{ width: 32 }} />
                                                        <AssignedDropdown
                                                            currentUser={currentUserId}
                                                            filter={pf}
                                                            onChange={this.bufferMergeFilterIntoLocation}
                                                            users={usrs}
                                                        />
                                                    </React.Fragment>
                                                ) : null}
                                            </div>
                                            <ProcessListFilter
                                                renderWhenNoFields={() => (
                                                    <span
                                                        style={{
                                                            paddingLeft: 'calc(0.5em + 4px)',
                                                            verticalAlign: 'bottom',
                                                        }}
                                                    >
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            style={{ marginBottom: 8 }}
                                                            onClick={() =>
                                                                this.pushSearchOrRefresh(
                                                                    this.state.bufferedLocationSearch,
                                                                )
                                                            }
                                                        >
                                                            <span style={{ width: 45 }}>Search</span>
                                                        </Button>
                                                    </span>
                                                )}
                                                renderContainer={({ BaseElement }) => (
                                                    <div>
                                                        <OnlyUpdateForChange value={this.state.externalLocationSearch}>
                                                            {BaseElement}
                                                        </OnlyUpdateForChange>
                                                    </div>
                                                )}
                                                key={getViewName(pdkey)}
                                                referencedFromEntity={referencedFromEntity}
                                                formId={getViewName(pdkey)}
                                                resource={'AppCase'}
                                                // filterValues parameter must be passed to set filters
                                                // initialized from the URL,
                                                // BUT we can't let the component rerender based on it
                                                // (there is a form internal, which needs to stick around
                                                // to handle the change events)
                                                filterValues={filterValues}
                                                setFilters={(...args) => {
                                                    this.setFilters(...args);
                                                }}
                                                submitFilters={(...args) => {
                                                    this.submitFilters();
                                                }}
                                                clearFilters={this.clearFilters}
                                                permanentFilter={permanentFilter}
                                                processDefinitionKey={pdkey}
                                                showButtons={true}
                                            />
                                        </div>
                                    </div>
                                )}
                            </ToprowContext.Consumer>
                        )}
                    />
                </OnlyUpdateForChange>
            </ToprowContext.Provider>
        );
    }
}
const mapDispatchToProps = (dispatch, ownProps: GenericListProps) => {
    const changeLocation = (location: GenericListProps['location']['search']) => {
        if (ownProps.interceptLocationChange) {
            ownProps.interceptLocationChange(location);
        } else {
            dispatch(pushAction(location));
        }
    };
    return {
        push: loc => dispatch(pushAction(loc)),
        pushSearch: changeLocation,
        getUsers: () => {
            dispatch(getPotentialUsers());
        },
        createProcessInstance: (processDefinitionId: string) => {
            dispatch(
                startProcess(
                    {
                        processDefinitionId,
                    },
                    () => null,
                    {},
                    true,
                ),
            );
        },
    };
};
const ProcessList: React.SFC<GenericListProps> = compose(
    connect(
        () => {
            const getUsers = createGetPotentialUsersByIdSelector();
            return (state: RootState) => ({
                users: getUsers(state),
                currentUserId: state.viewConfig && state.viewConfig.user && state.viewConfig.user.id,
                processDefinitions: state.bpm && state.bpm.processDefinitions && state.bpm.processDefinitions.byId,
                viewConfig: state.viewConfig,
            });
        },
        mapDispatchToProps,
    ),
    branch(
        props => !props.viewConfig || Object.keys(props.viewConfig).length === 0, // if no viewConfig, render nothing.
        renderNothing,
    ),
    lifecycle({
        componentDidMount() {
            this.props.getUsers();
        },
    }),
    withState('showHiddenFeatures', 'setShowHiddenFeatures', false),
)(ProcessListComponent);
export default ProcessList;
