export const TEXTBOX = 'TEXTBOX';
export const TEXTAREA = 'TEXTAREA';
export const CALENDAR = 'CALENDAR';
export const CALENDARTIME = 'CALENDARTIME';
export const FILEUPLOAD = 'FILEUPLOAD';
export const VIEW = 'VIEW';
export const LOOKUP = 'LOOKUP';
export const FLOAT = 'FLOAT';
export const INTEGER = 'INTEGER';
export const EMAIL = 'EMAIL';
export const CHECKBOX = 'CHECKBOX';
export const SELECT = 'SELECT';
export const MULTISELECT = 'MULTISELECT';
export const TOGGLE = 'TOGGLE';
// special
export const ADDRESS = 'ADDRESS';
export const EXPRESSION = 'EXPRESSION'; // this is used for HTML expression fields
export const ZONE_DATE = 'ZONE_DATE';
export const RADIO = 'RADIO';
export const FORCE_IDS_LIST = 'FORCE_IDS_LIST'; // this is to use many-many on merging of refManys
export const FORCE_ONEOF = 'FORCE_ONEOF';
export const CURRENCY = 'CURRENCY';
export const EVENT = 'EVENT';
export const ENTITY_CHIP = 'ENTITY_CHIP';
export const PERCENT = 'PERCENT';
export const ENTITY_TYPEAHEAD = 'ENTITY_TYPEAHEAD';
export const MULTIPLE_ENTITY_TYPEAHEAD = 'MULTIPLE_ENTITY_TYPEAHEAD';
export const DMSDOC = 'DMSDOC';
export const WYSIWYG = 'HTML_WYSIWYG';
export const NULLABLE_BOOLEAN = 'NULLABLE_BOOLEAN';
export const VALUESET_SUGGEST = 'VALUESET_SUGGEST';
