import React, { useRef } from 'react';
import { EventOrValueHandler, WrappedFieldMetaProps } from 'redux-form';
import { RootState } from 'reducers/rootReducer';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import Downshift from 'downshift';
import { FieldTitle } from '../aor/FieldTitle';
import TextField from '../TextField/TextField';
import { IconButton, Paper, MenuItem, withStyles, CircularProgress } from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';
import { fromNullable, fromPredicate } from 'fp-ts/lib/Option';
import { fetchStart, fetchEnd } from 'actions/aor/fetchActions';
import { getRestUrl, getPluralName } from 'components/generics/utils/viewConfigUtils';
import classnames from 'classnames';
import { WithStyles, createStyles, Theme } from '@material-ui/core';
import getFormHelperTextProps from 'fieldFactory/util/getFormHelperTextProps';
import uniqueId from 'lodash/uniqueId';
import { getFilterFromFilterString } from '../ListSelect';
import translateFieldWithSearchTypeAppended from 'clients/utils/translateFieldWithSearchTypeAppended';
import { crudGetOne as crudGetOneAction } from 'sideEffect/crud/getOne/actions';
import formatError from 'fieldFactory/util/formatError';
import EntityAutocompleteDropdown from './AutocompleteDropdown';
import EntityInspect from 'components/generics/hoc/EntityInspect';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        container: {
            flexGrow: 1,
            position: 'relative',
        },
        paper: {
            position: 'absolute',
            zIndex: 5000,
            marginTop: theme.spacing(-1),
            overflowY: 'scroll',
            maxHeight: 300,
            left: 0,
            minWidth: '100%',
        },
        popoverPaper: {
            maxHeight: 150,
        },
        paperTop: {
            position: 'absolute',
            zIndex: 5000,
            overflowY: 'scroll',
            maxHeight: 150,
            left: 0,
            minWidth: '100%',
            bottom: `calc(100% - ${theme.spacing(4)}px)`, // fixed disance from top
        },
        inputRoot: {
            flexWrap: 'wrap',
        },
        iconButton: {
            position: 'absolute',
            right: 0,
            top: theme.spacing(2),
            height: 30,
            width: 30,
            padding: 0,
        },
        loadingSpinner: {
            position: 'absolute',
            right: 2,
            top: 18,
            padding: 0,
        },
    });

interface EntityTypeaheadProps {
    randomizeNameAsBrowserAutocompleteHack?: boolean;
    expansions?: string[];
    filterString?: string;
    dropdownPosition?: 'above' | 'below';
    isPopover?: boolean;
    label?: string;
    isRequired?: boolean;
    emptyText?: string;
    disabled?: boolean;
    allowEmptyQuery: boolean;
    input: {
        onBlur: EventOrValueHandler<string | null>;
        value: string | null;
    };
    source: string;
    reference: string;
    meta: WrappedFieldMetaProps;
}
const makeMapStateToProps = () => {
    const mapStateToProps = (state: RootState, props: EntityTypeaheadProps) => {
        return {
            restUrl: getRestUrl(props.reference)(state),
            refEntityDisplayNamePlural: getPluralName(state.viewConfig, props.reference),
            currentlySelectedEntity: fromPredicate<string>(Boolean)(props.input.value)
                .chain(id => fromNullable(state.admin.entities[props.reference]).mapNullable(e => e[id]))
                .getOrElse(null),
        };
    };
    return mapStateToProps;
};
const dispatches = {
    fetchStart,
    fetchEnd,
    crudGetOne: crudGetOneAction,
    addEntityToStore: (record: { id: string; entityType: string }) => ({
        type: 'ADD_ENTITY_TO_STORE',
        payload: {
            data: {
                entities: {
                    [record.entityType]: {
                        [record.id]: record,
                    },
                },
            },
        },
    }),
};
type Dispatches = typeof dispatches;
interface EntityTypeaheadComponentProps
    extends EntityTypeaheadProps,
        ReturnType<ReturnType<typeof makeMapStateToProps>>,
        Dispatches,
        WithStyles<typeof styles> {}

const EntityTypeaheadComponent: React.FunctionComponent<EntityTypeaheadComponentProps> = props => {
    const errorMessageId = React.useRef(uniqueId('entity-typeahead'));
    const currentlySelectedEntityTitle = (props.currentlySelectedEntity && props.currentlySelectedEntity.title) || '';
    const [inputValue, setInputValue] = React.useState(currentlySelectedEntityTitle);
    const lastEntityTitle = React.useRef(currentlySelectedEntityTitle);
    React.useEffect(() => {
        if (currentlySelectedEntityTitle !== lastEntityTitle.current && currentlySelectedEntityTitle !== inputValue) {
            if (currentlySelectedEntityTitle) {
                setInputValue(currentlySelectedEntityTitle);
            } else if (!props.meta.dirty) {
                setInputValue('');
            }
        }
        lastEntityTitle.current = currentlySelectedEntityTitle;
    }, [currentlySelectedEntityTitle, setInputValue, inputValue, props.meta.dirty]);
    const [loading, setLoading] = React.useState(false);
    const {
        label,
        meta,
        currentlySelectedEntity,
        restUrl,
        isRequired,
        disabled,
        emptyText = 'None Selected',
        addEntityToStore,
        fetchStart,
        fetchEnd,
        classes,
        isPopover,
        reference,
        dropdownPosition = 'below',
        refEntityDisplayNamePlural,
        source,
        allowEmptyQuery,
        randomizeNameAsBrowserAutocompleteHack = true,
        filterString,
    } = props;
    const uniqueNameToThrowOffChromeAutoFill = useRef(new Date().toISOString());
    const paperClass = dropdownPosition === 'below' ? classes.paper : classes.paperTop;
    const newFilter = React.useMemo(() => {
        const filterObject = getFilterFromFilterString(filterString);
        const _newFilter = {};
        Object.entries(filterObject).forEach(([key, value]) => {
            _newFilter[
                translateFieldWithSearchTypeAppended(key)
                    .split('_~_')
                    .join('.')
            ] = value;
        });
        return _newFilter;
    }, [filterString]);
    return (
        <EntityInspect
            reference={reference}
            formId={`fromentitytypeahead ${source}`}
            renderComponent={args => (
                <div className={classes.root}>
                    <Downshift
                        inputValue={inputValue}
                        selectedItem={currentlySelectedEntity || ''}
                        onSelect={(record, ds) => {
                            if (record) {
                                // make record immediately available
                                fetchStart();
                                addEntityToStore(record);
                                fetchEnd();
                                setInputValue(record.title);
                                // IF we have expansions, we will have to fetch...
                                if (props.expansions && props.expansions.length > 0) {
                                    setLoading(true);
                                    props.crudGetOne({
                                        id: record.id,
                                        resource: reference,
                                        view: -1,
                                        appendExpansions: props.expansions,
                                        cb: () => {
                                            setImmediate(() => {
                                                setLoading(false);
                                                props.input.onBlur(record.id);
                                            });
                                        },
                                        errorsCbs: {
                                            '*': () => {
                                                setLoading(false);
                                                props.input.onBlur(record.id);
                                            },
                                        },
                                    });
                                } else {
                                    props.input.onBlur(record.id);
                                }
                            } else {
                                props.input.onBlur(null);
                            }
                        }}
                        itemToString={record => record.title}
                    >
                        {({
                            inputValue,
                            getInputProps,
                            getLabelProps,
                            getMenuProps,
                            getItemProps,
                            setItemCount,
                            clearItems,
                            selectedItem,
                            highlightedIndex,
                            isOpen,
                            openMenu,
                            clearSelection,
                        }) => {
                            const InputProps = getInputProps({
                                'aria-errormessage': meta.touched && meta.error ? errorMessageId.current : undefined,
                                placeholder: selectedItem ? selectedItem.title : emptyText,
                                disabled,
                                style: {
                                    textOverflow: 'ellipsis',
                                    marginRight: '60px',
                                },
                                onChange: e => {
                                    setInputValue(e.target.value);
                                },
                                onFocus: () => openMenu(),
                                autoComplete: 'never',
                            });
                            return (
                                <div className={classes.container}>
                                    <TextField
                                        margin="none"
                                        fullWidth={true}
                                        InputLabelProps={{
                                            shrink: true,
                                            ...getLabelProps({
                                                disabled: false,
                                            }),
                                        }}
                                        label={label && <FieldTitle label={label} isRequired={isRequired} />}
                                        InputProps={{
                                            inputProps: (() => {
                                                if (randomizeNameAsBrowserAutocompleteHack) {
                                                    return {
                                                        ...InputProps,
                                                        name: uniqueNameToThrowOffChromeAutoFill.current,
                                                    };
                                                }
                                                return InputProps;
                                            })(),
                                            ...InputProps,
                                            classes: {
                                                root: classes.inputRoot,
                                            },
                                        }}
                                        error={!!(meta.touched && meta.error)}
                                        helperText={
                                            meta.touched && meta.error ? `Error: ${formatError(meta.error)}` : undefined
                                        }
                                        FormHelperTextProps={getFormHelperTextProps(InputProps)}
                                    />
                                    {loading && (
                                        <CircularProgress
                                            style={{ height: 26, width: 26 }}
                                            className={classes.loadingSpinner}
                                        />
                                    )}
                                    {!loading && selectedItem && (
                                        <IconButton
                                            color="inherit"
                                            classes={{
                                                root: classes.iconButton,
                                            }}
                                            onClick={() => args.selectId(selectedItem.id)}
                                            aria-label="view"
                                        >
                                            <RemoveRedEye />
                                        </IconButton>
                                    )}
                                    {!loading && selectedItem && !disabled && (
                                        <IconButton
                                            color="inherit"
                                            classes={{
                                                root: classes.iconButton,
                                            }}
                                            style={{ right: 30 }}
                                            onClick={() => {
                                                clearSelection();
                                                setInputValue('');
                                            }}
                                            aria-label="clear"
                                        >
                                            <Clear />
                                        </IconButton>
                                    )}
                                    <div {...getMenuProps()}>
                                        {isOpen && (
                                            <Paper
                                                className={
                                                    isPopover
                                                        ? classnames(paperClass, classes.popoverPaper)
                                                        : paperClass
                                                }
                                                square={true}
                                            >
                                                {(() => {
                                                    if (!inputValue && !allowEmptyQuery) {
                                                        return (
                                                            <MenuItem component="div" aria-live="polite" disabled>
                                                                You have to enter a search query
                                                            </MenuItem>
                                                        );
                                                    }

                                                    return (
                                                        <EntityAutocompleteDropdown
                                                            selectedItem={selectedItem}
                                                            highlightedIndex={highlightedIndex}
                                                            restUrl={restUrl}
                                                            refEntityDisplayNamePlural={refEntityDisplayNamePlural}
                                                            getItemProps={getItemProps}
                                                            inputValue={inputValue}
                                                            filter={newFilter}
                                                            setItemCount={setItemCount}
                                                        />
                                                    );
                                                })()}
                                            </Paper>
                                        )}
                                    </div>
                                </div>
                            );
                        }}
                    </Downshift>
                </div>
            )}
        />
    );
};

const EntityTypeahead = compose(connect(makeMapStateToProps, dispatches), withStyles(styles))(EntityTypeaheadComponent);
export default EntityTypeahead;
