import { Action } from 'redux';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import { fetchJson } from '../util/fetchUtils';
import { API_URL } from '../config';
import { FETCH_START, FETCH_END, FETCH_ERROR } from 'actions/aor/types';
import { LOAD_VALUE_SET, VALUE_SET_NOT_RECEIVED, VALUE_SET_CONCEPTS_RECEIVED } from '../actions/constants';
import buildFetchOptions from './util/buildFetchOptions';
import SkipWhileTrying from './util/SkipWhileTrying';
// import { delay } from 'redux-saga';

// const saveViewConfig = state => window.sessionStorage.setItem('viewConfig', JSON.stringify(state));

interface ConceptFromApi {
    id: string;
    code: string;
    display?: string;
    description?: string;
    sortOrder?: number;
    active: boolean;
    group?: string;
}
export interface ConceptInApp extends ConceptFromApi {
    title: string;
    subtitle: string;
    entityType: 'Concept';
    valueSetId: string;
    valueSet?: string;
}

export const getTransformConcept = (valueSetId: string, valueSetCode: string) => (
    concept: ConceptFromApi,
): ConceptInApp => {
    return {
        ...concept,
        title: `${valueSetCode}:${concept.display}`,
        subtitle: concept.id,
        entityType: 'Concept',
        valueSetId,
        valueSet: valueSetId,
    };
};

interface ValuesetFromApi {
    id: string;
    code: string;
    display: string;
    concepts: {
        id: string;
        code: string;
        display: string;
        description: string;
        sortOrder: number;
        active: boolean;
    }[];
}

export interface ValuesetInApp {
    id: string;
    code: string;
    display: string;
    entityType: 'ValueSet';
    title: string;
    subtitle: string;
}

export const transformValueset = (valuesetFromApi: ValuesetFromApi): ValuesetInApp => {
    const { concepts, ...restFromApi } = valuesetFromApi;
    return {
        ...restFromApi,
        entityType: 'ValueSet',
        title: restFromApi.display,
        subtitle: restFromApi.id,
    };
};
const getConceptsReceivedAction = (valueSetId: string, valueSetCode: string, valueSet: ValuesetInApp) => {
    const transformConcept = getTransformConcept(valueSetId, valueSetCode);
    return (valueSetFromApi: ValuesetFromApi) => {
        return {
            type: VALUE_SET_CONCEPTS_RECEIVED as typeof VALUE_SET_CONCEPTS_RECEIVED,
            payload: {
                valueSetCode,
                valueSet,
                data: {
                    entities: {
                        Concept: Object.assign(
                            {},
                            ...valueSetFromApi.concepts.map(c => ({ [c.id]: transformConcept(c) })),
                        ) as { [id: string]: ConceptInApp },
                    },
                    result: valueSetFromApi.concepts.map(c => c.id),
                },
            },
        };
    };
};
export type ConceptsReceivedAction = ReturnType<ReturnType<typeof getConceptsReceivedAction>>;

const skipTrying = SkipWhileTrying(LOAD_VALUE_SET, (action: { payload: string }) => action.payload);

export function* loadValueSet(action: { payload: string } & Action) {
    const valueSetCode = action.payload;
    const fetchValueSet = yield select(
        (state: { valueSets: { invalid: boolean } }): boolean =>
            (state.valueSets[valueSetCode] || { invalid: true }).invalid,
    );
    if (!fetchValueSet) {
        // don't fetch
        console.log(`cache is valid or value set already exists so don't load valueSet ${action.payload}`); //tslint:disable-line
        skipTrying.actionFinished(valueSetCode);
        return;
    } else {
        console.log(`cache is invalid, loading valueSet ${action.payload}`); //tslint:disable-line
    }
    yield put({ type: FETCH_START });
    try {
        const options = buildFetchOptions();

        // TODO: todo this currently returns and array.
        //  Not sure if we want a separate endpoint for the gets on value sets that are also not secured?
        const { status, body } = yield call(
            fetchJson,
            `${API_URL}value-set/${valueSetCode}?includeInActive=true`,
            options,
        ); //tslint:disable-line
        if (status === 200) {
            const valueSetFromApi: ValuesetFromApi = JSON.parse(body);
            const valueSet = transformValueset(valueSetFromApi);
            const conceptsReceivedAction = getConceptsReceivedAction(valueSet.id, valueSetCode, valueSet)(
                valueSetFromApi,
            );

            yield put(conceptsReceivedAction);
            yield put({ type: FETCH_END });
        } else {
            yield put({ type: VALUE_SET_NOT_RECEIVED, status });
            yield put({ type: FETCH_ERROR });
        }
    } catch (error) {
        console.error(error); // tslint:disable-line
        yield put({ type: VALUE_SET_NOT_RECEIVED, error });
        yield put({ type: FETCH_ERROR, error });
    } finally {
        skipTrying.actionFinished(valueSetCode);
    }
}

export default function* getViewConfigOnLoginSaga() {
    yield takeEvery(skipTrying.takeAction, function*(action: { payload: string } & Action) {
        yield call(loadValueSet, action);
    });
}
