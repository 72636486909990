import React, { Children } from 'react';
import { Toolbar as MuiToolbar, createStyles, withStyles } from '@material-ui/core';
import SaveButton from 'components/generics/button/SaveButton';

const styles = createStyles({
    mobileToolbar: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        backgroundColor: 'white',
        justifyContent: 'flex-end',
        zIndex: 2,
        paddingRight: '2em',
    },
});

const valueOrDefault = (value, defaultValue) => (typeof value === 'undefined' ? defaultValue : value);

interface AorSaveButtonProps {
    invalid?: boolean;
    handleSubmitWithRedirect?: () => void;
    submitOnEnter?: boolean;
    saving?: boolean;
    raised?: boolean;
}
interface ToolbarProps {
    saving?: boolean;
    invalid?: boolean;
    submitOnEnter?: boolean;
    handleSubmitWithRedirect?: (redirect?: string | boolean | (() => void)) => () => void;
}
interface ToolbarComponentProps extends ToolbarProps {}
const ToolbarComponent: React.SFC<ToolbarComponentProps> = ({
    invalid,
    submitOnEnter,
    handleSubmitWithRedirect,
    saving,
    children,
}) => (
    <MuiToolbar>
        {Children.count(children) === 0 ? (
            <SaveButton
                handleSubmitWithRedirect={handleSubmitWithRedirect}
                invalid={invalid}
                submitOnEnter={submitOnEnter}
                saving={saving}
            />
        ) : (
            Children.map(children, (button: React.ReactElement<AorSaveButtonProps>) =>
                React.cloneElement(button, {
                    saving,
                    handleSubmitWithRedirect,
                    invalid,
                    submitOnEnter: valueOrDefault(button.props.submitOnEnter, submitOnEnter),
                }),
            )
        )}
    </MuiToolbar>
);

ToolbarComponent.defaultProps = {
    submitOnEnter: true,
};

const Toolbar: React.ComponentType<ToolbarProps> = (withStyles(styles) as any)(ToolbarComponent); // tslint:disable-line
export default Toolbar;
