import { ajax, AjaxRequest, AjaxResponse } from 'rxjs/ajax';
import { PotentialUser } from 'bpm/potentialUsers/reducer';
import { BACKEND_BASE_URL } from 'config';
import { Observable } from 'rxjs';
import { AuthPayload } from 'auth/definitions';
import ViewConfig, { View } from 'reducers/ViewConfigType';
import ProcessDefinition from 'bpm/types/processDefinition';
import { PrintTemplate } from 'printTemplate/definitions';
import { CreateTaskPayload } from 'bpm/createTask/types';
import { tap } from 'rxjs/operators';
import { ProcessInstanceFromRestProcessInstances } from 'bpm/dataAdapters/network/rest/processInstances/entities/processInstance';
import { TaskForm } from 'reducers/rootReducer';
import { GlobalAlert } from 'global-alerts/definitions';
import { storageController } from 'storage';
import { StartProcessPayload } from 'bpm/create-process-instance/actions';

// adds a token reset subscription to the ajax Observable
export const refreshJwt = (ajaxObservable: Observable<AjaxResponse>) => {
    return ajaxObservable.pipe(
        tap(ajaxResponse => {
            const newJwt = ajaxResponse.xhr.getResponseHeader('JWT');
            if (newJwt) {
                console.log('jwt refreshed');
                storageController.setToken(newJwt);
            }
        }),
    );
};

export interface TaskPotentialUsersResponse {
    canClaimTask: boolean;
    canAssignTask: boolean;
    potentialUsers: PotentialUser[];
}

const getJSON = ajax.getJSON;
export function getHeaders() {
    const headers = {
        credentials: 'same-origin',
        Accept: 'application/json',
        clientTzOffset: new Date().getTimezoneOffset(),
    };
    const authToken = storageController.getToken();
    if (authToken) {
        headers['Authorization'] = `Bearer ${authToken}`;
    }
    return headers;
}
type NoBodyVerbs = 'GET' | 'DELETE' | 'POST';
type BodyVerbs = 'POST' | 'PUT';

export function getOptions(url: string, method: NoBodyVerbs): Object;
export function getOptions(url: string, method: BodyVerbs, body: Object): Object;
export function getOptions(url: string, method: NoBodyVerbs | BodyVerbs, body?: Object): AjaxRequest {
    return {
        url,
        method,
        headers: {
            ...getHeaders(),
            'Content-Type': 'application/json',
        },
        withCredentials: true,
        responseType: 'json',
        body,
    };
}
const monitorOptions = (monitor: boolean = false) => {
    return (options: AjaxRequest) => {
        if (monitor) {
            options.headers['X-Is-Monitored'] = true;
        }
        return options;
    };
};
export const getUrl = (url: string) => `${BACKEND_BASE_URL}${url}`;

export interface EntityBase {
    id: string;
    entityType: string;
    entityVersion: number;
}
const authenticateService = (body: AuthPayload) =>
    ajax({
        url: getUrl('api/authenticate'),
        method: 'POST',
        withCredentials: true,
        responseType: 'json',
        headers: {
            'Content-Type': 'application/json',
            credentials: 'same-origin',
            Accept: 'application/json',
        },
        body,
    });
interface ProcessDefinitionResponse {
    data: ProcessDefinition[];
    size: number;
    start: number;
    total: number;
}

interface DashboardResponse extends EntityBase {
    config: string;
    displayName: string;
    entityType: 'Dashboard';
    name: string;
}
export interface UserPrimaryDashboardResponse extends EntityBase {
    id: string;
    entityType: 'User';
    login: string;
    active: boolean;
    roles: [
        {
            entityType: 'Role';
            name: string; // e.g. "ROLE_SUPER"
        },
    ];
    primaryDashboardId: string | undefined;
}
export const services = {
    getPublicGlobalAlerts: () => getJSON<GlobalAlert[]>(getUrl('api/public/global-alerts'), getHeaders()),
    getPrivateGlobalAlerts: () => getJSON<GlobalAlert[]>(getUrl('api/global-alerts'), getHeaders()),
    getViewConfig: () => getJSON<ViewConfig>(getUrl('api/view-config'), getHeaders()),
    getPrintTemplates: (entityConfId: string) =>
        getJSON<PrintTemplate[]>(
            getUrl(`api/print-templates?entityConfId.equals=${entityConfId}&sort=id%2CDESC&size=9999`),
            getHeaders(),
        ),
    getStartForm: (processDefinitionId: string) =>
        getJSON<TaskForm>(getUrl(`api/bpm/process-definition/${processDefinitionId}/start-form`), getHeaders()),
    getPrintTemplateByName: (name: string) =>
        getJSON<PrintTemplate[]>(getUrl(`api/print-templates?name.equals=${name}&sort=id%2CDESC&size=2`), getHeaders()),
    authenticate: authenticateService,
    refreshToken: () =>
        refreshJwt(
            ajax({
                url: getUrl('api/authenticate/refresh'),
                method: 'GET',
                headers: {
                    credentials: 'same-origin',
                    Authorization: storageController.getToken() ? `Bearer ${storageController.getToken()}` : undefined,
                },
                // when we get back non-json string, we need responseType: 'text' or IE11 throws.
                responseType: 'text',
                withCredentials: true,
            }),
        ),
    getProcessInstance: (processId: string) =>
        getJSON<ProcessInstanceFromRestProcessInstances>(
            getUrl(`api/bpm/process-instances/${processId}`),
            getHeaders(),
        ),
    updateView: (view: View) => refreshJwt(ajax(getOptions(getUrl('api/view-config/update-view'), 'POST', view))),
    loadAllDashboardConfigs: () => getJSON<DashboardResponse[]>(getUrl('api/user-dashboards'), getHeaders()),
    setCurrentUserPrimaryDashboard: (dashboardId: string) =>
        refreshJwt(ajax(getOptions(getUrl(`api/current-user/dashboard/${dashboardId}`), 'PUT', {}))),
    startProcessInstance: (payload: StartProcessPayload) =>
        refreshJwt(ajax(getOptions(getUrl('api/bpm/process-instances'), 'POST', payload))),
    getCurrentUserPrimaryDashboard: () =>
        getJSON<UserPrimaryDashboardResponse>(getUrl('api/current-user/dashboard'), getHeaders()),
    getProcessDefinitions: () =>
        getJSON<ProcessDefinitionResponse>(getUrl('api/bpm/process-definitions?latest=true'), getHeaders()),
    getAllPotentialUsers: () => getJSON<PotentialUser[]>(getUrl('api/bpm/potential-users?isActive=true'), getHeaders()),
    getTaskPotentialUsers: (taskId: string) =>
        getJSON<TaskPotentialUsersResponse>(getUrl(`api/bpm/tasks/${taskId}/potential-users`), getHeaders()),
    createTask: (data: CreateTaskPayload) => refreshJwt(ajax(getOptions(getUrl('api/bpm/tasks'), 'POST', data))),
    impersonateUser: (userId: string) => ajax(getOptions(getUrl(`api/impersonate/${userId}`), 'POST')),
    getTaskForm: (taskId: string) => getJSON<TaskForm>(getUrl(`api/bpm/task-forms/${taskId}`), getHeaders()),
    crudCreate: (params: { data: {}; restUrl: string }) => {
        const { data, restUrl } = params;
        return refreshJwt(ajax(getOptions(getUrl(restUrl), 'POST', data)));
    },
    crudUpdate: (params: { data: {}; restUrl: string }) => {
        const { data, restUrl } = params;
        return refreshJwt(ajax(getOptions(getUrl(restUrl), 'PUT', data)));
    },
    crudGet: (params: { restUrl: string; monitorRequest?: boolean }) => {
        const { restUrl, monitorRequest } = params;
        return refreshJwt(ajax(monitorOptions(monitorRequest)(getOptions(getUrl(restUrl), 'GET'))));
    },
    crudDelete: (params: { restUrl: string }) => {
        const { restUrl } = params;
        return refreshJwt(ajax(getOptions(getUrl(restUrl), 'DELETE')));
    },
    getJSON,
} as const;
export type Services = typeof services;

export interface GenericCrudArgs<D> {
    data?: D;
    monitorRequest?: boolean;
    restUrl: string;
}
// must be compatible with AjaxResponse
export interface GenericAjaxResponse {
    status: number;
    response: any;
    responseText: string;
    xhr: {
        getResponseHeader(key: string): string;
    };
}

export type GenericCrudService<D> = (params: GenericCrudArgs<D>) => Observable<GenericAjaxResponse>;
export const crudServices: {
    [key: string]: GenericCrudService<unknown>;
} = {
    crudCreate: services.crudCreate,
};
