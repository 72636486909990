import React from 'react';
import get from 'lodash.get';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import { crudGetOne as crudGetOneAction } from 'sideEffect/crud/getOne/actions';
import { getConceptDisplayById, isConceptActiveById } from '../../../components/generics/utils/valueSetsUtil';

const valueSetField = ({ valueSets, valueSetCode, addLabel, elStyle, label, record, source, conceptDisplay, active }) =>
    active ? conceptDisplay : <div style={{ color: 'grey' }}> {conceptDisplay}</div>;

valueSetField.propTypes = {
    valueSets: PropTypes.shape({}),
    valueSetCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    addLabel: PropTypes.bool,
    label: PropTypes.string.isRequired,
    record: PropTypes.shape({}),
    source: PropTypes.string,
    active: PropTypes.bool,
    conceptDisplay: PropTypes.string,
    elStyle: PropTypes.shape({}),
};
valueSetField.defaultProps = {
    addLabel: true,
};

const mapStateToProps = (state, { record, source }) => ({
    conceptDisplay: getConceptDisplayById(get(record, source), state.admin.entities),
    active: isConceptActiveById(get(record, source), state.admin.entities),
});
const ValueSetField = compose(
    connect(
        mapStateToProps,
        { crudGetOne: crudGetOneAction },
    ),
    lifecycle({
        componentDidMount() {
            if (this.props.fetchOwnData) {
                this.getConcept();
            }
        },
        componentWillReceiveProps(nextProps) {
            if (
                this.props.fetchOwnData &&
                get(this.props.record, this.props.source) !== get(nextProps.record, nextProps.source)
            ) {
                this.getConcept(nextProps);
            }
        },
        getConcept(props = this.props) {
            if (props.record && props.source) {
                const conceptId = get(props.record, props.source);
                if (conceptId) {
                    props.crudGetOne({
                        resource: 'Concept',
                        id: conceptId,
                        view: null,
                    });
                }
            }
        },
    }),
)(valueSetField);
export default ValueSetField;
