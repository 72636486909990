import { createCustomAction } from 'typesafe-actions';
import { USER_LOGIN, USER_LOGIN_FAILURE, USER_LOGIN_SUCCESS } from './constants';
import { AuthPayload } from './definitions';
import { AjaxError } from 'rxjs/ajax';

export const login = createCustomAction(USER_LOGIN, type => {
    return (payload: AuthPayload, errorCb: (error: AjaxError) => boolean, redirectTo?: string) => ({
        type,
        payload,
        errorCb,
        redirectTo,
    });
});

export const loginSuccess = createCustomAction(USER_LOGIN_SUCCESS, type => {
    return () => ({
        type,
    });
});

export const loginFailure = createCustomAction(USER_LOGIN_FAILURE, type => {
    return (error: Error) => ({
        type,
        error,
    });
});
