import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import pure from 'recompose/pure';
import { DateFormatContext } from 'fieldFactory/dateFormat/Broadcasts';
import moment from 'moment';

export const useToMmDdYyHhMi = input => {
    const dateFormat = useContext(DateFormatContext);
    if (input) {
        return moment(input).format(`${dateFormat} h:mm A`);
    }

    return '';
};

const DateTimeField = ({ elStyle, record, source }) => {
    const value = record && get(record, source);
    const dateString = useToMmDdYyHhMi(value);
    return <span style={elStyle}>{dateString}</span>;
};

/*
    These propTypes are important to have, even if they are not used.
*/
DateTimeField.propTypes = {
    addLabel: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
    elStyle: PropTypes.shape({}),
    label: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
    record: PropTypes.shape({}),
    source: PropTypes.string.isRequired,
};

DateTimeField.defaultProps = {
    addLabel: undefined,
    elStyle: {},
    label: undefined,
    record: undefined,
};

const PureDateTimeField = pure(DateTimeField);

PureDateTimeField.defaultProps = {
    addLabel: true,
};

export default PureDateTimeField;
