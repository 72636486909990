import React from 'react';
import debounce from 'lodash/debounce';
import { WrappedFieldInputProps, EventOrValueHandler } from 'redux-form';

const isEvent = (candidate: any): candidate is React.ChangeEvent<HTMLInputElement> =>
    !!(candidate && candidate.stopPropagation && candidate.preventDefault);

/*
    add as needed.
*/
type BaseValueType = string | number | null;

export const getValue = <ValueType extends BaseValueType>(
    eventOrValue: React.ChangeEvent<HTMLInputElement> | ValueType,
): ValueType => {
    if (isEvent(eventOrValue)) {
        return (eventOrValue.target.value as unknown) as ValueType;
    }
    return eventOrValue;
};

interface TextInputProps<ValueType> {
    emptyInitialValue: ValueType;
    debounceTime?: number;
    input?: {
        onBlur: EventOrValueHandler<React.ChangeEvent<HTMLInputElement>>;
        onChange: EventOrValueHandler<React.ChangeEvent<HTMLInputElement>>;
        value: ValueType;
    };
    renderInput: (arg: {
        value: ValueType;
        onBlur: WrappedFieldInputProps['onBlur'];
        onChange: WrappedFieldInputProps['onChange'];
    }) => JSX.Element;
}

interface TextInputState<ValueType> {
    text: ValueType;
    debounceInProgress: boolean;
    lastPropagatedValue: ValueType;
    abortDebounce: boolean;
    prevInputValue: ValueType;
}
const stateUpdate = {
    completeDebounce: <ValueType extends BaseValueType>(value: ValueType) => (
        state: TextInputState<ValueType>,
    ): TextInputState<ValueType> => ({
        ...state,
        text: value,
        debounceInProgress: false,
        lastPropagatedValue: value,
    }),
    loadExternalValue: <ValueType extends BaseValueType>(
        state: TextInputState<ValueType>,
        value,
    ): TextInputState<ValueType> => ({
        ...state,
        text: value,
        lastPropagatedValue: value,
        abortDebounce: true,
        prevInputValue: value,
    }),
    changeImmediateText: <ValueType extends BaseValueType>(value: ValueType, prevInputValue: ValueType) => (
        state: TextInputState<ValueType>,
    ): TextInputState<ValueType> => ({
        ...state,
        text: value,
        debounceInProgress: true,
        abortDebounce: false,
        prevInputValue,
    }),
    bypassDebounce: <ValueType extends BaseValueType>(value: ValueType, prevInputValue: ValueType) => (
        state: TextInputState<ValueType>,
    ): TextInputState<ValueType> => ({
        ...state,
        text: value,
        debounceInProgress: false,
        lastPropagatedValue: value,
        abortDebounce: true,
        prevInputValue,
    }),
};

export class TextInput<ValueType extends BaseValueType> extends React.Component<
    TextInputProps<ValueType>,
    TextInputState<ValueType>
> {
    state: TextInputState<ValueType> = {
        text: this.props.emptyInitialValue,
        debounceInProgress: false,
        lastPropagatedValue: this.props.emptyInitialValue,
        abortDebounce: false,
        prevInputValue: this.props.emptyInitialValue,
    };
    debouncedOnChange: (e: React.ChangeEvent<HTMLInputElement> | ValueType) => void;
    debouncedOnBlur: (e: React.ChangeEvent<HTMLInputElement> | ValueType) => void;
    static getDerivedStateFromProps<ValueType extends BaseValueType>(
        { input: { value } }: TextInputProps<ValueType>,
        state: TextInputState<ValueType>,
    ) {
        if (value !== state.lastPropagatedValue && value !== state.prevInputValue) {
            return stateUpdate.loadExternalValue(state, value);
        }
        return null;
    }
    constructor(props: TextInputProps<ValueType>) {
        super(props);
        this.debouncedOnChange = debounce((e: React.ChangeEvent<HTMLInputElement> | ValueType) => {
            if (this.state.abortDebounce) {
                return;
            }
            this.setState(stateUpdate.completeDebounce(getValue(e)), () => {
                this.props.input.onChange(e);
            });
        }, this.props.debounceTime || 250);
    }
    onChange = (e: React.ChangeEvent<HTMLInputElement> | ValueType) => {
        if (isEvent(e)) {
            e.persist();
        }
        this.setState(stateUpdate.changeImmediateText(getValue(e), this.props.input.value), () => {
            this.debouncedOnChange(e);
        });
    };
    onBlur = (e: React.ChangeEvent<HTMLInputElement> | ValueType) => {
        if (isEvent(e)) {
            e.persist();
        }
        (this.debouncedOnChange as any).cancel(); // tslint:disable-line
        this.setState(stateUpdate.bypassDebounce(getValue(e), this.props.input.value), () => {
            this.props.input.onBlur(e);
        });
    };
    render() {
        return this.props.renderInput({
            onBlur: this.onBlur,
            onChange: this.onChange,
            value: this.state.text,
        });
    }
}
export default TextInput;
