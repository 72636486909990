import React from 'react';
import pure from 'recompose/pure';
import { ReactElement } from 'react';
import memoizeOne from 'memoize-one';
import RGridInner from '../fields/display/RGrid';
import Hidden from './HiddenField';
import { FormFieldUnion } from '../../../fieldFactory/translation/fromFlowable/types/index';
import { formContext } from '../../../bpm/components/TaskDetail/TaskForm/FormContext';
import { printTemplatePrefix, printTemplatePdfPrefix } from 'fieldFactory/util/expressionConstants';

interface RGridWithVisProps {
    fields: ReactElement<{
        source: string;
    }>[];
    fieldsToHide: string | null;
    printTemplatesToHide: string | null;
    formDefinition: null | {
        id: string;
        name: string;
        key: string;
        version: number;
        fields: FormFieldUnion[];
        selectedOutcome?: string;
        outcomes: {
            name: string;
            configs?: {
                visibility?: string;
                editable?: string;
                validation?: string;
            };
        }[];
    };
}

const isNormalPrintTemplateExpressionField = (f: FormFieldUnion): boolean =>
    f.type === 'expression' && !!(f.value && typeof f.value === 'string' && f.value.startsWith(printTemplatePrefix));

const isPdfPrintTemplateExpressionField = (f: FormFieldUnion): boolean =>
    f.type === 'expression' && !!(f.value && typeof f.value === 'string' && f.value.startsWith(printTemplatePdfPrefix));

class RGridWithVis extends React.Component<RGridWithVisProps> {
    static defaultProps = {
        fields: [],
    };
    getFieldsById = memoizeOne((fields: FormFieldUnion[]) => {
        return Object.assign({}, ...fields.map(f => ({ [f.id]: f })));
    });
    getFieldsByPrintTemplateName = memoizeOne((fields: FormFieldUnion[]) => {
        return Object.assign(
            {},
            ...fields.flatMap(f =>
                isNormalPrintTemplateExpressionField(f)
                    ? [{ [f.value.slice(printTemplatePrefix.length)]: f }]
                    : isPdfPrintTemplateExpressionField(f)
                    ? [{ [f.value.slice(printTemplatePdfPrefix.length)]: f }]
                    : [],
            ),
        );
    });
    render() {
        const { fields, formDefinition } = this.props;

        return (
            <formContext.Consumer>
                {fc => (
                    <RGridInner
                        fields={(fields || []).map(f => {
                            const fieldIdBasedOnPrintTemplateName =
                                f.props['printTemplateName'] &&
                                formDefinition && // tslint:disable-line
                                this.getFieldsByPrintTemplateName(formDefinition.fields || [])[
                                    f.props['printTemplateName']
                                ].id;
                            if (fc.hiddenFields[f.props.source] || fc.hiddenFields[fieldIdBasedOnPrintTemplateName]) {
                                return (
                                    <Hidden dontShowCol={true} {...f.props}>
                                        {f}
                                    </Hidden>
                                );
                            }
                            return f;
                        })}
                    />
                )}
            </formContext.Consumer>
        );
    }
}

export default pure(RGridWithVis);
