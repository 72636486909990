import React from 'react';
import DOMPurify from 'dompurify';

// from https://github.com/cure53/DOMPurify/blob/master/demos/hooks-target-blank-demo.html
DOMPurify.addHook('afterSanitizeAttributes', function(node) {
    // set all elements owning target to target=_blank
    if ('target' in node) {
        if (node.getAttribute('target') !== '_self') {
            node.setAttribute('target', '_blank');
            // prevent https://www.owasp.org/index.php/Reverse_Tabnabbing
            node.setAttribute('rel', 'noopener noreferrer');
        }
    }
});

export const purifyHtml = (dangerousMarkup: string) => DOMPurify.sanitize(dangerousMarkup, { ADD_ATTR: ['target'] });

// we run the below (more strict) on 'templated segments' read in from unsafe sources.
// So things read-in from form values, for example.
// In order to prevent user-data from being writting to unsafe links, we prevent anchor tags.
export const purifyHtmlMoreStrict = (dangerousMarkup: string) =>
    DOMPurify.sanitize(dangerousMarkup, { FORBID_TAGS: ['a'] });

const createMarkup = dangerousMarkup => {
    // Don't mess with this. sanitize should always be the final step
    return { __html: DOMPurify.sanitize(dangerousMarkup, { ADD_ATTR: ['target'] }) };
};

const HtmlDisplayComponent: React.FunctionComponent<{
    elStyle?: React.CSSProperties;
    noMargin?: boolean;
    id?: string;
    html: string;
}> = ({ elStyle, html, noMargin, id }) => {
    return <div style={{ ...elStyle }} id={id} dangerouslySetInnerHTML={createMarkup(html)} />;
};

export default HtmlDisplayComponent;
