import React from 'react';
import { WithStyles, createStyles, withStyles, Typography, Theme } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

interface ErrorPageProps {
    retry?: (e?: any) => void;
    Icon: React.ComponentType<SvgIconProps>;
    headingText?: string;
    subText?: string;
}
const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100vw',
            height: 'calc(100vh - 64px)',
            position: 'relative',
        },
        centerArea: {
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
        },
        icon: {
            height: 200,
            width: 200,
        },
    });
interface ErrorPageComponentProps extends ErrorPageProps, WithStyles<typeof styles> {}
const ErrorPageComponent: React.SFC<ErrorPageComponentProps> = props => {
    const { classes, retry, Icon, headingText, subText } = props;
    return (
        <div className={classes.root}>
            <div className={classes.centerArea}>
                <Icon className={classes.icon} />
                {headingText && (
                    <Typography variant="h3" component="div">
                        {headingText}
                    </Typography>
                )}
                {subText && (
                    <React.Fragment>
                        <br />
                        <Typography>{subText}</Typography>
                    </React.Fragment>
                )}
                <br />
                {retry ? (
                    <button type="reset" onClick={retry}>
                        Retry
                    </button>
                ) : null}
            </div>
        </div>
    );
};
const ErrorPage: React.ComponentType<ErrorPageProps> = (withStyles as any)(styles)(ErrorPageComponent);
export default ErrorPage;
