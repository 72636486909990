import * as pt from './actions';
import { isActionOf } from 'typesafe-actions';
import { RootState } from 'reducers/rootReducer';
import { Epic } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { Services } from 'sideEffect/services';
import { filter, map, catchError, flatMap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

const getPrintTemplatesFlow: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) =>
    action$.pipe(
        filter(isActionOf(pt.getPrintTemplateByName)),
        flatMap(
            (action): Observable<RootAction> =>
                services.getPrintTemplateByName(action.payload.name).pipe(
                    map(resp => pt.getPrintTemplateByNameSuccess(resp, action.payload)),
                    catchError(e => of(pt.getPrintTemplateByNameFailure(e, action.payload))),
                ),
        ),
    );

export default getPrintTemplatesFlow;
