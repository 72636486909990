/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { Button, CardContent, CardActions, Typography, CircularProgress } from '@material-ui/core';
import { AttemptRequestState } from '../../components/AttemptRequest';
import { TaskActionConfirm } from './index';
import { RootState } from '../../reducers/rootReducer';

interface CaseNumberProps {
    appCaseId: string;
}
const caseNumberMapStateToProps = (state: RootState, { appCaseId }: CaseNumberProps) => {
    const appCases = state.admin.entities.AppCase;
    return {
        caseNumber: appCases && appCaseId && appCases[appCaseId] && (appCases[appCaseId] as any).caseNumber, // tslint:disable-line
    };
};
const CaseNumber: React.ComponentType<CaseNumberProps> = connect(caseNumberMapStateToProps)(
    (props: CaseNumberProps & ReturnType<typeof caseNumberMapStateToProps>) => <span>{props.caseNumber}</span>,
);

function renderActionConfirmTitle<T>(
    type: T,
    rowsSelected: number,
    appCaseId: number | string,
    itemName: 'task' | 'note',
) {
    return (
        <Typography gutterBottom={true} variant="h5">
            <span style={{ textTransform: 'capitalize' }}>{type + ' '}</span>
            {rowsSelected} {itemName}
            {rowsSelected > 1 ? 's' : ''} to case <CaseNumber appCaseId={`${appCaseId}`} />?
        </Typography>
    );
}

function createTaskActionConfirmPage<T>(
    dialogOpen: TaskActionConfirm<T>,
    rowsSelected: number | 'NO_TITLE',
    backFromConfirmation: (() => void) | 'NO_BACK_BUTTON',
    closeDialog: () => void,
    itemName: 'task' | 'note',
) {
    return (text: string | JSX.Element, submitButton: JSX.Element | null) => (
        <React.Fragment>
            <CardContent>
                {rowsSelected === 'NO_TITLE'
                    ? null
                    : renderActionConfirmTitle(dialogOpen.type, rowsSelected, dialogOpen.appCaseId, itemName)}
                {text}
            </CardContent>
            <CardActions>
                {backFromConfirmation === 'NO_BACK_BUTTON' ? null : (
                    <Button color="default" variant="outlined" onClick={backFromConfirmation}>
                        Back
                    </Button>
                )}
                <Button color="default" variant="outlined" onClick={closeDialog}>
                    Close
                </Button>
                {submitButton}
            </CardActions>
        </React.Fragment>
    );
}
function getCreateSubmitButton<T>(dialogOpen: TaskActionConfirm<T>, attempt: () => void) {
    return (loading: boolean = false) => (
        <Button
            style={{ marginLeft: 'auto' }}
            variant="contained"
            color={loading ? 'default' : 'primary'}
            disabled={loading}
            onClick={attempt}
        >
            Attempt {dialogOpen.type}{' '}
            {loading ? <CircularProgress style={{ marginLeft: '1em', height: 20, width: 20 }} /> : null}
        </Button>
    );
}

function getFoldFetchState<T>(
    dialogOpen: TaskActionConfirm<T>,
    rowsSelected: number,
    backFromConfirmation: () => void,
    closeDialog: () => void,
    attempt: () => void,
    keepSelectionAfterSuccess: boolean,
    itemName: 'task' | 'note',
) {
    const createPage = createTaskActionConfirmPage(
        dialogOpen,
        rowsSelected,
        backFromConfirmation,
        closeDialog,
        itemName,
    );
    const createFinalPage = keepSelectionAfterSuccess
        ? createPage
        : createTaskActionConfirmPage(dialogOpen, 'NO_TITLE', 'NO_BACK_BUTTON', closeDialog, itemName);
    const createSubmitBtn = getCreateSubmitButton(dialogOpen, attempt);
    return (fetchState: AttemptRequestState) =>
        fetchState._tag === 'failure'
            ? createPage(
                  <div>
                      Action failed.
                      <a href="javascript:;" onClick={attempt}>
                          try again?
                      </a>
                  </div>,
                  createSubmitBtn(),
              )
            : fetchState._tag === 'pending'
            ? createPage(<div>Pending...</div>, createSubmitBtn(true))
            : fetchState._tag === 'success'
            ? createFinalPage(<div>Success!</div>, null)
            : createPage(<div />, createSubmitBtn());
}

export default getFoldFetchState;
