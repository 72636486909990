import {
    VALUE_SET_CONCEPTS_RECEIVED,
    VIEW_CONFIG_RECEIVED,
    VALUE_SET_GROUP_RECEIVED,
    MULTIPLE_VALUE_SETS_RECEIVED,
} from '../actions/constants';
import { mapOption } from 'fp-ts/lib/Array';
import { fromNullable } from 'fp-ts/lib/Option';
import uniq from 'lodash/uniq';
import { ConceptsReceivedAction, ConceptInApp } from '../sagas/valueSetsSaga';
import { MultipleValuesetsReceivedAction } from 'sagas/valueSetMultifetchSaga';
import { CRUD_GET_ONE_SUCCESS } from 'sideEffect/crud/getOne/constants';
import { Concept } from 'fieldFactory/input/components/ValueSelectDownshift';
import produce from 'immer';

export interface ValueSets {
    [valueSetCode: string]: {
        id?: string;
        display?: string;
        invalid: boolean;
        conceptIds: string[];
        groups: {
            [groupName: string]: {
                invalid: boolean;
                ids: string[];
            };
        };
        allConceptsLoaded?: boolean;
    };
}

const getGroups = (
    ids: string[],
    conceptsBag: { [id: string]: ConceptInApp },
    freshGroups:
        | '*'
        | {
              [group: string]: true;
          },
    prevGroups: ValueSets[0]['groups'] = {},
) => {
    return mapOption(ids, id =>
        fromNullable(conceptsBag[id]).chain(concept => fromNullable(concept.group).map(group => [concept.id, group])),
    ).reduce((prev, [id, group]) => {
        const groups = group.split(',');
        return Object.assign(
            {},
            prev,
            ...groups.map(g => {
                const groupCanBeConsideredFullyFetched = freshGroups === '*' || freshGroups[g];
                const accInitial = groupCanBeConsideredFullyFetched || !prevGroups[g] ? [] : prevGroups[g].ids;
                const currentlyAccumulatedIds = [...(prev[g] ? prev[g].ids : accInitial), id];
                return {
                    [g]: {
                        invalid: groupCanBeConsideredFullyFetched
                            ? false
                            : prevGroups[g]
                            ? prevGroups[g].invalid === true
                            : true,
                        ids: currentlyAccumulatedIds,
                    },
                };
            }),
        );
    }, {});
};

export default (previousState: ValueSets = {}, action): ValueSets => {
    if (action.type === MULTIPLE_VALUE_SETS_RECEIVED) {
        const payload: MultipleValuesetsReceivedAction['payload'] = action.payload;

        // object structure representing requested valueset/group combinations
        const requested: {
            [valuesetCode: string]: { [group: string]: true } | true;
        } = (action as MultipleValuesetsReceivedAction).valueSetsFetched.reduce((prev, curr) => {
            const prevEntry = typeof prev[curr.valueSet] === 'object' ? prev[curr.valueSet] : undefined;
            const newValueSetEntry = curr.group
                ? {
                      ...prevEntry,
                      [curr.group]: true,
                  }
                : true;
            return {
                ...prev,
                [curr.valueSet]: newValueSetEntry,
            };
        }, {});
        const newState: ValueSets = Object.assign(
            {},
            previousState,
            payload.response.reduce((accumulated, vs) => {
                const prevVs: Partial<ValueSets[0]> = accumulated[vs.code] || {};
                const prevInvalid = prevVs.invalid;
                const requestedEntry = requested[vs.code];
                return {
                    ...accumulated,
                    [vs.code]: {
                        ...prevVs,
                        id: prevVs.id || vs.id,
                        conceptIds:
                            requestedEntry === true || !prevVs.conceptIds
                                ? vs.concepts.map(c => c.id)
                                : uniq([...prevVs.conceptIds, ...vs.concepts.map(c => c.id)]),
                        groups: {
                            ...(prevVs.groups || {}),
                            ...getGroups(
                                vs.concepts.map(c => c.id),
                                payload.data.entities.Concept,
                                typeof requestedEntry === 'object' // if specific groups were requested
                                    ? requestedEntry // only mark the groups requested as 'valid' in the cache
                                    : '*', // otherwise the whole valueset was requested and all groups are valid
                                prevVs.groups,
                            ),
                        },
                        allConceptsLoaded: requestedEntry === true || (!prevVs.invalid && prevVs.allConceptsLoaded),
                        invalid:
                            requestedEntry === true ? false : typeof prevInvalid === 'boolean' ? prevInvalid : true,
                    },
                };
            }, previousState),
        );
        return newState;
    } else if (action.type === VALUE_SET_CONCEPTS_RECEIVED) {
        const { payload }: ConceptsReceivedAction = action;
        const prevVs = previousState[payload.valueSetCode];
        const prevGroups: ValueSets[0]['groups'] | undefined = prevVs && prevVs.groups;
        const newState: ValueSets = {
            ...previousState,
            [payload.valueSetCode]: {
                ...(previousState[payload.valueSetCode] || {}),
                ...payload.valueSet,
                conceptIds: payload.data.result,
                groups: {
                    ...prevGroups, // old overwritten by new if exists
                    ...getGroups(payload.data.result, payload.data.entities.Concept, '*', prevGroups),
                },
                invalid: false,
                allConceptsLoaded: true,
            },
        };
        return newState;
    } else if (action.type === VALUE_SET_GROUP_RECEIVED) {
        const { payload, requestPayload } = action;
        console.log('VALUE_SET_GROUP_RECEIVED', payload); // tslint:disable-line
        interface ValueSetGroupConcept {
            id: number;
            code: string;
            display: string;
            group: string;
        }
        interface ValueSetGroupResponse {
            id: number;
            code: string;
            display: string;
            concepts: ValueSetGroupConcept[];
        }
        const response: ValueSetGroupResponse = payload.response;
        const prevValueSet = previousState[requestPayload.valueSetCode];
        const newState: ValueSets = {
            ...previousState,
            [requestPayload.valueSetCode]: {
                ...(prevValueSet || {}),
                invalid: prevValueSet ? prevValueSet.invalid : true,
                conceptIds: prevValueSet ? prevValueSet.conceptIds : [],
                groups: {
                    ...(prevValueSet && prevValueSet.groups),
                    [requestPayload.group]: {
                        invalid: false,
                        ids: response.concepts.map(({ id }) => id),
                    },
                },
            },
        };
        return newState;
    } else if (action.type === VIEW_CONFIG_RECEIVED) {
        const newState = Object.assign(
            {},
            ...Object.keys(previousState).map(key => ({
                [key]: {
                    ...previousState[key],
                    groups: Object.assign(
                        {},
                        ...Object.entries(previousState[key].groups || {}).map(([groupName, group]) => ({
                            [groupName]: { ...group, invalid: true },
                        })),
                    ),
                    invalid: true,
                },
            })),
        );

        return newState;
    } else if (
        action.type === CRUD_GET_ONE_SUCCESS &&
        action.payload.data &&
        action.payload.data.entities &&
        action.payload.data.entities.Concept
    ) {
        const valuesetEntriesById = Object.entries(previousState).reduce(
            (prev, [vscode, vs]) => {
                prev[vs.id] = [vscode, vs];
                return prev;
            },
            {} as {
                [id: string]: [string, ValueSets[0]];
            },
        );
        const newConceptsInExistingVs = (Object.values(action.payload.data.entities.Concept) as Concept[]).filter(
            (concept: Concept) => {
                const valuesetEntry = valuesetEntriesById[concept.valueSetId];
                return valuesetEntry && !valuesetEntry[1].conceptIds.includes(concept.id);
            },
        );
        if (newConceptsInExistingVs.length > 0) {
            return produce(previousState, draftState => {
                newConceptsInExistingVs.forEach(c => {
                    const draftVs = draftState[valuesetEntriesById[c.valueSetId][0]];
                    draftVs.conceptIds.push(c.id);
                    if (c.group && draftVs.groups[c.group] && !draftVs.groups[c.group].ids.includes(c.id)) {
                        draftVs.groups[c.group].ids.push(c.id);
                    }
                });
                return draftState;
            });
        }
        return previousState;
    }
    return previousState;
};
