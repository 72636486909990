import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { getView, getDefaultListViewName } from 'components/generics/utils/viewConfigUtils/index';
import { push as pushAction } from 'connected-react-router';
import GenericList from 'components/generics/genericList';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core';
import getRenderer from 'components/generics/genericList/renderList';
import { RootState } from 'reducers/rootReducer';
import { evaluateFilterString } from 'fieldFactory/popovers/PopoverRefInput/evaluteFilterString';
import { DateValidator } from '../../../../util/dateValidator';
import { currentUserProperties } from '../../../../expressions/contextUtils/currentUser';

const styles = (theme: Theme) =>
    createStyles({
        headerCell: {
            position: 'sticky',
            zIndex: 3,
            backgroundColor: theme.palette.background.paper,
            top: 0,
        },
        listResults: {
            position: 'relative',
            overflowY: 'auto',
            maxHeight: 270,
            '@media print': {
                overflowY: 'unset',
                maxHeight: 'unset',
            },
        },
    });

interface EntitySearchWidgetProps {
    entity: string;
    viewName?: string;
    filterConfig?: string;
}
const mapStateToProps = (state: RootState, ownProps: EntitySearchWidgetProps) => ({
    viewConfig: state.viewConfig,
});

const dispatches = {
    push: pushAction,
};
type Dispatches = typeof dispatches;

interface EntitySearchWidgetComponentProps
    extends EntitySearchWidgetProps,
        WithStyles<typeof styles>,
        Dispatches,
        ReturnType<typeof mapStateToProps> {}

interface EntitySearchWidgetState {
    location: { pathname: string; search: string };
}

class EntitySearchWidgetComponent extends React.Component<EntitySearchWidgetComponentProps, EntitySearchWidgetState> {
    static defaultProps = {
        definition: null,
        push: pushAction,
    };
    constructor(props: EntitySearchWidgetComponentProps) {
        super(props);
        this.state = {
            location: {
                pathname: `/${this.props.entity}`,
                search: '',
            },
        };
    }
    getViewName = () => {
        return getDefaultListViewName(this.props.viewConfig, this.props.entity);
    };
    hasAccessToView = () => {
        if (this.props.viewConfig && getView(this.props.viewConfig, this.getViewName())) {
            return true;
        }
        return false;
    };
    render() {
        const { push, classes, entity, viewName, filterConfig } = this.props;
        const result = filterConfig
            ? evaluateFilterString(filterConfig, {
                  ...new DateValidator(this.props.viewConfig.application.dateFormat),
                  ...currentUserProperties(this.props.viewConfig),
              })
            : '';
        const filterObject = result ? JSON.parse(result) : {};
        if (this.props.viewConfig && this.hasAccessToView()) {
            return (
                <div>
                    <GenericList
                        {...{
                            onRowSelect: selectedData => {
                                push(`/${entity}/${selectedData[0].id}/show`);
                            },
                            filter: filterObject,
                            actions: null,
                            hasCreate: false,
                            multiSelectable: false,
                            updateUrlFromFilter: false,
                            viewName: viewName || this.getViewName(),
                            fakePush: location => {
                                this.setState(state => ({ ...state, location }));
                            },
                            location: this.state.location,
                            renderList: getRenderer(classes, {}),
                            resource: entity,
                            name: entity,
                            formId: null,
                            title: '',
                            selectedData: {},
                            showImmediately: true,
                            showFilters: false,
                        }}
                    />
                </div>
            );
        } else {
            return <span>Access is not granted for the desired entity search</span>;
        }
    }
}

const enhance = compose(
    connect(
        mapStateToProps,
        dispatches,
    ),
    withStyles(styles),
);

const EntitySearchWidget: React.ComponentType<EntitySearchWidgetProps> = enhance(EntitySearchWidgetComponent);

export default EntitySearchWidget;
