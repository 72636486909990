import React from 'react';
import { parse } from 'query-string';
import lifecycle from 'recompose/lifecycle';
import GenericList, { GenericListWithDefaultProps } from '../../components/generics/genericList';
import XDate from 'xdate';
import { push as pushAction, RouterState } from 'connected-react-router';
import compose from 'recompose/compose';
import { fromNullable, fromEither } from 'fp-ts/lib/Option';
import { tryCatch } from 'fp-ts/lib/Either';
import { connect } from 'react-redux';
import { Button, CardActions, withStyles, Theme } from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';
import { merge } from '../replaceInLocation';
import { RootState } from '../../reducers/rootReducer';
import { CaseTypeDropdown, AssignedDropdown, StateDropdown } from './components/dropdowns';
import PopoverCreateTask from '../../bpm/components/TaskList/PopoverCreateTask';
import memoizeOne from 'memoize-one';
import { CurrentState } from './types';
import { stringify } from 'querystring';
import getRenderer from '../../components/generics/genericList/renderList';
import { toggleBulkActionsButton } from '../../components/ToggleBulkActionButton';
import { RenderListArguments } from '../../components/generics/genericList/List';
import ProcessSelectActionDialog from '../ProcessSelectActionDialog';
import * as config from '../../config';
import {
    getProcDefFromProcInstId,
    stripDelaySearchFromQueryString,
    getForceDatagridNotReady,
    getTodayISO,
} from '../util';
import { Helmet } from 'react-helmet';
import { getPotentialUsers } from 'bpm/potentialUsers/actions';
import { GetComponentProps } from 'util/typeUtils';
import { getDefaultSort } from 'components/generics/utils/viewConfigUtils';
import { storageController } from 'storage';
import { createSelector } from 'reselect';

const emptyObj = {};
export const createGetPotentialUsersByIdSelector = () =>
    createSelector(
        (state: RootState) => state.bpm.potentialUsers,
        (state: RootState) => state.viewConfig.user,
        (potentialUsers, currentUser) => {
            const byId = potentialUsers.map(pu => pu.byId).getOrElse(emptyObj);
            if (!byId[currentUser.id]) {
                return { ...byId, [currentUser.id]: currentUser };
            }
            return byId;
        },
    );
const makeMapStateToProps = () => {
    const potentialUsersByIdSelector = createGetPotentialUsersByIdSelector();
    return (state: RootState, ownProps) => ({
        users: potentialUsersByIdSelector(state),
    });
};

const ConnectedAssignedDropdown: React.ComponentType<GetComponentProps<typeof AssignedDropdown>> = compose(
    connect(
        makeMapStateToProps,
        {
            getPotentialUsers,
        },
    ),
    lifecycle({
        componentDidMount() {
            this.props.getPotentialUsers();
        },
    }),
)(AssignedDropdown);

const getLazyR = (type: 'copy' | 'move') => (appCaseId: number | string, selectedData: {}) => () =>
    fetch(`${config.BACKEND_BASE_URL}api/bpm/app-cases/${appCaseId}/${type}-tasks `, {
        method: type === 'copy' ? 'POST' : 'PUT',
        body: JSON.stringify({
            taskIds: Object.keys(selectedData),
        }),
        credentials: 'same-origin',
        headers: new Headers({
            Authorization: `Bearer ${storageController.getToken()}`,
            Cookie: `${window.document.cookie}`,
            Accept: 'application/json',
            'Content-type': 'application/json',
        }),
    });

const styles = (theme: Theme) =>
    ({
        headerCell: {
            position: 'sticky',
            zIndex: 3,
            backgroundColor: theme.palette.background.paper,
            top: 0,
        },
        listResults: {
            paddingLeft: 16,
            paddingRight: 16,
            position: 'relative',
            /*
            overflowY: 'auto',
            overflowX: 'auto',
            maxHeight: 380,
            '@media print': {
                overflowX: 'unset',
                overflowY: 'unset',
                maxHeight: 'unset',
            },
            */
        },
    } as const);

const getViewName = () => '_TASK_LIST';
interface TaskFilter {}

interface TaskListProps {
    overrideViewName?: string;
    lastProcessSearch?: string;
    fakePush?: (search: string) => void;
    redirect: (newLocation: string) => void;
    readOnly?: boolean;
    processId?: string;
    businessKey?: string | null;
    location: RouterState['location'];
    changeProcessDefinitionKey: (pdk: string) => void;
    changeAssignee: (assignee: string) => void;
    changeState: (state: CurrentState) => void;
    mergeFilterIntoLocation: (filter: TaskFilter) => void;
    bulkActionsOpen: boolean;
    toggleBulkActions?: () => void;
}

interface TaskListState {
    selectedData: null | {};
}

interface TaskListComponentProps extends TaskListProps {
    classes: {
        [k in keyof ReturnType<typeof styles>]: string;
    };
}

const currentFilterContext: React.Context<{}> = React.createContext<{}>({});

class TaskList extends React.Component<TaskListComponentProps, TaskListState> {
    _getFilterFromLocation = memoizeOne(search => fromNullable((parse(search) || {}).filter).map(JSON.parse));
    _getLocationWithoutDelaySearch = memoizeOne(({ search, pathname }) => ({
        pathname,
        search: stripDelaySearchFromQueryString(search),
    }));
    constructor(props: TaskListComponentProps) {
        super(props);
        this.state = {
            selectedData: this.props.bulkActionsOpen ? {} : null,
        };
    }

    componentWillReceiveProps(nextProps: TaskListComponentProps) {
        if (nextProps.bulkActionsOpen && !this.props.bulkActionsOpen && !this.state.selectedData) {
            this.setState({
                selectedData: {},
            });
        }
        if (!nextProps.bulkActionsOpen && this.props.bulkActionsOpen && this.state.selectedData) {
            this.setState({
                selectedData: null,
            });
        }
    }
    getProcessDefinitionKey = (props = this.props) =>
        this._getFilterFromLocation(props.location.search).chain(filter =>
            fromNullable(filter['processInstance.businessKey']),
        );
    getAssignee = (props = this.props) =>
        this._getFilterFromLocation(props.location.search).chain(filter => fromNullable(filter['assignee.id']));
    getTaskName = (props = this.props) =>
        this._getFilterFromLocation(props.location.search).chain(filter => fromNullable(filter.name));

    getState = (props = this.props) =>
        this._getFilterFromLocation(props.location.search).fold<CurrentState>('All', filter =>
            fromNullable(filter.endTime__NOT_EMPTY)
                .map((isEnded: boolean) =>
                    isEnded
                        ? 'Closed'
                        : fromNullable(filter.dueDate__LESS)
                              .chain(lt => fromEither(tryCatch(() => new XDate(lt))))
                              .fold('Open', lt => {
                                  const diffDays = lt.diffDays(new XDate());
                                  return diffDays > 0 ? 'Overdue' : 'Open';
                              }),
                )
                .fold('All', res => res),
        );
    clearSelectedData = () => {
        this.setState({
            selectedData: {},
        });
    };
    renderFilter: GenericListWithDefaultProps['renderFilter'] = ({ defaultRenderer, ...renderFilterArgs }) => {
        const props = this.props;
        return (
            <div style={{ width: '100%' }}>
                <div style={{ display: 'inline-flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
                    <div
                        style={{
                            marginLeft: 19,
                            marginTop: 26,
                            marginRight: 18,
                            fontWeight: 'bold',
                            fontSize: 15,
                        }}
                    >
                        Search
                    </div>
                    <div>
                        <div
                            style={{
                                display: 'inline-flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                alignItems: 'center',
                            }}
                        >
                            {props.processId ? null : (
                                <React.Fragment>
                                    <CaseTypeDropdown
                                        processDefinitionKey={this.getProcessDefinitionKey().toNullable()}
                                        onChange={props.changeProcessDefinitionKey}
                                    />
                                    <span key="divider0" style={{ width: 32 }} />
                                    <ConnectedAssignedDropdown
                                        assigneeId={this.getAssignee().toNullable()}
                                        onChange={props.changeAssignee}
                                    />
                                </React.Fragment>
                            )}
                            <span key="divider1" style={{ width: 32 }} />
                            <StateDropdown currentState={this.getState()} onChange={props.changeState} />
                        </div>
                    </div>
                </div>
                <div style={{ paddingLeft: 6, paddingBottom: 6 }}>
                    <currentFilterContext.Consumer>
                        {f =>
                            defaultRenderer({
                                ...renderFilterArgs,
                                filterValues: f,
                                displayHeader: false,
                            })
                        }
                    </currentFilterContext.Consumer>
                </div>
            </div>
        );
    };
    rowsSelected = () => Object.keys(this.state.selectedData || {}).length;
    someDataSelected = () => this.rowsSelected() > 0;
    render() {
        const props = this.props;
        const { selectedData } = this.state;
        return (
            <currentFilterContext.Provider
                key={getForceDatagridNotReady(this.props.location.search) ? 'NR' : ''}
                value={this._getFilterFromLocation(this.props.location.search).getOrElse(undefined)}
            >
                <Helmet>
                    <title>Search Tasks</title>
                </Helmet>
                <ProcessSelectActionDialog<'copy' | 'move'>
                    disableCaseTypeChange={true}
                    itemName="task"
                    initialSearch={
                        props.businessKey
                            ? `?filter=%7B"assignee_ANY"%3Atrue%2C"processInstance.businessKey"%3A"${props.businessKey}"%7D`
                            : undefined
                    }
                    getLazyRequests={{
                        copy: getLazyR('copy'),
                        move: getLazyR('move'),
                    }}
                    selectedData={selectedData}
                    onSuccess={this.clearSelectedData}
                    selectedDataStaysOnSuccess={false}
                    render={({ dataKey, getOpenPSDialog, closePSDialog }) => (
                        <GenericList
                            key={dataKey}
                            forceDatagridNotReady={getForceDatagridNotReady(this.props.location.search)}
                            hasCreate={false}
                            renderActions={actionProps => (
                                <CardActions style={{ zIndex: 2, display: 'inline-block', float: 'right' }}>
                                    {props.toggleBulkActions
                                        ? toggleBulkActionsButton(
                                              props.toggleBulkActions,
                                              props.bulkActionsOpen,
                                              this.someDataSelected(),
                                          )
                                        : null}
                                    {props.bulkActionsOpen && this.someDataSelected() ? (
                                        <React.Fragment>
                                            <Button variant="text" color="primary" onClick={getOpenPSDialog('move')}>
                                                Move ({this.rowsSelected()})
                                            </Button>
                                            <Button variant="text" color="primary" onClick={getOpenPSDialog('copy')}>
                                                Copy ({this.rowsSelected()})
                                            </Button>
                                        </React.Fragment>
                                    ) : null}
                                    <PopoverCreateTask processId={props.processId || null} />
                                    {!props.readOnly && props.processId ? (
                                        <Button
                                            variant="text"
                                            color="primary"
                                            onClick={() => props.redirect(`/processes${props.lastProcessSearch || ''}`)}
                                        >
                                            Close
                                            <Clear />
                                        </Button>
                                    ) : null}
                                </CardActions>
                            )}
                            updateUrlFromFilter={!props.fakePush}
                            fakePush={props.fakePush}
                            onRowSelect={([task]: { processInstanceId?: string; id: string }[]) => {
                                props.redirect(
                                    task.processInstanceId
                                        ? `/processes/${task.processInstanceId}/tasks/${task.id}/start`
                                        : `/tasks/${task.id}`,
                                );
                            }}
                            multiSelectable={this.props.bulkActionsOpen}
                            isPopover={false}
                            renderFilter={this.renderFilter}
                            {...props}
                            location={this._getLocationWithoutDelaySearch(this.props.location)}
                            renderList={(r1: RenderListArguments) =>
                                getRenderer(
                                    {
                                        root: props.classes.listResults,
                                        headerCell: props.classes.headerCell,
                                    },
                                    {},
                                )({
                                    ...r1,
                                    onRowSelectBulk: this.props.bulkActionsOpen
                                        ? (selected, allData) => {
                                              this.setState({
                                                  selectedData: Object.assign(
                                                      {},
                                                      ...selected.map(data => ({ [data.id]: data })),
                                                  ),
                                              });
                                          }
                                        : undefined,
                                    isBulkSelectableRecord: (record: { endTime?: string }) => !!record.endTime,
                                })
                            }
                            selectedData={this.state.selectedData || undefined}
                            title={'Task Summary'}
                            resource={'TaskInstance'}
                            viewName={props.overrideViewName || getViewName()}
                            formId={props.overrideViewName || getViewName()}
                            perPage={'25'}
                        />
                    )}
                />
            </currentFilterContext.Provider>
        );
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    const mergeFilterIntoLocation = (filter: TaskFilter) => {
        const { location, fakePush } = ownProps;
        const newSearch = merge(
            stripDelaySearchFromQueryString(location.search),
            filter,
            /* ['processInstance.businessKey', 'endTime__NOT_EMPTY', 'dueDate__LESS', 'processInstance.id',
             'assignee.login', 'name'],
            true */
            undefined,
            'STRIP_PAGE',
        );
        // if this is a controlled component, call the handler
        if (fakePush) {
            fakePush(newSearch);
        } else {
            // otherwise we are storing our search state in the window location
            dispatch(pushAction(newSearch));
        }
    };
    return {
        redirect: (newLocation: string) => dispatch(pushAction(newLocation)),
        changeProcessDefinitionKey: (pdKey: string) =>
            mergeFilterIntoLocation({
                'processInstance.businessKey': pdKey,
            }),
        changeAssignee: (assigneeId: string) =>
            mergeFilterIntoLocation({
                'assignee.id': assigneeId,
            }),
        changeState: (state: CurrentState) => {
            let filter;
            switch (state) {
                case 'Closed':
                    filter = { endTime__NOT_EMPTY: true, dueDate__LESS: undefined };
                    break;
                case 'Open':
                    filter = { endTime__NOT_EMPTY: false, dueDate__LESS: undefined };
                    break;
                case 'Overdue':
                    filter = {
                        endTime__NOT_EMPTY: false,
                        dueDate__LESS: getTodayISO(),
                    };
                    break;
                default:
                    filter = {
                        endTime__NOT_EMPTY: undefined,
                        dueDate__LESS: undefined,
                    };
            }
            mergeFilterIntoLocation(filter);
        },
    };
};

const enhance = compose(
    connect(
        (state: RootState, ownProps) => ({
            lastProcessSearch: state.bpm.currentProcessSearch.query,
        }),
        mapDispatchToProps,
    ),
    withStyles(styles),
);
const WindowTaskList = enhance(TaskList);
export default WindowTaskList;

interface ContainedTaskListProps {
    readOnly?: boolean;
    processId?: string;
    requireProcessId: boolean;
    overrideViewName?: string;
    initialSearch?: string | null;
}
interface ContainedTaskListState {
    search: string;
    showBulkActions: boolean;
}

const mapStateToProps = (state: RootState, ownProps: ContainedTaskListProps) => {
    const processDefinition = getProcDefFromProcInstId(state, ownProps.processId);
    const isAdmin = processDefinition.fold(false, pd => !!pd.adminUser);
    const initialSort = fromNullable(state.viewConfig)
        .mapNullable(vc => getDefaultSort(vc, '_TASK_LIST_FOR_PROCESS', 'ASC'))
        .getOrElse({ field: 'endTime', order: 'ASC' });

    return {
        isAdmin,
        processDefinition: processDefinition.fold(null, pd => pd),
        initialSort,
    };
};

interface ContainedTaskListComponentProps extends ContainedTaskListProps, ReturnType<typeof mapStateToProps> {}

const createContainedLocation = (processId, search) => {
    if (!processId) {
        return {
            search,
        };
    }
    return {
        search: merge(search, {
            'processInstance.id': processId,
        }),
    };
};
class ContainedTaskListComponent extends React.Component<ContainedTaskListComponentProps, ContainedTaskListState> {
    memoizedCreateContainedLocation = memoizeOne(createContainedLocation);
    constructor(props: ContainedTaskListComponentProps) {
        super(props);
        this.state = {
            search:
                this.props.initialSearch ||
                `?${stringify({
                    sort: this.props.initialSort.field,
                    order: this.props.initialSort.order,
                })}`,
            showBulkActions: false,
        };
    }
    fakePush = (search: string | { search: string }) => {
        this.setState(typeof search === 'string' ? { search } : search);
    };
    toggleBulkActions = () => {
        this.setState(state => ({
            ...state,
            showBulkActions: !state.showBulkActions,
        }));
    };
    render() {
        const { readOnly, processId, requireProcessId, overrideViewName, isAdmin, processDefinition } = this.props;
        const { showBulkActions } = this.state;
        if (!processId && requireProcessId) {
            return <div>Loading...</div>;
        }
        return (
            <WindowTaskList
                location={this.memoizedCreateContainedLocation(processId, this.state.search)}
                fakePush={this.fakePush}
                overrideViewName={overrideViewName}
                readOnly={readOnly}
                processId={processId}
                businessKey={processDefinition && processDefinition.key}
                bulkActionsOpen={showBulkActions}
                toggleBulkActions={isAdmin && this.toggleBulkActions}
            />
        );
    }
}

export const ContainedTaskList: React.ComponentType<ContainedTaskListProps> = connect(mapStateToProps)(
    ContainedTaskListComponent,
);
