import React, { useCallback } from 'react';
import { TaskForm } from 'reducers/rootReducer';
import { isTableFieldErrorMessage, createErrorTableFromMessages } from 'fieldFactory/input/components/EditableTable';
import { FormFieldUnion, TableFormField } from 'fieldFactory/translation/fromFlowable/types';
import trimEnd from 'lodash/trimEnd';

export const isTableField = (f: FormFieldUnion): f is TableFormField => {
    return f && f.type === 'table';
};

export const stripAsteriskFromLabel = label => trimEnd(`${label}`, ' *');

interface TaskFormErrorLIsProps {
    formErrors: {
        [field: string]: string[];
    };
    formDefinition: TaskForm;
    classes?: {
        li?: string;
    };
}
const TaskFormErrorLIs = ({ formErrors, formDefinition, classes = {} }: TaskFormErrorLIsProps) => {
    const getFieldsById = useCallback(() => {
        if (!formDefinition) {
            return {};
        }
        return (formDefinition.fields || []).reduce((prev, curr) => {
            return {
                ...prev,
                [curr.id]: curr,
            };
        }, {});
    }, [formDefinition]);
    return (
        <React.Fragment>
            {Object.entries(formErrors).map(([k, e]: [string, string[]]) => {
                // lookup label here and use instead of key below
                const formDefinitionFields = getFieldsById();
                const f = formDefinitionFields[k];

                let message: string[] | string | JSX.Element = Array.isArray(e) ? e.join(',') : e;

                const messageIsTopLevelOnly = e.length === 1 && !isTableFieldErrorMessage(e[0]);
                if (f && isTableField(f) && !messageIsTopLevelOnly) {
                    const fieldMessages = createErrorTableFromMessages(e.filter(isTableFieldErrorMessage));
                    const fieldMessageEntries = Object.entries(fieldMessages);
                    message = (
                        <div>
                            {fieldMessageEntries.map(([row, fieldErrors], i) => {
                                return (
                                    <div key={row}>
                                        {`Row ${(typeof row === 'string' ? parseInt(row, 10) : row) + 1}: `}
                                        <div>
                                            {Object.entries(fieldErrors).map(([fieldId, errorMessages]) => {
                                                const columnDefinition = f.params.columnObj.find(c => c.id === fieldId);
                                                const label = columnDefinition ? columnDefinition.name : fieldId;
                                                return (
                                                    <div key={fieldId}>{`${stripAsteriskFromLabel(
                                                        label,
                                                    )}: ${errorMessages.join('\n')}`}</div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    );
                }
                return (
                    <li key={k} className={classes.li}>
                        {f && f.name && f.name.trim() ? `${stripAsteriskFromLabel(f.name)}: ` : ''}
                        {message}
                    </li>
                );
            })}
        </React.Fragment>
    );
};

export default TaskFormErrorLIs;
