// crud
export * from './crudTypes';

// auth

export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_CHECK = 'AUTH_CHECK';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

// auth actions

export const USER_LOGIN = 'AOR/USER_LOGIN';
export const USER_LOGIN_LOADING = 'AOR/USER_LOGIN_LOADING';
export const USER_LOGIN_FAILURE = 'AOR/USER_LOGIN_FAILURE';
export const USER_LOGIN_SUCCESS = 'AOR/USER_LOGIN_SUCCESS';
export const USER_CHECK = 'AOR/USER_CHECK';
export const USER_LOGOUT = 'AOR/USER_LOGOUT';

// fetch actions

export const FETCH_START = 'AOR/FETCH_START';
export const FETCH_END = 'AOR/FETCH_END';
export const FETCH_ERROR = 'AOR/FETCH_ERROR';
export const FETCH_CANCEL = 'AOR/FETCH_CANCEL';
