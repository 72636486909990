import React, { ReactElement, useRef, useEffect, useState, useCallback, useMemo } from 'react';
import { fromPredicate } from 'fp-ts/lib/Option';
import { Responsive } from 'react-grid-layout';
import ReactResizeDetector from 'react-resize-detector';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import fromEntries from 'fromentries';
import uniq from 'lodash/uniq';
import deepEql from 'deep-eql';
import get from 'lodash/get';
import { correctLayout, compactLeft, removeSpaceBetweenRows } from './correctLayout';
import { Button, Card, CardActions } from '@material-ui/core';

interface MovableGridProps {
    columnStartsAt: 1 | 0;
    fields?: ReactElement<{
        row?: number;
        column?: number;
        span?: number;
        source?: number;
    }>[];
    onLayoutChange?: (args: { layout: Layout }) => void;
}
const getRowKey = (row, defaultValue = -1) =>
    fromPredicate((n: number) => !isNaN(n))(parseInt(row, 10)).getOrElse(defaultValue);

export type Layout = {
    i: string;
    x: number;
    y: number;
    w: number;
    h?: number;
    minW?: number;
    maxW?: number;
}[];
const getLayoutFromFields = (
    fields: ReactElement<{
        row?: number;
        column?: number;
        span?: number;
        source?: number;
    }>[],
    options: {
        columnStartsAt: 0 | 1;
    },
): Layout => {
    /*
        get the number of unique rows (including 'no row' as unique))
        that number is the row index of 'no rows',
        order the rest.
    */
    const fieldsPerRow = fields
        .map(f => f.props.row)
        .reduce(
            (prev, curr) => {
                const currRowKey = getRowKey(curr);

                if (typeof prev[currRowKey] === 'number') {
                    prev[currRowKey] += 1;
                } else {
                    prev[currRowKey] = 1;
                }

                return prev;
            },
            {} as {
                [row: number]: number;
            },
        );

    const fieldOrderingsPerRow: {
        [row: number]: {
            [fieldIndex: number]: {
                index: number;
                column: number;
                overflowedRows: number;
                span: number;
            };
        };
    } = fromEntries(
        Object.entries(groupBy(fields.map((f, i) => [f, i] as const), t => parseInt(get(t, '[0].props.row'), 10))).map(
            ([row, fieldIndexes]) => {
                return [
                    row,
                    sortBy(fieldIndexes, t => parseInt(get(t, '[0].props.column'), 10)).reduce(
                        (prev, [currField, i]) => {
                            const accumulatedSpans = Object.values(prev).reduce((_prev, { span = 4 }) => {
                                return span + _prev;
                            }, 0);
                            const overflowedRows = Math.floor(accumulatedSpans / 12);
                            prev[i] = {
                                index: i,
                                column: accumulatedSpans % 12,
                                overflowedRows,
                                span: currField.props.span,
                            };
                            return prev;
                        },
                        {} as {
                            [fieldIndex: number]: {
                                index: number;
                                column: number;
                                overflowedRows: number;
                                span: number;
                            };
                        },
                    ),
                ] as const;
            },
        ),
    );
    /*
    TODO: Put those without rows to the bottom.
    */

    const il = fields.map((f, index) => {
        const w = f.props.span || 12;
        return {
            i: `${index}`,
            x: fieldOrderingsPerRow[parseInt(f.props.row as any, 10)][index].column, // getRowKey((f.props.column - 1) * 4, options.columnStartsAt),
            y:
                getRowKey(
                    f.props.row,
                    Object.keys(fieldsPerRow).reduce((prev, curr) => {
                        return Math.max(prev, getRowKey(curr));
                    }, 0) + 1,
                ) + fieldOrderingsPerRow[parseInt(f.props.row as any, 10)][index].overflowedRows,
            w,
            h: 1, // Maybe read in from textArea height config? Or don't provide?
        };
    });
    const rows = uniq(il.map(e => e.y)).sort((a, b) => a - b);
    rows.forEach((r, i) => {
        il.filter(e => e.y === r).forEach(e => {
            e.y = i;
        });
    });
    return il;
};

const MovableGrid: React.SFC<MovableGridProps> = props => {
    const { onLayoutChange, columnStartsAt } = props;
    const itemRefs = useRef<Array<HTMLDivElement | null>>([]);
    const [heightIsCalculated, setHeightIsCalculated] = useState(true);
    const initialLayout = useMemo(() => {
        return getLayoutFromFields(props.fields, {
            columnStartsAt,
        });
    }, [props.fields, columnStartsAt]);
    const [layout, setLayout] = useState(initialLayout);
    const entitiesAreLoading = useSelector((state: RootState) => state.admin.loading > 0);
    const layouts = useMemo(
        () => ({
            lg: layout,
        }),
        [layout],
    );
    useEffect(() => {
        if (entitiesAreLoading) {
            return;
        }
        setLayout(getLayoutFromFields(props.fields, { columnStartsAt }));
        const to = setTimeout(() => {
            setHeightIsCalculated(false);
        }, 100);
        return () => {
            clearTimeout(to);
        };
    }, [props.fields, entitiesAreLoading, columnStartsAt]);
    useEffect(() => {
        if (!heightIsCalculated) {
            const to = setTimeout(() => {
                // wait for render, then set heights based on calculated element heights.
                setLayout(layout => {
                    const l = layout.map(l => {
                        const widgetElem = itemRefs.current[parseInt(l.i, 10)].firstElementChild as HTMLDivElement;
                        const offsetHeight = widgetElem.offsetHeight;
                        const h = Math.ceil(offsetHeight / 67.0);
                        return {
                            ...l,
                            h,
                            isResizeable: false,
                        };
                    });
                    return l;
                });
                setHeightIsCalculated(true);
            }, 500);
            return () => {
                clearTimeout(to);
            };
        }
    }, [heightIsCalculated]);
    const propagateLayout = useCallback(() => {
        onLayoutChange({
            layout,
        });
    }, [layout, onLayoutChange]);

    const setLayoutHandler = useCallback(
        _layout => {
            const correctedLayout = correctLayout(compactLeft(removeSpaceBetweenRows(_layout)));
            if (!deepEql(correctedLayout, layout)) {
                setLayout(correctedLayout);
            }
        },
        [setLayout, layout],
    );
    const fieldElements = useMemo(() => {
        return props.fields.map((f, index) => {
            return (
                <div
                    key={`${index}`}
                    data-index={`${index}`}
                    ref={ref => {
                        itemRefs.current.push(ref);
                    }}
                >
                    {f}
                    {/* <div style={{ position: 'relative' }}>
                        <div style={{ position: 'absolute', left: 0, top: 0 }}>
                            i: {index}
                            Row: {f.props.row}
                            Col: {f.props.column}
                            Span: {f.props.span}
                            y: {layout[index].y}
                        </div>
                    </div> */}
                </div>
            );
        });
    }, [props.fields]);
    return (
        <Card>
            <ReactResizeDetector handleWidth>
                {({ width }) => (
                    <div>
                        <div
                            style={{
                                width: '150px',
                                background: '#ddd',
                                border: '1px solid black',
                                marginTop: '10px',
                                padding: '10px',
                            }}
                            draggable={true}
                            unselectable="on"
                            // this is a hack for firefox
                            // Firefox requires some kind of initialization
                            // which we can do by adding this attribute
                            // @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
                            onDragStart={e => e.dataTransfer.setData('text/plain', '')}
                        >
                            Droppable Element
                        </div>
                        <Responsive
                            compactType={null}
                            breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                            cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
                            width={width}
                            rowHeight={67}
                            className="layout"
                            onDrop={e => {
                                console.log('foo');
                            }}
                            layouts={layouts}
                            margin={[5, 10]}
                            onLayoutChange={setLayoutHandler}
                            isDroppable={true}
                        >
                            {fieldElements}
                        </Responsive>
                    </div>
                )}
            </ReactResizeDetector>
            <CardActions>
                <Button variant="contained" color="primary" onClick={propagateLayout}>
                    Apply layout
                </Button>
            </CardActions>
        </Card>
    );
};
export default MovableGrid;
