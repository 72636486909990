import { parse } from 'querystring';
import ViewConfig from 'reducers/ViewConfigType';
import { fromPredicate } from 'fp-ts/lib/Option';
import uniq from 'lodash/uniq';
import isPlainObject from 'lodash/isPlainObject';
import { getIsExpensiveForFieldExpr } from 'components/generics/utils/viewConfigUtils';

const getExpansions = (restUrl: string) =>
    fromPredicate<string>(Boolean)(restUrl.slice(restUrl.indexOf('?') + 1))
        .map(searchQuery => parse(searchQuery).expand)
        .chain(fromPredicate<string | string[]>(Boolean))
        .map((expansion: string | string[]): string[] => {
            if (Array.isArray(expansion)) {
                return expansion.flatMap(e => e.split(','));
            }
            return expansion.split(',');
        })
        .getOrElse([]);
const getExpensiveCalcsOnEntity = (viewConfig: ViewConfig, resource: string) => {
    return Object.values(viewConfig.entities[resource].fields)
        .filter(f => getIsExpensiveForFieldExpr(viewConfig, resource, f.name, 'TRAVERSE_PATH'))
        .map(f => f.name);
};
export type RecursiveEntity = {
    entityType: string;
    id: string;
    [field: string]: RecursiveEntity | string | number | Date | string[] | number[] | RecursiveEntity[];
};
/*
    The reason to use getExpensiveCalcsNotInExpansion instead of 'getExpensiveCalcsNotExpandedInUrl' is
    because here we get the returned entityTypes which are not always known before the request comes back.
*/
export const getExpensiveCalcsNotInExpansion = (
    expansions: string[], // expansion not including the current level
    viewConfig: ViewConfig,
    recursiveEntity: RecursiveEntity,
): string[] => {
    const getExpansionsAfter = (field: string) =>
        expansions.filter(e => e.startsWith(field)).map(e => e.slice(0, field.length));
    const expensiveCalcsAtCurrentLevelNotInExpansion = getExpensiveCalcsOnEntity(
        viewConfig,
        recursiveEntity.entityType,
    ).filter(calcField => !expansions.includes(calcField));
    const nestedExpensiveCalcsNotInExpansion = Object.entries(recursiveEntity).flatMap(([field, value]) => {
        if (isPlainObject(value) && value['entityType'] && value['id']) {
            // entity-1
            return getExpensiveCalcsNotInExpansion(getExpansionsAfter(field), viewConfig, value as RecursiveEntity).map(
                pathToCalc => `${field}.${pathToCalc}`,
            );
        } else if (Array.isArray(value) && value.every(e => isPlainObject(e) && e.entityType && e.id)) {
            // entity array
            return (value as RecursiveEntity[]).flatMap(v =>
                getExpensiveCalcsNotInExpansion(getExpansionsAfter(field), viewConfig, v as RecursiveEntity).map(
                    pathToCalc => `${field}.${pathToCalc}`,
                ),
            );
        }
        return [];
    });
    return uniq([...expensiveCalcsAtCurrentLevelNotInExpansion, ...nestedExpensiveCalcsNotInExpansion]);
};

export const getExpensiveCalcsNotInUrlExpansion = (
    restUrl: string,
    viewConfig: ViewConfig,
    recursiveEntity: RecursiveEntity | RecursiveEntity[],
) => {
    const expansions = getExpansions(restUrl);
    if (Array.isArray(recursiveEntity)) {
        return uniq(recursiveEntity.flatMap(e => getExpensiveCalcsNotInExpansion(expansions, viewConfig, e)));
    }
    return getExpensiveCalcsNotInExpansion(expansions, viewConfig, recursiveEntity);
};
