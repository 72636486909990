import React from 'react';
export const refreshContext = React.createContext((() => null) as (e?: Event) => void);
export const { Provider, Consumer } = refreshContext;

export const withRefreshContext = BaseComponent => {
    return function ComponentWithRefresh(props) {
        // tslint:disable-line
        // ... and renders the wrapped component with the context theme!
        // Notice that we pass through any additional props as well
        return <Consumer>{refresh => <BaseComponent {...props} refresh={refresh} />}</Consumer>;
    };
};
