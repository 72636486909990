import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { createSelector } from 'reselect';
import ViewConfig from 'reducers/ViewConfigType';

interface WithFieldPermissionsProps {
    resource: string;
    children: (fieldPermissions: null | { [field: string]: number }) => JSX.Element | null;
}

const createGetEntity = <T extends { resource: string }>() =>
    createSelector(
        (state: { viewConfig: ViewConfig }, props: T) => props.resource,
        (state: { viewConfig: ViewConfig }, props: T) => state.viewConfig.entities,
        (resource, entities) => entities[resource],
    );
export const createGetAllFieldPermissionsOnEntity = () => {
    const getEntity = createGetEntity();
    return createSelector(
        getEntity,
        entity => {
            if (entity) {
                let fieldAccessLevels: { [field: string]: number } = {};
                Object.entries(entity.fields).forEach(([k, f]) => {
                    fieldAccessLevels[k] = f.accessLevel;
                });
                return fieldAccessLevels;
            }
            return null;
        },
    );
};

const makeMapStateToProps = () => {
    const getAddressPermissions = createGetAllFieldPermissionsOnEntity();
    return (state: RootState, props: WithFieldPermissionsProps) => {
        return {
            fieldPermissions: getAddressPermissions(state, props),
        };
    };
};
interface WithFieldPermissionsComponentProps
    extends WithFieldPermissionsProps,
        ReturnType<ReturnType<typeof makeMapStateToProps>> {}
const WithFieldPermissionsComponent: React.SFC<WithFieldPermissionsComponentProps> = props =>
    props.children(props.fieldPermissions);
const WithFieldPermissions: React.ComponentType<WithFieldPermissionsProps> = connect(makeMapStateToProps)(
    WithFieldPermissionsComponent,
);

export const withFieldPermissions = (resource?: string) => BaseComponent => <T extends { resource?: string }>(
    props: T,
) => (
    <WithFieldPermissions resource={resource || props.resource}>
        {fp => <BaseComponent {...props} fieldPermissions={fp} />}
    </WithFieldPermissions>
);
export default WithFieldPermissions;
