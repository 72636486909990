import { createSelector } from 'reselect';
import { RootState } from '../../../../reducers/rootReducer';
import getAllFieldsExpected, { getAdhocFieldsForView, EXTERNALGISID } from './util/getAllFieldsExpected';
import createRecordSelector from './util/recordSelector';
import forceBooleanFieldsBoolean from './util/enforceBoolValues';
import { getAllValuesetFields, getExpressions } from './util/entityVisExp';
import createFormContext from './util/createFormContext';
import { createGetEntities, createGetValueSets } from './util/getEntities';
import createDeepEqlSelector from './util/createDeepEqlSelector';
import mapFieldsToWidgets from './util/mapFieldsToWidgets';
import { CasetivityViewContext } from 'util/casetivityViewContext';
import getProviderAndHocWithViewContext from './util/getProviderAndHocWithViewContext';
import { EntityVisibilityExps } from 'reducers/entityVisibilityReducer';
import { FormContextEvaluator } from 'expressions/CachingEvaluator/FormContextEvaluator';

export interface FCPProps {
    viewName: string;
    record: { id: string; entityType: string };
    viewContext: CasetivityViewContext;
    formId?: undefined;
    overrides?: {
        visibilityExps?: EntityVisibilityExps[0];
    };
}

const emptyObj = {};
const createFormContextSelector = () => {
    const getEntities = createGetEntities();
    const recordSelector = createRecordSelector();
    const getValueSets = createGetValueSets();
    const formContextEvaluatorSelector = createSelector(
        (state: RootState, props: FCPProps): EntityVisibilityExps[0] =>
            (props.overrides && props.overrides.visibilityExps) || state.entityVisibility[props.viewName] || emptyObj,
        (state: RootState, props: FCPProps) => state.viewConfig,
        (state: RootState, props: FCPProps) => props.viewName,
        (state: RootState, props: FCPProps) => props.viewContext,
        (visConfig, viewConfig, viewName, viewContext) => {
            const allFieldsExpected = getAllFieldsExpected(viewConfig, viewName, {}, getAdhocFieldsForView);
            const fieldsToWidgets = mapFieldsToWidgets(viewConfig, viewName, allFieldsExpected);
            const allValuesetFields = getAllValuesetFields(visConfig, {}, {}, {});
            const visibilityExpressions = getExpressions(visConfig);
            return new FormContextEvaluator({
                basedOnEntityOptions: null,
                evaluationFactors: {
                    fieldWidgets: fieldsToWidgets,
                    dropdownAvailableOptionsExpressions: {},
                    valueset1AvailableConceptsExpressions: {},
                    valueset1Fields: allValuesetFields,
                    visibilityExpressions,
                    editabilityExpressions: {},
                    tableExpressions: {},
                },
                options: {
                    viewContext,
                    dateFormat: (viewConfig && viewConfig.application && viewConfig.application.dateFormat) || '',
                },
                useBackingValuesRegardlessOfDisplayStatus: {
                    [EXTERNALGISID]: true,
                },
                viewConfig,
            });
        },
    );
    const formContextSelector = createSelector(
        formContextEvaluatorSelector,
        (state: RootState, props: FCPProps) => recordSelector(state, props),
        getEntities,
        getValueSets,
        (state: RootState, props: FCPProps) => state.viewConfig,
        (state: RootState, props: FCPProps) => props.viewName,
        (formContextEvaluator, values: {}, entities: {}, valueSets, viewConfig, viewName) => {
            const valuesToUse = forceBooleanFieldsBoolean(viewConfig, viewName)(values);
            const { hiddenFields, fieldValues } = formContextEvaluator.evaluate(
                valuesToUse,
                valueSets,
                valuesToUse,
                entities,
            );
            return { hiddenFields, fieldValues, viewName };
        },
    );
    return createDeepEqlSelector(formContextSelector);
};

const defaultContext = { hiddenFields: {}, fieldValues: {}, viewName: undefined };

const { formContext, FormContextProvider: FCP } = createFormContext(createFormContextSelector, defaultContext);

const { FormContextProvider, formContextHoc } = getProviderAndHocWithViewContext(FCP);
export { formContext, formContextHoc, FormContextProvider };
