import { put } from 'redux-saga/effects';
import { taskEventType as taskEvent } from '../../actions/taskEvent';
import sagaFactory, { configType, HttpVerb } from '../../../sagas/util/sagaFactory';

const config: configType = {
    actionType: taskEvent.complete,
    verb: HttpVerb.PUT,
    url: (payload: { taskId: string }) => `api/bpm/tasks/${payload.taskId}/action/complete`,
    success: function*(payload: { taskId: string }, responseBody: {}) {
        yield put({ type: taskEvent.completeSuccess, payload });
        yield put({ type: taskEvent.get, payload: payload.taskId });
    },
    failure: taskEvent.completeFailure,
};

const saga = sagaFactory(config);
export default saga;
