import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import AttemptRequest from '../../../../components/AttemptRequest';
import * as config from '../../../../config';
import { storageController } from 'storage';

/*
REST URI: api/bpm/process-instances/<<processInstanceId>>/set-variables
Request Body: { "id" : "initiator", "value": "test" }
*/
interface SetVariableRequestBody {
    id: string;
    value: string;
}

const getLazyR = (processInstanceId: string) => (reqBody: SetVariableRequestBody) => () =>
    fetch(`${config.BACKEND_BASE_URL}api/bpm/process-instances/${processInstanceId}/set-variables`, {
        method: 'PUT',
        body: JSON.stringify(reqBody),
        credentials: 'same-origin',
        headers: new Headers({
            Authorization: `Bearer ${storageController.getToken()}`,
            Cookie: `${window.document.cookie}`,
            Accept: 'application/json',
            'Content-type': 'application/json',
        }),
    });
/*
    This is just a button that encapsulates the request + state of the fetch by changing its styles.
*/
export const getSetVariableButton = (processInstanceId: string, onSuccess: () => void = () => null) => (
    reqBody: SetVariableRequestBody,
) => (
    <AttemptRequest
        type="external"
        renderer={({ attemptAction }) => state =>
            state._tag === 'failure' ? (
                <div>
                    Action failed.
                    <a // eslint-disable-line
                        href="javascript:;" // eslint-disable-line
                        onClick={attemptAction}
                    >
                        try again?
                    </a>
                </div>
            ) : state._tag === 'pending' ? (
                <Button style={{ marginLeft: 'auto' }} variant="contained" color="default" disabled={true}>
                    Save
                    <CircularProgress style={{ marginLeft: '1em', height: 20, width: 20 }} />
                </Button>
            ) : state._tag === 'unsubmitted' ? (
                <Button style={{ marginLeft: 'auto' }} variant="contained" color="primary" onClick={attemptAction}>
                    Save
                </Button>
            ) : (
                <span style={{ marginLeft: 'auto' }}>Success!</span>
            )}
        lazyRequest={getLazyR(processInstanceId)(reqBody)}
        onSuccess={onSuccess}
    />
);
