import { curriedEvalExpression, SpelOptions } from 'expressions/evaluate';
import { evalErrMsg, entityPreprocessValuesForEval, getBaseFields, combineFieldsReq } from 'expressions/formValidation';
import mergeErrorsWithViewItemConfigVal from '../../form/mergeErrorsWithViewItemConfigVal';
import { EntityValidations } from 'reducers/entityValidationsReducer';
import { SearchValidations } from 'reducers/searchValidationsReducer';
import { ValueSets } from 'reducers/valueSetsReducer';
/*
import
    insertEntityReferencesForValidation
from 'components/generics/genericCreate/insertEntityReferencesForValidation/index';
*/
import ViewConfig from 'reducers/ViewConfigType';
import { currentUserProperties } from 'expressions/contextUtils/currentUser';
import updateDataOnRefChange from '../EntityFormContext/util/updatedDataOnSubRefChange';
import { fromNullable } from 'fp-ts/lib/Option';
import { unflatten } from 'flat';
import isObject from 'lodash/isObject';
import applyDeltaToEntities from 'casetivity-shared-js/lib/viewConfigSchema/traverseGetData/ApplyDeltaToEntities';
import { getRefEntityName, isValueSetField, isRefOneField } from 'components/generics/utils/viewConfigUtils';
import fromEntries from 'util/fromentries';
import flatten from 'flat';
import { denormalizeEntitiesByPaths } from 'casetivity-shared-js/lib/viewConfigSchema/denormalizing/buildEntityMappingsFromPaths';

const getReferencePaths = (values: {}, fields = false, acc = ''): string[] => {
    return Object.entries(values).flatMap(([path, value]) => {
        const currPath = acc ? `${acc}.${path}` : path;
        if (isObject(value) && !Array.isArray(value)) {
            return [currPath, ...getReferencePaths(value, fields, path)];
        }
        if (fields) {
            return [currPath];
        }
        return [];
    });
};

type Common<A, B> = {
    [P in keyof A & keyof B]: A[P] | B[P];
};
export const validate = (
    values,
    validations: undefined | Common<EntityValidations[0][0], SearchValidations[0][0]>[],
    entities: {},
    options: SpelOptions,
    valueSets: ValueSets,
    viewConfig: ViewConfig,
) => {
    // future performance change:
    // the last values for each expression, and only eval if they have changed.
    // or: skip validation completely if none of the 'fieldsRequired' have changed.

    const errors = (validations || [])
        .map(({ expression, message, fieldsRequired, valuesetFieldsRequired }) =>
            curriedEvalExpression(expression)
                .chain(evalfn => {
                    const evalContext = entityPreprocessValuesForEval(
                        values,
                        getBaseFields(fieldsRequired),
                        valuesetFieldsRequired,
                        entities,
                        options,
                        valueSets,
                    );
                    return evalfn(
                        {
                            ...evalContext,
                            ...currentUserProperties(viewConfig),
                        },
                        {},
                    ).map(result => {
                        return { message, fieldsRequired, valuesetFieldsRequired, result };
                    });
                })
                .mapLeft(err => ({ fieldsRequired, valuesetFieldsRequired, err })),
        )
        .flatMap(eith =>
            eith.fold(
                // lets include evaluation errors as validation errors to force their correction.
                ({ fieldsRequired, valuesetFieldsRequired, err }) =>
                    combineFieldsReq(fieldsRequired, valuesetFieldsRequired).map(f => [f, evalErrMsg(err)]),
                ({ message, fieldsRequired, valuesetFieldsRequired, result }) =>
                    !result ? combineFieldsReq(fieldsRequired, valuesetFieldsRequired).map(f => [f, message]) : [],
            ),
        );
    return errors.reduce((prev, [key, error]) => {
        if (prev[key] && prev[key].indexOf(error) === -1) {
            prev[key] = prev[key] + '\n' + error;
        } else {
            prev[key] = error;
        }
        return prev;
    }, {});
};
const validator = (
    values,
    props: {
        entityConfigValidations: EntityValidations | undefined;
        entities: any; // tslint:disable-line
        resource: string;
        options: SpelOptions;
        valueSets: ValueSets;
        viewConfig: ViewConfig;
        fieldValues?: {};
    },
) =>
    // when the form is used for an entityViewItem, check the config against the
    // registered validations for that widgetType
    // We will register other kinds of validations here, so maybe rename.
    {
        const updatedValues = fromNullable(props.entityConfigValidations[props.resource])
            .map(validations => {
                const expressionFields = Object.values(validations).reduce((prev, curr) => {
                    return prev.concat(curr.fieldsRequired);
                }, []);
                return updateDataOnRefChange(
                    props.viewConfig,
                    props.resource,
                    props.entities,
                    Object.entries(flatten(values)),
                    expressionFields,
                );
            })
            .map(entries => unflatten(Object.assign({}, values, ...entries.map(([k, v]) => ({ [k]: v })))))
            .getOrElse(values);

        const resultEntities = applyDeltaToEntities(
            props.viewConfig,
            props.entities,
            flatten(updatedValues),
            {
                id: updatedValues.id,
                entityType: props.resource,
            },
            false,
        );
        const referencePaths = getReferencePaths(updatedValues); // string paths across data
        const fieldPaths = getReferencePaths(updatedValues, true); // string paths to all fields;
        const nestedResults = Object.assign(
            {},
            ...referencePaths.flatMap(path => {
                const reference = getRefEntityName(props.viewConfig, props.resource, path, 'TRAVERSE_PATH');
                return fromNullable(props.entityConfigValidations[reference])
                    .map(vs =>
                        vs.filter(v => {
                            const res = v.fieldsRequired.some(fr => {
                                return fieldPaths
                                    .flatMap(p =>
                                        p.startsWith(path) && p[path.length] === '.' ? [p.slice(path.length + 1)] : [],
                                    )
                                    .some(path => path === fr || fr.startsWith(`${path}.`));
                            });
                            return res;
                        }),
                    )
                    .map(vs => {
                        const referenceId =
                            updatedValues[`${path}Id`] || (updatedValues[path] && updatedValues[path].id);
                        const fieldsUsedInValidations = vs.flatMap(vs => vs.fieldsRequired);
                        const valueSetsUsedInValidations = fromEntries(
                            vs.flatMap(vs => Object.entries(vs.valuesetFieldsRequired)),
                        );
                        const validateRes = validate(
                            entityPreprocessValuesForEval(
                                denormalizeEntitiesByPaths(
                                    resultEntities,
                                    fieldsUsedInValidations.map(f =>
                                        f.endsWith('Code') &&
                                        isValueSetField(props.viewConfig, reference, f.slice(0, -4), 'TRAVERSE_PATH')
                                            ? f.slice(0, -4)
                                            : f.endsWith('Id') &&
                                              isRefOneField(
                                                  props.viewConfig,
                                                  reference,
                                                  f.slice(0, -2),
                                                  'TRAVERSE_PATH',
                                              )
                                            ? f.slice(0, -2)
                                            : f,
                                    ),
                                    props.viewConfig,
                                    reference,
                                    referenceId,
                                ),
                                fieldsUsedInValidations,
                                valueSetsUsedInValidations,
                                resultEntities,
                                props.options,
                                props.valueSets,
                            ),
                            vs,
                            resultEntities,
                            props.options,
                            props.valueSets,
                            props.viewConfig,
                        );
                        return validateRes;
                    })
                    .map(errs => {
                        return fromEntries(Object.entries(errs).map(([k, v]) => [`${path}.${k}`, v]));
                    })
                    .fold([], errs => [errs] as {}[]);
            }),
        );
        const rootResults = validate(
            updatedValues,
            props.entityConfigValidations[props.resource],
            props.entities,
            props.options,
            props.valueSets,
            props.viewConfig,
        );
        return mergeErrorsWithViewItemConfigVal(
            unflatten({
                ...rootResults,
                ...nestedResults,
            }),
            props.resource,
            values,
            (props.entities && props.entities.Concept) || {},
        );
    };

export default validator;
