import React from 'react';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { MuiThemeProvider, createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'; // v1.x
import {
    Card,
    CardActions,
    Typography,
    Button,
    CircularProgress,
    CardContent,
    Dialog,
    CardHeader,
} from '@material-ui/core';
import Done from '@material-ui/icons/Done';
import { login } from '../actions';
import getV1InitialTheme from 'components/layouts/getMuiV1Theme';
import { getPrimaryColor, getSecondaryColor, getErrorColor } from 'components/layouts/getThemeColors';
import memoizeOne from 'memoize-one';
import TextInput from 'fieldFactory/input/components/TextInput';
import { RootState } from 'reducers/rootReducer';
import { CLEAR_STORE_BUT_KEEP_LOCATION } from 'actions/constants';
import { SnackbarProvider } from 'notistack';
import Notifier from 'notistack/components/Notifier';
import { AuthPayload } from 'auth/definitions';
import Popup from 'components/Popup';
import WithTextState from './WithTextState';
import AttemptRequest, { AttemptRequestState } from 'components/AttemptRequest';
import * as config from 'config';
import Themed from 'components/Themed';
import { fromNullable, fromPredicate } from 'fp-ts/lib/Option';
import { PasswordResetTextField } from 'auth/password-reset/components/shared';
import { Formik } from 'formik';
import { parse } from 'querystring';
import { Helmet } from 'react-helmet';
import PublicGlobalAlerts from 'global-alerts/public/components/PublicGlobalAlerts';
import { translateError } from 'auth/epic';
import { getLoginConfiguredTextSelector } from 'util/applicationConfig';
import { getDisplayTitle } from 'components/Title';
import LinkButton from 'components/links/LinkButton';
import AppFooter from 'footer';
import SafeHtmlAsReact from 'templatePage/components/SafeHtmlAsReact';
import { getAppTitleSelector } from 'util/applicationConfig';

const renderActionButtons = (args: {
    attemptAction: () => void;
    closeDialog: () => void;
    disallowSubmission: boolean;
}) => <T extends any>(state: AttemptRequestState<T>) => {
    return (
        <CardActions>
            <Button
                color="primary"
                variant="contained"
                disabled={args.disallowSubmission || state._tag === 'pending'}
                onClick={args.attemptAction}
            >
                Send password reset email{' '}
                {state._tag === 'pending' ? (
                    <CircularProgress
                        style={{
                            height: 20,
                            width: 20,
                        }}
                    />
                ) : (
                    state._tag === 'success'
                )}
            </Button>
            <Button onClick={args.closeDialog} aria-label="close">
                Close
            </Button>
        </CardActions>
    );
};

const getLazyR = (login: string) => () =>
    fetch(`${config.BACKEND_BASE_URL}api/account/forgot-password/init`, {
        method: 'POST',
        body: JSON.stringify({
            login,
        }),
        credentials: 'same-origin',
        headers: new Headers({
            Accept: 'application/json',
            'Content-type': 'application/json',
        }),
    });

interface PasswordResetButtonProps {
    formFieldName?: string;
    renderButton: (args: { openDialog: () => void }) => JSX.Element;
}
export const PasswordResetButton = ({ formFieldName, renderButton }: PasswordResetButtonProps) => (
    <Popup
        renderDialogContent={({ closeDialog }) => {
            const renderSubmitDialogContent = (initialValue: string) => (
                <WithTextState initial={initialValue}>
                    {({ text, setText }) => (
                        <Card>
                            <CardContent>
                                <TextInput
                                    source=""
                                    resource=""
                                    label="Login"
                                    ariaInputProps={{
                                        'aria-label': 'login',
                                    }}
                                    validate={() => null}
                                    input={{
                                        onChange: e => setText(e.target.value),
                                        value: text,
                                        onBlur: e => setText(e.target.value),
                                    }}
                                    meta={
                                        !text
                                            ? {
                                                  error: 'Required',
                                                  touched: true,
                                              }
                                            : !text.match("^[_'.@A-Za-z0-9-]*$")
                                            ? {
                                                  error: 'Invalid Username',
                                                  touched: true,
                                              }
                                            : {}
                                    }
                                />
                            </CardContent>
                            <AttemptRequest
                                type="external"
                                key={text}
                                lazyRequest={getLazyR(text.trim())}
                                renderer={({ attemptAction }) => state => (
                                    <React.Fragment>
                                        {state._tag === 'failure' ? (
                                            <Themed>
                                                {({ theme }) => (
                                                    <CardContent
                                                        style={{
                                                            paddingTop: 0,
                                                            paddingBottom: 0,
                                                            color: theme.palette.error.dark,
                                                        }}
                                                    >
                                                        {state.body && typeof state.body === 'object'
                                                            ? Object.values(state.body).map((msg, i) =>
                                                                  typeof msg === 'string' ? (
                                                                      <p key={i}>{msg}</p>
                                                                  ) : (
                                                                      <pre key={i}>
                                                                          {msg && JSON.stringify(msg, null, 4)}
                                                                      </pre>
                                                                  ),
                                                              )
                                                            : 'There was a problem: Failed to send the reset email.'}
                                                    </CardContent>
                                                )}
                                            </Themed>
                                        ) : state._tag === 'success' ? (
                                            <CardContent>
                                                <Done /> Please check your email.
                                            </CardContent>
                                        ) : null}
                                        {renderActionButtons({
                                            attemptAction,
                                            closeDialog,
                                            disallowSubmission: !text || !text.match("^[_'.@A-Za-z0-9-]*$"),
                                        })(state)}
                                    </React.Fragment>
                                )}
                            />
                        </Card>
                    )}
                </WithTextState>
            );
            return formFieldName ? (
                <Field name={formFieldName} component={({ input }) => renderSubmitDialogContent(input.value)} />
            ) : (
                renderSubmitDialogContent('')
            );
        }}
        renderToggler={({ openDialog }) =>
            renderButton({
                openDialog: openDialog(),
            })
        }
    />
);

const styles = (theme: Theme) =>
    createStyles({
        applicationHeader: {
            color: theme.palette.getContrastText(theme.palette.primary.main),
        },
        error: {
            color: theme.palette.error.main,
        },
        backdrop: {
            height: '100vh',
            backgroundColor: theme.palette.primary.main,
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
        },
        mainBack: {
            height: '100%',
            backgroundColor: theme.palette.primary.main,
            // position: 'absolute',
            // top: '50%',
            // left: '50%',
            // transform: 'translate(-50%, -50%)',
        },
        main: {
            display: 'flex',
            flexDirection: 'column',
            // minHeight: 'calc(100vh - 100px)',
            alignItems: 'center',
            justifyContent: 'center',
        },
        card: {
            minWidth: 300,
        },
        avatar: {
            margin: '1em',
            textAlign: 'center',
        },
        form: {
            padding: '0 1em 1em 1em',
        },
        input: {
            marginTop: 16,
            width: '100%',
            display: 'flex',
        },
    });

const mapStateToProps = (state: RootState) => ({
    isLoading: state.admin.loading > 0,
    logo: (state.basicInfo && state.basicInfo.logo) || '/logo.jpeg',
    displayTitle: getDisplayTitle(state),
    appColor: state.basicInfo && state.basicInfo.applicationColor,
    loginConfiguredText: getLoginConfiguredTextSelector(state),
    appTitle: getAppTitleSelector(state),
    isSecureEnvironment: state.basicInfo ? !state.basicInfo.debugFeaturesEnabled : true,
    thm0: state.thm0,
});

const dispatches = {
    userLogin: login,
    clearStoreButKeepLocation: () => ({ type: CLEAR_STORE_BUT_KEEP_LOCATION }),
};
type Dispatches = typeof dispatches;

interface LoginProps {
    location: Location;
}
interface LoginComponentProps
    extends LoginProps,
        ReturnType<typeof mapStateToProps>,
        InjectedFormProps,
        WithStyles<typeof styles>,
        Dispatches {}

const getNewPasswordLazyR = (data: { currentPassword: string; password: string; login: string }) => () =>
    fetch(`${config.BACKEND_BASE_URL}api/account/user-expired-password`, {
        method: 'POST',
        body: JSON.stringify(data),
        credentials: 'same-origin',
        headers: new Headers({
            Accept: 'application/json',
            'Content-type': 'application/json',
        }),
    });

type NewPasswordDialog =
    | {
          open: false;
      }
    | {
          open: true;
          data: {
              login: string;
              currentPassword: string;
          };
      };
interface LoginComponentState {
    newPasswordDialog: NewPasswordDialog;
    errorMessage: string;
}
class LoginComponent extends React.Component<LoginComponentProps, LoginComponentState> {
    state: LoginComponentState = {
        errorMessage: '',
        newPasswordDialog: {
            open: false,
        },
    };
    componentDidMount() {
        this.props.clearStoreButKeepLocation();
    }
    login = (auth: AuthPayload) => {
        this.props.userLogin(
            auth,
            error => {
                if (
                    fromNullable(error.response)
                        .mapNullable(r => r.AuthenticationException)
                        .fold(false, msg => msg && msg.includes('password expired'))
                ) {
                    const initialRequest = JSON.parse(error.request.body);
                    this.setState({
                        errorMessage: '',
                        newPasswordDialog: {
                            open: true as const,
                            data: {
                                currentPassword: initialRequest.password,
                                login: initialRequest.username,
                            },
                        },
                    });
                } else {
                    const errorMessage = translateError(error);
                    this.setState({
                        errorMessage,
                    });
                }
                return false;
            },
            this.getRedirectTo(),
        );
    };
    closeNewPasswordDialog = () => {
        this.setState({
            newPasswordDialog: {
                open: false,
            },
        });
    };
    getRedirectTo = () => {
        return fromPredicate<string>(Boolean)(this.props.location.search)
            .map(s => s.slice(1))
            .chain(fromPredicate<string>(Boolean))
            .mapNullable(parse)
            .mapNullable(p => p.redirectTo)
            .mapNullable(rt => (Array.isArray(rt) ? rt[0] : rt))
            .toUndefined();
    };
    render() {
        const { handleSubmit, isLoading, classes, loginConfiguredText, isSecureEnvironment } = this.props;
        const { newPasswordDialog } = this.state;
        return (
            <React.Fragment>
                <Helmet>
                    <title>Log in</title>
                </Helmet>
                <SnackbarProvider>
                    {!isSecureEnvironment && <Notifier />}
                    <div role="main" className={classes.backdrop}>
                        <Dialog
                            open={newPasswordDialog.open}
                            TransitionProps={
                                {
                                    // https://github.com/dequelabs/axe-core/issues/146
                                    role: 'presentation',
                                } as any
                            }
                        >
                            {newPasswordDialog.open ? (
                                <AttemptRequest
                                    type="internal"
                                    renderer={({ attemptAction }) => state => (
                                        <Formik<{ password1: string; password2: string }>
                                            initialValues={{ password1: '', password2: '' }}
                                            validate={values => {
                                                let errors: Partial<typeof values> = {};
                                                if (!values.password1) {
                                                    errors.password1 = 'Required';
                                                }
                                                if (!values.password2) {
                                                    errors.password2 = 'Required';
                                                }
                                                if (
                                                    values.password1 &&
                                                    values.password2 &&
                                                    values.password1 !== values.password2
                                                ) {
                                                    errors.password2 = 'Password must match';
                                                }
                                                return errors;
                                            }}
                                            onSubmit={(values, { setSubmitting }) => {
                                                attemptAction({
                                                    lazyRequest: getNewPasswordLazyR({
                                                        login: newPasswordDialog.data.login,
                                                        currentPassword: newPasswordDialog.data.currentPassword,
                                                        password: values.password1,
                                                    }),
                                                });
                                            }}
                                        >
                                            {({
                                                values,
                                                errors,
                                                touched,
                                                handleChange,
                                                handleBlur,
                                                handleSubmit,
                                                /* and other goodies */
                                            }) => (
                                                <Card>
                                                    <CardHeader title="Password Expired" />
                                                    <form autoComplete="off" onSubmit={handleSubmit}>
                                                        <CardContent>
                                                            Your password has expired. Please enter a new password.
                                                            <PasswordResetTextField
                                                                disabled={
                                                                    state._tag === 'success' || state._tag === 'pending'
                                                                }
                                                                handleChange={handleChange}
                                                                handleBlur={handleBlur}
                                                                value={values.password1}
                                                                name="password1"
                                                                label="New Password"
                                                                error={errors.password1}
                                                                touched={touched.password1}
                                                                type="password"
                                                            />
                                                            <PasswordResetTextField
                                                                disabled={
                                                                    state._tag === 'success' || state._tag === 'pending'
                                                                }
                                                                handleChange={handleChange}
                                                                handleBlur={handleBlur}
                                                                value={values.password2}
                                                                name="password2"
                                                                label="Please re-enter password"
                                                                error={errors.password2}
                                                                touched={touched.password2}
                                                                type="password"
                                                            />
                                                            {state._tag === 'failure' ? (
                                                                <Themed>
                                                                    {({ theme }) => (
                                                                        <div
                                                                            style={{
                                                                                color: theme.palette.error.dark,
                                                                            }}
                                                                        >
                                                                            {state.body &&
                                                                            typeof state.body === 'object'
                                                                                ? Object.values(state.body).map(
                                                                                      (msg, i) => <p key={i}>{msg}</p>,
                                                                                  )
                                                                                : 'There was a problem: Failed to update password.'}
                                                                        </div>
                                                                    )}
                                                                </Themed>
                                                            ) : state._tag === 'success' ? (
                                                                <span>
                                                                    <Done /> Success: Password updated.
                                                                </span>
                                                            ) : null}
                                                        </CardContent>
                                                        <CardActions>
                                                            <Button
                                                                variant="contained"
                                                                onClick={this.closeNewPasswordDialog}
                                                            >
                                                                Close
                                                            </Button>
                                                            <Button
                                                                color="primary"
                                                                variant="contained"
                                                                type="submit"
                                                                disabled={
                                                                    state._tag === 'pending' ||
                                                                    state._tag === 'success' ||
                                                                    Object.keys(errors).length > 0
                                                                }
                                                            >
                                                                Submit
                                                                {state._tag === 'pending' ? (
                                                                    <CircularProgress
                                                                        style={{ height: 15, width: 15 }}
                                                                    />
                                                                ) : null}
                                                            </Button>
                                                        </CardActions>
                                                    </form>
                                                </Card>
                                            )}
                                        </Formik>
                                    )}
                                />
                            ) : null}
                        </Dialog>
                        <PublicGlobalAlerts />
                        <div style={{ padding: '1em' }}>
                            {this.props.appTitle ? (
                                <Typography className={classes.applicationHeader} variant="h4" component="h1">
                                    <SafeHtmlAsReact html={this.props.appTitle} />
                                </Typography>
                            ) : (
                                <Typography className={classes.applicationHeader} variant="h4" component="h1">
                                    {this.props.displayTitle}
                                </Typography>
                            )}
                        </div>
                        <div className={classes.mainBack}>
                            <div
                                className={classes.main}
                                style={{
                                    height: 'calc(100% - 50px)',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                }}
                            >
                                <Card className={classes.card}>
                                    <div className={classes.avatar}>
                                        <img
                                            src={`${process.env.PUBLIC_URL}${this.props.logo}`}
                                            height="auto"
                                            width={300}
                                            alt="Logo"
                                        />
                                    </div>
                                    <form autoComplete="off" onSubmit={handleSubmit(this.login)}>
                                        <div className={classes.form}>
                                            <Themed>
                                                {({ theme }) => (
                                                    <React.Fragment>
                                                        <p style={{ marginBottom: 0, color: theme.palette.error.dark }}>
                                                            {this.state.errorMessage}
                                                        </p>
                                                    </React.Fragment>
                                                )}
                                            </Themed>
                                            <br />
                                            <div className={classes.input}>
                                                <Field
                                                    noDebounce={true}
                                                    id="username"
                                                    name="username"
                                                    margin="normal"
                                                    label="Username"
                                                    fullWidth={true}
                                                    component={TextInput}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                            <div className={classes.input}>
                                                <Field
                                                    noDebounce={true}
                                                    id="password"
                                                    name="password"
                                                    margin="normal"
                                                    label="Password"
                                                    fullWidth={true}
                                                    component={TextInput}
                                                    type="password"
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </div>
                                        {loginConfiguredText && (
                                            <div style={{ padding: '0px 16px' }}>
                                                <SafeHtmlAsReact html={loginConfiguredText} />
                                            </div>
                                        )}
                                        <CardActions>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={isLoading}
                                                id="submit"
                                                fullWidth={true}
                                            >
                                                Sign in
                                                {isLoading && <CircularProgress size={25} thickness={2} />}
                                            </Button>
                                        </CardActions>
                                        <CardActions style={{ paddingTop: 0 }}>
                                            <PasswordResetButton
                                                formFieldName="username"
                                                renderButton={({ openDialog }) => (
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        fullWidth={true}
                                                        onClick={openDialog}
                                                        aria-label="Forgot password?"
                                                    >
                                                        Forgot password?
                                                    </Button>
                                                )}
                                            />
                                        </CardActions>
                                        {(window as any).CASETIVITY_ANON_VIEW_CONFIG && (
                                            <CardActions style={{ paddingTop: 0 }}>
                                                <p
                                                    style={{
                                                        margin: '0px',
                                                        textAlign: 'center',
                                                        width: '100%',
                                                    }}
                                                >
                                                    <LinkButton to="/">Continue without logging in</LinkButton>
                                                </p>
                                            </CardActions>
                                        )}
                                    </form>
                                </Card>
                            </div>
                        </div>
                    </div>
                </SnackbarProvider>
            </React.Fragment>
        );
    }
}

const enhance = compose(
    reduxForm({
        form: 'signIn',
        validate: (values: { username?: string; password?: string }) => {
            const errors: { username?: string; password?: string } = {};
            if (!values.username) {
                errors.username = 'Username required';
            }
            if (!values.password) {
                errors.password = 'Password required';
            }
            return errors;
        },
    }),
    connect(
        mapStateToProps,
        dispatches,
    ),
    BaseComponent => {
        class MuiThemed extends React.Component<
            LoginProps & ReturnType<typeof mapStateToProps> & InjectedFormProps & Dispatches
        > {
            _getV1InitialTheme = memoizeOne(getV1InitialTheme);

            getLoginV1Theme = () => {
                return this._getV1InitialTheme(
                    getPrimaryColor(this.props.appColor),
                    getSecondaryColor(),
                    getErrorColor(this.props.appColor),
                );
            };
            render() {
                const theme = this.getLoginV1Theme();
                return (
                    <MuiThemeProvider theme={theme}>
                        <BaseComponent {...this.props} />
                        <AppFooter />
                    </MuiThemeProvider>
                );
            }
        }
        return MuiThemed;
    },
    withStyles(styles),
);

export default enhance(LoginComponent);
