import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startProcessFromKey } from 'bpm/create-process-instance/actions';
import { RootState } from 'reducers/rootReducer';
import { parse } from 'query-string';

interface StartProcessRouteProps {
    processDefinitionKey: string;
    searchStr?: string;
}

const StartProcessRoute: React.FunctionComponent<StartProcessRouteProps> = props => {
    // because we are redirected, we can break this after a single run.
    const wasTriggeredOnce = useRef(false);
    const dispatch = useDispatch();
    const startProcess = React.useCallback(() => {
        dispatch(
            startProcessFromKey(
                {
                    processDefinitionKey: props.processDefinitionKey,
                    values: parse(props.searchStr),
                },
                {
                    handleRedirect: true,
                },
            ),
        );
    }, [dispatch, props.processDefinitionKey, props.searchStr]);

    const processDefinitionsAreLoading = useSelector((state: RootState) => {
        return state.bpm.processDefinitions.isLoading;
    });
    const processDefinitionExists = useSelector((state: RootState) => {
        return Boolean(state.bpm.processDefinitions.byId[props.processDefinitionKey]);
    });
    useEffect(() => {
        if (processDefinitionExists && !wasTriggeredOnce.current) {
            wasTriggeredOnce.current = true;
            startProcess();
        }
    }, []); // eslint-disable-line
    useEffect(() => {
        if (processDefinitionExists && !wasTriggeredOnce.current) {
            wasTriggeredOnce.current = true;
            startProcess();
        }
    }, [processDefinitionExists, startProcess]);

    if (processDefinitionsAreLoading) {
        return <div>Pending...</div>;
    }
    return null;
};
export default StartProcessRoute;
