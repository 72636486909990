import React from 'react';
import { ReactElement, Component, ReactNode } from 'react';
import { reduxForm } from 'redux-form';
import withHandlers from 'recompose/withHandlers';
import withProps from 'recompose/withProps';
import onlyUpdateForKeys from 'recompose/onlyUpdateForKeys';
import pure from 'recompose/pure';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withPropsOnChange from 'recompose/withPropsOnChange';
import createGetDefaultValues from '../utils/getDefaultValuesEditForm';
import Toolbar from 'components/generics/form/Toolbar.aor';
import TabbableForm from '../form/TabbableForm';
import { findTabsWithErrors, isRefManyManyField } from '../utils/viewConfigUtils/index';
import { FieldFactorySubscriber } from '../../../fieldFactory/Broadcasts';
import withPopoverLock, { createIsPopoverSelector } from '../hoc/popoverLock';
import { withDirtyFieldMergeConflict } from '../hoc/withMergeConflict';
import { RootState } from '../../../reducers/rootReducer';
import { Dialog, DialogContent, DialogContentText, withWidth } from '@material-ui/core';
import MergeView from '../genericMerge/index';
import getBooleanFieldsPreloadedFalse from '../utils/getBooleanFieldsPreloadedFalse';
import validate from '../form/validate';
import { formContext } from '../form/EntityFormContext';
import deepSubmit from 'util/deepSubmit';
import { withDisableAllFieldsContextProvider, disableAllFieldsContext } from '../form/forceDisableAllFieldsContext';
import {
    withInjectErrorsToAllFieldsContextProvider,
    injectErrorsToAllFieldsContext,
} from '../form/forceInjectErrorsForAllFieldsContext';
import { createGetEntities } from 'components/generics/form/EntityFormContext/util/getEntities';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import ViewConfig from 'reducers/ViewConfigType';
import { AjaxError } from 'rxjs/ajax';
import ErrorDialog from '../form/SubmissionFailureDialog';
import FormSaveNotifierTrigger from 'formSaveNotifier/components/Trigger';
import EntityExpressionTester from 'expression-tester/entity-form';
import getFields from './getFields';
import EditableViewFormEditor from 'layout-editor/components/EditableViewFormEditor';

export const adjustForSubmit = (
    viewConfig: ViewConfig,
    viewName: string,
    valuesForRegisteredFieldsOnly: {},
    record: {
        id: string;
        entityType: string;
        entityVersion: number;
    },
) => {
    /*
    The job of the code below is to send data required for the validations to run, because backend validation
    doesn't support PATCH / doesn't look up the fields it needs for validation from the DB if missing.
    However the problem is we end up expanding trees of data we don't need, which can cause merge conflicts, or
    undecipherable errors

    In the meantime the below is commented out, and saves will fail if a field is missing from the save.
    */
    /*
    const entityValidations: EntityValidations[0] | undefined = props.entityConfigValidations;
    const requiredFields = uniq(setoidString)(
        getBaseFields(
            (entityValidations || []).flatMap(({ fieldsRequired }) => fieldsRequired)
        )
    );
    */
    /*
        boolean fields in the view should be set to 'false' if not present/undefined/null
    */
    const resource = viewConfig.views[viewName].entity;
    const values = Object.assign(
        {
            id: record.id,
            entityVersion: record.entityVersion,
            entityType: record.entityType,
        },
        getBooleanFieldsPreloadedFalse(viewConfig, viewName),
        valuesForRegisteredFieldsOnly,
    );
    Object.entries(values).forEach(t => {
        const [key /* value */] = t;
        /*
        This removes the referenced object if the id changes.
        Since we allow nested submits, we may want to change the id but also save nested data.
        Therefore this is commented out.

        if (key.endsWith('Id') &&
            props.record[key] !== value &&
            Object.prototype.hasOwnProperty.call(values, key.slice(0, -2))) {
            delete values[key.slice(0, -2)];
        }
        */

        /*
        Remove many-manys for now
        (onEdits we don't need the Ids anymore if the components are in 'commitChanges' mode).
        */
        if (key.endsWith('Ids') && isRefManyManyField(viewConfig, resource, key.slice(0, -3), 'TRAVERSE_PATH')) {
            delete values[key];
        }
    });
    return deepSubmit(values);
};

const _VALIDATION_HACK_KEY = '_validationHack';

interface EditFormBaseProps {
    isPopover?: boolean;
    title: string | ReactNode;
    actions: ReactElement<{ isSaving?: boolean; save: () => void }>;
    record: { id: string; entityType: string };
    resource: string;
    redirect: string | boolean;
    viewName: string;
    viewConfig: RootState['viewConfig'];
    basePath: string;

    // base OPTIONAL
    formId?: string;
    contentContainerStyle?: {};
    submitOnEnter?: boolean;
    toolbar?: ReactElement<{
        handleSubmitWithRedirect: (redirect: string) => Function;
        invalid: boolean;
        submitOnEnter: boolean;
    }>;
    useTabs?: boolean;
    match?: {};
    width: Breakpoint;
    key?: number;
    isLoading: boolean;
    // valuesForRegisteredFieldsOnly: {};
    saveRegisteredFields: (
        redirect: string | boolean,
        onSubmittingCb: () => void,
        onSaveOrFailureCb?: (err?: AjaxError) => void,
    ) => () => void;
}

interface EditFormProps extends EditFormBaseProps {
    // hoc REQUIRED
    handleSubmit: Function;
    invalid: boolean;
    save: Function;
    setAsTopView: Function;
    unsetAsTopView: Function;
    getPrintTemplate: Function;
    asyncValidate: Function;
    change: Function;
    initialize: Function;

    // hoc OPTIONAL
    topView?: string;
    baseFields?: ReactElement<{}>[];
    fieldsByTab?: {};
    tabsWithErrors?: string[];
    loadValueSet: Function;
}

interface EditFormState {
    isSaving: boolean;
    alertError: AjaxError | null;
}

class EditFormComponent extends Component<EditFormProps, EditFormState> {
    static defaultProps = {
        topView: null,
        formId: null,
        baseFields: [],
        fieldsByTab: {},
        contentContainerStyle: { borderTop: 'solid 1px #e0e0e0' },
        submitOnEnter: true,
        toolbar: <Toolbar />,
        viewConfig: null,
        tabsWithErrors: [],
        useTabs: true,
        activeField: null,
    };
    _isMounted = false;
    state: EditFormState = {
        isSaving: false,
        alertError: null,
    };
    getSetSaving = (isSaving: boolean, cb?: () => void) => () => {
        if (this._isMounted) {
            this.setState({ isSaving }, cb);
        }
    };
    componentDidMount() {
        this._isMounted = true;
        /*
            The below is a hack because sometimes forms don't get setup with their initial values.
            https://github.com/erikras/redux-form/issues/4069
        */
        this.props.initialize((this.props as any).initialValues); // tslint:disable-line
        if (this.props.record) {
            this.props.change(_VALIDATION_HACK_KEY, Date.now());
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isLoading && !this.props.isLoading) {
            // have to re-evaluate expressions now that entities are loaded.
            this.props.change(_VALIDATION_HACK_KEY, Date.now());
        }
    }

    handleSubmitWithRedirect = (redirect = this.props.redirect) => () => {
        this.props.saveRegisteredFields(redirect, this.getSetSaving(true), err => {
            this.getSetSaving(false, () => {
                if (err) {
                    this.setState({
                        alertError: err,
                    });
                }
            })();
        })();
    };
    clearAlert = () => {
        this.setState({ alertError: null });
    };
    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        const {
            contentContainerStyle,
            invalid,
            submitOnEnter,
            toolbar = (
                <Toolbar
                    handleSubmitWithRedirect={this.handleSubmitWithRedirect}
                    invalid={this.props.invalid}
                    submitOnEnter={this.props.submitOnEnter}
                />
            ),
            viewConfig,
            viewName,
            fieldsByTab = {},
            title,
            width,
            key,
            isLoading,
            record,
            isPopover,
        } = this.props;
        const actions = React.cloneElement(this.props.actions, {
            isSaving: this.state.isSaving,
            save: this.handleSubmitWithRedirect(),
        });
        return (
            <React.Fragment>
                <ErrorDialog alertError={this.state.alertError} clearAlert={this.clearAlert} />
                <EditableViewFormEditor mode="EDIT" record={record} viewName={viewName} />
                <TabbableForm
                    key={key}
                    isPopover={isPopover}
                    isLoading={isLoading}
                    width={width}
                    title={title}
                    actions={
                        process.env.NODE_ENV === 'development' ? (
                            <React.Fragment>
                                <disableAllFieldsContext.Consumer>
                                    {c => (
                                        <button onClick={() => c.set(!c.disabled)}>
                                            {c.disabled ? 'undisable fields' : 'Debug: Disable all fields'}
                                        </button>
                                    )}
                                </disableAllFieldsContext.Consumer>
                                <injectErrorsToAllFieldsContext.Consumer>
                                    {c => (
                                        <button onClick={() => c.set(c.error ? null : 'Foo!')}>
                                            {c.error ? 'uninject "Foo" errors' : 'Debug: inject "foo" errors'}
                                        </button>
                                    )}
                                </injectErrorsToAllFieldsContext.Consumer>
                                {actions}
                            </React.Fragment>
                        ) : (
                            actions
                        )
                        // actions as any
                    }
                    record={record}
                    viewName={viewName}
                    viewConfig={viewConfig}
                    toolbar={
                        toolbar &&
                        React.cloneElement(toolbar, {
                            handleSubmitWithRedirect: this.handleSubmitWithRedirect,
                            invalid,
                            submitOnEnter,
                            saving: this.state.isSaving,
                        })
                    }
                    contentContainerStyle={contentContainerStyle}
                    useTabs={this.props.useTabs}
                    baseFields={this.props.baseFields}
                    fieldsByTab={fieldsByTab}
                    tabsWithErrors={this.props.tabsWithErrors}
                />
                <formContext.Consumer>
                    {fc => {
                        return <FormSaveNotifierTrigger when={fc.isDirty && !this.state.isSaving} />;
                    }}
                </formContext.Consumer>
            </React.Fragment>
        );
    }
}
const EditFormComponentPure = pure(EditFormComponent);

class EditFormWithResolveMerge extends React.Component<
    EditFormProps,
    {
        displayMerge: boolean;
        myRecord: { id: string } | null;
        theirRecord: { id: string } | null;
        mergeRecord: { id: string } | null;
        match: {
            params: {
                id: string;
                id2: string;
            };
        };
    }
> {
    constructor(props: EditFormProps) {
        super(props);
        this.state = {
            displayMerge: false,
            myRecord: null,
            theirRecord: null,
            mergeRecord: null,
            match: {
                params: {
                    id: this.props.record.id,
                    id2: this.props.record.id,
                },
            },
        };
    }

    reloadEntity = () => {
        console.log('reloadEntity'); // tslint:disable-line
        /*
        const { crudGetOne, basePath, record } = this.props;
        crudGetOne(basePath.slice(1), record.id, basePath, false);
        */
    };

    handleOpen = () => {
        this.setState({ displayMerge: true });
    };

    handleClose = () => {
        this.setState({ displayMerge: false });
    };

    closeAndReload = () => {
        this.reloadEntity();
        this.handleClose();
    };

    render() {
        const { basePath, record } = this.props;
        const resourceName = basePath.slice(1);
        return (
            <div>
                <EditFormComponentPure {...this.props} />
                {/* <FlatButton
                primary={true}
                label="test open merge"
                icon={<ContentCreate />}
                onClick={this.handleOpen}
                style={{ overflow: 'inherit' }}
            />
            */}
                <Dialog
                    TransitionProps={
                        {
                            // https://github.com/dequelabs/axe-core/issues/146
                            role: 'presentation',
                        } as any
                    }
                    open={this.state.displayMerge}
                    onClose={this.handleClose}
                    maxWidth={false}
                    fullWidth={true}
                >
                    <DialogContent>
                        <DialogContentText style={{ textAlign: 'center' }}>
                            {'The record you are working on has been modified by another user.'}
                            {'Your submission, and the last saved record are shown below.'}
                            <br />
                            <b>{'Changes you have made have not been saved.'}</b>
                            {' Please make the necessary changes and resubmit.'}
                        </DialogContentText>
                        <MergeView
                            record1={this.state.myRecord || record}
                            record2={this.state.theirRecord || record}
                            record={this.state.mergeRecord || record}
                            resource={resourceName}
                            viewConfig={this.props.viewConfig}
                            viewName={this.props.viewName}
                            basePath={basePath}
                            match={this.state.match}
                            primaryRecordTitle="Your record"
                            altRecordTitle="Current record"
                            merge={() => {
                                alert('This is a stub!');
                            }}
                            includeRefManys={false}
                        />
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

const makeMapStateToProps1 = () => {
    const isPopoverSelector = createIsPopoverSelector();
    return (state: RootState, props) => {
        return {
            isPopover: isPopoverSelector(state, props),
            printMode: state.printMode,
        };
    };
};

const makeMapStateToProps = () => {
    const getEntities = createGetEntities();
    const getDefaultValues = createGetDefaultValues();
    const mapStateToProps = (state: RootState, props) => ({
        entityConfigValidations: state.entityValidations,
        tabsWithErrors: findTabsWithErrors(state, props),
        initialValues: getDefaultValues(state, props),
        entities: getEntities(state),
        form: props.formId || 'record-form',
        valueSets: state.valueSets,
        activeField: state.form
            ? state.form[props.formId || 'record-form']
                ? state.form[props.formId || 'record-form'].active
                : ''
            : '',
    });
    return mapStateToProps;
};
const enhance = compose(
    withDisableAllFieldsContextProvider,
    withInjectErrorsToAllFieldsContextProvider,
    BaseComponent => props => (
        <disableAllFieldsContext.Consumer>
            {c => <BaseComponent {...props} DEBUG_disableAllFields={c.disabled} />}
        </disableAllFieldsContext.Consumer>
    ),
    BaseComponent => props => {
        return (
            <EntityExpressionTester type="EDIT" record={props.record} viewName={props.viewName} formId={props.formId}>
                <BaseComponent {...props} />
            </EntityExpressionTester>
        );
    },
    withProps(({ match }) => ({
        matchId: match.params.id,
        matchPath: match.params.basePath,
    })),
    connect(makeMapStateToProps1),
    withPropsOnChange(
        [
            'viewName',
            'matchId',
            'matchPath',
            'fieldFactory',
            'taskFormDefinition',
            'formId',
            'printMode',
            'DEBUG_disableAllFields',
            'isPopover',
            'tabToPrefetch',
            //            'record',
        ],
        props => {
            return getFields(props.fieldFactory, props);
        },
    ),
    connect(makeMapStateToProps),
    BaseComponent => props => (
        <formContext.Consumer>
            {fc => (
                <BaseComponent
                    {...props}
                    valuesForRegisteredFieldsOnly={fc.registeredValues}
                    fieldValues={fc.fieldValues}
                />
            )}
        </formContext.Consumer>
    ),
    withHandlers({
        save: props => (_, redirect, onSaveOrFailureCb?: () => void) => {
            const valuesWithIdsAdjustedForNesting = adjustForSubmit(
                props.viewConfig,
                props.viewName,
                props.valuesForRegisteredFieldsOnly,
                props.record,
            );
            props.save(valuesWithIdsAdjustedForNesting, redirect, onSaveOrFailureCb);
        },
    }),
    reduxForm({
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
        shouldError: ({ values, props, nextProps, initialRender }: any) => {
            return initialRender || !props.anyTouched || nextProps.fieldValues !== props.fieldValues;
        },
        validate: (
            values,
            props: any, // tslint:disable-line
        ) => {
            // when changing views, can get 'old' fieldValues' stuck around for one update.
            // Check we are in sync, because we can try to traverse non-existent datapaths if entityType changed.
            if (props.record.id === props.fieldValues.id) {
                const res = validate(props.fieldValues, props);
                return res;
            }
            return {};
        },
    }),
    withWidth({
        initialWidth: 'md',
    }),
    withPopoverLock,
    withDirtyFieldMergeConflict,
    withHandlers({
        saveRegisteredFields: props => (
            redirect,
            onSubmittingCb: () => void,
            onSaveOrFailureCb?: (err?: AjaxError) => void,
        ) => {
            return props.handleSubmit(values => {
                onSubmittingCb();
                props.save(undefined, redirect, onSaveOrFailureCb);
            });
        },
    }),
    onlyUpdateForKeys([
        'invalid',
        'save',
        'asyncValidate',
        'topView',
        'baseFields',
        'fieldsByTab',
        'tabsWithErrors',
        'title',
        'actions',
        'record',
        'resource',
        'redirect',
        'viewName',
        'viewConfig',
        'basePath',
        'formId',
        'useTabs',
        'match',
        'saveRegisteredFields',
        'width',
        'isLoading',
        'key',
    ]),
    pure,
);
const EditForm = enhance(EditFormWithResolveMerge);
const EditFormWithFieldFactory = props => (
    <FieldFactorySubscriber>
        {fieldFactory => <EditForm {...props} fieldFactory={fieldFactory} />}
    </FieldFactorySubscriber>
);

export default EditFormWithFieldFactory;
