import React from 'react';

interface InjectErrorsToAllFieldsContext {
    set: (error: string | null) => void;
    error: string | null;
}
const injectErrorsToAllFieldsContext = React.createContext<InjectErrorsToAllFieldsContext>({
    set: () => {
        console.warn('field disability override set called outside of context provider'); // tslint:disable-line
        return null;
    },
    error: null,
});

interface InjectErrorsToAllFieldsContextState {
    error: string | null;
}
interface InjectErrorsToAllFieldsContextProps {}
class InjectErrorsToAllFieldsContextProvider extends React.Component<
    InjectErrorsToAllFieldsContextProps,
    InjectErrorsToAllFieldsContextState
> {
    state: InjectErrorsToAllFieldsContextState = { error: null };
    set = (error: string | null) => {
        this.setState({ error });
    };
    render() {
        return (
            <injectErrorsToAllFieldsContext.Provider
                value={{
                    set: this.set,
                    error: this.state.error,
                }}
            >
                {this.props.children}
            </injectErrorsToAllFieldsContext.Provider>
        );
    }
}

const withInjectErrorsToAllFieldsContextProvider = BaseComponent => props => (
    <InjectErrorsToAllFieldsContextProvider>
        <BaseComponent {...props} />
    </InjectErrorsToAllFieldsContextProvider>
);
export {
    InjectErrorsToAllFieldsContextProvider,
    injectErrorsToAllFieldsContext,
    withInjectErrorsToAllFieldsContextProvider,
};
