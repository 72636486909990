import { configValidationsByWidgetType } from '../../../validationsRegistry';
import forSearchConfigValidation from './viewItemConfigValidations/validations/forSearch';
import primativeDefaultValueValidation from './viewItemConfigValidations/validations/primativeDefaultValue';
import valuesetDefaultValueValidation from './viewItemConfigValidations/validations/valuesetDefaultValue';
import valuesetManyDefaultValueValidation from './viewItemConfigValidations/validations/valuesetManyDefaultValue';
import checkboxesValidation from './viewItemConfigValidations/validations/checkboxes';

const createValidationMessage = (maybeErrors: (string | null)[]): string | null => {
    const errorMessages = maybeErrors.filter(f => f);
    if (errorMessages.length === 0) {
        return null;
    }
    return `
        ${errorMessages.length} error${errorMessages.length > 1 ? 's' : ''} found in configuration:

        ${errorMessages.map((msg, i) => `${i + 1}: ${msg}`).join('\n\n')}
    `;
};

const mergeWithViewItemConfigValidation = (existingErrors, resource, values, concepts) => {
    if (
        // Looking at EntityViewItem and widgetType is selected
        resource === 'EntityViewItem' &&
        values.widgetTypeId &&
        concepts
    ) {
        const selectedWidgetType: {
            code: keyof typeof configValidationsByWidgetType;
        } = concepts[values.widgetTypeId];
        if (
            // the widgetTypeCode is available
            selectedWidgetType
        ) {
            // get any error based on the widget type selected + the 'config' field.
            const configErrorBasedOnWidgetType = ((configValidationsByWidgetType[selectedWidgetType.code] ||
                (() => null)) as (arg?: string) => string | null)(values.config);

            const configErrorBasedOnForSearch = values.forSearch ? forSearchConfigValidation(values.config) : null;

            const checkboxesError = values.forSearch
                ? null
                : selectedWidgetType.code === 'CHECKBOX'
                ? checkboxesValidation(values.config)
                : null;

            const defaultValueError =
                values.forSearch || selectedWidgetType.code === 'EXPRESSION'
                    ? null
                    : selectedWidgetType.code === 'SELECT' || selectedWidgetType.code === 'CHECKBOX'
                    ? valuesetDefaultValueValidation(values.config)
                    : primativeDefaultValueValidation(values.config);

            const valuesetManyDefaultValueError = values.forSearch
                ? null
                : selectedWidgetType.code === 'CHECKBOX' ||
                  selectedWidgetType.code === 'RADIO' ||
                  selectedWidgetType.code === 'MULTISELECT'
                ? valuesetManyDefaultValueValidation(values.config)
                : null;
            const msg = createValidationMessage([
                checkboxesError,
                valuesetManyDefaultValueError,
                configErrorBasedOnForSearch,
                configErrorBasedOnWidgetType,
                defaultValueError,
            ]);
            if (msg) {
                return Object.assign(existingErrors, { config: msg });
            }
        }
    }
    return existingErrors;
};
export default mergeWithViewItemConfigValidation;
