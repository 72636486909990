import React from 'react';
import get from 'lodash.get';
import pure from 'recompose/pure';

interface TextFieldProps {
    addLabel?: boolean;
    elStyle?: {};
    label?: string;
    record?: {};
    source: string;
}
const TextField: React.SFC<TextFieldProps> = ({ source, record = {}, elStyle }) => {
    return <span style={elStyle}>{get(record, source)}</span>;
};

const PureTextField = pure(TextField);

PureTextField.defaultProps = {
    addLabel: true,
};

export default PureTextField;
