import { combineReducers } from 'redux'; // eslint-disable-line import/no-extraneous-dependencies
import { processActivityEvent as activityEvent } from '../actions/processActivityEvent';
import { success, failure, pending, RemoteData } from '@devexperts/remote-data-ts';
import { ErrorDetails } from 'sagas/util/sagaFactory';

export interface Activity {
    caseDefinitionVersion: number;
    id: string;
    initiatorCanCompleteTask: boolean;
    memberOfCandidateGroup: boolean;
    memberOfCandidateUsers: boolean;
    name: string;
    processDefinitionVersion: number;
    readOnly: boolean;
    description?: string;
}
export interface ProcessActivities {
    byId: {
        [id: string]: Activity;
    };
    byProcess: {
        [id: string]: RemoteData<ErrorDetails | Error, string[]>;
    };
}

const byId = (state = {}, action) => {
    switch (action.type) {
        case activityEvent.getSuccess: {
            return Object.assign({}, state, ...action.payload.data.map(d => ({ [d.id]: d })));
        }
        default:
            return state;
    }
};

const byProcess = (state = {}, action) => {
    switch (action.type) {
        case activityEvent.get: {
            return {
                ...state,
                [action.payload.processId]: pending,
            };
        }
        case activityEvent.getSuccess: {
            return {
                ...state,
                [action.payload.processId]: success(action.payload.data.map(d => d.id)),
            };
        }
        case activityEvent.getFailure: {
            return {
                ...state,
                [action.requestPayload.processId]: failure(action.payload),
            };
        }
        default:
            return state;
    }
};

const processActivities = combineReducers<ProcessActivities>({
    byId,
    byProcess,
});

export default processActivities;
