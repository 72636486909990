import { RootAction } from 'actions/rootAction';
import { getType } from 'typesafe-actions';
import { actionButtonExpressionsGenerated } from './actions';

export interface ActionButtonExps {
    [viewName: string]: {
        [key: string]: {
            expression: string;
            buttonKey: string;
            fieldsRequired: string[];
            valuesetLiterals: string[];
            valuesetFieldsRequired: {
                [fieldSource: string]: string;
            };
        };
    };
}

export default (previousState: ActionButtonExps = {}, action: RootAction): ActionButtonExps => {
    switch (action.type) {
        case getType(actionButtonExpressionsGenerated):
            return action.payload;
        default:
            return previousState;
    }
};
