import React from 'react';
import { purifyHtml } from 'fieldFactory/display/components/HtmlDisplay';
import { processWithReact } from '.';

interface SafeHtmlAsReactProps {
    html: string;
}
const SafeHtmlAsReact = (props: SafeHtmlAsReactProps) => {
    return <div>{processWithReact(purifyHtml(props.html))}</div>;
};

export default SafeHtmlAsReact;
