import { SpelExpressionEvaluator } from 'spel2js';
import { DateValidator } from '../util/dateValidator';
import arrayValidations from '../util/arrayValidations';
import { RegexValidator } from '../util/regexValidator';
import StringUtils from '../util/stringValidator';
import { tryCatch, Either } from 'fp-ts/lib/Either';
import { compose } from 'fp-ts/lib/function';
import memoize from 'lodash/memoize';
import { EvaluationOptions } from './formValidation/definitions.d';
import { CasetivityViewContext } from 'util/casetivityViewContext';

type SpelNodeType =
    | 'assign'
    | 'boolean'
    | 'compound'
    | 'elvis'
    | 'method'
    | 'indexer'
    | 'list'
    | 'map'
    | 'null'
    | 'number'
    | 'op-and'
    | 'op-dec'
    | 'op-divide'
    | 'op-eq'
    | 'op-ge'
    | 'op-gt'
    | 'op-inc'
    | 'op-le'
    | 'op-lt'
    | 'op-minus'
    | 'op-modulus'
    | 'op-multiply'
    | 'op-ne'
    | 'op-not'
    | 'op-or'
    | 'op-plus'
    | 'op-power'
    | 'projection'
    | 'selection'
    | 'string'
    | 'ternary'
    | 'variable'
    | 'property';
/* | 'Abstract' */
// not sure why they assigned abstract as a node type

interface SpelActiveContext {
    peek(): any; // tslint:disable-line
}
interface SpelState {
    activeContext: SpelActiveContext;
}
type activeContext = any; // tslint:disable-line

export interface SpelNode {
    _type: SpelNodeType;
    getName(): string;
    getType(): SpelNodeType;
    setType(nodeType: SpelNodeType): void;
    getChildren(): SpelNode[];
    addChild(childNode: SpelNode): void;
    getParent(): SpelNode | null;
    setParent(parentNode: SpelNode): void;
    // hopefully these two below returntypes are the same. I'm not sure yet though.
    getContext(state: SpelState): activeContext | ReturnType<SpelState['activeContext']['peek']>;
    setContext(nodeContext: activeContext): void;
    getStartPosition(): number /* return (position >> 16); */;
    getEndPosition(): number /* (position & 0xffff) */;

    // must be overridden
    getValue(): void;

    toString(): string;
}

export const createRootContext = (context): object => {
    let newContext = {
        ...new DateValidator(context && context.options && context.options.dateFormat),
        ...new RegexValidator(),
        ...new StringUtils(),
        ...arrayValidations,
        ...context,
    };
    return newContext;
};

export type SpelOptions = { viewContext?: CasetivityViewContext; dateFormat?: string };

export type javaEquivPrimative = string | null | boolean | number | any[];
export const evaluateExpression = (
    expression: string,
    context: object = {},
    localVariablesAndMethods: object = {},
): javaEquivPrimative => {
    // TODO: Need to cache compiled expressions
    const compiledExpression: SpelCompiledExpression = SpelExpressionEvaluator.compile(expression);
    const rootContext = createRootContext(context);
    return compiledExpression.eval(rootContext, localVariablesAndMethods);
};

export interface SpelCompiledExpression {
    _compiledExpression: SpelNode; // ast
    eval(context: {}, locals: {}): javaEquivPrimative;
}

export const getCompiledExpression: (expression: string) => Either<Error, SpelCompiledExpression> = (
    expression: string,
) =>
    tryCatch(
        () => {
            return SpelExpressionEvaluator.compile(expression) as SpelCompiledExpression;
        },
        (e: Error) => e,
        // new Error(`Error compiling expression ${expression}: ${e && JSON.stringify(e)}`);
    );

const lazyEval: (
    sce: SpelCompiledExpression,
) => (context: {}, localVariablesAndMethods: {}) => Either<Error, javaEquivPrimative> = sce => (
    context,
    localVariablesAndMethods,
) =>
    tryCatch(
        () => {
            const res = sce.eval(createRootContext(context), localVariablesAndMethods);
            return res;
        },
        (e: Error) => e,
    );

const evaluateCompiledExpression = (
    e: Either<Error, SpelCompiledExpression>,
): Either<Error, ReturnType<typeof lazyEval>> => e.map(sce => lazyEval(sce));

export const processExpr = (options: EvaluationOptions) => (expression: string) => {
    if (options.stripHashes !== false) {
        return expression.split(/#(?!this)/).join('');
    }
    return expression;
};

const _curriedEvalExpression = compose(evaluateCompiledExpression, memoize(getCompiledExpression));
export const curriedEvalExpression = (expression: string, options: EvaluationOptions = {}) =>
    _curriedEvalExpression(processExpr(options)(expression));
