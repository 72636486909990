import * as React from 'react';
import { useSelector } from 'react-redux';
import { SubmissionError, InjectedFormProps } from 'redux-form';
import { initialValuesSelector as taskAssignmentInitialValuesSelector } from 'bpm/components/TaskDetail/TaskForm/TaskAssignment';
import { RootState } from 'reducers/rootReducer';
import { Button, CircularProgress } from '@material-ui/core';
import { asyncEventsInProgressContext } from 'bpm/components/TaskDetail/asyncEventCountContext';
import validate from 'bpm/components/TaskDetail/TaskForm/validate';
import { GetComponentProps } from 'util/typeUtils';
import useFormDefinition from 'bpm/components/TaskDetail/TaskForm/hooks/useFormDefinition';
import useViewConfig from 'util/hooks/useViewConfig';
import useValueSets from 'util/hooks/useValueSets';
import useEntitiesAreLoading from 'util/hooks/useEntitiesAreLoading';
import useTaskOption from 'bpm/components/TaskDetail/TaskForm/hooks/useTaskOption';
import useLinkedEntitySyncErrors from './Save/hooks/useLinkedEntitySyncErrors';
import useCascadingSave from './Save/hooks/useCascadingSave';
import ErrorDialog from './Save/ErrorDialog';
import WorkingDialog from './Save/WorkingDialog';
import useTaskAttributesAreDirty from './Save/hooks/useTaskAttributesAreDirty';
import { formContext as entityFormContext } from 'components/generics/form/EntityFormContext';
import { formContext as taskFormContext } from 'bpm/components/TaskDetail/TaskForm/FormContext';
import useEvaluateOutcomeExpression from 'bpm/components/TaskDetail/TaskForm/hooks/useEvaluateOutcomeExpression';
import useEntities from 'util/hooks/useEntities';
import { isDisabled } from '..';

export interface SaveTriggerArgs {
    cascadingSave: ReturnType<typeof useCascadingSave>[2];
    openErrorDialog: (data: OpenDialogData) => void;
}
export interface OpenDialogData {
    getTaskFormErrors: () => {};
    formDefinition: RootState['taskForms'][0];
    submissionType: 'save' | 'submit';
}

const translateTaskAttributesKey = n => {
    return n === 'dueDate' ? 'due date' : n;
};
interface SaveButtonProps {
    handleSubmit: InjectedFormProps['handleSubmit'];
    fields: React.ReactElement[];
    taskId: string;
    buttonText: string;
    submissionType: 'save' | 'submit';
    outcome?: string;
    outcomeDisplay?: string;
    onSuccess: (
        response: { redirect?: string; processComplete: boolean; error?: string; nextTaskId?: string },
        submittedTaskFormValues: {},
    ) => void;
    forceDisabled?: boolean;
    ButtonProps?: GetComponentProps<typeof Button>;
}

const SaveTrigger: React.SFC<SaveTriggerArgs & SaveButtonProps> = ({
    cascadingSave,
    openErrorDialog,
    outcome,
    fields,
    submissionType,
    forceDisabled,
    taskId,
    handleSubmit,
    buttonText,
    onSuccess,
    ButtonProps,
}) => {
    const hasEntityErrors = Object.keys(useLinkedEntitySyncErrors()).length > 0;
    const { isDirty: entityIsDirty } = React.useContext(entityFormContext);
    const [isLoading, setIsLoading] = React.useState(false);
    const taskO = useTaskOption(taskId);
    const entitiesAreLoading = useEntitiesAreLoading();
    const entities = useEntities();
    const taskAssignee = useSelector(
        (state: RootState) => taskAssignmentInitialValuesSelector(state, { taskId }).assignee,
    );
    const viewConfig = useViewConfig();
    const formDefinition = useFormDefinition(taskId);
    const valueSets = useValueSets();
    const { eventCount, increment, decrement } = React.useContext(asyncEventsInProgressContext);
    const fc = React.useContext(taskFormContext);
    const isSubmitting = useSelector((state: RootState) => state.bpm.tasks.submitting[taskId]);
    const getTaskFormErrors = React.useCallback(
        (includeFieldLevel: boolean = true) => {
            return validate({
                formDefinition: formDefinition!,
                outcome,
                entities,
                valuesAfterExpressionsApplied: fc.fieldValues,
                visibleAndEditableFields: fc.visibleAndEditableFields,
                viewConfig,
                fields,
                valueSets,
                ignoreFieldLevel: !includeFieldLevel,
            });
        },
        [formDefinition, outcome, entities, fc.fieldValues, fc.visibleAndEditableFields, viewConfig, fields, valueSets],
    );
    const saveIsEditable = useEvaluateOutcomeExpression(formDefinition, '_save', 'editable');
    const saveIsVisible = useEvaluateOutcomeExpression(formDefinition, '_save', 'visibility');
    const currentUser = viewConfig && viewConfig.user && viewConfig.user.login;
    const handleClick = React.useCallback(() => {
        const errors = getTaskFormErrors(true);
        if ((submissionType !== 'save' && Object.keys(errors).length > 0) || (entityIsDirty && hasEntityErrors)) {
            // call handleSubmit so taskForm reduxForm causes
            // syncErrors to update and display
            const displayErrors = getTaskFormErrors(false);
            handleSubmit(() => {
                throw new SubmissionError(Object.assign({ _error: displayErrors }, displayErrors));
            })();
            openErrorDialog({
                formDefinition: formDefinition!,
                getTaskFormErrors,
                submissionType,
            });
        } else {
            increment();
            setIsLoading(true);
            cascadingSave(
                submissionType,
                outcome,
                buttonText,
                taskId,
                formDefinition!,
                fc.fieldValues,
                Object.assign(
                    {},
                    ...Object.keys(fc.fieldValues).map(f => ({
                        [f]: !fc.hiddenFields[f],
                    })),
                ),
                {
                    '*': () => {
                        decrement();
                        setIsLoading(false);
                    },
                },
                () => {
                    decrement();
                    setIsLoading(false);
                },
                (
                    response = {
                        processComplete: false,
                    },
                ) => {
                    decrement();
                    setIsLoading(false);
                    onSuccess(response, fc.fieldValues);
                },
            );
        }
    }, [
        getTaskFormErrors,
        buttonText,
        handleSubmit,
        decrement,
        increment,
        cascadingSave,
        entityIsDirty,
        fc.fieldValues,
        fc.hiddenFields,
        formDefinition,
        hasEntityErrors,
        onSuccess,
        openErrorDialog,
        outcome,
        submissionType,
        taskId,
    ]);
    return submissionType === 'save' && !saveIsVisible ? null : (
        <Button
            variant="contained"
            disabled={
                (submissionType === 'save' && !saveIsEditable) ||
                entitiesAreLoading ||
                forceDisabled ||
                isDisabled(taskO.map(t => t.endDate).toUndefined(), currentUser, taskAssignee) ||
                isSubmitting ||
                eventCount > 0
            }
            onClick={entitiesAreLoading ? undefined : handleClick}
            {...ButtonProps}
        >
            {buttonText}
            {isLoading && <CircularProgress style={{ marginLeft: '1em', height: 20, width: 20 }} />}
        </Button>
    );
};

const SaveButton = ({
    buttonText,
    submissionType,
    outcome,
    onSuccess,
    forceDisabled,
    ButtonProps,
    handleSubmit,
    fields,
    taskId,
}: SaveButtonProps) => {
    const [errorDialogOpen, setErrorDialogOpen] = React.useState<OpenDialogData | false>(false);
    const closeDialog = React.useCallback(() => {
        setErrorDialogOpen(false);
    }, [setErrorDialogOpen]);
    const efc = React.useContext(entityFormContext);
    const resource = (efc.initialValues as { entityType?: string }).entityType;
    const [workingState, clearWorkingState, cascadingSave] = useCascadingSave();
    const { assigneeIsDirty, taskAttributesDirtyKeys, taskAttributesAreDirty } = useTaskAttributesAreDirty();

    const taskAttributesDirtyAlert = React.useCallback(() => {
        alert(
            `Please save task ${
                taskAttributesAreDirty
                    ? taskAttributesDirtyKeys.map(translateTaskAttributesKey).join(assigneeIsDirty ? ', ' : ' and ')
                    : ''
            }${
                assigneeIsDirty && taskAttributesAreDirty ? ' and assignee' : assigneeIsDirty ? 'assignee' : ''
            } before continuing.`,
        );
    }, [taskAttributesAreDirty, assigneeIsDirty, taskAttributesDirtyKeys]);

    if (taskAttributesAreDirty || assigneeIsDirty) {
        return (
            <Button variant="contained" {...ButtonProps} onClick={taskAttributesDirtyAlert}>
                {buttonText}
            </Button>
        );
    }
    return (
        <React.Fragment>
            <ErrorDialog resource={resource} errorDialogOpen={errorDialogOpen} closeDialog={closeDialog} />
            <WorkingDialog resource={resource} workingState={workingState} clearWorkingState={clearWorkingState} />
            <SaveTrigger
                cascadingSave={cascadingSave}
                openErrorDialog={setErrorDialogOpen}
                outcome={outcome}
                fields={fields}
                submissionType={submissionType}
                forceDisabled={forceDisabled}
                taskId={taskId}
                handleSubmit={handleSubmit}
                buttonText={buttonText}
                onSuccess={onSuccess}
                ButtonProps={ButtonProps}
            />
        </React.Fragment>
    );
};
export default SaveButton;
