import { VIEWITEM_FILTER_EXPRESSIONS_LOCALSTORAGE_KEY } from './constants';
import { ViewItemFilterExpressionsGenerated } from './reducer';
import { fromPredicate } from 'fp-ts/lib/Option';
import storage from 'local-storage-fallback';

const initialize = (): ViewItemFilterExpressionsGenerated => {
    return fromPredicate<string>(Boolean)(storage.getItem(VIEWITEM_FILTER_EXPRESSIONS_LOCALSTORAGE_KEY))
        .map(serializedConceptExpressions => JSON.parse(serializedConceptExpressions))
        .getOrElse({});
};
export default initialize;
