import * as React from 'react';
import { SFC, ReactNode } from 'react';
import { createSelector } from 'reselect';
import { Subtract } from 'utility-types';
import compose from 'recompose/compose';
import pure from 'recompose/pure';
import { connect, useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import { CardActions, Button, CircularProgress } from '@material-ui/core';
import Save from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import NavigationRefresh from '@material-ui/icons/Refresh';
import EditButton from '../button/EditButton';
import ShowButton from '../button/RedirectShowButton';
import * as ViewConfigUtils from '../utils/viewConfigUtils/index';
import applyMappings, { ExpressionType } from './applyMappings';
import { RootState } from '../../../reducers/rootReducer';
import { customEntityShowActions, customEntityEditActions } from '../overrides';
import { evaluateExpression, createRootContext } from '../../../expressions/evaluate';
import ViewConfig from '../../../reducers/ViewConfigType';
import WithErrorBoundary from '../fields/WithErrorBoundary';
import { parseConfig } from 'expressions/entityViewConfig/parse';
import { fromNullable, fromEither } from 'fp-ts/lib/Option';
import { reverseLookupAccessLevel } from 'util/accessControlThroughLinkedEntity';
import { createGetEntities } from '../form/EntityFormContext/util/getEntities';
import { getRecordFieldsRequiredForActionButtons } from 'clients/utils/createExpansionQuery/buildCommaSeperatedExpansions';
import { denormalizeEntitiesByPaths } from 'casetivity-shared-js/lib/viewConfigSchema/denormalizing/buildEntityMappingsFromPaths';
import casetivityViewContext, { CasetivityViewContext } from 'util/casetivityViewContext';
import produce from 'immer';
import get from 'lodash/get';
import set from 'lodash/set';
import { expressionTesterProvidedViewConfigurationContext } from 'expression-tester/entity-form';
import InlineDeleteButton from '../button/InlineDeleteButton';
import useViewConfig from 'util/hooks/useViewConfig';
import { entityAndConceptLookupUtils } from 'expressions/expressionArrays';

// MUTATES draftState
// have to null-init things like 'organizations.name' where we apply to all array entries.
// also only set last items in path (they might be missing because they are not set values)
// DON'T create false expansions (expansions should guarantee the data is there and if not there is a problem.)
// the SPEL expression can short circuit on its own.
export const nullInitializeData = (draftState: {}, path: string) => {
    const pathArr = path.split('.');
    pathArr.every((curr, i) => {
        const pathSoFar = pathArr.slice(0, i + 1).join('.');
        const currentValue = get(draftState, pathSoFar);
        // if it's an array and we have more to do, handle the remainder for all items in the array.
        if (Array.isArray(currentValue) && i !== path.length) {
            currentValue.forEach(v => {
                nullInitializeData(v, pathArr.slice(i + 1).join('.'));
            });
            // don't continue
            return false;
        }
        // if the value is missing, null initialize it and don't continue.
        else if (typeof currentValue === 'undefined') {
            set(draftState, pathSoFar, null);
            return false;
        }
        return true;
    });
    return draftState;
};

interface DispatchAction {
    type: String;
    payload: any;
    cb?: any;
}

interface EntityDispatchAction {
    displayRule?: string;
    label: string;
    key: string;
    action: DispatchAction;
    redirectOnSuccess?: string;
}

interface EntityLinkAction {
    displayRule?: string;
    label: string;
    key: string;
    url: string;
}
export type EntityActions = (EntityDispatchAction | EntityLinkAction)[];
export interface Config {
    entityActions: EntityActions;
}

interface BaseButton {
    basePath: string;
    resource: string;
    data?: any; // { id: string, entityType: string };
    accessLevel: number;
}

interface ButtonWrapper extends BaseButton {
    isSaving?: boolean;
    hasEdit: boolean;
    hasShow: boolean;
    hasDelete: boolean;
    hasRefresh: boolean;
    save?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    refresh?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    children?: React.ReactNode;
}

const cardActionStyle = {
    padding: 0,
    zIndex: 0,
    display: 'inline-block',
    float: 'left',
};

class CustomDispatchButtonComponent extends React.Component<
    {
        label: string;
        onClick: (onRequestEnd?: () => void) => void;
    },
    { inProgress: boolean }
> {
    state = { inProgress: false };
    handleClick = () => {
        this.setState({ inProgress: true }, () => this.props.onClick(() => this.setState({ inProgress: false })));
    };
    render() {
        const { label } = this.props;
        return (
            <Button disabled={this.state.inProgress} color="primary" onClick={this.handleClick}>
                {label}
            </Button>
        );
    }
}
const CustomDispatchButton = compose(
    connect(
        null,
        (
            dispatch,
            ownProps: {
                actionConf: {
                    redirectOnSuccess?: ExpressionType;
                    action: ExpressionType;
                };
                data: {};
                resource: string;
            },
        ) => ({
            onClick: (onRequestEnd?: () => void) => {
                dispatch(
                    ownProps.actionConf.redirectOnSuccess
                        ? {
                              ...applyMappings(
                                  ownProps.actionConf.action,
                                  [ownProps.data, ownProps.resource],
                                  ['record', 'resource'],
                              ),
                              cb: (payload, resultData) => {
                                  if (onRequestEnd) {
                                      onRequestEnd();
                                  }
                                  dispatch(
                                      push(
                                          applyMappings(
                                              ownProps.actionConf.redirectOnSuccess!,
                                              [payload, resultData, ownProps.resource],
                                              ['record', 'response', 'resource'],
                                          ),
                                      ),
                                  );
                              },
                              errorCb: onRequestEnd,
                          }
                        : applyMappings(ownProps.actionConf.action, ownProps.data),
                );
            },
        }),
    ),
)(CustomDispatchButtonComponent);

function isDispatchActionButton(buttonDef: EntityLinkAction | EntityDispatchAction): buttonDef is EntityDispatchAction {
    return (buttonDef as EntityDispatchAction).action !== undefined;
}

const customActionsMakeMapsStateToProps = () => {
    const getEntities = createGetEntities();
    const getRecordForDisplayRuleExpression = createSelector(
        (state: RootState, props) => state.actionButtonExps[props.viewName],
        (state: RootState, props) => state.viewConfig,
        getEntities,
        (state: RootState, props) => props.data,
        (state: RootState, props) => props.resource,
        (actionButtonExps, viewConfig, entities, data, entityType) => {
            if (data && actionButtonExps) {
                const fieldsRequired = getRecordFieldsRequiredForActionButtons(actionButtonExps);
                const expandedRoot = denormalizeEntitiesByPaths(
                    entities,
                    fieldsRequired,
                    viewConfig,
                    entityType,
                    data.id,
                );
                return produce(expandedRoot, draftState => {
                    fieldsRequired.forEach(path => {
                        nullInitializeData(draftState, path);
                    });
                    return draftState;
                });
            }
            return fromNullable(entities[entityType])
                .mapNullable(e => data && e[data.id])
                .getOrElse(undefined);
        },
    );
    return (state: RootState, props) => {
        return {
            entities: getEntities(state),
            recordForDisplayRuleExpression: getRecordForDisplayRuleExpression(state, props),
        };
    };
};
export const CustomActions = connect(customActionsMakeMapsStateToProps)(
    ({
        entities = {},
        actionButtonDefinitions,
        children,
        data,
        resource,
        viewConfig,
        viewContext,
        recordForDisplayRuleExpression,
    }: {
        actionButtonDefinitions: (EntityLinkAction | EntityDispatchAction)[];
        children: ReactNode;
        entities: {};
        data?: {};
        resource: string;
        viewContext: CasetivityViewContext;
        viewConfig: ViewConfig;
        recordForDisplayRuleExpression?: {};
    }) => {
        const valueSets = useSelector((state: RootState) => state.valueSets);
        return (
            <CardActions style={{ ...cardActionStyle, padding: '0.5em' } as React.CSSProperties}>
                {recordForDisplayRuleExpression &&
                    actionButtonDefinitions
                        .filter(
                            abd =>
                                !abd.displayRule ||
                                evaluateExpression(abd.displayRule, {
                                    record: recordForDisplayRuleExpression,
                                    resource,
                                    viewContext,
                                    roles: viewConfig.user.roles || {},
                                    viewConfig,
                                    ...entityAndConceptLookupUtils(entities, viewConfig, valueSets),
                                    ...createRootContext({
                                        viewContext,
                                        dateFormat: viewConfig.application.dateFormat,
                                    }),
                                    ...ViewConfigUtils,
                                    options: { dateFormat: viewConfig.application.dateFormat },
                                }) === true,
                        )
                        .map((actionButton, i) =>
                            isDispatchActionButton(actionButton) ? (
                                <CustomDispatchButton
                                    data={data}
                                    key={`customDispatch-${actionButton.label}`}
                                    actionConf={actionButton}
                                    label={actionButton.label}
                                />
                            ) : (
                                <Link
                                    to={applyMappings(actionButton.url, [data, resource], ['record', 'resource'])}
                                    key={`custom-link-${actionButton.key}`}
                                >
                                    <Button color="primary">{actionButton.label}</Button>
                                </Link>
                            ),
                        )}
                {children}
            </CardActions>
        );
    },
);

const BaseActions: SFC<ButtonWrapper & { forceHideDeleteButton: boolean; forceHideEditButton: boolean }> = ({
    accessLevel,
    basePath,
    data,
    resource,
    hasEdit,
    hasShow,
    hasDelete,
    hasRefresh,
    refresh,
    save,
    isSaving,

    forceHideDeleteButton,
    forceHideEditButton,
    children,
}) => {
    const viewConfig = useViewConfig();
    const dispatch = useDispatch();
    return (
        <CardActions style={cardActionStyle as React.CSSProperties}>
            {hasShow && data && data.id && <ShowButton basePath={basePath} record={data} resource={resource} />}
            {!forceHideEditButton && hasEdit && ViewConfigUtils.allowsEdit(accessLevel) && data && data.id && (
                <EditButton basePath={basePath} record={data} resource={resource} />
            )}
            {/* <ListButton icon={<Clear />} label="Close" basePath={basePath} /> */}
            {!forceHideDeleteButton && hasDelete && ViewConfigUtils.allowsDelete(accessLevel) && data && data.id && (
                <InlineDeleteButton
                    resource={resource}
                    id={data.id}
                    renderIcon={({ handleClick, classes }) => (
                        <Button color="secondary" onClick={handleClick} className={classes.del}>
                            Delete <DeleteIcon />
                        </Button>
                    )}
                    onDeleteSuccess={() => {
                        /* redirect to list */
                        dispatch(
                            push(`/${viewConfig.views[viewConfig.entities[resource].defaultViews.LIST.name].route}`),
                        );
                    }}
                />
            )}
            {hasRefresh && (
                <Button color="primary" onClick={refresh}>
                    Refresh&nbsp;
                    <NavigationRefresh />
                </Button>
            )}
            {save && (
                <Button color="primary" onClick={save} disabled={isSaving}>
                    Save <Save /> {isSaving ? <CircularProgress style={{ height: 15, width: 15 }} /> : null}
                </Button>
            )}
            {children}
        </CardActions>
    );
};

const OverrideablePermissionedActionsComponent = ({
    children,
    resource,
    config,
    hardActionsConfig,
    viewConfig,
    linkedEntityType,
    viewName,
    viewContext,
    ...props
}: {
    hardActionsConfig?: {
        [key: string]: EntityActions[0];
    };
    resource: string;
    viewContext: CasetivityViewContext;
    viewName: string;
} & ButtonWrapper &
    ReturnType<ReturnType<typeof makeMapStateToProps>>) => {
    const getEntities = React.useMemo(createGetEntities, []);
    const entities = useSelector(getEntities);
    const valueSets = useSelector((state: RootState) => state.valueSets);
    const reverseLookupLinkedEntityPermission = reverseLookupAccessLevel(viewConfig, linkedEntityType, resource);
    const adjustedAccessLevel = reverseLookupLinkedEntityPermission
        ? (() => {
              if (reverseLookupLinkedEntityPermission === 3) {
                  return props.accessLevel;
              }
              return Math.min(props.accessLevel, reverseLookupLinkedEntityPermission);
          })()
        : props.accessLevel;

    const hardConfig: EntityActions =
        (
            (hardActionsConfig &&
                Object.entries(hardActionsConfig).filter(([regexString, body]) => {
                    const rx = new RegExp(regexString);
                    return rx.test(resource);
                })) ||
            []
        ).map(([rxs, body]) => body) || [];
    const context = {
        record: props.data,
        resource,
        viewContext,
        roles: viewConfig.user.roles || {},
        ...entityAndConceptLookupUtils(entities, viewConfig, valueSets),
        ...createRootContext({
            viewContext,
            dateFormat: viewConfig.application.dateFormat,
        }),
        viewConfig,
        ...ViewConfigUtils,
        options: {
            dateFormat: viewConfig.application.dateFormat,
        },
    };
    const forceHideDeleteButton = fromNullable(config.hideDeleteButton)
        .map(dbv => {
            return evaluateExpression(dbv, context, context) === true;
        })
        .getOrElse(false);

    const forceHideEditButton = fromNullable(config.hideEditButton)
        .map(dbv => evaluateExpression(dbv, context, context) === true)
        .getOrElse(false);

    if ((config && config.entityActions) || hardConfig) {
        return (
            <CustomActions
                viewContext={viewContext}
                viewName={viewName}
                actionButtonDefinitions={[...((config && config.entityActions) || []), ...hardConfig]}
                data={props.data}
                resource={resource}
                viewConfig={viewConfig}
            >
                <BaseActions
                    resource={resource}
                    {...props}
                    accessLevel={adjustedAccessLevel}
                    forceHideDeleteButton={forceHideDeleteButton}
                    forceHideEditButton={forceHideEditButton}
                >
                    {React.Children.map(children, child => {
                        if (!React.isValidElement(child)) {
                            return child;
                        }
                        const childMinAccessLevel = child.props['data-minAccessLevel'];
                        if (
                            !childMinAccessLevel ||
                            (childMinAccessLevel <= adjustedAccessLevel &&
                                !(forceHideEditButton && childMinAccessLevel === 3) &&
                                !(forceHideDeleteButton && childMinAccessLevel === 5))
                        ) {
                            return child;
                        }
                        return null;
                    })}
                </BaseActions>
            </CustomActions>
        );
    }

    return (
        <BaseActions
            resource={resource}
            {...props}
            accessLevel={adjustedAccessLevel}
            forceHideDeleteButton={forceHideDeleteButton}
            forceHideEditButton={forceHideEditButton}
        >
            {children}
        </BaseActions>
    );
};

const viewSelector = <P extends { viewName: string }>(state: RootState, ownProps: P) =>
    state.viewConfig.views && state.viewConfig.views[ownProps.viewName];

const emptyObj = {};
const makeConfigSelector = () =>
    createSelector(
        viewSelector,
        view => {
            return fromNullable(view)
                .map(v => v.config)
                .chain(fromNullable)
                .map(parseConfig)
                .chain(fromEither)
                .getOrElse(emptyObj);
        },
    );

const makeMapStateToProps = () => {
    const configSelector = makeConfigSelector();
    const mapStateToProps = <P extends { viewName: string; data?: { id: string; entityType: string } }>(
        state: RootState,
        ownProps: P,
    ) => ({
        config: configSelector(state, ownProps),
        linkedEntityType:
            ownProps.data &&
            fromNullable(state.admin.entities[ownProps.data.entityType])
                .mapNullable(e => e[ownProps.data.id])
                .map(e => (e as any).linkedEntityType)
                .getOrElse(null),
        viewConfig: state.viewConfig,
    });
    return mapStateToProps;
};

const OverrideablePermissionedActions = compose(
    connect(makeMapStateToProps),
    (BaseComponent: typeof OverrideablePermissionedActionsComponent) => props => {
        return (
            // lets allow overriding of the config. To prevent issues in popups lets key into it by viewName
            <expressionTesterProvidedViewConfigurationContext.Consumer>
                {maybeOverride => (
                    <casetivityViewContext.Consumer>
                        {value => (
                            <WithErrorBoundary>
                                <BaseComponent
                                    {...props}
                                    config={maybeOverride[props.viewName] || props.config}
                                    viewContext={value}
                                />
                            </WithErrorBoundary>
                        )}
                    </casetivityViewContext.Consumer>
                )}
            </expressionTesterProvidedViewConfigurationContext.Consumer>
        );
    },
)(OverrideablePermissionedActionsComponent);

type PropsLeftFromButtonWrapper = Subtract<
    ButtonWrapper,
    {
        hasEdit: ButtonWrapper['hasEdit'];
        hasShow: ButtonWrapper['hasShow'];
        hasDelete: ButtonWrapper['hasDelete'];
        hasRefresh: ButtonWrapper['hasRefresh'];
    }
>;

export type EditActionsProps = {
    hasDefaultShow?: boolean;
    hasDefaultDelete?: boolean;
    accessLevel?: number;
    viewName: string;
    hasShow?: boolean;
    hasDelete?: boolean;
} & PropsLeftFromButtonWrapper;

export const EditActions: SFC<EditActionsProps> = pure(
    ({ hasDefaultShow = true, hasDefaultDelete = true, ...props }: EditActionsProps) => (
        <OverrideablePermissionedActions
            {...props}
            hardActionsConfig={customEntityEditActions}
            hasEdit={false}
            hasShow={!!(hasDefaultShow && props.hasShow)}
            hasDelete={!!(hasDefaultDelete && props.hasDelete)}
            hasRefresh={false}
        />
    ),
);

export type ShowActionsProps = {
    hasDefaultEdit: boolean;
    viewName: string;
} & PropsLeftFromButtonWrapper;

export const ShowActions: SFC<ShowActionsProps> = pure(props => (
    <OverrideablePermissionedActions
        {...props}
        hardActionsConfig={customEntityShowActions}
        hasEdit={props.hasDefaultEdit && props.hasEdit}
        hasShow={false}
        hasDelete={false}
        hasRefresh={false}
    />
));
ShowActions.defaultProps = {
    hasDefaultEdit: true,
};

export type DeleteActionsProps = {
    hasDefaultShow?: boolean;
    hasDefaultEdit?: boolean;
    accessLevel?: number;
    viewName: string;
    hasShow?: boolean;
    hasEdit?: boolean;
} & PropsLeftFromButtonWrapper;

export const DeleteActions: SFC<DeleteActionsProps> = pure(
    ({ hasDefaultShow = true, hasDefaultEdit = true, ...props }: DeleteActionsProps) => (
        <OverrideablePermissionedActions
            {...props}
            hasDelete={false}
            hasShow={!!(hasDefaultShow && props.hasShow)}
            hasEdit={!!(hasDefaultEdit && props.hasEdit)}
            hasRefresh={false}
        />
    ),
);
