import { createSelector } from 'reselect';
import set from 'lodash/set';
import {
    taskClosed,
    getFormDefinition,
    isEntityLinked,
    pickBetween,
    parseInitialValue,
} from 'bpm/components/TaskDetail/TaskForm/getInitialValues';

const getDefaultValues = (fields, data = {}, defaultValue = {}, defaultValues = {}, taskFieldsToOverrideWith = {}) => {
    const globalDefaultValue = typeof defaultValue === 'function' ? defaultValue() : defaultValue;
    const defaultValueFromFields = fields
        .map(child => ({
            source: child.props.source,
            defaultValue: child.props.defaultValue,
        }))
        .reduce((prev, next) => {
            if (next.defaultValue != null) {
                set(
                    prev,
                    next.source,
                    typeof next.defaultValue === 'function' ? next.defaultValue() : next.defaultValue,
                );
            }
            return prev;
        }, {});
    return { ...globalDefaultValue, ...defaultValueFromFields, ...data, ...taskFieldsToOverrideWith };
};

const getFields = (state, props) => props.baseFields;
const getRecord = (state, props) => props.record;
const getDefaultValue = (state, props) => props.defaultValue;
const getDefaultValuesFromState = state => {
    return state.admin.record;
};

const createGetTaskFieldsToOverrideWith = () =>
    createSelector(
        taskClosed,
        getFormDefinition,
        (taskIsClosed, formDefinition) => {
            return taskIsClosed // if the task is closed, lets not read values in to the entity form.
                ? {}
                : formDefinition &&
                      formDefinition.fields &&
                      formDefinition.fields.filter(isEntityLinked).reduce((formObj, field) => {
                          const value = pickBetween(field, taskIsClosed)(undefined, parseInitialValue(field));
                          if (typeof value !== 'undefined') {
                              return {
                                  ...formObj,
                                  [field.id]: value,
                              };
                          }
                          return formObj;
                      }, {});
        },
    );

const createGetDefaultValuesEditForm = () => {
    const getTaskFieldsToOverrideWith = createGetTaskFieldsToOverrideWith();
    return createSelector(
        getFields,
        getRecord,
        getDefaultValue,
        getDefaultValuesFromState,
        getTaskFieldsToOverrideWith,
        (fields, record, defaultValue, defaultValues, taskFieldsToOverrideWith) =>
            getDefaultValues(fields, record, defaultValue, defaultValues, taskFieldsToOverrideWith),
    );
};

export default createGetDefaultValuesEditForm;
