import { SET_AS_TOP_VIEW, UNSET_AS_TOP_VIEW } from '../actions/constants';

export const setAsTopView = viewId => ({
    type: SET_AS_TOP_VIEW,
    payload: viewId,
});

export const unsetAsTopView = viewId => ({
    type: UNSET_AS_TOP_VIEW,
    payload: viewId, // not necessary to pass this, BUT doing so anyways to catch errors.
});
