import React from 'react';
import { CardActions, Button, IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import PopoverCreateButton from '../../../fieldFactory/popovers/PopoverCreateButton';
import { allowsCreate } from '../utils/viewConfigUtils';
import { customEntityListActions } from '../overrides';
import { useHideCreateButton } from './ListActions';

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

const PermissionedListActions = ({
    listViewName,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    refresh,
    exportList,
    accessLevel,
    parentEntityName,
    parentFieldInChild,
    parentId,
    injectCreateValues,
    onCreateCb,
    handleClose,
}) => {
    const hideCreateButton = useHideCreateButton(listViewName);
    const customActions = customEntityListActions[resource];
    const closeButton = handleClose ? (
        <IconButton aria-label="Close popover" onClick={handleClose}>
            <Close />
        </IconButton>
    ) : null;
    return customActions ? (
        <CardActions style={cardActionStyle}>
            {customActions.map((action, i) => (
                <Link to={action.url} key={`custom-link-${action.key}`}>
                    <Button color="primary">{action.label}</Button>
                </Link>
            ))}
            {closeButton}
        </CardActions>
    ) : (
        <CardActions style={cardActionStyle}>
            {allowsCreate(accessLevel) && !hideCreateButton && (
                <PopoverCreateButton
                    widthStyles={{ small: {} }}
                    resource={resource}
                    label="Create"
                    resourceBasePath={`/${resource}`}
                    parentEntityName={parentEntityName}
                    parentFieldInChild={parentFieldInChild}
                    parentId={parentId}
                    onCreateCb={onCreateCb}
                    injectCreateValues={injectCreateValues}
                    key="list-backref-createbtn"
                />
            )}
            {closeButton}
        </CardActions>
    );
};

PermissionedListActions.defaultProps = {
    accessLevel: 0,
    showFilter: true,
    basePath: '',
    filterValues: {},
    displayedFilters: {},
};

export default PermissionedListActions;
