import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, CardContent, Button, CardActions, Toolbar } from '@material-ui/core';
import ActionCheck from '@material-ui/icons/Check';
import AlertError from '@material-ui/icons/Error';
import compose from 'recompose/compose';
import ViewTitle from 'components/generics/ViewTitle';
import withProps from 'recompose/withProps';
import { crudDelete as crudDeleteAction } from 'sideEffect/crud/delete/actions';
import { crudGetOne as crudGetOneAction } from 'sideEffect/crud/getOne/actions';
import EntityPageTitle from '../fields/display/EntityPageTitle';
import SsgAppBarMobile from 'components/SsgAppBarMobile';
import withPopoverLock from '../hoc/popoverLock';

/*
This Delete component is supposed to be used for popovers.
The normal Aor Delete is fine, and we use it for /delete routes.
*/
const styles = {
    actions: { zIndex: 2, display: 'inline-block', float: 'right' },
    toolbar: { clear: 'both' },
    button: { margin: '10px 24px', position: 'relative' },
};

class Delete extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.props.crudGetOne({
            resource: this.props.resource,
            id: this.props.id,
        });
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.id !== nextProps.id) {
            this.props.crudGetOne({
                resource: nextProps.resource,
                id: nextProps.id,
            });
        }
    }

    getBasePath() {
        return `/${this.props.resource}`;
    }

    handleSubmit(event) {
        event.preventDefault();
        this.props.crudDelete({
            resource: this.props.resource,
            id: this.props.id,
            cb: this.props.onDeleteCb,
        });
    }

    render() {
        const { id, data, isLoading, resource } = this.props;
        const defaultTitle = `Delete ${resource} ${id}`;
        const titleElement = (
            <b>
                <EntityPageTitle record={data} resource={resource} defaultTitle={defaultTitle} viewName="Delete " />
            </b>
        );

        return (
            <React.Fragment>
                {this.props.createMobileAppBar ? <SsgAppBarMobile title={titleElement} /> : null}
                <div>
                    <Card style={{ opacity: isLoading ? 0.8 : 1, padding: '1%' }}>
                        <CardActions style={styles.actions}>
                            {this.props.actions &&
                                React.cloneElement(this.props.actions, {
                                    resource: this.props.resource,
                                    viewName: `${this.props.resource}Delete`,
                                })}
                        </CardActions>
                        <ViewTitle
                            title={titleElement}
                            displayAboveWidth={this.props.createMobileAppBar ? 'xs' : undefined}
                        />
                        <div style={{ clear: 'both' }} />
                        <form autoComplete="off" onSubmit={this.handleSubmit}>
                            <CardContent>Are you sure?</CardContent>
                            <Toolbar style={styles.toolbar}>
                                <Button type="submit" color="primary" variant="contained" style={styles.button}>
                                    Delete <ActionCheck />
                                </Button>
                                &nbsp; &nbsp;
                                <Button
                                    variant="contained"
                                    label="Cancel"
                                    onClick={this.props.onCancelCb}
                                    style={styles.button}
                                >
                                    Cancel <AlertError />
                                </Button>
                            </Toolbar>
                        </form>
                    </Card>
                </div>
            </React.Fragment>
        );
    }
}

Delete.propTypes = {
    title: PropTypes.any,
    id: PropTypes.string.isRequired,
    resource: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    data: PropTypes.object,
    isLoading: PropTypes.bool.isRequired,
    crudGetOne: PropTypes.func.isRequired,
    crudDelete: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
    return {
        id: decodeURIComponent(props.match.params.id),
        data: (state.admin.entities[props.resource] || {})[decodeURIComponent(props.match.params.id)],
        isLoading: state.admin.loading > 0,
    };
}

const enhance = compose(
    connect(
        mapStateToProps,
        { crudGetOne: crudGetOneAction, crudDelete: crudDeleteAction },
    ),
    withProps(props => {
        return {
            formId: `Delete:${props.resource}:${props.id}`,
        };
    }),
    withPopoverLock,
);

export default enhance(Delete);
