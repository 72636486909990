import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm /* initialize */ } from 'redux-form';
import compose from 'recompose/compose';
import { createSelector } from 'reselect';
import { fromNullable, none, tryCatch, some, fromPredicate } from 'fp-ts/lib/Option';
import { mapOption } from 'fp-ts/lib/Array';
import { adjustFieldName } from '../../../fieldFactory/util/FormField';
import { RootState } from '../../../reducers/rootReducer';
import ViewConfig from '../../../reducers/ViewConfigType';
import { validate } from '../form/validate';
import { Button } from '@material-ui/core';
import RGridInner from '../fields/display/RGrid';
import { standardPadding } from 'fieldFactory/mapToFormField';
import { evaluatePreFilter } from '../utils/viewConfigUtils';

const makeMapStateToProps = () => {
    const hiddenFieldsSelector = createGetSearchFieldsByCriteriaSelector(({ visible, prefilter }) => visible === false);
    const disabledFieldsSelector = createGetSearchFieldsByCriteriaSelector(({ visible, prefilter, viewConfig }) => {
        const somePrefilterResult = (() => {
            if (prefilter || (prefilter as any) === false || (prefilter as any) === 0) {
                const prefilterResult = evaluatePreFilter(viewConfig)(prefilter);
                return prefilterResult.chain(fromPredicate(Boolean)).isSome();
            }
            return false;
        })();
        if (visible === 'disabled_if_prefiltered' && somePrefilterResult) {
            return true;
        }
        return visible === true && somePrefilterResult;
    });
    const mapStateToProps = (state: RootState, props) => ({
        hiddenFields: hiddenFieldsSelector(state, props),
        disabledFields: disabledFieldsSelector(state, props),
        // REMOVE THIS! (this is fixed on appcase refactoring branch)
        searchValidations: state.searchValidations[`${props.resource}List`],
        form: props.formId || 'filterForm',
        viewConfig: state.viewConfig,
        concepts: state.admin.entities.Concept,
        activeField:
            state.form && state.form[props.formId || 'filterForm']
                ? state.form[props.formId || 'filterForm'].active
                : '',
        registeredFields:
            state.form && state.form[props.formId || 'filterForm']
                ? state.form[props.formId || 'filterForm'].registeredFields
                : '',
        valueSets: state.valueSets,
    });
    return mapStateToProps;
};
type ConnectedProps = ReturnType<ReturnType<typeof makeMapStateToProps>>;
interface FormProps extends Pick<ConnectedProps, 'searchValidations' | 'concepts' | 'viewConfig' | 'valueSets'> {
    filtersAttributes?: {
        source: string;
        searchType?: string;
    }[];
    setFilters: (filters: {}) => void;
    children: (arg: { handleSubmit: any }) => JSX.Element;
}
const Form = reduxForm<{}, FormProps>({
    enableReinitialize: true,
    destroyOnUnmount: true,
    onChange: (values, dispatch, props: FormProps) => {
        // tslint:disable-line
        props.setFilters(values);
    },
    validate: (values, props: FormProps) => {
        // tslint:disable-line
        const newValues = Object.assign(
            {},
            ...(props.filtersAttributes || [])
                .map(({ source, searchType }) => {
                    return `${source}${searchType ? `__${searchType}` : ''}`;
                })
                .map(k => ({ [k]: null })),
            ...Object.entries(values).map(([key, value]) => ({ [key]: value === '' ? null : value })),
        );
        return validate(
            newValues,
            props.searchValidations,
            props.concepts,
            {
                dateFormat: props.viewConfig && props.viewConfig.application && props.viewConfig.application.dateFormat,
            },
            props.valueSets,
            props.viewConfig,
        );
    },
})(props => {
    // /*
    //     Workaround:
    //     ReduxForm doesn't reinitialize correctly when unmounted + remounted with different initial values.
    // */
    // // https://github.com/redux-form/redux-form/issues/4152
    // const dispatch = useDispatch();
    // React.useEffect(() => {
    //     if (props.initialValues) {
    //         dispatch(
    //             initialize(props.form, props.initialValues, true, {
    //                 keepValues: false,
    //                 updateUnregisteredFields: true,
    //             }),
    //         );
    //     }
    // }, []); // eslint-disable-line
    return props.children({
        handleSubmit: props.handleSubmit,
    });
});

const createGetSearchFieldsByCriteriaSelector = (
    matching: (config: {
        visible?: boolean | 'default' | 'disabled_if_prefiltered';
        prefilter?: string;
        viewConfig: ViewConfig;
    }) => boolean,
) =>
    createSelector(
        (state: RootState, props: FilterFormProps) => props.viewName,
        (state: RootState, props: FilterFormProps) => state.viewConfig,
        (viewName: string | undefined, viewConfig: ViewConfig): { [fieldName: string]: true } =>
            fromNullable(viewName)
                .chain(vn => fromNullable(viewConfig.views[vn]))
                .chain(v => fromNullable(v.searchFields))
                .fold<{ [fieldName: string]: true }>({}, sf =>
                    Object.assign(
                        {},
                        ...mapOption<[string, typeof sf[0]], { [fieldName: string]: true }>(
                            Object.entries(sf),
                            ([field, sfi]) =>
                                fromNullable(sfi.config || null)
                                    .chain(sfconf => tryCatch(() => JSON.parse(sfconf)))
                                    .chain(fc =>
                                        matching({ ...fc, viewConfig })
                                            ? some<{ [fieldName: string]: true }>({ [field]: true })
                                            : none,
                                    ),
                        ),
                    ),
                ),
    );

const emptyRecord = {};
const Hidden = props => <div style={{ display: 'none' }}>{props.children}</div>;

interface FilterFormProps {
    resource: string;
    initialValues: {};
    setFilters: (filters: {}) => void;
    submitFilters: (state?: { filters: {} }) => void;
    clearFilters: () => void;
    permanentFilter: {};
    referencedFromEntity?: boolean;
    viewConfig: ViewConfig;
    viewName?: string;
    formId: string;
    filters: React.ReactElement<{
        row?: number;
        source: string;
        replacePeriodsInFieldName?: boolean;
        searchType?: string; // e.g. CONTAINS, EQUAL etc.
        style?: {};
    }>[];
    filtersAttributes?: {
        source: string;
        searchType?: string;
    }[];
    clearValuesInForm: (fields?: string[]) => void;
    showButtons: boolean;
}

export const appendArrayIfNonEmpty = (arrayToAppendTo, arrayToAppend) =>
    arrayToAppendTo.length > 0 ? [...arrayToAppendTo, ...arrayToAppend] : [];

const getName = (keepIdOrIdsPostfix: boolean) => (filterElement: FilterFormProps['filters'][0], i) => {
    if (filterElement.props.source) {
        const source = (() => {
            const _source = filterElement.props.replacePeriodsInFieldName
                ? adjustFieldName(filterElement.props.source, filterElement.props.replacePeriodsInFieldName)
                : filterElement.props.source;
            if (keepIdOrIdsPostfix) {
                return _source;
            }
            return _source.endsWith('Id')
                ? _source.slice(0, -2)
                : _source.endsWith('Ids')
                ? _source.slice(0, -3)
                : _source;
        })();
        return `${source}${filterElement.props.searchType ? `__${filterElement.props.searchType}` : ''}`;
    }
    return i;
};

const Wrapped = props => <div style={standardPadding}>{props.children}</div>;

export const FilterFormComponent: React.SFC<
    FilterFormProps & ReturnType<ReturnType<typeof makeMapStateToProps>> & { handleSubmit: Function }
> = ({
    resource,
    submitFilters,
    clearValuesInForm,
    filters,
    hiddenFields,
    disabledFields,
    showButtons,
    initialValues,
    concepts,
    valueSets,
    searchValidations,
    viewConfig,
    filtersAttributes,
    setFilters,
    form,
}) => {
    return (
        <Form
            setFilters={setFilters}
            form={form}
            viewConfig={viewConfig}
            searchValidations={searchValidations}
            concepts={concepts}
            valueSets={valueSets}
            filtersAttributes={filtersAttributes}
            initialValues={initialValues}
        >
            {({ handleSubmit }) => (
                <div>
                    <RGridInner
                        fields={(filters || []).map((filterElement, i) => {
                            const name = getName(false)(filterElement, i);
                            const source = getName(true)(filterElement, i);
                            return (
                                <Wrapped key={i} {...filterElement.props}>
                                    {' '}
                                    {hiddenFields[name.split('_~_').join('.')] ? (
                                        <Hidden {...filterElement.props}>
                                            {filterElement.props.source ? (
                                                <Field
                                                    allowEmpty={true}
                                                    {...filterElement.props}
                                                    name={source}
                                                    key={source}
                                                    component={filterElement.type}
                                                    resource={resource}
                                                    record={emptyRecord}
                                                />
                                            ) : (
                                                filterElement
                                            )}
                                        </Hidden>
                                    ) : disabledFields[name] ? (
                                        <filterElement.type
                                            {...filterElement.props}
                                            resource={resource}
                                            disabled={true}
                                            input={{
                                                value: initialValues[getName(true)(filterElement, i)],
                                            }}
                                            meta={{}}
                                        />
                                    ) : filterElement.props.source ? (
                                        <Field
                                            allowEmpty={true}
                                            {...filterElement.props}
                                            name={source}
                                            component={filterElement.type}
                                            resource={resource}
                                            record={emptyRecord}
                                        />
                                    ) : (
                                        filterElement
                                    )}
                                </Wrapped>
                            );
                        })}
                    />
                    {showButtons && (filters || []).length > 0 && (
                        <div key="searchbtngroup" style={{ ...standardPadding, marginTop: '0.5em' }}>
                            <Button variant="contained" color="primary" onClick={handleSubmit(() => submitFilters())}>
                                <span style={{ width: 45 }}>Search</span>
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    const toClear = filters
                                        .filter((f, i) => {
                                            return !disabledFields[getName(false)(f, i)];
                                        })
                                        .map(getName(true));
                                    clearValuesInForm(toClear);
                                }}
                            >
                                <span style={{ width: 45 }}>Clear</span>
                            </Button>
                        </div>
                    )}
                    <div style={{ clear: 'right' }} />
                </div>
            )}
        </Form>
    );
};

FilterFormComponent.defaultProps = {
    initialValues: {},
};

const enhance = compose(
    connect(
        makeMapStateToProps,
        null,
    ),
);

const FilterForm: React.SFC<FilterFormProps> = enhance(FilterFormComponent);
export default FilterForm;
