import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Select as SelectField, MenuItem, Button } from '@material-ui/core';
import 'react-grid-layout/css/styles.css';
import WithWidgets from './WithWidgets';
import { RootState } from 'reducers/rootReducer';
import { setPrimaryDash } from 'dashboard2/dashboard-config/user-primary-dashboard/set-primary-dashboard/actions';
import { setCurrentDash } from 'dashboard2/dashboard-config/current-dashboard/actions';
import Grid from 'dashboard2/grid/components/Grid';
import { createSelector } from 'reselect';
import { fromPredicate } from 'fp-ts/lib/Option';
import memoizeOne from 'memoize-one';

interface DashboardProps {}
const makeMapStateToProps = () => {
    const getDashboardNameFromId = createSelector(
        (state: RootState, props: { dashboardId: string }) => props.dashboardId,
        (state: RootState, props: { dashboardId: string }) => state.dashboard.dashboards.configs,
        (dashboardId, dashConfigs) => {
            const matchingDash = Object.entries(dashConfigs).find(([name, dc]) => dc.id === dashboardId);
            return matchingDash && matchingDash[0];
        },
    );
    const mapStateToProps = (state: RootState, props: DashboardProps) => {
        const {
            dashboard: { dashboards, currentDashboard, userPrimaryDashboard },
        } = state;
        return {
            userLogin: state.viewConfig && state.viewConfig.user.login,
            allDashboardNames: dashboards.allDashboardNames,
            allConfigs: dashboards.configs,
            userPrimaryDashboard: userPrimaryDashboard.map(dashboardId =>
                dashboardId
                    ? {
                          id: dashboardId,
                          name: getDashboardNameFromId(state, { dashboardId }),
                      }
                    : false,
            ),
            currentDashboardName: currentDashboard === -1 ? null : currentDashboard,
            currentDashboardId:
                currentDashboard === -1 || !dashboards.configs[currentDashboard]
                    ? null
                    : dashboards.configs[currentDashboard].id,
        };
    };
    return mapStateToProps;
};

const dispatches = {
    updatePrimaryDash: setPrimaryDash,
    setCurrentDashboard: setCurrentDash,
};
type Dispatches = typeof dispatches;

interface DashboardComponentProps
    extends DashboardProps,
        ReturnType<ReturnType<typeof makeMapStateToProps>>,
        Dispatches {}
class Dashboard extends React.Component<DashboardComponentProps> {
    _memoIncludes = memoizeOne((arr: string[], searchElement: string) => {
        return arr && arr.includes(searchElement);
    });
    handleDashboardChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        this.props.setCurrentDashboard(event.target.value);
    };
    getCurrentDashboardName = () => {
        const { currentDashboardName, userPrimaryDashboard, allDashboardNames } = this.props;
        return (
            currentDashboardName ||
            userPrimaryDashboard
                .toOption()
                .chain(d =>
                    allDashboardNames.toOption().map(
                        dashboardNames =>
                            ({
                                userPrimaryDashboard: d,
                                allDashNames: dashboardNames,
                            } as const),
                    ),
                )
                .fold(undefined, ({ userPrimaryDashboard, allDashNames }) => {
                    if (userPrimaryDashboard && this._memoIncludes(allDashNames, userPrimaryDashboard.name)) {
                        return userPrimaryDashboard.name;
                    }
                    return allDashNames[0];
                })
        );
    };
    setPrimaryDashboardAsCurrent = () => {
        this.props.updatePrimaryDash(
            this.props.currentDashboardId || this.props.allConfigs[this.getCurrentDashboardName()].id,
        );
    };
    userIsAnonymous = () => {
        return !this.props.userLogin || this.props.userLogin === 'anonymousUser';
    };
    render() {
        const { allDashboardNames, userPrimaryDashboard } = this.props;
        const currentDashboardName = this.getCurrentDashboardName();
        if (allDashboardNames.isFailure()) {
            return 'Failed to load dashboards: ' + allDashboardNames.error.message;
        }
        if (userPrimaryDashboard.isFailure()) {
            return 'Failed to get user primary dashboard: ' + userPrimaryDashboard.error.message;
        }
        if (!allDashboardNames.isSuccess() || !currentDashboardName) {
            return <div>Loading...</div>;
        }

        return (
            <div>
                {!this.userIsAnonymous() && (
                    <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '5px', paddingBottom: '10px' }}>
                        <span>Dashboard:</span>
                        &nbsp;&nbsp;
                        <SelectField
                            style={{ minWidth: '150px', marginRight: 15, minHeight: 36 }}
                            aria-label="Select Dashboard"
                            onChange={this.handleDashboardChange}
                            value={currentDashboardName}
                        >
                            {allDashboardNames.value.map(key => (
                                <MenuItem key={key} value={key} id={key}>
                                    {key}
                                </MenuItem>
                            ))}
                        </SelectField>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.setPrimaryDashboardAsCurrent}
                            style={{
                                display:
                                    currentDashboardName ===
                                    userPrimaryDashboard
                                        .toOption()
                                        .chain(fromPredicate<{ id: string; name: string }>(Boolean))
                                        .map(d => d.name)
                                        .getOrElse(null)
                                        ? 'none'
                                        : undefined,
                            }}
                        >
                            Make Default
                        </Button>
                    </div>
                )}
                {/* WidgetElements remain mounted between dashboard changes if they are on the next dash,
                    so we have to add key={currentDashboardName} so everything is remounted.
                */}
                <WithWidgets dashboardName={currentDashboardName} key={currentDashboardName}>
                    {({ widgetElements, grid }) =>
                        grid &&
                        widgetElements && (
                            <Grid dashboardName={currentDashboardName} widgets={widgetElements} grid={grid} />
                        )
                    }
                </WithWidgets>
            </div>
        );
    }
}

const enhance = compose(
    connect(
        makeMapStateToProps,
        dispatches,
    ),
);

export default enhance(Dashboard) as React.ComponentType<DashboardProps>;
