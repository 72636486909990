import * as React from 'react';
import pure from 'recompose/pure';
import { ReactElement, SFC } from 'react';
import { Grid } from '@material-ui/core';
import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';
import WithErrorBoundary from '../WithErrorBoundary';
import memoize from 'lodash/memoize';
const UncheckedGrid: any = Grid; //tslint:disable-line
/*
    span	the number of cells,0 corresponds to display: none
    offset	the number of cells to the left of the grid spacing, no cell in grid spacing
    order	col number in the row
    xs	<576px and also default setting, could be a span value or a object contain above props
    sm	≥576px, could be a span value or a object contain above props
    md	≥768px, could be a span value or a object contain above props
    lg	≥992px, could be a span value or a object contain above props
    xl	≥1200px, could be a span value or a object contain above props
    https://github.com/evgenyrodionov/flexboxgrid2
*/

const encloseInARow = (cols, flexStart = false) => {
    return (
        <Grid item={true} xs={12}>
            <Grid container={true} spacing={0}>
                {cols}
            </Grid>
        </Grid>
    );
};

const getGridStyle = memoize((rowMargin: 'normal' | 'none', alignSelf: boolean) => {
    const rowMarginStyle = rowMargin === 'normal' ? { marginTop: '16px', marginBottom: '8px' } : {};
    return alignSelf ? { alignSelf: 'flex-start', ...rowMarginStyle } : rowMarginStyle;
});
const encloseInACol = (
    field,
    dropdownsFlipUp = false,
    smSize: number | null = 12,
    xsSize: number | null = smSize,
    rowMargin = 'normal',
) => {
    if (field === undefined) {
        return <Grid item={true} xs={12} sm={12} md={12} lg={12} />; // TODO: Add support for blank rows here
    } // } else if (field.props.hasTable) { TODO: un-comment this if span should not be specified for tables
    //     return (
    //         <Col xs={12} sm={12} md={12} lg={12}>
    //             {field}
    //         </Col>);
    // }

    if (field.props.dontShowCol) {
        return <div style={{ display: 'none' }} />;
    }
    return (
        <UncheckedGrid
            item={true}
            xs={(xsSize === null ? field.props.span : xsSize) || false}
            sm={(smSize === null ? field.props.span : smSize) || false}
            md={field.props.span || false}
            lg={field.props.span || false}
            xl={field.props.span || false}
            style={getGridStyle(rowMargin as 'normal' | 'none', field.props.alignSelf)}

            // TODO: adjust for larger screen size, need to override
            // https://github.com/edmon1024/flexboxgrid2/blob/master/flexboxgrid2.css
            // xl={field.props.span === 12 ? 12 : Math.floor(field.props.span * 0.7)}
        >
            <WithErrorBoundary>
                <div>
                    {dropdownsFlipUp ? React.cloneElement(field, { dropdownPosition: 'above' }) : field}
                    {field.props.renderDesc ? (
                        <div style={{ paddingLeft: '10px', marginTop: '-5px' }}>{field.props.renderDesc()}</div>
                    ) : null}
                </div>
            </WithErrorBoundary>
        </UncheckedGrid>
    );
};

const encloseInAGrid = rows => (
    <div>
        <Grid alignItems="flex-end" container={true} spacing={0}>
            {rows.map((row, key) => React.cloneElement(row, { key }))}
        </Grid>
    </div>
);

const orderFieldsByCol = fields => {
    return orderBy(fields, 'props.column', 'asc');
};

interface RGridProps {
    smSize?: number | null;
    xsSize?: number | null;
    flexStart?: boolean;
    fields?: ReactElement<{
        row?: number;
        span?: number;
    }>[];
    lastRowDropdownsFlipUp?: boolean;
    rowMargin?: 'none' | 'normal';
}

const sortFieldGroupsByRow = fields => {
    let sortedFields = fields;
    if (fields) {
        sortedFields = fields
            .filter(field => field != null)
            .sort((field1, field2) => {
                if (!field1.props.row && !field2.props.row) {
                    return 0;
                }
                if (!field1.props.row && field2.props.row) {
                    return 1;
                }
                if (field1.props.row && !field2.props.row) {
                    return -1;
                }
                if (field1.props.row && field2.props.row) {
                    if (field1.props.row < field2.props.row) {
                        return -1;
                    }
                    if (field1.props.row > field2.props.row) {
                        return 1;
                    }
                }
                return 0;
            });
    }

    return groupBy(sortedFields, 'props.row');
};

const RGrid: SFC<RGridProps> = pure(
    ({ fields, lastRowDropdownsFlipUp = false, flexStart, smSize, xsSize, rowMargin }: RGridProps) => {
        let fieldGroupsByRow = sortFieldGroupsByRow(fields);

        const rows: ReactElement<{}>[] = [];

        const fieldGroups = Object.values(fieldGroupsByRow);
        fieldGroups.forEach((group, i) => {
            const orderedFieldsOfARow = orderFieldsByCol(group);
            const cols: ReactElement<{}>[] = [];
            if (orderedFieldsOfARow.length > 0) {
                const isLastRow = i === fieldGroups.length - 1;
                orderedFieldsOfARow.forEach((field, key) => {
                    cols.push(
                        React.cloneElement(
                            encloseInACol(field, lastRowDropdownsFlipUp && isLastRow, smSize, xsSize, rowMargin),
                            { key },
                        ),
                    );
                });
            } else {
                cols.push(encloseInACol(undefined));
            }
            rows.push(encloseInARow(cols, flexStart));
        });

        return <div style={{ width: '100%' }}>{encloseInAGrid(rows)}</div>;
    },
);

export default RGrid;
