import { GenericList, GenericListWithDefaultProps } from '../../../components/generics/genericList';
import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import withPropsOnChange from 'recompose/withPropsOnChange';
import { Subtract } from 'utility-types';
import { loadValueSet as loadValueSetAction } from '../../../actions/valueSetsActions';
import {
    getRefEntityName,
    isRefOneField,
    isFieldViewField,
} from '../../../components/generics/utils/viewConfigUtils/index';
import { setAsTopView, unsetAsTopView } from '../../../popoverStackManagement/actions';
import { withFieldFactory } from '../../../fieldFactory/Broadcasts';
import { DataSource, Mode } from '../../../fieldFactory/FieldFactoryProvider';
import { withWidth } from '@material-ui/core';
import TextField from 'fieldFactory/display/components/aor/TextField';
import ViewConfig, { FieldViewField } from '../../../reducers/ViewConfigType';
import { fieldSubscriberHoc } from '../../../fieldFactory/display/experimental/FieldSubscriber';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';
import { RootState } from 'reducers/rootReducer';
import { fromNullable } from 'fp-ts/lib/Option';

const SubscribedTextField = fieldSubscriberHoc(TextField);

export const vfsColumn: FieldViewField[] = [
    {
        field: 'caseNumber',
        label: 'Case Number',
        widgetType: 'TEXTBOX',
        entity: 'AppCase',
    },
    {
        field: 'processInstance.startTime',
        label: 'Opened Date',
        widgetType: 'CALENDARTIME',
        entity: 'ProcessInstance',
    },
];

type ConnectedProps = Pick<
    GenericListWithDefaultProps,
    'loadValueSets' | 'viewConfig' | 'setAsTopView' | 'unsetAsTopView' | 'fields' | 'width'
>;

const getProcessDefinitionColumnFields = (viewConfig: ViewConfig, processDefinitionKey: string): FieldViewField[] => {
    const processConfig = viewConfig.processes[processDefinitionKey];
    if (processConfig) {
        const searchView = processConfig.views.SEARCH;
        if (searchView) {
            return Object.values(searchView.columns).filter(isFieldViewField) || [];
        }
    }
    return [];
};

const getProcessListColumnFields = (viewConfig: ViewConfig, processDefinitionKey: string) => {
    const includeLeadingDefaultFieldsWhenProcessKeyGiven = fromNullable(viewConfig.application.config)
        .mapNullable(c => c.processSearchDefaultColumns)
        .getOrElse(true);
    if (includeLeadingDefaultFieldsWhenProcessKeyGiven) {
        return [...vfsColumn, ...getProcessDefinitionColumnFields(viewConfig, processDefinitionKey)];
    }
    if (processDefinitionKey) {
        const configuredFields = getProcessDefinitionColumnFields(viewConfig, processDefinitionKey);
        if (configuredFields.length === 0) {
            return vfsColumn;
        }
        return configuredFields;
    }
    return vfsColumn;
};

const enhance = compose(
    withWidth({
        initialWidth: 'md',
    }),
    withFieldFactory,
    connect(
        ({ viewConfig }: RootState) => ({ viewConfig }),
        {
            loadValueSet: loadValueSetAction,
            setAsTopView,
            unsetAsTopView,
        },
    ),
    branch(
        props => !props.viewConfig || Object.keys(props.viewConfig).length === 0, // if no viewConfig, render nothing.
        renderNothing,
    ),
    withPropsOnChange(['processDefinitionKey', 'fieldFactory', 'referencedFromEntity'], props => {
        const { viewConfig, referencedFromEntity, processDefinitionKey } = props;
        const config = {
            dataSource: DataSource.ENTITY,
            mode: Mode.DISPLAY,
            validate: false,
            connected: false,
            options: {
                getOwnData: true,
            },
        };
        const fields = props.fieldFactory(config)({ neverDisabled: true })(
            getProcessListColumnFields(viewConfig, processDefinitionKey).filter(field => {
                if (!isFieldViewField(field)) {
                    // ignore expression fields for now.
                    return false;
                }
                return (
                    !referencedFromEntity ||
                    !isRefOneField(viewConfig, 'AppCase', field.field.split('.')[0]) ||
                    referencedFromEntity !== getRefEntityName(viewConfig, 'AppCase', field.field.split('.')[0])
                );
            }),
        );
        const primaryEntityFields = props.processDefinitionKey
            ? []
            : [
                  <SubscribedTextField
                      key="linkedEntity.title"
                      label="Primary Entity"
                      source="linkedEntity.title"
                      resource="AppCase"
                      addField={true}
                      sortable={false}
                      defaultValue="none"
                  />,
                  <SubscribedTextField
                      key="processInstance.processDefinition.name"
                      label="Type"
                      source="processInstance.processDefinition.name"
                      resource="AppCase"
                      sortable={false}
                      addField={true}
                  />,
              ];
        return {
            fields: [...fields.slice(0, 1), ...primaryEntityFields, ...fields.slice(1)],
        };
    }),
);

type GenericListProps = Subtract<GenericListWithDefaultProps, ConnectedProps> & {
    processDefinitionKey?: string;
    showType: boolean;
};

const EnhancedProcessList: React.SFC<GenericListProps> = enhance(GenericList);
export default EnhancedProcessList;
