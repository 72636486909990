import React from 'react';
import { RootState } from 'reducers/rootReducer';
import ViewConfig from 'reducers/ViewConfigType';
import getEmptyRequiredOptionFormFields from './util/getEmptyRequiredOptionFormFields';
import setupFieldValuesFromTable from './util/setupFieldValuesFromTable';
import {
    getEvaluator,
    errorsAsMessages,
    getEvaluatedValidations,
    flowablePreprocessValuesForEval,
} from 'expressions/formValidation';
import convertErrorMessagesToTableFormat from './util/convertErrorMessagesToTableFormat';
import deepExtend from 'deep-extend';
import { mapOption } from 'fp-ts/lib/Array';
import { fromNullable } from 'fp-ts/lib/Option';
import { FormFieldUnion } from 'fieldFactory/translation/fromFlowable/types';
import get from 'lodash/get';
import { ValueSets } from 'reducers/valueSetsReducer';
import { entityAndConceptLookupUtils } from 'expressions/expressionArrays';

interface ValidateArgs {
    outcome?: string;
    formDefinition: RootState['taskForms'][0];
    entities: {};
    valuesAfterExpressionsApplied: {};
    visibleAndEditableFields: string[];
    viewConfig: ViewConfig;
    fields: React.ReactElement<{
        source?: string;
        warn?: ((value: any, allValues: {}, props: {}) => string | undefined)[];
    }>[]; // tslint:disable-line
    valueSets: ValueSets;
    ignoreFieldLevel?: boolean;
}

const validate = ({
    outcome,
    formDefinition,
    entities,
    valuesAfterExpressionsApplied,
    visibleAndEditableFields,
    viewConfig,
    fields,
    valueSets,
    ignoreFieldLevel,
}: ValidateArgs) => {
    const fieldErrors: { [f: string]: string[] } = !ignoreFieldLevel
        ? Object.assign(
              {},
              ...fields.map(f => {
                  const { warn, source } = f.props;
                  if (warn && source) {
                      const errs = warn.flatMap(warnfn => {
                          const res = warnfn(
                              get(valuesAfterExpressionsApplied, source),
                              valuesAfterExpressionsApplied,
                              f.props,
                          );
                          return res ? [res] : [];
                      });
                      return errs.length > 0
                          ? {
                                [source]: errs,
                            }
                          : {};
                  }
                  return {};
              }),
          )
        : {};
    const dropdownsRequired: { [f: string]: string[] } = !ignoreFieldLevel
        ? Object.assign(
              {},
              ...getEmptyRequiredOptionFormFields(formDefinition)(valuesAfterExpressionsApplied).map(fid => ({
                  [fid]: 'Required',
              })),
          )
        : {};

    const formFieldsObj = Object.assign({}, ...(formDefinition.fields || []).map(f => ({ [f.id]: f })));
    const userEditableFields: FormFieldUnion[] = visibleAndEditableFields.map(f => formFieldsObj[f]);
    const tableFields = userEditableFields.flatMap(f =>
        f.type === 'table' && f.params.columnObj
            ? f.params.columnObj.map(cf => ({
                  ...cf,
                  id: `${f.id}_c_${cf.id}`,
              }))
            : [],
    );
    const allFields = [...(formDefinition.fields || []), ...tableFields];
    const tableRowColumnValues = userEditableFields.flatMap(f =>
        f.type === 'table' && f.params.columnObj
            ? [setupFieldValuesFromTable(f.id, valuesAfterExpressionsApplied[f.id] || [], f.params.columnObj)]
            : [],
    );

    const fieldIds = allFields.map(field => field.id);
    const valuesWithOutcomes = {
        ...flowablePreprocessValuesForEval(
            Object.assign(
                {},
                entityAndConceptLookupUtils(entities, viewConfig, valueSets),
                valuesAfterExpressionsApplied,
                ...tableRowColumnValues,
                { outcome: outcome || null },
            ),
            allFields,
            entities,
            {
                dateFormat: (viewConfig && viewConfig.application && viewConfig.application.dateFormat) || '',
            },
            valueSets,
        ),
        _outcome: outcome,
    };

    const tableFieldsAndEditableFields = [...tableFields, ...userEditableFields];

    // get an evaluator null initializing the fields in the form for the evaluation context
    const validator = getEvaluator(
        {
            /* default options */
        },
        fieldIds,
    );

    const validations: [/* fieldName: */ string, /* expression: */ string][] = mapOption(
        tableFieldsAndEditableFields,
        f =>
            fromNullable(f.params)
                .chain(params => fromNullable(params.configs))
                .chain(configs => fromNullable(configs.validation || null))
                .map<[string, string]>(validation => [f.id, validation]),
    ).flatMap(([fid, validation]) =>
        fid.indexOf('_c_') !== -1
            ? // find out how many rows there are, and create a new validation for each row
              (valuesAfterExpressionsApplied[fid.split('_c_')[0]] || []).map((_, i) => [
                  `${fid}_${i}`,
                  validation.replace(/[a-zA-Z0-9]+_c_[a-zA-Z0-9]+/g, `$&_${i}`),
              ])
            : [[fid, validation]],
    );
    const resultTuples = getEvaluatedValidations(validator, valuesWithOutcomes)(validations);
    const messagesIncludingUnexpectedErrors = deepExtend(
        // combines string arrays
        {},
        convertErrorMessagesToTableFormat(errorsAsMessages(resultTuples, fieldIds)),
        fieldErrors,
        dropdownsRequired,
    );
    return messagesIncludingUnexpectedErrors;
};

export default validate;
