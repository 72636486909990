import React from 'react';
import { ReactNode } from 'react';
import { connect } from 'react-redux';
import pure from 'recompose/pure';
import compose from 'recompose/compose';
import { push as pushAction } from 'connected-react-router';
import Responsive from 'util/Responsive';
import { setSidebarVisibility as setSidebarVisibilityAction } from 'actions/aor/uiActions';
import VerticalMenuComponent from './VerticalMenu';
import ToolbarMenuComponent from './ToolbarMenu';
import { capitalizeFirstLetter } from '../generics/utils/genericUtils';
import { RootState } from '../../reducers/rootReducer';

interface MenuProps {
    setSidebarVisibility: (open: boolean) => void;
    pushAction: (route: string) => void;
    hasDashboard: boolean;
    logout: ReactNode;
    onMenuTap: () => void;
    viewConfig: RootState['viewConfig'];
}

const Menu: React.SFC<MenuProps> = (props: MenuProps) => (
    <Responsive small={<VerticalMenuComponent {...props} />} medium={<ToolbarMenuComponent {...props} />} />
);

Menu.defaultProps = {
    onMenuTap: () => null,
};

const mapStateToProps = (state: RootState) => ({
    viewConfig: state.viewConfig,
    location: state.router.location ? capitalizeFirstLetter(state.router.location.pathname.substring(1)) : null,
});

const enhance = compose(
    connect(
        mapStateToProps,
        {
            pushAction,
            setSidebarVisibility: setSidebarVisibilityAction,
        },
    ),
    pure,
);

export const ToolbarMenu = enhance(ToolbarMenuComponent);
export const VerticalMenu = enhance(VerticalMenuComponent);
export default enhance(Menu);
