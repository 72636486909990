import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { RootState } from '../../src/reducers/rootReducer';
import { Dispatch, AnyAction } from 'redux';
import { ArgumentTypes } from '../../src/util/typeUtils';
import { WithStyles, createStyles, Theme, withStyles, IconButton, Tooltip } from '@material-ui/core';
import Print from '@material-ui/icons/Print';
import Check from '@material-ui/icons/CheckBox';

const styles = ({ palette, spacing }: Theme) =>
    createStyles({
        on: {
            color: palette.secondary.main,
            zIndex: 5,
        },
        off: {
            zIndex: 5,
        },
    });

export interface TogglePrintModeProps {}
const makeMapStateToProps = () => {
    // create any caches/selectors in this scope
    const mapStateToProps = (state: RootState, props: TogglePrintModeProps) => {
        return {
            printMode: state.printMode,
        };
    };
    return mapStateToProps;
};
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, ownProps: TogglePrintModeProps) => ({
    toggle: () => dispatch({ type: 'TOGGLE_PRINT_MODE' }),
});
type ActionCreatorProps = {
    [key in keyof ReturnType<typeof mapDispatchToProps>]: (
        ...args: ArgumentTypes<ReturnType<typeof mapDispatchToProps>[key]>
    ) => void;
};
interface TogglePrintModeComponentProps
    extends ReturnType<ReturnType<typeof makeMapStateToProps>>,
        ActionCreatorProps,
        WithStyles<typeof styles>,
        TogglePrintModeProps {}

class TogglePrintModeComponent extends React.Component<TogglePrintModeComponentProps> {
    render() {
        const { toggle, classes, printMode } = this.props;
        const title = `Turn print-mode ${printMode ? 'off' : 'on'}`;
        return (
            <Tooltip title={title}>
                <IconButton aria-label={title} className={printMode ? classes.on : classes.off} onClick={toggle}>
                    <Print />
                    {printMode ? <Check /> : null}
                </IconButton>
            </Tooltip>
        );
    }
}

const TogglePrintMode: React.SFC<TogglePrintModeProps> = compose(
    connect(
        makeMapStateToProps,
        mapDispatchToProps,
    ),
    withStyles(styles),
)(TogglePrintModeComponent);

export default TogglePrintMode;
