import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import AttemptRequest from '../../../../../components/AttemptRequest';
import * as config from '../../../../../config';
import { storageController } from 'storage';

/*
PUT to api/bpm/tasks/{taskId}/change-scheddate

With something like this in the body:
{ "newTaskSchedDate": "2020-02-03 14:01:05" }
*/
const getLazyR = (taskId: string) => (newSchedDate: string | Date) => () =>
    fetch(`${config.BACKEND_BASE_URL}api/bpm/tasks/${taskId}/change-scheddate`, {
        method: 'PUT',
        body: JSON.stringify({
            newTaskSchedDate: new Date(newSchedDate),
        }),
        credentials: 'same-origin',
        headers: new Headers({
            Authorization: `Bearer ${storageController.getToken()}`,
            Cookie: `${window.document.cookie}`,
            Accept: 'application/json',
            'Content-type': 'application/json',
        }),
    });

export const getChangeTaskSchedDateButton = (taskId: string, onSuccess: () => void) => newSchedDate => (
    <AttemptRequest
        type="external"
        renderer={({ attemptAction }) => state =>
            state._tag === 'failure' ? (
                <div>
                    Action failed.
                    <a // eslint-disable-line
                        href="javascript:;" // eslint-disable-line
                        onClick={attemptAction}
                    >
                        try again?
                    </a>
                </div>
            ) : state._tag === 'pending' ? (
                <Button style={{ marginLeft: 'auto' }} variant="contained" color="default" disabled={true}>
                    Save
                    <CircularProgress style={{ marginLeft: '1em', height: 20, width: 20 }} />
                </Button>
            ) : state._tag === 'unsubmitted' ? (
                <Button style={{ marginLeft: 'auto' }} variant="contained" color="primary" onClick={attemptAction}>
                    Save
                </Button>
            ) : (
                <span style={{ marginLeft: 'auto' }}>Success!</span>
            )}
        lazyRequest={getLazyR(taskId)(newSchedDate)}
        onSuccess={onSuccess}
    />
);
