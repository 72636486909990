import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { List, ListItem, ListItemText, Dialog, DialogContent, ListItemIcon, withTheme } from '@material-ui/core';
import GenericShow from '../genericShow';
import Toolbar from '../form/Toolbar.aor';
import CloseButton from 'fieldFactory/popovers/PopoverCloseButton';
import SelfFocus from 'react-selffocus-element';

const tertiaryStyle = { float: 'right', opacity: 0.541176 };

class DialogListItemComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    }
    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        console.log('handleClose called');
        this.setState({ open: false });
    };
    render() {
        const {
            id,
            data,
            basePath,
            primaryText,
            secondaryText,
            tertiaryText,
            leftIcon,
            showDialog,
            innerContent,
            resourceName,
            resourceBasePath,
            style,
            theme,
        } = this.props;
        const textStyle = { color: theme.palette.primary.contrastText };
        const _primaryText = (
            <div style={textStyle}>
                {primaryText(data[id], id)}
                {tertiaryText &&
                    (() => {
                        const text = tertiaryText(data[id], id);
                        return text && text !== 'null' && <span style={tertiaryStyle}>{text}</span>;
                    })()}
            </div>
        );
        const _secondaryText =
            secondaryText &&
            (() => {
                const text = secondaryText(data[id], id);
                return text && text !== 'null' && <span style={textStyle}>{text}</span>;
            })();
        const _leftIcon = leftIcon && <span style={textStyle}>{leftIcon(data[id], id)}</span>;
        const ListFragment = (
            <React.Fragment>
                <li style={{ backgroundColor: theme.palette.primary.main }}>
                    <SelfFocus>
                        <ListItem
                            button={true}
                            alignItems="flex-start"
                            style={{
                                ...style,
                                backgroundColor: theme.palette.primary.main,
                            }}
                            onClick={() => {
                                if (showDialog) {
                                    this.handleOpen();
                                }
                            }}
                        >
                            {_leftIcon && <ListItemIcon>{_leftIcon}</ListItemIcon>}
                            <ListItemText primary={_primaryText} secondary={_secondaryText} />
                        </ListItem>
                    </SelfFocus>
                    {innerContent && innerContent(data[id], id)}
                    {showDialog && (
                        <Dialog
                            TransitionProps={{
                                // https://github.com/dequelabs/axe-core/issues/146
                                role: 'presentation',
                            }}
                            open={this.state.open}
                            onClose={this.handleClose}
                            maxWidth={false}
                            fullWidth={true}
                        >
                            <DialogContent style={{ padding: 0 }}>
                                <GenericShow
                                    {...{
                                        formId: `${resourceName}-simplelist-show`,
                                        viewName: `${resourceName}Show`,
                                        // formId: `popover-create-form-${resourceName}-Parent:${parentEntityName}:${parentId}`,
                                        location: {
                                            pathname: resourceBasePath,
                                            search: '',
                                        },
                                        match: {
                                            isExact: true,
                                            params: {
                                                id,
                                                resourceBasePath,
                                            },
                                        },
                                        resource: resourceName,
                                        toolbar: (
                                            <Toolbar>
                                                <CloseButton handleClose={this.handleClose} />
                                            </Toolbar>
                                        ),
                                    }}
                                />
                            </DialogContent>
                        </Dialog>
                    )}
                </li>
            </React.Fragment>
        );

        if (showDialog) {
            return ListFragment;
        } else {
            return (
                <Link to={`${basePath}/${id}`} style={{ textDecoration: 'none' }}>
                    {ListFragment}
                </Link>
            );
        }
    }
}
DialogListItemComponent.defaultProps = {
    innerContent: null,
    showDialog: false,
    data: {},
    primaryText: null,
    secondaryText: null,
    secondaryTextLines: undefined,
    tertiaryText: null,
    leftAvatar: null,
    leftIcon: null,
    rightAvatar: null,
    rightIcon: null,
    resourceName: undefined,
    resourceBasePath: undefined,
    style: undefined,
};
const DialogListItem = withTheme(DialogListItemComponent);

const SimpleList = ({ ids, style, liStyle, ...props }) => (
    <List style={style}>
        {ids.map(id => (
            <DialogListItem key={id} id={id} style={liStyle} {...props} />
        ))}
    </List>
);
SimpleList.propTypes = {
    ids: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    style: PropTypes.shape({}),
    liStyle: PropTypes.shape({}),
};
SimpleList.defaultProps = {
    style: {},
    liStyle: {},
    ids: [],
};

export default SimpleList;
