import React from 'react';
import MuiEditableTable from './MuiEditableTable';

import { withFieldFactory } from '../../../../fieldFactory/Broadcasts';
import { DataSource, Mode } from '../../../../fieldFactory/FieldFactoryProvider';

import compose from 'recompose/compose';
import withPropsOnChange from 'recompose/withPropsOnChange';
import { withStyles } from '@material-ui/core/styles';

// examples
/*
const mapTypeToFieldName = (type) => {
    switch (type) {
        case 'TEXTBOX':
            // return 'TextField';
            return TextInput;
        case 'CALENDAR':
            return DateInput;
        default:
            return 'TextField';
    }
};
*/
// const colSpec = this.props.colSpec.map(({ name: title, type }) =>
// ({ title, fieldName: title.replace(' ', '-'), inputType: mapTypeToFieldName(type) }));
/*
const colSpec = [
    {title: 'Title', fieldName: 'title', inputType: "SelectField",
        selectOptions: ["Mr", "Mrs", "Miss", "Other"], width, defaultValue: 'Mr'},
    {title: 'Name', fieldName: 'foreName', inputType: "TextField", width},
    {title: 'Surname', fieldName: 'surname', inputType: "TextField", width},
    {title: 'Maiden Name', fieldName: 'maidenName', inputType: "TextField", width,
        isReadOnly: (...args) => { console.log('shouldBeReadOnlyArgs', ...args); return true; }},
    {title: 'Employed', fieldName: 'employed', inputType: "Toggle", width}
];
*/

/*
const rowData = [
    { title: 'Mr', foreName: 'John', surname: 'Smith', employed: true},
    { title: 'Miss', foreName: 'Emily', surname: 'Lockhart', employed: false},
    { title: 'Mrs', foreName: 'Marilyn', surname: 'Monroe', employed: true}
];
*/
const isStringGreaterThanLength = (len: number) => (val: any): val is string =>
    typeof val === 'string' && val.length > len; // tslint:disable-line

const flowableArrayOfObjectsToJSONStr = (val: string): string =>
    val
        .split(' ')
        .join('')
        .split('},{')
        .map(f =>
            f
                .split('}')
                .join('"}')
                .split('{')
                .join('{"')
                .split('=')
                .join('":"')
                .split(',')
                .join('","'),
        )
        .join('"},{"');
/*
const parseErrorFormatForDisplay = (errorText: string[]) =>
// errorText is in format
// <field>_<row>:<message>|<field>_<row>:<message> ...
        errorText.map(et =>
            et.split('|')
            .map(rowColStr => {
                const [fieldRow, message] = rowColStr.split(':', 2);
                const [field, row] = fieldRow.split('_', 2);
                return `\nError for ${field}, row ${row}: ${message}`;
            })
        );
*/

export const isTableFieldErrorMessage = (m: string) => m && m.includes('_') && m.includes(':');

export const createErrorTableFromMessages = (
    errorText: string[],
): {
    [row: string]: {
        [field: string]: string[];
    };
} => {
    // errorText is in format
    // <field>_<row>:<message>|<field>_<row>:<message> ...
    return errorText
        .flatMap(et => {
            return et.split('|').map(rowColStr => {
                const [fieldRow, message] = rowColStr.split(':', 2);
                const [field, row] = fieldRow.split('_', 2);
                return [row, field, message];
                // return `\nError for ${field}, row ${row}: ${message}`;
            });
        })
        .reduce(
            (res, [row, field, message]) => ({
                ...res,
                [row]: {
                    ...res[row],
                    [field]: [...((res[row] || {})[field] || []), message],
                },
            }),
            {},
        );
};
const EditableTable = ({
    colSpecWithComponents,
    input,
    meta: { error, touched },
    theme,
    disabled,
    label,
    colSpec,
    source,
    allowRowAddDelete = true,
}) => {
    return (
        <div>
            <MuiEditableTable
                label={label}
                source={source}
                disabled={disabled}
                errorTable={
                    touched &&
                    error &&
                    Array.isArray(error) &&
                    createErrorTableFromMessages(error.filter(isTableFieldErrorMessage))
                }
                colSpec={colSpecWithComponents}
                columns={colSpec}
                allowRowAddDelete={allowRowAddDelete}
                rowData={
                    isStringGreaterThanLength(4)(input.value)
                        ? JSON.parse(flowableArrayOfObjectsToJSONStr(input.value))
                        : isStringGreaterThanLength(1)(input.value)
                        ? JSON.parse(input.value)
                        : input.value || []
                }
                onChange={input.onBlur}
                reorderable={true}
                containerStyle={{ width: '100%' }}
            />
            {touched && error && Array.isArray(error) && error.find(e => !isTableFieldErrorMessage(e)) && (
                <span style={{ color: theme.palette.error.main, fontSize: 'small' }}>
                    {error.filter(e => !isTableFieldErrorMessage(e)).join(`\n`)}
                </span>
            )}
        </div>
    );
};

const EditableTableContainer = compose(
    withFieldFactory,
    withPropsOnChange(['fieldFactory'], props => {
        const config = {
            dataSource: DataSource.FLOWABLE,
            mode: Mode.INPUT,
            validate: false,
            connected: false,
            options: {
                fullWidth: true,
            },
        };
        const fieldFactory = props.fieldFactory(config)({
            disabled: props.disabled,
            overrideFieldValueIfDisabled: true,
        });
        return { fieldFactory };
    }),
    withPropsOnChange(['colSpec', 'fieldFactory', 'disabled'], ({ colSpec, fieldFactory, disabled }) => ({
        colSpecWithComponents: colSpec.map(({ name, type, id, readOnly, params, ...rest }) => ({
            title: name,
            fieldName: id,
            readOnly: readOnly,
            span: (params && params.span && parseInt(params.span, 10)) || Math.floor(12 / colSpec.length),
            inputType: fieldFactory([
                {
                    ...rest,
                    params,
                    id,
                    required: false,
                    type,
                    name, // : disabled ? '' : name
                },
            ])[0],
        })),
    })),
    withStyles({}, { withTheme: true }),
)(EditableTable);

export default EditableTableContainer;
