import * as fieldTypes from '../../fieldTypes';
import getInputTypeFromFlowableField from './getTypeFromFlowableField';
import getValidationFromFlowableField from './getValidationFromFlowableField';
import ViewConfig from '../../../reducers/ViewConfigType';
import * as flowableFieldTypes from './flowableFieldTypes';
import { FormFieldUnion, SimpleFormField } from './types';
import {
    DataSource,
    FieldFromFlowable,
    FlowableDropdownField,
    FlowableExpressionField,
    FlowableDataField,
    FlowableRadioField,
} from '../types';

// output type
export interface BaseFlowableField {
    name: string;
    type: (typeof fieldTypes)[keyof typeof fieldTypes];
    originalDefinition: string;
    label: string | null;
    readOnly?: boolean;
    value?: any; // tslint:disable-line
    required?: boolean;
    params?: SimpleFormField['params'];
    _dataSource: DataSource.FLOWABLE;
}

export const mapFormField = (
    viewConfig: ViewConfig,
    flowableFieldDefinition: FormFieldUnion,
    linkedEntity?: string, // passed for the sake of validating linkedEntity fields against entity rules.
    // comes from props passed to withFieldFactory decorated component. (props.relatedEntityResource)
): FieldFromFlowable => {
    const originalDefinition = JSON.stringify(flowableFieldDefinition);
    const { id: name, name: label, readOnly, type: flowableType, value } = flowableFieldDefinition;

    if (flowableType === undefined) {
        console.warn('field with undefined type', flowableFieldDefinition);
    }

    const type = getInputTypeFromFlowableField(flowableType);
    const { params, required } = flowableFieldDefinition;
    if (params && (params as any).singleSelectValueSet) {
        (params as any).valueSet = (params as any).singleSelectValueSet;
    }
    const baseFieldAttrs: BaseFlowableField = {
        name,
        type,
        label,
        readOnly,
        value,
        required,
        params,
        originalDefinition,
        _dataSource: DataSource.FLOWABLE,
    };

    switch (flowableFieldDefinition.type) {
        case flowableFieldTypes.EXPRESSION: {
            const { expression } = flowableFieldDefinition;
            return {
                ...baseFieldAttrs,
                type,
                name: name || `ExpressionField:${name}-${expression}-${value}-${label}`,
            } as FlowableExpressionField;
        }
        case flowableFieldTypes.RADIO:
        case flowableFieldTypes.DROPDOWN: {
            const { options, hasEmptyValue } = flowableFieldDefinition;
            return {
                ...baseFieldAttrs,
                type,
                options,
                hasEmptyValue,
                warn: getValidationFromFlowableField(flowableFieldDefinition),
            } as (FlowableDropdownField | FlowableRadioField);
        }
        default: {
            /*
            case flowableFieldTypes.TABLE:
            case flowableFieldTypes.VALUE_SET_DROPDOWN:
            case flowableFieldTypes.ENTITY_LOOKUP:
            */
            const warn = getValidationFromFlowableField(flowableFieldDefinition);
            return {
                ...baseFieldAttrs,
                type,
                params,
                warn,
            } as FlowableDataField;
        }
    }
};

export default (viewConfig, linkedEntity?: string, mode?: any) => (
    fieldDefinitions, // mode is there just to match interface
) => fieldDefinitions.map(flowableDef => mapFormField(viewConfig, flowableDef, linkedEntity));
