import { RootState } from '../rootReducer';
import clone from 'clone';
import traverse from 'traverse';
import { serialize, deserialize, isRemoteData } from '../lists/list/serializeDeserialize';
import isCyclic from 'util/isCyclic';

export const toSerializableState = (state: RootState) => {
    const stateIsCyclic = isCyclic(state);
    if (stateIsCyclic) {
        console.log('state is cyclic.');
    }
    const clonedState = clone(state, stateIsCyclic, 100);
    traverse(clonedState).forEach(function(x) {
        if (isRemoteData(x)) {
            this.update(serialize(x), true);
        }
    });
    return clonedState;
};
export const rehydrateState = (sstate: ReturnType<typeof toSerializableState>): RootState => {
    const clonedSState = clone(sstate);
    traverse(clonedSState).forEach(function(x) {
        if (isRemoteData(x)) {
            this.update(deserialize(x), true);
        }
    });
    return clonedSState;
};
