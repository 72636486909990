import { EntityBase } from 'sideEffect/services';
import getApplyRevisionToAllIds from 'viewConfigSchema/alterIdsForRevisionChildren/index';
import { normalize } from 'normalizr';
import { applyAndMarkWildcardEntities } from 'viewConfigSchema/stripHibernateArtifactsAndReplaceWithIds';
import ViewConfig from 'reducers/ViewConfigType';
import buildSchemaFromViewConfig from 'viewConfigSchema/buildSchemaFromViewConfig';

let normalizationSchema = null;

/*
    This is used to build a new normalization schema (probably from a new viewConfig)
    You can assume this is imported in another file and called when a new viewConfig is loaded...
*/
export const rebuildSchema = viewConfig => {
    normalizationSchema = buildSchemaFromViewConfig(viewConfig);
};

export const getSchema = viewConfig => {
    if (normalizationSchema) {
        return normalizationSchema;
    }
    normalizationSchema = buildSchemaFromViewConfig(viewConfig);
    return normalizationSchema;
};

export interface NormalizedResponse<T, R extends string | string[]> {
    entities: {
        [entityName: string]: {
            [id: string]: T;
        };
    };
    result: R;
}
export function processResponse<T extends EntityBase>(
    resource: string,
    viewConfig: ViewConfig,
    respData: T,
): NormalizedResponse<T, string>;
export function processResponse<T extends EntityBase>(
    resource: string,
    viewConfig: ViewConfig,
    respData: T[],
): NormalizedResponse<T, string[]>;

export function processResponse<T extends EntityBase>(resource: string, viewConfig: ViewConfig, respData: T | T[]) {
    const schema = getSchema(viewConfig);
    const applyRevisionToAllIds = getApplyRevisionToAllIds(viewConfig);
    return normalize(
        applyRevisionToAllIds(applyAndMarkWildcardEntities(respData, viewConfig)),
        respData.constructor === Array ? [schema[resource]] : schema[resource],
    );
}
