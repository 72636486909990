import { TaskForm } from 'reducers/rootReducer';
import ViewConfig from 'reducers/ViewConfigType';
import { crudGetOne } from 'sideEffect/crud/getOne/actions';
import { fromNullable, tryCatch } from 'fp-ts/lib/Option';
import getExpansionsFromFilter from 'isomorphic-query-filters/expand';
import { FormFieldUnion, EntityTypeaheadField, EntityLookupField } from 'fieldFactory/translation/fromFlowable/types';

const getNeededInFilter = (f: EntityTypeaheadField | EntityLookupField) =>
    fromNullable(f.params)
        .mapNullable(params => params.filter || null)
        .map(filter => getExpansionsFromFilter(filter))
        .getOrElse([]);
const getConfiguredExpansions = (f: FormFieldUnion) =>
    fromNullable(f.params)
        .mapNullable(p => p.configs)
        .mapNullable(c => c.fieldConfig)
        .chain(fc => tryCatch(() => JSON.parse(fc)))
        .mapNullable(fc => fc.expansions)
        .getOrElse([]);

const getRefOnesForFormActions = <TF extends { fields: TaskForm['fields'] }>(
    taskForm: TF,
    viewConfig: ViewConfig,
    tableValue?: {}[],
): ReturnType<typeof crudGetOne>[] => {
    const refOnes = Object.values(taskForm.fields).flatMap(f => {
        if (f.type === 'table') {
            return getRefOnesForFormActions(
                { fields: f.params.columnObj },
                viewConfig,
                typeof f.value === 'string' && f.value
                    ? JSON.parse(f.value)
                    : Array.isArray(f.value)
                    ? f.value
                    : undefined,
            );
        }
        if (f.type === 'entity-lookup' || f.type === 'entity-typeahead') {
            const neededInFilter = getNeededInFilter(f);
            const configuredExpansions = getConfiguredExpansions(f);
            if (f.value) {
                return [
                    crudGetOne({
                        resource: f.params.entity,
                        id: f.value,
                        view: -1,
                        appendExpansions: [...neededInFilter, ...configuredExpansions],
                    }),
                ];
            } else if (tableValue) {
                return tableValue.flatMap(row => {
                    const value = row[f.id];
                    if (value) {
                        return [
                            crudGetOne({
                                resource: f.params.entity,
                                id: value,
                                view: -1,
                                appendExpansions: [...neededInFilter, ...configuredExpansions],
                            }),
                        ];
                    }
                    return [];
                });
            }
        }
        return [];
    });
    return refOnes;
};
export default getRefOnesForFormActions;
