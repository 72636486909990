import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import FileDownload from '@material-ui/icons/SaveAlt';
import IconButton from '@material-ui/core/IconButton';
import { MenuItem } from '@material-ui/core';

const ExportViewConfigButton = ({
    mobile,
    label,
    exportConfigFull,
    exportConfigCore,
    exportConfigUserExcluded,
    exportConfigCoreUserExcluded,
    exportProcesses,
    onClick,
}) =>
    mobile ? (
        <MenuItem onClick={onClick}>
            {label} <FileDownload />
        </MenuItem>
    ) : (
        <div>
            <IconButton color="inherit" onClick={onClick} aria-label="Export Viewconfig">
                <FileDownload />
            </IconButton>
        </div>
    );

function mapStateToProps(state) {
    return {
        isLoading: state.admin.loading > 0,
    };
}

const enhance = compose(
    connect(
        mapStateToProps,
        {
            // exportConfigFull: ExportViewConfigEventCreator.exportViewConfigFull,
            // exportConfigCore: ExportViewConfigEventCreator.exportViewConfigCore,
        },
    ),
); // eslint-disable-line space-in-parens

ExportViewConfigButton.defaultProps = {
    mobile: false,
    label: '',
};

export default enhance(ExportViewConfigButton);
