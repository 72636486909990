import React from 'react';
import Themed from './Themed';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
const getConsoleErrorCount = () => (window as any).casetivity_error_count;
const ConsoleErrorCount = props => {
    const [count, setCount] = React.useState(getConsoleErrorCount());
    React.useEffect(() => {
        const interval = setInterval(() => {
            const newErrorCount = getConsoleErrorCount();
            if (count !== newErrorCount) {
                setCount(newErrorCount);
            }
        }, 1500);
        return () => clearInterval(interval);
    }, []); // eslint-disable-line
    return (
        <Themed>
            {({ theme }) => {
                return count > 0 ? <span style={{ color: theme.palette.error.dark }}>{count}</span> : <span />;
            }}
        </Themed>
    );
};
const ConsoleErrorCounter = props => {
    const isSecure = useSelector((state: RootState) =>
        state.basicInfo ? !state.basicInfo.debugFeaturesEnabled : true,
    );
    if (isSecure) {
        return null;
    }
    return <ConsoleErrorCount />;
};
export default ConsoleErrorCounter;
