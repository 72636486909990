import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push as pushAction } from 'connected-react-router';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';
import compose from 'recompose/compose';
import ViewTitle from '../ViewTitle';

import { crudGetOne as crudGetOneAction } from 'sideEffect/crud/getOne/actions';
import withProps from 'recompose/withProps';
import { loadValueSets as loadValueSetsAction } from '../../../actions/valueSetsActions';
import { allowsDelete, allowsEdit, getAccessLevelForEntity } from '../utils/viewConfigUtils';
import EntityPageTitle from '../fields/display/EntityPageTitle';
import prefetchLists from '../form/prefetchLists';
import { crudGetList as crudGetListAction } from 'sideEffect/crud/getList/actions';
import { createVisibilityVSCodeLiteralsSelector } from '../form/valuesetCodeExpressionLiteralsSelector';
import DeferredSpinner from 'components/DeferredSpinner';
import FormDisplayStatus from 'remoteStatus/one/components/implementations/FormDisplayStatus';
import { Provider } from '../form/refreshContext';
import SsgAppBarMobile from 'components/SsgAppBarMobile';
import getTabToPrefetch from '../form/getTabToPrefetch';
import formTypeContext from '../form/formTypeContext';

const LookupData = connect((state, props) => {
    const resourceBag = state.admin.entities[props.resource];
    if (resourceBag) {
        return { data: resourceBag[props.id] };
    }
    return { data: undefined };
})(props => props.children(props.data));

export class Show extends Component {
    state = {
        dataKey: 0,
        wasLoading: false,
    };
    static getDerivedStateFromProps(props, state) {
        if (props.isLoading !== state.wasLoading) {
            return {
                ...state,
                dataKey: !props.isLoading ? state.dataKey + 1 : state.dataKey,
                wasLoading: props.isLoading,
            };
        }
        return null;
    }
    componentDidMount() {
        this.updateData();

        const { viewConfig, viewName, id, crudGetList, tabToPrefetch } = this.props;

        // fetch all data initially
        prefetchLists(viewConfig, viewName, id, crudGetList, tabToPrefetch);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.id !== nextProps.id) {
            this.updateData(nextProps.resource, nextProps.id);
        }
    }

    getBasePath() {
        const { resource } = this.props;
        return `/${resource}`;
    }

    updateData(resource = this.props.resource, id = this.props.id) {
        const valueSetCodes = this.props.valueSetCodeLiterals.map(valueset => ({ valueSet: valueset }));
        if (valueSetCodes.length > 0) {
            this.props.loadValueSets(valueSetCodes);
        }
        this.props.crudGetOne({
            monitorRequest: true,
            resource,
            id,
            view: this.props.viewName,
            cb: (responseId, responseData) => {
                if (`${id}` !== `${responseId}`) {
                    // redirect if id is different (due to merge)
                    this.props.push(`/${resource}/${responseId}/show`);
                }
            },
        });
    }

    refresh = event => {
        if (event) {
            event.stopPropagation();
        }
        this.fullRefresh = true;
        this.updateData();
        // list data needs to be refetched as well
        const { viewConfig, viewName, id, crudGetList } = this.props;
        prefetchLists(viewConfig, viewName, id, crudGetList);
    };

    render() {
        const { actions, children, id, data, isLoading, resource, hasDelete, hasEdit, viewConfig } = this.props;
        const basePath = this.getBasePath();

        const defaultTitle = `${resource} ${id}`;
        const titleElement = <EntityPageTitle record={data} resource={resource} defaultTitle={defaultTitle} />;

        const renderSuccess = () =>
            data ? (
                React.cloneElement(children, {
                    isLoading,
                    id,
                    title: (
                        <ViewTitle
                            displayAboveWidth={this.props.createMobileAppBar ? 'xs' : undefined}
                            title={titleElement}
                        />
                    ),
                    actions: actions && (
                        <LookupData id={id} resource={resource}>
                            {data =>
                                React.cloneElement(actions, {
                                    // force actions to reload
                                    key: this.state.dataKey,
                                    basePath,
                                    data,
                                    hasDelete,
                                    hasEdit,
                                    refresh: this.refresh,
                                    resource,
                                    accessLevel: getAccessLevelForEntity(viewConfig, resource),
                                    viewName: this.props.viewName,
                                })
                            }
                        </LookupData>
                    ),
                    resource,
                    tabToPrefetch: this.props.tabToPrefetch,
                    referenceFieldsShouldFetchInitialData: true,
                    basePath,
                    record: data,
                })
            ) : (
                <DeferredSpinner />
            );
        return (
            <Provider value={this.refresh}>
                <formTypeContext.Provider value="SHOW">
                    {this.props.createMobileAppBar ? <SsgAppBarMobile title={titleElement} /> : null}
                    <FormDisplayStatus
                        id={id}
                        resource={resource}
                        showSuccessOffline={!!data}
                        renderSuccess={renderSuccess}
                        refresh={this.refresh}
                    />
                </formTypeContext.Provider>
            </Provider>
        );
    }
}

Show.propTypes = {
    actions: PropTypes.element,
    children: PropTypes.element,
    crudGetOne: PropTypes.func.isRequired,
    data: PropTypes.shape({}),
    hasDelete: PropTypes.bool.isRequired,
    hasEdit: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    match: PropTypes.shape({}).isRequired, // eslint-disable-line react/no-unused-prop-types
    resource: PropTypes.string.isRequired,
};

Show.defaultProps = {
    data: undefined,
    actions: null,
    children: null,
    title: undefined,
};

const makeMapStateToProps = () => {
    const getValueSetCodeLiterals = createVisibilityVSCodeLiteralsSelector();
    const mapStateToProps = (state, props) => {
        const id = decodeURIComponent(props.match.params.id);
        return {
            valueSetCodeLiterals: getValueSetCodeLiterals(state, props),
            id,
            data: (state.admin.entities[props.resource] || {})[decodeURIComponent(props.match.params.id)],
            isLoading: state.admin.loading > 0,
            tabToPrefetch: getTabToPrefetch(state, { ...props, id }),
        };
    };
    return mapStateToProps;
};

const enhance = compose(
    connect(
        makeMapStateToProps,
        {
            crudGetOne: crudGetOneAction,
            push: pushAction,
            crudGetList: crudGetListAction,
            loadValueSets: loadValueSetsAction,
        },
    ),
    branch(props => !props.viewConfig || Object.keys(props.viewConfig).length === 0, renderNothing),
    withProps(props => {
        const accessLevel = getAccessLevelForEntity(props.viewConfig, props.resource);
        return {
            hasEdit: allowsEdit(accessLevel),
            hasDelete: allowsDelete(accessLevel),
        };
    }),
);

export default enhance(Show);
