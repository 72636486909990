import React from 'react';
import get from 'lodash.get';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import { crudGetOne as crudGetOneAction } from 'sideEffect/crud/getOne/actions';

interface ValueSetListFieldComponentProps {
    concepts: {};
    valueSetCode: number;
    addLabel: boolean;
    elStyle: React.CSSProperties;
    label: string;
    source: string;
    record?: {};
    crudGetOne: Function;
}

export const ValueSetListFieldComponent: React.SFC<ValueSetListFieldComponentProps> = ({
    concepts = {},
    valueSetCode,
    addLabel,
    elStyle,
    label,
    record,
    source,
}) => {
    const list = get(record, source) || [];
    const getDisplay = value => (concepts[value.id || value] || {}).display;
    return (
        <span style={elStyle}>
            {list.reduce((acc, curr, i) => `${acc}${i !== 0 ? ', ' : ' '}${getDisplay(curr)}`, '')}
        </span>
    );
};

ValueSetListFieldComponent.defaultProps = {
    addLabel: true,
};

const mapStateToProps = (state, ownProps) => ({ concepts: state.admin.entities.Concept });
const ValueSetListField = compose(
    connect(
        mapStateToProps,
        { crudGetOne: crudGetOneAction },
    ),
    lifecycle({
        componentDidMount() {
            if (this.props.fetchOwnData) {
                this.getMissingConcepts(this.props);
            }
        },
        componentWillReceiveProps(nextProps: ValueSetListFieldComponentProps) {
            if (
                this.props.fetchOwnData &&
                get(this.props.record, this.props.source) !== get(nextProps.record, nextProps.source)
            ) {
                this.getMissingConcepts(nextProps);
            }
        },
        getMissingConcepts(props: ValueSetListFieldComponentProps) {
            if (props.record && props.source) {
                const conceptIds = get(props.record, props.source);
                if (conceptIds) {
                    conceptIds.forEach(id => {
                        if (!props.concepts || typeof props.concepts[id] === 'undefined') {
                            props.crudGetOne({
                                resource: 'Concept',
                                id,
                                view: null,
                            });
                        }
                    });
                }
            }
        },
    }),
)(ValueSetListFieldComponent);
export default ValueSetListField;
