import * as React from 'react';
import { SFC } from 'react';
import { Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import compose from 'recompose/compose';
import pure from 'recompose/pure';
import ToggleDrawerWrapperComponent from './ToggleDrawerWrapper';
import AssignedOpenTaskList from './AssignedOpenedTasksList';
import { RootState } from 'reducers/rootReducer';
import { fromNullable } from 'fp-ts/lib/Option';
import { Typography } from '@material-ui/core';
import { getTaskDrawerTaskLinkUrlSelector, getWillHaveSubmitButton } from 'util/applicationConfig';
import { evaluateExpression } from 'expressions/evaluate';
import useViewConfig from 'util/hooks/useViewConfig';
import { currentUserProperties } from 'expressions/contextUtils/currentUser';

const mapStateToProps = (state: RootState) => ({
    currentUserId: state.viewConfig.user && state.viewConfig.user.id,
    willHaveSubmitButton: getWillHaveSubmitButton(state),
    overriddenTaskUrl: state.viewConfig.application,
    configuredTemplateString: getTaskDrawerTaskLinkUrlSelector(state),
});

interface MyActiveTasksComponentProps extends ReturnType<typeof mapStateToProps> {
    open: boolean;
}
const MyActiveTasksComponent: SFC<MyActiveTasksComponentProps> = props => (
    <div>
        <Link
            to={
                props.configuredTemplateString ||
                `/tasks?${props.willHaveSubmitButton ? 'delaySearch=true&' : ''}filter=%7B"assignee.id"%3A"${
                    // current user login
                    props.currentUserId
                }"%2C"endTime__NOT_EMPTY"%3Afalse%7D&order=ASC&page=1&perPage=25&sort=startTime`
            }
        >
            <Typography variant="subtitle1" component="h2" style={{ marginBottom: 0, textAlign: 'center' }}>
                Tasks List
            </Typography>
            {/* <h5 style={{ textAlign: 'center', marginBottom: 0 }}>Tasks List</h5> */}
        </Link>
        <AssignedOpenTaskList open={props.open} />
    </div>
);

const enhance = compose(
    connect(mapStateToProps),
    pure,
);

const MyActiveTasks = enhance(MyActiveTasksComponent);

const MyActiveTasksDrawer: SFC<{}> = props => {
    const hideTaskDrawerExpression: boolean | string = useSelector((state: RootState) =>
        fromNullable(state.viewConfig.application)
            .mapNullable(a => a.config)
            .mapNullable(c => c.hideTaskDrawer)
            .getOrElse(false),
    );
    const viewConfig = useViewConfig();
    const hideTaskDrawer = React.useMemo(() => {
        if (typeof hideTaskDrawerExpression === 'boolean') {
            return hideTaskDrawerExpression;
        }
        return evaluateExpression(hideTaskDrawerExpression, {
            ...currentUserProperties(viewConfig),
        });
    }, [hideTaskDrawerExpression, viewConfig]);
    if (hideTaskDrawer) {
        return <div>{props.children}</div>;
    }
    return (
        <ToggleDrawerWrapperComponent renderDrawer={({ open }) => (open ? <MyActiveTasks open={open} /> : null)}>
            {props.children}
        </ToggleDrawerWrapperComponent>
    );
};

export default MyActiveTasksDrawer;
