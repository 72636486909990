import ViewConfig from '../../reducers/ViewConfigType';
import traverseGetData from 'casetivity-shared-js/lib/viewConfigSchema/traverseGetData';
import memoizeOne from 'memoize-one';
import { RootState } from '../../reducers/rootReducer';
import { fromNullable, option } from 'fp-ts/lib/Option';
import { ValueSets } from '../../reducers/valueSetsReducer';
import { identity } from 'fp-ts/lib/function';
import untypedDecurry from 'decurry';
import { mapOption, traverse, flatten, uniq } from 'fp-ts/lib/Array';
import { Concept } from '../../fieldFactory/input/components/ValueSelectDownshift';
import { eqString } from 'fp-ts/lib/Eq';

const parseIdsIfString = ids => {
    if (typeof ids === 'string' && ids[0] === '[') {
        return JSON.parse(ids);
    }
    return ids;
};

export const getContainsCodes = (concepts: { [id: string]: { code: string } }) => (
    ids: string[] | null,
    ...codes: string[]
) => {
    const conceptsByCode = Object.assign(
        {}, // perhaps the concepts aren't loaded yet... lets not error out if the ids aren't found.
        ...(parseIdsIfString(ids) || []).flatMap(id =>
            concepts[id] && concepts[id].code ? [{ [concepts[id].code]: concepts[id] }] : [],
        ),
    );
    return !codes.find(c => !conceptsByCode[c]); // the codes were all found
};

const getConceptsByCode = memoizeOne((concepts: { [id: string]: { code: string } }) => {
    return Object.assign(
        {},
        ...Object.entries(concepts).map(([id, concept]) => {
            return { [concept.code]: concept };
        }),
    );
});
export const getGetConceptIdsFromCodes = (concepts: { [id: string]: { code: string } }) => {
    const conceptsByCode = getConceptsByCode(concepts);
    return (...codes: string[]) => {
        return codes.flatMap(code => {
            const concept = conceptsByCode[code];
            if (concept) {
                return [concept.id];
            }
            console.warn(`failed to lookup concept by code "${code}"`);
            return [];
        });
    };
};
export const getLookupConceptIdsFromValuesetGroup = (valueSets: RootState['valueSets'], entities: {}) => {
    const warn = (vs: string, g: string) => {
        console.warn(`Unable to lookup concepts ids
    from valueset "${vs}" group "${g}".
    Returning empty array`);
    };
    return (valueSet: string, group: string | 'ALL'): string[] => {
        return fromNullable(valueSets[valueSet])
            .chain(vs =>
                group === 'ALL'
                    ? fromNullable(vs.conceptIds)
                    : traverse(option)(group.split(','), gr => {
                          const groupIds = fromNullable(vs.groups[gr]).map(g => g.ids);
                          if (groupIds.isNone()) {
                              warn(valueSet, gr);
                          }
                          return groupIds;
                      })
                          .map(flatten)
                          .map(uniq(eqString)),
            )
            .chain(fromNullable)
            .getOrElseL(() => {
                warn(valueSet, group);
                return [];
            });
    };
};

export const getLookupEntityField = (viewConfig: ViewConfig, entities: {}) => {
    const lookupEntityData = (entityType: string, id: string, expression: string) => {
        return traverseGetData(
            viewConfig,
            expression,
            {
                id,
                entityType,
            },
            entities,
        ).fold(null, value => (typeof value !== 'undefined' ? value : null));
    };
    return lookupEntityData;
};

type Status = 'VALID_LOADED_UPDATED_ONCE' | 'VALID_LOADED_NOT_UPDATED_ONCE' | 'NOT_READY';
export const createGetValuesetTables = () => {
    interface State {
        valuesets: {
            [valuesetCode: string]:
                | {
                      status: Status;
                      numberOfConceptsLastLoaded: number;
                  }
                | undefined;
        };
        data: {
            [valuesetCode: string]: {
                [key: string]: {};
            };
        };
        getNumConceptsLoaded: (concepts: {}) => number;
    }
    const createInitial = (): State => ({
        valuesets: {},
        data: {},
        getNumConceptsLoaded: memoizeOne((concepts: {}) => Object.keys(concepts).length),
    });
    let cachedTables = createInitial();
    const _clearCache = () => {
        cachedTables = createInitial();
    };
    // it is 'calculate's responsibility to set the desired key and merge into the existing record.
    const setData = (valueSetCode, cacheKey, calculate) => {
        if (!cachedTables.data[valueSetCode]) {
            cachedTables.data[valueSetCode] = {};
        }
        const res = calculate();
        cachedTables.data[valueSetCode][cacheKey] = res;
        return res;
    };
    const getData = (valueSetCode, cacheKey, calculate) => {
        return fromNullable(cachedTables.data[valueSetCode])
            .map(vsd => vsd[cacheKey])
            .chain(fromNullable)
            .getOrElseL(() => {
                return setData(valueSetCode, cacheKey, calculate);
            });
    };
    const setAs = (status: Status) => (valueSetCode, concepts) => {
        const { getNumConceptsLoaded } = cachedTables;
        cachedTables.valuesets = {
            ...cachedTables.valuesets,
            [valueSetCode]: {
                status,
                numberOfConceptsLastLoaded: getNumConceptsLoaded(concepts),
            },
        };
    };
    const _getTables = (valueSets: ValueSets, valueSetCode: string, concepts: {}, cacheKey, calculate: () => {}) => {
        const { valuesets: prevValuesets, getNumConceptsLoaded } = cachedTables;
        const valueSet = valueSets[valueSetCode];
        if (!valueSet) {
            return {};
        }
        const { status: prevStatus, numberOfConceptsLastLoaded } = prevValuesets[valueSetCode] || {
            status: 'NOT_READY',
            numberOfConceptsLastLoaded: 0,
        };

        const currIsLoaded = !!(valueSet.allConceptsLoaded && !valueSet.invalid);
        if (prevStatus === 'NOT_READY') {
            if (currIsLoaded) {
                setAs('VALID_LOADED_NOT_UPDATED_ONCE')(valueSetCode, concepts);
                setData(valueSetCode, cacheKey, calculate);
            } else {
                // skip
            }
        } else if (prevStatus === 'VALID_LOADED_NOT_UPDATED_ONCE') {
            if (!currIsLoaded) {
                delete cachedTables.valuesets[valueSetCode];
            } else {
                if (numberOfConceptsLastLoaded !== getNumConceptsLoaded(concepts)) {
                    setAs('VALID_LOADED_UPDATED_ONCE')(valueSetCode, concepts);
                    setData(valueSetCode, cacheKey, calculate);
                } else {
                    // skip
                }
            }
        } else if (!currIsLoaded) {
            // prevStatus === 'VALID_UPDATED_ONCE
            delete cachedTables.valuesets[valueSetCode];
        }
        return getData(valueSetCode, cacheKey, calculate);
    };
    const peekAtState = () => cachedTables;
    return [_getTables, _clearCache, peekAtState] as [typeof _getTables, typeof _clearCache, typeof peekAtState];
};

/*
    We create a global cache here. We can always create local caches for testing with
    createGetValuesetTables();
    Import clearCache and call whenever we need to clear our cache,
*/
export const [getTables, clearCache] = createGetValuesetTables();

export const getValuesetReverseLookupUtilities = (
    valueSets: ValueSets,
    concepts: {
        [id: string]: Concept;
    },
) => {
    const getConceptPropertyMapping = <K extends string | number, V>(
        // second argument is cachekey
        keySelector: (concept: Concept) => [K, string],
        valueSelector: (concept: Concept) => [V, string],
        ignoreCase: boolean = false, // ignore case of index value
    ) => {
        const createKey = (k1: string, k2: string) => k1 + '|' + k2;
        return (valueSetCode: string) => {
            const toLowerIfIgnoreCase = k => (ignoreCase && typeof k === 'string' ? k.toLowerCase() : k);
            // get cacheKeys for our selectors.
            const [, cacheSubKey1] = keySelector({ id: 'fake', code: 'fake', display: 'fake' });
            const [, cacheSubKey2] = valueSelector({ id: 'fake', code: 'fake', display: 'fake' });
            const cacheKey = createKey(cacheSubKey1, cacheSubKey2);
            const map = getTables(
                valueSets,
                valueSetCode, // only updates when valueset invalid or allConceptsLoaded flag changes
                concepts,
                cacheKey, // or the key was never set
                () => {
                    // when recalculating, set the entry in the cache
                    return fromNullable(valueSets[valueSetCode])
                        .map(vs => vs.conceptIds)
                        .chain(fromNullable)
                        .map(ids => mapOption(ids, id => fromNullable(concepts[id])))
                        .map(conceptArr =>
                            conceptArr.reduce((prev, curr) => {
                                const ret = fromNullable(keySelector(curr)[0])
                                    .map(toLowerIfIgnoreCase)
                                    .fold(prev, k => {
                                        return {
                                            ...prev,
                                            [k]: valueSelector(curr)[0],
                                        };
                                    });
                                return ret;
                            }, {}),
                        )
                        .getOrElse({});
                },
            );
            return (index: string | number) => {
                return fromNullable(map[toLowerIfIgnoreCase(index)]).fold(null, identity);
            };
        };
    };
    // second return value is a 'key' to cache tables on.
    // e,g, a table of id -> display will only be calculated once.
    type selector<T> = (concept: Concept) => [T, string];
    const getId: selector<string> = concept => [concept.id, 'id'];
    const getDisplay: selector<string> = concept => [concept.display, 'display'];
    const getCode: selector<string> = concept => [concept.code, 'code'];
    const getConcept: selector<Concept> = concept => [concept, 'concept'];

    const decurry: <T>(
        curriedFn: (valueSetCode: string) => (index: string | number) => T,
    ) => (valueSetCode: string, index: string | number) => T = fn => untypedDecurry(2, fn);

    const getConceptFromCode = decurry(getConceptPropertyMapping(getCode, getConcept));
    const getConceptFromDisplay = decurry(getConceptPropertyMapping(getDisplay, getConcept, true));
    const getConceptIdFromCode = decurry(getConceptPropertyMapping(getCode, getId));
    const getConceptIdFromDisplay = decurry(getConceptPropertyMapping(getDisplay, getId, true));
    const getConceptCodeFromDisplay = decurry(getConceptPropertyMapping(getDisplay, getCode, true));

    const isValidConcept = (vscode: string, id: string) => !!getConceptPropertyMapping(getId, getConcept)(vscode)(id);
    const isValidConceptFromCode = (vscode: string, code: string) => !!getConceptFromCode(vscode, code);
    const isValidConceptFromDisplay = (vscode: string, display: string) => !!getConceptFromDisplay(vscode, display);

    const valuesetIsLoaded = (vscode: string) =>
        fromNullable(valueSets[vscode])
            .map(vs => vs.allConceptsLoaded === true)
            .getOrElse(false);
    return {
        valuesetIsLoaded,
        getConceptFromCode,
        getConceptFromDisplay,
        getConceptIdFromCode,
        getConceptIdFromDisplay,
        getConceptCodeFromDisplay,
        isValidConcept,
        isValidConceptFromCode,
        isValidConceptFromDisplay,
    };
};

export const isNumEqual = (value1: string | number, value2: string | number) => {
    const v1 = parseInt(value1 as any, 10);
    const v2 = parseInt(value2 as any, 10);
    return !isNaN(v1) && v1 === v2;
};
export const isZero = (value: any) => {
    return isNumEqual(value, 0);
};
