import React from 'react';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { fromNullable } from 'fp-ts/lib/Option';

const useTaskOption = (taskId: string) => {
    const taskOSelector = React.useMemo(() => {
        return createSelector(
            (state: RootState) => state.bpm.tasks.byId[taskId],
            fromNullable,
        );
    }, [taskId]);
    const taskO = useSelector(taskOSelector);
    return taskO;
};
export default useTaskOption;
