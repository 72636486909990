import React from 'react';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';
import { withStyles } from '@material-ui/core/styles';
import ProcessFileInput from '../../../bpm/components/ProcessDetail/ProcessFileInput';
import DownloadFromLink from '../../display/components/DownloadFromLink';
import Clear from '@material-ui/icons/Clear';
import { IconButton, FormControl, InputLabel, FormHelperText } from '@material-ui/core';
import MultipleFileUpload from './FileUpload/MultipleFileInput';
import { processContext } from 'bpm/components/processContext';
import uniqueId from 'lodash/uniqueId';

const hideIfNoInput = branch(
    props => !props.input, // input is not provided
    renderNothing,
);

const styles = theme => ({
    button: {
        width: 24,
        height: 24,
        padding: 0,
    },
    error: {
        color: theme.palette.error.main,
        fontSize: 'small',
    },
});

const UploadComponent = ({ taskId, ...props }) => {
    const ariaErrormessageRef = React.useRef(uniqueId('ProcessUploadErrorMsg'));
    const InputProps = {
        'aria-label': props.label,
        'aria-invalid': !!(props.meta.touched && props.meta.error),
        'aria-errormessage': props.meta.touched && props.meta.error ? ariaErrormessageRef.current : undefined,
    };
    const inputRef = React.createRef();

    if (props.multiple) {
        return <MultipleFileUpload taskId={taskId} onBlur={props.input.onBlur} {...props} />;
    }
    const value = props.input.value && Array.isArray(props.input.value) && props.input.value[0];
    return (
        <FormControl
            margin="none"
            style={{ width: '100%' }}
            error={props.meta && props.meta.touched && props.meta.error}
        >
            <InputLabel shrink={true} style={{ marginBottom: '10px' }}>
                {props.label}
            </InputLabel>
            {!props.dontShowUploadInput && (
                <ProcessFileInput
                    inputProps={InputProps}
                    fieldName={props.label}
                    onChange={props.input.onChange}
                    onBlur={props.input.onBlur}
                    value={value}
                    disabled={props.disabled}
                    showFile={false}
                    taskId={taskId}
                    inputRef={inputRef}
                />
            )}
            {value && value.id && (
                <div style={{ display: 'flex', direction: 'row', marginTop: '16px', alignItems: 'center' }}>
                    <DownloadFromLink
                        url={`api/bpm/process-docs/${value.id}/raw`}
                        fileName={value.fileFileName || '<no file name>'}
                        contentType={value.mimeType}
                    />
                    {!props.disabled ? (
                        <IconButton
                            aria-label={
                                value.fileFileName ? `Remove file ${value.fileFileName}` : 'Remove unnamed file'
                            }
                            className={props.classes.button}
                            onClick={() => {
                                props.input.onBlur(null);
                                inputRef.current.value = null;
                            }}
                        >
                            <Clear />
                        </IconButton>
                    ) : null}
                </div>
            )}
            {props.meta && props.meta.touched && props.meta.error && (
                <FormHelperText id={InputProps['aria-errormessage']}>Error: {props.meta.error}</FormHelperText>
            )}
        </FormControl>
    );
};

const ProcessFileUpload = hideIfNoInput(props => (
    <processContext.Consumer>
        {({ id, taskId }) => <UploadComponent {...props} taskId={taskId} />}
    </processContext.Consumer>
));

export default withStyles(styles)(ProcessFileUpload);
