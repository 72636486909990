import { createStore, compose, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { createEpicMiddleware, ofType } from 'redux-observable';
import rootSaga from './sagas';
import rootEpic from './epics';
import rootReducer, { getInitialState, routerHistory } from './reducer';
import { services, Services } from 'sideEffect/services';
import { RootAction } from 'actions/rootAction';
import { BehaviorSubject } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { RootState } from 'reducers/rootReducer';
import { rehydrateState } from 'reducers/serializeDeserialize';
export { routerHistory } from './reducer';

export const epic$ = new BehaviorSubject(rootEpic);

// Since we're using mergeMap, by default any new
// epic that comes in will be merged into the previous
// one, unless an EPIC_END action is dispatched first,
// which would cause the old one(s) to be unsubscribed
const hotReloadingEpic = (action$, ...rest) =>
    epic$.pipe(
        mergeMap(epic => epic(action$, ...(rest as [any, any])).pipe(takeUntil(action$.pipe(ofType('EPIC_END'))))),
    );

export const startNewRootEpic = () => epic$.next(rootEpic);

export function configureStore(initialState?: RootState, noSideEffectsMode: boolean = false) {
    const sagaMiddleware = createSagaMiddleware();
    const epicMiddleware = createEpicMiddleware<RootAction, RootAction, RootState, Services>({
        dependencies: services,
    });
    const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    if (noSideEffectsMode) {
        return createStore(
            rootReducer,
            initialState && (rehydrateState(initialState) as any),
            composeEnhancers(applyMiddleware(routerMiddleware(routerHistory))),
        );
    }
    const store = createStore(
        rootReducer,
        initialState ? (rehydrateState(initialState) as any) : getInitialState(),
        composeEnhancers(applyMiddleware(sagaMiddleware, epicMiddleware, routerMiddleware(routerHistory))),
    );

    epicMiddleware.run(hotReloadingEpic);

    sagaMiddleware.run(rootSaga);

    return store;
}

export default configureStore;
