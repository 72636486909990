import * as React from 'react';
import { SFC } from 'react';
import { Card } from '@material-ui/core';
import CommentList from '../Comments';
import ViewTitle from '../../../components/generics/ViewTitle';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { fromNullable, fromPredicate, tryCatch } from 'fp-ts/lib/Option';
import { evaluateExpression } from 'casetivity-shared-js/lib/spel/evaluate';
import { currentUserProperties } from 'expressions/contextUtils/currentUser';
import useViewConfig from 'util/hooks/useViewConfig';

interface ProcessConfigConfig {
    displayNotes?: string | boolean;
}
const CommentPanel: SFC<{
    processId?: string;
    taskId?: string;
}> = ({ processId, taskId }) => {
    const caseDefinition = useSelector((state: RootState) => {
        return fromNullable(state.bpm.processInstances.byId[processId])
            .mapNullable(process => process.businessKey)
            .mapNullable(bk => state.bpm.processDefinitions.byId[bk])
            .getOrElse(null);
    });
    const displayExpression: string | boolean | undefined = useSelector((state: RootState) => {
        return fromNullable(state.viewConfig.processes)
            .mapNullable(pis => caseDefinition && pis[caseDefinition.key])
            .mapNullable(pi => pi.config)
            .chain(fromPredicate<string>(Boolean))
            .chain(configStr => tryCatch(() => JSON.parse(configStr) as ProcessConfigConfig))
            .mapNullable(config => config.displayNotes)
            .toUndefined();
    });
    const viewConfig = useViewConfig();
    const roles = useSelector((state: RootState) => state.viewConfig.user.roles);
    const displayNotes = React.useMemo(() => {
        if (typeof displayExpression === 'undefined') {
            return true;
        } else if (typeof displayExpression === 'boolean') {
            return displayExpression;
        } else {
            try {
                const res = evaluateExpression(displayExpression, {
                    roles: roles || [],
                    ...currentUserProperties(viewConfig),
                    caseDefinition,
                });
                return res;
            } catch (e) {
                console.log('expression: ', displayExpression, 'ERROR', e);
                console.error(e);
                return false;
            }
        }
    }, [displayExpression, roles, caseDefinition, viewConfig]);

    return displayNotes ? (
        <Card style={{ marginBottom: '1em' }}>
            <div>
                <CommentList
                    title={<ViewTitle title="Notes" />}
                    processId={processId}
                    taskId={taskId}
                    showFloatingButtons={false}
                />
            </div>
        </Card>
    ) : null;
};
export default CommentPanel;
