import { LOAD_VALUE_SET, LOAD_VALUE_SET_GROUP, LOAD_MULTIPLE_VALUE_SETS } from './constants';
import { createCustomAction } from 'typesafe-actions';

export const loadValueSet = createCustomAction(LOAD_VALUE_SET, type => {
    return (valueSetCode: string) => ({
        type,
        payload: valueSetCode,
    });
});

export const loadValueSetGroup = createCustomAction(LOAD_VALUE_SET_GROUP, type => {
    return (valueSetCode: string, group: string) => ({
        type,
        payload: {
            valueSetCode,
            group,
        },
    });
});

interface Vs {
    valueSet: string;
    group?: string;
}
export const loadValueSets = createCustomAction(LOAD_MULTIPLE_VALUE_SETS, type => {
    return (valuesets: Vs[]) => ({
        type,
        payload: {
            valuesets,
        },
    });
});
