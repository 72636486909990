import { getPrimaryDash, getPrimaryDashSuccess, getPrimaryDashFailure } from './actions';
import { isActionOf } from 'typesafe-actions';
import { RootState } from 'reducers/rootReducer';
import { Epic } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { Services } from 'sideEffect/services';
import { filter, map, catchError, flatMap } from 'rxjs/operators';
import { of } from 'rxjs';

const getPrimaryDashboardFlow: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) =>
    action$.pipe(
        filter(isActionOf(getPrimaryDash)),
        flatMap(action =>
            services.getCurrentUserPrimaryDashboard().pipe(
                map(response => getPrimaryDashSuccess(response)),
                catchError(err => of(getPrimaryDashFailure(err))),
            ),
        ),
    );
export default getPrimaryDashboardFlow;
