import React from 'react';
import { RootState } from '../../../../reducers/rootReducer';
import { connect } from 'react-redux';
import { fromNullable } from 'fp-ts/lib/Option';

interface EntityPageTitleProps {
    record?: { title?: string };
    resource: string;
    defaultTitle: string;
    viewName?: string;
}
const EntityPageTitle: React.SFC<EntityPageTitleProps & { displayName?: string }> = ({
    record,
    resource,
    defaultTitle,
    displayName,
    viewName,
}) => (
    <span>
        {viewName}
        {record ? `${displayName || resource}: ${record.title || defaultTitle}` : ''}
    </span>
);

EntityPageTitle.defaultProps = {
    record: {},
    defaultTitle: '',
};

export default connect((state: RootState, props: EntityPageTitleProps) => ({
    displayName: fromNullable(state.viewConfig.entities)
        .mapNullable(e => e[props.resource])
        .mapNullable(r => r.displayName)
        .getOrElse(undefined),
}))(EntityPageTitle);
