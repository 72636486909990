import { getFormAsyncErrors, getFormSyncErrors, getFormSubmitErrors } from 'redux-form';
import uniq from 'lodash/uniq';
import minBy from 'lodash/minBy';
import { RootState } from '../../../../reducers/rootReducer';
import memoizeOne from 'memoize-one';
import {
    ViewField,
    EntityField,
    SearchField,
    Tab,
    MenuItem,
    FieldViewField,
} from '../../../../reducers/ViewConfigType';
import {
    isValueSetOrValueSetManyField,
    isRefManyField,
    isRefOneField,
    getRefEntityName,
    isString,
    getValueSetForFieldExpr,
    isValueSetField,
    isValueSetManyField,
    isRefManyManyField,
    getDataTypeForFieldExpr,
} from './getFieldProperties';
import { isFieldViewField, isAddressVerificationField } from './getFieldProperties/viewFields';
import { fromNullable, tryCatch, fromPredicate, fromEither, some } from 'fp-ts/lib/Option';
import decodeAddressConfig from 'fieldFactory/input/components/Address/util/decodeAddressConfig';
import { IFieldMapping2 } from 'fieldFactory/input/components/Address/types';
import applyToBracketedExpressions from 'casetivity-shared-js/lib/print-templates/render/util/applyToBracketedExpressions';
import { evaluateExpression } from 'casetivity-shared-js/lib/spel/evaluate';
import { tryCatch2v } from 'fp-ts/lib/Either';
import produce from 'immer';
export * from './getFieldProperties';
export * from './getFieldProperties/viewFields';

type ViewConfig = RootState['viewConfig'];
type View = ViewConfig['views'][keyof ViewConfig['views']];
/*
    Note to prevent confusion:
    Both entities and fields have 'tabs' properties!
    viewConfig[entityName].tabs contains an array of tab titles
    fieldObj.tabs contains indexes of the tabs to appear in
    (1 indexed. This is in case we want to use 0 to 'opt-in' to outside-tab view in the future)
*/

export const getDisplayName = (viewConfig: ViewConfig, entityName: string): string =>
    viewConfig.entities[entityName].displayName;

export const getPluralName = (viewConfig: ViewConfig, entityName: string): string | null =>
    viewConfig.entities[entityName].displayNamePlural;

export const getPossibleMatchName = (viewConfig: ViewConfig, entityName: string): string =>
    `${viewConfig.entities[entityName].displayName} Possible Matches`;

export const hasTabs = (viewConfig: ViewConfig, viewName: string): boolean =>
    Object.prototype.hasOwnProperty.call(viewConfig.views[viewName], 'tabs'); // TODO - deprecate

export const viewHasTabs = (view: View): boolean => view.tabs && Object.keys(view.tabs).length > 0;

export const getTabsTitlesFromView = (view: View): string[] => {
    if (viewHasTabs(view)) {
        return Object.keys(view.tabs || {});
    }
    return [];
};

// TODO Update REMOVING the OLD Config
export const getFields = (viewConfig: ViewConfig, entityName: string): EntityField[] =>
    Object.values(viewConfig.entities[entityName].fields);

export const getEntityId = (viewConfig: ViewConfig, entityName: string) => viewConfig.entities[entityName].id;

export const getEntityRestUrl = (viewConfig: ViewConfig, entityName: string) => viewConfig.entities[entityName].restUrl;

export const getFieldsFromView = (view: View): ViewField[] => Object.values(view.fields);
export const getFieldInstanceEntriesFromView = (viewConfig: ViewConfig, viewName): [string, ViewField][] => {
    const view = viewConfig.views[viewName];
    const getWithPostfix = getAdjustedFieldSource(viewConfig)(view);
    return !view
        ? []
        : Object.entries(view.fields).map(([key, vf]) => {
              return [isFieldViewField(vf) ? getWithPostfix(vf, key) : key, vf];
          });
};

export const getSearchFieldsFromView = (view: View): SearchField[] =>
    Object.values(typeof view.searchFields === 'undefined' ? {} : view.searchFields);

export const getDefaultListViewName = (viewConfig: ViewConfig, resource: string) => {
    return fromNullable(viewConfig.entities[resource])
        .mapNullable(e => e.defaultViews)
        .mapNullable(d => d.LIST)
        .mapNullable<string | null>(L => L.name)
        .getOrElse(null);
};

// smaller set of fields to display when shown as a reference
export const getAsRefFields = (viewConfig, entityName) => {
    const entityDef = viewConfig.entities[entityName];
    if (entityDef.defaultViews && entityDef.defaultViews.LIST) {
        const defaultListView = viewConfig.views[entityDef.defaultViews.LIST.name];
        return getFieldsFromView(defaultListView);
    }
    return [];
    // TODO: Backup to all entity fields or certain types
    // - this will be some effort since would have to use the data type to determine widget..?
};

export const getViewFieldsForTab = (tab: Tab): ViewField[] => Object.values(tab.fields);
export const getViewFieldInstanceEntriesForTab = (
    viewConfig: ViewConfig,
    viewName: string,
    tab: Tab,
): [string, ViewField][] => {
    const view = viewConfig.views[viewName];
    const getWithPostfix = getAdjustedFieldSource(viewConfig)(view);
    return Object.entries(tab.fields).map(([key, vf]) => {
        return [isFieldViewField(vf) ? getWithPostfix(vf, key) : key, vf];
    });
};

/*
    Tab validation functions
*/
const collectErrors = state => {
    const syncErrors = getFormSyncErrors('record-form')(state);
    const asyncErrors = getFormAsyncErrors('record-form')(state);
    const submitErrors = getFormSubmitErrors('record-form')(state);

    return {
        ...syncErrors,
        ...asyncErrors,
        ...submitErrors,
    };
};

export const getView = (viewConfig: ViewConfig, viewName: string): View => viewConfig.views[viewName];

export const APPCASE_LIST_PREFIX = '_APPCASE_PROC_LIST:'; // trailing colon indicates process definition key follows
export const APPCASE_PAGE_PREFIX = '_APPCASE_PROC_PAGE:';
const isCapital = (c: string) => c.toUpperCase() === c;

// like adjustLinkedXToLinkedEntity, except returns the related entity in _2
const disallowJustEntity = (x?: string | null): string | null => (x && x !== 'Entity' ? x : null);
export const adjustLinkedXToLinkedEntityWithRes = (fieldName: string): [string, string | null] => {
    if (!fieldName) {
        // just in case undefined is passed at runtime
        // (this happens due to the CloneElement stuff where source isn't given to inputs initially)
        return [fieldName, null];
    }
    if (fieldName.startsWith('linked') && fieldName.length > 6 && isCapital(fieldName[6])) {
        const [first, ...rest] = fieldName.split('.');
        return [['linkedEntity', ...rest].join('.'), disallowJustEntity(first.slice('linked'.length))];
    }
    const linkedIndex = fieldName.indexOf('.linked');
    if (
        linkedIndex !== -1 &&
        fieldName[linkedIndex + '.linked'.length] &&
        isCapital(fieldName[linkedIndex + '.linked'.length])
    ) {
        const before = fieldName.slice(0, linkedIndex);
        const middle = fieldName.slice(linkedIndex + '.linked'.length).split('.')[0];
        const after = fieldName
            .slice(linkedIndex + 1)
            .split('.')
            .flatMap((v, i) => (i === 0 ? [] : [v]))
            .join('.');
        return [`${before}.linkedEntity${after.length > 0 ? `.${after}` : ''}`, disallowJustEntity(middle)];
    }
    return [fieldName, null];
};

export const adjustLinkedXToLinkedEntity = (fieldName: string) => {
    return adjustLinkedXToLinkedEntityWithRes(fieldName)[0];
};

export const getBPMConfigFields = (
    viewName: string,
    viewConfig: ViewConfig,
    options: {
        mode: 'KEEP_LINKEDX_TYPE' | 'ALWAYS_LINKEDENTITY'; // adjustment to fields returned
        readOrWrite: 'READ' | 'WRITE'; // read = columns + summary. write = search
    },
): FieldViewField[] => {
    const { mode, readOrWrite } = options;
    if (viewName.startsWith(APPCASE_LIST_PREFIX)) {
        const keyInSEARCHview: 'searchFields' | 'columns' = readOrWrite === 'WRITE' ? 'searchFields' : 'columns';
        const processConfig = viewConfig.processes[viewName.slice(APPCASE_LIST_PREFIX.length)];
        if (processConfig && processConfig.views.SEARCH && processConfig.views.SEARCH[keyInSEARCHview]) {
            const fields: FieldViewField[] = Object.values(processConfig.views.SEARCH[keyInSEARCHview]).filter(
                isFieldViewField,
            );
            if (mode === 'ALWAYS_LINKEDENTITY') {
                return fields.map((f): FieldViewField => ({ ...f, field: adjustLinkedXToLinkedEntity(f.field) }));
            }
            return fields;
        }
    }
    if (viewName.startsWith(APPCASE_PAGE_PREFIX)) {
        if (readOrWrite === 'WRITE') {
            // No WRITE fields are specified for the APPCASE_PAGE view. returning no fields
            return [];
        } else {
            const processConfig = viewConfig.processes[viewName.slice(APPCASE_LIST_PREFIX.length)];
            if (processConfig && processConfig.views.SUMMARY && processConfig.views.SUMMARY.headers) {
                const fields: FieldViewField[] = Object.values(processConfig.views.SUMMARY.headers).filter(
                    isFieldViewField,
                );
                if (mode === 'ALWAYS_LINKEDENTITY') {
                    return fields.map((f): FieldViewField => ({ ...f, field: adjustLinkedXToLinkedEntity(f.field) }));
                }
                return fields;
            }
        }
    }
    return [];
};

// Most viewNames are straightforward indexes into the viewConfig.
// However some views have additional behavior we want to inject.
// for example _APPCASE_PROC_LIST represents our process list config,
// but _APPCASE_PROC_LIST:environmental-case represents our process list with the environmental-case selected.
// in this case we should merge fields from viewConfig.processes['environmental-case'].views.SEARCH.(columns or search)
// this is done on a case by case basis

// the method below returns (adjusted) viewName to index into the viewConfig, and additional fields to merge,
// since these are common operations that occur together.
export const getViewIndexAndAdditionalConfigFields = (
    viewName: string,
    viewConfig: ViewConfig,
    mode: 'KEEP_LINKEDX_TYPE' | 'ALWAYS_LINKEDENTITY',
    readOrWrite: 'READ' | 'WRITE' = 'READ',
): [string, FieldViewField[]] => {
    if (viewName.startsWith(APPCASE_LIST_PREFIX)) {
        return [
            viewName.slice(0, APPCASE_LIST_PREFIX.length - 1),
            getBPMConfigFields(viewName, viewConfig, { mode, readOrWrite }),
        ];
    }
    if (viewName.startsWith(APPCASE_PAGE_PREFIX)) {
        return [
            viewName.slice(0, APPCASE_PAGE_PREFIX.length - 1),
            getBPMConfigFields(viewName, viewConfig, { mode, readOrWrite }),
        ];
    }
    return [viewName, []];
};
export const getValuesetFieldsFromAddressWidgetConfig = (
    config: string,
    viewConfig: ViewConfig,
): [string, string][] => {
    const VERIF_SOURCE = 'verificationStatus';
    const base = tryCatch(() => getValueSetForFieldExpr(viewConfig, 'Address', VERIF_SOURCE, 'TRAVERSE_PATH'))
        .map(valueSet => [[VERIF_SOURCE, valueSet] as [string, string]])
        .getOrElse([]);
    const addrConfig = decodeAddressConfig(config, 'RETURN_NULL');
    const fromAddressConfig = Object.entries(addrConfig.fieldMapping)
        .flatMap(([k, path]: [keyof IFieldMapping2, string]): [string, string][] => {
            try {
                if (k.endsWith('Id') || k.endsWith('Code')) {
                    const source = path.endsWith('Id')
                        ? path.slice(0, -2)
                        : path.endsWith('Code')
                        ? path.slice(0, -4)
                        : path;
                    const dataType = getDataTypeForFieldExpr(viewConfig, 'Address', source, 'TRAVERSE_PATH');
                    if (dataType === 'VALUESET' || dataType === 'VALUESETMANY') {
                        const valueSet = getValueSetForFieldExpr(viewConfig, 'Address', source, 'TRAVERSE_PATH');
                        return [[source, valueSet]];
                    }
                }
                return [];
            } catch (e) {
                return [];
            }
        })
        .concat(base);
    return fromAddressConfig;
};
export const getValuesetCodesFromAddressWidgetConfig = (config: string, viewConfig: ViewConfig): string[] => {
    return getValuesetFieldsFromAddressWidgetConfig(config, viewConfig).map(([f, c]) => c);
};

export const getAllFieldsFromView = (viewConfig: ViewConfig, viewName: string) => {
    const view = getView(viewConfig, viewName);
    const tabFields = getTabsTitlesFromView(view).map(tabKey => getViewFieldsForTab(view.tabs![tabKey]));

    return [...getFieldsFromView(view)].concat(...tabFields);
};
export const getAllFieldEntriesFromView = (viewConfig: ViewConfig, viewName: string) => {
    const view = getView(viewConfig, viewName);
    const tabFields = getTabsTitlesFromView(view).flatMap(tabKey => Object.entries(view.tabs![tabKey].fields));

    return Object.entries(view.fields).concat(tabFields);
};

/* this seriously needs tests */
/* memoizing-one since there's JSON parsing going on in there, and it can be triggered in our form-contexts a lot */
export const getValueSetFieldsRequiredForEntity = memoizeOne(
    (
        viewConfig: ViewConfig,
        _viewName: string,
        types: 'ONES' | 'MANYS' | 'BOTH',
    ): {
        [field: string]: string;
    } => {
        const filterField = (field: ViewField): field is FieldViewField =>
            isFieldViewField(field) &&
            (types === 'BOTH'
                ? isValueSetOrValueSetManyField(viewConfig, field.entity, field.field)
                : types === 'ONES'
                ? isValueSetField(viewConfig, field.entity, field.field)
                : isValueSetManyField(viewConfig, field.entity, field.field));
        const [viewName, processConfigFields] = getViewIndexAndAdditionalConfigFields(
            _viewName,
            viewConfig,
            'ALWAYS_LINKEDENTITY',
        );
        const view = getView(viewConfig, viewName);
        const allViewFields = getAllFieldsFromView(viewConfig, viewName);
        const allSubViewFields = allViewFields
            .filter(
                field =>
                    isFieldViewField(field) &&
                    (isRefOneField(viewConfig, field.entity, field.field) ||
                        isRefManyField(viewConfig, field.entity, field.field)),
            )
            .flatMap((field: FieldViewField) =>
                getAsRefFields(viewConfig, getRefEntityName(viewConfig, view.entity, field.field, 'TRAVERSE_PATH')).map(
                    childField => [field, childField] as const,
                ),
            );

        const allFieldsToGetValuesetsFrom = [...allViewFields].concat(...processConfigFields);
        const subViewFieldValueSets = allSubViewFields
            .filter((t): t is [FieldViewField, FieldViewField] => filterField(t[1]))
            .map(
                ([originalField, childField]) =>
                    [
                        originalField.field + '.' + childField.field,
                        getValueSetForFieldExpr(viewConfig, childField.entity, childField.field),
                    ] as const,
            );

        const vsFieldValueSets = allFieldsToGetValuesetsFrom
            .filter(filterField)
            .map(
                (field: FieldViewField) =>
                    [field.field, getValueSetForFieldExpr(viewConfig, field.entity, field.field)] as const,
            );
        const vsSuggestionFieldValueSets = allFieldsToGetValuesetsFrom.flatMap((f): [string, string][] => {
            if (f.widgetType === 'VALUESET_SUGGEST') {
                return fromNullable(f.config)
                    .chain(conf => tryCatch(() => JSON.parse(conf) as { valueSet?: string }))
                    .chain(conf => fromNullable(conf.valueSet))
                    .fold<[string, string][]>([], valueSet => [[f.field, valueSet]]);
            }
            return [];
        });

        const addressWidgetValuesets = allViewFields.filter(isAddressVerificationField).flatMap(f => {
            return getValuesetFieldsFromAddressWidgetConfig(f.config, viewConfig);
        });
        const valueSets = vsFieldValueSets
            .concat(subViewFieldValueSets)
            .concat(addressWidgetValuesets)
            .concat(vsSuggestionFieldValueSets)
            .filter(([f, vs]) => isString(vs))
            .reduce<{ [field: string]: string }>((prev, [field, valueSetCode]) => {
                prev[field] = valueSetCode;
                return prev;
            }, {});
        return valueSets;
    },
);
export const getValueSetCodesRequiredForEntity = (viewConfig: ViewConfig, _viewName: string): string[] => {
    return uniq(Object.values(getValueSetFieldsRequiredForEntity(viewConfig, _viewName, 'BOTH')));
};

const emptyArr = [];
export const findTabsWithErrors = <Props extends { viewName: string }>(
    state: RootState,
    props: Props,
    collectErrorsImpl = collectErrors,
): string[] | undefined => {
    const viewConfig = state.viewConfig;
    const viewName = props.viewName;
    const view = getView(viewConfig, viewName);
    return findTabsWithErrorsFromViewDef(state, view, collectErrorsImpl);
};
export const findTabsWithErrorsFromViewDef = (
    state: RootState,
    view: View,
    collectErrorsImpl = collectErrors,
): string[] | undefined => {
    const errors = collectErrorsImpl(state);
    const toReturn = viewHasTabs(view)
        ? getTabsTitlesFromView(view).filter(tabKey =>
              getViewFieldsForTab(view.tabs![tabKey]).some(
                  fieldObj =>
                      isFieldViewField(fieldObj) &&
                      (errors[fieldObj.field] ||
                          errors[`${fieldObj.field}Ids`] ||
                          errors[`${fieldObj.field}Id`] ||
                          // added the below to support 'verificationStatusCode' (address field registered name). Might cause collisions in rare cases.
                          errors[`${fieldObj.field}Code`]),
              ),
          )
        : undefined;
    if (toReturn && toReturn.length === 0) {
        return emptyArr;
    } else {
        return toReturn;
    }
};

export const getRestUrl: (entityName: string) => (state: RootState) => string | null = entityName => state =>
    state.viewConfig &&
    state.viewConfig.entities &&
    state.viewConfig.entities[entityName] &&
    state.viewConfig.entities[entityName].restUrl;

export const getMenus = (viewConfig: ViewConfig): MenuItem[] => viewConfig.menus;

export const translateRoute = (viewConfig: ViewConfig, route?: string | null, view?: string | null): string | null => {
    if (viewConfig && !route && !view) {
        // console.error('Falsy view and route while translating route for MenuItem');
    }
    if (view && getView(viewConfig, view)) {
        return getView(viewConfig, view).route;
    }
    return route as string; // one or the other will be a string
};

export const getLogin = (viewConfig: ViewConfig): string | null => (viewConfig.user ? viewConfig.user.login : null);
export const getLoginName = (viewConfig: ViewConfig): string | null =>
    viewConfig.user ? viewConfig.user.firstName : null;

export const allowsDelete = (accessLevel: number): boolean => accessLevel >= 5;
export const allowsMerge = (accessLevel: number): boolean => accessLevel >= 4;
export const allowsEdit = (accessLevel: number): boolean => accessLevel >= 3;
export const allowsCreate = (accessLevel: number): boolean => accessLevel >= 2;

export const getAccessLevelForEntity = (viewConfig: ViewConfig, entityName: string): number => {
    return viewConfig.entities[entityName].accessLevel;
};

export const getViewConfig = () => (state: RootState): ViewConfig => {
    const viewConfig = state.viewConfig;
    return viewConfig;
};

export const getEntityValidations = () => (state: RootState) => state.entityValidations;

export const evaluatePreFilter = (viewConfig: ViewConfig) => (prefilter: string | true | false | null | number) => {
    if (typeof prefilter === 'boolean' || typeof prefilter === 'number' || prefilter === null) {
        return some(prefilter);
    }
    return fromEither(
        tryCatch2v(
            () => {
                return applyToBracketedExpressions(
                    exp => {
                        const result = evaluateExpression(
                            exp,
                            {
                                user: produce(viewConfig.user, draftState => {
                                    if (draftState.properties) {
                                        if (!draftState.properties.organizationId) {
                                            draftState.properties.organizationId = null;
                                        }
                                    }
                                    return draftState;
                                }),
                            },
                            {},
                        );
                        return `${result}`;
                    },
                    '<<',
                    '>>',
                )(prefilter);
            },
            e => {
                console.error(e);
                return e;
            },
        ),
    );
};
export const getAllPrefilters = (
    viewConfig: ViewConfig,
    resource: string,
    viewName: string,
    filterBy: 'VISIBLE_WITHOUT_DEFAULT' | 'NOT_VISIBLE' | 'NON_DEFAULT' | 'DEFAULT_VALUE' | 'ALL' = 'ALL',
): {} => {
    let allConfigs = {};
    const [viewIndex] = getViewIndexAndAdditionalConfigFields(viewName, viewConfig, 'KEEP_LINKEDX_TYPE');
    const searchFields = viewConfig.views[viewIndex].searchFields;

    if (searchFields) {
        Object.keys(searchFields || {}).forEach((field: string) => {
            const config: string = searchFields[field].config;
            if (config) {
                const evaluatedPreFilter = fromPredicate(Boolean)(config)
                    .map(JSON.parse)
                    .mapNullable((conf: any): string => {
                        if (
                            filterBy === 'ALL' ||
                            (filterBy === 'VISIBLE_WITHOUT_DEFAULT' && conf.visible === true) ||
                            (filterBy === 'NOT_VISIBLE' && conf.visible === false) ||
                            (filterBy === 'DEFAULT_VALUE' && conf.visible === 'default') ||
                            (filterBy === 'NON_DEFAULT' && conf.visible !== 'default')
                        ) {
                            return conf.prefilter;
                        }
                        return null;
                    })
                    .chain(evaluatePreFilter(viewConfig))
                    .chain(
                        fromPredicate(value =>
                            Boolean(
                                (typeof value === 'string' && value) ||
                                    typeof value === 'number' ||
                                    value === null ||
                                    typeof value === 'boolean',
                            ),
                        ),
                    )
                    .toUndefined();
                if (typeof evaluatedPreFilter !== 'undefined') {
                    const modifiedFieldName = (() => {
                        const fieldName = searchFields[field].field;
                        const searchType = searchFields[field].searchType;
                        const f = isValueSetField(viewConfig, resource, fieldName, 'POP_LAST')
                            ? `${fieldName}Code`
                            : isValueSetManyField(viewConfig, resource, fieldName, 'POP_LAST')
                            ? `${fieldName}.code`
                            : isRefOneField(viewConfig, resource, fieldName)
                            ? `${fieldName}Id`
                            : fieldName;
                        return searchType ? `${f}__${searchType}` : f;
                    })();
                    allConfigs[modifiedFieldName] = evaluatedPreFilter;
                }
            }
        });
    }
    return allConfigs;
};

export const showRecentlyViewed = (viewConfig: ViewConfig, viewName: string) => {
    return fromNullable(viewConfig.views)
        .mapNullable(views => views[viewName])
        .chain(v => fromPredicate<string>(Boolean)(v.config))
        .chain(c => tryCatch(() => JSON.parse(c)))
        .mapNullable(conf => conf.showRecentlyViewed as number)
        .getOrElse(0);
};

export const getValidationExpForEntity = (viewConfig: ViewConfig, entityName: string): string | null | undefined =>
    viewConfig.entities[entityName].validationExp;

export const getValidationExpForSearch = (viewConfig: ViewConfig, viewName?: string) => {
    const config = viewName && viewConfig.views[viewName].config;
    if (config) {
        const fullConfig = JSON.parse(config);

        const exp = JSON.stringify(fullConfig.searchValidations);

        return exp;
    }

    return;
};

export const getViewConfiguration = (viewConfig: ViewConfig, viewName: string) => {
    if (viewName && viewConfig.views[viewName].config) {
        const fullConfig = viewConfig.views[viewName].config;

        return fullConfig;
    }

    return;
};
export const isFieldToAppendIdToSource = (viewConfig: ViewConfig) => (fromView: View) => (f: FieldViewField) => {
    return (
        isRefOneField(viewConfig, fromView.entity, f.field, 'TRAVERSE_PATH') ||
        isValueSetField(viewConfig, fromView.entity, f.field, 'TRAVERSE_PATH')
    );
};
export const isFieldToAppendIdsToSource = (viewConfig: ViewConfig) => (fromView: View) => (f: FieldViewField) => {
    return (
        isRefManyManyField(viewConfig, fromView.entity, f.field, 'TRAVERSE_PATH') ||
        isValueSetManyField(viewConfig, fromView.entity, f.field, 'TRAVERSE_PATH')
    );
};
export const getAdjustedFieldSource = (viewConfig: ViewConfig) => (view: View) => (
    f: FieldViewField,
    widgetKey?: string,
) => {
    const fieldKey = widgetKey || f.field;

    if (f.widgetType === 'ADDRESS' && fieldKey) {
        return fieldKey.endsWith('Code') ? fieldKey : `${fieldKey}Code`;
    }
    const appendId = isFieldToAppendIdToSource(viewConfig)(view);
    const appendIds = isFieldToAppendIdsToSource(viewConfig)(view);
    return appendId(f) ? `${fieldKey}Id` : appendIds(f) ? `${fieldKey}Ids` : fieldKey;
};

export const areActionsHidden = (viewName, viewConfig) => {
    if (viewConfig.views[viewName] && viewConfig.views[viewName].config) {
        const parsedHideActions = JSON.parse(viewConfig.views[viewName].config).hideActions;
        if (parsedHideActions) {
            return parsedHideActions;
        }
    }
    return false;
};

export const getDefaultSort = (viewConfig: ViewConfig, viewName: string, defaultOrder: 'ASC' | 'DESC' = 'ASC') => {
    const view = viewConfig.views[viewName];
    if (!view) {
        return;
    }
    const getAdjustedSource = getAdjustedFieldSource(viewConfig)(view);
    const sortableFieldKeys = Object.keys(view.fields).filter(
        fieldKey => (view.fields[fieldKey] as SearchField).sortDir && (view.fields[fieldKey] as SearchField).sortOrder,
    );
    const firstSortKey =
        sortableFieldKeys.length > 0
            ? minBy(sortableFieldKeys, key => (view.fields[key] as SearchField).sortOrder)
            : null;
    return firstSortKey
        ? {
              field: getAdjustedSource(view.fields[firstSortKey] as FieldViewField),
              order: (view.fields[firstSortKey] as SearchField).sortDir || defaultOrder,
          }
        : undefined;
};
export const getCustomViewName = (viewType: 'SHOW' | 'EDIT' | 'CREATE' | 'LIST', throwException: boolean = true) => (
    resource: string,
    viewConfig: ViewConfig,
    viewItemConfig?: string,
) => {
    return fromPredicate(Boolean)(viewItemConfig)
        .chain(config => tryCatch(() => JSON.parse(viewItemConfig)))
        .mapNullable(
            (config: { viewOverride?: { show?: string; edit?: string; create?: string; list?: string } }) =>
                config.viewOverride,
        )
        .mapNullable(viewOverride => viewOverride[viewType.toLowerCase()])
        .getOrElseL(() => {
            return fromNullable(viewConfig.entities[resource].defaultViews)
                .mapNullable(defaultViews => defaultViews[viewType])
                .mapNullable(vt => vt.name)
                .getOrElseL(() => {
                    if (throwException) {
                        throw new Error(`Could not get a default viewName for "${resource}, ${viewType}"`);
                    }
                    return undefined;
                });
        });
};

export const applyNoWrap = config => {
    if (config) {
        const parsedConfig = JSON.parse(config);
        if (parsedConfig && parsedConfig.noWrap) {
            return parsedConfig.noWrap;
        }
    }
    return false;
};
