/* eslint jsx-a11y/anchor-is-valid: 0 */
/* eslint no-script-url: 0 */
import React from 'react';
import { RootState } from '../../../reducers/rootReducer';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import uniq from 'lodash/uniq';
import get from 'lodash/get';
import { Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import { getRefEntityName } from '../../../components/generics/utils/viewConfigUtils';
import { Dialog } from '@material-ui/core';
import Toolbar from 'components/generics/form/Toolbar.aor';
import SaveButton from 'components/generics/button/SaveButton';
import CloseButton from '../../popovers/PopoverCloseButton';
import ViewConfig from '../../../reducers/ViewConfigType';
import GenericEdit from '../../../components/generics/genericEdit';

interface RefManyManyInputProps {
    input: {
        value: string;
        onBlur: Function;
    };
    resource: string;
    viewConfig: ViewConfig;
    source: string;
    reference: string;
    data: {
        [id: string]: {
            id: string;
            title: string;
        };
    };
    record: {
        id: string;
    };
    specificIds: (string)[];
    disabled?: boolean;
}

interface RefManyManyState {
    openRecord: string | null;
}

class SelectOneOfInput extends React.Component<RefManyManyInputProps, RefManyManyState> {
    constructor(props: RefManyManyInputProps) {
        super(props);
        this.state = {
            openRecord: null,
        };
    }

    handleEditOpen = id => {
        this.setState({ openRecord: id });
    };

    handleEditClose = () => {
        this.setState({ openRecord: null });
    };

    render() {
        const { source, reference, specificIds, disabled = false, data } = this.props;
        return (
            <div style={{ paddingTop: '1em' }}>
                {/* presentation */}
                <RadioGroup
                    name={this.props.source}
                    value={this.props.input.value || '_None'}
                    onChange={(e: any) => {
                        // tslint:disable-line
                        this.props.input.onBlur(e.target.value === '_None' ? null : e.target.value);
                    }}
                >
                    {uniq(specificIds).map(id => (
                        <FormControlLabel
                            value={id || '_None'}
                            disabled={disabled}
                            control={<Radio />}
                            label={
                                id ? (
                                    <a
                                        href="javascript:;"
                                        style={{ zIndex: 3, position: 'relative' }}
                                        onClick={e => {
                                            console.log('froma', e); //tslint:disable-line
                                            e.stopPropagation();
                                            e.preventDefault();
                                            this.handleEditOpen(id);
                                        }}
                                    >
                                        {(id && data && data[id] && data[id].title) || id}
                                    </a>
                                ) : (
                                    'None'
                                )
                            }
                        />
                    ))}
                </RadioGroup>
                <Dialog
                    TransitionProps={
                        {
                            // https://github.com/dequelabs/axe-core/issues/146
                            role: 'presentation',
                        } as any
                    }
                    open={!!this.state.openRecord}
                    onClose={this.handleEditClose}
                    maxWidth={false}
                    fullWidth={true}
                >
                    <div>
                        {this.state.openRecord && (
                            <GenericEdit
                                {...{
                                    viewName: `${reference}Edit`,
                                    formId: `inspectionedit-from-${source}-to-${reference}-${this.state.openRecord}-realedit`,
                                    redirect: false,
                                    hasList: false,
                                    location: {
                                        pathname: `/${reference}/${this.state.openRecord}`,
                                    },
                                    match: {
                                        isExact: true,
                                        params: {
                                            id: this.state.openRecord as any, // tslint:disable-line
                                            basePath: `/${reference}`,
                                        },
                                    },
                                    resource: reference,
                                    onSaveCb: (...args) => {
                                        console.log('close and reload now! args: ', args);
                                    }, // tslint:disable-line
                                    name: reference,
                                    toolbar: (
                                        <Toolbar>
                                            <CloseButton handleClose={this.handleEditClose} />
                                            <SaveButton />
                                        </Toolbar>
                                    ),
                                }}
                            />
                        )}
                    </div>
                </Dialog>
            </div>
        );
    }
}
const makeMakeMapStateToProps = (readOnly: boolean = false) => () => {
    const mapStateToProps = (state: RootState, props) => {
        const reference = getRefEntityName(
            state.viewConfig,
            props.resource,
            props.source.endsWith('Id') ? props.source.slice(0, -2) : props.source,
        );
        return {
            data: state.admin.entities[reference] || {},
            disabled: readOnly || props.disabled || false,
            input: readOnly ? { value: get(props.record, props.source) } : props.input,
            reference,
        };
    };
    return mapStateToProps;
};
export default compose(connect(makeMakeMapStateToProps()))(SelectOneOfInput);

export const DisplayVersion = compose(connect(makeMakeMapStateToProps(true)))(SelectOneOfInput);
