import * as React from 'react';
import { Component, ReactElement } from 'react';
import { connect } from 'react-redux';
import { setSidebarVisibility as setSidebarVisibilityAction } from 'actions/aor/uiActions';
import memoizeOne from 'memoize-one';
import { MuiThemeProvider, withStyles, createStyles, WithStyles, Theme } from '@material-ui/core/styles';
import { push as pushAction } from 'connected-react-router';
import { withWidth } from '@material-ui/core';
import compose from 'recompose/compose';
import Sidebar from '../SsgSidebar';
import TaskDrawerWrapper from '../../bpm/components/TaskDrawer/TaskDrawer';
import { getProcessDefinitions as getProcessDefinitionsAction } from 'bpm/processDefinitions/actions';
import getReportDefinitionAction from '../../report/actions/getReportDefinitions';
import AdminRoutes from '../../routes/AdminRoutes';
import { DefaultViews } from '../../util/DefaultViews';
import ViewConfig from '../../reducers/ViewConfigType';
import loadViewConfigAction from '../../actions/loadViewConfig';
import getResourcesFromViewConfig from '../../util/getResourceListFromConfig';
import getV1InitialTheme from './getMuiV1Theme';
import { getPrimaryColor, getSecondaryColor, getErrorColor } from './getThemeColors';
import { RootState } from '../../reducers/rootReducer';
import { Width } from 'bpm/components/layout/withAdjustedWidth';
import { SnackbarProvider } from 'notistack';
import Notifier from 'notistack/components/Notifier';
import Menu from 'components/menu/index';
import DeferredSpinner from 'components/DeferredSpinner';
import { crudGetOne as crudGetOneAction } from 'sideEffect/crud/getOne/actions';
import PrivateGlobalAlerts from 'global-alerts/private/components/PrivateGlobalAlerts';
import ImpersonateBanner from 'impersonate/components/ImpersonateBanner';
import ConnectedIdleTimer from 'idle-timer/containers/ConnectedIdleTimer';
import CheckLastRequestRunner from 'reauth-interval-and-retries/check-last-request/components/CheckLastRequestRunner';
import OfflineAlert from 'reauth-interval-and-retries/check-last-request/components/OfflineAlert';
import DetectConnectivity from 'connectivity/components/DetectConnectivity';
import BannerSpacer from 'connectivity/components/BannerSpacer';
import DebugActionDispatcher from 'debug-action-dispatcher';
import AppFooter from 'footer';
import { getFooterTextSelector } from 'util/applicationConfig';
import { storageController } from 'storage';

const snackbarProviderStyles = (theme: Theme) =>
    createStyles({
        success: { backgroundColor: theme.palette.primary.main },
    });
const SnackbarProviderComponent: React.SFC<WithStyles<typeof snackbarProviderStyles>> = props => (
    <SnackbarProvider
        classes={{
            variantSuccess: props.classes.success,
        }}
        preventDuplicate={true}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
    >
        {props.children as any}
    </SnackbarProvider>
);
export const StyledSnackbarProvider = withStyles(snackbarProviderStyles)(SnackbarProviderComponent);

interface LayoutProps {
    authClient?: Function;
    customRoutes?: ReactElement<{}>[];
    dashboard?: Function | String;
    isLoading?: boolean;
    setSidebarVisibility: Function;
    themeV1: any; // tslint:disable-line
    themeV0: {
        body: {};
        bodySmall: {};
    };
    redirect: (loc: string) => void;
    width: Width;
    viewConfig: ViewConfig;
    viewConfigIsLoading: boolean;
    getAvailableProcesses: Function;
    getReportDefinition: Function;
    loadViewConfig: Function;
    withDrawerWrapper: boolean;
    appColor?: string;
    thm0: boolean;
    isSecureEnvironment?: boolean;
    crudGetOne: typeof crudGetOneAction;
    footerText: string | null;
}

class Layout extends Component<LayoutProps> {
    _getV1InitialTheme = memoizeOne(getV1InitialTheme);
    _getGeneratedResources = memoizeOne((viewConfig: ViewConfig) => {
        if (!viewConfig || !viewConfig.entities) {
            return [];
        }
        return getResourcesFromViewConfig(viewConfig);
    });
    componentDidMount() {
        // if we have no anonymous view-config + aren't logged in in any way, and aren't on the login/logout-redirect page already (we are not)
        if (
            process.env.NODE_ENV !== 'test' &&
            !(window as any).CASETIVITY_ANON_VIEW_CONFIG &&
            !storageController.getToken()
        ) {
            const path = `/logout-redirect?redirectTo=${encodeURIComponent(
                window.location.pathname + window.location.search,
            )}`;
            setTimeout(() => this.props.redirect(path), 10);
        } else {
            if ((global as any).REFERRED_AUTH) {
                // tslint:disable-line
                // auth token is present from auth referral, but viewConfig is not. Must load viewConfig
                this.props.loadViewConfig();
            }
            this.fetchOrganizationIfUserHasOne();

            this.props.setSidebarVisibility(false);
            // if (this.props.width !== 1 ) {
            //    this.props.setSidebarVisibility(true);
            // }

            // dispatch request to get process definitions for menus
            this.props.getAvailableProcesses();
            this.props.getReportDefinition();
        }
    }

    componentWillReceiveProps(newProps: LayoutProps) {
        if (newProps.width !== 'xs' && newProps.width !== 'sm' && this.props.width !== newProps.width) {
            this.props.setSidebarVisibility(false);
        }
        const newOrganizationId =
            newProps.viewConfig &&
            newProps.viewConfig.user &&
            newProps.viewConfig.user.properties &&
            newProps.viewConfig.user.properties.organizationId;
        if (newOrganizationId !== this.getOrganizationId()) {
            this.fetchOrganizationIfUserHasOne(newOrganizationId);
        }
    }
    fetchOrganizationIfUserHasOne = (overrideId?: string) => {
        const id = overrideId || this.getOrganizationId();
        if (
            id &&
            this.props.viewConfig &&
            this.props.viewConfig.entities &&
            this.props.viewConfig.entities.Organization
        ) {
            this.props.crudGetOne(
                {
                    resource: 'Organization',
                    id,
                    view: -1,
                },
                false,
            );
        }
    };
    getOrganizationId = () => {
        const { viewConfig } = this.props;
        return viewConfig && viewConfig.user && viewConfig.user.properties && viewConfig.user.properties.organizationId;
    };

    generatedResources() {
        return this._getGeneratedResources(this.props.viewConfig);
    }

    nonDefaultResourceViews(): ViewConfig['views'][0][] {
        const vc = this.props.viewConfig;
        if (!vc || !vc.entities) {
            return [];
        }
        return Object.values(vc.views).filter(v => !DefaultViews.isDefaultView(vc, v.name));
    }
    getThemeArgs = () => [
        (this.props.appColor as any) || 'blue', // tslint:disable-line
        'grey',
    ];
    createV1Theme = ({ appColor } = this.props) => {
        const theme = this._getV1InitialTheme(
            getPrimaryColor(appColor),
            getSecondaryColor(),
            getErrorColor(appColor),
            true, // DOM sideEffect: rebuild the stylesheet for React-Web-Tabs
        );
        // set the document background color
        document.body.style.background = theme.palette.background.default;
        return theme;
    };
    render() {
        const {
            // authClient,
            customRoutes = [],
            dashboard,
            width,
            withDrawerWrapper,
            isSecureEnvironment,
            viewConfig,
            viewConfigIsLoading,
            footerText,
        } = this.props;
        const adminRoutes = (
            <AdminRoutes
                customRoutes={customRoutes}
                resources={this.generatedResources() as never[]}
                nonDefaultResourceViews={this.nonDefaultResourceViews()}
                /* authClient={authClient} this isn't used even in aor (as far as I can tell) */
                dashboard={dashboard}
            />
        );
        const isSmall = width === 'xs';
        const theme = this.createV1Theme();
        if ((!viewConfig || Object.keys(viewConfig).length === 0) && viewConfigIsLoading) {
            return <DeferredSpinner />;
        }
        const anonUser = viewConfig.user && viewConfig.user.login === 'anonymousUser';
        return (
            <MuiThemeProvider theme={theme}>
                {!anonUser && <CheckLastRequestRunner />}
                {!anonUser && <OfflineAlert />}
                <ConnectedIdleTimer>
                    <div>
                        <ImpersonateBanner />
                        <DetectConnectivity />
                        <BannerSpacer />
                        <PrivateGlobalAlerts />
                        <div style={{ paddingBottom: footerText ? '35px' : '0px' }}>
                            {!isSmall && (
                                <span>
                                    <div
                                        style={{
                                            /* position: 'fixed', */ position: 'relative',
                                            width: '100%',
                                            zIndex: 100,
                                        }}
                                    >
                                        <Menu />
                                    </div>
                                </span>
                            )}
                            {/* uncomment below if using position:fixed toolbar */}
                            {/* width !== 1 && <div style={{ height: muiTheme.toolbar.height }} /> */}
                            <div
                                id="maincontent"
                                tabIndex={0}
                                className="body"
                                role="main"
                                style={{
                                    backgroundColor: theme.palette.background.default,
                                }}
                            >
                                <StyledSnackbarProvider>
                                    <React.Fragment>
                                        {!isSecureEnvironment && <Notifier />}
                                        <div>
                                            {withDrawerWrapper ? (
                                                <TaskDrawerWrapper>{adminRoutes}</TaskDrawerWrapper>
                                            ) : (
                                                adminRoutes
                                            )}
                                        </div>
                                        {isSmall ? (
                                            <Sidebar>
                                                <Menu />
                                            </Sidebar>
                                        ) : null}
                                    </React.Fragment>
                                </StyledSnackbarProvider>
                            </div>
                            {/* <Notification /> */}
                        </div>
                        <AppFooter />
                        <DebugActionDispatcher />
                    </div>
                </ConnectedIdleTimer>
            </MuiThemeProvider>
        );
    }
}

function mapStateToProps(state: RootState) {
    return {
        viewConfig: state.viewConfig,
        viewConfigIsLoading: state.viewConfigIsLoading,
        withDrawerWrapper:
            state.viewConfig &&
            state.viewConfig.application &&
            state.viewConfig.application.name !== 'rvrs' &&
            state.viewConfig.user.login !== 'anonymousUser',
        appColor: state.basicInfo && state.basicInfo.applicationColor,
        isSecureEnvironment: state.basicInfo ? !state.basicInfo.debugFeaturesEnabled : true,
        footerText: getFooterTextSelector(state),
    };
}

const enhance = compose(
    connect(
        mapStateToProps,
        {
            setSidebarVisibility: setSidebarVisibilityAction,
            getAvailableProcesses: getProcessDefinitionsAction,
            getReportDefinition: getReportDefinitionAction,
            loadViewConfig: loadViewConfigAction,
            redirect: pushAction,
            crudGetOne: crudGetOneAction,
        },
    ),
    withWidth({
        initialWidth: 'md',
    }),
);

export default enhance(Layout);
