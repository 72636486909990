import * as React from 'react';
import { getView } from '../../utils/viewConfigUtils';
import FieldMatrixProps from '../interfaces/FieldMatrix';
import * as widgetTypes from '../../utils/widgetTypes';
import { FieldViewField } from '../../../../reducers/ViewConfigType';
import { LiveFieldConfig } from '../../../../fieldFactory/FieldFactoryProvider';

import {
    isSelect,
    getIsValueSetSelect,
    getIsEntityJoinMultiSelect,
} from '../../utils/viewConfigUtils/getFieldProperties/viewFields';
import { hiddenLabelStyle } from './injectDisplayFieldsForRecord';

const injectInputFieldsForMerge = (
    props: FieldMatrixProps & {
        generateInputFields: (config: LiveFieldConfig) => (fieldDefs: {}[]) => React.ReactElement<{}>[];
    },
) => {
    const liveConfig = {
        record: props.record,
        resource: props.resource,
        basePath: props.basePath,
        match: props.match,
        commitChanges: false,
    };
    const generate = props.generateInputFields(liveConfig);
    const view = getView(props.viewConfig, props.viewName || `${props.resource}Merge`);
    const viewFields = ([] as FieldViewField[])
        .concat(
            Object.values(view.fields).filter(f => f.widgetType !== 'EXPRESSION') as FieldViewField[],
            ...(Object.values(view.tabs || {}).map(t =>
                Object.values(t.fields).filter(f => f.widgetType !== 'EXPRESSION'),
            ) as FieldViewField[][]),
        )
        .filter(f =>
            props.onlyFields
                ? props.onlyFields.indexOf(f.field) !== -1 ||
                  props.onlyFields.indexOf(`${f.field}Id`) !== -1 ||
                  props.onlyFields.indexOf(`${f.field}Ids`) !== -1
                : true,
        );
    console.log('viewFields::', viewFields); // tslint:disable-line

    const processRefManysFields = (field: FieldViewField) => {
        return props.record1 && props.record2 && props.includeRefManys
            ? props.generateInputFields({ ...liveConfig, specificIds: [props.record1.id, props.record2.id] })(
                  [field].map(f => ({ ...f, widgetType: widgetTypes.FORCE_IDS_LIST })),
              )
            : [];
    };

    const processSelectFields = (field: FieldViewField) => {
        return (
            props.record1 &&
            props.record2 &&
            props.generateInputFields({
                ...liveConfig,
                specificIds: [props.record1[`${field.field}Id`], props.record2[`${field.field}Id`]],
            })([field].map(f => ({ ...f, widgetType: widgetTypes.FORCE_ONEOF })))
        );
    };

    const generatedInputFields = (viewFields.flatMap(field => {
        if (getIsEntityJoinMultiSelect(props.viewConfig)(field)) {
            return processRefManysFields(field);
        } else if (isSelect(field) && !getIsValueSetSelect(props.viewConfig)(field)) {
            return processSelectFields(field);
        } else {
            return generate([field]);
        }
    }) as any) as React.ReactElement<{ label: string; source: string }>[]; // tslint:disable-line
    return {
        mergeFields: props.record &&
            props.record1 &&
            props.record2 &&
            props.viewConfig && [
                ...generatedInputFields.map((f, i) => {
                    const id = `merge-input-row-${i}`;
                    return (
                        <span key={i} id={id}>
                            <label htmlFor={id}>
                                <span style={hiddenLabelStyle}>Merged</span>
                            </label>
                            {f}
                        </span>
                    );
                }),
            ],
    };
};

export default injectInputFieldsForMerge;
