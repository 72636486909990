import { evaluateExpression } from '../../expressions/evaluate';
import moment from 'moment';
import { getTodayISO } from 'bpm2/util';

// Should replace w/ one of Misha's runtime methods
export const parseRowFlagConfig = (rowFlagConfigString: string) => {
    return JSON.parse(rowFlagConfigString);
};

export const evaluateRowFlagCondition = (rowFlagExpression: string, cellValue) => {
    return evaluateExpression(rowFlagExpression, { cellValue: cellValue });
};

export const getRowsToFlag = (data, columns, definition): number[] => {
    if (!definition.rowFlagConfig) {
        return [];
    }
    const { columnNameToFlag, rowFlagExpression } = parseRowFlagConfig(definition.rowFlagConfig);

    return data.flatMap((r, index) => {
        const columnsToFlag = columns.filter(
            c => c === columnNameToFlag && evaluateRowFlagCondition(rowFlagExpression, r[c]),
        );
        if (columnsToFlag.length > 0) {
            return [index];
        }
        return [];
    });
};

const getEntityUrlFromDefinition = (cellValue, reportWidgetDefinition) => {
    if (reportWidgetDefinition.urlSuffix) {
        return `/${reportWidgetDefinition.entity}/${cellValue}/${reportWidgetDefinition.urlSuffix}`;
    } else {
        return `/${reportWidgetDefinition.entity}/${cellValue}/show`;
    }
};

const getFlowableUrlFromDefinition = (fullRow, reportWidgetDefinition) => {
    // task format: processes/processid/tasks/taskid/start
    if (reportWidgetDefinition.isTask) {
        return `/processes/${fullRow.processID}/tasks/${fullRow.taskID}/start`;
    } else {
        // process format: processes/processID
        return `/processes/${fullRow.processID}/`;
    }
};

const getDrillDownFromData = (fullRow, definition) => {
    if (definition.drillDownColumn && definition.drillDownFilterType && definition.drillDownDestination) {
        let allFilters = {
            [definition.drillDownFilterType]: fullRow[definition.drillDownColumn],
            ...definition.drillDownFilters,
        };
        Object.entries(allFilters).forEach(([key, value]) => {
            if (value === '<todayTimestamp>') {
                allFilters[key] = getTodayISO();
            }
            if (value === '<currentTimestamp>') {
                allFilters[key] = moment(Date.now()).toISOString();
            }
        });
        const filterJSON = JSON.stringify(allFilters);
        const urlEncodedValue = encodeURIComponent(filterJSON);
        return `${definition.drillDownDestination}?filter=${urlEncodedValue}`;
    }
    return '/';
};

export const getDrillDown = (row, definition, data) => {
    if (definition.isFromFlowable) {
        const url = getFlowableUrlFromDefinition(data[row], definition);
        return url;
    } else if (definition.hasCustomDrilldown) {
        // This should probably be removed
        if (definition.urlList[row]) {
            const url = definition.urlList[row];
            return url;
        }
    } else if (definition.drillDownFromData) {
        const url = getDrillDownFromData(data[row], definition);
        return url;
    } else {
        const url = getEntityUrlFromDefinition(data[row].ID, definition);
        return url;
    }
};
