import React from 'react';
import { ReactNode } from 'react';
import { ListItem, MenuItem, IconButton } from '@material-ui/core';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { ListItemText, List, Collapse } from '@material-ui/core';
import { translateRoute } from '../../../generics/utils/viewConfigUtils';
import { renderMenuItemsArg } from '../DropdownInterface';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    button: {
        width: 24,
        height: 24,
        padding: 0,
    },
});

class ExpandableListComponent extends React.Component<
    {
        classes: {
            button: string;
        };
        route?: string | null;
        id: string;
        label: ReactNode;
        onClick: (route: string) => void;
    },
    { readonly open: boolean }
> {
    state = {
        open: false,
    };
    onToggleNestedList = (e: React.MouseEvent<HTMLElement> | null = null) => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
        this.setState({ open: !this.state.open });
    };

    getLabel = () => {
        const entitiesLabel = (
            <span
                style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                }}
            >
                {this.props.label}
            </span>
        );
        return entitiesLabel;
    };
    listItemClick = () => {
        const { route, onClick } = this.props;
        if (route) {
            onClick(route);
        } else {
            this.onToggleNestedList();
        }
    };

    render() {
        const icon = this.state.open ? <ExpandLess /> : <ExpandMore />;
        return (
            <div>
                <ListItem id={this.props.id} button={true} onClick={this.listItemClick}>
                    <ListItemText inset={true} primary={this.getLabel()} />
                    {this.props.route ? (
                        <IconButton onClick={this.onToggleNestedList} className={this.props.classes.button}>
                            {icon}
                        </IconButton>
                    ) : (
                        icon
                    )}
                </ListItem>
                <Collapse in={this.state.open} timeout="auto" unmountOnExit={true}>
                    <List component="menu">{this.props.children}</List>
                </Collapse>
            </div>
        );
    }
}

const ExpandableList = withStyles(styles)(ExpandableListComponent);

export default ({ mobile, navChildren, onClick, style, viewConfig }: renderMenuItemsArg) =>
    navChildren &&
    navChildren.map(subMenuItem => {
        const subItems =
            subMenuItem.children &&
            subMenuItem.children.map(nestedMenuItem => ({
                id: nestedMenuItem.name,
                caption: nestedMenuItem.label,
                onClick: () => {
                    const route = translateRoute(viewConfig, nestedMenuItem.route, nestedMenuItem.view);
                    if (route) {
                        onClick(route);
                    }
                },
            }));

        const translatedRoute = translateRoute(viewConfig, subMenuItem.route, subMenuItem.view);
        const label = subItems
            ? [subMenuItem.label, <KeyboardArrowRight key="arrRight" color="inherit" />]
            : subMenuItem.label;

        const menuItemProps = {
            className: `${mobile ? 'mobile-' : ''}dropdownMenuItem`,
            key: `menu-item-nav-${subMenuItem.name}`,
            id: subMenuItem.name,
            style,
            onClick: event => {
                if (translatedRoute) {
                    event.preventDefault();
                    onClick(translatedRoute);
                } else {
                    // we used to keep track of selected subMenus to close others.
                    // const selectedLabel = event.nativeEvent.target.textContent;
                    // setSelectedSubmenu && setSelectedSubmenu(selectedLabel);
                }
            },
            ...(mobile
                ? {
                      primaryTogglesNestedList: true,
                      nestedItems: subItems,
                  }
                : {
                      menuItems: subItems,
                  }),
        };
        if (mobile) {
            return subItems && subItems.length > 0 ? (
                <ExpandableList
                    id={subMenuItem.name}
                    label={subMenuItem.label}
                    route={subMenuItem.route}
                    onClick={onClick}
                >
                    {subItems.map(({ id, caption, onClick: subItemClick }, i) => (
                        <ListItem id={id} onClick={subItemClick} key={i}>
                            {caption}
                        </ListItem>
                    ))}
                </ExpandableList>
            ) : (
                <ListItem {...menuItemProps}>{label}</ListItem>
            );
        }
        return !subItems ? (
            <MenuItem {...menuItemProps}>{label}</MenuItem>
        ) : (
            <div>
                {() => {
                    console.error('submenu renderer called for toolbar menu');
                    return '--';
                }}{' '}
                {/* tslint:disable-line */}
            </div>
        );
    });
