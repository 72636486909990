import * as t from 'io-ts';

const _entityViewConfig = t.exact(
    t.partial({
        entityActions: t.array(
            t.union([
                t.intersection([
                    t.partial({
                        displayRule: t.string,
                        redirectOnSuccess: t.string,
                    }),
                    t.type({
                        label: t.string,
                        key: t.string,
                        action: t.type({
                            type: t.string,
                            payload: t.any,
                        }),
                    }),
                ]),
                t.intersection([
                    t.partial({
                        displayRule: t.string,
                    }),
                    t.type({
                        label: t.string,
                        key: t.string,
                        url: t.string,
                    }),
                ]),
            ]),
        ),
        visibleField: t.array(t.object),
        editableField: t.array(t.object),
        hideDeleteButton: t.string,
        hideEditButton: t.string,
        conceptIdsForFields: t.object,
        hideCreateButton: t.union([t.string, t.boolean]),
    }),
);

export interface EntityViewConfig extends t.TypeOf<typeof _entityViewConfig> {}
export const entityViewConfig = t.clean<EntityViewConfig, EntityViewConfig>(_entityViewConfig);
