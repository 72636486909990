import { createSelector } from 'reselect';
import { FormFieldUnion, SimpleFormField } from '../../../../fieldFactory/translation/fromFlowable/types/index';
import createDeepEqlSelector from 'components/generics/form/EntityFormContext/util/createDeepEqlSelector';
import { fromNullable, tryCatch } from 'fp-ts/lib/Option';
import { RootState, TaskForm } from 'reducers/rootReducer';

interface GetInitialValuesProps {
    taskId: string;
    relatedEntityResource?: string;
    relatedEntityId?: string;
}
export const isEntityLinked = field => !!(field.params && field.params.entityField);

/* Building initialValues selector */
export const getFormDefinition = (state: RootState, props: GetInitialValuesProps) => state.taskForms[props.taskId];
const getRelatedEntityRecord = (state: RootState, props: GetInitialValuesProps) =>
    props.relatedEntityResource &&
    props.relatedEntityId &&
    (state.admin.entities[props.relatedEntityResource] || {})[props.relatedEntityId];

export const taskClosed = (state: RootState, props: GetInitialValuesProps) =>
    Boolean(props.taskId) &&
    fromNullable(state.admin.entities)
        .mapNullable(e => e['TaskInstance'])
        .mapNullable(ti => ti[props.taskId])
        .mapNullable((tf: any) => tf.endTime)
        .fold(false, Boolean);

export const parseInitialValue = (field: FormFieldUnion) => {
    if (field.type === 'dropdown' || field.type === 'radio-buttons') {
        if (field.value) {
            return (
                (field.options || []).find(({ name }) =>
                    typeof field.value === 'string'
                        ? field.value.includes(`name=${name}}`) || field.value === name
                        : field.value.name === name,
                ) || null
            );
        }
        return field.hasEmptyValue && field.options && field.options[0] ? field.options[0] : field.value || null;
    } else if (field.type === 'integer') {
        if (field.value || field.value === 0) {
            return parseInt(field.value, 10);
        } else {
            return null;
        }
    } else if (field.type === 'decimal') {
        if (field.value || field.value === 0) {
            return parseFloat(field.value);
        } else {
            return null;
        }
    } else if (
        field.type === 'value-set-multi-select' ||
        field.type === 'value-set-multi-checkbox' ||
        field.type === 'entity-multi-select-chip' ||
        field.type === 'multiple-entity-typeahead' ||
        field.type === 'list-view'
    ) {
        if (field.value) {
            // initial value given
            if (typeof field.value === 'string' && field.value.startsWith('[')) {
                return JSON.parse(field.value);
            } else if (Array.isArray(field.value)) {
                return field.value;
            }
        }
    } else if (field.type === 'boolean') {
        return field.value || false;
    } else if (field.type === 'table') {
        return field.value || [];
    }
    return field.value;
};

export const pickBetween = (taskField: FormFieldUnion, taskClosed: boolean) => (
    entityFieldValue: any,
    taskFieldValue: any,
) => {
    const lfb = fromNullable(taskField.params)
        .mapNullable(p => p.configs)
        .mapNullable(c => c.fieldConfig)
        .chain(fc => tryCatch(() => JSON.parse(fc)))
        .mapNullable(c => c.linkedFieldBehavior);
    if (taskClosed) {
        return lfb
            .mapNullable(lfb => lfb.whenClosed)
            .fold(taskFieldValue, wd => (wd === 'unlink-from-entity' ? taskFieldValue : entityFieldValue));
    }
    return lfb
        .mapNullable(lfb => lfb.whenEditable)
        .fold(
            entityFieldValue, // default use entity field.
            we =>
                we === 'initialize-from-entity'
                    ? entityFieldValue
                    : we === 'initialize-from-taskform-unless-empty' && (!taskFieldValue && taskFieldValue !== 0)
                    ? entityFieldValue
                    : taskFieldValue,
        );
};

const getInitialValuesCombiner = (formDefinition: TaskForm, relatedEntityRecord?: {}, taskIsClosed?: boolean) => {
    return (
        formDefinition &&
        formDefinition.fields &&
        formDefinition.fields.reduce(
            (formObj, field) => ({
                ...formObj,
                [field.id]: isEntityLinked(field)
                    ? pickBetween(field, taskIsClosed)(
                          relatedEntityRecord && relatedEntityRecord[(field as SimpleFormField).params.entityField],
                          parseInitialValue(field),
                      )
                    : parseInitialValue(field),
            }),
            {},
        )
    );
};
export const createGetInitialValues = () => {
    return createDeepEqlSelector(
        createSelector(
            getFormDefinition,
            getRelatedEntityRecord,
            taskClosed,
            getInitialValuesCombiner,
        ),
    );
};

export const createGetStartFormInitialValues = () => {
    return createDeepEqlSelector(
        createSelector(
            (state, props: { formDefinition: TaskForm }) => props.formDefinition,
            getInitialValuesCombiner,
        ),
    );
};
