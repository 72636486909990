import { put, takeEvery } from 'redux-saga/effects';
import { LOAD_VIEW_CONFIG_SUCCESS } from 'viewConfig/constants';
import ViewConfig from '../reducers/ViewConfigType';
import { fromNullable, fromEither } from 'fp-ts/lib/Option';
import { mapOption } from 'fp-ts/lib/Array';
import { ViewEditableExps } from '../reducers/entityEditabilityReducer';
import makeGetExpression from './util/makeGetExpression';
import { getViewConfiguration } from '../components/generics/utils/viewConfigUtils';
import { tryCatch } from 'fp-ts/lib/Either';
import storage from 'local-storage-fallback';

export default function*() {
    yield takeEvery(LOAD_VIEW_CONFIG_SUCCESS, function*({
        payload: { viewConfig },
    }: {
        type: typeof LOAD_VIEW_CONFIG_SUCCESS;
        payload: { viewConfig: ViewConfig };
    }) {
        const validConfigs = mapOption(Object.entries(viewConfig.views), ([viewName, view]) =>
            makeGetExpression('editableField')(
                viewName,
                view.entity,
                viewConfig,
                fromEither(
                    // this should take viewName
                    tryCatch(() => getViewConfiguration(viewConfig, viewName)).mapLeft(e => console.log(e)), // tslint:disable-line
                ).chain(fromNullable),
            ),
        );
        const editableExps: ViewEditableExps = Object.assign({}, ...validConfigs);
        storage.setItem('entityEditability', JSON.stringify(editableExps));
        yield put({ type: 'ENTITY_EDITIBILITY_GENERATED', payload: editableExps });
    });
}
