/* eslint no-fallthrough: 0 */
import { ActionType, getType } from 'typesafe-actions';
import { RootAction } from 'actions/rootAction';
import * as listActions from 'sideEffect/crud/getList/actions';
import { getFilterString } from './';
import getResource from './list/getResource';

export type ListAction = ActionType<typeof listActions>;

export interface ListsLoading {
    [filter: string]: boolean;
}

export default (resource: string) => (previousState: ListsLoading = {}, action: RootAction): ListsLoading => {
    switch (action.type) {
        case getType(listActions.crudGetListSuccess):
        case getType(listActions.crudGetListFailure):
        case getType(listActions.crudGetListCancel): {
            const actionResource = getResource(action);
            if (actionResource === resource) {
                const filterString = getFilterString(action);
                return {
                    ...previousState,
                    [filterString]: false,
                };
            }
        }
        case getType(listActions.crudGetList): {
            const actionResource = getResource(action);
            if (actionResource === resource) {
                const filterString = getFilterString(action);
                return {
                    ...previousState,
                    [filterString]: true,
                };
            }
        }
        default:
            return previousState;
    }
};
