/* eslint no-loop-func: 0 */
import { entityPreprocessValuesForEval } from 'expressions/formValidation';
import memoizeOne from 'memoize-one';
import deepEql from 'deep-eql';
import get from 'lodash/get';
import { SpelExpressionEvaluator } from 'spel2js';
import deepExtend from 'util/cyclicDeepExtend';
import { SpelOptions, createRootContext } from 'expressions/evaluate';
import {
    getCalculateValuesBasedOnAvailableFields,
    ValuesetFieldAvailableConcepts,
    AvailableOptions,
} from 'expressions/expressionArrays/formValuesInDynamicContext/util/calcValuesBasedOnAvailableFields';
import ViewConfig from 'reducers/ViewConfigType';
import stableStringify from 'fast-json-stable-stringify';
import { isRefOneField } from 'components/generics/utils/viewConfigUtils';
import updateDataOnReferenceChange from 'components/generics/form/EntityFormContext/util/updatedDataOnSubRefChange';
import flatten, { unflatten } from 'flat';
import uniq from 'lodash/uniq';
import { tryCatch } from 'fp-ts/lib/Either';
import { some, none } from 'fp-ts/lib/Option';
import { identity } from 'fp-ts/lib/function';
import produce from 'immer';
import { getFilterFromFilterString } from 'fieldFactory/input/components/ListSelect';
import filterEntityByQueryRepresentation from 'isomorphic-query-filters/filterEntityByQueryRepresentation';
import { applyToFilterString } from 'fieldFactory/popovers/PopoverRefInput/evaluteFilterString';
import cleaner from 'deep-cleaner';
import getDirtyPaths from 'expressions/expressionArrays/formValuesInDynamicContext/util/getDirtyPaths';
import isEmpty from 'lodash/isEmpty';
import clone from 'clone';
import fromEntries from 'util/fromentries';
import KeyCachingEvaluator from './Evaluator';
import { ValueSets } from 'reducers/valueSetsReducer';
import { getSubExpressionsOfFilterTemplate } from 'viewConfigCalculations/filterExpressions/epic';
import { entityAndConceptLookupUtils } from 'expressions/expressionArrays';
import { SpelCompiledExpression } from 'casetivity-shared-js/lib/spel/evaluate';

const cleanIfObjOrArray = v => {
    if (v && typeof v === 'object') {
        // clone until https://github.com/darksinge/deep-cleaner/issues/13 closed
        return cleaner(clone(v));
    }
    return v;
};

const keysToClearValuesetCachedResults = [
    'containsCodes',
    'lookupConceptIdsFromValuesetGroup',
    'valuesetIsLoaded',
    'getConceptFromCode',
    'getConceptFromDisplay',
    'getConceptIdFromCode',
    'getConceptIdFromDisplay',
    'getConceptCodeFromDisplay',
    'isValidConcept',
    'isValidConceptFromCode',
    'isValidConceptFromDisplay',
    'getConceptIdsFromCodes',
];

const isNonEditablePathToConsiderClean = (
    path: string,
    tableRowFieldContext: ReturnType<typeof evaluateContext2>['tableRowContexts'][0] | undefined,
) => {
    if (!tableRowFieldContext) {
        return false;
    }
    const [indexStr, ...rest] = path.split('.');
    const index = parseInt(indexStr, 10);
    if (isNaN(index)) {
        return false;
    }
    const rowContext = tableRowFieldContext[index];
    if (!rowContext) {
        // row was deleted
        return false;
    }
    const isBaseCase = rest.length === 1 || !rest.find(sp => !isNaN(parseInt(sp, 10)));
    if (isBaseCase) {
        // there may be undocumented values in tableRows that don't match the schema.
        // lets ignore these for dirtyness. (can't simply check hidden/disabled field list)
        return !rowContext.visibleAndEditableFields.includes(rest.join('.'));
    }
    const [nextField, ...rest2] = rest;
    // recurse to all nested tables.
    return isNonEditablePathToConsiderClean(rest2.join('.'), rowContext.tableRowContexts[nextField]);
};

export interface AvailableOptionsExpressions {
    [source: string]: {
        emptyValue:
            | {
                  name: string;
                  value?: any;
              }
            | string
            | null;
        optionVisibilities: {
            // e.g. { "name": "Foo" } or { "name": "Foo", value: "foo" }
            [stableStringifiedOptionObject: string]: 'ALWAYS_VISIBLE' | string;
        };
    };
}
export interface EvaluationFactors {
    fieldWidgets: {
        [field: string]: string[]; // key: field. value: array of widget Ids associated with that field.
        // There may be different field-instances with different visibilities mapping to the same field.
        // Only when they all (disable or hide), we set the field to its initial value.
    };
    valueset1Fields?: {
        [source: string /* 'Id' removed */]: string;
    };
    valueset1AvailableConceptsExpressions?: {
        // values are spel expressions resulting in a list of available concepts to that field
        [source: string /* 'Id' removed */]: string;
    };
    dropdownAvailableOptionsExpressions?: AvailableOptionsExpressions;
    visibilityExpressions?: {
        [formField: string]: string[];
    };
    editabilityExpressions?: {
        [formField: string]: string[];
    };
    reference1EntityFilterExpressions?: {
        [source: string /* Includes 'Id' if present. */]: {
            entityType: string;
            expression: string;
        };
    };
    referenceManyEntityFilterExpressions?: {
        [source: string /* Includes 'Ids' if present. */]: {
            entityType: string;
            expression: string;
        };
    };
    tableExpressions?: {
        // table data is ephemeral - if something is 'disabled' for 'hidden' - we make it null (instead of initial value)
        [fieldId: string]: EvaluationFactors;
    };
}
interface EvalOptions {
    bypassInitialConsitencyForFilters?: boolean;
}
interface ConstructorArgs {
    evaluationFactors: EvaluationFactors;
    basedOnEntityOptions: {
        basedOnEntity: string;
        fieldsUsedInExpressions: string[];
    } | null;
    viewConfig: ViewConfig;
    useBackingValuesRegardlessOfDisplayStatus: {
        [field: string]: true;
    };
    options: SpelOptions;
    evalOptions?: EvalOptions;
}
const getExpressionsForCachingEvaluator = (
    args: Pick<
        EvaluationFactors,
        | 'valueset1AvailableConceptsExpressions'
        | 'dropdownAvailableOptionsExpressions'
        | 'visibilityExpressions'
        | 'editabilityExpressions'
        | 'reference1EntityFilterExpressions'
        | 'referenceManyEntityFilterExpressions'
    >,
) => {
    return {
        visibility: args.visibilityExpressions,
        editability: args.editabilityExpressions,
        availableConcepts:
            args.valueset1AvailableConceptsExpressions &&
            fromEntries(
                Object.entries(args.valueset1AvailableConceptsExpressions).map(
                    ([k, v]) => [k, [v]] as [string, string[]],
                ),
            ),
        availableOptions:
            args.dropdownAvailableOptionsExpressions &&
            fromEntries(
                Object.entries(args.dropdownAvailableOptionsExpressions).map(([k, v]) => [
                    k,
                    fromEntries(Object.entries(v.optionVisibilities).map(([k, v]) => [k, [v]] as [string, string[]])),
                ]),
            ),
        ref1filterExpressions:
            args.reference1EntityFilterExpressions &&
            fromEntries(
                Object.entries(args.reference1EntityFilterExpressions).map(([k, v]) => {
                    return [k, getSubExpressionsOfFilterTemplate(v.expression)];
                }),
            ),
        refmanyFilterExpressions:
            args.referenceManyEntityFilterExpressions &&
            fromEntries(
                Object.entries(args.referenceManyEntityFilterExpressions).map(([k, v]) => {
                    return [k, getSubExpressionsOfFilterTemplate(v.expression)];
                }),
            ),
    };
};
export class FormContextEvaluator {
    expressionsEvaluator: KeyCachingEvaluator<ReturnType<typeof getExpressionsForCachingEvaluator>>;
    initialFiltersHandler:
        | {
              type: 'dontHandle';
          }
        | {
              type: 'allowInitialEvenIfInvalid';
              evaluator: KeyCachingEvaluator<
                  ReturnType<typeof getExpressionsForCachingEvaluator>['ref1filterExpressions']
              >;
          };
    constructorArgs: ConstructorArgs;
    fields: string[];
    refOneFields: string[];
    cachedEntities: { Concept?: {} } | null = null;
    cachedValuesets: ValueSets | null = null;
    constructor(args: ConstructorArgs) {
        this.constructorArgs = args;
        const { fieldWidgets = {} } = args.evaluationFactors;
        const { viewConfig, basedOnEntityOptions } = args;
        this.fields = Object.keys(fieldWidgets);
        this.refOneFields = basedOnEntityOptions
            ? this.fields.filter(f => {
                  return tryCatch(() =>
                      isRefOneField(
                          viewConfig,
                          basedOnEntityOptions.basedOnEntity,
                          f.endsWith('Id') ? f.slice(0, -2) : f,
                          'TRAVERSE_PATH',
                      ),
                  ).fold(e => {
                      return false;
                  }, identity);
              })
            : [];

        this.expressionsEvaluator = new KeyCachingEvaluator(
            getExpressionsForCachingEvaluator(args.evaluationFactors),
            this.evaluationFn,
            'deepeql',
        );

        this.initialFiltersHandler = this.byPassConsistencyForInitialFilters()
            ? {
                  type: 'allowInitialEvenIfInvalid',
                  evaluator: new KeyCachingEvaluator(
                      getExpressionsForCachingEvaluator(args.evaluationFactors).ref1filterExpressions || {},
                      this.evaluationFn,
                      'deepeql',
                  ),
              }
            : { type: 'dontHandle' };
    }
    byPassConsistencyForInitialFilters = () => {
        const { evalOptions: { bypassInitialConsitencyForFilters = true } = {} } = this.constructorArgs;
        return bypassInitialConsitencyForFilters;
    };
    evaluationFn = (expression: string) => {
        const compiledExpression: SpelCompiledExpression = SpelExpressionEvaluator.compile(expression);
        return (values: {}) => {
            const context = { ALWAYS_VISIBLE: true };
            const result = compiledExpression.eval({ ...context, ...createRootContext(values) }, {});
            return result;
        };
    };
    evaluate = (_values: {}, valueSets: ValueSets, initialValues: {}, entities: { Concept?: {} }) => {
        if (entities !== this.cachedEntities) {
            this.cachedEntities = entities;
            this.expressionsEvaluator.clearCaches(expression => {
                return expression.includes('lookupEntityData');
            }, this.evaluationFn);
        }

        if (valueSets !== this.cachedValuesets) {
            this.cachedValuesets = valueSets;
            this.expressionsEvaluator.clearCaches(expression => {
                // todo: also include valueset functions
                return (
                    expression.includes('Code') || keysToClearValuesetCachedResults.some(x => expression.includes(x))
                );
            }, this.evaluationFn);
        }

        const {
            viewConfig,
            options,
            useBackingValuesRegardlessOfDisplayStatus = {},
            basedOnEntityOptions,
            evaluationFactors: {
                fieldWidgets = {},
                valueset1AvailableConceptsExpressions = {},
                dropdownAvailableOptionsExpressions = {},
                valueset1Fields = {},
                reference1EntityFilterExpressions = {},
                referenceManyEntityFilterExpressions = {},
                tableExpressions = {},
            },
        } = this.constructorArgs;
        // cache things which we close on, so we can detect changes to them and clear caches of expressions that reference them.
        // e.g. expressions that include 'lookupEntityData' or '.+Code'

        let values = _values;
        let valuesWithInitialsAndConcptAvailbtyApplied = values;
        let hiddenFieldInstances: string[] = [];
        let disabledFieldInstances: string[] = [];
        let nullFilteredRefOneFields: string[] = [];
        let availableConcepts: ValuesetFieldAvailableConcepts = fromEntries(
            Object.entries(valueset1AvailableConceptsExpressions).map(([f, expression]) => [f, '*']),
        );
        let evaluatedRefManyFilters: {
            [field: string]: {
                entityType: string;
                filter: {};
            };
        } = {};
        let dropdownAndRadioAvailableOptions: AvailableOptions = fromEntries(
            Object.entries(dropdownAvailableOptionsExpressions).map(([f, conf]) => {
                const initOptions = fromEntries(Object.keys(conf.optionVisibilities).map(option => [option, true]));
                return [f, { empty: conf.emptyValue as any, options: initOptions }];
            }),
        );

        const getHiddenFieldDict = memoizeOne((hiddenFields: string[]) => {
            return fromEntries(hiddenFields.map(field => [field, true]));
        });
        const getDisabledFieldsFieldDict = memoizeOne((disabledFields: string[]) => {
            return fromEntries(disabledFields.map(field => [field, true]));
        });

        const getEntirelyNonEditableOrVisibleFields = (() => {
            const getNonEditableFields = memoizeOne(
                (_hiddenFieldInstances: string[], _disabledFieldInstances: string[]): string[] =>
                    Object.entries(fieldWidgets).reduce((prev, [field, fieldInstances]) => {
                        const nonEditable = fieldInstances.every(
                            widget =>
                                !useBackingValuesRegardlessOfDisplayStatus[widget] &&
                                (getHiddenFieldDict(_hiddenFieldInstances)[widget] ||
                                    getDisabledFieldsFieldDict(_disabledFieldInstances)[widget]),
                        );
                        if (nonEditable) {
                            prev.push(field);
                            return prev;
                        }
                        return prev;
                    }, []),
            );
            return (_hiddenFieldInstances = hiddenFieldInstances, _disabledFieldInstances = disabledFieldInstances) =>
                getNonEditableFields(_hiddenFieldInstances, _disabledFieldInstances);
        })();
        const getChangedReferenceFields = () =>
            this.refOneFields
                .filter(f => {
                    const initial = get(initialValues, f, null);
                    const current = get(valuesWithInitialsAndConcptAvailbtyApplied, f, null);
                    return (initial || current) && initial !== current;
                })
                .sort();
        let changedReferenceFields: string[] = getChangedReferenceFields();
        const calculateValuesBasedOnAvailableFields = memoizeOne((...changeOnThese: string[]) => {
            const adjustedValues = getCalculateValuesBasedOnAvailableFields(
                viewConfig,
                entities,
                initialValues,
                values,
                getEntirelyNonEditableOrVisibleFields(),
                availableConcepts,
                valueset1Fields,
                dropdownAndRadioAvailableOptions,
                nullFilteredRefOneFields,
                evaluatedRefManyFilters,
            );

            // insert references based on above with the data in expansions drawn from either
            // the reference, or the user input according to editability of the field.
            if (basedOnEntityOptions) {
                const av = flatten(adjustedValues);
                const avKeys = Object.keys(av);
                const u = updateDataOnReferenceChange(
                    viewConfig,
                    basedOnEntityOptions.basedOnEntity,
                    entities,
                    this.fields.map(f => [f, av[f]] as [string, unknown]),
                    uniq([...getEntirelyNonEditableOrVisibleFields(), ...basedOnEntityOptions.fieldsUsedInExpressions]),
                );
                u.forEach(([path, v]) => {
                    const kToDel = avKeys.find(k => k === path || (k.startsWith(path) && k[path.length] === '.'));
                    if (kToDel) {
                        delete av[kToDel];
                    }
                });
                const toRet = unflatten(Object.assign({}, av, fromEntries(u), flatten(fromEntries(u))));
                return toRet;
            }
            return adjustedValues;
        });
        // we only care about the presence/absence of fields, not whether its through editability or visibility
        const c2 = memoizeOne(
            (
                changedReferenceFieldsRepresentation: string,
                valuesetConceptsMapRepresentation: string,
                dropdownAndRadioAvailableOptionsRepresentation: string,
                filteredRefOneFieldsRepresentation: string,
                ...fieldsToOverrideWithInitialValues: string[]
            ) => {
                return calculateValuesBasedOnAvailableFields(
                    ...fieldsToOverrideWithInitialValues.sort(),
                    '|',
                    valuesetConceptsMapRepresentation,
                    '|',
                    dropdownAndRadioAvailableOptionsRepresentation,
                    '|',
                    filteredRefOneFieldsRepresentation,
                    '|',
                    changedReferenceFieldsRepresentation,
                );
            },
        );
        const preprocessValues = (values: {}) => ({
            ...entityPreprocessValuesForEval(
                values,
                basedOnEntityOptions
                    ? uniq([...this.fields, ...basedOnEntityOptions.fieldsUsedInExpressions])
                    : this.fields,
                valueset1Fields,
                entities,
                options,
                valueSets,
            ),
            ...entityAndConceptLookupUtils(entities, viewConfig, valueSets),
        });

        do {
            if (!values) {
                break;
            }
            valuesWithInitialsAndConcptAvailbtyApplied = c2(
                changedReferenceFields.join(','),
                stableStringify(availableConcepts),
                stableStringify(dropdownAndRadioAvailableOptions),
                stableStringify(nullFilteredRefOneFields),
                ...getEntirelyNonEditableOrVisibleFields(),
            );
            const expressionResults = this.expressionsEvaluator.evaluateAll(
                preprocessValues(valuesWithInitialsAndConcptAvailbtyApplied),
            );

            hiddenFieldInstances = Object.entries(expressionResults.visibility || {}).flatMap(([k, v]) =>
                !v.find(Boolean) ? [k] : [],
            );
            disabledFieldInstances = Object.entries(expressionResults.editability || {}).flatMap(([k, v]) =>
                !v.find(Boolean) ? [k] : [],
            );
            availableConcepts = fromEntries(
                Object.entries(expressionResults.availableConcepts || {}).map(([k, [r]]) => {
                    const result = (() => {
                        if (r === '*') {
                            return '*';
                        }
                        if (!Array.isArray(r) || r.some(e => typeof e !== 'string')) {
                            // log a warning.
                            return '*';
                        }
                        return fromEntries(r.map(e => [e, true] as [string, true]));
                    })();
                    if (
                        initialValues[`${k}Id`] &&
                        initialValues[`${k}Id`] === valuesWithInitialsAndConcptAvailbtyApplied[`${k}Id`] &&
                        result !== '*'
                    ) {
                        result[initialValues[`${k}Id`]] = true;
                    }
                    return [k, result] as [string, '*' | { [conceptId: string]: true }];
                }),
            );
            dropdownAndRadioAvailableOptions = fromEntries(
                Object.entries(expressionResults.availableOptions || {}).map(([field, results]) => {
                    const optionsEntry: AvailableOptions[0] = {
                        empty: dropdownAndRadioAvailableOptions[field].empty,
                        options: fromEntries(Object.entries(results).map(([k, [v]]) => [k, Boolean(v)])),
                    };
                    return [field, optionsEntry];
                }),
            );

            const initialFilters =
                this.initialFiltersHandler.type === 'allowInitialEvenIfInvalid'
                    ? some(this.initialFiltersHandler.evaluator.evaluateAll(preprocessValues(initialValues)))
                    : none;
            nullFilteredRefOneFields = Object.entries(expressionResults.ref1filterExpressions || {}).flatMap(
                ([field, results]) => {
                    const { expression, entityType } = reference1EntityFilterExpressions[field];
                    const evaluatedString = applyToFilterString(expression, results);
                    const filter = getFilterFromFilterString(evaluatedString);
                    const value = valuesWithInitialsAndConcptAvailbtyApplied[field];
                    // BYPASS CONSISTENCY FOR ANY STARTING DATA THAT FAILS FILTER
                    // IF this field's value is the initial value AND the initial value is invalid against the initial form state, then lets ignore.
                    if (value && value === initialValues[field] && initialFilters.isSome()) {
                        const initialFilter = getFilterFromFilterString(
                            applyToFilterString(expression, initialFilters.value[field]),
                        );
                        if (
                            !filterEntityByQueryRepresentation(viewConfig)(initialFilter)(
                                { id: value, entityType },
                                entities,
                            )
                        ) {
                            return []; // Our starting data wasn't consistent, so ONLY in this case, lets ignore that this particular initial value might not be valid.
                        }
                    } // END BYPASS CONSISTENCY
                    if (value && typeof value === 'string') {
                        const filteredOut = !filterEntityByQueryRepresentation(viewConfig)(filter)(
                            { id: value, entityType },
                            entities,
                        );
                        if (filteredOut) {
                            return [field]; // it had a value and was filtered out
                        }
                    } else if (nullFilteredRefOneFields.includes(field)) {
                        return [field]; // don't cause a change if it's hidden because it was filtered on the last pass.
                        // (otherwise this will cause an infinite loop where the field is added and removed to the list)
                    }
                    return [];
                },
            );
            evaluatedRefManyFilters = fromEntries(
                Object.entries(expressionResults.refmanyFilterExpressions || {}).map(([field, results]) => {
                    const { expression, entityType } = referenceManyEntityFilterExpressions[field];
                    const evaluatedString = applyToFilterString(expression, results);
                    const filter = getFilterFromFilterString(evaluatedString);
                    return [field, { entityType, filter }];
                }),
            );
        } while (
            valuesWithInitialsAndConcptAvailbtyApplied !==
            c2(
                uniq(changedReferenceFields)
                    .sort()
                    .join(','),
                stableStringify(availableConcepts),
                stableStringify(dropdownAndRadioAvailableOptions),
                stableStringify(nullFilteredRefOneFields),
                ...getEntirelyNonEditableOrVisibleFields(),
            )
        );
        const tableRowContexts: {
            [field: string]: ReturnType<typeof evaluateContext2>[];
        } = (() => {
            let _tableRowContexts = {};
            Object.entries(tableExpressions).forEach(([id, evaluationFactors]) => {
                const tableRows = (valuesWithInitialsAndConcptAvailbtyApplied[id] || []).map(rowData => {
                    // generally, the values fields in table-rows depend on for their filters shouldn't be changing unexpectedly
                    // causing data to be invalid, so it's ok to not allow initially inconsistent data to be kept inside a table.
                    // makes things easier.
                    const evaluatedRowContext = getEvaluateContext({ bypassInitialConsitencyForFilters: false })(
                        evaluationFactors,
                        null,
                        viewConfig,
                        valueSets,
                        {
                            ...Object.assign(
                                {},
                                ...Object.keys(evaluationFactors.fieldWidgets).map(f => {
                                    return {
                                        [id + '_c_' + f]: typeof rowData[f] === 'undefined' ? null : rowData[f],
                                    };
                                }),
                            ),
                            // by null-initializing values here (instead of adding fields to evaluationFactors.fieldWidgets),
                            // we restrict 'registeredFields' in the TableRowContext but still get null-initialized 'outer scope' values
                            ...entityPreprocessValuesForEval(
                                valuesWithInitialsAndConcptAvailbtyApplied,
                                this.fields,
                                valueset1Fields,
                                entities,
                                options,
                                valueSets,
                            ),
                            ...rowData,
                        },
                        entities,
                        options,
                        {},
                        {},
                        'allTrue',
                        'allTrue',
                    );
                    // only store row data.
                    return { ...evaluatedRowContext, fieldValues: evaluatedRowContext.registeredValues };
                });
                _tableRowContexts[id] = tableRows;
            });
            return _tableRowContexts;
        })();
        valuesWithInitialsAndConcptAvailbtyApplied = produce(
            valuesWithInitialsAndConcptAvailbtyApplied,
            draftValues => {
                Object.entries(tableRowContexts).forEach(([field, rows]) => {
                    draftValues[field] = rows.map(rowContext => rowContext.registeredValues);
                });
                return draftValues;
            },
        );

        const hiddenFieldsInstancesObj: { [f: string]: true } = fromEntries(hiddenFieldInstances.map(f => [f, true]));
        const disabledFieldInstancesObj: { [f: string]: true } = fromEntries(
            disabledFieldInstances.map(f => [f, true]),
        );

        const visibleAndEditableFieldInstances = ([] as string[])
            .concat(...Object.values(fieldWidgets))
            .flatMap(f => (hiddenFieldsInstancesObj[f] || disabledFieldInstancesObj[f] ? [] : [f]));

        const dirtyValues: {} = deepExtend(
            {},
            ...this.fields.map(f => {
                const v1OrNull = cleanIfObjOrArray(get(initialValues, f, null));
                const v2OrNull = cleanIfObjOrArray(get(valuesWithInitialsAndConcptAvailbtyApplied, f, null));
                // The only table values that should count as 'dirty' should be for visible, editable fields.
                if (tableRowContexts[f]) {
                    // we can't check tableRowContexts[f][i].isDirty because rows don't have determinate initialValues to compare against
                    // instead iterate over all dirty-value paths: if they are all non-editable or undocumented fields, then the value isn't dirty.
                    const isDirtyTableField = (() => {
                        if (isEmpty(v1OrNull) && isEmpty(v2OrNull)) {
                            return false;
                        }
                        if (isEmpty(v1OrNull) || isEmpty(v2OrNull)) {
                            return true;
                        }
                        const dirtyValuePaths = getDirtyPaths(v1OrNull, v2OrNull, true);
                        return dirtyValuePaths.find(path => {
                            return !isNonEditablePathToConsiderClean(path, tableRowContexts[f]);
                        });
                    })();
                    return isDirtyTableField ? { [f]: get(valuesWithInitialsAndConcptAvailbtyApplied, f) } : {};
                }
                return !deepEql(v1OrNull, v2OrNull)
                    ? unflatten({ [f]: get(valuesWithInitialsAndConcptAvailbtyApplied, f) })
                    : {};
            }),
        );
        const registeredValues: {} = unflatten(
            fromEntries(this.fields.map(f => [f, get(valuesWithInitialsAndConcptAvailbtyApplied, f)])),
        );
        return {
            availableOptions: dropdownAndRadioAvailableOptions,
            hiddenFields: hiddenFieldsInstancesObj,
            disabledFields: disabledFieldInstancesObj,
            visibleAndEditableFields: visibleAndEditableFieldInstances,
            fieldValues: valuesWithInitialsAndConcptAvailbtyApplied,
            nullFilteredRefOneFields,
            registeredValues,
            dirtyValues,
            isDirty: Object.keys(dirtyValues).length > 0,
            initialValues,
            valuesetFieldAvailableConceptIds: availableConcepts,
            tableRowContexts,
        };
    };
}

/*
 */
const getEvaluateContext = (evalOptions: EvalOptions) => (
    evaluationFactors: EvaluationFactors,
    basedOnEntityOptions: {
        basedOnEntity: string;
        fieldsUsedInExpressions: string[];
    } | null,
    // we use this to calculate values based on
    // reference changes if we are in 'entity' context
    viewConfig: ViewConfig,
    valueSets: ValueSets,
    formValues: {},
    entities: { Concept?: {} },
    options: SpelOptions,
    initialValues: {} = {},
    // escape hatch so the value being hidden doesn't force us to use the initial value for the field
    // added because gisIdentifier is hidden, but we want to submit that value when the value is changed
    // by the Address widget.
    useBackingValuesRegardlessOfDisplayStatus: {
        [field: string]: true;
    } = {},
    visibleWhen: any = 'allTrue',
    visibleWhenArray: any = 'allTrue',
) => {
    return new FormContextEvaluator({
        basedOnEntityOptions,
        evaluationFactors,
        options,
        useBackingValuesRegardlessOfDisplayStatus,
        viewConfig,
        evalOptions,
    }).evaluate(formValues, valueSets, initialValues, entities);
};

export const evaluateContext2 = getEvaluateContext({ bypassInitialConsitencyForFilters: true });
