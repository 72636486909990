import React from 'react';

interface DisableAllFieldsContext {
    set: (disabled: boolean) => void;
    disabled: boolean;
}
const disableAllFieldsContext = React.createContext<DisableAllFieldsContext>({
    set: () => {
        console.warn('field disability override set called outside of context provider'); // tslint:disable-line
        return null;
    },
    disabled: false,
});

interface DisableAllFieldsContextState {
    disabled: boolean;
}
interface DisableAllFieldsContextProps {}
class DisableAllFieldsContextProvider extends React.Component<
    DisableAllFieldsContextProps,
    DisableAllFieldsContextState
> {
    state: DisableAllFieldsContextState = { disabled: false };
    set = (disabled: boolean) => {
        this.setState({ disabled });
    };
    render() {
        return (
            <disableAllFieldsContext.Provider
                value={{
                    set: this.set,
                    disabled: this.state.disabled,
                }}
            >
                {this.props.children}
            </disableAllFieldsContext.Provider>
        );
    }
}

const withDisableAllFieldsContextProvider = BaseComponent => props => (
    <DisableAllFieldsContextProvider>
        <BaseComponent {...props} />
    </DisableAllFieldsContextProvider>
);
export { DisableAllFieldsContextProvider, disableAllFieldsContext, withDisableAllFieldsContextProvider };
