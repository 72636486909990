import React from 'react';
import { Widget } from 'dashboard2/dashboard-config/types';
import Widgetize from 'dashboard2/widgets/widgetize';
import MyActiveTasksWidget from 'dashboard2/widgets/components/MyActiveTasksWidget';
import ClaimableTasksWidget from 'dashboard2/widgets/components/ClaimableTasksWidget';
import EntitySearchWidget from 'dashboard2/widgets/components/EntitySearchWidget';
import LinkWidget from 'dashboard2/widgets/components/LinkWidget';
import StartProcessWidget from 'dashboard2/widgets/components/StartProcessWidget';
import ReportWidget from 'dashboard2/widgets/report/components';
import GetPushAction from './components/GetPushAction';
import { Typography } from '@material-ui/core';
import HtmlWidget from 'dashboard2/widgets/components/HtmlWidget';
import TextTemplatePage from 'templatePage/components';
import MapWidget from 'dashboard2/widgets/components/MapWidget';

export default class WidgetFactory {
    static getComponent = (widgetConfiguration: Widget) => {
        const { title } = widgetConfiguration;
        switch (widgetConfiguration.type) {
            case 'MapWidget': {
                const { Component } = MapWidget;
                return (
                    <Widgetize title="none" layout={{}}>
                        <Component />
                    </Widgetize>
                );
            }
            case 'MyActiveTasksWidget': {
                const { GetHandleTitleClick, Component } = MyActiveTasksWidget;
                const {
                    definition: { drillToMyTasks },
                } = widgetConfiguration;
                return (
                    <Widgetize
                        title={{
                            title,
                            GetHandleTitleClick: drillToMyTasks ? GetHandleTitleClick : undefined,
                        }}
                        layout={{}}
                    >
                        <Component useTableView={widgetConfiguration.definition.useTaskListView !== false} />
                    </Widgetize>
                );
            }
            case 'ClaimableTasksWidget': {
                const { Component } = ClaimableTasksWidget;
                const { processKey, taskKey, roles, viewName, onlyUnassigned } = widgetConfiguration.definition;
                return (
                    <Widgetize
                        title={{
                            title,
                        }}
                        layout={{}}
                    >
                        <Component
                            processKey={processKey}
                            taskKey={taskKey}
                            roles={roles}
                            viewName={viewName}
                            onlyUnassigned={onlyUnassigned}
                        />
                    </Widgetize>
                );
            }
            case 'EntitySearchWidget': {
                const { Component } = EntitySearchWidget;
                const { entity, viewName, filterConfig } = widgetConfiguration.definition;
                return (
                    <Widgetize title={{ title }} layout={{}}>
                        <Component entity={entity} viewName={viewName} filterConfig={filterConfig} />
                    </Widgetize>
                );
            }
            case 'TemplatePageWidget': {
                return (
                    <TextTemplatePage
                        padding={false}
                        textTemplateName={widgetConfiguration.definition.textTemplateName}
                    />
                );
            }
            case 'HtmlWidget': {
                const { Component } = HtmlWidget;
                return (
                    <Widgetize title="none" layout={{}}>
                        <Component html={widgetConfiguration.definition.html} />
                    </Widgetize>
                );
            }
            case 'LinkWidget': {
                const { Component } = LinkWidget;
                const {
                    title,
                    definition: { url },
                } = widgetConfiguration;
                return (
                    <Widgetize title="none" layout={{}}>
                        <Component url={url} title={title} />
                    </Widgetize>
                );
            }
            case 'StartProcessWidget': {
                const { Component } = StartProcessWidget;
                const { title, definition } = widgetConfiguration;
                return (
                    <Widgetize title="none" layout={{}}>
                        <Component
                            startVariables={definition.startVariables}
                            processDefinitionKey={definition.processDefinitionKey}
                            title={title}
                        />
                    </Widgetize>
                );
            }
            case 'ReportWidget': {
                const { Component } = ReportWidget;
                const { title, definition } = widgetConfiguration;
                const titleUrl = definition.titleUrl;
                if (definition.type === 'singleValue') {
                    return (
                        <Widgetize title="none" layout={{}}>
                            <div style={{ position: 'relative', height: '100%', width: '100%' }}>
                                <div
                                    style={{
                                        position: 'absolute',
                                        width: '100%',
                                        top: '50%',
                                        msTransform: 'translateY(-50%)',
                                        transform: 'translateY(-50%)',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        verticalAlign: 'middle',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Typography variant="h5" component="span" style={{ padding: 16 }}>
                                        {title}
                                    </Typography>
                                    <Typography variant="h5" component="span" style={{ padding: 16 }}>
                                        <Component definition={definition} />
                                    </Typography>
                                </div>
                            </div>
                        </Widgetize>
                    );
                }
                return (
                    <Widgetize
                        title={{
                            title,
                            GetHandleTitleClick: titleUrl ? GetPushAction : undefined,
                            GetHandleTitleClickProps: titleUrl ? { url: definition.titleUrl } : undefined,
                        }}
                        layout={{}}
                    >
                        <Component definition={definition} />
                    </Widgetize>
                );
            }
        }
    };
    static create = (widgetConfiguration: Widget): React.ReactElement<{ key: string; id: string }> => {
        // switch (widgetConfiguration.type) {
        //    /*
        //     case 'TextWidget':
        //         WidgetComponent = TextWidget;
        //         widgetProps.dataLoaded = true;
        //         break;
        //     case 'HtmlWidget':
        //         WidgetComponent = HtmlWidget;
        //         widgetProps.addScroller = true;
        //         break;
        //     case 'TableWidget':
        //         WidgetComponent = TableWidget;
        //         break;
        //     */
        //     case 'SectionWidget':
        //         WidgetComponent = SectionWidget;
        //         break;
        //     */
        //     default:
        //         // logger.error('A widget can't be loaded because its type '{}' is unknown.', widgetConfiguration.type);
        //         // TODO: Add an error widget
        //         WidgetComponent = TextWidget;
        //         widgetProps.title = 'Unknown Widget Type';
        //         widgetProps.text = `Type not found : ${widgetConfiguration.type}. Please check your configuration.`;
        //         break;
        // }

        return (
            <div key={widgetConfiguration.id} id={widgetConfiguration.id}>
                {WidgetFactory.getComponent(widgetConfiguration)}
            </div>
        );
    };

    static createAllWidgets(widgets: Widget[]) {
        return widgets.map(widgetConfig => WidgetFactory.create(widgetConfig));
    }
}
