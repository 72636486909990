import get from 'lodash/get';
import deepExtend from '../../../../util/cyclicDeepExtend';
import { unflatten } from 'flat';
import stableStringify from 'fast-json-stable-stringify';
import isPojo from '../../../../util/isPojo';
import filterEntityByQueryRepresentation from 'isomorphic-query-filters/filterEntityByQueryRepresentation';
import ViewConfig from 'reducers/ViewConfigType';
import fromEntries from 'util/fromentries';

export interface ValuesetFieldAvailableConcepts {
    [field: string]:
        | {
              [conceptId: string]: boolean;
          }
        | '*';
}

export interface AvailableOptions {
    [field: string]: {
        empty: { name: string; value?: unknown } | null;
        options: {
            [stringifiedOption: string]: boolean;
        };
    };
}

export const replaceValuesetFieldsWithNullIfConceptsUnavailable = (
    values: {},
    valuesetFieldAvailableConcepts: ValuesetFieldAvailableConcepts,
    valuesetOneFields: {
        [field: string]: any;
    },
) => {
    const isOne = (f: string) => !!valuesetOneFields[f];

    return deepExtend(
        { ...values },
        unflatten(
            Object.assign(
                {},
                ...Object.entries(valuesetFieldAvailableConcepts).flatMap(([f, allowed]) => {
                    const fieldIdPath = isOne(f) ? `${f}Id` : `${f}Ids`;
                    const value = get(values, fieldIdPath);
                    if (value) {
                        if (allowed === '*' || (isOne(f) && allowed[value])) {
                            return [];
                        } else {
                            return [{ [fieldIdPath]: isOne(f) ? null : value && value.filter(id => allowed[id]) }];
                        }
                    }
                    return [];
                }),
            ),
        ),
    );
};

export const replaceOptions = (
    values: {},
    availableOptions: AvailableOptions,
    replaceValuesWithEntryObjects: boolean = true,
) => {
    return deepExtend(
        { ...values },
        unflatten(
            Object.assign(
                {},
                ...Object.entries(availableOptions).flatMap(([f, { empty, options }]) => {
                    const value = get(values, f);
                    if (isPojo(value)) {
                        if (options[stableStringify(value)]) {
                            return [];
                        }
                        return [{ [f]: empty }];
                    }
                    if (!value) {
                        return [{ [f]: empty }];
                    }
                    if (typeof value === 'string' || typeof value === 'number') {
                        if (typeof value === 'string') {
                            if (value.startsWith('{') && value.endsWith('}')) {
                                try {
                                    const parsed = JSON.parse(value);
                                    if (options[stableStringify(parsed)]) {
                                        return replaceValuesWithEntryObjects ? [{ [f]: parsed }] : [];
                                    }
                                } catch (e) {
                                    // continue to treat as plain value
                                }
                            }
                        }
                        const optObjs = Object.keys(options).map(option => JSON.parse(option));
                        const foundOption = optObjs.find(o => o.value && o.value === value);
                        if (foundOption) {
                            return replaceValuesWithEntryObjects ? [{ [f]: foundOption }] : [];
                        }
                        return [{ [f]: empty }];
                    }
                    return [];
                }),
            ),
        ),
    );
};

export const getCalculateValuesBasedOnAvailableFields = (
    viewConfig: ViewConfig,
    entities: {},
    initialValues: {},
    formValues: {},
    entirelyHiddenOrDisabledFields: string[],
    valuesetFieldAvailableConcepts: ValuesetFieldAvailableConcepts = {},
    valuesetOneFields: {
        // just used to check if the field is a 'one' or a 'many' (it's a many if it's not in the list)
        [field: string]: any;
    } = {},
    availableOptions: AvailableOptions = {},
    filteredRefOneFields: string[] = [],
    evaluatedRefManyFilters: {
        [field: string]: {
            entityType: string;
            filter: {};
        };
    } = {},
) => {
    const adjustedRefManys = Object.entries(evaluatedRefManyFilters).flatMap(([field, { entityType, filter }]) => {
        const ids = formValues[field];
        if (ids && Array.isArray(ids)) {
            const resultingValue = ids.filter(id => {
                return filterEntityByQueryRepresentation(viewConfig)(filter)({ id, entityType }, entities);
            });
            if (resultingValue.length < ids.length) {
                return [[field, resultingValue] as [string, string[]]];
            }
        }
        return [];
    });
    const merged = deepExtend(
        { ...formValues },
        unflatten(Object.assign({}, ...filteredRefOneFields.map(f => ({ [f]: null })))),
        unflatten(Object.assign({}, ...entirelyHiddenOrDisabledFields.map(f => ({ [f]: get(initialValues, f) })))),
        unflatten(fromEntries(adjustedRefManys)),
    );

    const withConceptAvailabilityApplied = replaceValuesetFieldsWithNullIfConceptsUnavailable(
        merged,
        valuesetFieldAvailableConcepts,
        valuesetOneFields,
    );
    const withOptionsAvailabilityApplied = replaceOptions(withConceptAvailabilityApplied, availableOptions, true);
    return withOptionsAvailabilityApplied;
};
