import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push as pushAction } from 'connected-react-router';
import { RootState } from 'reducers/rootReducer';

const useLogout = () => {
    const dispatch = useDispatch();
    const currPath = useSelector((state: RootState) => {
        return state.router.location.pathname;
    });
    const currSearch = useSelector((state: RootState) => {
        return state.router.location.search;
    });

    const logoutFn = useCallback(() => {
        if (currPath === '/') {
            dispatch(pushAction('/logout-redirect'));
        } else if (currPath !== '/login' && currPath !== '/logout-redirect') {
            const path = `/logout-redirect?redirectTo=${encodeURIComponent(currPath + currSearch)}`;
            dispatch(pushAction(path));
        }
    }, [dispatch, currPath, currSearch]);
    return logoutFn;
};
export default useLogout;
