import parse from 'date-fns/parse';
import moment from 'moment';

export const getTzOffset = () => new Date().getTimezoneOffset() / 60;
export const datify = (input: string | Date | null | undefined | number, mode: 'YYYYMMDD' | 'ISO' = 'YYYYMMDD') => {
    if (!input) {
        return null;
    }
    // get day saved
    if (mode === 'ISO') {
        const res = moment(input).format('YYYY-MM-DD');
        if (res === 'Invalid date') {
            console.error('invalid date', input); // tslint:disable-line
            return null;
        }
        return res;
    }
    // get day if e.g. new Date(<YYYY-MM-DD>)
    if (typeof input === 'string' && input.length <= 10 && input.length >= 8) {
        const parsed = parse(input);
        if (Object.prototype.toString.call(parsed) === '[object Date]' && !isNaN(parsed.getTime())) {
            return parsed;
        }
        throw new Error(`Invalid date: ${input}`);
    }
    // copy date to not mutate the argument
    let date: Date = input instanceof Date ? new Date(+input) : new Date(input);
    if (isNaN(date as any)) {
        // tslint:disable-line

        date = new Date((input as string).split('+')[0]);
        if (isNaN(date as any)) {
            // tslint:disable-line
            throw new Error(`Invalid date: ${input}`);
        } else {
            console.log('date fixed.'); // tslint:disable-line
        }
    }
    if (getTzOffset() > 0) {
        date.setDate(date.getDate() + 1);
    }
    return date;
};
