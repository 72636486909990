import 'isomorphic-fetch';
import 'babel-polyfill';
import 'es6-symbol/implement';
import 'es5-shim';
import 'es6-shim';
import 'es7-shim';
import 'moment-jdateformatparser';
import 'moment-business-days';
import injectViews from './injectViews';
import stripUnusedItems from 'stripUnusedItemsFromViewConfig';
import storage from 'local-storage-fallback';
import { storageController } from 'storage';
import { userAgent } from 'userAgent';

Error.stackTraceLimit = 50;

(window as any).casetivity_error_count = 0;
if (!userAgent.isIe()) {
    const consoleerror = console.error;
    window.console.error = function(...args) {
        consoleerror(...args);
        (window as any).casetivity_error_count += 1;
    };
}

/*
    Make fetch reset token in storage if JWT found in header
*/
const oldFetch = fetch;

window.fetch = (...args) => {
    return oldFetch(...args).then(r => {
        const newJwt = r.headers.get('JWT');
        if (newJwt) {
            // tslint:disable-line
            // setItem is synchronous
            storageController.setToken(newJwt);
        }
        return r;
    });
};

/*
    Hooks to mock certain requests in development
*/
/*
import { mockResponses } from './mockedResponses';
console.log(process.env); // tslint:disable-line
if (process.env.NODE_ENV === 'development') {
    const realFetch: any = window.fetch; // tslint:disable-line
    import('fetch-mock')
        .then((fetchMock) => {
            let mockChain = fetchMock;
            mockResponses.forEach((mockDef) => {
                mockChain = mockChain.mock(mockDef.url, mockDef.response, mockDef.options);
            });
            mockChain.catch((...t) => realFetch(...t));
        });
}
/* end mock fetch */

/*
    shim Object.values
*/
if (!Object.values) {
    require('object.values').shim();
}

/*
    If not logged in, we will check if our referral cookie has been given. We will set that as our
    token if so.
    global variable REFERRED_AUTH will indicate that the viewConfig needs to be loaded since we skipped
        the login step which normally triggers the viewConfig fetch
    (layout component will do this)
*/
const getCookie = name => {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) {
        return match[2];
    }
    return null;
};
(global as any).REFERRED_AUTH = false; // tslint:disable-line

/*
    on landing pages our own injected views aren't given, but the viewConfig is set here.
*/
const RAW_VC_KEY = 'viewconfig_without_injected_views';
const rawVc = storage.getItem(RAW_VC_KEY);
if (rawVc) {
    // lets inject the views now, and set in the proper storage key
    const updatedVc = stripUnusedItems(injectViews(JSON.parse(rawVc)));
    storage.removeItem(RAW_VC_KEY);

    storage.setItem('viewconfig', JSON.stringify(updatedVc));

    // NOTE: THIS WILL REFETCH THE VIEWCONFIG!
    // This is necessary for various things like rebuilding the schema, and parsing validations
    (global as any).REFERRED_AUTH = true; // tslint:disable-line
}

function delete_cookie(name: string) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

const jwtFromCookie = getCookie('JWT');
if (jwtFromCookie) {
    storageController.setToken(jwtFromCookie);
    delete_cookie('JWT');
    (global as any).REFERRED_AUTH = true; // tslint:disable-line
}

// Create Element.remove() function if not exist (internet explorer)
if (!('remove' in Element.prototype)) {
    (Element.prototype as any).remove = function() {
        // tslint:disable-line
        if (this.parentNode) {
            this.parentNode.removeChild(this);
        }
    };
}
