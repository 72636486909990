import React, { lazy, Suspense } from 'react';
const Playground = lazy(() => import('./'));

class ErrorBoundary extends React.Component<{}, { hasError: boolean }> {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
        console.error(error, info);
    }

    render() {
        if (this.state.hasError) {
            return (
                <h1>
                    Failed to lazy load. Probably because 'unsafe-eval' is not allowed, but we are in a 'development'
                    environment where we import code that requires it
                </h1>
            );
        }
        return this.props.children;
    }
}

function LazyPlayground() {
    if (process.env.NODE_ENV === 'development') {
        return (
            <ErrorBoundary>
                <Suspense fallback={<div>Loading...</div>}>
                    <Playground />
                </Suspense>
            </ErrorBoundary>
        );
    } else {
        return (
            <div>
                PrintTemplate playground is only available in development mode, because the 'docx' package relies on
                unsafe-eval
            </div>
        );
    }
}

export default LazyPlayground;
