import { Field, DataSource } from './translation/types/index';
import { getLabelledBy } from './input/fieldUtils';

export type AriaSupportProps = {
    labelledBy?: string;
};

export function getAriaSupportProperties(row: Number, col: Number, fieldDefintion: Field) {
    const ariaConfigs: AriaSupportProps = {};
    const labelledByConfigValue = getLabelledBy(fieldDefintion);
    if (labelledByConfigValue) {
        let labelledByValue = '';
        if (labelledByConfigValue.startsWith('&')) {
            const parsedValue = parseLabelledBy(labelledByConfigValue);
            const isFlowable = fieldDefintion._dataSource === DataSource.FLOWABLE;
            const calculatedValue = `${isFlowable ? 'flowable' : ''}expression:r${row.valueOf() +
                parsedValue.row.valueOf()}c${col.valueOf() + parsedValue.col.valueOf()}`;
            labelledByValue = calculatedValue;
        } else {
            labelledByValue = labelledByConfigValue;
        }
        ariaConfigs.labelledBy = labelledByValue;
    }
    return ariaConfigs;
}

type ParsedLabelledBy = {
    row: Number;
    col: Number;
};

function parseLabelledBy(value: String): ParsedLabelledBy {
    const rowAndColParts = value
        .trim()
        .toLowerCase()
        .split('r')[1]
        .split('c');
    return { row: Number(rowAndColParts[0]), col: Number(rowAndColParts[1]) };
}
