import React, { Component } from 'react';
import { fromNullable } from 'fp-ts/lib/Option';
import { RemoteData } from '@devexperts/remote-data-ts';
import { connect } from 'react-redux';
import defaultProps from 'recompose/defaultProps';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import { parse, stringify } from 'query-string';
import { push as pushAction } from 'connected-react-router';
import { Card, Typography } from '@material-ui/core';
import compose from 'recompose/compose';
import { createSelector } from 'reselect';
import queryReducer, {
    SET_SORT,
    SET_PAGE,
    SET_FILTER,
    SORT_DESC,
    QueryAction,
    QueryState,
    SET_PER_PAGE,
} from './queryReducer';
import ViewTitle from '../ViewTitle';
import { crudGetList as crudGetListAction } from 'sideEffect/crud/getList/actions';
import { saveAs } from 'file-saver';
import { BACKEND_BASE_URL } from '../../../config';
import dgStyles from '../utils/datagridStyles';
import { customShowRedirects } from '../overrides';
import { RootState } from '../../../reducers/rootReducer';
import { initial } from '@devexperts/remote-data-ts';
import { Subtract } from 'utility-types';
import removeNullAndUndefinedKeys from '../../../util/removeNullAndUndefinedKeys';
import getNoResultsTextElement from './getNoResultsTextElement';
import SsgAppBarMobile from 'components/SsgAppBarMobile';
import DeferredSpinner from 'components/DeferredSpinner';
import { getPluralName, getAllPrefilters, showRecentlyViewed as getShowRecentlyViewed } from '../utils/viewConfigUtils';
import { queryParametersForSearch } from 'sideEffect/crud/util/queryBuilderUtils';
import preprocessFilter from 'clients/utils/preprocessFilter';
import { storageController } from 'storage';
import RecentlyVisitedLinks from 'recently-visited/components/RecentlyVisitedLinks';
/*
imports for tablehead on empty example
import { renderTableHead } from './renderList';
import { Table } from '@material-ui/core';
*/
export const LIST_LOADING_TESTID = 'list-loading-indicator';
export const SUBMIT_SEARCH_TEXT = 'Submit search for Results';

type FirstArgument<T> = T extends (arg1: infer U, ...args: any[]) => any ? U : any; // tslint:disable-line

const styles = {
    header: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
} as const;

/**
 * List page component
 *
 * The <List> component renders the list layout (title, buttons, filters, pagination),
 * and fetches the list of records from the REST API.
 * It then delegates the rendering of the list of records to its child component.
 * Usually, it's a <Datagrid>, responsible for displaying a table with one row for each post.
 *
 * In Redux terms, <List> is a connected component, and <Datagrid> is a dumb component.
 *
 * Props:
 *   - title
 *   - perPage
 *   - sort
 *   - filter (the permanent filter to apply to the query)
 *   - actions
 *   - filters (a React Element used to display the filter form)
 *   - pagination
 *
 * @example
 *     const PostFilter = (props) => (
 *         <Filter {...props}>
 *             <TextInput label="Search" source="q" alwaysOn />
 *             <TextInput label="Title" source="title" />
 *         </Filter>
 *     );
 *     export const PostList = (props) => (
 *         <List {...props}
 *             title="List of posts"
 *             sort={{ field: 'published_at' }}
 *             filter={{ is_published: true }}
 *             filters={<PostFilter />}
 *         >
 *             <Datagrid>
 *                 <TextField source="id" />
 *                 <TextField source="title" />
 *                 <EditButton />
 *             </Datagrid>
 *         </List>
 *     );
 */
const Div = props => <div {...props} />;
const DEFAULT_SORT: Sort = { field: 'id', order: SORT_DESC };
export interface Data {
    [key: string]: {
        id: string;
        title?: string;
        subtitle?: string;
    };
}
interface ListResult {
    ids: (string | number)[];
    total: number;
    data: Data;
}

interface ListState {
    key: number;
    filters: {};
    datagridReady: boolean;
}

export interface RenderFilterArguments {
    displayHeader?: boolean;
    resource: string;
    filterValues: {};
    setFilters: (filters: {}) => void;
    submitFilters: (state: { filters: {} }) => void;
    clearFilters: () => void;
    formId?: string | null;
    permanentFilter: {};
}

export interface RenderPaginationArguments {
    total: number;
    page: number;
    perPage: number;
    setPage: (page: number) => void;
    setPerPage: (perPage: number) => void;
}
export type RenderActionsArguments = {
    listHasData: boolean;
    filterValues: {};
    basePath: string;
    showCreate: boolean;
    refresh: (e: Event) => void;
    exportList: () => void;
    createRedirectQueryString?: string;
    viewConfig?: RootState['viewConfig'];
    listViewName?: string;
} & Pick<ListInternalProps, 'resource' | 'hasCreate'>;

export interface Sort {
    field: string;
    order: 'ASC' | 'DESC';
}
export interface RenderListArguments {
    SelectIconComponent?: React.ComponentType<any>;
    disableSorting?: boolean;
    resultHeadingText?: string | JSX.Element | null;
    resource: string;
    ids: string[];
    data: Data;
    currentSort?: {
        field: string;
        order: 'ASC' | 'DESC';
    };
    basePath: string;
    isLoading: boolean;
    styles?: {};
    noClick?: boolean;
    setSort: (sort: string) => void;
    bodyOptions?: {
        showRowHover: boolean;
        selectable: boolean;
        deselectOnClickaway: boolean;
    };
    rowOptions?: {
        hoverable: boolean;
        selectable: boolean;
    };
    options?: {
        hoverable: boolean;
        multiSelectable: boolean;
    };
    selectedData?: { [id: string]: { id: string } };
    onRowSelect?: <T extends Data>(selected: T[0][], allData: Data) => void;
    showCheckBox?: boolean;
    width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    listIsLoading?: boolean;
    fields: React.ReactElement<{
        record?: {};
        basePath?: string;
        resource: string;
        source: string;
        label: string;
        sortable?: boolean;
    }>[]; // improve on this type.
    ariaProps?: {};
}
interface ListWithDefaultProps {
    noRecentlyVisited?: boolean;
    appendExpansions?: string[];
    keyForPrev?: string;
    cancelRequestOnRouteChange?: boolean;
    createMobileAppBar?: boolean;

    resultHeadingText?: string | null;
    usePrevWhenLoading?: boolean;
    viewName: string;
    noClick?: boolean;

    isPopover: boolean;
    embeddedInFormId?: string;
    formId?: string | null;
    topView?: string | null;
    viewStack: string[];
    listIsLoading: boolean;
    hasEmptySearch?: boolean;
    hasSearchFields?: boolean;

    // the props you can change
    title?: Element | string | null;

    // used to pass location with updated search string back to parent component, if list is in a dialog or similar.
    fakePush?: Function;
    // used to pass selected rows back up the component heirarchy e.g. when List is a dialog in popup search.
    onRowSelect?: RenderListArguments['onRowSelect'];
    // goes with onRowSelect-contains selectedData to pass down to child datagrid component if using SelectableDatagrid.
    selectedData?: {};
    filter?: {};
    perPage: string;
    sort?: Sort;
    view: RootState['viewConfig']['views'][0];
    crudGetList: typeof crudGetListAction;
    filterValues?: {};
    alwaysPreventInitialSearch?: boolean;
    forceDatagridNotReady?: boolean;
    hasCreate: boolean;
    isLoading: boolean;
    location: {
        pathname: string;
        search: string;
    };
    showRecentlyViewed: number;
    path?: string;
    push: Function;
    query: QueryState;
    resource: string;
    overrideApi?: string;
    width: RenderListArguments['width'];
    fields: RenderListArguments['fields'];
    viewConfig: RootState['viewConfig'];
    remoteData: RemoteData<Error, ListResult>;
    prev: ListResult | null;
    dgStyles?: {};
    dgOptions?: {};
    updateUrlFromFilter?: boolean;
    multiSelectable?: boolean;
    canSelectRows?: boolean;
    showImmediately?: boolean;
    showCreate?: boolean;
    createRedirectQueryString?: string;
    showCheckBox?: boolean;
    useCard?: boolean;
    customTitleElement?: React.ReactNode;
    fetchOnMount?: boolean;
    renderWhileNoPrevAndSpinnerDeferred?: () => JSX.Element | null | string;
    renderList: (args: RenderListArguments) => React.ReactElement<RenderListArguments> | null;
    renderPagination: (args: RenderPaginationArguments) => React.ReactElement<RenderPaginationArguments> | null;
    renderActions: (args: RenderActionsArguments) => React.ReactElement<RenderActionsArguments> | null;
    renderFilter: (args: RenderFilterArguments) => React.ReactElement<RenderFilterArguments> | null;
    renderNoResults?: (args: RenderListArguments & { getDefaultNoResults: () => JSX.Element }) => JSX.Element | null;
}
interface ListDefaultProps {
    noRecentlyVisited: boolean;
    showImmediately: boolean;
    canSelectRows: boolean;
    multiSelectable: boolean;
    updateUrlFromFilter: boolean;
    filter: {};
    filterValues: {};
}

type LoadedState = 'BASE_STATE' | 'STARTED_LOADING' | 'ENDED_LOADING';

class LoadedRetainer {
    private state: LoadedState;
    constructor() {
        this.state = 'BASE_STATE';
    }
    getState() {
        return this.state;
    }
    canReset() {
        this.state = 'BASE_STATE';
    }
    someLoadingFinished() {
        return this.state === 'ENDED_LOADING';
    }
    take(listIsLoading: boolean) {
        if (listIsLoading) {
            if (this.state === 'BASE_STATE') {
                this.state = 'STARTED_LOADING';
            }
        } else {
            if (this.state === 'STARTED_LOADING') {
                this.state = 'ENDED_LOADING';
            }
        }
    }
}

type ListInternalProps = ListWithDefaultProps & ListDefaultProps;
export const List: React.ComponentClass<ListWithDefaultProps, ListState> = class extends Component<
    ListInternalProps,
    ListState
> {
    loadedRetainer = new LoadedRetainer();
    static displayName = 'ListComponent';
    static defaultProps = {
        noRecentlyVisited: false,
        showImmediately: false,
        canSelectRows: true,
        topView: null,
        formId: null,
        multiSelectable: false,
        updateUrlFromFilter: true,
        filter: {},
        filterValues: {},
    };
    fullRefresh: boolean;
    constructor(props: ListInternalProps) {
        super(props);
        this.state = {
            key: 0,
            /* Because we want to send filters only when the submit button is pressed,
                we will have setFilter set the filter in our state instead of dispatching SET_FILTER.
                Then when the submit button is pressed, we send the filter in our component state with SET_FILTER.
            */
            filters: this.props.filterValues || {},
            datagridReady:
                !this.props.forceDatagridNotReady &&
                !this.props.alwaysPreventInitialSearch &&
                (this.props.showImmediately || !this.props.hasEmptySearch),
        };
    }

    componentDidMount() {
        const { query, sort = DEFAULT_SORT, fetchOnMount = true } = this.props;
        if (!fetchOnMount || !this.state.datagridReady) {
            return;
        } else if (Object.keys(query).length > 0) {
            this.updateData();
        } else {
            const newParams = {
                filter: {},
                page: '1',
                sort: sort.field,
                order: sort.order,
                perPage: this.props.perPage,
            };
            this.updateData(newParams);
        }
    }

    componentDidUpdate(prevProps: ListInternalProps, prevState) {
        if (
            this.props.topView &&
            this.props.topView !== this.props.formId &&
            (!this.props.embeddedInFormId || this.props.topView !== this.props.embeddedInFormId)
        ) {
            return;
        }
        if (
            !this.props.forceDatagridNotReady &&
            this.loadedRetainer.someLoadingFinished() &&
            !this.state.datagridReady &&
            !this.props.hasEmptySearch // no longer have an empty search from the page init? display the datagrid!
        ) {
            this.setState({ datagridReady: true });
        }

        if (this.props.resource !== prevProps.resource || !this.queriesEqual(prevProps.query, this.props.query)) {
            this.updateData(this.props.query || ({} as QueryState), this.props.filter);
        }
        if (this.props.remoteData.toNullable() !== prevProps.remoteData.toNullable() && this.fullRefresh) {
            this.fullRefresh = false;
            this.setState(state => ({ ...state, key: state.key + 1 }));
        }
        if (this.loadedRetainer.someLoadingFinished()) {
            this.loadedRetainer.canReset();
        }
    }
    queriesEqual(query1: ListInternalProps['query'], query2: ListInternalProps['query']) {
        return (
            query1.perPage === query2.perPage &&
            query1.sort === query2.sort &&
            query1.order === query2.order &&
            query1.page === query2.page &&
            query1.perPage === query2.perPage &&
            isEqual(query1.filter, query2.filter)
        );
    }

    shouldComponentUpdate(nextProps: ListInternalProps, nextState: ListState) {
        this.loadedRetainer.take(this.props.listIsLoading);
        /* if (!this.state.datagridReady && nextState.datagridReady) {
            return true;
        } */
        /*
        if (this.props.listIsLoading !== nextProps.listIsLoading) {
            return true;
        }
        */
        /*
        if (this.props.keyForPrev !== nextProps.keyForPrev) {
            return true;
        }
        */
        // a popover was removed. Prevents double flash on popover open
        if (this.props.forceDatagridNotReady !== nextProps.forceDatagridNotReady) {
            return true;
        }
        if (
            this.props.viewStack.length > nextProps.viewStack.length &&
            (nextProps.topView === nextProps.formId || nextProps.topView === nextProps.embeddedInFormId)
        ) {
            // form is back to top view
            return true;
        }
        if (this.props.multiSelectable !== nextProps.multiSelectable) {
            return true;
        }
        if (
            /*
                    If the list is not multiselectable,
                    update if (but not only if) it has selectedData and selectedData is different.
                    */
            ((!nextProps.multiSelectable &&
                (!nextProps.selectedData ||
                    (this.props.selectedData &&
                        Object.keys(nextProps.selectedData)[0] &&
                        Object.keys(this.props.selectedData)[0] &&
                        Object.keys(nextProps.selectedData)[0] === Object.keys(this.props.selectedData)[0]))) ||
                /*
                    Otherwise if the list is multiselectable, update if something was added or removed.
                    */
                (nextProps.multiSelectable &&
                    nextProps.selectedData &&
                    this.props.selectedData &&
                    Object.keys(nextProps.selectedData).length === Object.keys(this.props.selectedData).length &&
                    !Object.keys(nextProps.selectedData).find(k => !this.props.selectedData[k]))) &&
            // && nextProps.selectedData === this.props.selectedData)
            nextProps.isLoading === this.props.isLoading &&
            nextProps.width === this.props.width &&
            nextState === this.state &&
            nextProps.remoteData._tag === this.props.remoteData._tag &&
            !this.loadedRetainer.someLoadingFinished() &&
            this.queriesEqual(nextProps.query, this.props.query)
        ) {
            return false;
        }

        if (
            nextProps.topView && // there is a popover
            nextProps.topView !== nextProps.formId && // I am not top layer
            nextProps.topView !== nextProps.embeddedInFormId /* && !this.props.isPopover */ // I am not embedded in top layer
        ) {
            // view is not on the top layer.
            return false;
        }

        return true;
    }

    getBasePath() {
        return this.props.location.pathname;
    }

    /**
     * Merge list params from 3 different sources:
     *   - the query string
     *   - the params stored in the state (from previous navigation)
     *   - the props passed to the List component
     */
    getQuery(): QueryState {
        const query: Partial<QueryState> = this.props.query || { page: 1 };
        if (!query.sort) {
            const { sort = DEFAULT_SORT } = this.props;
            query.sort = sort.field;
            query.order = sort.order;
        }
        if (!query.perPage) {
            query.perPage = this.props.perPage;
        }
        return query as QueryState;
    }

    setSort = sort => this.changeParams({ type: SET_SORT, payload: sort });

    setPage = page => this.changeParams({ type: SET_PAGE, payload: page });

    setPerPage = perPage => this.changeParams({ type: SET_PER_PAGE, payload: perPage });

    setFilters = filters => {
        this.setState({ filters });
    };
    // setFilters = filters => this.changeParams({ type: SET_FILTER, payload: filters });
    submitFilters = (state: { filters: {} } = this.state) =>
        this.changeParams({ type: SET_FILTER, payload: state.filters });
    clearFilters = () => this.setState({ filters: {} }, this.submitFilters);

    updateData(query?: ListInternalProps['query'], permanentFilter: ListInternalProps['filter'] = this.props.filter) {
        const params = query || this.getQuery();
        const { sort, order, page, perPage, filter } = params;
        const pagination = {
            // parseInt returns the number unchanged if number is passed
            page: page ? parseInt(page as string, 10) : 1,
            perPage: parseInt(perPage as string, 10),
        };
        this.props.crudGetList(
            {
                resource: this.props.resource,
                overrideApi: this.props.overrideApi,
                pagination,
                sort: { field: sort, order },
                filter: { ...permanentFilter, ...filter },
                // the list should be capable of overwriting its permanentFilter
                view: this.props.viewName,
                appendExpansions: this.props.appendExpansions,
            },
            this.props.cancelRequestOnRouteChange === false ? false : true,
        );
    }

    refresh = event => {
        event.stopPropagation();
        this.fullRefresh = true;
        this.updateData();
    };

    exportList = () => {
        const { filter } = this.getQuery();
        const filterStr = queryParametersForSearch(
            preprocessFilter(filter, this.props.viewConfig, this.props.viewName),
            true,
        );
        const request = new Request(
            `${BACKEND_BASE_URL}${
                this.props.viewConfig.entities[this.props.resource].restUrl
            }/export?page=0&size=2000&${filterStr}`,
            {
                method: 'GET',
                credentials: 'same-origin',
                headers: new Headers({
                    Authorization: `Bearer ${storageController.getToken()}`,
                    Cookie: `${window.document.cookie}`,
                }),
            },
        );
        fetch(request)
            .then(response => response.blob())
            .then(blob => saveAs(blob, `${this.props.title || this.props.resource}.csv`));
    };

    pushNewLocation = (locationWithUpdatedSearch, filter?: {}) => {
        if (this.props.updateUrlFromFilter) {
            // change the Url
            this.props.push(locationWithUpdatedSearch);
        } else {
            if (!this.props.fakePush) {
                throw new Error('must provide a fakePush function to List if updateUrlFromFilter is false');
            }
            // The list is in a popup or similar - this sets the parent state to the search location.
            this.props.fakePush(locationWithUpdatedSearch, filter);
        }
    };

    changeParams(action: QueryAction) {
        const newParams = queryReducer(this.getQuery(), action);
        this.updateParams(newParams);
    }

    updateParams = (newParams: QueryState) => {
        const newFilter = removeNullAndUndefinedKeys({ ...newParams.filter });
        const filter = JSON.stringify(newFilter, Object.keys(newFilter).sort());
        const locationWithUpdatedSearch = {
            ...this.props.location,
            search: `?${stringify({
                ...newParams,
                filter,
            })}`,
        };
        this.pushNewLocation(
            locationWithUpdatedSearch,
            // sending the filter as a second argument as a way of saying 'make sure you include this in the new location'
            // the processList, for example, strips out old filters because when the case-type changes, we have to remove old search parameters
            // this says 'this must be part of the search query created'
            newFilter,
        );
        /*
            Prevent double-fetch
            We 'updateData' when our search parameters change.
            Since our list 'state' is stored externally throught the 'location' (url-encoded query parameters)
            and we refetch on location changes, we just need to call 'pushNewLocation'
        */
        //  if the search hasn't changed, then we have to trigger the search ourselves,
        // since there won't be an fetch from props changing

        if (locationWithUpdatedSearch.search === this.props.location.search) {
            this.updateData(newParams);
        }
    };
    getRenderListProps = ({ ids, total, data, resultHeadingText }): RenderListArguments => {
        const {
            resource,
            isLoading,
            listIsLoading,
            canSelectRows,
            selectedData,
            query,
            onRowSelect,
            dgOptions,
            showCheckBox = false,
            width,
            fields,
            noClick,
        } = this.props;
        const basePath = this.getBasePath();
        const isSelectable = !customShowRedirects[resource] || customShowRedirects[resource].find(r => r._isRowClick);
        return {
            resultHeadingText,
            resource,
            ids,
            data,
            noClick,
            currentSort: { field: query.sort, order: query.order },
            basePath,
            isLoading,
            listIsLoading,
            styles: this.props.dgStyles || dgStyles,
            width,
            fields,
            setSort: this.setSort,
            ...(canSelectRows
                ? {
                      bodyOptions: {
                          showRowHover: isSelectable,
                          selectable: isSelectable,
                          deselectOnClickaway: !selectedData,
                      },
                      rowOptions: {
                          hoverable: isSelectable,
                          selectable: isSelectable,
                      },
                      options: {
                          hoverable: isSelectable,
                          multiSelectable: this.props.multiSelectable,
                          ...(dgOptions || {}),
                      },
                  }
                : {}),
            selectedData,
            onRowSelect,
            showCheckBox,
        };
    };
    renderResults = ({ ids, total, data }) => {
        const { key } = this.state;
        const { query, renderNoResults, resultHeadingText } = this.props;
        return total > 0 ? (
            <div key={key}>
                {this.props.renderList(this.getRenderListProps({ ids, total, data, resultHeadingText }))}
                {this.props.renderPagination({
                    total,
                    setPerPage: this.setPerPage,
                    // parseInt returns the number unchanged if number is passed
                    page: parseInt(query.page as string, 10),
                    perPage: parseInt(this.props.perPage as string, 10),
                    setPage: this.setPage,
                })}
            </div>
        ) : renderNoResults ? (
            renderNoResults({
                getDefaultNoResults: () => getNoResultsTextElement('No results found'),
                ...this.getRenderListProps({ ids: [], total: 0, data: {}, resultHeadingText: null }),
            })
        ) : (
            getNoResultsTextElement('No results found')
        );
    };
    renderFilters() {
        const { resource, query, renderFilter, formId, filter, viewConfig, viewName } = this.props;
        const filterValues = query.filter;

        const defaultFilters = !this.state.datagridReady
            ? getAllPrefilters(viewConfig, resource, viewName, 'DEFAULT_VALUE')
            : {};
        return renderFilter({
            resource,
            filterValues: { ...defaultFilters, ...filterValues },
            setFilters: this.setFilters,
            submitFilters: this.submitFilters,
            clearFilters: this.clearFilters,
            formId,
            permanentFilter: filter,
        });
    }

    renderHiddenAriaLive = remoteData => {
        const resourceDisplayName = getPluralName(this.props.viewConfig, this.props.resource) || 'Results';
        return (
            <span aria-live="polite" aria-atomic="true" className="casetivity-off-screen">
                {this.props.listIsLoading
                    ? 'loading'
                    : !this.state.datagridReady
                    ? SUBMIT_SEARCH_TEXT
                    : remoteData.fold(
                          '',
                          'loading',
                          e => 'Search failed: ' + e.message,
                          ({ ids, total }) =>
                              total > 0
                                  ? `Showing ${ids.length} out of ${total} ${resourceDisplayName}`
                                  : `No ${resourceDisplayName} found`,
                      )}
            </span>
        );
    };

    render() {
        const {
            resource,
            hasCreate,
            title,
            remoteData,
            isLoading,
            showCreate = true,
            usePrevWhenLoading = true,
            createRedirectQueryString,
            useCard = true,
            isPopover,
            customTitleElement,
            createMobileAppBar,
            showRecentlyViewed,
            noRecentlyVisited,
        } = this.props;
        const query = this.getQuery();
        const filterValues = query.filter;
        const basePath = this.getBasePath();

        const titleElement = title && <span>{title}</span>;
        const ParentComponent = useCard ? Card : Div;
        return (
            <React.Fragment>
                {createMobileAppBar ? <SsgAppBarMobile title={titleElement} /> : null}
                <div className="list-page">
                    <ParentComponent style={{ opacity: isLoading ? 0.8 : 1, padding: useCard ? '1%' : 0 }}>
                        <div style={styles.header}>
                            {customTitleElement ||
                                (!isPopover ? (
                                    title && (
                                        <ViewTitle
                                            displayAboveWidth={createMobileAppBar ? 'xs' : undefined}
                                            title={titleElement}
                                        />
                                    )
                                ) : (
                                    <Typography variant="h5" component="div">
                                        {titleElement}
                                    </Typography>
                                ))}
                            {this.props.renderActions({
                                listHasData: remoteData.isSuccess() && remoteData.value.total > 0,
                                resource,
                                filterValues,
                                basePath,
                                hasCreate,
                                showCreate,
                                refresh: this.refresh,
                                exportList: this.exportList,
                                createRedirectQueryString,
                                viewConfig: this.props.viewConfig,
                                listViewName: this.props.viewName,
                            })}
                        </div>
                        {this.renderFilters()}
                        {noRecentlyVisited || !showRecentlyViewed ? null : (
                            <div style={{ width: '100%', marginTop: '1em' }}>
                                <RecentlyVisitedLinks maxResults={showRecentlyViewed} entityType={resource} />
                            </div>
                        )}
                        {this.renderHiddenAriaLive(remoteData)}
                        <div style={{ display: this.state.datagridReady ? undefined : 'none' }}>
                            {remoteData.foldL(
                                () => (
                                    <div>Preparing...</div>
                                ),
                                () =>
                                    this.props.prev && usePrevWhenLoading ? (
                                        this.renderResults(this.props.prev)
                                    ) : (
                                        <div data-testid={LIST_LOADING_TESTID}>
                                            <DeferredSpinner
                                                renderWhileDeferred={this.props.renderWhileNoPrevAndSpinnerDeferred}
                                            />
                                        </div>
                                    ),
                                error => (
                                    <div>
                                        Error! {error.message}
                                        <button onClick={this.refresh}>Retry</button>
                                        <button
                                            onClick={() => {
                                                this.updateParams({
                                                    sort: DEFAULT_SORT.field,
                                                    page: 1,
                                                    perPage: this.props.perPage,
                                                    order: DEFAULT_SORT.order,
                                                    filter: {},
                                                });
                                            }}
                                        >
                                            Reset
                                        </button>
                                    </div>
                                ),
                                this.renderResults,
                            )}
                        </div>
                        <div
                            style={{
                                display: this.state.datagridReady ? 'none' : undefined,
                                textAlign: 'center',
                                padding: '2em',
                                color: 'black',
                            }}
                        >
                            {this.props.listIsLoading ? (
                                <DeferredSpinner renderWhileDeferred={() => SUBMIT_SEARCH_TEXT} />
                            ) : (
                                SUBMIT_SEARCH_TEXT
                            )}
                        </div>
                    </ParentComponent>
                </div>
            </React.Fragment>
        );
    }
};

const getStringRep = query => {
    const newQ = { ...query };
    if (query.filter) {
        newQ.filter = JSON.stringify(query.filter, Object.keys(query.filter).sort());
    }
    return `?${stringify(newQ)}`;
};
const emptyObj = {};

const getPerPage = (state: RootState, props: ConnectedListProps) => {
    return props.perPage || (state.basicInfo && state.basicInfo.pageNum ? state.basicInfo.pageNum : 25);
};

const getLocationSearch = (_, props: ConnectedListProps) => props.location.search;

export const constructQuery = (locationSearch, permanentFilter, defaultPerPage, defaultSort) => {
    const query = parse(locationSearch);
    if (query.filter && typeof query.filter === 'string') {
        query.filter = JSON.parse(query.filter);
    } else {
        query.filter = {};
    }
    query.filter = { ...query.filter, ...permanentFilter };
    if (!query.page) {
        query.page = 1;
    }
    if (!query.perPage) {
        query.perPage = defaultPerPage;
    }
    if (!query.sort) {
        query.sort = defaultSort.field;
    }
    if (!query.order) {
        query.order = defaultSort.order;
    }
    return query;
};

const makeMapStateToProps = () => {
    const getQuery = createSelector(
        getLocationSearch,
        (_, props: ConnectedListProps) => props.filter,
        getPerPage,
        (_, props: ConnectedListProps) => props.sort,
        constructQuery,
    );
    // cached previous success data: used to present the last data this component saw while loading
    let prevSuccessData: ListResult | null = null;

    const stringRepSelector = createSelector(getQuery, getStringRep);
    const listForFilterSelector = createSelector(
        stringRepSelector,
        (state: RootState, props: ConnectedListProps): RootState['admin']['resources'][0] => {
            return state.admin.resources[props.resource];
        },
        (stringRep, resourceState) => fromNullable(resourceState).chain(rs => fromNullable(rs.lists[stringRep])),
    );
    const listIsLoadingForFilterSelector = createSelector(
        stringRepSelector,
        (state: RootState, props: ConnectedListProps): RootState['admin']['resources'][0] => {
            return state.admin.resources[props.resource];
        },
        (stringRep, resourceState) =>
            fromNullable(resourceState)
                .chain(rs => fromNullable(rs.listIsLoading[stringRep]))
                .getOrElse(false),
    );
    const memoizedGetData = createSelector(
        listForFilterSelector,
        (_, props) => props.resource,
        (_, props) => props.viewName,
        (state, _) => state.admin.entities,
        (state, _) => state.viewConfig,

        (listForFilter, resource, viewName, entities, viewConfig) => {
            return listForFilter.getOrElse(initial).map(listRep => ({
                ids: listRep.ids,
                total: listRep.total,
                data: entities[resource]
                    ? Object.assign(
                          {},
                          ...listRep.ids.map(id => (entities[resource][id] ? { [id]: entities[resource][id] } : {})),
                      )
                    : emptyObj,
            }));
        },
    );
    const getShowRecentlyViewedSelector = createSelector(
        (state: RootState, viewName: string) => state.viewConfig,
        (state: RootState, viewName: string) => viewName,
        getShowRecentlyViewed,
    );
    let prevKeyForPrev: string | undefined = undefined;
    const mapStateToProps = (state: RootState, props: ConnectedListProps): StateMappedProps => {
        const showRecentlyViewed = getShowRecentlyViewedSelector(state, props.viewName);
        const hasSearchFields =
            Object.keys(
                fromNullable(state.viewConfig.views[props.viewName])
                    .mapNullable(v => v.searchFields)
                    .getOrElse({}),
            ).length > 0;
        const query = getQuery(state, props);
        const filterValues = query.filter;

        const remoteData: ListWithDefaultProps['remoteData'] = memoizedGetData(state, props);
        const prev = props.keyForPrev === prevKeyForPrev ? prevSuccessData : null;
        if (props.keyForPrev !== prevKeyForPrev) {
            prevSuccessData = null;
        } else if (remoteData.isSuccess()) {
            prevSuccessData = remoteData.toNullable();
        }
        const listIsLoading = listIsLoadingForFilterSelector(state, props);
        // subract these from the filters given to determine if our search is empty for purposes of not immediately showing results.
        const preFilters = getAllPrefilters(state.viewConfig, props.resource, props.viewName, 'NON_DEFAULT');
        const filterEmptyExceptPrefilters = (obj?: {}) => {
            return !obj || !Object.keys(obj).find(k => !preFilters[k]);
        };
        const hasEmptySearch =
            hasSearchFields &&
            isEmpty(props.location.search.startsWith('?') ? props.location.search.slice(1) : props.location.search) &&
            filterEmptyExceptPrefilters(filterValues) &&
            filterEmptyExceptPrefilters(props.filter);

        prevKeyForPrev = props.keyForPrev;
        return {
            showRecentlyViewed,
            query,
            prev,
            remoteData,
            isLoading: state.admin.loading > 0,
            filterValues,
            viewStack: state.viewStack,
            topView: state.viewStack[0],
            perPage: query.perPage,
            listIsLoading,
            hasEmptySearch,
            hasSearchFields,
        };
    };
    return mapStateToProps;
};

type StateMappedProps = Pick<
    ListWithDefaultProps,
    | 'showRecentlyViewed'
    | 'query'
    | 'prev'
    | 'remoteData'
    | 'isLoading'
    | 'filterValues'
    | 'viewStack'
    | 'topView'
    | 'perPage'
    | 'listIsLoading'
    | 'hasEmptySearch'
    | 'hasSearchFields'
>;

interface DispatchMappedProps {
    crudGetList: ListWithDefaultProps['crudGetList'];
    push: ListWithDefaultProps['push'];
}
export type ConnectedListProps = Subtract<ListWithDefaultProps, Partial<StateMappedProps & DispatchMappedProps>> & {
    perPage?: string;
};

const enhance = compose(
    defaultProps({
        sort: {
            field: 'id',
            order: SORT_DESC,
        },
    }),
    connect(makeMapStateToProps, {
        crudGetList: crudGetListAction,
        push: pushAction,
    }),
);

const GenericList: React.SFC<ConnectedListProps> = enhance(List);
export default GenericList;
