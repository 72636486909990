import React, { useMemo, useContext, useState } from 'react';
import { formContext } from 'bpm/components/TaskDetail/TaskForm/FormContext';
import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    Card,
    TextField,
    Button,
    Divider,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
} from '@material-ui/core';
import { entityPreprocessValuesForEval } from 'expressions/formValidation';
import { TaskForm, RootState } from 'reducers/rootReducer';
import { getValueset1Fields } from 'bpm/form-context-utils';
import { useSelector } from 'react-redux';
import { createGetEntities } from 'components/generics/form/EntityFormContext/util/getEntities';
import { formContext as editEntityFormContext } from 'components/generics/form/EntityFormContext';
import { formContext as showEntityFormContext } from 'components/generics/form/EntityFormContext/Show';
import JSONEditorDemo from 'expression-tester/JsonEditorReact';
import { evaluateExpression } from 'casetivity-shared-js/lib/spel/evaluate';
import { createRootContext } from 'expressions/evaluate';
import { entityAndConceptLookupUtils } from 'expressions/expressionArrays';
import createRecordSelector from 'components/generics/form/EntityFormContext/util/recordSelector';
import forceBooleanFieldsBoolean from 'components/generics/form/EntityFormContext/util/enforceBoolValues';
import { evaluateContext2 } from 'expressions/CachingEvaluator/FormContextEvaluator';

function addSlashes(string) {
    return (
        string
            .replace(/\\/g, '\\\\')
            .replace(/\t/g, '\\t')
            .replace(/\n/g, '\\n')
            .replace(/\f/g, '\\f')
            .replace(/\r/g, '\\r')
            // .replace(/'/g, "\\'");
            .replace(/"/g, '\\"')
    );
}

function isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

type ExpressionTestAreaProps =
    | {
          contextType: 'start-form' | 'existing-task-form';
          formDefinition: TaskForm;
          viewName?: undefined;
      }
    | {
          contextType: 'entity-view';
          type: 'EDIT' | 'SHOW';
          viewName: string;
          record: { id: string; entityType: string };
          fieldsInExpressions: string[];
          valueset1Fields: {
              [f: string]: string;
          };
      };
const useGetEvalContext = (props: ExpressionTestAreaProps) => {
    const viewConfig = useSelector((state: RootState) => state.viewConfig);
    const getEntities = useMemo(createGetEntities, []);

    // GET VALUES FOR SHOW VIEW
    const getRootRecord = useMemo(createRecordSelector, []);
    const rootRecord = useSelector((state: RootState) =>
        props.contextType === 'entity-view'
            ? getRootRecord(state, { ...props, overrideFieldsInExpressions: props.fieldsInExpressions })
            : null,
    );
    const valuesToUse = useMemo(
        () =>
            props.contextType === 'entity-view' &&
            rootRecord &&
            forceBooleanFieldsBoolean(viewConfig, props.viewName)(rootRecord),
        [rootRecord, props.contextType, props.viewName, viewConfig],
    );
    // END FOR SHOW VIEW
    const valuesets = useSelector((state: RootState) => state.valueSets);
    const entities = useSelector(getEntities);
    const fc = useContext<ReturnType<typeof evaluateContext2>>((props.contextType === 'entity-view'
        ? props.type === 'EDIT'
            ? editEntityFormContext
            : showEntityFormContext
        : formContext) as any);
    const evalContext = createRootContext({
        ...entityPreprocessValuesForEval(
            props.contextType === 'entity-view' && props.type === 'SHOW' ? valuesToUse : fc.fieldValues,
            props.contextType === 'entity-view'
                ? props.fieldsInExpressions
                : props.formDefinition.fields.map(f => f.id),
            props.contextType === 'entity-view'
                ? props.valueset1Fields
                : getValueset1Fields(props.formDefinition.fields),
            entities,
            {
                viewContext:
                    props.contextType === 'start-form'
                        ? 'START_FORM'
                        : props.contextType === 'existing-task-form'
                        ? 'PROCESS_TASK'
                        : 'ENTITY',
                dateFormat: 'MM/dd/yyyy', // can hook this up later
            },
            valuesets,
        ),
        ...entityAndConceptLookupUtils(entities, viewConfig, valuesets),
    });
    return evalContext;
};
const ExpressionTestValues: React.SFC<ExpressionTestAreaProps> = props => {
    const evalContext = useGetEvalContext(props);
    const evalContextValues = Object.entries(evalContext)
        .filter(([key, value]) => !isFunction(value))
        .reduce((a, [k, v]) => ({ ...a, [k]: v }), {});
    return (
        <JSONEditorDemo
            mode="view"
            json={evalContextValues}
            // onChangeJSON={this.onChangeJSON}
        />
    );
};

const Evaluator: React.SFC<ExpressionTestAreaProps> = props => {
    const [text, setText] = useState('');
    const [evalResult, setEvalResult] = useState('');
    const evalContext = useGetEvalContext(props);
    const evalContextFns = Object.entries(evalContext)
        .filter(([key, value]) => isFunction(value))
        .reduce((a, [k, v]) => ({ ...a, [k]: v }), {});
    return (
        <div style={{ padding: '1em' }}>
            <div style={{ paddingBottom: '1em' }}>
                <ExpansionPanel>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        In addition to the values to the left, the following functions are available:
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <ul>
                            {Object.keys(evalContextFns).map(fnname => (
                                <li key={fnname}>{fnname}</li>
                            ))}
                        </ul>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
            <TextField
                multiline={true}
                rows={2}
                style={{ width: '100%' }}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={e => setText(e.target.value)}
                onBlur={e => setText(e.target.value)}
                value={text}
                label="Expression"
            />
            ["{addSlashes(text)}"]
            <div style={{ paddingTop: '1em' }}>
                <Button
                    onClick={() => {
                        try {
                            setEvalResult(`${evaluateExpression(text, evalContext)}`);
                        } catch (e) {
                            console.error(e);
                            setEvalResult('The expression has an error. Check the console.');
                        }
                    }}
                    color="primary"
                    variant="contained"
                >
                    Evaluate
                </Button>
            </div>
            <Divider style={{ marginTop: '.5em', marginBottom: '.5em' }} />
            {evalResult}
        </div>
    );
};

const ExpressionTestArea: React.SFC<ExpressionTestAreaProps & { belowEvaluator?: JSX.Element }> = ({
    belowEvaluator,
    ...props
}) => {
    return (
        <Card>
            <div style={{ padding: '1em' }}>Test new expressions against the live values in the form below.</div>
            <SplitPane split="vertical">
                <Pane initialSize="50%">
                    <ExpressionTestValues {...props} />
                </Pane>
                <div>
                    <Evaluator {...props} />
                    {belowEvaluator}
                </div>
                {/* <Pane initialSize="25%" minSize="10%" maxSize="500px">
    Using a Pane allows you to specify any constraints directly
</Pane> */}
            </SplitPane>
        </Card>
    );
};
export default ExpressionTestArea;
