import React from 'react';
import TextField from './TextField/TextField';
import { withStyles, Theme } from '@material-ui/core/styles';
import DebouncedTextInput from './DebouncedTextInput';
import MaskedInput, { conformToMask } from 'react-text-mask';
import FieldTitle from './aor/FieldTitle';
import getFormHelperTextProps from 'fieldFactory/util/getFormHelperTextProps';
import uniqueId from 'lodash/uniqueId';
import memoizeOne from 'memoize-one';
import formatError from 'fieldFactory/util/formatError';

type Input = any; // tslint:disable-line
type Meta = any; // tslint:disable-line

interface Classes {}
const styles: (theme: Theme) => Classes = (theme: Theme) => ({});

interface TextInputProps {
    randomizeNameAsBrowserAutocompleteHack?: boolean;
    noDebounce?: boolean;
    classes: { [key in keyof Classes]: string };
    className?: string;
    isRequired?: boolean;
    options?: {};
    source: string;
    resource: string;
    input?: Input;
    meta: Meta;
    label?: string;
    addField?: boolean;
    elStyle?: {};
    validate: Function | Function[];
    disabled?: boolean;
    fullWidth?: boolean;
    ariaInputProps?: {};
    renderLabel?: boolean;
    mask?: string;
    type?: string;
}

function TextMaskCustom(props) {
    const { inputRef, mask, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={mask}
            placeholderChar={'\u2000'}
            showMask={true}
        />
    );
}

interface MaskableTextInputProps {
    value: string;
    mask?: string;
    children: (
        args:
            | {
                  useMaskedInput: false;
                  textInputRef: React.RefObject<HTMLInputElement>;
              }
            | {
                  useMaskedInput: true;
                  isEmptyMaskedValue: (value: string) => boolean;
                  mask: (string | RegExp)[];
                  textInputRef: React.RefObject<HTMLInputElement>;
              },
    ) => JSX.Element | null;
}
export class MaskableTextInput extends React.Component<MaskableTextInputProps> {
    textInput = React.createRef<HTMLInputElement>();
    _getMaskArray = memoizeOne(mask => {
        if (mask) {
            return [
                ...mask.split('').map(c => {
                    switch (c.toLowerCase()) {
                        case 'a':
                            return /[a-zA-Z]/;
                        case '9':
                            return /\d/;
                        case '*':
                            return /./;
                        default:
                            return c;
                    }
                }),
            ];
        }
        return undefined;
    });
    _getEmptyMaskedValue = memoizeOne(mask =>
        conformToMask('', this._getMaskArray(mask), {
            placeholderChar: '\u2000',
            guide: true,
        }),
    );
    isEmptyMaskedValue = (value: string) => {
        return value === this._getEmptyMaskedValue(this.props.mask).conformedValue;
    };
    useMask = (value = this.props.value) => {
        const { mask } = this.props;
        if (!mask) {
            return false;
        }
        const {
            // conformedValue,
            meta: { someCharsRejected },
        } = conformToMask(value || '', this._getMaskArray(mask), {
            placeholderChar: '\u2000',
            guide: true,
        });
        const res = mask && !someCharsRejected;
        return res;
    };
    getSnapshotBeforeUpdate(prevProps: MaskableTextInputProps) {
        const inputIsFocused = this.textInput.current === document.activeElement;
        return inputIsFocused;
    }
    componentDidUpdate(prevProps: MaskableTextInputProps, prevState, inputWasFocused) {
        if (inputWasFocused && this.useMask(prevProps.value) !== this.useMask()) {
            // because we had to swap out inner components, we need to return focus.
            // we have to swap inputComponents to make masks change because react-text-mask is buggy and unmaintained.
            // :(
            this.textInput.current.focus();
        }
    }
    render() {
        const useMaskedInput = this.useMask();
        if (useMaskedInput) {
            return this.props.children({
                mask: this._getMaskArray(this.props.mask),
                useMaskedInput,
                textInputRef: this.textInput,
                isEmptyMaskedValue: this.isEmptyMaskedValue,
            });
        }
        return this.props.children({
            useMaskedInput: false,
            textInputRef: this.textInput,
        });
    }
}

export class TextInput extends React.Component<TextInputProps> {
    private errorMessageId: string = uniqueId('textinput');
    private uniqueNameToThrowOffChromeAutoFill = new Date().toISOString();
    static defaultProps = {
        options: {},
        fullWidth: true,
        addField: true,
        disabled: false,
        ariaInputProps: {},
        renderLabel: true,
    };
    render() {
        const {
            className,
            input,
            meta,
            isRequired,
            label,
            options,
            source,
            resource,
            disabled,
            ariaInputProps,
            renderLabel,
            fullWidth,
            type,
            noDebounce,
            mask,
            randomizeNameAsBrowserAutocompleteHack = true,
            // classes,
        } = this.props;
        if (typeof meta === 'undefined') {
            throw new Error(
                "The LongTextInput component wasn't called within a redux-form <Field>. " +
                    'Did you decorate it and forget to add the addField prop to your component? ' +
                    'See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component' +
                    ' for details.',
            );
        }
        const { touched, error } = meta;
        const _inputProps: {} = {
            'aria-label': label,
            autoComplete: 'never',
            ...ariaInputProps,
        };
        if (randomizeNameAsBrowserAutocompleteHack) {
            _inputProps['name'] = this.uniqueNameToThrowOffChromeAutoFill;
        }
        if (touched && error) {
            _inputProps['aria-errormessage'] = this.errorMessageId;
        }
        const renderInput = ({ value, onChange, onBlur }) => (
            <MaskableTextInput value={value} mask={mask}>
                {maskable => {
                    const valueIsEmptyMask = value => maskable.useMaskedInput && maskable.isEmptyMaskedValue(value);
                    const inputProps = maskable.useMaskedInput ? { ..._inputProps, mask: maskable.mask } : _inputProps;
                    const callChangeHandler = (handleEvent: (e) => void) => e => {
                        const valueWasDisplayedWithoutFittingMask =
                            !maskable.useMaskedInput && e.target.value !== value;
                        if (valueWasDisplayedWithoutFittingMask || valueIsEmptyMask(e.target.value)) {
                            handleEvent('');
                        } else {
                            handleEvent(e);
                        }
                    };
                    const selectStart = e => {
                        if (valueIsEmptyMask(e.target.value)) {
                            e.target.setSelectionRange(0, 0);
                        }
                    };
                    return (
                        <TextField
                            inputRef={maskable.textInputRef}
                            type={type}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                inputComponent: maskable.useMaskedInput ? TextMaskCustom : undefined,
                                inputProps,
                            }}
                            {...input}
                            onFocus={selectStart}
                            onKeyUp={selectStart}
                            onMouseUp={selectStart}
                            onKeyDown={selectStart}
                            onBlur={!mask ? onBlur : callChangeHandler(onBlur)}
                            onChange={!mask ? onChange : callChangeHandler(onChange)}
                            fullWidth={fullWidth}
                            value={value || ''}
                            className={className}
                            margin="none"
                            label={
                                renderLabel && (
                                    <FieldTitle
                                        label={label}
                                        source={source}
                                        resource={resource}
                                        isRequired={isRequired}
                                    />
                                )
                            }
                            keepCharPositions={true}
                            error={!!(touched && error)}
                            helperText={touched && error ? `Error: ${formatError(error)}` : undefined}
                            disabled={disabled}
                            FormHelperTextProps={getFormHelperTextProps(inputProps)}
                            {...options}
                        />
                    );
                }}
            </MaskableTextInput>
        );
        if (noDebounce) {
            return renderInput(input);
        }
        return <DebouncedTextInput emptyInitialValue="" input={input} renderInput={renderInput} />;
    }
}
export default withStyles(styles as any)(TextInput); // tslint:disable-line
