/* eslint no-useless-computed-key: 0 */
import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../reducers/rootReducer';
import { Collapse, CardActions, IconButton, Checkbox, Button, TextField, CircularProgress } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Transform from '@material-ui/icons/Transform';
import classnames from 'classnames';
import FormSaveNotifierTrigger from 'formSaveNotifier/components/Trigger';
import { GenericListWithPopovers } from '../../components/generics/genericList';
import getListRenderer from '../../components/generics/genericList/renderList';
import compose from 'recompose/compose';
import pure from 'recompose/pure';
import { withStyles } from '@material-ui/core/styles';
import { withDateFormat } from '../../fieldFactory/dateFormat/Broadcasts';
import { toggleBulkActionsButton } from '../../components/ToggleBulkActionButton';
import ProcessSelectActionDialog from '../../bpm2/ProcessSelectActionDialog';
import * as config from '../../config';
import { crudCreate as crudCreateAction } from 'sideEffect/crud/create/actions';
import { getProcDefFromProcInstId } from '../../bpm2/util';
import { getIsAdmin, getIsAdminFromProcess } from './TaskDetail/Task';
import InlineDeleteButton from 'components/generics/button/InlineDeleteButton';
import FormControlLabel from 'fieldFactory/input/components/mui/FormControlLabel';
import { storageController } from 'storage';

const getLazyR = (type: 'move') => (appCaseId: number | string, selectedData: {}) => () =>
    fetch(`${config.BACKEND_BASE_URL}api/bpm/app-cases/${appCaseId}/${type}-comments`, {
        method: 'PUT', // posterity: for copy, use POST
        body: JSON.stringify({
            commentIds: Object.keys(selectedData),
        }),
        credentials: 'same-origin',
        headers: new Headers({
            Authorization: `Bearer ${storageController.getToken()}`,
            Cookie: `${window.document.cookie}`,
            Accept: 'application/json',
            'Content-type': 'application/json',
        }),
    });

const mapStateToProps = (state: RootState, props) => {
    return {
        printMode: state.printMode,
        key: `${state.printMode}`,
        isAdmin: props.taskId ? getIsAdmin(state, props.taskId) : getIsAdminFromProcess(state, props.processId),
        businessKey: getProcDefFromProcInstId(state, props.processId).fold(null, pd => pd.key),
    };
};

const styles = theme => ({
    actions: {
        display: 'flex',
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        marginLeft: 'auto',
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    headerCell: {
        position: 'sticky' as 'sticky',
        zIndex: 3,
        backgroundColor: theme.palette.background.paper,
        top: 0,
    },
    listResults: {
        position: 'relative' as 'relative',
        overflowY: 'auto' as 'auto',
        maxHeight: 270,
        ['@media print']: {
            overflowY: 'unset' as 'unset',
            maxHeight: 'unset' as 'unset',
        },
    },
});
interface CommentsControllerProps extends ReturnType<typeof mapStateToProps> {
    title: React.ReactNode;
    tasks: RootState['bpm']['tasks']['byId'];
    processId: string;
    taskId?: string;
    data: Comment[];
    taskSpecificData: Comment[];
    displayOnEmpty: string;
    // formatDate: (date: string) => string;
    dateFormat: string;
    classes: {
        [k in keyof ReturnType<typeof styles>]: string;
    };
    crudCreate: typeof crudCreateAction;
}
interface CommentsControllerState {
    text: string;
    open: boolean;
    showAllComments: boolean;
    location: { pathname: string; search: string };
    updateList: number;
    selectedComments: {};
    showBulkActions: boolean;
    disableSubmit: boolean;
}
class CommentsController extends Component<CommentsControllerProps, CommentsControllerState> {
    static defaultProps = {
        taskId: undefined,
        displayOnEmpty: 'No Notes',
        data: [],
    };
    constructor(props: CommentsControllerProps) {
        super(props);
        this.state = {
            open: this.props.printMode,
            text: '',
            showAllComments: true,
            location: {
                pathname: '/ProcessNote',
                search: '',
            },
            updateList: 0,
            selectedComments: {},
            showBulkActions: false,
            disableSubmit: false,
        };
    }
    toggleBulkActions = () => {
        this.setState(state => ({
            ...state,
            showBulkActions: !state.showBulkActions,
            selectedComments: {},
        }));
    };
    disableSubmit = (cb?: () => void) => {
        this.setState({ disableSubmit: true }, cb);
    };
    undisableSubmit = (cb?: () => void) => {
        this.setState({ disableSubmit: false }, cb);
    };
    updateList = () =>
        this.setState(prevState => {
            return {
                ...prevState,
                selectedComments: {},
                updateList: prevState.updateList + 1,
            };
        });
    submit = e => {
        // this callback is called in the Saga IFF the submission succeeds.
        const resetForm = () =>
            this.setState(state => ({
                text: '',
                updateList: state.updateList + 1,
                disableSubmit: false,
            }));
        this.disableSubmit(() =>
            this.props.crudCreate({
                resource: 'ProcessNote',
                data: {
                    processInstanceId: this.props.processId || null,
                    taskInstanceId: this.props.taskId || null,
                    note: this.state.text,
                },
                cb: resetForm,
                errorsCbs: {
                    ['*']: () => this.undisableSubmit(),
                },
            }),
        );
    };
    handleChange = event => {
        this.setState({
            text: event.target.value,
        });
    };
    handleCommentViewChange = () => {
        this.setState({
            showAllComments: !this.state.showAllComments,
            updateList: this.state.updateList + 1,
        });
    };
    getFilters = showAllComments => {
        if (this.props.taskId && !showAllComments) {
            return {
                taskInstanceId: this.props.taskId,
            };
        }
        return {
            processInstanceId: this.props.processId,
        };
    };
    rowsSelected = () => Object.keys(this.state.selectedComments).length;
    someDataSelected = () => this.rowsSelected() > 0;
    render() {
        const { showBulkActions, updateList, disableSubmit } = this.state;
        const { taskId, classes, title, businessKey, printMode, isAdmin } = this.props;
        return (
            <div>
                {!this.state.open && (
                    <CardActions style={{ padding: '1%' }} className={classes.actions} disableSpacing={true}>
                        {title}
                        <IconButton
                            onClick={() => this.setState(state => ({ ...state, open: !state.open }))}
                            className={classnames(classes.expand, {
                                [classes.expandOpen]: this.state.open,
                            })}
                            aria-expanded={this.state.open}
                            aria-label="Show more - Notes"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    </CardActions>
                )}
                {this.state.open && (
                    <div key={`${updateList}`} style={{ padding: '1%' }}>
                        <ProcessSelectActionDialog<'move'>
                            itemName="note"
                            initialSearch={
                                businessKey
                                    ? `?filter=%7B"assignee_ANY"%3Atrue%2C"processInstance.businessKey"%3A"${businessKey}"%7D` // tslint:disable-line
                                    : undefined
                            }
                            getLazyRequests={{
                                move: getLazyR('move'),
                            }}
                            selectedData={this.state.selectedComments}
                            onSuccess={this.updateList}
                            selectedDataStaysOnSuccess={true}
                            render={({ getOpenPSDialog, closePSDialog }) => (
                                <GenericListWithPopovers
                                    reference="ProcessNote"
                                    config={null}
                                    title={'Notes'}
                                    useCard={false}
                                    multiSelectable={showBulkActions}
                                    resultHeadingText={null}
                                    filter={this.getFilters(this.state.showAllComments)}
                                    renderActions={actionProps => (
                                        <CardActions
                                            style={{ zIndex: 2, padding: 0, display: 'inline-block', float: 'right' }}
                                        >
                                            {taskId ? (
                                                <FormControlLabel
                                                    htmlFor="showallcasenotescheckbox"
                                                    control={
                                                        <Checkbox
                                                            id="showallcasenotescheckbox"
                                                            inputProps={{
                                                                'aria-label': 'Show all Case notes',
                                                            }}
                                                            checked={this.state.showAllComments}
                                                            onChange={this.handleCommentViewChange}
                                                            // style={{margin: '-10px'}}
                                                        />
                                                    }
                                                    label={'Show all Case notes'}
                                                    style={{ marginLeft: '10px' }}
                                                />
                                            ) : null}
                                            {isAdmin &&
                                                actionProps.listHasData &&
                                                toggleBulkActionsButton(
                                                    this.toggleBulkActions,
                                                    showBulkActions,
                                                    this.someDataSelected(),
                                                )}
                                            {showBulkActions && this.someDataSelected() ? (
                                                <Button
                                                    variant="text"
                                                    color="primary"
                                                    onClick={getOpenPSDialog('move')}
                                                >
                                                    Move ({this.rowsSelected()}) Note
                                                    {this.rowsSelected() > 1 ? 's' : ''}
                                                    <Transform />
                                                </Button>
                                            ) : null}
                                            <IconButton
                                                onClick={() =>
                                                    this.setState(state => ({ ...state, open: !state.open }))
                                                }
                                                className={classnames(classes.expand, {
                                                    [classes.expandOpen]: this.state.open,
                                                })}
                                                aria-expanded={this.state.open}
                                                aria-label="Collapse - Notes"
                                            >
                                                <ExpandMoreIcon />
                                            </IconButton>
                                        </CardActions>
                                    )}
                                    hasCreate={false}
                                    updateUrlFromFilter={false}
                                    viewName={'ProcessNoteList'}
                                    location={this.state.location}
                                    showFilters={false}
                                    formId={null}
                                    selectedData={this.state.selectedComments}
                                    resource={'ProcessNote'}
                                    showImmediately={true}
                                    fakePush={location => {
                                        this.setState(state => ({ ...state, location }));
                                    }}
                                    perPage={printMode ? '100' : undefined}
                                    renderList={r =>
                                        getListRenderer(
                                            {
                                                root: classes.listResults,
                                                headerCell: classes.headerCell,
                                            },
                                            {},
                                        )({
                                            ...r,
                                            isBulkSelectableRecord: data => !data['taskInstanceId'], // tslint:disable-line
                                            onRowSelectBulk: showBulkActions
                                                ? (selected, allData) => {
                                                      this.setState({
                                                          selectedComments: Object.assign(
                                                              {},
                                                              ...selected.map(data => ({ [data.id]: data })),
                                                          ),
                                                      });
                                                  }
                                                : undefined,
                                            renderAtRowEnd:
                                                showBulkActions && isAdmin
                                                    ? (rargs, record) => [
                                                          <InlineDeleteButton
                                                              id={record.id}
                                                              resource={rargs.resource}
                                                          />,
                                                      ]
                                                    : undefined,
                                        })
                                    }
                                />
                            )}
                        />
                    </div>
                )}
                <Collapse in={this.state.open} timeout={{ enter: 2, exit: 2 }} unmountOnExit={true}>
                    <div>
                        <div style={{ padding: '1em', paddingTop: 0 }}>
                            <TextField
                                name="comment-input"
                                placeholder="Enter Note"
                                multiline={true}
                                style={{ width: '100%' }}
                                value={this.state.text}
                                onChange={this.handleChange}
                            />
                            <div style={{ textAlign: 'right', paddingTop: 16 }}>
                                <Button
                                    variant="contained"
                                    disabled={!this.state.text || disableSubmit}
                                    onClick={this.submit}
                                >
                                    Submit
                                    {disableSubmit ? (
                                        <CircularProgress
                                            style={{
                                                marginLeft: '1em',
                                                height: 20,
                                                width: 20,
                                            }}
                                        />
                                    ) : null}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Collapse>
                <FormSaveNotifierTrigger when={Boolean(this.state.text)} />
            </div>
        );
    }
}

const Comments = compose(
    connect(
        mapStateToProps,
        { crudCreate: crudCreateAction },
    ),
    pure,
    withDateFormat,
    withStyles(styles),
)(CommentsController);

export default Comments;
