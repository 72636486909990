import React from 'react';

export const FieldFactoryContext = React.createContext(null);

export const FieldFactorySubscriber = FieldFactoryContext.Consumer;

export const withFieldFactory = BaseComponent => props => (
    <FieldFactoryContext.Consumer>
        {fieldFactory => <BaseComponent {...props} fieldFactory={fieldFactory} />}
    </FieldFactoryContext.Consumer>
);
