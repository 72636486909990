import React, { Suspense } from 'react';

const MapComponent = React.lazy(() => import('./Component'));

interface ComponentProps {}
const Component: React.FunctionComponent<ComponentProps> = props => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <MapComponent />
        </Suspense>
    );
};
export default { Component };
