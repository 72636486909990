import * as t from 'io-ts';
import getErrorFromConfig from '../getErrorFromConfig';

const forSearchConfig = t.partial({
    prefilter: t.any,
    visible: t.union([t.boolean, t.literal('default'), t.literal('disabled_if_prefiltered')]),
});
type ForSearchConfig = t.TypeOf<typeof forSearchConfig>;

const forSearchExample: ForSearchConfig = {
    prefilter: 'fixed value',
    visible: false,
};

const forSearchConfigValidation = getErrorFromConfig(
    forSearchConfig,
    `Invalid configuration for search field. Example: ${JSON.stringify(forSearchExample)}`,
    `Invalid json. Example: ${JSON.stringify(forSearchExample)}`,
);
export default forSearchConfigValidation;
