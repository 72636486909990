import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import CustomShow from './Show';
import viewConfigPropType from '../../propTypes/viewConfig';
import ShowForm from './ShowForm';
import { ShowActions } from '../viewActions/ActionsWithOverrides';

const GenericShow = props => (
    <CustomShow {...props} key={((props.match || {}).params || {}).id}>
        <ShowForm {...props} />
    </CustomShow>
);

GenericShow.propTypes = {
    actions: PropTypes.node,
    resource: PropTypes.string.isRequired,
    viewName: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
    viewConfig: viewConfigPropType.isRequired,
};

GenericShow.defaultProps = {
    actions: <ShowActions />,
};

export default compose(connect(({ viewConfig }, ownProps) => ({ ...ownProps, viewConfig })))(GenericShow);
