import { crudGetList as crudGetListAction } from 'sideEffect/crud/getList/actions';
import ViewConfig, { FieldViewField } from '../../../reducers/ViewConfigType';
import {
    getDataTypeForFieldExpr,
    isFieldViewField,
    getRefEntityName,
    getDefaultSort,
    getCustomViewName,
    getAllPrefilters,
    getPathBackFromFieldPath,
    getLongestRefonePathInPath,
} from '../utils/viewConfigUtils';
import { REF_MANY_PER_PAGE } from '../../../config';
import { fromNullable } from 'fp-ts/lib/Option';
type FirstArgument<T> = T extends (arg1: infer U, ...args: any[]) => any ? U : any; // tslint:disable-line

const prefetchLists = (
    viewConfig: ViewConfig,
    viewName: string,
    id: string | number,
    crudGetList: (arg: FirstArgument<typeof crudGetListAction>) => void,
    tabKey?: string,
) => {
    // fetch all data initially
    const allFields: FieldViewField[] = ([] as FieldViewField[]).concat(
        Object.values(viewConfig.views[viewName].fields || {}).filter(isFieldViewField),
        ...fromNullable(viewConfig.views[viewName])
            .mapNullable(v => v.tabs)
            .mapNullable(tabs => (tabKey ? [tabs[tabKey]] : Object.values(tabs)))
            .map(tl => tl.map(t => Object.values(t.fields).filter(isFieldViewField)))
            .getOrElse([]),
    );
    const referenceManyFields = allFields.filter(
        f => getDataTypeForFieldExpr(viewConfig, f.entity, f.field, 'POP_LAST') === 'REFMANY',
    );

    referenceManyFields.forEach(f => {
        const leadingRefOne = getLongestRefonePathInPath(viewConfig, viewConfig.views[viewName].entity, f.field);
        if (leadingRefOne) {
            // this field is dynamic depending on the reference. We can't prefetch based on the dynamic field without knowing the referred data.
            // (this doesn't mean the below wouldn't work, just our components expect to filter based on the reference in the dynamic case)
            return;
        }
        const reference = getRefEntityName(viewConfig, f.entity, f.field, 'POP_LAST');

        const entity = viewConfig.views[viewName].entity;
        // to work, this has to correspond to the permanentFilter in RefManyMultiSelect
        const target = getPathBackFromFieldPath(viewConfig, entity, f.field) as string;

        const viewNameOvr = getCustomViewName('LIST')(reference, viewConfig, f.config);
        crudGetList({
            resource: reference,
            pagination: {
                page: 1,
                perPage: REF_MANY_PER_PAGE,
            },
            filter: {
                [f.field === 'revisions' ? `${target}Id` : `${target}.id`]: id,
                ...getAllPrefilters(viewConfig, viewConfig.views[viewNameOvr].entity, viewNameOvr),
            },
            sort: getDefaultSort(viewConfig, viewNameOvr) || { field: 'id', order: 'DESC' },
            view: viewNameOvr,
        });
    });
};

export default prefetchLists;
