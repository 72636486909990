import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../../../src/reducers/rootReducer';
import { TextField } from '@material-ui/core';
import { fromNullable } from 'fp-ts/lib/Option';

export interface TaskOutcomeProps {
    outcome: string;
}
const makeMapStateToProps = () => {
    // create any caches/selectors in this scope
    const mapStateToProps = (state: RootState, props: TaskOutcomeProps) => {
        return {
            label: fromNullable(state.viewConfig)
                .mapNullable(vc => vc.entities.TaskInstance)
                .mapNullable(ti => ti.fields.outcome)
                .mapNullable(o => o.label)
                .getOrElse('Selected Outcome'),
        };
    };
    return mapStateToProps;
};
interface TaskOutcomeComponentProps extends TaskOutcomeProps, ReturnType<ReturnType<typeof makeMapStateToProps>> {}

class TaskOutcomeComponent extends React.Component<TaskOutcomeComponentProps> {
    render() {
        const { outcome, label } = this.props;
        return (
            <div>
                <TextField
                    margin="none"
                    value={outcome || 'Completed'}
                    disabled={true}
                    id="selectedOutcome"
                    label={label.charAt(0).toUpperCase() + label.slice(1)}
                    InputLabelProps={{ disabled: false }}
                />
            </div>
        );
    }
}

const TaskOutcome: React.ComponentType<TaskOutcomeProps> = connect(makeMapStateToProps)(TaskOutcomeComponent);

export default TaskOutcome;
