import * as dca from './actions';
import { RootAction } from 'actions/rootAction';
import { getType } from 'typesafe-actions';
import { Dashboard, Widget } from '../types';
import { Subtract } from 'utility-types';
import { RemoteData, initial, success, pending, failure } from '@devexperts/remote-data-ts';

interface WidgetMap {
    [widgetId: string]: Widget;
}
// convert the WidgetArray to a mapping indexed by the widget id
type DashboardConfigWithWidgetMap = Subtract<
    Dashboard['dashboardConfig'],
    Pick<Dashboard['dashboardConfig'], 'widgets'>
> & {
    widgets: WidgetMap;
};
interface DashboardConfigState {
    configs: {
        [displayName: string]: {
            readyForWidgetsToDisplay: boolean;
            dashboardConfig: DashboardConfigWithWidgetMap;
        } & Pick<Dashboard, 'hideConfigChange' | 'id' | 'displayName'>;
    };
    allDashboardNames: RemoteData<Error, string[]>;
}
const initialState = { configs: {}, allDashboardNames: initial };

export default function DashboardConfigReducer(
    state: DashboardConfigState = initialState,
    action: RootAction,
): DashboardConfigState {
    switch (action.type) {
        case getType(dca.loadAllDashboards): {
            return {
                ...state,
                allDashboardNames: pending,
            };
        }
        case getType(dca.loadAllDashboardsSuccess):
            // case DashboardConfigEventType.ConfigLoadSuccess:
            const allDashboardNames = action.payload.map(d => d.displayName);
            allDashboardNames.sort();
            const configs = action.payload.reduce(
                (prev, curr) => {
                    prev[curr.displayName] = {
                        readyForWidgetsToDisplay: false,
                        hideConfigChange: curr.hideConfigChange,
                        dashboardConfig: {
                            ...curr.dashboardConfig,
                            widgets: curr.dashboardConfig.widgets.reduce(
                                (prev, curr) => {
                                    prev[curr.id] = curr;
                                    return prev;
                                },
                                {} as WidgetMap,
                            ),
                        },
                        id: curr.id,
                        displayName: curr.displayName,
                    };
                    return prev;
                },
                { ...state.configs },
            );
            return {
                ...state,
                allDashboardNames: success(allDashboardNames),
                configs,
            };
        case getType(dca.loadAllDashboardFailure): {
            return {
                ...state,
                allDashboardNames: failure(action.error),
            };
        }
        default:
            return state;
    }
}
