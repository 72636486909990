import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Button, IconButton } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Warn from '@material-ui/icons/WarningOutlined';
import { Card } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getProcessDefinitions as getProcessDefinitionsAction } from 'bpm/processDefinitions/actions';
import { load as getViewConfigAction } from 'viewConfig/actions';
import { RootState } from 'reducers/rootReducer';
import { fromNullable } from 'fp-ts/lib/Option';
import Clear from '@material-ui/icons/Clear';
import { clearImpersonation, impersonateUser } from 'impersonate/actions';
import { storageController } from 'storage';
import { push as pushAction } from 'connected-react-router';
import { syncedActionsController } from 'configureStore/syncedActionsController';
import { clearImpersonatingStorage } from 'impersonate/storage';

interface ImpersonateBannerComponentProps {}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        card: {
            display: 'flex',
            backgroundColor: theme.palette.background.paper,
            padding: '.5em 1em',
            borderRadius: 0,
            borderLeft: '8px solid ' + theme.palette.error.main,
            borderRight: '8px solid ' + theme.palette.error.main,
            borderBottom: '1px solid grey',
        },
        heading: {
            marginRight: '1em',
            marginLeft: '1em',
            flex: 1,
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
    }),
);

const ImpersonateBanner = (props: ImpersonateBannerComponentProps) => {
    const dispatch = useDispatch();
    const impersonating = useSelector((state: RootState) => state.impersonating);
    const viewConfigCurrentUser = useSelector((state: RootState) => state.viewConfig && state.viewConfig.user);
    const impersonatedUserTitle = useSelector((state: RootState) => {
        if (impersonating.type === 'success' || impersonating.type === 'pending' || impersonating.type === 'error') {
            if (viewConfigCurrentUser && impersonating.userId === viewConfigCurrentUser.id) {
                return viewConfigCurrentUser.title || viewConfigCurrentUser.login;
            }
            return fromNullable(state.admin.entities['User'])
                .mapNullable(ub => ub[impersonating.userId])
                .map(user => user.title || (user as any).login)
                .getOrElse(null);
        }
        return null;
    });
    const classes = useStyles(props);
    if (impersonating.type === 'success' || impersonating.type === 'error' || impersonating.type === 'pending') {
        return (
            <div className={classes.root}>
                <div className="casetivity-off-screen" aria-live="assertive" aria-atomic="true">
                    {impersonating.type === 'pending'
                        ? `Attempting to impersonate ${impersonatedUserTitle}`
                        : impersonating.type === 'error'
                        ? `Failed to impersonate ${impersonatedUserTitle}`
                        : `You are now impersonating ${impersonatedUserTitle}`}
                </div>
                <Card key="impersonate-back-to-user" className={classes.card}>
                    <div style={{ height: '100%', width: '.5em', backgroundColor: 'red' }} />
                    {impersonating.type === 'pending' ? (
                        <Typography className={classes.heading}>
                            Attempting to impersonate {impersonatedUserTitle}...
                        </Typography>
                    ) : impersonating.type === 'error' ? (
                        <React.Fragment>
                            <Typography className={classes.heading}>
                                Failed to impersonate {impersonatedUserTitle}
                                {!impersonating.error.status
                                    ? ': Network Error'
                                    : `: ${impersonating.error.status} Error`}{' '}
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        dispatch(impersonateUser(impersonating.userId));
                                    }}
                                >
                                    Retry?
                                </Button>
                            </Typography>
                            <IconButton
                                onClick={() => {
                                    dispatch(clearImpersonation());
                                }}
                                style={{ padding: 2 }}
                            >
                                <Clear />
                            </IconButton>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Typography className={classes.heading}>
                                <Warn style={{ marginBottom: '-4px' }} /> You are now impersonating{' '}
                                {impersonatedUserTitle}
                            </Typography>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                    // redirect to a 'safe' route where we won't have permission errors when we switch users.
                                    dispatch(pushAction('/_temp'));
                                    storageController.stopImpersonating();
                                    clearImpersonatingStorage();
                                    (syncedActionsController.trigger as any)(clearImpersonation());
                                    dispatch(clearImpersonation());
                                    dispatch(getViewConfigAction(false, impersonating.currentLocation));
                                    dispatch(getProcessDefinitionsAction(false));
                                }}
                            >
                                Back to {impersonating.previousUserTitle}
                            </Button>
                        </React.Fragment>
                    )}
                </Card>
            </div>
        );
    }
    return (
        <div>
            <div className="casetivity-off-screen" aria-live="assertive" aria-atomic="true">
                Not impersonating
            </div>
        </div>
    );
};

export default ImpersonateBanner;
