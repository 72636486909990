import React, { useEffect, useReducer } from 'react';
import FormSaveNotifier from '../FormSaveNotifier';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import produce from 'immer';

type FormCountAction =
    | {
          type: 'inc';
          formId: string;
      }
    | {
          type: 'dec';
          formId: string;
      }
    | {
          type: 'reset';
      };
type FormSaveNotifierContext = React.Dispatch<FormCountAction>;

export const formSaveNotifierContext = React.createContext<FormSaveNotifierContext>(() => {
    console.error('formSaveNotifier dispatch method called outside of Provider');
});
const FormSaveNotifierProvider = props => {
    const location = useSelector((state: RootState) => {
        return state.router.location.pathname as string;
    });
    const [dirtyForms, dispatch] = useReducer((state: { [formId: string]: true }, action: FormCountAction) => {
        switch (action.type) {
            case 'dec': {
                const { formId } = action;
                if (state[formId]) {
                    return produce(state, draftState => {
                        delete draftState[formId];
                        return draftState;
                    });
                }
                return state;
            }
            case 'inc': {
                const { formId } = action;
                if (!state[formId]) {
                    return {
                        ...state,
                        [formId]: true,
                    };
                }
                return state;
            }
            case 'reset':
                return {};
        }
    }, {});
    useEffect(() => {
        // reset on location change.
        dispatch({
            type: 'reset',
        });
    }, [location]);
    const hasDirtyForms = Object.keys(dirtyForms).length > 0;
    return (
        <formSaveNotifierContext.Provider value={dispatch}>
            <FormSaveNotifier key={hasDirtyForms ? '1' : '2'} when={hasDirtyForms} />
            {props.children}
        </formSaveNotifierContext.Provider>
    );
};
export default FormSaveNotifierProvider;
