import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import { RootState } from '../../../reducers/rootReducer';
import { createSelector } from 'reselect';
import { setAsTopView, unsetAsTopView } from '../../../popoverStackManagement/actions';

export const createIsPopoverSelector = () =>
    createSelector(
        (state: RootState) => state.viewStack,
        (_, props) => props.formId,
        (viewStack, formId) => viewStack.indexOf(formId) !== -1,
    );
const makeMapStateToProps = () => {
    const isPopoverSelector = createIsPopoverSelector();
    const mapStateToProps = (state: RootState, props) => ({
        topView: state.viewStack[0],
        // setup for when we need forms to behave diff. as popovers
        isPopover: isPopoverSelector(state, props),
        viewStack: state.viewStack,
    });
    return mapStateToProps;
};

export default compose(
    connect(
        makeMapStateToProps,
        {
            setAsTopView,
            unsetAsTopView,
        },
    ),
    lifecycle({
        componentDidMount() {
            if (this.props.formId) {
                this.props.setAsTopView(this.props.formId);
            }
        },
        shouldComponentUpdate(nextProps: { formId?: string; viewStack: string[] }) {
            if (!nextProps.formId && nextProps.viewStack.length > 0) {
                /*
                The view is the base view, and there are popups rendered. Don't update.
                */
                return false;
            } else if (nextProps.formId && nextProps.formId !== nextProps.viewStack[0]) {
                /*
                The view is not the base view, but it also isn't the top popover view. Don't update.
                */
                return false;
            }
            return true;
            /*
            if (this.props.formId) {
                if (this.props.topView === this.props.formId) {
                    return true;
                }
                return false;
            }
            return true;
            */
        },
        componentWillUnmount() {
            if (this.props.formId) {
                this.props.unsetAsTopView(this.props.formId);
            }
        },
    }),
);
