import React from 'react';
import AttemptRequest from 'components/AttemptRequest';
import * as config from 'config';
import { ServerError, NetworkUnavailable } from 'remoteStatus/one/components/pages';
import DeferredSpinner from 'components/DeferredSpinner';
import { Helmet } from 'react-helmet';
import HtmlToReact from 'html-to-react';
import Clear from '@material-ui/icons/Clear';
import ListView from 'templatePage/templatableComponents/ListView';
import { storageController } from 'storage';
import LinkButton from 'components/links/LinkButton';
import { fromPredicate } from 'fp-ts/lib/Option';
import Popup from 'components/Popup';
import { IconButton } from '@material-ui/core';

const htmlToReactParser = new HtmlToReact.Parser();

const isValidNode = function() {
    return true;
};

const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);

// Order matters. Instructions are processed in
// the order they're defined
const processingInstructions = [
    {
        // This is REQUIRED, it tells the parser
        // that we want to insert our React
        // component as a child
        replaceChildren: true,
        shouldProcessNode: function(node) {
            return (
                node.attribs &&
                (node.attribs['data-listview'] ||
                    node.attribs['data-pushlink'] ||
                    node.attribs['data-dialog-buttontext'])
            );
        },
        processNode: function(node, children, index) {
            const viewName = node.attribs && node.attribs['data-listview'];
            if (viewName) {
                const noClick = (() => {
                    const noClickAttribute = node.attribs && node.attribs['data-listview-noclick'];
                    return noClickAttribute === 'true'
                        ? true
                        : noClickAttribute === 'false'
                        ? false
                        : typeof noClickAttribute === 'boolean'
                        ? noClickAttribute
                        : undefined;
                })();
                return <ListView viewName={viewName} noClick={noClick} />;
            }
            const pushTo = node.attribs && node.attribs['data-pushlink'];
            if (pushTo) {
                const className = node.attribs && node.attribs['data-classname'];
                return (
                    <LinkButton to={pushTo} className={className}>
                        {children}
                    </LinkButton>
                );
            }
            const buttonClass = node.attribs && node.attribs['data-dialog-buttonclass'];
            const dialogText = node.attribs && node.attribs['data-dialog-buttontext'];
            if (dialogText) {
                return (
                    <Popup
                        renderDialogContent={({ closeDialog }) => (
                            <div style={{ position: 'relative', padding: '1em' }}>
                                <IconButton
                                    size="small"
                                    style={{ position: 'absolute', top: 0, right: 0 }}
                                    aria-label="Close dialog"
                                    onClick={closeDialog}
                                >
                                    <Clear />
                                </IconButton>
                                {children}
                            </div>
                        )}
                        renderToggler={({ openDialog }) => (
                            <button type="button" className={buttonClass} onClick={openDialog()}>
                                {dialogText}
                            </button>
                        )}
                    />
                );
            }
            return <div />;
        },
    },
    {
        // Anything else
        shouldProcessNode: function(node) {
            return true;
        },
        processNode: processNodeDefinitions.processDefaultNode,
    },
];

export const processWithReact = (html: string) =>
    htmlToReactParser.parseWithInstructions(html, isValidNode, processingInstructions);

const getLazyR = (textTemplateName: string) => () =>
    fetch(`${config.BACKEND_BASE_URL}api/pages/${textTemplateName}`, {
        method: 'GET',
        credentials: 'same-origin',
        headers: new Headers({
            Accept: 'application/json',
            'Content-type': 'application/json',
            Authorization: `Bearer ${storageController.getToken()}`,
        }),
    });

interface TextTemplatePageProps {
    textTemplateName: string;
    padding?: boolean;
}
const TextTemplatePage: React.SFC<TextTemplatePageProps> = props => {
    const { padding = true } = props;
    return (
        <AttemptRequest<{
            text?: string;
        }>
            key={props.textTemplateName}
            type="external"
            lazyRequest={getLazyR(props.textTemplateName)}
            requestOnMount={true}
            renderer={({ attemptAction }) => state => {
                if (state._tag === 'pending') {
                    return <DeferredSpinner />;
                }
                if (state._tag === 'success') {
                    const title = fromPredicate<string>(Boolean)(state.data.text)
                        .mapNullable(txt => txt.split('\n')[0])
                        .map(fl => fl.trim())
                        .chain(tfl =>
                            fromPredicate<string>((fl: string) => fl.startsWith('<!--') && fl.endsWith('-->'))(
                                tfl,
                            ).map(fl => fl.slice('<!--'.length, '-->'.length * -1)),
                        )
                        .map(i => i.trim())
                        .toNullable();
                    return (
                        <div style={{ width: '100%' }}>
                            {title && (
                                <Helmet>
                                    <title>{title}</title>
                                </Helmet>
                            )}
                            <div style={{ padding: padding ? '1em' : undefined }}>
                                {state.data &&
                                    state.data.text &&
                                    htmlToReactParser.parseWithInstructions(
                                        state.data.text,
                                        isValidNode,
                                        processingInstructions,
                                    )}
                            </div>
                        </div>
                    );
                }
                if (state._tag === 'failure') {
                    if (state.status) {
                        return <ServerError code={state.status} message={state.message} />;
                    }
                    return <NetworkUnavailable retry={attemptAction} />;
                }
                if (state._tag === 'unsubmitted') {
                    return null;
                }
            }}
        />
    );
};

export default TextTemplatePage;
