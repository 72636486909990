import * as React from 'react';
import { Component } from 'react';
import { createElement } from 'react';
import { Provider, connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import { RootState } from './reducers/rootReducer';
import FieldFactoryProvider from './fieldFactory/FieldFactoryProvider';
import DateFormatProvider from './fieldFactory/dateFormat/DateFormatProvider';
import SsgResponsiveLayout from './components/layouts/SsgResponsiveLayout';
import customRoutes from './routes/customRoutes';
import LoginPage from 'auth/components/Login';
import DashboardPage from 'dashboard2/components/DashboardPage';
import DebugAndReportingHelper from './components/DebugAndReportingHelper';
import LBI from './components/LoadBasicInfo';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { routerHistory } from 'configureStore';
import PasswordReset from 'auth/password-reset/components/SelfReset';
import ConsoleErrorCount from 'components/ConsoleErrorCount';
import FormSaveNotifierProvider from 'formSaveNotifier/components/Provider';
import WithEnvConfigGlobalStylesheet from 'components/WriteEnvConfigGlobalStylesheet';
import store from 'configureStore/store';
import WindowTitle from 'components/WindowTitle';

class ScrollToTopComponent extends Component<{
    pathname: string;
}> {
    componentDidUpdate(prevProps: { pathname: string }) {
        if (this.props.pathname !== prevProps.pathname) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        return this.props.children;
    }
}
const LoadBasicInfo = LBI;
const ScrollToTop = connect((state: RootState) => {
    return {
        pathname: state.router.location.pathname,
    };
})(ScrollToTopComponent);

export const InnerApp = props => (
    <MuiPickersUtilsProvider utils={MomentUtils}>
        <DateFormatProvider>
            <FieldFactoryProvider>
                <ConnectedRouter history={routerHistory}>
                    <LoadBasicInfo>
                        <FormSaveNotifierProvider>
                            <WithEnvConfigGlobalStylesheet />
                            <WindowTitle />
                            <ScrollToTop>
                                <div>
                                    <Switch>
                                        <Route
                                            exact={true}
                                            path="/login"
                                            render={({ location }) =>
                                                createElement(LoginPage, {
                                                    location,
                                                })
                                            }
                                        />
                                        <Route
                                            path="/reset/finish"
                                            render={({ location }) =>
                                                createElement(PasswordReset, {
                                                    location,
                                                })
                                            }
                                        />
                                        <Route
                                            path="/"
                                            render={() =>
                                                createElement(SsgResponsiveLayout, {
                                                    dashboard: DashboardPage,
                                                    customRoutes,
                                                })
                                            }
                                        />
                                    </Switch>
                                    <div
                                        style={{
                                            position: 'fixed',
                                            minWidth: '2em',
                                            minHeight: '2em',
                                            bottom: 0,
                                            right: 0,
                                            zIndex: 2147483647,
                                        }}
                                    >
                                        <span style={{ display: 'inline-flex' }}>
                                            <ConsoleErrorCount />
                                            <DebugAndReportingHelper />
                                        </span>
                                    </div>
                                </div>
                            </ScrollToTop>
                        </FormSaveNotifierProvider>
                    </LoadBasicInfo>
                </ConnectedRouter>
            </FieldFactoryProvider>
        </DateFormatProvider>
    </MuiPickersUtilsProvider>
);

class App extends React.Component {
    render() {
        return (
            <Provider store={store}>
                <InnerApp />
            </Provider>
        );
    }
}

export default App;
