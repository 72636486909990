import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import TabCloseIcon from '@material-ui/icons/Clear';
import TabOpenIcon from '@material-ui/icons/FormatLineSpacing';
import { setSidebarVisibility as setSidebarVisibilityAction } from 'actions/aor/uiActions';
import { openTaskDrawer, closeTaskDrawer } from '../../../actions/taskDrawer';
import { withWidth, Drawer } from '@material-ui/core';
import { RootState } from 'reducers/rootReducer';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

const TOGGLE_DRAWER_WIDTH = 256;

interface ToggleDrawerWrapperProps {
    renderDrawer: (arg: { open: boolean }) => JSX.Element | null;
}

function mapStateToProps(state: RootState) {
    return {
        sidebarOpen: state.admin.ui.sidebarOpen,
        taskDrawerOpen: state.bpm.taskDrawer,
    };
}
const dispatches = {
    setSidebarVisibility: setSidebarVisibilityAction,
    openTaskDrawer,
    closeTaskDrawer,
};
type Dispatches = typeof dispatches;

interface ToggleDrawerWrapperComponentProps
    extends ToggleDrawerWrapperProps,
        Dispatches,
        ReturnType<typeof mapStateToProps> {
    width: Breakpoint;
}

class ToggleDrawerWrapperComponent extends React.Component<ToggleDrawerWrapperComponentProps> {
    // we don't use scroll handling value - just use it to update the component on scroll (at which point we recalculate our css)
    state = {
        mounted: false,
        y: 0,
    };
    childDivRef = React.createRef<HTMLDivElement>();
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        /*
            Explaination of setState in componentDidMount
            https://github.com/airbnb/javascript/issues/684#issuecomment-340598319
        */
        this.setState({ mounted: true }); // eslint-disable-line react/no-did-mount-set-state
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = e => {
        if (this.state.mounted) {
            // prevents calling setState on unmounted component
            this.setState({
                y: window.scrollY,
            });
        }
    };

    handleToggleDrawer() {
        const taskDrawerOpen = !this.props.taskDrawerOpen;
        if (taskDrawerOpen) {
            this.props.openTaskDrawer();
        } else {
            this.props.closeTaskDrawer();
        }
    }

    render() {
        const tabWidth = this.props.taskDrawerOpen ? 35 : 40;
        const width = this.props.width;
        const contentStyle: React.CSSProperties = { transition: 'margin-left 350ms cubic-bezier(0.23, 1, 0.32, 1)' };

        const toolbarHeightCss = (() => {
            const childDivElement = this.childDivRef.current;
            if (childDivElement) {
                return childDivElement.getBoundingClientRect().top;
            }
            return 64;
        })();
        if (this.props.taskDrawerOpen) {
            contentStyle.marginLeft = TOGGLE_DRAWER_WIDTH;
        }

        return (
            <div style={width === 'xs' ? {} : contentStyle}>
                <Drawer
                    variant="persistent"
                    open={this.props.taskDrawerOpen}
                    PaperProps={{
                        style: {
                            // height: `calc(100% - ${toolbarHeightCss}px)`, // <- if toolbar is position:fixed
                            height: `calc(100% - ${Math.max(toolbarHeightCss, 0)}px)`,
                            // top: toolbarHeightCss, // <- if toolbar is position:fixed
                            top: Math.max(toolbarHeightCss, 0),
                            overflow: 'visible',
                            zIndex: 10,
                            width: TOGGLE_DRAWER_WIDTH,
                        },
                    }}
                >
                    {this.props.renderDrawer({ open: this.props.taskDrawerOpen })}
                    <div
                        style={{
                            position: 'absolute',
                            bottom: 30,
                            right: -tabWidth,
                            maxHeight: 40,
                            visibility: 'visible',
                        }}
                    >
                        <button
                            style={{
                                width: tabWidth,
                                textAlign: 'right',
                                height: 40,
                                borderLeft: 0,
                                border: 0,
                                paddingLeft: this.props.taskDrawerOpen ? 2 : undefined,
                                borderRadius: '0px 4px 4px 0px',
                                boxShadow: 'rgba(0, 0, 0, 0.13) 3px 3px 4px, rgba(0, 0, 0, 0.13) 3px -1px 4px',
                            }}
                            aria-label="Open Task Drawer"
                            onClick={() => this.handleToggleDrawer()}
                            onMouseUp={
                                e => e.currentTarget.blur()
                                /* kills outline in chrome on mouseup (still works on tabs for accessibility) */
                            }
                        >
                            {this.props.taskDrawerOpen ? <TabCloseIcon /> : <TabOpenIcon />}
                        </button>
                    </div>
                </Drawer>
                <div ref={this.childDivRef}>{this.props.children}</div>
            </div>
        );
    }
}

const enhance = compose(
    connect(
        mapStateToProps,
        dispatches,
    ),
    withWidth({
        initialWidth: 'md',
    }),
);
const ToggleDrawerWrapper: React.ComponentType<ToggleDrawerWrapperProps> = enhance(ToggleDrawerWrapperComponent);
export default ToggleDrawerWrapper;
