import React from 'react';
import { AppBar, Toolbar, Button } from '@material-ui/core';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core/styles';
// use this when we move to using BrowserHistory
// import { HashLink as Link } from 'react-router-hash-link';
import { MenuProps } from './MenuInterface';
import menuItems from './menu-generator';
import ThemeableToolbarTitle from '../SsgToolbarTitle';

const styles = (theme: Theme) =>
    createStyles({
        hiddenSkipNav: {
            position: 'absolute',
            left: '-10000px',
            top: 'auto',
            overflow: 'hidden',
            '&$focused': {
                color: theme.palette.primary.contrastText,
                position: 'unset',
                flex: 1,
                'text-decoration': 'underline',
                outline: '1px dotted ' + theme.palette.primary.contrastText,
            },
        },
        focused: {},
        root: { flexGrow: 1 },
        toolbar: { flexWrap: 'wrap' },
    });

const ToolbarMenuComponent: React.SFC<MenuProps & WithStyles<typeof styles>> = ({ classes, children, ...rest }) => (
    <div className={classes.root}>
        <AppBar position="static">
            <Toolbar className={classes.toolbar}>
                <Button
                    // use this when we move to using BrowserHistory
                    // component={props => <Link to="maincontent" {...props} />}
                    disableFocusRipple={true}
                    classes={{
                        root: classes.hiddenSkipNav,
                        focusVisible: classes.focused,
                    }}
                    aria-label="Skip to main content"
                    onClick={() => {
                        // simulate hash click
                        const main = document.getElementById('maincontent');
                        if (main) {
                            window.scrollTo(0, main.offsetTop);
                            main.focus();
                        }
                    }}
                >
                    Skip to main content
                </Button>
                <ThemeableToolbarTitle />
                {menuItems({ ...rest, mobile: false })}
            </Toolbar>
        </AppBar>
    </div>
);

const ToolbarMenu = withStyles(styles)(ToolbarMenuComponent);

export default ToolbarMenu;
