import * as React from 'react';
import { connect, Selector } from 'react-redux';
import translateViewFields from './translation/fromEntity/translateViewFields';
import translateFlowableFields from './translation/fromFlowable/translateFlowableFields';
import pipe from './util/pipe';
import { FieldFactoryContext } from './Broadcasts';
import generateInputFields from './input/generateField';
import generateDisplayFields from './display/generateField';
import mapToFormField from './mapToFormField';
import ViewConfig from '../reducers/ViewConfigType';
import { Field, DataSource } from './translation/types';
import { evaluateContext2 } from 'expressions/CachingEvaluator/FormContextEvaluator';
import { RootState } from 'reducers/rootReducer';
export { DataSource } from './translation/types';

export enum Mode {
    DISPLAY = 'Display',
    INPUT = 'Input',
}
/* View Live props (anything related to our particular INSTANCE. Generally- id. Connects fields to record/etc.) */
export interface EntityLiveFieldProps {
    replacePeriodsInFieldName?: string;
    record: {};
    resource: string;
    basePath: string;
    match?: {}; // route info from Redux-Router. TODO: fill definition
    specificIds?: (number | null)[]; // passing specific ids to e.g.
    // ManyMany select (giving you options to search among relationships)
    commitChanges?: boolean; // used by the refmanymanyidsselect to determine whether to stage, or save with each change
    neverShowRequiredAsterisk?: boolean;
    renderLabel?: boolean;
}
export interface FlowableLiveFieldProps {
    // for mocking route params sent to components with entity relationships.
    match?: {};
    renderLabel?: boolean;
}
export type LiveFieldConfig = EntityLiveFieldProps | FlowableLiveFieldProps;

/* Field Component config - relates to WHICH field components we use on the page. */
export interface EntityConfiguration {
    // for relationships to other entities
    parentEntityIdValue?: string;
    parentEntityName?: string;
    parentField?: string;
    // special
    optInWriteable?: {}[];
    // adds underline and min-height for display fields
    addUnderlineAndMinHeight?: boolean;

    // only applies for 'DISPLAY'
    getOwnData?: boolean;
    // only applies when getOwnData is provided
    defaultValue?: any; // tslint:disable-line
}
export interface FlowableConfiguration {
    relatedEntityResource?: string;
    /* related Entity (used for flowable if we need viewConfig access in translation/field generation steps) */
}
export interface Config {
    dataSource: DataSource;
    mode: Mode;
    validate: boolean; // apply validation rules
    connected: boolean; // connected to Redux Form
    options: EntityConfiguration | FlowableConfiguration;
}
const getTranslator = (dataSource: DataSource) => {
    switch (dataSource) {
        case DataSource.ENTITY:
            return translateViewFields;
        case DataSource.FLOWABLE:
            return translateFlowableFields;
        default:
            throw Error(`invalid datasource!!!: ${dataSource}`);
    }
};

const getFieldGenerator = (mode: Mode) => {
    switch (mode) {
        case Mode.DISPLAY:
            return generateDisplayFields;
        case Mode.INPUT:
            return generateInputFields;
        default:
            throw Error(`invalid mode! ${mode}`);
    }
};

const generateFieldFactory = (viewConfig: ViewConfig) => (config: Config) => {
    // lets get the translation function to convert data source fields to our standard interface
    const translator = getTranslator(config.dataSource);
    const hasLinkedEntity = Object.prototype.hasOwnProperty.call(config.options, 'relatedEntityResource');
    const translateToStandardDefinition = liveConfig =>
        hasLinkedEntity
            ? translator(
                  viewConfig,
                  (config.options as FlowableConfiguration).relatedEntityResource, // should this be liveConfig?
                  config.mode,
              )
            : translator(viewConfig, liveConfig.resource, config.mode);
    const generateFieldComponent = getFieldGenerator(config.mode);

    // convert the entire fieldDefinition array to the respective components with proper configuration
    const getBoundComponents = liveProps => (fieldDefinitions: Field[]) =>
        fieldDefinitions
            // strip validations if configuration is validate: false
            .map(fieldDefinition => (config.validate ? fieldDefinition : { ...fieldDefinition, validate: undefined }))
            .map((fieldDefinition, i) =>
                generateFieldComponent(fieldDefinition, viewConfig, config.options, liveProps),
            );
    return (
        liveConfig: LiveFieldConfig,
        selector: Selector<RootState, any> | null = null, // tslint:disable-line
        formContextSelector: (() => (fc: ReturnType<typeof evaluateContext2>, props?: {}) => {}) | null = null,
    ) => {
        const wrapComponents = config.connected
            ? mapToFormField(liveConfig as EntityLiveFieldProps, selector, formContextSelector)
            : i => i;
        const pipeline = (fieldDefinitions: {}[] | [string, {}][]) => {
            // entity-side, we can pass [fieldInstanceKey, ViewField] so we mark fieldInstanceIdentifier on fields that point to the same source
            // this won't really work TaskForm side.
            return pipe(
                translateToStandardDefinition(liveConfig),
                getBoundComponents(liveConfig),
                wrapComponents,
            )(fieldDefinitions);
        };

        return pipeline;
    };
};

export type GenerateFieldFactory = typeof generateFieldFactory;

class FieldFactoryProviderComponent extends React.Component<{ viewConfig: ViewConfig }> {
    render() {
        return (
            <FieldFactoryContext.Provider value={generateFieldFactory(this.props.viewConfig)}>
                {this.props.children}
            </FieldFactoryContext.Provider>
        );
    }
}
const FieldFactoryProvider = connect(({ viewConfig }: RootState) => ({
    viewConfig,
}))(FieldFactoryProviderComponent);

export default FieldFactoryProvider;
