import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Button, CardActions } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import CreateButton from 'components/generics/button/CreateButton';
import { allowsCreate, areActionsHidden } from '../utils/viewConfigUtils/index';
import { customEntityListActions } from '../overrides';
import { RootState } from '../../../reducers/rootReducer';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { fromNullable, fromPredicate, fromEither } from 'fp-ts/lib/Option';
import ViewConfig from 'reducers/ViewConfigType';
import { parseConfig } from 'expressions/entityViewConfig/parse';
import { EntityViewConfig } from 'expressions/entityViewConfig/type';
import { evaluateExpression } from 'expressions/evaluate';
import { currentUserProperties } from 'expressions/contextUtils/currentUser';
import useViewConfig from 'util/hooks/useViewConfig';

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block' as 'inline-block',
    float: 'right' as 'right',
    padding: 0,
};

interface PermissionedListActionsProps {
    showCreate?: boolean;
    accessLevel: number;
    resource?: string;
    displayedFilters?: {};
    filterValues?: {};
    basePath?: string;
    showFilter?: boolean | Function;
    refresh?: Function;
    exportList?: () => void;
    createRedirectQueryString?: string;
    viewConfig?: RootState['viewConfig'];
    listViewName?: string;
    roles: string[];
}
const getHideCreateSelector = <T extends { viewName: string }>() => {
    const hideCreateSelector = createSelector(
        (state: RootState, props: T) => state.viewConfig,
        (state: RootState, props: T) => props.viewName,
        getHideCreate,
    );
    return hideCreateSelector;
};
export const useHideCreateButton = (viewName: string) => {
    const viewConfig = useViewConfig();
    const hideCreateSelector = useMemo(getHideCreateSelector, []);
    const hideCreate = useSelector((state: RootState) => hideCreateSelector(state, { viewName }));
    const hideCreateResult = useMemo(() => {
        try {
            if (hideCreate && typeof hideCreate === 'string') {
                return evaluateExpression(hideCreate, {
                    ...currentUserProperties(viewConfig),
                });
            }
            return hideCreate;
        } catch (e) {
            console.error(e);
            return false;
        }
    }, [hideCreate, viewConfig]);
    return hideCreateResult;
};

const PermissionedListActionsComponent: React.SFC<PermissionedListActionsProps> = ({
    resource,
    displayedFilters,
    filterValues,
    basePath: _basePath,
    showFilter,
    showCreate,
    refresh,
    exportList,
    accessLevel,
    createRedirectQueryString,
    viewConfig,
    listViewName,
    roles,
}) => {
    const hideCreateResult = useHideCreateButton(listViewName);
    const customActions = resource && customEntityListActions[resource];
    return customActions ? (
        <CardActions style={cardActionStyle}>
            {customActions.map((action, i) => (
                <Link to={action.url} key={`custom-link-${action.key}`}>
                    <Button color="primary">{action.label}</Button>
                </Link>
            ))}
        </CardActions>
    ) : (
        <CardActions style={cardActionStyle}>
            {showCreate &&
                !hideCreateResult &&
                allowsCreate(accessLevel) &&
                (createRedirectQueryString ? (
                    <Button
                        style={{ overflow: 'inherit' }}
                        color="primary"
                        component={props => (
                            <Link
                                to={`${viewConfig.views[viewConfig.entities[resource].defaultViews.CREATE.name].route}${createRedirectQueryString}`}
                                {...props}
                            />
                        )}
                    >
                        Create&nbsp;
                        <Add />
                    </Button>
                ) : (
                    <CreateButton resource={resource} />
                ))}
            {allowsCreate(accessLevel) && roles.indexOf('ROLE_SUPER') !== -1 && (
                <Link to={`/import/${resource}`}>
                    <Button color="primary">Import</Button>
                </Link>
            )}
            {!areActionsHidden(listViewName, viewConfig) && roles.indexOf('ROLE_SUPER') !== -1 && (
                <Button color="primary" onClick={exportList}>
                    Export
                </Button>
            )}
            {/* <FlatButton primary label="refresh" onClick={refresh} icon={<NavigationRefresh />} /> */}
            {/* Add your custom actions */}
            {/* <FlatButton primary label="Custom Action" onClick={customAction} /> */}
        </CardActions>
    );
};

PermissionedListActionsComponent.defaultProps = {
    showCreate: true,
    showFilter: true,
    basePath: '',
    filterValues: {},
    displayedFilters: {},
};
export const getHideCreate = (viewConfig: ViewConfig, listViewName?: string) => {
    return fromNullable(viewConfig)
        .map(vc => vc.views)
        .chain(fromNullable)
        .chain(v => fromNullable(listViewName).mapNullable(vn => v[vn]))
        .chain(fromNullable)
        .map(c => c.config)
        .chain(fromPredicate<string>(Boolean))
        .chain(c =>
            fromEither(
                parseConfig(c).mapLeft(e => {
                    console.error(e);
                    return e;
                }),
            ),
        )
        .map((pc: EntityViewConfig) => pc.hideCreateButton)
        .chain(fromNullable)
        .getOrElse(false);
};

const PermissionedListActions: React.ComponentType<PermissionedListActionsProps> = PermissionedListActionsComponent;

export default PermissionedListActions;
