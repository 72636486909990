import React from 'react';
import PropTypes from 'prop-types';
import { Button, ListItem, ListItemText } from '@material-ui/core';
import { withRouter, History } from 'react-router';
import { Subtract } from 'utility-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    buttonLabel: {
        color: theme.palette.primary.contrastText,
    },
    buttonWrapper: {
        marginRight: '1em',
    },
});

interface MenuItemLinkComponentProps {
    history: History;
    to: string;
    onClick: () => void;
    id: string;
    classes: {
        buttonLabel: string;
        buttonWrapper: string;
    };
    mobile?: boolean;
}
export class MenuItemLinkComponent extends React.Component<MenuItemLinkComponentProps> {
    static propTypes = {
        history: PropTypes.object.isRequired,
        to: PropTypes.string.isRequired,
    };

    handleMenuClick = () => {
        this.props.history.push(this.props.to);
        this.props.onClick();
    };
    render() {
        const { children, id, classes, mobile } = this.props;

        return mobile ? (
            <ListItem button={true} id={id} onClick={this.handleMenuClick}>
                <ListItemText inset={true} primary={children} />
            </ListItem>
        ) : (
            <div className={classes.buttonWrapper}>
                <Button
                    classes={{
                        label: classes.buttonLabel,
                    }}
                    onClick={this.handleMenuClick}
                    variant={'text'}
                    id={id}
                >
                    {children}
                </Button>
            </div>
        );
    }
}

export const MenuItemLink: React.ComponentType<
    Subtract<
        MenuItemLinkComponentProps,
        { history: MenuItemLinkComponentProps['history']; classes: MenuItemLinkComponentProps['classes'] }
    >
> = (withStyles(styles) as any)(withRouter(MenuItemLinkComponent)); // tslint:disable-line

export default MenuItemLink;
