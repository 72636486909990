import React from 'react';
import { reduxForm } from 'redux-form'; // eslint-disable-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';
import { Toolbar, Card, Typography } from '@material-ui/core';
import compose from 'recompose/compose';
import withPropsOnChange from 'recompose/withPropsOnChange';
import { Helmet } from 'react-helmet';
import FileDownloadButton from './FileDownloadButton';
import { withFieldFactory } from '../../fieldFactory/Broadcasts';
import { DataSource, Mode } from '../../fieldFactory/FieldFactoryProvider';

const wrappedFields = fields =>
    (fields || []).map((field, i) => (
        <div key={i} style={{ paddingBottom: '1em', maxWidth: 256 * 2 }}>
            {field}
        </div>
    ));

const ReportFormComponent = ({ handleSubmit, onSubmit, fields, reportDefinitions }) => {
    console.log('rfc rerender');
    const outputOptions =
        reportDefinitions.config &&
        JSON.parse(reportDefinitions.config).availableOutputs &&
        JSON.parse(reportDefinitions.config).availableOutputs.length > 0
            ? JSON.parse(reportDefinitions.config).availableOutputs
            : ['pdf', 'xlsx', 'csv', 'txt', 'xml'];
    return (
        <div style={{ height: '100%' }}>
            <Helmet>
                <title>{reportDefinitions.displayName}</title>
            </Helmet>
            <Card>
                <Toolbar>
                    <Typography component="h1" variant="h5">
                        Report {reportDefinitions.displayName}
                    </Typography>
                </Toolbar>

                <div style={{ marginLeft: '1.5em', marginTop: '1em' }}>
                    <Typography variant="h6" component="h2">
                        Description
                    </Typography>
                    <span>{reportDefinitions.description}</span>
                    <form autoComplete="off">
                        <div
                            style={{
                                /*
                                    Not only does the below not do anything (we just render our inputs vertically)
                                    but it somehow makes input widths change randomly on hover (between 256px and 100%).

                                    Leaving this as a mystery to solve...

                                    display: 'flex',
                                    flexDirection: 'column',
                                    flexWrap: 'wrap',
                                */
                                marginTop: '1.5em',
                            }}
                        >
                            {wrappedFields(fields)}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                marginTop: '1.5em',
                                paddingBottom: '1em',
                            }}
                        >
                            {outputOptions &&
                                outputOptions.map((value, index) => {
                                    return (
                                        <FileDownloadButton
                                            key={index}
                                            fileType={value.toUpperCase()}
                                            longRunning={reportDefinitions.longRunning}
                                            handleSubmit={handleSubmit}
                                            onSubmit={onSubmit}
                                        />
                                    );
                                })}
                        </div>
                    </form>
                </div>
            </Card>
            <div style={{ height: '100%' }} />
        </div>
    );
};

ReportFormComponent.propTypes = {
    generateFields: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    reportDefinitions: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        name: PropTypes.string,
        displayName: PropTypes.string,
        description: PropTypes.string,
        dataSource: PropTypes.string,
        definition: PropTypes.string,
        fields: PropTypes.arrayOf(
            PropTypes.shape({
                fieldType: PropTypes.string,
                id: PropTypes.number,
                name: PropTypes.string,
                type: PropTypes.string,
                // value
                required: PropTypes.bool,
                readOnly: PropTypes.bool,
                overrideId: PropTypes.bool,
                // placeholder
                // layout
            }),
        ),
    }).isRequired,
};

const enhance = compose(
    reduxForm({
        // a unique name for the form
        form: 'current-report-form',
    }),
    withFieldFactory,
    withPropsOnChange(['fieldFactory'], props => {
        const config = {
            dataSource: DataSource.FLOWABLE,
            mode: Mode.INPUT,
            validate: true,
            connected: true,
            options: {},
        };
        return {
            generateFields: props.fieldFactory(config)({}),
        };
    }),
    withPropsOnChange(['generateFields', 'reportDefinitions'], ({ generateFields, reportDefinitions }) => ({
        fields: reportDefinitions.fields && generateFields(reportDefinitions.fields),
    })),
);

export default enhance(ReportFormComponent);
