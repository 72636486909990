import React from 'react';
import { ListItem } from '@material-ui/core';
import { getLogin } from '../../../generics/utils/viewConfigUtils';
import LogoutButton from '../../../custom/Account/LogoutButton';
import ViewConfig from '../../../../reducers/ViewConfigType';

export default ({
    mobile = false,
    viewConfig,
    style,
}: {
    mobile: boolean;
    viewConfig: ViewConfig;
    style?: React.CSSProperties;
}) => {
    const login = getLogin(viewConfig);
    const loginProps = {
        className: 'dropdownMenuItem',
        style,
        children: login,
    };
    const logoutProps = {
        className: 'dropdownMenuItem',
        style,
        children: 'Logout',
    };
    if (mobile) {
        return [<ListItem key={1} {...loginProps} />, <LogoutButton mobile={true} key={2} {...logoutProps} />];
    }
    return [
        <li role="presentation">
            <div key={1} {...loginProps} style={{ padding: '16px 24px' }} />
        </li>,
        <li role="presentation">
            <LogoutButton tabIndex={0} mobile={false} key={2} {...logoutProps} />
        </li>,
    ];
};
