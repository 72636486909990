import React from 'react';
import { connect } from 'react-redux';
import withHandlers from 'recompose/withHandlers';
import lifecycle from 'recompose/lifecycle';
import compose from 'recompose/compose';
import Search from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear';
import Add from '@material-ui/icons/Add';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';
import { FormControl, InputLabel, Input, IconButton, FormHelperText } from '@material-ui/core';
import { crudGetOne as crudGetOneAction } from 'sideEffect/crud/getOne/actions';
import { RootState } from '../../../reducers/rootReducer';
import uniqueId from 'lodash/uniqueId';
import { allowsCreate } from 'components/generics/utils/viewConfigUtils';

type Input = any; // tslint:disable-line
type Meta = any; // tslint:disable-line

type ArgumentTypes<F extends Function> = F extends (...args: infer A) => any ? A : never; // tslint:disable-line

export interface Record {
    id: string;
    entityType: string;
    title?: string;
}

/*
    Displays selected record, and triggers popover with onClick prop.
*/
const getFloatingButtonStyle = (renderLabel: boolean | undefined, offsetRight: number | string = 0) => {
    return {
        position: 'absolute',
        right: offsetRight,
        top: renderLabel ? 16 : 0,
        padding: 4,
        zIndex: 2,
    };
};
interface ReferenceDisplayProps {
    noSearch?: boolean;
    isOpen: boolean;
    isHardDisabled?: boolean;
    label: string | null;
    input: Input;
    meta: Meta;
    openSearch: () => void;
    openAdd: () => void;
    openDetail: () => void;
    htmlId?: string;
    record: Record;
    disabled?: boolean;
    reference: string;
    ariaInputProps?: {};
    renderLabel?: boolean;
    expansions?: string[];
    fetchOwnData?: boolean;
}
const mapStateToProps = (state: RootState, props: ReferenceDisplayProps) => {
    const referenceId = props.input.value;
    return {
        viewConfig: state.viewConfig,
        referenceRecord: (state.admin.entities[props.reference] || {})[referenceId],
    };
};
interface DispatchToProps {
    crudGetOne: (...args: ArgumentTypes<typeof crudGetOneAction>) => void;
}
const dispatchToProps: DispatchToProps = {
    crudGetOne: crudGetOneAction,
};
interface ReferenceDisplayConnectedProps
    extends ReferenceDisplayProps,
        ReturnType<typeof mapStateToProps>,
        DispatchToProps {}

const fetchReference = (props: ReferenceDisplayConnectedProps) => ({
    crudGetOne,
    reference,
    input,
    expansions,
} = props) => {
    const id = input.value;
    if (id) {
        crudGetOne({
            resource: reference,
            id,
            view: -1,
            appendExpansions: expansions,
        });
    }
};
interface Handlers {
    fetchReference: ReturnType<typeof fetchReference>;
}
const handlers: Handlers = {
    fetchReference,
};
interface ReferenceDisplayComponentProps extends ReferenceDisplayConnectedProps, Handlers {}

class ReferenceDisplayComponent extends React.Component<ReferenceDisplayComponentProps> {
    private inputId = uniqueId('refone-input');
    private errorMessageId = uniqueId('refone-error');
    private viewButtonRef = React.createRef<HTMLButtonElement>();
    private searchButtonRef = React.createRef<HTMLButtonElement>();
    static defaultProps = {
        ariaInputProps: {},
        renderLabel: true,
    };
    getCurrentlySelected = () => {
        const { input } = this.props;
        return input.value !== null && typeof input.value !== 'undefined' && input.value !== '';
    };
    getDisplayText = () => {
        const { referenceRecord = null } = this.props;
        return referenceRecord ? referenceRecord.title || referenceRecord.id : '';
    };
    handleBaseClick = () => {
        const { openDetail, openSearch } = this.props;
        const currentlySelected = this.getCurrentlySelected();

        if (currentlySelected) {
            const { current } = this.viewButtonRef;
            if (current) {
                current.focus();
            }
            openDetail();
        } else {
            const { current } = this.searchButtonRef;
            if (current) {
                current.focus();
            }
            openSearch();
        }
    };
    renderInputStyle() {
        const {
            input,
            openSearch,
            openAdd,
            label,
            disabled,
            noSearch,
            renderLabel,
            reference,
            meta: { touched, error },
            viewConfig,
        } = this.props;

        const hasCreate = allowsCreate(viewConfig.entities[reference].accessLevel);

        const currentlySelected = this.getCurrentlySelected();
        return (
            <div style={{ position: 'relative', width: '100%', margin: 0 }}>
                <FormControl style={{ zIndex: 'unset' }} fullWidth={true} error={touched && error}>
                    {renderLabel && (
                        <InputLabel shrink={true} disabled={disabled} htmlFor={this.inputId}>
                            {label}
                        </InputLabel>
                    )}
                    <Input
                        id={this.inputId}
                        value={this.getDisplayText()}
                        inputProps={{
                            'aria-disabled': true,
                            'aria-errormessage': touched && error ? this.errorMessageId : undefined,
                            style: {
                                textOverflow: 'ellipsis',
                                marginRight: '90px',
                            },
                        }}
                    />
                    {!disabled && currentlySelected && (
                        <IconButton
                            buttonRef={this.viewButtonRef}
                            aria-label={`View ${label}: ${this.getDisplayText()} Opens a modal dialog`}
                            style={getFloatingButtonStyle(renderLabel, noSearch ? 30 : 60) as any}
                            onClick={evt => {
                                evt.preventDefault();
                                evt.stopPropagation();
                                this.props.openDetail();
                            }}
                        >
                            <RemoveRedEye />
                        </IconButton>
                    )}
                    {!disabled && currentlySelected && (
                        <IconButton
                            aria-label="clear value"
                            style={getFloatingButtonStyle(renderLabel, noSearch ? 0 : 30) as any}
                            onClick={evt => {
                                if (!disabled) {
                                    evt.preventDefault();
                                    evt.stopPropagation();
                                    input.onBlur(null);
                                }
                            }}
                        >
                            <Clear />
                        </IconButton>
                    )}
                    {!disabled &&
                        (this.props.noSearch ? (
                            !currentlySelected &&
                            hasCreate && (
                                <IconButton
                                    aria-label={`Add ${label}`}
                                    aria-haspopup="true"
                                    onClick={evt => {
                                        evt.preventDefault();
                                        evt.stopPropagation();
                                        openAdd();
                                    }}
                                    style={getFloatingButtonStyle(renderLabel) as any}
                                >
                                    <Add />
                                </IconButton>
                            )
                        ) : (
                            <IconButton
                                buttonRef={this.searchButtonRef}
                                aria-label={`Select ${label}`}
                                aria-haspopup="true"
                                onBlur={() => input.onBlur()}
                                onClick={evt => {
                                    evt.preventDefault();
                                    evt.stopPropagation();
                                    openSearch();
                                }}
                                style={getFloatingButtonStyle(renderLabel) as any}
                            >
                                <Search />
                            </IconButton>
                        ))}
                    {touched && error && (
                        <FormHelperText style={{ zIndex: 2 }} id={this.errorMessageId}>
                            Error: {error}
                        </FormHelperText>
                    )}
                </FormControl>
                <div
                    // adding div to block disabled TextField from click event
                    // ('disabled' prop prevents click propagation in firefox)
                    aria-hidden={true}
                    role="button"
                    onClick={this.handleBaseClick}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 1,
                        height: '100%',
                        width: '100%',
                        cursor: 'pointer',
                    }}
                />
            </div>
        );
    }
    render() {
        const { label, renderLabel } = this.props;
        if (this.props.isHardDisabled) {
            return (
                <FormControl fullWidth={true}>
                    {label && renderLabel && (
                        <InputLabel shrink={true} disabled={false}>
                            {label}
                        </InputLabel>
                    )}
                    <span
                        style={{
                            height: 48,
                            borderBottomStyle: 'dotted',
                            borderBottom: '1px dotted rgba(0, 0, 0, 0.42)',
                            position: 'relative',
                        }}
                    >
                        {/* eslint-disable-next-line */}
                        <a
                            href="javascript:;" // eslint-disable-line
                            style={{ cursor: 'pointer', width: '100%', position: 'absolute', bottom: 4 }}
                            role="button"
                            onClick={this.props.openDetail}
                        >
                            {this.getDisplayText()}
                            <span className="casetivity-off-screen">Opens a modal dialog</span>
                        </a>
                    </span>
                </FormControl>
            );
        }
        return this.renderInputStyle();
    }
}

const enhanceRefDisplay = compose(
    connect(
        mapStateToProps,
        dispatchToProps,
    ),
    withHandlers(handlers),
    lifecycle({
        componentDidMount() {
            const props: ReferenceDisplayComponentProps = this.props;
            if (props.fetchOwnData !== false) {
                props.fetchReference();
            }
        },
        componentWillReceiveProps(nextProps: ReferenceDisplayComponentProps) {
            const props: ReferenceDisplayComponentProps = this.props;
            if (
                (!props.record && nextProps.record && nextProps.record.id) || // record is instantiated
                (nextProps.record && props.record && props.record.id !== nextProps.record.id) || // record is changed
                (nextProps.referenceRecord &&
                    // referenceRecord changed
                    (!props.referenceRecord || nextProps.referenceRecord.id !== props.referenceRecord.id)) ||
                // no referenceRecord for the incoming (changed) value
                (!nextProps.referenceRecord && nextProps.input.value !== props.input.value)
            ) {
                props.fetchReference(nextProps);
            }
        },
    }),
);
const ReferenceDisplay: React.SFC<ReferenceDisplayProps> = enhanceRefDisplay(ReferenceDisplayComponent);

export default ReferenceDisplay;
