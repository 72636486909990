import * as React from 'react';
import { useSelector } from 'react-redux';
import { reduxForm, SubmissionError, InjectedFormProps } from 'redux-form';
import { RootState, TaskForm } from 'reducers/rootReducer';
import { createGetInitialValues } from 'bpm/components/TaskDetail/TaskForm/getInitialValues';
import { formContext } from 'bpm/components/TaskDetail/TaskForm/FormContext';
import useViewConfig from 'util/hooks/useViewConfig';
import useValueSets from 'util/hooks/useValueSets';
import useTaskFormFields from 'bpm/components/TaskDetail/TaskForm/hooks/useTaskFormFields';
import validate from 'bpm/components/TaskDetail/TaskForm/validate';
import TaskFormLayout, { FormLayoutProps } from './Layout';
import useEntities from 'util/hooks/useEntities';

const Form = reduxForm<{}, FormLayoutProps>({
    enableReinitialize: true,
    form: 'current-task-form',
    shouldWarn: ({ values, nextProps, props, initialRender }) => {
        // maybe optimize this eventually if we know what the runtime cost is.
        return true;
    },
})((props: FormLayoutProps & InjectedFormProps) => <TaskFormLayout {...props} />);

interface InternalTaskFormProps extends Pick<FormLayoutProps, 'renderLinkedEntity'> {
    taskId: string;
    processId: string;
    formDefinition: TaskForm;
    relatedEntityResource?: string;
    relatedEntityId?: string;
}

interface Values {
    submissionType: 'save' | 'complete';
    _outcome?: string;
}
/*
    Providers onSubmit (validation)
*/
const InternalTaskForm: React.SFC<InternalTaskFormProps> = props => {
    const { taskId, relatedEntityId, relatedEntityResource, formDefinition } = props;
    const fields = useTaskFormFields(props);
    const getInitialValues = React.useMemo(createGetInitialValues, []);
    const initialValues = useSelector((state: RootState) =>
        getInitialValues(state, {
            taskId,
            relatedEntityId,
            relatedEntityResource,
        }),
    );
    const fc = React.useContext(formContext);
    const entities = useEntities();
    const valueSets = useValueSets();
    const viewConfig = useViewConfig();

    const onSubmit = React.useCallback(
        (values: Values) => {
            if (values.submissionType !== 'save') {
                const messagesIncludingUnexpectedErrors = validate({
                    outcome: values._outcome,
                    entities,
                    valuesAfterExpressionsApplied: fc.fieldValues,
                    visibleAndEditableFields: fc.visibleAndEditableFields,
                    viewConfig,
                    fields,
                    formDefinition,
                    valueSets,
                    ignoreFieldLevel: true, // let the field itself handle its 'field-level' validation
                });
                if (Object.keys(messagesIncludingUnexpectedErrors).length > 0) {
                    const submissionError = Object.assign(
                        { _error: messagesIncludingUnexpectedErrors },
                        messagesIncludingUnexpectedErrors,
                    );
                    throw new SubmissionError(submissionError);
                }
            }
        },
        [entities, fc.fieldValues, fc.visibleAndEditableFields, viewConfig, fields, formDefinition, valueSets],
    );
    return (
        <Form
            processId={props.processId}
            formDefinition={formDefinition}
            fields={fields}
            taskId={taskId}
            onSubmit={onSubmit as () => void}
            initialValues={initialValues}
            renderLinkedEntity={props.renderLinkedEntity}
        />
    );
};
export default InternalTaskForm;
