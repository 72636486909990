import React from 'react';
import { TextField } from '@material-ui/core';
import { withStyles, Theme } from '@material-ui/core/styles';
import DebouncedTextInput from './DebouncedTextInput';
import FieldTitle from './aor/FieldTitle';
import { activeTabContext } from 'components/generics/form/TabbableForm';
import getFormHelperTextProps from 'fieldFactory/util/getFormHelperTextProps';
import uniqueId from 'lodash/uniqueId';
import formatError from 'fieldFactory/util/formatError';

interface Classes {
    //     inputLabelDefault: React.CSSProperties;
}
const styles: (theme: Theme) => Classes = (theme: Theme) => ({
    inputLabelDefault: {
        // color: grey[900], this was done thorugh overrides on MuiInputs.
        // (leaving as an example of overriding classes on a component basis)
    },
});

export const LongTextInput: React.SFC<{
    classes: { [key in keyof Classes]: string };
    className?: string;
    isRequired?: boolean;
    options?: {};
    source: string;
    resource: string;
    input?: {
        onBlur: () => void;
        onChange: () => void;
        value: string;
    };
    meta: {
        touched: boolean;
        error: string;
    };
    label?: string;
    addField?: boolean;
    elStyle?: {};
    validate: Function | Function[];
    fullWidth?: boolean;
    disabled?: boolean;
    rows?: number;
    customStyles?: {};
    renderLabel: boolean;
    ariaInputProps?: {};
}> = ({
    className,
    input,
    meta,
    isRequired,
    label,
    options,
    source,
    resource,
    disabled,
    classes,
    rows,
    renderLabel,
    ariaInputProps,
    customStyles,
}) => {
    const ariaErrormessageRef = React.useRef(uniqueId('longtext-ErrorMsg'));
    if (typeof meta === 'undefined') {
        throw new Error(
            "The LongTextInput component wasn't called within a redux-form <Field>. " +
                'Did you decorate it and forget to add the addField prop to your component? ' +
                'See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component' +
                ' for details.',
        );
    }
    const { touched, error } = meta;
    const InputProps =
        touched && error
            ? { ...ariaInputProps, 'aria-errormessage': ariaErrormessageRef.current }
            : { ...ariaInputProps, 'aria-errormessage': undefined };
    if (label && typeof label === 'string' && label.trim()) {
        InputProps['aria-label'] = label;
    }
    return (
        <activeTabContext.Consumer>
            {tabContext => (
                <DebouncedTextInput
                    key={tabContext._type} // Remount when 'hidden' or 'shown' because heights need to be recalculated.
                    emptyInitialValue=""
                    input={input}
                    renderInput={({ value, onChange, onBlur }) => (
                        <TextField
                            InputLabelProps={{
                                shrink: true,
                                disabled: false,
                            }}
                            inputProps={InputProps}
                            {...input}
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            className={className}
                            multiline={true}
                            margin="none"
                            rows={rows} // height calculation fails
                            label={
                                renderLabel && (
                                    <FieldTitle
                                        label={label}
                                        source={source}
                                        resource={resource}
                                        isRequired={isRequired}
                                    />
                                )
                            }
                            error={!!(touched && error)}
                            helperText={touched && error ? `Error: ${formatError(error)}` : undefined}
                            FormHelperTextProps={getFormHelperTextProps(InputProps)}
                            disabled={disabled}
                            {...options}
                            style={{ minHeight: '15px', ...customStyles }}
                        />
                    )}
                />
            )}
        </activeTabContext.Consumer>
    );
};

LongTextInput.defaultProps = {
    options: {},
    fullWidth: true,
    addField: true,
    disabled: false,
    ariaInputProps: {},
    renderLabel: true,
};

export default withStyles(styles as any)(LongTextInput); // tslint:disable-line
