import React from 'react';
import { withWidth } from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

interface ResponsiveProps {
    small?: JSX.Element;
    medium?: JSX.Element;
    large?: JSX.Element;
}
interface ResponsiveComponentProps extends ResponsiveProps {
    width?: Breakpoint;
}
export const Responsive: React.SFC<ResponsiveComponentProps> = ({ small, medium, large, width, ...rest }) => {
    let component;
    switch (width) {
        case 'xs':
            component = small ? small : medium ? medium : large;
            break;
        case 'sm':
        case 'md':
            component = medium ? medium : large ? large : small;
            break;
        case 'lg':
        case 'xl':
            component = large ? large : medium ? medium : small;
            break;
        default:
            throw new Error(`Unknown width ${width}`);
    }
    return React.cloneElement(component, rest);
};

const ResponsiveEnhanced: React.ComponentType<ResponsiveProps> = withWidth({
    initialWidth: 'md',
})(Responsive) as any; // tslint:disable-line
export default ResponsiveEnhanced;
