import * as React from 'react';
import { TaskForm } from 'reducers/rootReducer';
import { fromNullable, fromPredicate } from 'fp-ts/lib/Option';
import { flowablePreprocessValuesForEval } from 'expressions/formValidation';
import { memoizedTraverseEval } from 'expressions/expressionArrays';
import useViewConfig from 'util/hooks/useViewConfig';
import useValueSets from 'util/hooks/useValueSets';
import useEntities from 'util/hooks/useEntities';
import useFormHasErrors from 'bpm/components/TaskDetail/TaskForm/hooks/useFormHasErrors';
import { formContext as taskFormContext } from 'bpm/components/TaskDetail/TaskForm/FormContext';

const useEvaluateOutcomeExpression = (
    formDefinition: TaskForm,
    name: string,
    config: 'visibility' | 'editable',
    defaultTo: any = true,
) => {
    const entityFormHasErrors = useFormHasErrors('record-form');
    const entities = useEntities();
    const viewConfig = useViewConfig();
    const valueSets = useValueSets();
    const fc = React.useContext(taskFormContext);
    const evalButtonExp = (exp: string) => {
        const setupValues = flowablePreprocessValuesForEval(
            { ...fc.fieldValues, entityFormHasErrors },
            formDefinition.fields,
            entities,
            {
                dateFormat: viewConfig && viewConfig.application && viewConfig.application.dateFormat,
            },
            valueSets,
        );
        return memoizedTraverseEval(exp, {}, entities, viewConfig, valueSets)(setupValues).fold(
            l => {
                console.error(l);
                return false;
            },
            r => r.reduce((prev, curr) => prev && curr, true),
        );
    };
    const outcomeButtonConfig = fromNullable(formDefinition.outcomes)
        .mapNullable(outcomes => outcomes.find(o => o.name && o.name.toLowerCase() === name.toLowerCase()))
        .mapNullable(saveOutcome => saveOutcome.configs);
    return outcomeButtonConfig
        .mapNullable(configs => configs[config])
        .chain(fromPredicate<string>(Boolean))
        .map(evalButtonExp)
        .getOrElse(defaultTo);
};
export default useEvaluateOutcomeExpression;
