import * as get from './actions';
import { isActionOf } from 'typesafe-actions';
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { Services } from 'sideEffect/services';
import { Epic, ofType } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { RootState } from 'reducers/rootReducer';
import { filter, withLatestFrom, map, flatMap, mapTo, take } from 'rxjs/operators';
import crudGetOneFlow from 'sideEffect/crud/util/epics/CoreCrud/getOne';
import buildUrl from './buildUrl';
import { getViewIndexAndAdditionalConfigFields } from 'components/generics/utils/viewConfigUtils';
import { fetchStart, fetchEnd } from 'actions/aor/fetchActions';
import { concat, of, race, Observable } from 'rxjs';

const getOneFlow: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) =>
    action$.pipe(
        filter(isActionOf(get.crudGetOne)),
        withLatestFrom(
            state$.pipe(map(state => state.viewConfig)),
            state$.pipe(map(state => state.entityValidations)),
            state$.pipe(map(state => state.actionButtonExps)),
            state$.pipe(map(state => state.router.location)),
        ),
        flatMap(([action, viewConfig, entityValidations, actionButtonExps, location]) => {
            const { resource, cb, view, errorsCbs } = action.payload;

            let viewType = null;
            if (view && typeof view === 'string') {
                const [viewName] = getViewIndexAndAdditionalConfigFields(view, viewConfig, 'KEEP_LINKEDX_TYPE');
                if (!viewConfig.views[viewName]) {
                    throw new Error(`View ${view} [viewIndex: ${viewName}] was passed in payload of action ${JSON.stringify(
                        action,
                    )}.
                        However this view does not exist in the viewConfig.
                    `);
                }
                viewType = viewConfig.views[viewName].viewType;
            }
            const restUrl = buildUrl(
                action.payload,
                viewConfig,
                viewType === 'EDIT' ? entityValidations : {},
                actionButtonExps,
            );
            const getAjaxStream = (startFetch: boolean) =>
                crudGetOneFlow(
                    {
                        restUrl,
                        monitorRequest: action.payload.monitorRequest,
                    },
                    {
                        service: services.crudGet,
                        failureAction: get.crudGetOneFailure,
                        successAction: get.crudGetOneSuccess,
                        successCb: cb,
                        errorsCbs,
                    },
                    {
                        resource,
                        viewConfig,
                        initialRequestPayload: action.payload,
                        restUrl,
                    },
                    startFetch,
                );
            if (action.cancelOnRouteChange) {
                const blocker$: Observable<ReturnType<typeof fetchEnd>> = action$.pipe(
                    ofType(LOCATION_CHANGE),
                    filter((a: LocationChangeAction) => {
                        return (
                            decodeURIComponent(a.payload.location.pathname) !== decodeURIComponent(location.pathname) ||
                            decodeURIComponent(a.payload.location.search) !== decodeURIComponent(location.search)
                        );
                    }),
                    take(1),
                    mapTo(fetchEnd()),
                );
                return concat(of(fetchStart()), race(getAjaxStream(false), blocker$));
            }
            return getAjaxStream(true);
        }),
    );
export default getOneFlow;
