import * as loginActions from './actions';
import { isActionOf } from 'typesafe-actions';
import { RootState } from 'reducers/rootReducer';
import { Epic } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { Services } from 'sideEffect/services';
import { filter, catchError, flatMap, tap } from 'rxjs/operators';
import { of, concat } from 'rxjs';
import { enqueueSnackbar as enqueueSnackbarAction } from 'notistack/actions';
import { getResponseAndThrowErrorForNon200 } from 'sideEffect/crud/util/epics/CoreCrud/shared';
import { push as pushAction } from 'connected-react-router';
import { fetchEnd, fetchStart } from 'actions/aor/fetchActions';
import { AuthResponse } from './definitions';
import * as viewConfig from 'viewConfig/actions';
import { AjaxError } from 'rxjs/ajax';
import { storageController } from 'storage';

export const translateError = (err: AjaxError) =>
    err.message && err.message.includes('ajax error 401')
        ? err.response.AuthenticationException
        : err.message && err.message.includes('ajax error 4')
        ? 'Login failed, please check your username and password'
        : err.message && err.message.includes('ajax error 5')
        ? 'Server Error'
        : err.message && err.message.includes('ajax error')
        ? 'System Error'
        : err.message || 'Authentication failed, please retry';

const loginFlow: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) => {
    return action$.pipe(
        filter(isActionOf(loginActions.login)),
        tap(() => {
            storageController.clear();
        }),
        flatMap(action =>
            concat(
                of(fetchStart()),
                services.authenticate(action.payload).pipe(
                    getResponseAndThrowErrorForNon200,
                    tap((r: AuthResponse) => {
                        storageController.setToken(r.id_token);
                    }),
                    flatMap(r =>
                        concat(
                            of(loginActions.loginSuccess()),
                            of(fetchEnd()),
                            of(viewConfig.load(false)),
                            of(action.redirectTo ? pushAction(action.redirectTo) : pushAction('/')),
                        ),
                    ),
                    catchError(err => {
                        const notify = action.errorCb(err);
                        if (!notify) {
                            return concat(of(loginActions.loginFailure(err)), of(fetchEnd()));
                        }
                        const errorMessage = translateError(err);
                        return concat(
                            of(loginActions.loginFailure(err)),
                            of(fetchEnd()),
                            of(enqueueSnackbarAction({ message: errorMessage, options: { variant: 'warning' } })),
                        );
                    }),
                ),
            ),
        ),
    );
};
export default loginFlow;
