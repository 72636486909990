import storage from 'local-storage-fallback';
import { fromNullable } from 'fp-ts/lib/Option';

const IMPERSONATE_KEY = 'currentlyimpersonating';
interface StoredData {
    userId: string;
    previousUserTitle: string;
    currentLocation: string;
}
export const clearImpersonatingStorage = () => {
    storage.removeItem(IMPERSONATE_KEY);
};
export const storeImpersonating = (action: StoredData) => {
    storage.setItem(IMPERSONATE_KEY, JSON.stringify(action));
};
export const getImpersonating = (): StoredData => {
    return fromNullable(storage.getItem(IMPERSONATE_KEY))
        .map(JSON.parse)
        .getOrElse(null);
};
