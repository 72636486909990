import React, { InputHTMLAttributes } from 'react';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';
import { Checkbox, FormControl, FormHelperText } from '@material-ui/core';
import FormControlLabel from 'fieldFactory/input/components/mui/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import Done from '@material-ui/icons/Done';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import uniqueId from 'lodash/uniqueId';

const hideIfNoInput = branch(
    props => !props.input, // input is not provided
    renderNothing,
);

const CheckBox = ({ input, label, meta: { touched, error }, theme, disabled }) => {
    const inputId = React.useRef(uniqueId('checkboxInputId'));
    const ariaErrormessageRef = React.useRef(uniqueId('checkboxErrorMsg'));
    const InputProps: React.InputHTMLAttributes<HTMLInputElement> = {
        id: inputId.current,
        'aria-label': label,
        'aria-invalid': touched && error,
        'aria-describedby': touched && error ? ariaErrormessageRef.current : undefined,
    };
    return (
        // don't make this full width! It just cases whitespace to be clickable.
        <FormControl margin="none" style={{ marginTop: '.5em ' }} error={touched && error}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={!!input.value}
                        inputProps={InputProps}
                        onChange={(e, checked) => input.onBlur(checked)}
                        checkedIcon={<Done style={{ fontSize: '1.875em', width: 24, height: 24 }} />}
                        icon={<CheckBoxOutlineBlankIcon style={{ fontSize: '0.875em', width: 24, height: 24 }} />}
                    />
                }
                label={label}
                htmlFor={inputId.current}
                disabled={disabled}
                style={{ fontSize: '0.875em' }}
            />
            {touched && error && <FormHelperText id={InputProps['aria-describedby']}>Error: {error}</FormHelperText>}
        </FormControl>
    );
};

export default withStyles({}, { withTheme: true })(hideIfNoInput(CheckBox));
