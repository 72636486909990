import React, { ReactElement } from 'react';
import { RootState } from '../../../reducers/rootReducer';
import { createSelector } from 'reselect';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Subtract } from 'utility-types';
import memoizeOne from 'memoize-one';
import traverseGetData from 'casetivity-shared-js/lib/viewConfigSchema/traverseGetData';
import createRecordFromPath from 'casetivity-shared-js/lib/viewConfigSchema/traverseGetData/createRecordFromPath';
import { createGetEntities } from 'components/generics/form/EntityFormContext/util/getEntities';
import applyDeltaToEntities from 'casetivity-shared-js/lib/viewConfigSchema/traverseGetData/ApplyDeltaToEntities';

const NOT_FOUND = '_NOT_FOUND________';

interface FieldSubscriberProps {
    subscribeToEntireRecord?: boolean;
    source: string;
    defaultValue?: string | ReactElement<any>; //tslint:disable-line
    resource: string;
    id?: string | number;
    record?: { id: string | number };
    renderDisplayField: (params: {
        record: {};
        value: any; // tslint:disable-line
    }) => React.ReactElement<{}> | null;
    delta?: {};
}

interface FieldSubscriberComponentProps extends FieldSubscriberProps {
    record: { id: string | number };
    value?: any; // tslint:disable-line
}
const FieldSubscriberComponent: React.SFC<FieldSubscriberComponentProps> = ({ renderDisplayField, record, value }) => {
    return renderDisplayField({ record, value });
};

const getAppliedSource = (subscribeToEntireRecord?: boolean) => (source: string) =>
    subscribeToEntireRecord
        ? source
              .split('.')
              .slice(0, -1)
              .join('.') // path to record only
        : source;

const getMakeMapStateToProps = (withDelta: boolean = false) => () => {
    const empty = {};
    const memoizedCreateRecordFromPath = memoizeOne(createRecordFromPath);
    const getEntitiesSelector = (() => {
        if (withDelta) {
            const getEntities = createGetEntities();
            return createSelector(
                getEntities,
                (state: RootState) => state.viewConfig,
                (state: RootState, props: FieldSubscriberProps) => props.delta,
                (state: RootState, props: FieldSubscriberProps) => props.id,
                (state: RootState, props: FieldSubscriberProps) => props.resource,
                (entities, viewConfig, delta, id, entityType) => {
                    return applyDeltaToEntities(viewConfig, entities, delta, { id, entityType }, false);
                },
            );
        }
        return (state: RootState, props: FieldSubscriberProps) => state.admin.entities as any; // tslint:disable-line no-any
    })();
    const valueSelector = createSelector(
        (state: RootState, props: FieldSubscriberProps) => props.source,
        (state: RootState, props: FieldSubscriberProps) => props.id,
        (state: RootState, props: FieldSubscriberProps) => props.resource,
        (state: RootState, props: FieldSubscriberProps) => state.viewConfig,
        getEntitiesSelector,
        (state: RootState, props: FieldSubscriberProps) => props.subscribeToEntireRecord,
        (source, id, resource, viewConfig, entities, subscribeToEntireRecord) => {
            // optimization here: only update for entities on our path.
            // for now we just recalculate calculate for all entities

            // it would be nice to deal with optionals past this point, but we need to be able to check equality
            // for caching results
            return id
                ? traverseGetData(
                      viewConfig,
                      getAppliedSource(subscribeToEntireRecord)(source),
                      { id, entityType: resource },
                      entities,
                  ).fold(NOT_FOUND, value => value)
                : NOT_FOUND;
        },
    );
    const reconstructRecordSelector = createSelector(
        (state: RootState, props: FieldSubscriberProps) => props.source,
        valueSelector,
        (state: RootState, props: FieldSubscriberProps) => props.defaultValue,
        (state: RootState, props: FieldSubscriberProps) => props.subscribeToEntireRecord,
        (source, value, defaultValue, subscribeToEntireRecord): { record: {}; value?: any } => {
            // tslint:disable-line
            if (value === NOT_FOUND) {
                if (defaultValue) {
                    return {
                        record: memoizedCreateRecordFromPath(source, defaultValue),
                        value,
                    };
                }
                return { record: empty };
            }
            return {
                record: memoizedCreateRecordFromPath(getAppliedSource(subscribeToEntireRecord)(source), value),
                value: value,
            };
        },
    );
    const mapStateToProps = (state: RootState, props: FieldSubscriberProps) => {
        return reconstructRecordSelector(state, props);
    };
    return mapStateToProps;
};
const FieldSubscriber: React.SFC<Subtract<FieldSubscriberProps, Pick<FieldSubscriberProps, 'delta'>>> = compose(
    connect(getMakeMapStateToProps(false)),
)(FieldSubscriberComponent);

export default FieldSubscriber;

export const FieldSubscriberWithDelta: React.SFC<FieldSubscriberProps & { delta: {} }> = compose(
    connect(getMakeMapStateToProps(true)),
)(FieldSubscriberComponent);

interface BaseComponentProps {
    source?: string;
    recource?: string;
    record?: {};
    id?: string | number;
    label?: string;
    addField?: boolean;
    sortable?: boolean;
    overrideRender?: (baseComponent: React.ReactElement<{}>, value: any) => React.ReactElement<{}> | null; // tslint:disable-line
}

export const fieldSubscriberHoc = (BaseComponent: React.ComponentType<BaseComponentProps>) => (
    props: Subtract<FieldSubscriberProps & BaseComponentProps, Pick<FieldSubscriberProps, 'renderDisplayField'>>,
) => (
    <FieldSubscriber
        {...props}
        id={props.id || (props.record ? props.record.id : undefined)}
        renderDisplayField={({ record, value }) => {
            const baseComponent = <BaseComponent {...props} record={record} />;
            if (props.overrideRender) {
                return props.overrideRender(baseComponent, value);
            }
            return baseComponent;
        }}
    />
);
