import React, { useRef, useEffect, useCallback } from 'react';
import IdleTimer from 'react-idle-timer';
import Countdown from 'react-countdown-now';
import { IdleTimerState } from 'idle-timer/hooks/useIdleTimerState';
import useIdleSync from 'idle-timer/hooks/useIdleSync';
import moment from 'moment';
import { Dialog, Card, CardContent, CardActions, Button, CardHeader } from '@material-ui/core';
import useLogout from 'auth/hooks/useLogout';
import createRefreshTokenRequest from 'idle-timer/util/tokenReset';
import { disableFormsaveNotifierInc } from 'formSaveNotifier/actions';
import { useDispatch } from 'react-redux';

interface IdleTimerProps {
    timeToWarning: number;
    warningTime: number;
}

const CasetivityIdleTimer: React.SFC<IdleTimerProps> = props => {
    const reduxDispatch = useDispatch();
    const idleTimer = useRef<IdleTimer>();
    const [state, dispatch, syncController] = useIdleSync(idleTimer);

    const trigger: (action: IdleTimerState) => void = useCallback(
        action => {
            if (syncController.current) {
                (syncController.current.trigger as (action: IdleTimerState) => void)(action);
            }
        },
        [syncController],
    );
    const resetActive = useCallback(() => {
        const action = { status: 'ACTIVE', activeDate: new Date() } as const;
        dispatch(action);
        trigger(action);
        idleTimer.current.reset();
    }, [dispatch, trigger, idleTimer]);
    const _logout = useLogout();
    const logout = useCallback(() => {
        reduxDispatch(disableFormsaveNotifierInc());
        // give a change for formsave notification to have an update pass.
        setImmediate(_logout);
    }, [_logout, reduxDispatch]);

    const onActive = useCallback(() => {
        if (state.status === 'IDLE') {
            const timeDifferenceFromWhenLastIdle = Math.abs(moment(state.date).diff(Date.now(), 'milliseconds'));
            if (timeDifferenceFromWhenLastIdle > props.warningTime + props.timeToWarning) {
                logout();
            } else {
                resetActive();
            }
        } else {
            resetActive();
        }
    }, [state, logout, resetActive, props.warningTime, props.timeToWarning]);

    const onIdle = e => {
        const action = { status: 'IDLE', date: new Date() } as const;
        dispatch(action);
        trigger(action);
    };
    useEffect(() => {
        function handleVisibilityChange() {
            if (document.hidden) {
                console.log('hidden');
            } else {
                if (state.status === 'ACTIVE') {
                    const timeDiffFromLastActive = Math.abs(moment(state.activeDate).diff(Date.now(), 'milliseconds'));
                    if (timeDiffFromLastActive > props.warningTime + props.timeToWarning) {
                        // last global active state was too long ago.
                        logout();
                    } else {
                        resetActive();
                    }
                } else {
                    // state.status === 'IDLE'
                    onActive();
                }
            }
        }
        document.addEventListener('visibilitychange', handleVisibilityChange, false);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange, false);
        };
    }, [state, onActive, resetActive, props.warningTime, props.timeToWarning, logout]);
    const manualContinue = () => {
        resetActive();
        fetch(createRefreshTokenRequest())
            .then(res => {
                if (res.status === 401) {
                    alert('Authentication refresh failed. Logging out.');
                    logout();
                }
            })
            .catch(err => {
                alert('Authentication refresh failed.');
            });
    };
    return (
        <div>
            <IdleTimer
                ref={idleTimer}
                element={document}
                onActive={onActive}
                onIdle={onIdle}
                onAction={resetActive}
                throttle={250}
                timeout={props.timeToWarning}
                stopOnIdle={true}
                startOnMount={true}
            />
            {state.status === 'IDLE' && (
                <Dialog
                    TransitionProps={
                        {
                            // https://github.com/dequelabs/axe-core/issues/146
                            role: 'presentation',
                        } as any
                    }
                    open={true}
                >
                    <Countdown
                        date={moment(state.date)
                            .add(props.warningTime, 'milliseconds')
                            .toDate()}
                        onComplete={logout}
                        renderer={({ total, completed }) => {
                            if (!completed) {
                                return (
                                    <Card>
                                        <CardHeader title="Timeout Warning" />
                                        <CardContent>
                                            Your session is about to expire in:{' '}
                                            {new Date(total).toISOString().substr(14, 5)}
                                        </CardContent>
                                        <CardActions>
                                            <Button color="primary" variant="contained" onClick={logout}>
                                                Log out
                                            </Button>
                                            <Button color="primary" variant="contained" onClick={manualContinue}>
                                                Continue
                                            </Button>
                                        </CardActions>
                                    </Card>
                                );
                            }
                            return null;
                        }}
                    />
                </Dialog>
            )}
            {props.children}
        </div>
    );
};
export default CasetivityIdleTimer;
