import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Subtract } from 'utility-types';
import uniqueId from 'lodash/uniqueId';

type Input = any; // tslint:disable-line
type Meta = any; // tslint:disable-line

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 195,
    },
});

interface Choices {
    [key: string]: {};
}
interface SelectProps {
    disabled?: boolean;
    classes: {
        formControl: string;
    };
    input: Input;
    meta?: Meta;
    label: string;
    choices: Choices;
    inputId?: string;
    inputName?: string;
    source?: string;
    nameProp?: string;
    valueProp?: string;
    showNoneChoice?: boolean;
    noneChoiceText?: string;
}
const SelectComponent: React.SFC<SelectProps> = ({
    source,
    classes,
    input,
    meta,
    label,
    choices,
    inputId,
    inputName,
    nameProp = 'name',
    valueProp,
    showNoneChoice = true,
    disabled,
    noneChoiceText = 'None',
}) => {
    const inputLabelId = React.useRef(uniqueId('ChoicesSelect'));
    return (
        <FormControl disabled={disabled} className={classes.formControl}>
            <InputLabel shrink={true} id={inputLabelId.current}>
                {label}
            </InputLabel>
            <Select
                disabled={disabled}
                value={input.value}
                onChange={e => input.onBlur(e.target.value)}
                SelectDisplayProps={{ 'aria-describedby': inputLabelId.current }}
                inputProps={{
                    name: inputName,
                    id: inputId,
                }}
            >
                {showNoneChoice ? (
                    <MenuItem value={undefined}>
                        <em>{noneChoiceText}</em>
                    </MenuItem>
                ) : null}
                {choices &&
                    Object.entries(choices).map(([key, choice]) => (
                        <MenuItem className="dropdownMenuItem" key={key} value={valueProp ? choice[valueProp] : key}>
                            {choice[nameProp]}
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    );
};
const SelectInput: React.SFC<Subtract<SelectProps, Pick<SelectProps, 'classes'>>> = withStyles(styles)(
    SelectComponent,
) as any; // tslint:disable-line

export default SelectInput;
