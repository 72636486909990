import React from 'react';
import { Link } from 'react-router-dom';
import shouldUpdate from 'recompose/shouldUpdate';
import compose from 'recompose/compose';
import { Button } from '@material-ui/core';
import ContentCreate from '@material-ui/icons/Create';

const EditButton = ({ basePath = '', label = 'Edit', record = {} }) => (
    <Button
        color="primary"
        component={props =>
            record && record.id && <Link to={`${basePath}/${encodeURIComponent(record.id)}`} {...props} />
        }
        style={{ overflow: 'inherit' }}
    >
        {label}&nbsp;
        <ContentCreate />
    </Button>
);

const enhance = compose(
    shouldUpdate(
        (props, nextProps) =>
            (props.record && nextProps.record && props.record.id !== nextProps.record.id) ||
            props.basePath !== nextProps.basePath ||
            (props.record == null && nextProps.record != null),
    ),
);

export default enhance(EditButton);
