import {
    getTabsTitlesFromView,
    getFieldInstanceEntriesFromView,
    getViewFieldInstanceEntriesForTab,
} from '../utils/viewConfigUtils/index';
import { Config, DataSource, Mode } from '../../../fieldFactory/FieldFactoryProvider';
import ViewConfig, { ViewField } from 'reducers/ViewConfigType';

export const getGetGenerateFields = <
    P extends {
        printMode?: boolean;
        referenceFieldsShouldFetchInitialData?: boolean;
        optInWriteable?: {}[];
        record?: {};
        basePath?: string;
        match?: {};
        resource?: string;
        formId?: string;
        DEBUG_disableAllFields?: boolean;
        isPopover?: boolean;
    }
>(
    fieldFactory: (config: Config) => (liveProps: {}) => (fieldInstanceEntries: [string, ViewField][]) => JSX.Element[],
    props: P,
) => {
    const config: Config = {
        dataSource: DataSource.ENTITY,
        mode: Mode.INPUT,
        validate: true,
        connected: true,
        options: {
            optInWriteable: props.optInWriteable,
        },
    };
    const getGenerateFields = (dataWasPrefetchedForThisGroup: boolean) => {
        return fieldFactory(config)({
            record: props.record,
            resource: props.resource,
            basePath: props.basePath,
            match: props.match,
            referenceFieldsShouldFetchInitialData: (() => {
                if (dataWasPrefetchedForThisGroup) {
                    return false;
                }
                return props.printMode || props.referenceFieldsShouldFetchInitialData;
            })(),
            shouldFetchValueset: false,
            embeddedInFormId: props.formId,
            disabled: props.DEBUG_disableAllFields,
            isPopover: props.isPopover,
            overrideFieldValueIfDisabled: true,
        });
    };
    return getGenerateFields;
};

const getFields = (
    fieldFactory: (config: Config) => (liveProps: {}) => (fieldInstanceEntries: [string, ViewField][]) => JSX.Element[],
    props: {
        printMode?: boolean;
        referenceFieldsShouldFetchInitialData?: boolean;
        optInWriteable?: {}[];
        record?: {};
        basePath?: string;
        match?: {};
        resource?: string;
        formId?: string;
        DEBUG_disableAllFields?: boolean;
        isPopover?: boolean;
        viewConfig: ViewConfig;
        viewName: string;
        tabToPrefetch?: string;
    },
) => {
    const getGenerateFields = getGetGenerateFields(fieldFactory, props);
    return {
        baseFields: getGenerateFields(true)(getFieldInstanceEntriesFromView(props.viewConfig, props.viewName)),
        fieldsByTab: Object.assign(
            {},
            ...getTabsTitlesFromView(props.viewConfig.views[props.viewName]).map(tabKey => ({
                [tabKey]: getGenerateFields(tabKey === props.tabToPrefetch)(
                    getViewFieldInstanceEntriesForTab(
                        props.viewConfig,
                        props.viewName,
                        props.viewConfig.views[props.viewName].tabs![tabKey],
                    ),
                ),
            })),
        ),
    };
};

export default getFields;
