import React from 'react';
import keycode from 'keycode';
import Downshift from 'downshift';
import { connect } from 'react-redux';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import { createSelector } from 'reselect';
import { MenuItem, Chip, Paper, FormControl, InputLabel, Input, FormHelperText } from '@material-ui/core';
import { lifecycle, compose } from 'recompose';
import { RootState } from '../../../reducers/rootReducer';
import classnames from 'classnames';
import { getValueSetForFieldExpr } from '../../../components/generics/utils/viewConfigUtils/index';
import { getConceptsByValueSetCode } from '../../../components/generics/utils/valueSetsUtil';
import {
    loadValueSet as loadValueSetAction,
    loadValueSetGroup as loadValueSetGroupAction,
} from '../../../actions/valueSetsActions';
import FieldTitle from './aor/FieldTitle';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import uniqueId from 'lodash/uniqueId';
import { grey } from '@material-ui/core/colors';
import { createGetEntities } from 'components/generics/form/EntityFormContext/util/getEntities';

type Input = WrappedFieldInputProps;
type Meta = WrappedFieldMetaProps;

const styles = (theme: Theme) =>
    createStyles({
        chipArea: {
            marginTop: 16,
            backgroundColor: grey[100],
        },
        root: {
            flexGrow: 1,
        },
        container: {
            flexGrow: 1,
            position: 'relative',
        },
        smallPaper: {
            maxHeight: 150,
        },
        inputUnderline: {
            '&::after': {
                borderBottom: 0,
            },
        },
        largePaper: {
            maxHeight: 300,
        },
        paper: {
            position: 'absolute',
            zIndex: 5000,
            marginTop: theme.spacing(-1),
            overflowY: 'scroll',
            left: 0,
            minWidth: '100%',
        },
        paperTop: {
            position: 'absolute',
            zIndex: 5000,
            overflowY: 'scroll',
            left: 0,
            minWidth: '100%',
            bottom: `calc(100% - ${theme.spacing(4)}px)`, // fixed disance from top
        },
        chip: {
            margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
        },
        inputRoot: {
            flexWrap: 'wrap',
        },
    });

interface Concept {
    code?: string;
    description?: string;
    display: string;
    id: string;
    sortOrder?: number;
    subtitle?: string;
    title?: string;
    valueSetId?: number;
    active?: boolean;
}

function renderSuggestion({
    suggestion,
    index,
    itemProps,
    highlightedIndex,
    selectedItem,
}: {
    suggestion: Concept;
    index?: number;
    itemProps?: {};
    highlightedIndex: number | null;
    selectedItem?: string;
}) {
    const isHighlighted = highlightedIndex === index;
    const isSelected = (selectedItem || '').indexOf(suggestion.display) > -1;

    return (
        <MenuItem
            data-value={suggestion.code}
            {...itemProps}
            key={suggestion.display}
            selected={isHighlighted}
            component="div"
            style={{
                fontWeight: isSelected ? 500 : 400,
            }}
        >
            {suggestion.display}
        </MenuItem>
    );
}

interface DownshiftMultipleProps extends WithStyles<typeof styles> {
    isPopover?: boolean;
    options?: {
        id: string;
    };
    disabled?: boolean;
    label?: string;
    input: Input;
    dataSource: Concept[];
    dataTableById: { [id: string]: Concept };
    dataTableByDisplay: { [display: string]: Concept };
    meta: Meta;
    dropdownPosition?: 'above' | 'below';
    ariaInputProps: {};
    renderLabel: boolean;
    conceptIds?: string[]; // overriding what concepts are available due to live changes in the form.;
}
interface DownshiftMultipleState {
    inputValue: string;
}

class DownshiftMultiple extends React.Component<DownshiftMultipleProps, DownshiftMultipleState> {
    emptyArray = [];
    private errorMessageId = uniqueId('valuesetmany-errormsg');
    static defaultProps = {
        ariaInputProps: {},
        renderLabel: true,
    };
    constructor(props: DownshiftMultipleProps) {
        super(props);
        this.state = {
            inputValue: '',
        };
    }
    conceptAllowedByExpression = (conceptId: string) => {
        const { conceptIds } = this.props;
        return conceptIds ? conceptIds.indexOf(conceptId) !== -1 : true;
    };
    getSelectedIds = (): string[] => {
        const { input, conceptIds } = this.props;
        const currentlySelectedInForm = (input && input.value) || this.emptyArray;
        if (conceptIds) {
            // not super efficient for extremely large lists of concepts.
            return currentlySelectedInForm.filter(this.conceptAllowedByExpression);
        }
        return currentlySelectedInForm;
    };

    getSuggestions = (inputValue: null | string) => {
        let count = 0;
        return this.props.dataSource
            .filter(suggestion => this.conceptAllowedByExpression(suggestion.id))
            .filter(suggestion => {
                const matchesInputValue =
                    !inputValue || suggestion.display.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
                const notIncludedAlready = this.getSelectedIds().indexOf(suggestion.id) === -1;
                const keep = matchesInputValue && notIncludedAlready && suggestion.active && count < 1000;

                if (keep) {
                    count += 1;
                }

                return keep;
            });
    };

    handleKeyDown = event => {
        const { inputValue } = this.state;
        const selectedIds = this.getSelectedIds();
        if (selectedIds.length && !inputValue.length && keycode(event) === 'backspace') {
            const newSelectedIds = selectedIds.slice(0, selectedIds.length - 1);
            this.props.input.onBlur(newSelectedIds);
        }
    };

    handleInputChange = event => {
        this.setState({ inputValue: event.target.value });
    };

    handleChange = (item: Concept) => {
        let selectedIds = this.getSelectedIds();
        if (selectedIds.indexOf(item.id) === -1) {
            selectedIds = [...selectedIds, item.id];
        }

        this.setState(
            {
                inputValue: '',
            },
            () => this.props.input.onBlur(selectedIds),
        );
    };

    handleDelete = (id: string) => () => {
        const selectedIds = [...this.getSelectedIds()];
        selectedIds.splice(selectedIds.indexOf(id), 1);

        this.props.input.onBlur(selectedIds);
    };
    render() {
        const {
            classes,
            disabled = false,
            options: { id: inputId } = { id: 'valueset-multiple' },
            dropdownPosition = 'below',
            renderLabel,
            isPopover = false,
            label,
            meta: { touched, error },
        } = this.props;
        const { inputValue } = this.state;
        const selectedIds = this.getSelectedIds();
        const paperClass = classnames(
            dropdownPosition === 'below' ? classes.paper : classes.paperTop,
            isPopover || dropdownPosition === 'above' ? classes.smallPaper : classes.largePaper,
        );

        return (
            <Downshift
                itemToString={item => (item ? item.display : '')}
                inputValue={inputValue}
                onChange={this.handleChange}
                selectedItem={selectedIds.map(id => this.props.dataTableById[id]) as any} // tslint:disable-line
            >
                {({
                    getInputProps,
                    getItemProps,
                    isOpen,
                    inputValue: inputValue2,
                    selectedItem: selectedItem2,
                    highlightedIndex,
                    getMenuProps,
                    openMenu,
                    getLabelProps,
                    getRootProps,
                }) => {
                    const InputProps = getInputProps({
                        label: this.props.label,
                        onClick: () => !disabled && openMenu(),
                        onBlur: () => {
                            this.props.input.onBlur(undefined);
                        },
                        onChange: this.handleInputChange,
                        onKeyDown: this.handleKeyDown,
                        placeholder: disabled ? '' : 'Select values',
                        id: inputId,
                        disabled,
                        'aria-haspopup': true,
                        'aria-roledescription': 'Type to filter results',
                        'aria-errormessage':
                            this.props.meta.touched && this.props.meta.error ? this.errorMessageId : undefined,
                    });
                    return (
                        <FormControl
                            fullWidth={true}
                            margin="none"
                            style={{ zIndex: 'unset' }}
                            error={Boolean(touched && error)}
                            disabled={disabled}
                        >
                            {renderLabel && (
                                <InputLabel focused={false} shrink={true} {...getLabelProps()}>
                                    <FieldTitle label={label} isRequired={false} />
                                </InputLabel>
                            )}
                            <div className={classes.chipArea}>
                                {selectedIds.map(id => (
                                    <Chip
                                        key={id}
                                        tabIndex={0}
                                        aria-roledescription="Button Press Delete key to delete"
                                        label={this.props.dataTableById[id] && this.props.dataTableById[id].display}
                                        className={classes.chip}
                                        onDelete={() => !disabled && this.handleDelete(id)()}
                                        deleteIcon={disabled ? <span style={{ width: '.5em' }} /> : undefined}
                                    />
                                ))}
                            </div>
                            <div {...getRootProps()} className={classes.container}>
                                <Input
                                    fullWidth={true}
                                    classes={{
                                        root: classes.inputRoot,
                                        underline: classes.inputUnderline,
                                    }}
                                    inputProps={{
                                        ...InputProps,
                                        'aria-label': label,
                                    }}
                                    {...(InputProps as any)}
                                />
                                {touched && error && (
                                    <FormHelperText id={InputProps['aria-errormessage']}>Error: {error}</FormHelperText>
                                )}
                                <div {...getMenuProps()}>
                                    {isOpen ? (
                                        <Paper className={paperClass} square={true}>
                                            {this.getSuggestions(inputValue2).map((suggestion, index) =>
                                                renderSuggestion({
                                                    suggestion,
                                                    index,
                                                    itemProps: getItemProps({ item: suggestion }),
                                                    highlightedIndex,
                                                    selectedItem: selectedItem2 as any, // tslint:disable-line
                                                }),
                                            )}
                                        </Paper>
                                    ) : null}
                                </div>
                            </div>
                        </FormControl>
                    );
                }}
            </Downshift>
        );
    }
}

const getValueSetGroup = (state, props) => props.group || null;

const makeMapStateToProps = () => {
    const emptyObj = {};
    const getEntities = createGetEntities();
    const getConcepts = createSelector(
        getEntities,
        entities => ((entities as any).Concept || emptyObj) as { [id: string]: Concept },
    );
    return createSelector(
        (state: RootState) => state.valueSets,
        getValueSetGroup,
        (state: RootState, props) =>
            props.valueSet ||
            getValueSetForFieldExpr(
                state.viewConfig,
                props.resource,
                props.source.slice(0, -3), // pop off 'Ids'
                'TRAVERSE_PATH',
            ),
        getConcepts,
        (valueSets, group, valueSetCode, concepts) => {
            const conceptsList = getConceptsByValueSetCode(valueSets, valueSetCode, concepts, group);
            const dataTableById: { [id: string]: Concept } = (() => {
                const dt = {};
                conceptsList.forEach(o => (dt[o.id] = o));
                return dt;
            })();
            const dataTableByDisplay: { [display: string]: Concept } = (() => {
                const dt = {};
                conceptsList.forEach(o => (dt[o.display.toLowerCase()] = o));
                return dt;
            })();
            return {
                dataSource: conceptsList,
                dataTableById,
                dataTableByDisplay,
                valueSetCode,
            };
        },
    );
};
export const valueSetsManyHoc = compose(
    connect(
        makeMapStateToProps,
        {
            loadValueSet: loadValueSetAction,
            loadValueSetGroup: loadValueSetGroupAction,
        },
    ),
    lifecycle({
        componentDidMount() {
            const {
                loadValueSet,
                loadValueSetGroup,
                valueSet,
                valueSetCode,
                group,
                shouldFetchValueset = true,
            } = this.props;
            if ((valueSet || valueSetCode) && shouldFetchValueset) {
                if (group) {
                    group.split(',').forEach(g => {
                        loadValueSetGroup(valueSet || valueSetCode, g);
                    });
                } else {
                    loadValueSet(valueSet || valueSetCode);
                }
            }
        },
        componentWillReceiveProps(nextProps: { valueSet?: string; valueSetCode?: string; group?: string }) {
            if (
                (this.props.valueSet !== nextProps.valueSet && nextProps.valueSet) ||
                (this.props.group !== nextProps.group && nextProps.group) ||
                (this.props.valueSetCode !== nextProps.valueSetCode && nextProps.valueSetCode)
            ) {
                if (nextProps.group) {
                    nextProps.group.split(',').forEach(g => {
                        this.props.loadValueSetGroup(nextProps.valueSet || nextProps.valueSetCode, g);
                    });
                } else {
                    this.props.loadValueSet(nextProps.valueSet || nextProps.valueSetCode);
                }
            }
        },
    }),
);
const ValuesetMany = compose(
    valueSetsManyHoc,
    withStyles(styles),
)(DownshiftMultiple);

export default ValuesetMany;
