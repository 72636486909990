import * as React from 'react';
import { SFC, ReactElement } from 'react';
import { connect } from 'react-redux';
import withHandlers from 'recompose/withHandlers';
import lifecycle from 'recompose/lifecycle';
import { withWidth, TextField } from '@material-ui/core';
import compose from 'recompose/compose';
import { Dialog } from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';
import Create from '@material-ui/icons/Create';
import Add from '@material-ui/icons/Add';
import Toolbar from 'components/generics/form/Toolbar.aor';
import SaveButton from 'components/generics/button/SaveButton';
import CloseButton from './PopoverCloseButton';
import { crudGetOne as crudGetOneAction } from 'sideEffect/crud/getOne/actions';
import GenericCreate from '../../components/generics/genericCreate';
import GenericDelete from '../../components/generics/genericDelete';
import GenericEdit from '../../components/generics/genericEdit';
import { RootState } from '../../reducers/rootReducer';
import withBox from '../input/components/hoc/withBox';

const TextFieldWithBox = withBox()(props => <TextField {...props} disabled={true} />);

/*
    Displays selected record, and triggers popover with onClick prop.
*/
const floatingButtonStyle = { position: 'absolute', right: 0, top: 36, cursor: 'pointer', zIndex: 2 };
let ReferenceDisplay: SFC<{
    onClick: (event: any /*MouseEvent<HTMLDivElement>*/) => void; // tslint:disable-line no-any
    label: string | ReactElement<{}>;
    reference: string;
    allowEmpty: boolean;
    input: { value: string | number; onChange: Function; onBlur: Function };
    meta: {};
    basePath: string;
    record?: { id: string };
    resource: string;
    referenceRecord?: { title: string; id: string };
    source: string;
    ariaInputProps?: {};
    renderLabel?: boolean;
    onClickDelete: Function;
}> = ({ referenceRecord, label, input, onClick, onClickDelete, ariaInputProps, renderLabel }) => (
    <div
        tabIndex={0}
        role="button"
        onClick={onClick}
        style={{ position: 'relative', display: 'inline-block', width: '100%', margin: 0 }}
    >
        <div
            style={{
                // adding div to block disabled TextField from click event
                // ('disabled' prop prevents click propagation in firefox)
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 1,
                height: '100%',
                width: '100%',
                cursor: 'pointer',
            }}
        />
        <TextFieldWithBox
            fullWidth={true}
            style={{ cursor: 'pointer', width: 'calc(100% - 25px)' }}
            underlineStyle={{ borderBottom: '1px solid rgb(224, 224, 224)' }}
            floatingLabelText={renderLabel ? label : undefined}
            inputProps={{ ...ariaInputProps }}
            value={referenceRecord ? referenceRecord.title || referenceRecord.id : ''}
        />
        {input.value !== null && typeof input.value !== 'undefined' && input.value !== '' ? (
            <div>
                <Create
                    style={{ ...floatingButtonStyle, right: 36 } as any} // tslint:disable-line
                />
                <Clear
                    style={floatingButtonStyle as any} // tslint:disable-line
                    onClick={evt => {
                        evt.preventDefault();
                        evt.stopPropagation();
                        /*
                                hook into deletion for record
                            */
                        if (onClickDelete && typeof onClickDelete === 'function') {
                            onClickDelete();
                        } else {
                            input.onBlur(null); // set reference to null
                        }
                    }}
                />
            </div>
        ) : (
            <Add
                style={floatingButtonStyle as any} // tslint:disable-line
            />
        )}
    </div>
);

function mapStateToProps(
    state: RootState,
    props: {
        input: { value: string };
        reference: string;
        resource: string;
        source: string;
    },
) {
    const referenceId = props.input.value;
    return {
        referenceRecord: (state.admin.entities[props.reference] || {})[referenceId],
    };
}
const enhanceRefDisplay = compose(
    connect(
        mapStateToProps,
        {
            crudGetOne: crudGetOneAction,
        },
    ),
    withHandlers({
        fetchReference: props => ({ crudGetOne, reference, input } = props) => {
            const id = input.value;
            if (id) {
                crudGetOne({
                    resource: reference,
                    id,
                    view: null,
                });
            }
        },
    }),
    lifecycle({
        componentDidMount() {
            this.props.fetchReference();
        },
        componentWillReceiveProps(nextProps: { record: { id: string }; referenceRecord: { id: string } }) {
            if (
                (!this.props.record && nextProps.record && nextProps.record.id) || // record is instantiated
                (nextProps.record && this.props.record && this.props.record.id !== nextProps.record.id) || // record is changed
                (nextProps.referenceRecord &&
                    (!this.props.referenceRecord || nextProps.referenceRecord.id !== this.props.referenceRecord.id)) // referenceRecord changed
            ) {
                this.props.fetchReference(nextProps);
            }
        },
    }),
);
ReferenceDisplay = enhanceRefDisplay(ReferenceDisplay);

interface PopoverRefInputProps {
    basePath: string;
    input: { value: string; onChange: Function; onBlur: Function };
    label: string | ReactElement<{}>;
    meta: {};
    record: { id: string };
    reference: string;
    resource: string;
    source: string;
    resourceBasePath: string;
    ariaInputProps?: {};
    renderLabel?: boolean;
}
interface PopoverRefInputState {
    open: boolean;
    deleteOpen: boolean;
    location: {
        pathname: string;
        search: string;
    };
    selected: {};
}
/*
    Controlled component putting it all together.
*/
class PopoverRefInput extends React.Component<PopoverRefInputProps, PopoverRefInputState> {
    static defaultProps = {
        label: 'Create',
        resourceBasePath: '',
        parentEntityName: '',
        parentFieldInChild: '',
        parentId: '',
    };
    /* @lazyInject(TYPES.EditView) private editView: new () => EditView; */

    constructor(props: PopoverRefInputProps) {
        super(props);
        this.state = {
            open: false,
            deleteOpen: false,
            location: {
                pathname: this.props.resourceBasePath,
                search: '',
            },
            selected: {},
        };
    }

    setReference = data => {
        // this.props.onChange(data.id);
        this.props.input.onBlur(data.id || data);
        this.handleClose();
    };

    unsetReference = () => {
        // this.props.onChange(data.id);
        this.props.input.onBlur(null);
    };

    handleOpen = () => {
        this.setState({ open: true });
    };
    handleDeleteOpen = () => {
        this.setState({ deleteOpen: true });
    };

    handleClose = () => {
        this.setState({
            open: false,
            deleteOpen: false,
            location: {
                pathname: this.props.resourceBasePath,
                search: '',
            },
            selected: {},
        });
    };

    unstage = id => {
        this.setState(state => {
            // const { [id]: forUnstage, ...rest } =
            // state.selected; <-- destructuring spread doesn't work with number keys! (this is a bug)

            // create a new object to delete from to prevent side effects.
            const rest = Object.assign({}, state.selected);

            delete rest[id];
            return {
                ...state,
                selected: {
                    ...rest,
                },
            };
        });
    };

    render() {
        const {
            basePath,
            input,
            label,
            meta,
            record,
            reference,
            resource,
            source,
            ariaInputProps,
            renderLabel,
        } = this.props;

        return (
            <div>
                <ReferenceDisplay
                    onClick={this.handleOpen}
                    label={label}
                    reference={reference}
                    allowEmpty={true}
                    input={input}
                    meta={meta}
                    basePath={basePath}
                    record={record}
                    resource={resource}
                    source={source}
                    onClickDelete={this.handleDeleteOpen}
                    renderLabel={renderLabel}
                    ariaInputProps={ariaInputProps}
                />
                <Dialog
                    TransitionProps={
                        {
                            // https://github.com/dequelabs/axe-core/issues/146
                            role: 'presentation',
                        } as any
                    }
                    open={this.state.open}
                    onClose={this.handleClose}
                    maxWidth={false}
                    fullWidth={true}
                >
                    <div>
                        {!input.value && (
                            <GenericCreate
                                {...{
                                    viewName: `${reference}Create`,
                                    formId: `popover-create-form-${reference}-Parent:${reference}:${record.id}`,
                                    redirect: false,
                                    location: {
                                        pathname: `${reference}/create`,
                                        search: `?parentEntity=${resource}&parentField=${
                                            /* no way to distinguish */ ''
                                        }&parentId=${record.id}`,
                                    },
                                    resource: reference,
                                    onCreateCb: this.setReference,
                                    name: reference,
                                    toolbar: (
                                        <Toolbar>
                                            <CloseButton handleClose={this.handleClose} />
                                            <SaveButton />
                                        </Toolbar>
                                    ),
                                }}
                            />
                        )}
                        {input.value && (
                            <GenericEdit
                                {...{
                                    viewName: `${reference}Edit`,
                                    formId: `popover-edit-form-${reference}-${input.value}`,
                                    redirect: false,
                                    hasList: false,
                                    location: { pathname: `/${reference}/${input.value}` },
                                    match: {
                                        isExact: true,
                                        params: {
                                            id: input.value,
                                            basePath: `/${reference}`,
                                        },
                                    },
                                    resource: reference,
                                    onSaveCb: this.handleClose,
                                    name: reference,
                                    toolbar: (
                                        <Toolbar>
                                            <CloseButton handleClose={this.handleClose} />
                                            <SaveButton />
                                        </Toolbar>
                                    ),
                                }}
                            />
                        )}
                    </div>
                </Dialog>
                <Dialog
                    TransitionProps={
                        {
                            // https://github.com/dequelabs/axe-core/issues/146
                            role: 'presentation',
                        } as any
                    }
                    open={this.state.deleteOpen}
                    onClose={this.handleClose}
                    maxWidth={false}
                    fullWidth={true}
                >
                    <div>
                        <GenericDelete
                            id={input.value}
                            // title: PropTypes.any,
                            resource={reference}
                            location={{ pathname: `/${reference}/${input.value}/delete` }}
                            match={{
                                isExact: true,
                                params: {
                                    id: input.value,
                                    basePath: `/${reference}`,
                                },
                            }}
                            onDeleteCb={this.handleClose}
                            onCancelCb={this.handleClose}
                        />
                    </div>
                </Dialog>
            </div>
        );
    }
}

const enhance = compose(
    connect(
        null,
        {
            crudGetOne: crudGetOneAction,
        },
    ),
    withWidth({
        initialWidth: 'md',
    }),
);

export default enhance(PopoverRefInput);
