import * as t from 'io-ts';
import getErrorFromConfig from '../getErrorFromConfig';

const forSearchConfig = t.partial({
    direction: t.union([t.literal('HORIZONTAL'), t.literal('VERTICAL')]),
    suppressLabel: t.boolean,
});
type ForSearchConfig = t.TypeOf<typeof forSearchConfig>;

const forSearchExample: ForSearchConfig = {
    direction: 'HORIZONTAL',
    suppressLabel: true,
};

const forSearchConfigValidation = getErrorFromConfig(
    forSearchConfig,
    `Invalid configuration for checkboxes. Example: ${JSON.stringify(forSearchExample)}`,
    `Invalid json. Example: ${JSON.stringify(forSearchExample)}`,
);
export default forSearchConfigValidation;
