import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import withPropsOnChange from 'recompose/withPropsOnChange';
import { withStyles } from '@material-ui/core/styles';
import { RootState } from '../../../../reducers/rootReducer';
import ValueSetSelect from '../ValueSelectDownshift';
import TextInput from '../TextInput';
import { Concept } from '../ValueSelectDownshift';
import { configIsLine1Line2, addressFieldLabels, renderVerificationMessage } from './util';
import get from 'lodash/get';
import './registerEVIConfigValidation';
import { IFieldMapping, IAddressWidgetConfig, Values } from './types';
import { loadValueSet as loadValueSetAction } from '../../../../actions/valueSetsActions';
import { createConceptInvertedIndexSelector, withFieldConfigs } from './popoverAddress';

interface AddressValidationInputProps {
    record: {};
    source: string;
    label: string;
    values: Values;
    validationState?: false | 'manual' | 'verified';
    viewConfig: RootState['viewConfig'];
    concepts: {
        [id: number]: Concept;
    };
    stateConceptsByCode: {
        [code: string]: Concept;
    };
    townCodeConceptsByCode: {
        [display: string]: Concept;
    };
    communityConceptsByCode: {
        [code: string]: Concept;
    };
    classes: {
        error: string;
        primary: string;
    };
    addrFieldConfig: IFieldMapping;
    invertedAddrFieldConfig: { [key: string]: string };
    showVerificationStatus: boolean;
    verifStatusConceptsByCode: {
        [code: string]: Concept;
    };
    loadVerifStatusValueSet: () => void;
}

const styles = theme => ({
    error: {
        color: theme.palette.error.main,
    },
    primary: {
        color: theme.palette.primary.main,
    },
});

class AddressValidationInput extends React.Component<AddressValidationInputProps> {
    componentDidMount() {
        this.props.loadVerifStatusValueSet();
    }
    renderFieldGroup = (fields: (keyof typeof addressFieldLabels)[]) => {
        const { addrFieldConfig } = this.props;
        return (
            <div style={{ marginTop: '16px', marginBottom: '8px' }}>
                {fields
                    .map(hfn => [addrFieldConfig[hfn], hfn])
                    .filter(f => f[0])
                    .map((
                        [fname, basename], // tslint:disable-line
                    ) =>
                        fname.endsWith('Id') ? (
                            <ValueSetSelect
                                disabled={true}
                                key={fname}
                                label={addressFieldLabels[basename]}
                                source={fname}
                                resource="Address"
                                input={{
                                    value: this.props.values[fname],
                                    onBlur: () => null,
                                }}
                                emptyText={''}
                                meta={{}}
                                shouldFetchValueset={true}
                            />
                        ) : (
                            <TextInput
                                label={addressFieldLabels[basename]}
                                source={fname}
                                key={fname}
                                input={{
                                    value: this.props.values[fname] || '',
                                }}
                                meta={{}}
                                resource="Address"
                                validate={() => null}
                                options={{
                                    fullWidth: true,
                                }}
                                disabled={true}
                            />
                        ),
                    )}
            </div>
        );
    };
    setupCodesAndIds = (values: IAddressWidgetConfig['fieldMapping'] = {}) => {
        const { townCodeConceptsByCode, communityConceptsByCode, stateConceptsByCode } = this.props;
        // setup Codes and Ids if found
        return Object.assign(
            {},
            ...Object.entries(values).flatMap(
                ([key, _value]: [keyof IAddressWidgetConfig['fieldMapping'], any]): {} => {
                    // tslint:disable-line
                    const value = typeof _value === 'string' ? _value.toLowerCase() : _value;
                    if (key === 'casetivityCityCode') {
                        const c = townCodeConceptsByCode[value];
                        return [{ casetivityCityId: c && c.id }];
                    }
                    if (key === 'casetivityNeighborhoodCode') {
                        const c = communityConceptsByCode[value];
                        return [{ casetivityNeighborhoodId: c && c.id }];
                    }
                    if (key === 'casetivityStateCode') {
                        const c = stateConceptsByCode[value];
                        return [{ casetivityStateId: c && c.id }];
                    }
                    return [];
                },
            ),
            values,
        );
    };
    cellWidth = (multiples: number, columns: number) => `calc(${multiples * (100 / columns)}% - 6px)`;
    renderVerificationMessage = () => {
        const { showVerificationStatus, classes, record, source, verifStatusConceptsByCode } = this.props;
        if (!showVerificationStatus) {
            return null;
        }
        const value = get(record, source);
        return renderVerificationMessage(classes, value, verifStatusConceptsByCode);
    };
    render() {
        const { addrFieldConfig } = this.props;
        return (
            <div>
                {this.renderVerificationMessage()}
                <div>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        {configIsLine1Line2(addrFieldConfig) ? (
                            <div style={{ width: this.cellWidth(5, 5) }}>
                                {this.renderFieldGroup(['casetivityLine2'])}
                            </div>
                        ) : (
                            [
                                <div key="strNum" style={{ width: this.cellWidth(1, 5) }}>
                                    {this.renderFieldGroup(['casetivityHouse'])}
                                </div>,
                                <div key="strName" style={{ width: this.cellWidth(3, 5) }}>
                                    {this.renderFieldGroup(['casetivityStreet'])}
                                </div>,
                                <div key="unit" style={{ width: this.cellWidth(1, 5) }}>
                                    {this.renderFieldGroup(['casetivityUnit'])}
                                </div>,
                            ]
                        )}
                    </div>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        {configIsLine1Line2(addrFieldConfig) ? (
                            <div style={{ width: this.cellWidth(2, 2) }}>
                                {this.renderFieldGroup(['casetivityLine1'])}
                            </div>
                        ) : null}
                    </div>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div style={{ width: this.cellWidth(1, 4) }}>
                            {this.renderFieldGroup([
                                addrFieldConfig.casetivityNeighborhoodId
                                    ? 'casetivityNeighborhoodId'
                                    : 'casetivityNeighborhoodCode',
                            ])}
                        </div>
                        <div style={{ width: this.cellWidth(1, 4) }}>
                            {this.renderFieldGroup([
                                addrFieldConfig.casetivityCityId ? 'casetivityCityId' : 'casetivityCityCode',
                            ])}
                        </div>
                        <div style={{ width: this.cellWidth(1, 4) }}>
                            {this.renderFieldGroup([
                                addrFieldConfig.casetivityStateId ? 'casetivityStateId' : 'casetivityStateCode',
                            ])}
                        </div>
                        <div style={{ width: this.cellWidth(1, 4) }}>{this.renderFieldGroup(['casetivityZip'])}</div>
                    </div>
                </div>
            </div>
        );
    }
}

interface PAWithConfigValProps {
    source: string;
    label: string;
    config: IAddressWidgetConfig['fieldMapping'];
}
type ConnectLevelProps = PAWithConfigValProps & {
    addrFieldConfig: IFieldMapping;
    invertedAddrFieldConfig: { [key: string]: string };
};

const makeMapStateToProps = () => {
    const stateConceptsByCodeSelector = createConceptInvertedIndexSelector('State')('code');
    const communityConceptsByCodeSelector = createConceptInvertedIndexSelector('Community')('code');
    const townCodeConceptsByCodeSelector = createConceptInvertedIndexSelector('TownCode')('code');
    const verifStatusConceptsByCodeSelector = createConceptInvertedIndexSelector('VerificationStatus')('code');

    return (state: RootState, props: ConnectLevelProps) => {
        return {
            viewConfig: state.viewConfig,
            concepts: state.admin.entities.Concept,
            stateConceptsByCode: stateConceptsByCodeSelector(state),
            communityConceptsByCode: communityConceptsByCodeSelector(state),
            townCodeConceptsByCode: townCodeConceptsByCodeSelector(state),
            verifStatusConceptsByCode: verifStatusConceptsByCodeSelector(state),
        };
    };
};

const PopoverAddress: React.SFC<PAWithConfigValProps> = compose(
    withFieldConfigs,
    withPropsOnChange(
        ['record', 'addrFieldConfig'],
        ({ record, addrFieldConfig }: { record: {}; addrFieldConfig: IFieldMapping }) => {
            return {
                values: Object.assign(
                    {},
                    ...Object.entries(addrFieldConfig).map(([casetivityName, formName]: [string, string]) => ({
                        [formName]: record[formName],
                    })),
                ),
            };
        },
    ),
    connect(
        makeMapStateToProps,
        {
            loadVerifStatusValueSet: () => loadValueSetAction('VerificationStatus'),
        },
    ),
    withStyles(styles),
)(AddressValidationInput);

export default PopoverAddress;
