import isPojo from '../isPojo';
import ViewConfig from '../../reducers/ViewConfigType';
import { fromNullable } from 'fp-ts/lib/Option';
import { getRefEntityName } from '../../components/generics/utils/viewConfigUtils';
import { tryCatch } from 'fp-ts/lib/Either';

const hasId = (obj: {}): obj is { id: string } => !!obj['id']; // tslint:disable-line

const insertIdsAndVersionNumbers = (
    initialValues: {},
    submissionData: {},
    entities: {
        [entityName: string]: {
            [id: string]: {
                id: string;
                entityType: string;
                entityVersion?: number; // Concepts don't always have their entityVersion
            };
        };
    },
    rootEntity: string,
    viewConfig: ViewConfig,
) => {
    //  iterate over key/values
    //  if value is a plain-old-javascript-object:
    //      lookup id:
    //          if id is present,
    //              get the entityName
    //                  (look up entityType in initialValues) * works iff entitytypes dont change
    //              look up the versionNumber in entities bag
    //              [return object with versionNumber added]
    //          if id is not present,
    //              look up id and versionNumber in initialValues for that field
    const injectInExpansion = (relEntity: string, k: string, v: {}): { [k: string]: {} } => {
        const getEntityVersionFromId = (id: string) =>
            fromNullable(entities[relEntity])
                .map(e => e[id])
                .chain(fromNullable)
                .map(e => e.entityVersion)
                .chain(fromNullable);
        if (hasId(v)) {
            const entityVersion = getEntityVersionFromId(v.id);
            if (entityVersion.isSome()) {
                // now that we know the id and versionNumber
                return {
                    [k]: insertIdsAndVersionNumbers(
                        // keep going!
                        initialValues[k],
                        {
                            ...v,
                            entityVersion: entityVersion.value,
                        },
                        entities,
                        relEntity,
                        viewConfig,
                    ),
                };
            }
            // entityVersion not found in entityBag
            throw new Error(`entityVersion for ${relEntity} ${v.id} not found.`);
        }
        // id not found. Look up in initialValues.
        return fromNullable(initialValues[k])
            .map(o => o.id)
            .chain(fromNullable)
            .fold(
                { [k]: v }, // lets consider this like a 'create'. no id to be sent because there never was one.
                id =>
                    getEntityVersionFromId(id)
                        .map(entityVersion => {
                            return {
                                [k]: insertIdsAndVersionNumbers(
                                    initialValues[k],
                                    {
                                        ...v,
                                        id,
                                        entityVersion,
                                    },
                                    entities,
                                    relEntity,
                                    viewConfig,
                                ),
                            };
                        })
                        .getOrElse({ [k]: v }),
            );
    };
    return Object.assign(
        {},
        ...Object.entries(submissionData).map(([k, v]): { [key: string]: unknown } => {
            const getExpansionBasedOnInitialValueEntityTypeAndSkipOtherwise = (err?: Error) => {
                if (err) {
                    console.error(err);
                } // tslint:disable-line
                return fromNullable(initialValues[k])
                    .map(o => o.entityType)
                    .chain(fromNullable)
                    .foldL(
                        () => {
                            // At this point neither the viewConfig nor the initialValues
                            // can tell us about the relation. lets skip it.
                            return { [k]: v };
                        },
                        relEntity => injectInExpansion(relEntity, k, v),
                    );
            };
            if (isPojo(v)) {
                return tryCatch(() => getRefEntityName(viewConfig, rootEntity, k, 'TRAVERSE_PATH')).fold(
                    getExpansionBasedOnInitialValueEntityTypeAndSkipOtherwise,
                    expansionRelatedEntity => {
                        if (expansionRelatedEntity === 'CasetivityEntity') {
                            /*
                                  We don't handle 'wildcard' entity references here.
                                  Generally, forms change references only if the entitytype is known via the viewConfig.
                                  We could search all entities for the id to get the versionNumber, but

                                  Lets gracefully handle this case and degrade.
                                */
                            return getExpansionBasedOnInitialValueEntityTypeAndSkipOtherwise();
                        }
                        return injectInExpansion(expansionRelatedEntity, k, v);
                    },
                );
            }
            return { [k]: v };
        }),
    );
};

export default insertIdsAndVersionNumbers;
