import {
    required,
    email,
    requiredNonEmptyArray,
    maxLength,
    minLength,
    maxValue,
    minValue,
    regex,
    getValidationInFormContext,
} from 'fieldFactory/util/validate';
import * as WidgetTypes from '../../../components/generics/utils/widgetTypes';
import * as FieldDataTypes from '../../../components/generics/utils/fieldDataTypes';
import {
    getRequiredForFieldExpr,
    getMaxSizeForFieldExpr,
    getMinSizeForFieldExpr,
    getPatternForFieldExpr,
    getDataTypeForFieldExpr,
} from '../../../components/generics/utils/viewConfigUtils';
import traverseGetData from 'casetivity-shared-js/lib/viewConfigSchema/traverseGetData';

export default (viewConfig, configuredEntity, name, widgetType) => {
    // if it starts with 'linked' and contains a . afterwards, It's a search field.
    // const isSearchField = (name.startsWith('linked') && name.indexOf('.') !== -1);
    const requiredValue = getRequiredForFieldExpr(viewConfig, configuredEntity, name);
    const maxSizeValue = getMaxSizeForFieldExpr(viewConfig, configuredEntity, name);
    const minSizeValue = getMinSizeForFieldExpr(viewConfig, configuredEntity, name);
    const patternString = getPatternForFieldExpr(viewConfig, configuredEntity, name);
    const dataType = getDataTypeForFieldExpr(viewConfig, configuredEntity, name);

    const validate = [];
    if (requiredValue) {
        if (dataType === FieldDataTypes.REFMANYMANY || dataType === FieldDataTypes.VALUESET_MANY) {
            validate.push(requiredNonEmptyArray);
        } else if (widgetType === WidgetTypes.NULLABLE_BOOLEAN) {
            // for nullable boolean fields, we require a non-null value
            validate.push((value, _, props) => {
                return Boolean(value) !== value ? 'Required' : undefined;
            });
        } else if (widgetType === WidgetTypes.CHECKBOX || widgetType === WidgetTypes.TOGGLE) {
            // Required validation doesn't apply because it will always be either true or false
        } else {
            validate.push(required);
        }
    }
    if (widgetType === WidgetTypes.EMAIL) {
        validate.push(email);
    }
    try {
        if (
            widgetType === WidgetTypes.FILEUPLOAD &&
            getRequiredForFieldExpr(viewConfig, configuredEntity, `${name}Identifier`)
        ) {
            validate.push((value, allValues, props) => {
                // Create views have an empty record here, so check 'id'
                if (props.record && props.record.id && props.entities) {
                    // there is an existing record - we have to check the documentIdentifier
                    const documentIdentifier = traverseGetData(
                        viewConfig,
                        `${name}Identifier`,
                        props.record,
                        props.entities,
                        false,
                    );
                    if ((!documentIdentifier && !value) || value === null) {
                        // null means 'will be unset'. undefined means 'use linked file'
                        return 'Required';
                    }
                } else if (!value) {
                    return 'Required';
                }
                return undefined;
            });
        }
    } catch (e) {
        console.error(e);
    }

    if (maxSizeValue !== undefined) {
        if (dataType === FieldDataTypes.STRING || dataType === FieldDataTypes.TEXTBLOB) {
            validate.push(maxLength(maxSizeValue));
        }
        if (dataType === FieldDataTypes.INTEGER || dataType === FieldDataTypes.LONG) {
            validate.push(maxValue(maxSizeValue, undefined, 'INTEGER'));
        }
        if (
            dataType === FieldDataTypes.BIGDECIMAL ||
            dataType === FieldDataTypes.FLOAT ||
            dataType === FieldDataTypes.DOUBLE
        ) {
            validate.push(maxValue(maxSizeValue, undefined, 'FLOAT'));
        }
    }
    if (minSizeValue !== undefined) {
        if (dataType === FieldDataTypes.STRING || dataType === FieldDataTypes.TEXTBLOB) {
            validate.push(minLength(minSizeValue));
        }
        if (dataType === FieldDataTypes.INTEGER || dataType === FieldDataTypes.LONG) {
            validate.push(minValue(minSizeValue, undefined, 'INTEGER'));
        }
        if (
            dataType === FieldDataTypes.BIGDECIMAL ||
            dataType === FieldDataTypes.FLOAT ||
            dataType === FieldDataTypes.DOUBLE
        ) {
            validate.push(minValue(minSizeValue, undefined, 'FLOAT'));
        }
    }

    if (patternString !== undefined) {
        const pattern = new RegExp(patternString);
        validate.push(regex(pattern, `Invalid format. The pattern is ${decodeURIComponent(patternString)}`));
    }
    const fieldSource =
        (dataType === 'VALUESETMANY' || dataType === 'REFMANYMANY') && !name.endsWith('Ids')
            ? `${name}Ids`
            : (dataType === 'VALUESET' || dataType === 'REFONE') && !name.endsWith('Id')
            ? `${name}Id`
            : name;
    return validate.map(validation => getValidationInFormContext(validation, fieldSource));
};
