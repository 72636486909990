import produce from 'immer';
import fromEntries from 'util/fromentries';
import { DontOverwrite } from 'sideEffect/crud/util/epics/CoreCrud/shared';

const customMappings = {
    AppCase: {
        // from [guaranteedfield] to [link] in case it's not as simple as stripping 'Id' off
        processInstanceId: ['processInstance', 'processInstanceId'],
    },
};
const alwaysKeepKeys = {
    ProcessInstance: {
        appCase: true,
    },
};

export const ensureReferencesAreNotErasedIfNotExpanded = (data: {}, entityName: string) => {
    return Object.assign(
        {},
        ...Object.entries(data).map(([key, value]) => {
            let toReturn = { [key]: value };
            const otherFields = entityName && customMappings[entityName] ? customMappings[entityName][key] : null;
            if (otherFields) {
                const otherFieldsNotOnData = otherFields.filter(f => !Object.prototype.hasOwnProperty.call(data, f));
                toReturn = Object.assign({}, toReturn, ...otherFieldsNotOnData.map(f => ({ [f]: value })));
            }
            if (key.endsWith('Id') && !Object.prototype.hasOwnProperty.call(data, key.slice(0, -2))) {
                toReturn[`${key.slice(0, -2)}`] = value;
            }
            return toReturn;
        }),
    );
};

interface Records {
    [entityName: string]: {
        [id: string]: {};
    };
}
export const addRecords = (prevState: Records, newEntities: Records, dontOverwrite?: DontOverwrite): Records => {
    return produce(prevState, draftState => {
        Object.entries(newEntities).forEach(([entityName, dataMap]) => {
            Object.entries(dataMap).forEach(([id, data]) => {
                if (!draftState[entityName]) {
                    draftState[entityName] = {};
                }

                draftState[entityName][id] = {
                    // keep old many-relations
                    ...(draftState[entityName][id]
                        ? fromEntries(
                              Object.entries(draftState[entityName][id]).filter(([field]) => {
                                  const isManyRelated =
                                      field.endsWith('Ids') || draftState[entityName][id][field + 'Ids'];
                                  const includedInDontOverrideList =
                                      dontOverwrite &&
                                      dontOverwrite[entityName] &&
                                      dontOverwrite[entityName][id] &&
                                      (() => {
                                          const entry = dontOverwrite[entityName][id];
                                          if (entry[field]) {
                                              return true;
                                          }
                                          if (field.endsWith('Id')) {
                                              return entry[field.slice(0, -2)];
                                          }
                                          return entry[field + 'Id'];
                                      })();
                                  const inAlwaysKeep = alwaysKeepKeys[entityName] && alwaysKeepKeys[entityName][field];
                                  return isManyRelated || includedInDontOverrideList || inAlwaysKeep;
                              }),
                          )
                        : undefined),
                    entityType: entityName,
                    ...ensureReferencesAreNotErasedIfNotExpanded(data, entityName),
                };
            });
        });
    });
};

const entities = (state = { Concept: {} }, action) => {
    const payload = action.payload;
    if (payload && payload.data && payload.data.entities) {
        return addRecords(state, payload.data.entities, action.meta && action.meta.dontOverwrite);
    }

    return state;
};
export default entities;
