import pda from './actions';
import { RootAction } from 'actions/rootAction';
import { getType } from 'typesafe-actions';
import { RemoteData, initial, pending, success, failure } from '@devexperts/remote-data-ts';

const userPrimaryDashboardReducer = (
    state: RemoteData<Error, false | string> = initial,
    action: RootAction,
): RemoteData<Error, false | string> => {
    switch (action.type) {
        case getType(pda.getPrimaryDash): {
            return pending;
        }
        case getType(pda.getPrimaryDashSuccess): {
            return success(action.user.primaryDashboardId || false);
        }
        case getType(pda.setPrimaryDashSuccess): {
            return success(action.dashboardId || false);
        }
        case getType(pda.getPrimaryDashFailure): {
            return failure(action.error);
        }
        default:
            return state;
    }
};
export default userPrimaryDashboardReducer;
