import { createSelector } from 'reselect';
import { FormFieldConfigs, FormFieldUnion } from '../../../../fieldFactory/translation/fromFlowable/types/index';
import { createGetInitialValues, createGetStartFormInitialValues } from './getInitialValues';
import { RootState, TaskForm } from '../../../../reducers/rootReducer';
import createFormContext from 'components/generics/form/EntityFormContext/util/createFormContext';
import { createGetEntities, createGetValueSets } from 'components/generics/form/EntityFormContext/util/getEntities';
import createDeepEqlSelector from 'components/generics/form/EntityFormContext/util/createDeepEqlSelector';
import { fromNullable } from 'fp-ts/lib/Option';
import {
    getExpressionsFromFields,
    getConceptsAvailableExpressionsFromFields,
    getOptionAvailabilityExpressionsFromFields,
    getFormValues,
    getLiveFormInitial,
    getInjectedVisibilityTestExpressions,
    getValueset1Fields,
    wrapEachValueInArray,
} from 'bpm/form-context-utils';
import combiner, {
    getRef1FilterExpressions,
    getRefManyFilterExpressions,
    getTableExpressions,
} from 'bpm/form-context-utils/selectorCombiner';
import defaultFormContext from 'bpm/form-context-utils/defaultFormContext';
import { FormContextEvaluator, AvailableOptionsExpressions } from 'expressions/CachingEvaluator/FormContextEvaluator';
import { EXTERNALGISID } from 'components/generics/form/EntityFormContext/util/getAllFieldsExpected';

type FCPProps =
    | {
          contextType: 'existing-task-form';
          taskId: string;
          relatedEntityResource?: string;
          relatedEntityId?: string;
          overrideFormDefinition?: TaskForm;
      }
    | {
          contextType: 'start-form';
          formDefinition: TaskForm;
      };

const getTaskFormFields = (state: RootState, props: FCPProps) => {
    return props.contextType === 'start-form'
        ? props.formDefinition.fields
        : props.overrideFormDefinition
        ? props.overrideFormDefinition.fields
        : fromNullable(state.taskForms[props.taskId])
              .mapNullable(t => t.fields)
              .getOrElse(undefined);
};

const createGetTaskFormExpressions = (expType: keyof FormFieldConfigs) =>
    createSelector(
        getTaskFormFields,
        getExpressionsFromFields(expType),
    );

const createGetTaskFormConceptsAvailableExpressions = () =>
    createSelector(
        getTaskFormFields,
        getConceptsAvailableExpressionsFromFields,
    );

const createGetTaskFormOptionAvailabilityExpressions = () =>
    createSelector(
        getTaskFormFields,
        getOptionAvailabilityExpressionsFromFields,
    );

const getFormContextEvaluatorSelector = () => {
    return createSelector(
        getTaskFormFields,
        createGetTaskFormExpressions('editable'),
        createGetTaskFormExpressions('visibility'),
        createGetTaskFormConceptsAvailableExpressions(),
        createGetTaskFormOptionAvailabilityExpressions(),
        getInjectedVisibilityTestExpressions,
        (state: RootState) => state.viewConfig,
        (
            fields: FormFieldUnion[],
            editabilityExpressions: {
                [formField: string]: string;
            },
            visibilityExpressions: {
                [formField: string]: string;
            },
            valueset1AvailableConceptsExpressions: {
                [source: string]: string;
            },
            dropdownAvailableOptionsExpressions: AvailableOptionsExpressions,
            injectedVisibilityTestExpression: {} | undefined,
            viewConfig,
        ) => {
            const Evaluator = new FormContextEvaluator({
                basedOnEntityOptions: null,
                evaluationFactors: {
                    fieldWidgets: (fields || [])
                        .map(f => f.id)
                        .reduce((prev, curr) => {
                            prev[curr] = [curr];
                            return prev;
                        }, {}),
                    dropdownAvailableOptionsExpressions,
                    editabilityExpressions: wrapEachValueInArray(editabilityExpressions),
                    visibilityExpressions: wrapEachValueInArray({
                        ...visibilityExpressions,
                        ...(injectedVisibilityTestExpression || {}),
                    }),
                    reference1EntityFilterExpressions: getRef1FilterExpressions(fields || []),
                    referenceManyEntityFilterExpressions: getRefManyFilterExpressions(fields || [], viewConfig),
                    valueset1Fields: getValueset1Fields(fields || []),
                    valueset1AvailableConceptsExpressions,
                    tableExpressions: getTableExpressions(fields || []),
                },
                options: { dateFormat: viewConfig && viewConfig.application && viewConfig.application.dateFormat },
                viewConfig,
                useBackingValuesRegardlessOfDisplayStatus: fields.reduce(
                    (prev, curr) => {
                        // don't "correct" values of linked-fields because they can be set externally
                        if (curr.params && (curr.params as any).entityField) {
                            prev[curr.id] = true;
                        }
                        return prev;
                    },
                    {
                        [EXTERNALGISID]: true,
                    },
                ),
            });
            return Evaluator;
        },
    );
};
const createFormContextSelector = () => {
    const getEntities = createGetEntities();
    const getValueSets = createGetValueSets();
    const getExistingTaskInitialValues = createGetInitialValues();
    const getStartFormInitialValues = createGetStartFormInitialValues();
    const formContextEvaluatorSelector = getFormContextEvaluatorSelector();
    const selector = createSelector(
        formContextEvaluatorSelector,
        getTaskFormFields,
        getFormValues,
        getEntities,
        getValueSets,
        (state: RootState, props: FCPProps) =>
            props.contextType === 'start-form'
                ? getStartFormInitialValues(state, props)
                : // use initial in store first, in case we reinitialized in the component after successful save.
                  getLiveFormInitial(state) || getExistingTaskInitialValues(state, props),
        combiner,
    );
    return createDeepEqlSelector(selector);
};

export const { formContext, formContextHoc, FormContextProvider } = createFormContext(
    createFormContextSelector,
    defaultFormContext,
);
