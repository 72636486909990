import React, { Component } from 'react';
import pure from 'recompose/pure';
import Button from '@material-ui/core/Button';
import { withStyles, createStyles } from '@material-ui/core/styles';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import compose from 'recompose/compose';
import { TableCell } from '@material-ui/core';
type GetComponentProps<T> = T extends React.ComponentType<infer P> | React.Component<infer P> ? P : never;

const styles = theme =>
    createStyles({
        offScreen: {
            position: 'absolute',
            left: '-10000px',
            top: 'auto',
            overflow: 'hidden',
        },
        actions: {
            flexShrink: 0,
            color: theme.palette.text.secondary,
            marginLeft: 20,
        },
        hellip: { padding: '1.2em' },
    });

interface TableCellProps extends GetComponentProps<typeof TableCell> {}
interface PaginationActionsProps extends TableCellProps {
    backIconButtonProps?: {};
    count: number;
    nextIconButtonProps?: {};
    onChangePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => void;
    page: number;
    rowsPerPage: number;
}

interface PaginationActionsComponentProps extends PaginationActionsProps {
    classes: {
        [k in keyof ReturnType<typeof styles>]: string;
    } &
        TableCellProps['classes'];
}
export class PaginationActionsComponent extends Component<PaginationActionsComponentProps> {
    /**
     * Warning: material-ui's page is 0-based
     */
    range() {
        const { page, rowsPerPage, count } = this.props;
        const nbPages = Math.ceil(count / rowsPerPage) || 1;
        if (isNaN(page) || nbPages === 1) {
            return [];
        }
        const input: (number | '.')[] = [];
        // display page links around the current page
        if (page > 1) {
            input.push(1);
        }
        if (page === 3) {
            input.push(2);
        }
        if (page > 3) {
            input.push('.');
        }
        if (page > 0) {
            input.push(page);
        }
        input.push(page + 1);
        if (page < nbPages - 1) {
            input.push(page + 2);
        }
        if (page === nbPages - 4) {
            input.push(nbPages - 1);
        }
        if (page < nbPages - 4) {
            input.push('.');
        }
        if (page < nbPages - 2) {
            input.push(nbPages);
        }

        return input;
    }

    getNbPages = () => Math.ceil(this.props.count / this.props.rowsPerPage) || 1;

    prevPage = event => {
        if (this.props.page === 0) {
            throw new Error('Cannot go before page 1');
        }
        this.props.onChangePage(event, this.props.page - 1);
    };

    nextPage = event => {
        if (this.props.page > this.getNbPages() - 1) {
            throw new Error('Cannot go after last page');
        }
        this.props.onChangePage(event, this.props.page + 1);
    };

    gotoPage = event => {
        const page = parseInt(event.currentTarget.dataset.page, 10);
        if (page < 0 || page > this.getNbPages() - 1) {
            throw new Error(`Page number ${page + 1} out of boundaries`);
        }
        this.props.onChangePage(event, page);
    };

    renderPageNums() {
        const { classes } = this.props;

        return this.range().map((pageNum, index) =>
            pageNum === '.' ? (
                <span key={`hyphen_${index}`} className={classes.hellip}>
                    &hellip;
                </span>
            ) : (
                <Button
                    className="page-number"
                    color={pageNum === this.props.page + 1 ? 'default' : 'primary'}
                    key={pageNum}
                    data-page={pageNum - 1}
                    onClick={this.gotoPage}
                    size="small"
                >
                    <span className={classes.offScreen}>Page </span>
                    {pageNum}
                </Button>
            ),
        );
    }

    render() {
        const { classes, page } = this.props;

        const nbPages = this.getNbPages();
        if (nbPages === 1) {
            return <div className={classes.actions} />;
        }
        return (
            <div className={classes.actions}>
                {page > 0 && (
                    <Button color="primary" key="prev" onClick={this.prevPage} className="previous-page" size="small">
                        <ChevronLeft />
                        Prev
                    </Button>
                )}
                {this.renderPageNums()}
                {page !== nbPages - 1 && (
                    <Button color="primary" key="next" onClick={this.nextPage} className="next-page" size="small">
                        Next
                        <ChevronRight />
                    </Button>
                )}
            </div>
        );
    }
}

const enhance = compose(
    pure,
    withStyles(styles),
);

const PaginationActions: React.SFC<PaginationActionsProps> = enhance(PaginationActionsComponent);
export default PaginationActions;
