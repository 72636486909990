import ViewConfig from '../../reducers/ViewConfigType';
import { appendStarToTextFieldsInSearch } from './appendStarToTextFieldsInSearch';
import translateFilter from './translateFieldWithSearchTypeAppended';

export default (fields: { [key: string]: {} }, viewConfig: ViewConfig, viewName?: string | null) =>
    Object.assign(
        {},
        ...Object.entries(appendStarToTextFieldsInSearch(fields, viewConfig, viewName)).flatMap(([field, value]) => {
            if (value === '*' || value === '') {
                return [];
            }
            if (field.endsWith('__IN') && (!value || (Array.isArray(value) && value.length === 0))) {
                return [];
            }
            return [{ [translateFilter(field)]: value }];
        }),
    );
