import React from 'react';
import {
    Dialog,
    IconButton,
    Card,
    CardContent,
    CardActions,
    Button,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import { getSetVariableButton } from './setVariable';

interface EditProcessVariableProps {
    processInstanceId: string;
    variableName: string;
    variableType: 'string' | 'localdate' | 'boolean' | 'double' | 'integer';
    initialValue?: string;
    onSuccess: () => void;
}

type DialogOpen =
    | {
          _tag: 'EditProcessVariable';
          value: any;
      }
    | false;

interface EditProcessVariableState {
    dialogOpen: DialogOpen;
}

class EditProcessVariableComponent extends React.Component<EditProcessVariableProps, EditProcessVariableState> {
    constructor(props: EditProcessVariableProps) {
        super(props);
        this.state = {
            dialogOpen: false,
        };
    }
    handleValueChange = e => {
        const value = (() => {
            if (e.target.type === 'checkbox') {
                return e.target.checked === 'true' || e.target.checked === true;
            } else {
                return (e.target && e.target.value) || '';
            }
        })();
        // const value = (e.target && e.target.value) || '';
        this.setState(prevState => ({
            dialogOpen: prevState.dialogOpen && {
                ...prevState.dialogOpen,
                value,
            },
        }));
    };

    handleSetNull = () => {
        this.setState(prevState => ({
            dialogOpen: prevState.dialogOpen && {
                ...prevState.dialogOpen,
                value: null,
            },
        }));
    };

    openDialog = (initialName: string = '') => () => {
        this.setState({
            dialogOpen: {
                _tag: 'EditProcessVariable',
                value: initialName,
            },
        });
    };
    closeDialog = () => {
        this.setState({ dialogOpen: false });
    };
    getInputType = () => {
        const { variableType } = this.props;
        switch (variableType) {
            case 'boolean':
                return 'checkbox';
            case 'integer':
            case 'double':
                return 'number';
            case 'string':
                return 'text';
            case 'localdate':
                return 'date';
            default:
                return 'text';
        }
    };
    getInputValue = (value: any) => {
        const { variableType } = this.props;
        switch (variableType) {
            case 'boolean':
                return value === true || value === 'true';
            case 'integer':
            case 'double':
                return parseFloat(value);
            default:
                return value;
        }
    };
    renderDialog = () => {
        const { dialogOpen } = this.state;
        const { variableName, processInstanceId, onSuccess } = this.props;
        if (!dialogOpen) {
            return null;
        }
        const type = this.getInputType();
        return (
            <Dialog
                onClose={this.closeDialog}
                open={true}
                maxWidth={false}
                fullWidth={true}
                TransitionProps={
                    {
                        // https://github.com/dequelabs/axe-core/issues/146
                        role: 'presentation',
                    } as any
                }
            >
                <Card>
                    <CardContent>
                        <input
                            id="editProcessVariable"
                            type={type}
                            // label={variableName}
                            value={dialogOpen.value || ''}
                            checked={
                                type === 'checkbox'
                                    ? dialogOpen.value === 'true' || dialogOpen.value === true
                                    : undefined
                            }
                            onChange={this.handleValueChange}
                        />
                        <FormControlLabel
                            value="start"
                            control={<Checkbox color="primary" onChange={this.handleSetNull} />}
                            label="Set Null"
                            labelPlacement="start"
                        />
                    </CardContent>
                    <CardActions>
                        <Button color="default" onClick={this.closeDialog}>
                            Close
                        </Button>
                        {getSetVariableButton(processInstanceId, onSuccess)({
                            id: variableName,
                            value: this.getInputValue(dialogOpen.value),
                        })}
                    </CardActions>
                </Card>
            </Dialog>
        );
    };
    render() {
        const { initialValue } = this.props;
        return (
            <React.Fragment>
                {this.renderDialog()}
                <IconButton aria-label="edit" onClick={this.openDialog(initialValue)} style={{ width: 38, height: 38 }}>
                    <Edit />
                </IconButton>
            </React.Fragment>
        );
    }
}

export default EditProcessVariableComponent;
