import React from 'react';
import ImageEye from '@material-ui/icons/RemoveRedEye';
import {
    Dialog,
    DialogContent,
    Card,
    Button,
    withStyles,
    WithStyles,
    Theme,
    createStyles,
    IconButton,
} from '@material-ui/core';
import { stringify } from 'query-string';
import GenericList from '../../components/generics/genericList/index';
import SimpleList from '../../components/generics/genericList/SimpleList';
import PopoverListActions from '../../components/generics/genericList/PopoverListActions';
import { userAgent } from 'userAgent';
import { getSearchFieldsFromView } from 'components/generics/utils/viewConfigUtils';
import compose from 'recompose/compose';
import Add from '@material-ui/icons/Add';
import { RootState } from 'reducers/rootReducer';
import { connect } from 'react-redux';
import Close from '@material-ui/icons/Close';
import { tableRowContext } from 'fieldFactory/input/components/EditableTable/MuiEditableTable';

const isIe = userAgent.isIe();
const PopoverListActns: any = PopoverListActions; // tslint:disable-line

interface SearchSelectDialogProps {
    expansions?: string[];
    viewName?: string;
    reference: string;
    basePath: string;
    values: string[];
    isOpen: boolean;
    handleClose: () => void;
    resourceBasePath: string;
    setReference: (val: { id: number }) => void;
    onCreateCb: (val: { id: string }) => void;
    filter?: {};
    formId?: string;
    injectCreateValues?: {};
}

const styles = (theme: Theme) =>
    createStyles({
        alreadySelectedTypography: {
            color: theme.palette.getContrastText(theme.palette.primary.main),
            float: 'right',
            marginRight: '1em',
        },
    });

interface SearchSelectDialogState {
    readonly selected: {
        [key: number]: {
            id: number;
        };
    };
    readonly location: {
        pathname: string;
        search: string;
    };
}

const mapStateToProps = (state: RootState) => {
    return {
        viewConfig: state.viewConfig,
    };
};

interface SearchSelectDialogComponentProps
    extends ReturnType<typeof mapStateToProps>,
        WithStyles<typeof styles>,
        SearchSelectDialogProps {}

class SearchSelectDialogComponent extends React.Component<SearchSelectDialogComponentProps, SearchSelectDialogState> {
    initialSearch: string;
    constructor(props: SearchSelectDialogComponentProps) {
        super(props);
        this.initialSearch = `?${stringify({ filter: JSON.stringify(this.props.filter) })}`;
        this.state = {
            selected: {},
            location: {
                pathname: this.props.resourceBasePath,
                search: this.initialSearch,
            },
        };
    }

    unstage = id => {
        this.setState(state => {
            // destructuring spread doesn't work with number keys! (this is a bug)
            // create a new object to delete from to prevent side effects.
            const rest = Object.assign({}, state.selected);
            delete rest[id];
            return {
                ...state,
                selected: {
                    ...rest,
                },
            };
        });
    };

    render() {
        const {
            isOpen,
            handleClose,
            reference,
            basePath,
            values,
            setReference,
            filter,
            injectCreateValues,
            onCreateCb,
            viewName: _viewName,
            formId,
            classes,
            viewConfig,
        } = this.props;
        const displayInfoBar = Object.keys(this.state.selected).length === 1 ? undefined : 'none';
        const viewName = _viewName || `${reference}List`;
        return (
            <tableRowContext.Provider value={null}>
                <Dialog
                    TransitionProps={
                        {
                            // https://github.com/dequelabs/axe-core/issues/146
                            role: 'presentation',
                        } as any
                    }
                    fullWidth={true}
                    open={isOpen}
                    onClose={handleClose}
                    maxWidth={false}
                >
                    <DialogContent key={isOpen ? 'isopen' : 'closed'} style={{ padding: 0, minWidth: '80vw' }}>
                        <GenericList
                            {...{
                                // It's really a pain clicking search all the time in integration tests, so lets have it search automatically
                                alwaysPreventInitialSearch:
                                    process.env.NODE_ENV === 'test'
                                        ? undefined
                                        : (() => {
                                              const view = viewConfig.views[viewName];
                                              return (
                                                  // no search has been submitted yet with this field
                                                  // (without this.state.location.search === this.initialSearch, reopening and clicking submit doesn't work)
                                                  this.state.location.search === this.initialSearch &&
                                                  view &&
                                                  getSearchFieldsFromView(view).length > 0
                                              );
                                          })(),
                                formId: formId || `filterForm-resource:${reference}`,
                                onRowSelect: (selectedData, allData) => {
                                    this.setState(state => ({
                                        ...state,
                                        selected: Object.assign({}, ...selectedData.map(obj => ({ [obj.id]: obj }))),
                                    }));
                                },
                                actions: (
                                    <PopoverListActns
                                        handleClose={handleClose}
                                        onCreateCb={(id, data) => {
                                            console.log(data); // tslint:disable-line
                                            handleClose();
                                            onCreateCb(data);
                                        }}
                                        injectCreateValues={injectCreateValues}
                                        basePath={`/${reference}`}
                                    />
                                ),
                                renderList: ({ defaultRenderer, ...args }) => {
                                    return defaultRenderer({ ...args, SelectIconComponent: Add });
                                },
                                hasCreate: false,
                                multiSelectable: false,
                                updateUrlFromFilter: false,
                                viewName,
                                fakePush: location => {
                                    this.setState(state => ({ ...state, location }));
                                },
                                location: this.state.location,
                                resource: reference,
                                name: reference,
                                selectedData: this.state.selected,
                                filter,
                                isPopover: true,
                            }}
                        />
                        <div
                            style={{
                                width: '100%',
                                position: isIe ? 'absolute' : 'sticky',
                                bottom: 0,
                                height: 70,
                                display: displayInfoBar,
                            }}
                        >
                            <Card style={{ position: 'relative' }}>
                                <SimpleList
                                    leftIcon={() => <ImageEye color="inherit" />}
                                    style={{ padding: 0 }}
                                    liStyle={{
                                        height: 70,
                                        width: 'calc(100% - 150px)',
                                    }}
                                    data={this.state.selected}
                                    basePath={basePath}
                                    ids={Object.keys(this.state.selected)}
                                    primaryText={data => `View ${data.title}`}
                                    secondaryText={data =>
                                        data.subtitle && data.subtitle !== 'null' && <span>{data.subtitle}</span>
                                    }
                                    showDialog={true}
                                    resourceBasePath={`/${reference}`}
                                    resourceName={reference}
                                    innerContent={(data, id) => [
                                        <div
                                            style={{
                                                position: 'absolute',
                                                cursor: 'unset',
                                                top: 0,
                                                right: 0,
                                                width: 200,
                                                height: 70,
                                            }}
                                            onClick={e => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                            }}
                                        >
                                            {values.indexOf(data.id) !== -1 ? ( // TODO: RESOLVE SOURCE CORRECTLY
                                                <div
                                                    style={{ position: 'absolute', top: 15, right: 150 }}
                                                    key="alreadyAdded"
                                                    className={classes.alreadySelectedTypography}
                                                >
                                                    Already Selected
                                                </div>
                                            ) : (
                                                <Button
                                                    variant="contained"
                                                    key="addButton"
                                                    aria-label="Add selected record"
                                                    style={{
                                                        position: 'absolute',
                                                        top: 15,
                                                        right: 80,
                                                    }}
                                                    onClick={evt => {
                                                        evt.stopPropagation();
                                                        evt.preventDefault();
                                                        setReference(data);
                                                    }}
                                                    onKeyPress={evt => {
                                                        evt.stopPropagation();
                                                        evt.preventDefault();
                                                        if (evt.key === 'Enter' || evt.key === ' ') {
                                                            setReference(data);
                                                        }
                                                    }}
                                                >
                                                    Select
                                                </Button>
                                            )}
                                            {
                                                <IconButton
                                                    // variant="contained"
                                                    color="secondary"
                                                    key="unstageButton"
                                                    style={{ position: 'absolute', color: 'white', top: 10, right: 18 }}
                                                    aria-label="Cancel selection"
                                                    onClick={evt => {
                                                        evt.stopPropagation();
                                                        evt.preventDefault();
                                                        this.unstage(id);
                                                    }}
                                                    onKeyPress={evt => {
                                                        evt.stopPropagation();
                                                        evt.preventDefault();
                                                        if (evt.key === 'Enter' || evt.key === ' ') {
                                                            this.unstage(data);
                                                        }
                                                    }}
                                                >
                                                    <Close />
                                                </IconButton>
                                            }
                                        </div>,
                                    ]}
                                />
                            </Card>
                        </div>
                    </DialogContent>
                </Dialog>
            </tableRowContext.Provider>
        );
    }
}

const SearchSelectDialog: React.ComponentType<SearchSelectDialogProps> = compose(
    withStyles(styles),
    connect(mapStateToProps),
)(SearchSelectDialogComponent);
export default SearchSelectDialog;
