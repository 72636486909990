import React, { Component } from 'react';
import TextField from './TextField/TextField';
import FieldTitle from './aor/FieldTitle';
import NumberFormat from 'react-number-format';
import { withStyles, Theme } from '@material-ui/core/styles';
import uniqueId from 'lodash/uniqueId';
import getFormHelperTextProps from 'fieldFactory/util/getFormHelperTextProps';
import formatError from 'fieldFactory/util/formatError';

type Input = any; // tslint:disable-line
type Meta = any; // tslint:disable-line

interface Classes {}
const styles: (theme: Theme) => Classes = (theme: Theme) => ({});

interface NumberInputProps {
    input: Input;
    isInteger: Boolean;
    elStyle?: {};
    isRequired?: Boolean;
    label: string;
    meta: Meta;
    options?: {};
    source: string;
    resource?: string;
    disabled?: boolean;
    className?: string;
    renderLabel?: boolean;
    ariaInputProps?: {};
}
const NumberFormatInteger = props => {
    const { inputRef, onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            decimalScale={0}
            allowNegative={false}
            step={1}
            isAllowed={values => {
                const { floatValue, formattedValue } = values;
                return (
                    formattedValue === '' ||
                    (floatValue >= Number.MIN_SAFE_INTEGER && floatValue <= Number.MAX_SAFE_INTEGER)
                );
                /*

            return floatValue >= 5 &&  floatValue <= 10000;
            */
                //return true;
            }}
        />
    );
};

const NumberFormatFloat = props => {
    const { inputRef, onChange, ...other } = props;
    return <NumberFormat {...other} getInputRef={inputRef} />;
};

class NumberInput extends Component<NumberInputProps, {}> {
    private errorMessageId: string = uniqueId('numberinput');
    static defaultProps = {
        ariaInputProps: {},
        renderLabel: true,
    };
    onChange = e => {
        this.props.input.onChange(this.getValue(e));
    };
    onBlur = e => {
        this.props.input.onBlur(this.getValue(e));
    };
    getValue = e => {
        const { isInteger } = this.props;
        const value = isInteger ? parseInt(e.target.value, 10) : parseFloat(e.target.value);
        return isNaN(value) ? null : value;
    };
    render() {
        const {
            elStyle,
            input,
            label,
            meta: { touched, error },
            options,
            source,
            resource,
            isRequired,
            isInteger,
            disabled,
            className,
            renderLabel,
            ariaInputProps,
        } = this.props;
        const InputProps = touched && error ? { 'aria-errormessage': this.errorMessageId } : undefined;
        return (
            <TextField
                {...input}
                onChange={this.onChange}
                onBlur={this.onBlur}
                InputLabelProps={{
                    shrink: true,
                    error: false,
                }}
                className={className}
                fullWidth={true}
                margin="none"
                label={
                    renderLabel && (
                        <FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />
                    )
                }
                InputProps={{ inputComponent: isInteger ? NumberFormatInteger : NumberFormatFloat }}
                // eslint-disable-next-line
                inputProps={{
                    ...ariaInputProps,
                    ...InputProps,
                    title: `Allows only ${isInteger ? 'integer' : 'floating point'} values`,
                }}
                error={!!(touched && error)}
                helperText={touched && error ? `Error: ${formatError(error)}` : undefined}
                style={elStyle}
                disabled={disabled}
                FormHelperTextProps={getFormHelperTextProps(InputProps)}
                {...options}
            />
        );
    }
}

export default withStyles(styles as any)(NumberInput); //tslint:disable-line
