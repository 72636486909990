import React, { useCallback, useEffect } from 'react';
import EditableViewFormLayout from './EditableViewFormLayout';
import { RootState } from 'reducers/rootReducer';
import { useSelector, useDispatch } from 'react-redux';
import produce from 'immer';
import { loadSuccess, reloadFromLocalstorage } from 'viewConfig/actions';
import { Button, IconButton } from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';
import JsonDownload from 'expression-tester/util/JsonDownload';
import Warning from '@material-ui/icons/Warning';
import useSubmitView from 'layout-editor/hooks/useSubmitView';
import Popup from 'components/Popup';

interface EditableViewFormEditor {
    mode: 'EDIT' | 'SHOW';
    record?: {
        id: string;
        entityType: string;
    };
    viewName: string;
}
const EditableViewFormEditor: React.SFC<EditableViewFormEditor> = props => {
    const dispatch = useDispatch();
    const open = useSelector((state: RootState) => state.expressionTesterOpen);
    const viewConfig = useSelector((state: RootState) => state.viewConfig);
    const view = viewConfig.views[props.viewName];
    const reset = useCallback(() => {
        dispatch(reloadFromLocalstorage());
    }, [dispatch]);
    const handleViewChange = useCallback(
        ({ view }) => {
            const newViewConfig = produce(viewConfig, draftState => {
                draftState.views[view.name] = view;
                return draftState;
            });
            dispatch(loadSuccess(newViewConfig));
        },
        [viewConfig, dispatch],
    );
    const [state, submit] = useSubmitView(view);
    useEffect(() => {
        if (state.type === 'ERROR') {
            console.error(state.error);
            alert('Failed to save view. Check the console for details.');
        } else if (state.type === 'SUCCESS') {
            alert('Success!');
        }
    }, [state]);
    return open ? (
        <Popup
            ComponentProps={{ fullScreen: true, fullWidth: true }}
            renderDialogContent={({ closeDialog }) => {
                return (
                    <div>
                        <IconButton style={{ float: 'right' }} aria-label="clear" onClick={closeDialog}>
                            <Clear />
                        </IconButton>
                        <EditableViewFormLayout
                            mode={props.mode}
                            view={view}
                            record={props.record}
                            onViewChange={handleViewChange}
                        />
                        <div>
                            <Button variant="contained" onClick={reset}>
                                Reset Layouts
                            </Button>
                            &nbsp;
                            <JsonDownload json={view} title="Export Applied View Layout" />
                            &nbsp;
                            <Button
                                onClick={submit}
                                disabled={state.type === 'SUBMITTING'}
                                variant="contained"
                                color="primary"
                            >
                                <Warning /> Save to backend
                            </Button>
                        </div>
                    </div>
                );
            }}
            renderToggler={({ openDialog }) => <Button onClick={openDialog()}>Edit Layout</Button>}
        />
    ) : null;
};

export default EditableViewFormEditor;
