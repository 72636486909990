import ViewConfig from 'reducers/ViewConfigType';
import { fromEither, Option, fromNullable } from 'fp-ts/lib/Option';
import { tryCatch } from 'fp-ts/lib/Either';
import { mapOption } from 'fp-ts/lib/Array';
import { SpelCompiledExpression /* SpelNode */ } from 'expressions/evaluate';
import { parsingOrValidationErrMsg } from 'expressions/formValidation';
import getFieldsInAst from 'casetivity-shared-js/lib/spel/getFieldsInAst';
import { SpelExpressionEvaluator } from 'spel2js';
import { parseConfig } from 'expressions/entityViewConfig/parse';
import setupValuesetFieldsRequired from 'viewConfigCalculations/util/setupValuesetFieldsRequired';
import getValueSetCodeLiterals from 'expressions/getFieldsInAst/getValuesetCodeLiterals';
import removeMethodHashes from 'casetivity-shared-js/lib/spel/getFieldsInAst/removeMethodHashes';
import maybeLogError from 'viewConfigCalculations/util/logErrorIfPermissioned';

const getConceptExpressions = (
    key: string,
    resource: string,
    viewConfig: ViewConfig,
    viewConfiguration: Option<string>,
) =>
    viewConfiguration
        .map(parseConfig)
        .map(e => e.map(c => c.conceptIdsForFields))
        // prints errors for the failed configs
        .map(e =>
            e.mapLeft(error => {
                maybeLogError(viewConfig)(parsingOrValidationErrMsg(error));
                return error;
            }),
        )
        .chain(fromEither)
        .chain(fromNullable)
        .map(v => {
            // Either empty object or array object
            return mapOption(Object.entries(v).map(([f, e]) => [f, removeMethodHashes(e)]), ([fieldName, expression]) =>
                fromEither(
                    tryCatch(
                        () => {
                            const compiledExpression = (SpelExpressionEvaluator.compile(
                                expression,
                            ) as SpelCompiledExpression)._compiledExpression;
                            const fields = getFieldsInAst(expression)(compiledExpression);
                            return {
                                expression,
                                fieldName,
                                fieldsRequired: fields,
                                valuesetLiterals: getValueSetCodeLiterals(expression)(compiledExpression),
                            };
                        },
                        (e: Error) => {
                            // prints errors for the failed SPEL compilation
                            maybeLogError(viewConfig)('Error parsing SPEL entityConfig validation expression', e);
                            return e;
                        },
                    ).chain(setupValuesetFieldsRequired(viewConfig, resource, expression)),
                ),
            );
        })
        .map(v => ({ [key]: Object.assign({}, ...v.map(c => ({ [c.fieldName]: c }))) }));

export default getConceptExpressions;
