import * as React from 'react';
import { connect } from 'react-redux';
import shouldUpdate from 'recompose/shouldUpdate';
import compose from 'recompose/compose';
import { Button } from '@material-ui/core';
import ContentCreate from '@material-ui/icons/Create';
import { Dialog } from '@material-ui/core';
import Toolbar from 'components/generics/form/Toolbar.aor';
import SaveButton from 'components/generics/button/SaveButton';
import { crudGetOne as crudGetOneAction } from 'sideEffect/crud/getOne/actions';
import CloseButton from './PopoverCloseButton';
import GenericEdit from '../../components/generics/genericEdit';
import { tableRowContext } from 'fieldFactory/input/components/EditableTable/MuiEditableTable';

interface PopoverEditButtonConnectedProps {
    basePath: string;
    label?: string;
    record: { id: string };
}
interface PopoverEditButtonProps extends PopoverEditButtonConnectedProps {
    crudGetOne: Function;
}
interface PopoverEditButtonState {
    open: boolean;
}
class PopoverEditButton extends React.Component<PopoverEditButtonProps, PopoverEditButtonState> {
    static defaultProps = {
        label: 'Edit',
        basePath: '',
        record: {},
    };

    /* @lazyInject(TYPES.EditView)
    private editView: new () => EditView;
    */
    constructor(props: PopoverEditButtonProps) {
        super(props);
        this.state = {
            open: false,
        };
    }

    reloadEntity = () => {
        const { crudGetOne, basePath, record } = this.props;
        crudGetOne({
            resource: basePath.slice(1),
            id: record.id,
        });
    };

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    closeAndReload = () => {
        this.reloadEntity();
        this.handleClose();
    };

    render() {
        const { basePath, label, record } = this.props;
        const resourceName = basePath.slice(1);
        return (
            <tableRowContext.Provider value={null}>
                <div>
                    <Button color="primary" onClick={this.handleOpen}>
                        {label}&nbsp;
                        <ContentCreate />
                    </Button>
                    <Dialog
                        TransitionProps={
                            {
                                // https://github.com/dequelabs/axe-core/issues/146
                                role: 'presentation',
                            } as any
                        }
                        open={this.state.open}
                        onClose={this.handleClose}
                        maxWidth={false}
                        fullWidth={true}
                    >
                        <div>
                            <GenericEdit
                                {...{
                                    viewName: `${resourceName}Edit`,
                                    formId: `popover-edit-form-${resourceName}-${record.id}`,
                                    redirect: false,
                                    hasList: false,
                                    location: { pathname: `${basePath}/${record.id}` },
                                    match: {
                                        isExact: true,
                                        params: {
                                            id: record.id,
                                            basePath,
                                        },
                                    },
                                    resource: resourceName,
                                    onSaveCb: this.closeAndReload,
                                    name: resourceName,
                                    toolbar: (
                                        <Toolbar>
                                            <CloseButton handleClose={this.handleClose} />
                                            <SaveButton />
                                        </Toolbar>
                                    ),
                                }}
                            />
                        </div>
                    </Dialog>
                </div>
            </tableRowContext.Provider>
        );
    }
}

const enhance = compose(
    connect(
        null,
        {
            crudGetOne: crudGetOneAction,
        },
    ),
    shouldUpdate(
        (props, nextProps) =>
            (props.record && nextProps.record && props.record.id !== nextProps.record.id) ||
            props.basePath !== nextProps.basePath ||
            (props.record == null && nextProps.record != null),
    ),
);

const PEB: React.SFC<PopoverEditButtonConnectedProps> = enhance(PopoverEditButton);
export default PEB;
