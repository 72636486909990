import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import compose from 'recompose/compose';
import getReportDefinitionsAction from '../actions/getReportDefinitions';
import ReportForm from './ReportForm';
import FileSaver from 'file-saver';
import buildFetchOptions from '../../sagas/util/buildFetchOptions';
import { BACKEND_BASE_URL } from 'config';
import DeferredSpinner from 'components/DeferredSpinner';
import { storageController } from 'storage';

const runReportEndpoint = 'api/reports/';
const getReportUrl = (reportDefinitionId, reportType) =>
    `${BACKEND_BASE_URL}${runReportEndpoint}${reportDefinitionId}?file-type=${reportType}`;
const getFileName = (reportDefinitionName, reportType) => `${reportDefinitionName}.${reportType.toLowerCase()}`;

const downloadReport = (formData, reportDefinitionId, fileName, reportType) => {
    const options = buildFetchOptions();
    options.method = 'POST';
    options.body = formData;

    const token = storageController.getToken();
    if (token) {
        options.headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        };
    }
    const url = getReportUrl(reportDefinitionId, reportType);
    return fetch(url, options)
        .then(response => response.blob())
        .then(blob => {
            if (blob && blob.size > 0) {
                FileSaver.saveAs(blob, fileName);
            }
        });
};

class ReportPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    componentDidMount() {
        this.props.getReportDefinitions(this.props.reportName);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.reportName !== nextProps.reportName) {
            this.props.getReportDefinitions(nextProps.reportName);
        }
    }

    onSubmit = (reportType, values) => {
        console.log(this);
        const inputData = {};
        if (values) {
            Object.assign(
                inputData,
                ...Object.keys(values)
                    .filter(key => values[key] !== null)
                    .map(key => {
                        const value = values[key];
                        if (value) {
                            console.log(`key: ${key}, value: ${value}`);
                        }
                        return { [key]: value };
                    }),
            );

            const formData = JSON.stringify(inputData);
            const reportDefinition = this.props.reportDefinitions;
            this.setState({ loading: true }, () => {
                downloadReport(
                    formData,
                    reportDefinition.id,
                    getFileName(reportDefinition.name, reportType),
                    reportType,
                )
                    .catch(e => {
                        alert('Request failed');
                    })
                    .finally(() => this.setState(state => ({ loading: false })));
            });
        }
    };

    render() {
        return this.props.reportDefinitions && Object.keys(this.props.reportDefinitions).length > 0 ? (
            <LoadingOverlay active={this.state.loading} spinner={true} text="Running Report...">
                <ReportForm onSubmit={this.onSubmit} reportDefinition={this.props.reportDefinitions} {...this.props} />
            </LoadingOverlay>
        ) : (
            <DeferredSpinner />
        );
    }
}

ReportPage.propTypes = {
    reportName: PropTypes.string.isRequired,
    getReportDefinitions: PropTypes.func.isRequired,
    reportDefinitions: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        name: PropTypes.string,
        displayName: PropTypes.string,
        description: PropTypes.string,
        dataSource: PropTypes.string,
        definition: PropTypes.string,
        longRunning: PropTypes.bool,
        fields: PropTypes.arrayOf(
            PropTypes.shape({
                fieldType: PropTypes.string,
                id: PropTypes.string,
                name: PropTypes.string,
                type: PropTypes.string,
                // value
                required: PropTypes.bool,
                readOnly: PropTypes.bool,
                overrideId: PropTypes.bool,
                // placeholder
                // layout
            }),
        ),
    }),
};

ReportPage.defaultProps = {
    reportDefinitions: null,
    report: null,
};

// connect the process drodown so it can get/update Process Definitions
const mapStateToProps = state => ({
    reportDefinitions: state.reportDefinitions,
});
const enhance = compose(
    connect(
        mapStateToProps,
        {
            getReportDefinitions: getReportDefinitionsAction,
        },
    ),
);
export default enhance(ReportPage);
