import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Dialog, TextField, IconButton, Card, CardContent, CardActions, Button } from '@material-ui/core';
import { taskEventCreator } from '../../../../actions/taskEvent';
import { getChangeTaskEndDateButton } from './changeenddate';
import Edit from '@material-ui/icons/Edit';
import { RootState } from '../../../../../reducers/rootReducer';
import { withDateFormat, DateFormatProps } from '../../../../../fieldFactory/dateFormat/Broadcasts';
import DateTimePicker from '../../../../../fieldFactory/input/components/DateTimePicker';
import { fromNullable } from 'fp-ts/lib/Option';
import moment from 'moment';

interface EditableTaskEndDateProps {
    taskId: string;
    rootStyle?: {};
    isAdmin?: boolean;
}
const mapStateToProps = (state: RootState, { taskId }: EditableTaskEndDateProps) => ({
    task: taskId && state.bpm.tasks.byId[taskId],
});
const mapDispatchToProps = (dispatch, ownProps) => ({
    refresh: () => dispatch(taskEventCreator.getTask(ownProps.taskId)),
});

type DialogOpen =
    | {
          _tag: 'EditTaskEndDate';
          endDate: Date | string;
      }
    | false;

type EditEndDateProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> &
    EditableTaskEndDateProps &
    DateFormatProps;

interface EditEndDateState {
    dialogOpen: DialogOpen;
}

class EditTaskEndDateComponent extends React.Component<EditEndDateProps, EditEndDateState> {
    constructor(props: EditEndDateProps) {
        super(props);
        this.state = {
            dialogOpen: false,
        };
    }
    handleEndDateChange = e => {
        const endDate = typeof e === 'string' ? moment(e).toDate() : (e.target && e.target.value) || '';
        this.changeEndDate(endDate);
    };
    changeEndDate = (endDate: Date) => {
        if (endDate) {
            this.setState(prevState => ({
                dialogOpen: prevState.dialogOpen && {
                    ...prevState.dialogOpen,
                    endDate,
                },
            }));
        }
    };
    openDialog = (endDate: Date | string = '') => () => {
        this.setState({
            dialogOpen: {
                _tag: 'EditTaskEndDate',
                endDate,
            },
        });
    };
    closeDialog = () => {
        this.setState({ dialogOpen: false });
    };
    renderDialog = () => {
        const { dialogOpen } = this.state;
        const { taskId, refresh } = this.props;
        if (!dialogOpen) {
            return null;
        }
        return (
            <Dialog
                onClose={this.closeDialog}
                open={true}
                TransitionProps={
                    {
                        // https://github.com/dequelabs/axe-core/issues/146
                        role: 'presentation',
                    } as any
                }
            >
                <Card>
                    <CardContent>
                        <DateTimePicker
                            options={{
                                id: 'editEndDateOfCompletedTask',
                            }}
                            label="Completed Date"
                            input={{
                                value: moment(dialogOpen.endDate).toISOString() || '',
                                onChange: this.handleEndDateChange,
                                onBlur: this.changeEndDate,
                            }}
                            meta={{}}
                        />
                    </CardContent>
                    <CardActions>
                        <Button color="default" onClick={this.closeDialog}>
                            Close
                        </Button>
                        {getChangeTaskEndDateButton(taskId, refresh)(dialogOpen.endDate)}
                    </CardActions>
                </Card>
            </Dialog>
        );
    };
    render() {
        const { task, rootStyle, isAdmin = true, formatDate } = this.props;
        if (!task) {
            return null;
        }
        const dateDisplayValue = fromNullable(task.endDate)
            .map(formatDate)
            .getOrElse('');
        return (
            <React.Fragment>
                {this.renderDialog()}
                <div style={{ display: 'flex', ...rootStyle } || { paddingLeft: 16 }}>
                    <TextField
                        disabled={true}
                        id="disabled-completed-task-enddate"
                        InputLabelProps={{ disabled: false }}
                        label="Completed Date"
                        value={dateDisplayValue}
                        defaultValue={dateDisplayValue}
                    />
                    {isAdmin && (
                        <IconButton
                            aria-label="completed date"
                            onClick={this.openDialog(task.endDate)}
                            style={{ width: 38, height: 38 }}
                        >
                            <Edit />
                        </IconButton>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

const EditTaskEndDate: React.SFC<EditableTaskEndDateProps> = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withDateFormat,
)(EditTaskEndDateComponent);
export default EditTaskEndDate;
