import React from 'react';
import { Switch, FormControlLabel } from '@material-ui/core';
import { FieldTitle } from 'fieldFactory/input/components/aor/FieldTitle';
import { FormHelperText } from '@material-ui/core';
import uniqueId from 'lodash/uniqueId';

const styles = {
    block: {
        maxWidth: 250,
        marginTop: '.5em',
    },
    label: {
        color: 'rgba(0, 0, 0, 1)',
    },
    toggle: {
        marginBottom: 16,
    },
};

const BooleanInput = ({
    input,
    isRequired,
    label,
    source,
    elStyle,
    resource,
    options,
    meta,
    renderLabel,
    ariaInputProps,
    id,
}) => {
    const { error } = meta;
    const ariaErrormessageRef = React.useRef(uniqueId('toggleErrorMsg'));
    const InputProps: React.InputHTMLAttributes<HTMLInputElement> = {
        'aria-label': label,
        'aria-invalid': error,
        'aria-describedby': error ? ariaErrormessageRef.current : undefined,
    };
    return (
        <div style={{ ...elStyle, ...styles.block, verticalAlign: 'center' }}>
            <FormControlLabel
                labelPlacement="start"
                control={
                    <Switch
                        checked={!!input.value}
                        onChange={input.onChange}
                        inputProps={{ ...ariaInputProps, ...InputProps }}
                    />
                }
                label={
                    renderLabel && (
                        <FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />
                    )
                }
            />
            <FormHelperText error={error} id={InputProps['aria-describedby']}>
                Error: {error}
            </FormHelperText>
        </div>
    );
};

BooleanInput.defaultProps = {
    addField: true,
    options: {},
    ariaInputProps: {},
    renderLabel: true,
};

export default BooleanInput;
