import { storageController } from 'storage';

export default () => {
    const token = storageController.getToken();
    const options = {};
    options.credentials = 'same-origin';
    if (token) {
        options.user = {
            authenticated: true,
            token: `Bearer ${token}`,
            Cookie: `${window.document.cookie}`,
        };
    }
    return options;
};
