import React from 'react';
import {
    Card,
    CardHeader,
    IconButton,
    Dialog,
    Button,
    Select,
    MenuItem,
    CircularProgress,
    InputLabel,
    FormControl,
} from '@material-ui/core';
import TextInput from '../../../fieldFactory/input/components/TextInput';
import BooleanInput from '../../../fieldFactory/input/components/BooleanInput';
import { reduxForm, Field, formValueSelector, initialize } from 'redux-form';
import get from 'lodash/get';
import { Responsive } from 'react-grid-layout';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import Add from '@material-ui/icons/Add';
import { DashboardCreateEventCreator } from '../../../actions/dashboardCreateActions';
import DashCreateButton from './DashCreateButton';
import { loadAllDashboards } from 'dashboard2/dashboard-config/load-dashboards/actions';
import { RootState } from 'reducers/rootReducer';
import { DashboardConfig } from 'dashboard2/dashboard-config/types';
import { RemoteData } from '@devexperts/remote-data-ts';
import { storageController } from 'storage';
import ReactResizeDetector from 'react-resize-detector';

const fieldMappings = require('./config.json');

interface DashboardCreatorProps {
    widthBreakPoints: {};
    heightBreakPoints: {};
    breakpoints: {};
    cols: {
        md: string;
        sm: string;
    };
    rowHeight: string;
    dashboardWidgets: {};
    handleSubmit: Function;
    createDefinition: Function;
    updateLayout: Function;
    dashName: String;
    reset: Function;
    deleteWidgetDefinition: Function;
    definitions: {};
    allDashboardNames: RemoteData<Error, string[]>;
    loadAllConfigs: Function;
    allConfigs: {};
    clearAll: Function;
    setEdited: Function;
    initialize: Function;
    initialValues: {};
}

interface DashboardCreatorState {
    isOpen: boolean;
    widgets: {}[];
    newWidgetCounter: number;
    cols: any; //tslint:disable-line
    breakpoint: any; //tslint:disable-line
    layout: any; //tslint:disable-line
    dialogOpen: boolean;
    newWidgetType: string;
    editWidgetId: string;
    dashboardToEdit: string;
}

class DashboardCreator extends React.Component<DashboardCreatorProps, DashboardCreatorState> {
    static defaultProps = {
        className: 'layout',
        rowHeight: 30,
    };
    constructor(props: DashboardCreatorProps) {
        super(props);
        this.state = {
            isOpen: false,
            widgets: [],
            newWidgetCounter: 0,
            cols: null,
            breakpoint: null,
            layout: null,
            dialogOpen: false,
            newWidgetType: '',
            editWidgetId: '',
            dashboardToEdit: '',
        };
        this.onBreakpointChange = this.onBreakpointChange.bind(this);
    }

    importDashboard = config => {
        const mediumLayout = config.dashboardConfig.grid.layouts.md;
        const widgetDefinitions = Object.values((config.dashboardConfig as DashboardConfig).widgets);
        this.props.updateLayout(mediumLayout);
        const widgetConf = mediumLayout.map(wi => {
            const type = widgetDefinitions.filter(def => def.id === wi.i)[0].type;

            return {
                i: wi.i,
                x: wi.x,
                y: wi.y,
                w: wi.w,
                h: wi.h,
                type: type,
            };
        });

        this.setState({ widgets: widgetConf });

        widgetDefinitions.forEach(wi => {
            this.props.createDefinition(wi.id, wi.type, wi.title, wi.definition);
        });
    };

    getConfigForEdit = () => {
        if (this.props.allConfigs && this.props.allConfigs[this.state.dashboardToEdit]) {
            this.importDashboard(this.props.allConfigs[this.state.dashboardToEdit]);
        }
    };

    getSampleDashboard = () => {
        const config = require('../../../config.js');

        const request = new Request(`${config.API_URL}user-dashboards`, {
            method: 'GET',
            credentials: 'same-origin',
            headers: new Headers({
                Authorization: `Bearer ${storageController.getToken()}`,
                Cookie: `${window.document.cookie}`,
                Accept: 'application/json',
                'Content-type': 'application/json',
            }),
        });
        fetch(request).then(response => {
            if (response && response.status !== 404 && response.status !== 500) {
                response.json().then(data => {
                    const conf = data[0];
                    const parsed = JSON.parse(conf.config);
                    this.importDashboard(parsed);
                });
            }
        });
    };
    handleMenuSwitch = key => {
        this.setState({ dashboardToEdit: key.target.value });
    };
    clearAll = () => {
        this.setState({ dashboardToEdit: '' });
        this.props.clearAll();
    };

    generateTextInputs = () => {
        return (
            <div>
                <Card style={{ width: '98%', margin: '0 auto' }}>
                    <CardHeader title={'Select a dashboard or create a new dashboard'} />
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                        <div style={{ display: this.state.dashboardToEdit === '' ? 'block' : 'none' }}>
                            <Field label={'Name'} component={TextInput} key={'name'} name={'name'} />
                            <br />
                        </div>

                        {this.props.allDashboardNames.fold(
                            <span>Pending</span>,
                            <CircularProgress />,
                            err => (
                                <span>Failed: {err.message}</span>
                            ),
                            dashboardNames => (
                                <div>
                                    <Select
                                        style={{ minWidth: '150px', marginRight: 15, minHeight: 36 }}
                                        aria-label="Select Dashboard"
                                        value={
                                            this.state.dashboardToEdit === '' ? 'Select...' : this.state.dashboardToEdit
                                        }
                                        onChange={this.handleMenuSwitch}
                                    >
                                        {dashboardNames.map((key, i) => (
                                            <MenuItem
                                                key={key}
                                                value={key}
                                                id={key}
                                                onClick={() => {
                                                    this.handleMenuSwitch(key);
                                                }}
                                            >
                                                {key}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <Button onClick={() => this.getConfigForEdit()}>Edit</Button>
                                    <Button onClick={() => this.clearAll()}>Clear</Button>
                                </div>
                            ),
                        )}

                        <div style={{}}>
                            <DashCreateButton
                                isEdit={this.state.dashboardToEdit !== ''}
                                name={this.props.dashName || this.state.dashboardToEdit}
                            />
                        </div>
                        <FormControl>
                            <InputLabel id="demo-simple-select-label">Add Widget</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                style={{ minWidth: '150px', marginRight: 15, minHeight: 36 }}
                                value={'add widget'}
                                onChange={this.addWidget}
                            >
                                {Object.keys(fieldMappings).map(field => (
                                    <MenuItem
                                        key={field}
                                        value={field}
                                        id={field}
                                        onClick={() => {
                                            this.addWidget(field);
                                        }}
                                    >
                                        {field}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </Card>
            </div>
        );
    };

    createWidget = widget => {
        return (
            <div data-grid={widget} key={widget.i} style={{ backgroundColor: '#E6E6E6' }}>
                {widget.i}
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <p>Layout [x, y, w, h]: </p>
                    <div style={{ paddingTop: '1em' }}>
                        {this.state.layout &&
                            this.state.layout.map(function(l) {
                                if (l.i === widget.i) {
                                    return `[${l.x}, ${l.y}, ${l.w}, ${l.h}]`;
                                }
                                return null;
                            })}
                    </div>
                </div>
                <span
                    onClick={() => this.onWidgetRemove(widget.i)}
                    style={{
                        position: 'absolute',
                        right: '2px',
                        top: 0,
                    }}
                >
                    x
                </span>
                <div
                    style={{
                        position: 'absolute',
                        right: '50%',
                        top: '50%',
                    }}
                >
                    <Button onClick={() => this.onWidgetEdit(widget.type, widget.i)}>Edit</Button>
                </div>
            </div>
        );
    };
    onWidgetEdit = (type, id) => {
        this.props.setEdited(id);
        this.setState({ editWidgetId: id });

        const fields = Object.keys(fieldMappings[type]);
        this.props.initialize(
            'dashboardcreate',
            { ...this.props.definitions[id], id: id, ...this.props.definitions[id].definition },
            fields,
        ); //tslint:disable-line
        this.handleDialogOpen(type);
    };
    addWidget = event => {
        this.setState({ editWidgetId: '' });
        this.handleDialogOpen(event.target.value);
    };
    addWidgetToState = id => {
        this.setState({
            widgets: this.state.widgets.concat({
                i: id,
                x: (this.state.widgets.length * 2) % 12,
                y: Number.MAX_SAFE_INTEGER,
                w: 2,
                h: 2,
                type: this.state.newWidgetType,
                num: this.state.newWidgetCounter,
            }),
            newWidgetCounter: this.state.newWidgetCounter + 1,
        });
    };
    generateWidgetPalette = () => {
        // Text widget pallete to create widgets on the grid
        const colStyle = { marginLeft: '20px', display: 'flex', flexDirection: 'column', height: 'inherit' };
        const rowStyle = {
            display: 'flex',
            alignItems: 'baseline',
            justifyContent: 'space-between',
            borderBottom: '1px solid black',
        };
        /*tslint:disable:no-any*/
        return (
            <div style={colStyle as any}>
                {Object.keys(fieldMappings).map(field => (
                    <div key={field} style={rowStyle as any}>
                        <span>{field}</span>
                        <IconButton onClick={() => this.addWidget(field)}>
                            <Add />
                        </IconButton>
                    </div>
                ))}
            </div>
        );
        /*tslint:enable:no-any*/
    };
    onLayoutChange(layout) {
        this.setState({ layout: layout });
    }
    onBreakpointChange(breakpoint, cols) {
        //tslint:disable-line
        this.setState({
            breakpoint: breakpoint,
            cols: cols,
        });
    }
    onWidgetRemove(i: String) {
        this.props.deleteWidgetDefinition(i);
        this.setState({ widgets: this.state.widgets.filter(o => get(o, 'i') !== i) }); //tslint:disable-line
    }

    generateGrid = () => {
        const containerStyle = {
            width: '98%',
            height: '500px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        };
        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                <Card style={this.state.widgets.length !== 0 ? { width: '100%' } : containerStyle}>
                    {this.state.widgets.length > 0 ? (
                        <div>
                            <ReactResizeDetector handleWidth>
                                {({ width }) => (
                                    <Responsive
                                        rowHeight={this.props.rowHeight}
                                        width={width}
                                        className="layout"
                                        onBreakpointChange={() => this.onBreakpointChange}
                                        onLayoutChange={layout => {
                                            this.props.updateLayout(layout);
                                            this.onLayoutChange(layout);
                                        }} //tslint:disable-line
                                        onResize={layout => {
                                            this.onLayoutChange(layout);
                                        }}
                                        onDrag={layout => {
                                            this.onLayoutChange(layout);
                                        }}
                                    >
                                        {this.state.widgets.map(widget => this.createWidget(widget))}
                                    </Responsive>
                                )}
                            </ReactResizeDetector>
                        </div>
                    ) : (
                        <div
                            style={{
                                border: '2px solid #B0B0B0',
                                position: 'relative',
                                width: '80%',
                                height: '80%',
                                borderRadius: '25px',
                                backgroundColor: '#E6E6E6',
                                margin: '0 auto',
                            }}
                        >
                            {/* {this.onLayoutChange()} */}
                            <span
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    color: '#B0B0B0',
                                    left: '40%',
                                    fontSize: 'large',
                                }}
                            >
                                {' '}
                                Widgets will appear here.
                            </span>
                        </div>
                    )}
                </Card>
            </div>
        );
    };
    handleDialogCloseActions(values: any) {
        //tslint:disable-line

        const filtered = Object.keys(values).filter(
            key =>
                Object.keys(fieldMappings[this.state.newWidgetType]).includes(key) && key !== 'id' && key !== 'title',
        );

        const definition = {};
        const gridProps = {};
        filtered.forEach(key => {
            if (key !== 'moved' && key !== 'static') definition[key] = values[key];
            else gridProps[key] = values[key];
        });
        let newLayoutProps = this.state.layout;
        if (Object.entries(gridProps).length > 0) {
            newLayoutProps.map(l => {
                l.concat(gridProps);
                return null;
            });
            this.onLayoutChange(newLayoutProps);
        }

        this.props.createDefinition(values.id, this.state.newWidgetType, values.title, definition);

        this.setState({ dialogOpen: false });

        if (this.state.editWidgetId === '') {
            this.addWidgetToState(values.id);
        }

        this.props.reset();
    }
    handleDialogOpen(widgetType: string) {
        this.setState({ dialogOpen: true, newWidgetType: widgetType });
    }

    getCurrentDefinition(id: string) {
        if (this.props.definitions && this.props.definitions[id]) {
            return this.props.definitions[id].definition;
        }
        return false;
    }
    createFieldsForNewWidget(fieldMapping: {}): JSX.Element {
        if (this.state.newWidgetType !== '') {
            const currentValues = this.getCurrentDefinition(this.state.editWidgetId);
            return (
                <div style={{ display: 'flex', flexDirection: 'column', padding: '60px' }}>
                    {Object.keys(fieldMapping).map(field => {
                        if (currentValues) {
                            if (fieldMapping[field] === 'text') {
                                return (
                                    <div style={{ paddingTop: '1em' }}>
                                        <Field
                                            component={TextInput}
                                            label={field}
                                            aria-label="name"
                                            name={field}
                                            key={field}
                                        />
                                    </div>
                                );
                            } else {
                                return (
                                    <div style={{ paddingTop: '1em' }}>
                                        <Field
                                            component={BooleanInput}
                                            label={field}
                                            aria-label="name"
                                            name={field}
                                            key={field}
                                        />
                                    </div>
                                );
                            }
                        } else {
                            return (
                                <div style={{ paddingTop: '1em' }}>
                                    <Field
                                        component={fieldMapping[field] === 'text' ? TextInput : BooleanInput}
                                        props={{ customStyles: { width: '75%' } }}
                                        label={field}
                                        aria-label="name"
                                        name={field}
                                        key={field}
                                    />
                                </div>
                            );
                        }
                    })}
                    <div style={{ paddingTop: '1em' }}>
                        <Field component={BooleanInput} label="moved" aria-label="moved" name="moved" key={'moved'} />
                    </div>
                    <div style={{ paddingTop: '1em' }}>
                        <Field component={BooleanInput} label="static" aria-label="static" name="static" key="static" />
                    </div>
                </div>
            );
        }
        return <div>Loading...</div>;
    }
    renderDialog() {
        const text = this.state.editWidgetId !== '' ? 'Edit Widget' : 'Add Widget';
        return (
            <Card>
                <CardHeader title="Widget Definition" />
                <div>{this.createFieldsForNewWidget(fieldMappings[this.state.newWidgetType])}</div>
                <Button
                    color="default"
                    onClick={this.props.handleSubmit(values => {
                        this.handleDialogCloseActions(values);
                    })}
                    style={{ right: 0 }}
                >
                    {text}
                </Button>
            </Card>
        );
    }
    handleBasicClose() {
        this.setState({ dialogOpen: false });
    }
    componentDidMount() {
        this.props.loadAllConfigs();
    }
    render() {
        return (
            <div>
                {this.generateTextInputs()}
                <br />
                {this.generateGrid()}
                <Dialog
                    TransitionProps={
                        {
                            // https://github.com/dequelabs/axe-core/issues/146
                            role: 'presentation',
                        } as any
                    }
                    maxWidth={false}
                    fullWidth={true}
                    open={this.state.dialogOpen}
                    onBackdropClick={() => this.handleBasicClose()}
                >
                    {this.renderDialog()}
                </Dialog>
            </div>
        );
    }
}

const selector = formValueSelector('dashboardcreate');

const mapStateToProps = (state: RootState, ownProps) => ({
    dashName: selector(state, 'name'),
    viewConfig: state.viewConfig,
    definitions: state.dashboardCreator.widgets,
    allDashboardNames: state.dashboard.dashboards.allDashboardNames,
    allConfigs: state.dashboard.dashboards.configs,
    currentEdited: state.dashboardCreator.currentEdited,
    initialValues:
        state.dashboardCreator &&
        state.dashboardCreator.widgets &&
        state.dashboardCreator.currentEdited &&
        state.dashboardCreator.widgets[state.dashboardCreator.currentEdited],
});

const mapDispatchToProps = {
    createDefinition: DashboardCreateEventCreator.createDefinition,
    updateLayout: DashboardCreateEventCreator.updateLayout,
    deleteWidgetDefinition: DashboardCreateEventCreator.deleteDefinition,
    loadAllConfigs: loadAllDashboards,
    clearAll: DashboardCreateEventCreator.clearAll,
    setEdited: DashboardCreateEventCreator.setEdited,
    initialize: initialize,
};
const DashboardCreate = compose(
    reduxForm({
        form: 'dashboardcreate',
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
)(DashboardCreator);

export default DashboardCreate;
