import React from 'react';
import { customSortFields } from '../overrides';

type FieldComponent = React.ReactElement<{ source: string; sortable?: boolean }>;
export default (entityName: string): ((fieldComponent: FieldComponent) => FieldComponent) => {
    for (const [regexString, body] of Object.entries(customSortFields)) {
        // eslint-disable-line no-restricted-syntax
        const rx = new RegExp(regexString);
        if (rx.test(entityName)) {
            return fieldComponent => {
                if (body[fieldComponent.props.source]) {
                    return fieldComponent;
                }
                // unsortable by default
                return React.cloneElement(fieldComponent, { sortable: false });
            };
        }
    }
    return fieldComponent => fieldComponent;
};
