import { SetDifference } from 'utility-types';
import * as fieldTypes from '../../fieldTypes';
import { searchTypes } from '../../../reducers/ViewConfigType';
import {
    ExpressionFormField,
    SimpleFormField,
    ValuesetFormField,
    EntityLookupField,
    TableFormField,
    RadioFormField,
    ValueSetRadioboxFormField,
    ListField,
    ValuesetManyFormField,
    AddressFormField,
    ValueSetManyCheckboxFormField,
    EventFormField,
    EntityTypeaheadField,
    DMSDocLinkField,
    ValuesetSuggestFormField,
    EntityMultipleTypeaheadField,
} from '../fromFlowable/types/index';
import { DataSource } from './index';
export { default as DataSource } from './DataSource';

type fieldConstants = (typeof fieldTypes)[keyof typeof fieldTypes];
export type validationFunction = (value: any, _, props: {}) => string | undefined[]; // tslint:disable-line

interface BaseField {
    name: string;
    type: (typeof fieldTypes)[keyof typeof fieldTypes];
    label: string | null;
    originalDefinition: string;
    _dataSource: DataSource;
}

/*
    Entity Below
*/
interface GridField {
    column?: number;
    row?: number;
    span?: number;
}
export interface GenericField extends BaseField, GridField {}
export interface EntityField extends GenericField {
    _dataSource: DataSource.ENTITY;
    fieldInstanceIdentifier?: string;
    description?: string;
}

export interface EntityExpressionField extends EntityField {
    type: typeof fieldTypes.HTML_EXPRESSION;
    htmlConfig?: string;
}
export interface EntityAddressVerificationField extends EntityField {
    type: typeof fieldTypes.ADDRESS_VERIFICATION;
    config?: string;
}
export interface EntityEventField extends EntityField {
    type: typeof fieldTypes.EVENT;
    config?: string;
}
export interface EntityRefManyField extends EntityField {
    type: typeof fieldTypes.REFMANY_MULTISELECT;
    config?: string;
}
type uniqueEntityFields = {
    html_expression: EntityExpressionField;
    address_verification: EntityAddressVerificationField;
    ref_many: EntityRefManyField;
    event: EntityEventField;
};
type typesOfUniqueEntityFields = uniqueEntityFields[keyof uniqueEntityFields]['type'];

/*
    Flowable Below
*/

export interface FlowableField extends GenericField {
    _dataSource: DataSource.FLOWABLE;
    readOnly?: boolean;
    value?: any; // tslint:disable-line
    validate?: validationFunction[];
    params?: SimpleFormField['params'];
    description?: string;
    required?: boolean;
}

export interface FlowableFieldOption {
    name: string;
    configs?: {
        visibility?: string;
    };
}

// special
export interface FlowableDropdownField extends FlowableField {
    type: typeof fieldTypes.CHOICES_SELECT;
    options: FlowableFieldOption[];
    hasEmptyValue?: boolean;
}
export interface FlowableRadioField extends FlowableField {
    type: typeof fieldTypes.RADIO;
    options: FlowableFieldOption[];
    params: RadioFormField['params'];
    hasEmptyValue?: boolean;
}
export interface FlowableExpressionField extends FlowableField {
    type: typeof fieldTypes.EXPRESSION;
    params: ExpressionFormField['params'];
}
export interface FlowableValueSetField extends FlowableField {
    type: typeof fieldTypes.VALUESET_SELECT;
    params: ValuesetFormField['params'];
}
export interface FlowableValueSetSuggestField extends FlowableField {
    type: typeof fieldTypes.VALUESET_SUGGEST;
    params: ValuesetSuggestFormField['params'];
}
export interface FlowableValueSetManyField extends FlowableField {
    type: typeof fieldTypes.VALUESET_MULTISELECT;
    params: ValuesetManyFormField['params'];
}
export interface FlowableEntityLookupField extends FlowableField {
    type: typeof fieldTypes.REFONE_SELECT | typeof fieldTypes.REFONE_JOIN_SELECT;
    params: EntityLookupField['params'];
}
export interface FlowableDmsDocLinkField extends FlowableField {
    type: typeof fieldTypes.DMS_DOCUMENT;
    params: DMSDocLinkField['params'];
}
export interface FlowableEntityTypeaheadField extends FlowableField {
    type: typeof fieldTypes.ENTITY_TYPEAHEAD;
    params: { filter?: string } & EntityTypeaheadField['params'];
}
export interface FlowableMultipleEntityTypeaheadField extends FlowableField {
    type: typeof fieldTypes.MULTIPLE_ENTITY_TYPEAHEAD;
    params: { filter?: string } & EntityMultipleTypeaheadField['params'];
}
export interface FlowableValueSetRadioField extends FlowableField {
    type: typeof fieldTypes.VALUESET_CHECKBOX;
    params: ValueSetRadioboxFormField['params'];
}
export interface FlowableValueSetManyCheckboxField extends FlowableField {
    type: typeof fieldTypes.VALUESET_MULTICHECKBOX;
    params: ValueSetManyCheckboxFormField['params'];
}
export interface FlowableTableField extends FlowableField {
    type: typeof fieldTypes.TABLE;
    params: TableFormField['params'];
    value: TableFormField['value'];
}
export interface FlowableListField extends FlowableField {
    type: typeof fieldTypes.LIST;
    params: ListField['params'];
    value: ListField['value'];
}
export interface FlowableEntityChipField extends FlowableField {
    type: typeof fieldTypes.REFMANYMANY_CHIP;
    params: {
        filter?: string;
    } & FlowableField['params'];
}
export interface FlowableAddressField extends FlowableField {
    type: typeof fieldTypes.ADDRESS_VERIFICATION;
    params: AddressFormField['params'];
}
export interface FlowableEventField extends FlowableField {
    type: typeof fieldTypes.EVENT;
    params: EventFormField['params'];
}

/* unique as in, doesn't exist for entities */
interface UniqueFlowableFields {
    [fieldTypes.CHOICES_SELECT]: FlowableDropdownField;
    [fieldTypes.RADIO]: FlowableRadioField;
    [fieldTypes.EXPRESSION]: FlowableExpressionField;
    [fieldTypes.TABLE]: FlowableTableField;
    [fieldTypes.LIST]: FlowableListField;
}
/* Has a corresponding entity version, but has a different type that needs to be discriminated */
interface SpecialFlowableFields extends UniqueFlowableFields {
    [fieldTypes.VALUESET_SELECT]: FlowableValueSetField;
    [fieldTypes.VALUESET_SUGGEST]: FlowableValueSetSuggestField;
    [fieldTypes.VALUESET_MULTISELECT]: FlowableValueSetManyField;
    [fieldTypes.VALUESET_MULTICHECKBOX]: FlowableValueSetManyCheckboxField;
    [fieldTypes.VALUESET_CHECKBOX]: FlowableValueSetRadioField;
    [fieldTypes.ADDRESS_VERIFICATION]: FlowableAddressField;
    [fieldTypes.EVENT]: FlowableEventField;
    refoneOrRefoneJoin: FlowableEntityLookupField;
    [fieldTypes.DMS_DOCUMENT]: FlowableDmsDocLinkField;
    [fieldTypes.ENTITY_TYPEAHEAD]: FlowableEntityTypeaheadField;
    [fieldTypes.MULTIPLE_ENTITY_TYPEAHEAD]: FlowableMultipleEntityTypeaheadField;
    [fieldTypes.REFMANYMANY_CHIP]: FlowableEntityChipField;
}
type typesOfUniqueFlowableFields = UniqueFlowableFields[keyof UniqueFlowableFields]['type'];
type typesOfSpecialFlowableFields = SpecialFlowableFields[keyof SpecialFlowableFields]['type'];

/* Base fields for entity + flowable */

export interface EntityDataField extends EntityField {
    configuredEntity: string;
    refEntityName?: string;
    validate?: validationFunction[];
    type: SetDifference<fieldConstants, typesOfUniqueEntityFields | typesOfUniqueFlowableFields>;
    searchType?: searchTypes;
    config?: string;
}
export interface FlowableDataField extends FlowableField {
    type: SetDifference<fieldConstants, typesOfSpecialFlowableFields | typesOfUniqueEntityFields>;
    params: SimpleFormField['params'];
    warn?: validationFunction[];
    required?: boolean;
}

/*
    Exports
*/
export type FieldFromFlowable = FlowableDataField | SpecialFlowableFields[keyof SpecialFlowableFields];
export type FieldFromEntity = EntityDataField | uniqueEntityFields[keyof uniqueEntityFields];

export type Field = FieldFromFlowable | FieldFromEntity;
