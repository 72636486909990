import deepEql from 'deep-eql';

/*
    Ids can come back as strings or numbers.
    We check for equality of field values by comparing every primitive as a string.

    Ignores objects since we shouldn't be saving them (this is for finding conflicts with dirty form data)
*/

const isArrayOfStringsOrNumbers = (arr: any[]): arr is (string | number)[] =>
    !arr.find(elem => typeof elem !== 'string' && typeof elem !== 'number'); // tslint:disable-line

const arraysEqlAsIds = (arr1: (string | number)[], arr2: (string | number)[]) => {
    if (arr1.length !== arr2.length) {
        return false;
    }
    const sorted1 = arr1.map(e => `${e}`).sort();
    const sorted2 = arr2.map(e => `${e}`).sort();
    return !sorted1.find((e, i) => sorted2[i] !== e);
};

const arrayNotEqualTo = (value: (string | number)[]) => other =>
    !(other instanceof Array) || !arraysEqlAsIds(other, value);
const stringNotEqualTo = (value: string | number) => other => `${other}` !== `${value}`;

export const kvDiffInBoth = (origInitial: {}, currentDirty: {}) => ([key, value]) => {
    const origInitialValue = origInitial[key];
    const currentDirtyValue = currentDirty[key];
    if (typeof value === 'string' || typeof value === 'number') {
        const notEqual = stringNotEqualTo(value);
        return notEqual(origInitialValue) && notEqual(currentDirtyValue);
    }
    if (value instanceof Array && isArrayOfStringsOrNumbers(value)) {
        const notEqual = arrayNotEqualTo(value);
        return notEqual(origInitialValue) && notEqual(currentDirtyValue);
    }
    // (this gives false positives on objects because string <-> number equality doesn't work)
    // we shouldn't be resolving merge conflicts on objects though
    return !deepEql(origInitialValue, value) && !deepEql(currentDirtyValue, value);
};
