import React, { useContext } from 'react';
import { Card, CardHeader } from '@material-ui/core';
import { ShowWidgetsContext } from 'dashboard2/grid/components/Grid';
import Themed from 'components/Themed';

const CARD_HEADER_OUTER_HEIGHT = '63px';

export interface GetHandleTitleClickProps {
    render: (args: { handleTitleClick: () => void }) => JSX.Element;
}

interface WidgetizeProps<TitleClickProps> {
    layout: {
        cardStyle?: React.CSSProperties;
        contentStyle?: React.CSSProperties;
    };
    title:
        | {
              title: string;
              GetHandleTitleClick?: React.ComponentType<GetHandleTitleClickProps & TitleClickProps>;
              GetHandleTitleClickProps?: TitleClickProps;
          }
        | 'none';
    children: JSX.Element;
}
const WidgetizeComponent = <TitleClickProps extends {}>(props: WidgetizeProps<TitleClickProps>) => {
    const showWidgetContent = useContext(ShowWidgetsContext);
    const getContentStyle = (): React.CSSProperties => {
        const {
            layout: { contentStyle: _contentStyle },
            title,
        } = props;
        return {
            overflow: 'auto',
            height: title === 'none' ? '100%' : `calc(100% - ${CARD_HEADER_OUTER_HEIGHT})`,
            ..._contentStyle,
        };
    };
    const getCardStyle = (): React.CSSProperties => {
        const {
            layout: { cardStyle },
        } = props;
        return showWidgetContent
            ? { width: '100%', height: '100%', boxShadow: '0 0 2px 1px rgba(0, 0, 0, 0.15)', ...cardStyle }
            : { display: 'none' };
    };
    const getCardHeader = (): JSX.Element | null => {
        const { title } = props;
        if (title !== 'none') {
            if (title.GetHandleTitleClick) {
                const { GetHandleTitleClickProps } = title;
                return (
                    <title.GetHandleTitleClick
                        render={({ handleTitleClick }) => (
                            <Themed>
                                {({ theme }) => (
                                    <CardHeader
                                        onClick={handleTitleClick}
                                        style={{ cursor: 'pointer', fontWeight: 'bold' }}
                                        titleTypographyProps={{
                                            component: 'h2',
                                            style: {
                                                color: theme.palette.primary.main,
                                            },
                                        }}
                                        title={title.title}
                                    />
                                )}
                            </Themed>
                        )}
                        {...GetHandleTitleClickProps}
                    />
                );
            }
            return (
                <Themed>
                    {({ theme }) => (
                        <CardHeader
                            style={{ fontWeight: 'bold' }}
                            titleTypographyProps={{
                                style: {
                                    color: theme.palette.secondary.contrastText,
                                },
                                component: 'h2',
                            }}
                            title={title.title}
                        />
                    )}
                </Themed>
            );
        }
        return null;
    };
    return (
        <Card style={getCardStyle()}>
            {getCardHeader()}
            <div style={getContentStyle()}>{props.children}</div>
        </Card>
    );
};

export default WidgetizeComponent;
