import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { EntityFormContextProvider } from 'components/generics/form/EntityFormContext';
import { FormContextProvider as ShowFormContextProvider } from 'components/generics/form/EntityFormContext/Show';
import { AsyncEventsInProgressContextProvider } from 'bpm/components/TaskDetail/asyncEventCountContext';
import Warning from '@material-ui/icons/Warning';
import { fromNullable } from 'fp-ts/lib/Option';
import DeferredSpinner from 'components/DeferredSpinner';
import { DisplayStatusComponent } from 'remoteStatus/one/components/DisplayStatus';
import { NetworkUnavailable, ServerError } from 'remoteStatus/one/components/pages';
import BpmFormExpressionTester from 'expression-tester/bpm-form';
import ErrorPage from 'remoteStatus/one/components/pages/BaseErrorPage';
import useViewConfig from 'util/hooks/useViewConfig';
import useFetchTaskForm from 'bpm/components/TaskDetail/TaskForm/hooks/useFetchTaskForm';
import InternalTaskForm from './Form';

interface TaskFormProps {
    processId: string;
    taskId: string;
    forceNoLinkedEntityArea?: boolean;
    relatedEntityResource?: string;
    relatedEntityId?: string;
    renderLinkedEntity?: (options?: {
        viewName?: string;
        actions?: JSX.Element | null;
        toolbar?: JSX.Element | null;
    }) => JSX.Element | null;
}
const TaskFormComponent: React.FunctionComponent<TaskFormProps> = props => {
    const { taskId, processId, forceNoLinkedEntityArea } = props;
    const formFetchStatus = useFetchTaskForm(taskId);
    const relatedEntityId = useSelector((state: RootState) => {
        return fromNullable(state.bpm.tasks.byId)
            .mapNullable(byId => byId[taskId])
            .mapNullable(task => task.linkedEntityId)
            .getOrElse(props.relatedEntityId);
    });
    const relatedEntityResource = useSelector((state: RootState) => {
        return fromNullable(state.bpm.tasks.byId)
            .mapNullable(byId => byId[taskId])
            .mapNullable(task => task.linkedEntityType)
            .getOrElse(props.relatedEntityResource);
    });
    const viewConfig = useViewConfig();
    const entityViewName = fromNullable(relatedEntityResource)
        .mapNullable(rer => viewConfig.entities[rer])
        .mapNullable(e => e.defaultViews)
        .mapNullable(v => v.EDIT || v.SHOW)
        .mapNullable(v => v.name)
        .toNullable();

    if (!formFetchStatus) {
        return null;
    }
    const FormPage = (
        <div>
            <AsyncEventsInProgressContextProvider>
                <BpmFormExpressionTester
                    contextType="existing-task-form"
                    taskId={taskId}
                    relatedEntityId={relatedEntityId}
                    relatedEntityResource={relatedEntityResource}
                >
                    {({ formDefinition }) => {
                        if (!formDefinition) {
                            return null;
                        }
                        const assumedViewName = formDefinition.viewName || entityViewName;
                        // lets create the context if the view exists and linkedEntity is provided
                        if (!forceNoLinkedEntityArea && relatedEntityId && assumedViewName) {
                            const internalTaskForm = (
                                <InternalTaskForm
                                    taskId={taskId}
                                    processId={processId}
                                    formDefinition={formDefinition}
                                    relatedEntityId={relatedEntityId}
                                    relatedEntityResource={relatedEntityResource}
                                    renderLinkedEntity={props.renderLinkedEntity}
                                />
                            );
                            return viewConfig.views[entityViewName].viewType === 'SHOW' ? (
                                <ShowFormContextProvider
                                    viewName={assumedViewName}
                                    record={{ id: relatedEntityId, entityType: relatedEntityResource }}
                                >
                                    {internalTaskForm}
                                </ShowFormContextProvider>
                            ) : (
                                <EntityFormContextProvider
                                    viewName={assumedViewName}
                                    formId="record-form" // base forms are always named 'record-form'
                                    record={{ id: relatedEntityId, entityType: relatedEntityResource }}
                                >
                                    {internalTaskForm}
                                </EntityFormContextProvider>
                            );
                        }
                        return (
                            <InternalTaskForm
                                taskId={taskId}
                                processId={processId}
                                formDefinition={formDefinition}
                                relatedEntityId={relatedEntityId}
                                relatedEntityResource={relatedEntityResource}
                                renderLinkedEntity={props.renderLinkedEntity}
                            />
                        );
                    }}
                </BpmFormExpressionTester>
            </AsyncEventsInProgressContextProvider>
        </div>
    );
    return (
        <DisplayStatusComponent
            renderUnhandledError={() => (
                <ErrorPage headingText="Unhandled Error" Icon={Warning} subText={'Check console for error details'} />
            )}
            remoteStatus={formFetchStatus}
            renderLoading={previousStatus => {
                if (previousStatus !== 'initial' && previousStatus._type === 'success') {
                    return FormPage;
                }
                return <DeferredSpinner />;
            }}
            renderNetworkError={() => <NetworkUnavailable />}
            renderServerError={(code, message) => {
                return <ServerError code={code} message={message} />;
            }}
            renderSuccess={() => {
                return FormPage;
            }}
        />
    );
};

export default TaskFormComponent;
