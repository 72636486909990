import * as list from './actions';
import ViewConfig, { View } from 'reducers/ViewConfigType';
import preprocessFilter from 'clients/utils/preprocessFilter';
import createExpansion from 'clients/utils/createExpansionQuery/buildCommaSeperatedExpansions';
import {
    adjustSortFieldName,
    buildPaginationQueryString,
    queryParametersForSearch,
    specialCasesOnlyExpansionQuery,
    expansionQuery,
} from '../util/queryBuilderUtils';
import { fromPredicate } from 'fp-ts/lib/Option';
import { SearchField } from 'casetivity-shared-js/lib/view-config/views';

export const getSecondarySorts = (view?: View, stabilizerDirection: 'ASC' | 'DESC' = 'ASC') => {
    const secondarySortsInOrder = fromPredicate<View>(Boolean)(view)
        .map(v => Object.values(v.fields))
        .map(fields =>
            fields
                .filter(f => {
                    const so = (f as SearchField).sortOrder;
                    return so || so === 0;
                })
                .sort((a: SearchField, b: SearchField) => a.sortOrder - b.sortOrder)
                .map((sf: SearchField) => ({ field: sf.field, direction: sf.sortDir || 'ASC' })),
        )
        .map(([first, ...rest]) => rest) // we only want the secondary sorts, since primary sort is overwritten
        .chain(fromPredicate(l => l.length > 0))
        .map(l => [...l, { field: 'id', direction: stabilizerDirection }])
        .getOrElse([{ field: 'id', direction: stabilizerDirection }]);
    return secondarySortsInOrder;
};
export const getSecondarySortString = (
    view?: View,
    stabilizerDirection: 'ASC' | 'DESC' = 'ASC',
    dontInclude: string[] = [],
    startWith: string = '',
) => {
    const secondarySorts = getSecondarySorts(view, stabilizerDirection).filter(
        f => dontInclude.indexOf(f.field) === -1,
    );
    return secondarySorts.reduce((prev, curr, i) => {
        return `${prev}sort=${curr.field}%2C${curr.direction}${i === secondarySorts.length - 1 ? '' : '&'}`;
    }, startWith);
};

const ID_ASC_SORT = 'sort=id%2CASC';
const buildUrl = (params: list.GetListParams, viewConfig: ViewConfig) => {
    const { page, perPage } = params.pagination;
    const { field = 'id', order = 'DESC' } = params.sort;

    const sortField = adjustSortFieldName(field, viewConfig, params.resource);

    const paginationQuery = buildPaginationQueryString({
        sortField,
        sortDir: order,
        page,
        perPage,
    });

    const tieBreakingSort =
        sortField && params.view
            ? getSecondarySortString(viewConfig.views[params.view], order, [sortField], '&')
            : sortField && sortField !== 'id'
            ? `&${ID_ASC_SORT}`
            : '';

    const restUrl = params.overrideApi || viewConfig.entities[params.resource].restUrl;
    return `${restUrl}?${queryParametersForSearch(
        preprocessFilter(params.filter, viewConfig, params.view),
        true,
    )}${paginationQuery}${tieBreakingSort}${
        params.view
            ? `&expand=${createExpansion(params.view, viewConfig)}${
                  params.appendExpansions
                      ? expansionQuery('&', params.resource, viewConfig, params.appendExpansions)
                      : ''
              }`
            : specialCasesOnlyExpansionQuery('&', params.resource)
    }`;
};
export default buildUrl;
