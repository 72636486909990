import React from 'react';
import { Subtract } from 'utility-types';
import { ListItem, ListItemText, List, Collapse, IconButton } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { withStyles } from '@material-ui/core/styles';
import { DropdownMenuProps } from './DropdownInterface';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { startProcessFromKey } from 'bpm/create-process-instance/actions';

const styles = theme => ({
    button: {
        width: 24,
        height: 24,
        padding: 0,
    },
});

class SidebarDropdown extends React.Component<
    {
        id: string;
        classes: { button: string };
        to?: string | null;
        createProcessInstanceFromKey: typeof startProcessFromKey;
    } & Subtract<DropdownMenuProps, { mobile: DropdownMenuProps['mobile'] }> // sidebar is mobile only
> {
    state = {
        open: false,
    };
    onToggleNestedList = () => {
        this.setState({ open: !this.state.open });
    };

    onMenuItemClick = route => {
        this.props.setSidebarVisibility(false);
        if (route.startsWith('startProcess(') && route.endsWith(')')) {
            this.props.createProcessInstanceFromKey(
                {
                    processDefinitionKey: route.slice('startProcess('.length, -1),
                },
                {
                    handleRedirect: true,
                    successCb: () => null,
                    errorsCbs: {},
                },
            );
        } else {
            this.props.pushAction(`/${route}`);
        }
    };

    getLabel = () => {
        const entitiesLabel = (
            <span
                style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                }}
            >
                {this.props.label}
            </span>
        );
        return entitiesLabel;
    };

    listItemClick = () => {
        const { to } = this.props;
        if (to) {
            this.props.pushAction(to);
        }
    };

    render() {
        if (!this.props.viewConfig || Object.keys(this.props.viewConfig).length === 0) {
            return null;
        }
        const icon = this.state.open ? <ExpandLess /> : <ExpandMore />;
        return (
            <div>
                <ListItem
                    id={this.props.id}
                    button={true}
                    onClick={this.props.to ? this.listItemClick : this.onToggleNestedList}
                >
                    <ListItemText inset={true} primary={this.getLabel()} />
                    {this.props.to ? (
                        <IconButton onClick={this.onToggleNestedList} className={this.props.classes.button}>
                            {icon}
                        </IconButton>
                    ) : (
                        icon
                    )}
                </ListItem>
                <Collapse in={this.state.open} timeout="auto" unmountOnExit={true}>
                    <List component="menu">
                        {this.props.renderMenuItems({
                            navChildren: this.props.navChildren,
                            pushAction: this.props.pushAction,
                            logout: this.props.logout,
                            onMenuTap: this.props.onMenuTap,
                            viewConfig: this.props.viewConfig,
                            onClick: route => this.onMenuItemClick(route),
                            mobile: true,
                        })}
                    </List>
                </Collapse>
            </div>
        );
    }
}
export default compose(
    withStyles(styles),
    connect(
        null,
        { createProcessInstanceFromKey: startProcessFromKey },
    ),
)(SidebarDropdown);
