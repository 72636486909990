import React from 'react';
import { Card } from '@material-ui/core';
import 'react-grid-layout/css/styles.css';
import { connect } from 'react-redux';
import lifecycle from 'recompose/lifecycle';
import compose from 'recompose/compose';
import SsgAppBarMobile from 'components/SsgAppBarMobile';
import Dashboard from './Dashboard';
import { getLoginName } from 'components/generics/utils/viewConfigUtils';
import ViewTitle from 'components/generics/ViewTitle';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';
import { Helmet } from 'react-helmet';
import { RootState } from 'reducers/rootReducer';
import { getPrimaryDash } from 'dashboard2/dashboard-config/user-primary-dashboard/get-primary-dashboard/actions';
import { loadAllDashboards } from 'dashboard2/dashboard-config/load-dashboards/actions';
import { getHasNoAnonWelcomeMessageSelector } from 'util/applicationConfig';

interface DashboardPageProps {}

function mapStateToProps(state: RootState, props: DashboardPageProps) {
    return {
        login: state.viewConfig && state.viewConfig.user && state.viewConfig.user.login,
        loginName: getLoginName(state.viewConfig),
        noAnonWelcomeMessage: getHasNoAnonWelcomeMessageSelector(state),
        viewConfigLoaded: !!(state.viewConfig && state.viewConfig.application),
    };
}

const dispatches = {
    getPrimaryDash: getPrimaryDash,
    loadAllConfigs: loadAllDashboards,
};
type Dispatches = typeof dispatches;

interface DashboardPageComponentProps extends ReturnType<typeof mapStateToProps>, Dispatches {}

const DashboardPage: React.SFC<DashboardPageComponentProps> = ({ loginName, login, noAnonWelcomeMessage }) => {
    const anon = !login || login === 'anonymousUser';
    const welcomeMessage = (() => {
        if (anon) {
            return 'Welcome!';
        }
        if (loginName) {
            return `Welcome Back, ${loginName}!`;
        }
        return 'Welcome Back!';
    })();
    return (
        <div>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            {<SsgAppBarMobile title="Dashboard" />}
            <Card style={{ padding: '1%' }}>
                {anon && noAnonWelcomeMessage ? null : <ViewTitle title={welcomeMessage} />}
                <Dashboard />
            </Card>
        </div>
    );
};

const enhance = compose(
    connect(
        mapStateToProps,
        dispatches,
    ),
    branch((props: DashboardPageComponentProps) => !props.viewConfigLoaded, renderNothing),
    lifecycle({
        componentDidMount() {
            const props: DashboardPageComponentProps = this.props;
            props.loadAllConfigs();
            props.getPrimaryDash();
        },
    }),
);

export default enhance(DashboardPage);
