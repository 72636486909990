import * as React from 'react';
import { Button, Card, CardContent } from '@material-ui/core';
import ThreeColumnRow from './ThreeColumnRow';
import { useDispatch, useSelector } from 'react-redux';
import { push as pushAction } from 'connected-react-router';
import { RootState } from 'reducers/rootReducer';
import Forward from '@material-ui/icons/Forward';

interface ThreeColumnTableProps {
    record1id: string;
    triColumnFields: React.ReactElement<{}>[][];
    handleSubmit: (submit: Function) => void;
    merge: (values: {}) => void;
    primaryRecordTitle?: string | null;
    altRecordTitle?: string;
    renderActions: (arg: ActionsArg) => JSX.Element;
    topTitle?: JSX.Element | null;
    wrapInCard?: boolean;
}
interface ActionsArg {
    handleSubmit: Function;
    merge: Function;
}

const defaultRenderActions = ({ handleSubmit, merge }) => (
    <ThreeColumnRow style={{ borderBottom: 0, paddingTop: '1.5em', paddingBottom: 0 }}>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div style={{ textAlign: 'right' }}>
            <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit(({ mergeRecords, ...values }) => merge(values))}
            >
                Submit
            </Button>
        </div>
    </ThreeColumnRow>
);

const ThreeColumnTable = ({
    record1id,
    triColumnFields,
    handleSubmit,
    merge,
    primaryRecordTitle = null,
    altRecordTitle = 'Other record',
    renderActions = defaultRenderActions,
    topTitle,
    wrapInCard = true,
}: ThreeColumnTableProps) => {
    const Wrapper = wrapInCard ? Card : React.Fragment;
    const dispatch = useDispatch();
    const currPath = useSelector((state: RootState) => {
        return state.router.location.pathname;
    });
    const mainPath = currPath.split('merge')[0];
    return (
        <Wrapper>
            <Button color="primary" style={{ float: 'right' }} onClick={() => dispatch(pushAction(mainPath))}>
                <Forward className="flip-horizontal" /> Back
            </Button>
            {topTitle}
            <CardContent>
                <ThreeColumnRow>
                    <div style={{ textAlign: 'center' }}>
                        <h2>{primaryRecordTitle || `Record ${record1id}`}</h2>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <h2>{altRecordTitle}</h2>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <h2>Merged record</h2>
                    </div>
                </ThreeColumnRow>
                <form autoComplete="off">
                    {triColumnFields.map(([primaryRecordField, secondaryRecordField, mergeField], i) => {
                        return (
                            <div key={i}>
                                <ThreeColumnRow>
                                    {primaryRecordField}
                                    {secondaryRecordField}
                                    {mergeField}
                                </ThreeColumnRow>
                            </div>
                        );
                    })}
                </form>
                {renderActions({
                    handleSubmit,
                    merge,
                })}
            </CardContent>
        </Wrapper>
    );
};
export default ThreeColumnTable;
