import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InjectedFormProps } from 'redux-form';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import TaskHeader from 'bpm/components/TaskDetail/TaskForm/TaskHeader';
import { ProcessTaskAttributes as TaskAttributes } from 'bpm/components/TaskDetail/TaskForm/TaskAttributes';
import { initialValuesSelector as taskAssignmentInitialValuesSelector } from 'bpm/components/TaskDetail/TaskForm/TaskAssignment';
import { getIsAdmin } from 'bpm/components/TaskDetail/Task';
import { RootState, TaskForm } from 'reducers/rootReducer';
import EnhancedRGridWithVis from 'components/generics/form/EnhancedRGridTask';
import { formContext } from 'bpm/components/TaskDetail/TaskForm/FormContext';
import { Divider, Card, Button } from '@material-ui/core';
import getAppCaseByProcessAction from 'bpm/actions/getAppCaseByProcess';
import { Helmet } from 'react-helmet';
import { useCustomError } from 'bpm/components/TaskDetail/TaskForm/customErrorContext';
import DisplayError from 'bpm/components/TaskDetail/TaskForm/customErrorContext/DisplayError';
import { GetComponentProps } from 'util/typeUtils';
import memoize from 'lodash/memoize';
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import Outcomes from 'bpm/components/TaskDetail/TaskForm/Outcomes';
import FormSaveNotifierTrigger from 'formSaveNotifier/components/Trigger';
import { getTaskForm as getTaskFormAction } from 'bpm/task-form/actions';
import { processPageRefreshContext } from 'bpm/components/ProcessDetail/ProcessPage';
import EditableTaskFormLayout from 'layout-editor/components/EditableTaskFormLayout';
import { stagedFormDefinitionContext } from 'expression-tester/bpm-form';
import useViewConfig from 'util/hooks/useViewConfig';
import useFormHasErrors from 'bpm/components/TaskDetail/TaskForm/hooks/useFormHasErrors';
import useTaskOption from 'bpm/components/TaskDetail/TaskForm/hooks/useTaskOption';
import SaveButton from './SaveButton';
import useEvaluateOutcomeExpression from '../../../hooks/useEvaluateOutcomeExpression';

const primaryButtonProps: MuiButtonProps = { color: 'primary' };
const getBackLink = memoize(processId => {
    return props => <Link to={`/processes/${processId}`} {...props} />;
});

const RendersDiv: React.SFC<{ style?: React.CSSProperties }> = props => <div style={props.style}>{props.children}</div>;

export const isDisabled = (taskEndDate, currentUser, taskAssignee) =>
    !taskAssignee ||
    (taskAssignee !== currentUser && taskAssignee.toLowerCase() !== 'anonymoususer') ||
    Boolean(taskEndDate);

export interface FormLayoutProps {
    children?: React.ReactNode;
    onSubmit: () => void; // throws
    fields: React.ReactElement[];
    processId: string;
    taskId: string;
    formDefinition: TaskForm;
    renderLinkedEntity?: (options?: {
        viewName?: string;
        actions?: JSX.Element | null;
        toolbar?: JSX.Element | null;
    }) => JSX.Element | null;
}
const TaskFormLayout: React.SFC<FormLayoutProps & InjectedFormProps> = props => {
    const { processId, taskId, formDefinition, renderLinkedEntity } = props;
    const viewConfig = useViewConfig();
    const processInstance = useSelector((state: RootState) => processId && state.bpm.processInstances.byId[processId]);
    const dispatch = useDispatch();
    const expressionTesterOpen = useSelector((state: RootState) => state.expressionTesterOpen);

    const fc = React.useContext(formContext);
    const { refresh } = React.useContext(processPageRefreshContext);
    const taskO = useTaskOption(taskId);
    const taskLoaded = taskO.isSome();
    const taskOutcome = taskO.map(t => t.outcome).toUndefined();
    const taskName = taskO.map(t => t.name).toUndefined();
    const currentUser = viewConfig && viewConfig.user.login;
    const currentProcessSearch = useSelector((state: RootState) => state.bpm.currentProcessSearch.query);
    const isSubmitting = useSelector((state: RootState) => state.bpm.tasks.submitting[taskId]);
    const [errorState, errorDispatch] = useCustomError();
    const isAdmin = useSelector((state: RootState) => getIsAdmin(state, taskId));
    const taskEndDate = taskO.map(t => t.endDate).toUndefined();
    const entityFormHasErrors = useFormHasErrors('record-form');
    const taskAssignee = useSelector((state: RootState) => taskAssignmentInitialValuesSelector(state, props).assignee);
    const cancelIsVisible = useEvaluateOutcomeExpression(formDefinition, '_cancel', 'visibility');
    const renderLinkedEntityArea = () => {
        if (!renderLinkedEntity) {
            return null;
        }
        const viewName = viewConfig.views[formDefinition.viewName] ? formDefinition.viewName : undefined;
        if (isDisabled(taskEndDate, currentUser, taskAssignee)) {
            /* If the form is disabled, just use the regular Entity's saves */
            return renderLinkedEntity({
                viewName,
            });
        }
        /* Form is editable- save should submit the form as well. */
        return renderLinkedEntity({
            viewName,
            actions: (
                <RendersDiv style={{ margin: '0.5em' }}>
                    <SaveButton
                        taskId={taskId}
                        fields={props.fields}
                        handleSubmit={props.handleSubmit}
                        buttonText={'Save'}
                        onSuccess={(_, submittedTaskFormValues) => {
                            props.initialize(submittedTaskFormValues);
                        }}
                        submissionType="save"
                    />
                </RendersDiv>
            ),
            toolbar: <RendersDiv />,
        });
    };
    const onCompleteSuccess = React.useCallback(
        response => {
            if (response.error) {
                errorDispatch({ type: 'SET_ERROR', errorText: response.error });
                dispatch(getTaskFormAction(taskId));
            } else if (response.redirect) {
                dispatch(push(response.redirect));
            } else if (response.processComplete) {
                dispatch(push(`/processes${currentProcessSearch || ''}`));
            } else if (response.nextTaskId) {
                if (processInstance && processInstance.businessKey) {
                    dispatch(getAppCaseByProcessAction(processId, processInstance.businessKey));
                }
                dispatch(push(`/processes/${processId}/tasks/${response.nextTaskId}/start`));
            } else {
                dispatch(push(`/processes/${processId}`));
            }
        },
        [dispatch, errorDispatch, currentProcessSearch, taskId, processInstance, processId],
    );
    const createCompleteButton = React.useCallback(
        (label: string, outcome?: string, forceDisabled?: boolean, ButtonProps?: GetComponentProps<typeof Button>) => (
            <SaveButton
                taskId={taskId}
                outcome={outcome}
                forceDisabled={forceDisabled}
                ButtonProps={ButtonProps}
                fields={props.fields}
                handleSubmit={props.handleSubmit}
                buttonText={label}
                onSuccess={onCompleteSuccess}
                submissionType="submit"
            />
        ),
        [onCompleteSuccess, props.fields, taskId, props.handleSubmit],
    );
    return (
        <div>
            <Helmet>
                <title>
                    {taskName || (formDefinition && (formDefinition.name || formDefinition.key)) || 'Casetivity'}
                </title>
            </Helmet>
            {expressionTesterOpen && (
                <stagedFormDefinitionContext.Consumer>
                    {({ formDefinition, setFormDefinition }) => (
                        <EditableTaskFormLayout
                            formDefinition={formDefinition}
                            onFormDefinitionChange={({ formDefinition }) => {
                                setFormDefinition(formDefinition);
                            }}
                        />
                    )}
                </stagedFormDefinitionContext.Consumer>
            )}
            <Card style={{ position: 'relative', overflow: 'visible', padding: '1%' }}>
                <TaskHeader
                    isAdmin={isAdmin}
                    processId={processId}
                    title={formDefinition && taskLoaded ? taskName || formDefinition.name || formDefinition.key : ''}
                    formDefinition={formDefinition}
                    taskId={taskId}
                    task={{
                        taskLoaded,
                        taskId,
                        taskName,
                        endDate: taskEndDate,
                        taskOutcome,
                    }}
                />
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        flexDirection: 'row',
                        marginTop: '0.5em',
                        paddingLeft: 'calc(0.5em + 4px)',
                    }}
                >
                    <TaskAttributes taskId={taskId} isAdmin={isAdmin} processId={processId} />
                </div>
                <Divider />
                <div>
                    <form autoComplete="off">
                        {props.fields && <EnhancedRGridWithVis fields={props.fields} formDefinition={formDefinition} />}
                        <div
                            style={{
                                textAlign: 'center',
                                paddingBottom: 10,
                                paddingTop: 10,
                                overflowWrap: 'break-word',
                            }}
                        >
                            <DisplayError errorText={errorState.errorText} errorKey={errorState.key} />
                            {taskLoaded && !taskEndDate ? (
                                <div style={{ display: 'inline-block', width: '100%' }}>
                                    <SaveButton
                                        taskId={taskId}
                                        fields={props.fields}
                                        handleSubmit={props.handleSubmit}
                                        buttonText={'Save'}
                                        onSuccess={() => {
                                            refresh();
                                        }}
                                        submissionType="save"
                                    />
                                    &nbsp;&nbsp;
                                    {cancelIsVisible && (
                                        <Button variant="contained" component={getBackLink(processId)}>
                                            Cancel
                                        </Button>
                                    )}
                                    &nbsp;&nbsp;
                                    {formDefinition &&
                                    formDefinition.outcomes &&
                                    formDefinition.outcomes.filter(
                                        o =>
                                            o.name &&
                                            o.name.toLowerCase() !== '_save' &&
                                            o.name.toLowerCase() !== '_cancel',
                                    ).length > 0 ? (
                                        <Outcomes
                                            formDefinition={formDefinition}
                                            taskLoaded={taskLoaded}
                                            taskOutcome={taskOutcome}
                                            entityFormHasErrors={entityFormHasErrors}
                                            createButton={createCompleteButton}
                                        />
                                    ) : (
                                        createCompleteButton('Complete', undefined, undefined, primaryButtonProps)
                                    )}
                                </div>
                            ) : null}
                        </div>
                    </form>
                </div>
                <FormSaveNotifierTrigger
                    when={!isDisabled(taskEndDate, currentUser, taskAssignee) && fc.isDirty && !isSubmitting}
                />
            </Card>
            {renderLinkedEntityArea()}
        </div>
    );
};

export default TaskFormLayout;
