import React, { createContext } from 'react';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Popup from '../../../../components/Popup';
import { Button, Card, CardContent, CardActions, IconButton } from '@material-ui/core';
import Task from 'bpm/types/task';
import ScheduledTasksController from './ScheduledTasksController';
import { EventComponentProps } from '../Event/Event';
import Clear from '@material-ui/icons/Clear';
import { dateToDateTimeString } from '../DateTimePicker';

let allViews = Object.keys(Views).map(k => Views[k]);
const localizer = momentLocalizer(moment); // or globalizeLocalizer

const mapTaskToEvent = (task: Task): Event => {
    const end = moment(task.schedDate)
        .add(task.schedDuration, 'minutes')
        .toDate();
    return {
        taskId: task.id,
        start: moment(task.schedDate as any).toDate(), // tslint:disable-line
        end,
        title: task.name,
    };
};

interface SelectableProps {
    changeActionsStandard: EventComponentProps['changeActionsStandard'];
    currentStart?: Date;
    currentDuration?: number;
    events: Event[];
    renderAttendeesField: () => JSX.Element | null;
    disabled?: boolean;
    actions: {
        closeDialog: () => void;
    };
}
interface Event {
    taskId?: string;
    start: Date;
    end: Date;
    title: string;
}

class Selectable extends React.Component<SelectableProps> {
    getEvents = () => {
        const { currentStart, currentDuration } = this.props;
        if (currentStart && currentDuration) {
            const start = moment(currentStart).toDate();
            const end = moment(start)
                .add(currentDuration, 'minutes')
                .toDate();
            return [...this.props.events, { start, end, title: 'New Event' }];
        }
        return this.props.events;
    };
    getClearStartAndDuration = (cb?: () => void) => () => {
        const { changeActionsStandard } = this.props;
        changeActionsStandard.date('');
        changeActionsStandard.duration('');
        if (cb) {
            cb();
        }
    };
    render() {
        const { changeActionsStandard, renderAttendeesField, disabled, actions } = this.props;
        return (
            <Popup
                renderDialogContent={({ closeDialog }) => (
                    <Card>
                        <CardContent>Clear Event?</CardContent>
                        <CardActions>
                            <Button onClick={closeDialog}>Cancel</Button>
                            <Button
                                style={{ marginLeft: 'auto' }}
                                color="primary"
                                variant="contained"
                                onClick={this.getClearStartAndDuration(closeDialog)}
                            >
                                Clear
                            </Button>
                        </CardActions>
                    </Card>
                )}
                renderToggler={({ openDialog }) => (
                    <div style={{ height: '100%', position: 'relative' }}>
                        <Calendar
                            style={{ height: '50vh', width: '100%' }}
                            localizer={localizer}
                            events={this.getEvents()}
                            views={allViews}
                            defaultView={Views.WEEK}
                            scrollToTime={new Date(1970, 1, 1, 6)}
                            defaultDate={new Date()}
                            selectable={!disabled}
                            onSelectEvent={event => {
                                if (!event.taskId) {
                                    openDialog()();
                                }
                            }}
                            onSelectSlot={({ start, end }) => {
                                const startDate = moment(start).toDate();
                                changeActionsStandard.date(dateToDateTimeString(startDate));
                                const duration = moment(end).diff(moment(startDate), 'minutes');
                                changeActionsStandard.duration(duration);
                            }}
                        />
                        <Card style={{ position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 10 }}>
                            {renderAttendeesField()}
                        </Card>
                        <div style={{ marginTop: '1em', display: 'flex', flexDirection: 'row-reverse' }}>
                            <Button variant="contained" color="primary" onClick={actions.closeDialog}>
                                Done
                            </Button>
                        </div>
                    </div>
                )}
            />
        );
    }
}
interface PopupCalendarProps {
    processId?: string;
    taskId?: string;
    changeActionsStandard?: EventComponentProps['changeActionsStandard'];
    currentStart?: Date;
    currentDuration?: number;
    renderAttendeesField: () => JSX.Element | null;
    disabled?: boolean;
}

const calendarControllingProperties = createContext<PopupCalendarProps>({
    renderAttendeesField: () => null,
});

const PopupCalendar: React.SFC<PopupCalendarProps> = props => {
    return (
        <Popup
            ComponentProps={{
                fullWidth: true,
            }}
            component="dialog"
            renderDialogContent={({ closeDialog }) => {
                return (
                    <calendarControllingProperties.Provider value={props}>
                        <ScheduledTasksController
                            renderActions={() => (
                                <IconButton aria-label="close" onClick={closeDialog}>
                                    <Clear />
                                </IconButton>
                            )}
                            processId={props.processId}
                            requireProcessId={true}
                            renderList={({ data }) => {
                                return (
                                    <calendarControllingProperties.Consumer>
                                        {({
                                            changeActionsStandard,
                                            currentDuration,
                                            currentStart,
                                            renderAttendeesField,
                                            disabled,
                                        }) =>
                                            changeActionsStandard ? (
                                                <Selectable
                                                    actions={{
                                                        closeDialog,
                                                    }}
                                                    disabled={disabled}
                                                    changeActionsStandard={changeActionsStandard}
                                                    events={(Object.values(data) as {
                                                        schedDate?: Date;
                                                        schedDuration?: number;
                                                        id?: string;
                                                    }[])
                                                        .filter(
                                                            e =>
                                                                e.id !== props.taskId &&
                                                                (e.schedDate && e.schedDuration),
                                                        )
                                                        .map(mapTaskToEvent)}
                                                    currentStart={currentStart}
                                                    currentDuration={currentDuration}
                                                    renderAttendeesField={renderAttendeesField}
                                                />
                                            ) : null
                                        }
                                    </calendarControllingProperties.Consumer>
                                );
                            }}
                        />
                    </calendarControllingProperties.Provider>
                );
            }}
            renderToggler={({ openDialog }) => (
                <Button variant="contained" color="primary" onClick={openDialog()}>
                    Show Case Calendar
                </Button>
            )}
        />
    );
};
export default PopupCalendar;
