import React from 'react';
import AccountCircle from '@material-ui/icons/AccountCircle';
import renderAccountMenuItems from './submenu-generators/accountMenuItems';
import ToolbarDropdown from './ToolbarDropdown';
import SidebarDropdown from './SidebarDropdown';
import { DropdownMenuProps } from './DropdownInterface';
import { Subtract } from 'utility-types';

const Dropdown: React.SFC<DropdownMenuProps> = ({ mobile, ...props }) =>
    mobile ? <SidebarDropdown id="accountdropdown" {...props} /> : <ToolbarDropdown id="accountdropdown" {...props} />;

const AccountMenu: React.SFC<
    Subtract<
        DropdownMenuProps,
        {
            label: DropdownMenuProps['label'];
            navChildren: DropdownMenuProps['navChildren'];
            renderMenuItems: DropdownMenuProps['renderMenuItems'];
        }
    >
> = ({ mobile, ...props }) => (
    <Dropdown
        navChildren={undefined}
        {...props}
        key="accountdropdown"
        renderMenuItems={renderAccountMenuItems}
        label="Account"
        icon={<AccountCircle color="inherit" />}
        mobile={mobile}
    />
);

export default AccountMenu;
