import React from 'react';
import Typography from '@material-ui/core/Typography';
import { NavLink } from 'react-router-dom';
import Title from 'components/Title';
import { withTheme } from '@material-ui/core';

const ThemeableToolbarTitle = withTheme(({ theme }) => (
    <NavLink to="/" style={{ textDecoration: 'none', color: 'unset', flex: 1 }}>
        <Typography
            variant="h6"
            aria-label="Title"
            style={{ color: theme.palette.getContrastText(theme.palette.primary.main) }}
            component="h1"
            to="/"
        >
            <Title />
        </Typography>
    </NavLink>
));

export default ThemeableToolbarTitle;
