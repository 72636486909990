import { useContext, useMemo } from 'react';
import { formContext } from 'components/generics/form/EntityFormContext';
import useViewConfig from 'util/hooks/useViewConfig';
import { evaluateExpression } from 'expressions/evaluate';
import { entityPreprocessValuesForEval } from 'expressions/formValidation';
import {
    getAllFieldEntriesFromView,
    isFieldViewField,
    getDataTypeForFieldExpr,
    getValueSetForFieldExpr,
} from 'components/generics/utils/viewConfigUtils';
import ViewConfig from 'reducers/ViewConfigType';
import fromEntries from 'util/fromentries';
import casetivityViewContext from 'util/casetivityViewContext';
import useEntities from 'util/hooks/useEntities';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { entityAndConceptLookupUtils } from 'expressions/expressionArrays';
import { createRootContext } from 'expressions/evaluate';
import { denormalizeEntitiesByPaths } from 'casetivity-shared-js/lib/viewConfigSchema/denormalizing/buildEntityMappingsFromPaths';
import getFieldsRequiredForExpression from 'clients/utils/getFieldsRequiredForExpression';
import produce from 'immer';

// doesn't include expressions - just fields used in the view's fields directly as data sources.
const getDataFieldPathsInView = (viewConfig: ViewConfig, viewName: string) =>
    getAllFieldEntriesFromView(viewConfig, viewName).flatMap(([key, field]) => {
        if (isFieldViewField(field)) {
            const dataType = getDataTypeForFieldExpr(
                viewConfig,
                viewConfig.views[viewName].entity,
                field.field,
                'TRAVERSE_PATH',
            );
            if (dataType === 'REFONE') {
                return [field.field, `${field.field}Id`];
            }
            if (dataType === 'REFMANYMANY') {
                return [field.field, `${field.field}Ids`];
            }
            return [field.field];
        }
        return [];
    });
const getValueset1FieldsInView = (viewConfig: ViewConfig, viewName: string): { [field: string]: string } =>
    fromEntries<string>(getAllFieldEntriesFromView(viewConfig, viewName).flatMap(([key, field]) => {
        const rootEntity = viewConfig.views[viewName].entity;
        if (isFieldViewField(field)) {
            const dataType = getDataTypeForFieldExpr(viewConfig, rootEntity, field.field, 'TRAVERSE_PATH');
            if (dataType === 'VALUESET') {
                return [
                    [field.field, getValueSetForFieldExpr(viewConfig, rootEntity, field.field, 'TRAVERSE_PATH')] as [
                        string,
                        string,
                    ],
                ];
            }
        }
        return [];
    }) as [string, string][]);

function useEvaluateInEntityContext(expression: string, throwOnException: true): any;
function useEvaluateInEntityContext(expression: string, throwOnException: false, defaultOnException: any): any;
function useEvaluateInEntityContext(expression: string, throwOnException: boolean, defaultOnException?: any) {
    const fc = useContext(formContext);
    const viewConfig = useViewConfig();
    const entities = useEntities();
    const viewContext = useContext(casetivityViewContext);
    const valueSets = useSelector((state: RootState) => state.valueSets);
    const fieldsRequired = useMemo(() => {
        return getFieldsRequiredForExpression(expression);
    }, [expression]);
    const values = produce(
        denormalizeEntitiesByPaths(
            entities,
            fieldsRequired.map(f => (f.endsWith('Id') ? f.slice(0, -2) : f.endsWith('Ids') ? f.slice(0, -3) : f)),
            viewConfig,
            viewConfig.views[fc.viewName].entity,
            fc.initialValues['id'],
        ) || {},
        draftState => {
            fieldsRequired.forEach(f => {
                if (typeof draftState[f] === 'undefined') {
                    draftState[f] = null;
                }
            });
            return draftState;
        },
    );
    const expressionResult = useMemo(() => {
        if (typeof expression === 'boolean') {
            return expression;
        }
        const nullInitializedFields = entityPreprocessValuesForEval(
            values,
            getDataFieldPathsInView(viewConfig, fc.viewName),
            getValueset1FieldsInView(viewConfig, fc.viewName),
            entities,
            {
                viewContext,
                dateFormat: viewConfig.application.dateFormat,
            },
            valueSets,
        );
        const evaluate = () => {
            return evaluateExpression(expression, {
                ...nullInitializedFields,
                ...entityAndConceptLookupUtils(entities, viewConfig, valueSets),
                ...createRootContext({
                    viewContext,
                    dateFormat: viewConfig.application.dateFormat,
                }),
                _user: viewConfig.user,
            });
        };
        if (throwOnException) {
            return evaluate();
        }
        try {
            return evaluate();
        } catch (e) {
            console.error(e);
            return defaultOnException;
        }
    }, [
        expression,
        values,
        entities,
        valueSets,
        viewContext,
        fc.viewName,
        viewConfig,
        throwOnException,
        defaultOnException,
    ]);
    return expressionResult;
}

export default useEvaluateInEntityContext;
