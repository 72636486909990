/*
    View Config
*/

export const LOAD_VIEW_CONFIG = 'LOAD_VIEW_CONFIG';

export const VIEW_CONFIG_RECEIVED = 'VIEW_CONFIG_RECEIVED';

export const VIEW_CONFIG_NOT_RECEIVED = 'VIEW_CONFIG_NOT_RECEIVED';

export const EXPORT_VIEW_CONFIG = 'EXPORT_VIEW_CONFIG';

export const VIEW_CONFIG_NOT_EXPORTED = 'VIEW_CONFIG_NOT_EXPORTED';

export const VIEW_CONFIG_EXPORTED = 'VIEW_CONFIG_EXPORTED';

export const PROCESSES_NOT_EXPORTED = 'PROCESSES_NOT_EXPORTED';

/*
    Open/Close Task Drawer
*/

export const TASK_DRAWER_OPEN = 'TASK_DRAWER_OPEN';

export const TASK_DRAWER_CLOSE = 'TASK_DRAWER_CLOSE';

/*
    Report
 */

export const GET_REPORT_DEFINITIONS = 'GET_REPORT_DEFINITIONS';

export const GET_REPORT_DEFINITIONS_SUCCESS = 'GET_REPORT_DEFINITIONS_SUCCESS';

export const GET_REPORT_DEFINITIONS_FAILURE = 'GET_REPORT_DEFINITIONS_FAILURE';

/* Value Set Related Actions */

export const LOAD_VALUE_SET = 'LOAD_VALUE_SET';

export const VALUE_SET_RECEIVED = 'VALUE_SET_RECEIVED';

export const VALUE_SET_NOT_RECEIVED = 'VALUE_SET_NOT_RECEIVED';

/* Value Set Groups */

export const LOAD_VALUE_SET_GROUP = 'LOAD_VALUE_SET_GROUP';

export const VALUE_SET_GROUP_RECEIVED = 'VALUE_SET_GROUP_RECEIVED';

export const VALUE_SET_GROUP_NOT_RECEIVED = 'VALUE_SET_GROUP_NOT_RECEIVED';

// valueset multiple

export const LOAD_MULTIPLE_VALUE_SETS = 'LOAD_MULTIPLE_VALUE_SETS';

export const MULTIPLE_VALUE_SETS_RECEIVED = 'MULTIPLE_VALUE_SETS_RECEIVED';

export const MULTIPLE_VALUE_SETS_NOT_RECEIVED = 'MULTIPLE_VALUE_SETS_NOT_RECEIVED';

// concepts

export const LOAD_VALUE_SET_CONCEPTS = 'LOAD_VALUE_SET_CONCEPTS';

export const VALUE_SET_CONCEPTS_RECEIVED = 'VALUE_SET_CONCEPTS_RECEIVED';

export const VALUE_SET_CONCEPTS_NOT_RECEIVED = 'VALUE_SET_CONCEPTS_NOT_RECEIVED';

/*
    View Locking
*/

export const SET_AS_TOP_VIEW = 'SET_AS_TOP_VIEW';

export const UNSET_AS_TOP_VIEW = 'UNSET_AS_TOP_VIEW';

/*
    Merge
*/

export const MERGE = 'MERGE';

export const MERGE_SUCCESS = 'MERGE_SUCCESS';

export const MERGE_FAILURE = 'MERGE_FAILURE';

/*
    Not a match
*/
export const NOT_MATCH = 'NOT_MATCH';

/*
    Most Recent Tab opened
*/

export const RECENT_TAB_OPEN = 'RECENT_TAB_OPEN';

/*
    Turn off fetches for debug mode
*/

export const TOGGLE_DEBUG_MODE = 'TOGGLE_DEBUG_MODE';

/*
    Import a new redux-state for debugging
*/

export const REPLACE_STATE = '_REPLACE_STATE';

/*
    print mode
*/
export const TOGGLE_PRINT_MODE = 'TOGGLE_PRINT_MODE';

// clear redux state
export const CLEAR_STORE = 'CLEAR_STORE';
export const CLEAR_STORE_BUT_KEEP_LOCATION = 'CLEAR_STORE_BUT_KEEP_LOCATION';
