import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getStartForm } from '../get-start-form/actions';
import { RootState } from 'reducers/rootReducer';
import { fromNullable } from 'fp-ts/lib/Option';
import { initial } from '@devexperts/remote-data-ts';
import DeferredSpinner from 'components/DeferredSpinner';
import { NetworkUnavailable, ServerError } from 'remoteStatus/one/components/pages';
import StartFormForm from './StartForm';
import Warning from '@material-ui/icons/Warning';
import ErrorPage from 'remoteStatus/one/components/pages/BaseErrorPage';
import produce from 'immer';
import fromEntries from 'util/fromentries';
interface StartFormProps {
    businessKey: string;
    queryParams?: {};
}
const StartForm = (props: StartFormProps) => {
    const processDefinitionId = useSelector((state: RootState) => {
        return fromNullable(state.bpm.processDefinitions.byId[props.businessKey])
            .mapNullable(d => d.id)
            .toNullable();
    });
    const formRemoteData = useSelector((state: RootState) => {
        const _formRemoteData = processDefinitionId && state.bpm.startForms[processDefinitionId];
        return _formRemoteData || initial;
    });
    const dispatch = useDispatch();
    useEffect(() => {
        if (processDefinitionId) {
            dispatch(getStartForm(processDefinitionId));
        }
    }, [dispatch, processDefinitionId]);
    useEffect(() => {
        if (formRemoteData.isFailure()) {
            console.error(formRemoteData.error);
        }
    }, [formRemoteData]);

    return formRemoteData.foldL(
        () => (processDefinitionId ? <DeferredSpinner /> : null),
        () => <DeferredSpinner />,
        err => {
            if (err.status) {
                return <ServerError code={err.status} message={err.message} />;
            }
            if (err.name && err.name.startsWith('AjaxError')) {
                return <NetworkUnavailable retry={() => dispatch(getStartForm(processDefinitionId))} />;
            }
            return (
                <ErrorPage
                    Icon={Warning}
                    headingText="Form Configuration Error"
                    subText="Check console to see the full error and stack trace."
                />
            );
        },
        taskForm => {
            // initialize the form with query-parameters in the url.
            const taskFormInitialized = props.queryParams
                ? produce(taskForm, draftTaskForm => {
                      draftTaskForm.fields.forEach(f => {
                          const value = props.queryParams[f.id];
                          if (f.id && typeof value !== 'undefined') {
                              switch (f.type) {
                                  case 'boolean':
                                      f.value = value;
                                      break;
                                  case 'integer':
                                      f.value = parseInt(value, 10);
                                      break;
                                  case 'dropdown':
                                  case 'radio-buttons':
                                  case 'value-set-multi-select':
                                  case 'entity-multi-select-chip':
                                  case 'value-set-multi-checkbox':
                                  case 'multiple-entity-typeahead':
                                      f.value = JSON.parse(value);
                                      break;
                                  default:
                                      f.value = value;
                              }
                          }
                      });
                      return draftTaskForm;
                  })
                : taskForm;
            // any query-parameters that don't map onto fields, we just append to our submission data
            const passThroughValues = props.queryParams
                ? fromEntries(
                      Object.entries(props.queryParams).filter(([k, v]) => {
                          return !taskForm.fields.some(f => f.id === k);
                      }),
                  )
                : undefined;
            return (
                <StartFormForm
                    businessKey={props.businessKey}
                    formDefinition={taskFormInitialized}
                    passThroughValues={passThroughValues}
                />
            );
        },
    );
};
export default StartForm;
