import { Option, none, some } from 'fp-ts/lib/Option';
import { tryCatch } from 'fp-ts/lib/Either';
import { mapOption } from 'fp-ts/lib/Array';
import fromEntries from 'util/fromentries';
import getFieldsRequiredForExpression from 'clients/utils/getFieldsRequiredForExpression';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import flattenObject from 'flat';
import clone from 'clone';
import traverse from 'traverse';
import flattenArray from 'lodash/flatten';
import deepEql from 'deep-eql';
import { processExpr } from 'casetivity-shared-js/lib/spel/evaluate';
import { alertOnce } from './util';

const useForErrors = (values: {}) => true;
const transformExpr = (__expression: string) => {
    const _expression = processExpr({ stripHashes: true })(__expression).trim();
    const expression = (() => {
        if (_expression.startsWith('[') && _expression.endsWith(']')) {
            return (JSON.parse(_expression) as string[]).join(' && ');
        }
        return _expression;
    })();
    return expression;
};
class CachingExpression {
    cachedValues: {
        [key: string]: Option<any>;
    };
    expression: string;
    cachedResult: Option<any>;
    evaluateFn: (values: {}) => any;
    resultEquality: 'shalloweql' | 'deepeql';
    lastResultCameFromCache: boolean;
    didError: boolean;
    constructor(
        expression: string,
        evaluateFn: (__expression: string) => (values: {}) => any,
        resultEquality: 'shalloweql' | 'deepeql',
    ) {
        const valuesKeysToChangeOn = getFieldsRequiredForExpression(expression);
        this.expression = expression;
        this.cachedValues = fromEntries(valuesKeysToChangeOn.map(k => [k, none] as [string, Option<any>]));
        this.cachedResult = none;
        this.didError = false;
        try {
            this.evaluateFn = evaluateFn(this.expression);
        } catch (e) {
            console.error(e);
            console.error(`The above error occurred for expression '${this.expression}' while parsing`);
            this.evaluateFn = useForErrors;
            alertOnce(this.expression, e);
            this.didError = true;
        }
        this.resultEquality = resultEquality;
        this.lastResultCameFromCache = false;
    }
    isEqual = (left: any, right: any) => {
        if (this.resultEquality === 'shalloweql') {
            return left === right;
        }
        return deepEql(left, right);
    };
    clearCache = () => {
        if (!this.didError) {
            this.cachedResult = none;
        }
    };
    // return Some(x) if there is a new value x calculated.
    maybeEvaluate = (values: {}): Option<any> => {
        if (
            this.cachedResult.isNone() ||
            Object.entries(this.cachedValues).find(
                ([k, maybeValue]) => maybeValue.isNone() || maybeValue.value !== get(values, k),
            )
        ) {
            const newResult = tryCatch(
                () => this.evaluateFn(values),
                (e: Error) => {
                    alertOnce(this.expression, e);
                    this.didError = true; // this has reached an end-state.
                    this.cachedValues = {}; // No longer watch any values.
                    return e;
                },
            ).getOrElseL(() => useForErrors(values));
            if (this.cachedResult.isNone() || !this.isEqual(newResult, this.cachedResult.value)) {
                this.cachedResult = some(newResult);

                // update cached values for the new evaluation result
                Object.entries(this.cachedValues).forEach(([k, cachedValue]) => {
                    if (cachedValue.isNone() || !this.isEqual(get(values, k), cachedValue.value)) {
                        this.cachedValues[k] = some(get(values, k));
                    }
                });
                this.lastResultCameFromCache = false;
            } else {
                this.lastResultCameFromCache = true;
            }
        } else {
            this.lastResultCameFromCache = true;
        }
        return this.cachedResult;
    };
}

interface CachingEvaluatorState {
    [expression: string]: CachingExpression;
}

class CachingEvaluator {
    state: CachingEvaluatorState;
    resultEquality: 'shalloweql' | 'deepeql';
    constructor(
        expressions: string[],
        evaluationFn: (expression: string) => (values: {}) => any,
        resultEquality: 'shalloweql' | 'deepeql',
    ) {
        this.resultEquality = resultEquality;
        this.state = fromEntries(
            expressions.map(expression => {
                return [expression, new CachingExpression(expression, evaluationFn, this.resultEquality)] as [
                    string,
                    CachingExpression,
                ];
            }),
        );
    }
    getChangedExpressions = () => {
        return Object.entries(this.state).flatMap(([key, ce]) => (!ce.lastResultCameFromCache ? [key] : []));
    };
    someValueWasRecalculated = () => {
        return Boolean(Object.values(this.state).find(ce => !ce.lastResultCameFromCache));
    };
    clearCaches = (
        forExpressionsThatMatch: (expression) => boolean,
        evaluationFn: (expression: string, values: {}) => any,
    ) => {
        Object.keys(this.state).forEach(expression => {
            if (forExpressionsThatMatch(expression)) {
                this.state[expression].clearCache();
            }
        });
    };
    // returns an object of any changed values.
    evaluateAll = (values: {}): {
        [expression: string]: any;
    } => {
        return fromEntries(
            mapOption(Object.entries(this.state), ([expression, cachingExp]) =>
                cachingExp.maybeEvaluate(values).map(r => [expression, r] as [string, any]),
            ),
        );
    };
}

interface Expressions {
    [key: string]: string[] | Expressions;
}
class KeyCachingEvaluator<ExpressionsShape extends Expressions> {
    cachingEvaluator: CachingEvaluator;
    // expression -> keys that use it
    invertedExpressions: {
        [expression: string]: string[];
    };
    expressions: ExpressionsShape;
    constructor(
        expressions: ExpressionsShape,
        evaluationFn: (expression: string) => (values: {}) => any,
        resultEquality: 'shalloweql' | 'deepeql',
    ) {
        this.expressions = traverse(clone(expressions)).forEach(function(x) {
            if (Array.isArray(x)) {
                this.update(x.map(transformExpr));
            }
        });
        this.cachingEvaluator = new CachingEvaluator(
            flattenArray<string>(Object.values(flattenObject(this.expressions, { safe: true })))
                .filter(Boolean)
                .filter(
                    v =>
                        // flatten (from 'flat' library) doesn't remove empty objects, so filter these out
                        typeof v === 'string',
                ),
            evaluationFn,
            resultEquality,
        );
        this.invertedExpressions = (() => {
            const invertedExpressions = {};
            Object.entries<string[]>(flattenObject(this.expressions, { safe: true }))
                .filter(([, exp]) => Array.isArray(exp))
                .forEach(([path, expressions]) => {
                    (expressions || []).forEach(exp => {
                        if (!invertedExpressions[exp]) {
                            invertedExpressions[exp] = [path];
                        } else {
                            invertedExpressions[exp].push(path);
                        }
                    });
                });
            return invertedExpressions;
        })();
    }
    // call this whenever we need to pass a new closure, since some state it closes on has changed.
    clearCaches = (
        forExpressionsThatMatch: (expression) => boolean,
        evaluationFn: (expression: string, values: {}) => any,
    ) => {
        this.cachingEvaluator.clearCaches(forExpressionsThatMatch, evaluationFn);
    };
    someValueWasRecalculated = () => {
        return this.cachingEvaluator.someValueWasRecalculated();
    };
    getChangedEvaluations = () => {
        return uniq(
            this.cachingEvaluator.getChangedExpressions().flatMap(e => {
                return this.invertedExpressions[e];
            }),
        );
    };
    // return key value pairs of all new values
    evaluateAll = (values: {}) => {
        type ExpressionResult<T> = {
            [k in keyof T]: T[k] extends string[] ? any[] : ExpressionResult<T[k]>;
        };
        const preresult = Object.entries(this.cachingEvaluator.evaluateAll(values)).flatMap(([expression, value]) => {
            return this.invertedExpressions[expression].map(path => [path, value] as [string, any]);
        });
        const prO = preresult.reduce((prev, [key, result]) => {
            if (!prev[key]) {
                prev[key] = [result];
            } else {
                prev[key].push(result);
            }
            return prev;
        }, {});
        const result = traverse(clone(this.expressions)).forEach(function(x) {
            if (Array.isArray(x)) {
                this.update(prO[this.path.join('.')], true);
            }
        }) as ExpressionResult<ExpressionsShape>;
        return result;
    };
}

export default KeyCachingEvaluator;
