import * as WidgetType from '../../components/generics/utils/widgetTypes';
import ViewConfig, { FieldViewField } from '../../reducers/ViewConfigType';
import { getViewIndexAndAdditionalConfigFields } from '../../components/generics/utils/viewConfigUtils';

const modifySearchKey = (key: string) => {
    var searchKeyComponents = key.split('__');
    if (searchKeyComponents.length === 2) {
        key = searchKeyComponents[0].split('_~_').join('.') + '__' + searchKeyComponents[1];
    }
    return key;
};

export const appendStarToTextFieldsInSearch = (
    fields: { [key: string]: {} },
    viewConfig: ViewConfig,
    viewName?: string | null,
) => {
    const [viewIndex, additionalSearchFields] = viewName
        ? getViewIndexAndAdditionalConfigFields(viewName, viewConfig, 'KEEP_LINKEDX_TYPE', 'WRITE')
        : [null, [] as FieldViewField[]];
    const viewSearchFields = viewIndex ? viewConfig.views[viewIndex].searchFields || {} : {};

    const textSearchFields = Object.keys(viewSearchFields || {})
        .filter(key => {
            if (typeof viewSearchFields === 'undefined') {
                return false;
            }
            const f = viewSearchFields[key];
            if (typeof f === 'undefined') {
                return false;
            }

            return f.widgetType === WidgetType.TEXTBOX || f.widgetType === WidgetType.TEXTAREA;
        })
        .concat(
            additionalSearchFields.flatMap(f =>
                f.widgetType === WidgetType.TEXTBOX || f.widgetType === WidgetType.TEXTAREA
                    ? [f.searchType ? `${f.field}__${f.searchType}` : f.field]
                    : [],
            ),
        );

    const newObj = Object.keys(fields).map(key => {
        const modKey = modifySearchKey(key);
        let ind = textSearchFields.indexOf(modKey);
        if (ind === -1) {
            return { [key]: fields[key] };
        } else {
            switch (modKey.split('__').pop()) {
                case 'EXACT':
                    return { [key]: fields[key] };

                case 'CONTAINS':
                    if (fields[key]) {
                        return { [key]: `*${fields[key]}*` };
                    }
                    return { [key]: '*' };

                case 'STARTS_WITH':
                    return { [key]: `${fields[key]}*` };

                default:
                    return { [key]: `${fields[key]}` };
            }
        }
    });
    return Object.assign({}, ...newObj);
};
