import moment from 'moment';
import memoizeOne from 'memoize-one';
import { differenceInMonths, differenceInYears } from 'date-fns';
export const DATE_PATTERN_FROM_UI = 'YYYY-MM-DD';

/** ******************************************************************************** */
// DON'T CLEAN THE UNUSED METHODS IN THIS FILE BECAUSE THEY ARE USED IN EXPRESSIONS!!!
/** ******************************************************************************** */

export class DateValidator {
    constructor(dateFormat) {
        this.DEFAULT_DATE_PATTERN = this.translateDateFormat(dateFormat);
    }
    getDate = (date, format) => {
        if (typeof format === 'string' && format.toLowerCase() === 'mm/dd/yyyy') {
            const mdy = date.split('/');
            if (mdy[2] && mdy[0] && mdy[1]) {
                return `${mdy[2]}-${mdy[0]}-${mdy[1]}`;
            }
        } else if (typeof format === 'string' && format.toLowerCase() === 'yyyy-mm-dd') {
            const mdy = date.split('-');
            if (mdy[2] && mdy[0] && mdy[1]) {
                return `${mdy[1]}/${mdy[2]}/${mdy[0]}`;
            }
        }
        return date;
    };

    translateDateFormat = memoizeOne(dateFormat => moment().toMomentFormatString(dateFormat || 'MM/dd/yyyy'));

    getDateString = date => this.getDate(date, this.DEFAULT_DATE_PATTERN);

    parseDateStr = (dateStr, pattern = DATE_PATTERN_FROM_UI) => moment(dateStr, pattern.toUpperCase());

    formatDate = (dateStr, pattern) => moment(dateStr).format(pattern);

    compareDateStr = (dateOneStr, dateTwoStr, pattern, operator, includeNonBusiness) => {
        console.log(dateOneStr);
        console.log(dateTwoStr);
        if (!(dateOneStr && dateTwoStr)) {
            return true;
        }

        try {
            if (pattern) {
                const dateOne = this.parseDateStr(dateOneStr, pattern);
                const dateTwo = this.parseDateStr(dateTwoStr, pattern);

                if (!(dateOne && dateTwo)) {
                    return true;
                }

                if (!operator) {
                    return dateOne.isSame(dateTwo);
                }

                switch (operator) {
                    case '>':
                        return dateOne.isAfter(dateTwo);
                    case '>=':
                        return dateOne.isAfter(dateTwo) || dateOne.isSame(dateTwo);
                    case '==':
                        return dateOne.isSame(dateTwo);
                    case '<':
                        return dateOne.isBefore(dateTwo);
                    case '<=':
                        return dateOne.isBefore(dateTwo) || dateOne.isSame(dateTwo);
                    case '|':
                        return includeNonBusiness
                            ? dateOne.diff(dateTwo, 'days')
                            : dateOne.businessDiff(dateTwo, 'days');

                    default:
                        return dateOne.isSame(dateTwo);
                }
            }

            return this.compareDateStr(dateOneStr, dateTwoStr, DATE_PATTERN_FROM_UI, operator);
        } catch (errors) {
            console.log(`Error in comparing dates: ${dateOneStr} ${dateTwoStr}. ${errors}`);
            return true;
        }
    };

    modifyDateStr = (dateOneStr, amount, unit, pattern, operator, includeNonBusiness) => {
        if (!dateOneStr) {
            return true;
        }
        try {
            if (pattern) {
                const upperCasePattern = pattern.toUpperCase();
                const dateOne = moment(dateOneStr, upperCasePattern);

                switch (operator) {
                    case '+':
                        return includeNonBusiness
                            ? dateOne.add(amount, unit).format(pattern)
                            : dateOne.businessAdd(amount, unit).format(pattern);
                    case '-':
                        return includeNonBusiness
                            ? dateOne.subtract(amount, unit).format(pattern)
                            : dateOne.businessSubtract(amount, unit).format(pattern);

                    default:
                        return dateOne;
                }
            }
            return this.modifyDateStr(dateOneStr, amount, unit, DATE_PATTERN_FROM_UI, operator);
        } catch (errors) {
            console.log(`Error in modifying dates: ${dateOneStr}. ${errors}`);
            return true;
        }
    };

    daysBetween = (dateOne, dateTwo, includeNonBusiness = true) =>
        this.compareDateStr(dateOne, dateTwo, DATE_PATTERN_FROM_UI, '|', includeNonBusiness);

    addDaysToDateStr = (dateOne, amount, includeNonBusiness = true) =>
        this.modifyDateStr(dateOne, amount, 'day', DATE_PATTERN_FROM_UI, '+', includeNonBusiness);

    subtractDaysToDateStr = (dateOne, amount, includeNonBusiness = true) =>
        this.modifyDateStr(dateOne, amount, 'day', DATE_PATTERN_FROM_UI, '-', includeNonBusiness);

    addYearsToDateStr = (dateOne, amount, includeNonBusiness = true) =>
        this.modifyDateStr(dateOne, amount, 'year', DATE_PATTERN_FROM_UI, '+', includeNonBusiness);

    addMonthsToDateStr = (dateOne, amount, includeNonBusiness = true) =>
        this.modifyDateStr(dateOne, amount, 'month', DATE_PATTERN_FROM_UI, '+', includeNonBusiness);

    today = () => this.todayStr(DATE_PATTERN_FROM_UI);

    todayDate = () => this.today();

    compareLocalDate = (dateOneStr, dateTwoStr, operator) => {
        return this.compareDateStr(dateOneStr, dateTwoStr, DATE_PATTERN_FROM_UI, operator);
    };

    isLessThanCurrentYear = year => {
        const currentYear = new Date().getFullYear();
        return year < currentYear;
    };

    sameDate = (dateOne, dateTwo) => this.compareLocalDate(dateOne, dateTwo, '==');

    beforeDate = (dateOne, dateTwo) => this.compareLocalDate(dateOne, dateTwo, '<');

    beforeOrEqualsDate = (dateOne, dateTwo) => this.compareLocalDate(dateOne, dateTwo, '<=');

    afterDate = (dateOne, dateTwo) => this.compareLocalDate(dateOne, dateTwo, '>');

    afterOrEqualsDate = (dateOne, dateTwo) => this.compareLocalDate(dateOne, dateTwo, '>=');

    isToday = date => this.sameDate(date, this.todayStr(DATE_PATTERN_FROM_UI));

    beforeToday = date => this.beforeDate(date, this.todayStr(DATE_PATTERN_FROM_UI));

    beforeOrEqualsToday = date => this.beforeOrEqualsDate(date, this.todayStr(DATE_PATTERN_FROM_UI));

    afterToday = date => this.afterDate(date, this.todayStr(DATE_PATTERN_FROM_UI));

    afterOrEqualsToday = date => this.afterOrEqualsDate(date, this.todayStr(DATE_PATTERN_FROM_UI));

    sameDateStr = (dateOneStr, dateTwoStr, pattern) => this.compareDateStr(dateOneStr, dateTwoStr, pattern, '==');

    beforeDateStr = (dateOneStr, dateTwoStr, pattern) => this.compareDateStr(dateOneStr, dateTwoStr, pattern, '<');

    beforeOrEqualsDateStr = (dateOneStr, dateTwoStr, pattern) =>
        this.compareDateStr(dateOneStr, dateTwoStr, pattern, '<=');

    afterDateStr = (dateOneStr, dateTwoStr, pattern) => this.compareDateStr(dateOneStr, dateTwoStr, pattern, '>');

    afterOrEqualsDateStr = (dateOneStr, dateTwoStr, pattern) =>
        this.compareDateStr(dateOneStr, dateTwoStr, pattern, '>=');

    sameDateStrPatterned = (dateOneStr, dateTwoStr) =>
        this.sameDateStr(dateOneStr, dateTwoStr, this.DEFAULT_DATE_PATTERN);

    beforeDateStrPatterned = (dateOneStr, dateTwoStr) =>
        this.beforeDateStr(dateOneStr, dateTwoStr, this.DEFAULT_DATE_PATTERN);

    beforeOrEqualsDateStrPatterned = (dateOneStr, dateTwoStr) =>
        this.beforeOrEqualsDateStr(dateOneStr, dateTwoStr, this.DEFAULT_DATE_PATTERN);

    afterDateStrPatterned = (dateOneStr, dateTwoStr) =>
        this.afterDateStr(dateOneStr, dateTwoStr, this.DEFAULT_DATE_PATTERN);

    afterOrEqualsDateStrPatterned = (dateOneStr, dateTwoStr) =>
        this.afterOrEqualsDateStr(dateOneStr, dateTwoStr, this.DEFAULT_DATE_PATTERN);

    todayStr = pattern => (pattern ? moment().format(pattern.toUpperCase()) : undefined);

    isTodayStr = (dateString, pattern) => this.sameDateStr(dateString, this.todayStr(pattern), pattern);

    beforeTodayStr = (dateString, pattern) => this.beforeDateStr(dateString, this.todayStr(pattern), pattern);

    beforeOrEqualsTodayStr = (dateString, pattern) =>
        this.beforeOrEqualsDateStr(dateString, this.todayStr(pattern), pattern);

    afterTodayStr = (dateString, pattern) => this.afterDateStr(dateString, this.todayStr(pattern), pattern);

    afterOrEqualsTodayStr = (dateString, pattern) =>
        this.afterOrEqualsDateStr(dateString, this.todayStr(pattern), pattern);

    isTodayStrPatterned = dateString => this.isTodayStr(dateString, this.DEFAULT_DATE_PATTERN);

    beforeTodayStrPatterned = dateString => this.beforeTodayStr(dateString, this.DEFAULT_DATE_PATTERN);

    beforeOrEqualsTodayStrPatterned = dateString => this.beforeOrEqualsTodayStr(dateString, this.DEFAULT_DATE_PATTERN);

    afterTodayStrPatterned = dateString => this.afterTodayStr(dateString, this.DEFAULT_DATE_PATTERN);

    afterOrEqualsTodayStrPatterned = dateString => this.afterOrEqualsTodayStr(dateString, this.DEFAULT_DATE_PATTERN);

    monthsBetween = (dateOneStr, dateTwoStr) => {
        if (dateOneStr && dateTwoStr) {
            return differenceInMonths(this.parseDateStr(dateOneStr), this.parseDateStr(dateTwoStr));
        }
        return undefined;
    };

    yearsBetween = (dateOneStr, dateTwoStr) =>
        dateOneStr && dateTwoStr
            ? differenceInYears(this.parseDateStr(dateOneStr), this.parseDateStr(dateTwoStr))
            : undefined;
}
