import React, { Component } from 'react';
import pure from 'recompose/pure';
import TablePagination from '@material-ui/core/TablePagination';
import compose from 'recompose/compose';

import PaginationActions from './RaPaginationActions';
import Responsive from 'util/Responsive';
import uniqueId from 'lodash/uniqueId';

interface PaginationComponentProps {
    page: number;
    perPage: number;
    setPage: (page: number) => void;
    setPerPage: (perPage: number) => void;
    total: number;

    showSmall?: boolean;
    classes?: {};
    className?: string;
    rowsPerPageOptions?: number[];
}
const emptyArray = [];
const defaultRowsPerPageOptions = [5, 10, 25, 100];
export class PaginationComponent extends Component<PaginationComponentProps> {
    mediumRPPLabelId = uniqueId('rowsPerPage');
    getNbPages = () => Math.ceil(this.props.total / this.props.perPage) || 1;

    componentDidUpdate() {
        if (this.props.page < 1 || isNaN(this.props.page)) {
            this.props.setPage(1);
        }
    }

    /**
     * Warning: material-ui's page is 0-based
     */
    handlePageChange = (event, page) => {
        if (event) {
            event.stopPropagation();
        }
        if (page < 0 || page > this.getNbPages() - 1) {
            throw new Error(`Page number ${page + 1} out of boundaries`);
        }
        this.props.setPage(page + 1);
    };

    handlePerPageChange = event => {
        event.preventDefault();
        event.stopPropagation();
        this.props.setPerPage(event.target.value);
    };

    labelDisplayedRows = ({ from, to, count }) => {
        return `${from}-${to} of ${count}`;
    };

    render() {
        const { page, perPage, rowsPerPageOptions = defaultRowsPerPageOptions, total, showSmall } = this.props;

        if (total === 0) {
            return null;
        }
        const small = (
            <TablePagination
                count={total}
                rowsPerPage={perPage}
                page={page - 1}
                onChangePage={this.handlePageChange}
                rowsPerPageOptions={emptyArray}
                component="span"
                labelDisplayedRows={this.labelDisplayedRows}
                backIconButtonProps={{
                    'aria-label': 'previous page',
                }}
                nextIconButtonProps={{
                    'aria-label': 'next page',
                }}
                SelectProps={{
                    onClick: e => {
                        e.preventDefault();
                        e.stopPropagation();
                    },
                    MenuProps: {
                        style: { zIndex: 100000 },
                    },
                }}
            />
        );
        const medium = (
            <TablePagination
                count={total}
                rowsPerPage={perPage}
                page={page - 1}
                onChangePage={this.handlePageChange}
                onChangeRowsPerPage={this.handlePerPageChange}
                ActionsComponent={PaginationActions}
                component="span"
                labelRowsPerPage={<span id={this.mediumRPPLabelId}>Rows per page:</span>}
                labelDisplayedRows={this.labelDisplayedRows}
                rowsPerPageOptions={rowsPerPageOptions}
                SelectProps={{
                    onClick: e => {
                        e.preventDefault();
                        e.stopPropagation();
                    },
                    MenuProps: {
                        style: { zIndex: 100000 },
                    },
                    SelectDisplayProps: {
                        'aria-describedby': this.mediumRPPLabelId,
                    },
                }}
            />
        );
        return showSmall ? small : <Responsive small={small} medium={medium} />;
    }
}
const Pagination: React.SFC<PaginationComponentProps> = compose(pure)(PaginationComponent);
export default Pagination;
