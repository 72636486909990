import React, { Component } from 'react';
import { parse } from 'query-string';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import Create from './Create';
import withPopoverLock from '../hoc/popoverLock';
import {
    getValueSetCodesRequiredForEntity,
    getView,
    getAccessLevelForEntity,
    allowsEdit,
} from '../utils/viewConfigUtils';
import { loadValueSets as loadValueSetsAction } from '../../../actions/valueSetsActions';
import { setAsTopView, unsetAsTopView } from '../../../popoverStackManagement/actions';
import uniq from 'lodash/uniq';
import { getEditableViewVSCodeLiteralsSelector } from '../form/valuesetCodeExpressionLiteralsSelector';
import { SpelOptions } from 'expressions/evaluate';

export interface CreateProps {
    location: Location;
    resource: string;
    viewName: string;
    basePath: string;
    match: {};
    formId?: string;
    options: SpelOptions;
    injectValues?: {};
    redirect?: string | false;
    submitOnEnter?: boolean;
    toolbar?: JSX.Element;
    isPopover?: boolean;
}

const makeMapStateToProps = () => {
    const getValueSetCodeLiterals = getEditableViewVSCodeLiteralsSelector();
    const mapStateToProps = (state, props) => {
        return {
            valueSetCodeLiterals: getValueSetCodeLiterals(state, props),
            viewConfig: state.viewConfig,
            viewStack: state.viewStack,
        };
    };
    return mapStateToProps;
};
const dispatches = {
    loadValueSets: loadValueSetsAction,
    setAsTopView,
    unsetAsTopView,
};
type Dispatches = typeof dispatches;

interface CreateComponentProps extends CreateProps, ReturnType<ReturnType<typeof makeMapStateToProps>>, Dispatches {}

class GenericCreate extends Component<CreateComponentProps> {
    componentDidMount() {
        const valuesets = uniq(
            getValueSetCodesRequiredForEntity(this.props.viewConfig, this.props.viewName).concat(
                this.props.valueSetCodeLiterals,
            ),
        ).map(valueSetCode => ({ valueSet: valueSetCode }));
        this.props.loadValueSets(valuesets);
    }
    render() {
        const viewName = this.props.viewName;
        const viewConfig = this.props.viewConfig;

        const parsedQueryString = parse(this.props.location.search);
        const parentId = parsedQueryString.parentId;

        const parentEntity = parsedQueryString.parentEntity;
        // contains caps-indicates field name has been translated DTO style. Must translate back to index into viewConfig.
        // const parentEntity = parentRefField.replace(/([A-Z])/g, str => `-${str.toLowerCase()}`);

        const view = getView(viewConfig, viewName);

        const entityName = this.props.resource;

        let redirect = this.props.redirect;

        if (parentId && redirect !== false) {
            // redirect to parent entity
            redirect = `/${parentEntity}/${parentId}${
                allowsEdit(getAccessLevelForEntity(viewConfig, parentEntity)) ? '' : '/show'
            }`;
        } else if (redirect && !allowsEdit(getAccessLevelForEntity(viewConfig, entityName))) {
            // don't assign 'show' if redirect is false
            redirect = 'show';
        }
        return <Create {...this.props} redirect={redirect} view={view} />;
    }
}

const enhance = compose(
    connect(
        makeMapStateToProps,
        dispatches,
    ),
    withPopoverLock,
);

export default enhance(GenericCreate);
