import { useState, useEffect, useCallback } from 'react';
import { RemoteData, initial, success, failure, pending } from '@devexperts/remote-data-ts';
import { AjaxError, ajax } from 'rxjs/ajax';
import { EntityBase, getUrl, getHeaders } from 'sideEffect/services';

interface Entity extends EntityBase {
    title?: string;
}
interface RecentlyVisitedProps {
    entityType: string;
    maxResults?: number;
    renderLoading: () => JSX.Element;
    renderFailure: (error: AjaxError, retry: () => void) => JSX.Element;
    renderSuccess: (data: Entity[], refresh: () => void) => JSX.Element;
}
type RetryState = 'none' | 'retry';
const RecentlyVisited = ({
    entityType,
    maxResults = 5,
    renderFailure,
    renderLoading,
    renderSuccess,
}: RecentlyVisitedProps) => {
    const [state, setState] = useState<RemoteData<AjaxError, Entity[]>>(initial);
    const [retryState, setRetryState] = useState<RetryState>('none');
    const retry = useCallback(() => {
        setState(pending);
        setRetryState('retry');
    }, [setRetryState]);
    useEffect(() => {
        if (!entityType) {
            return;
        }
        const $ajax = ajax.getJSON<Entity[]>(
            getUrl(`api/visited-entities?entityType=${entityType}&maxResults=${maxResults}`),
            getHeaders(),
        );
        const subscription = $ajax.subscribe(
            response => {
                if (retryState === 'retry') {
                    setRetryState('none');
                }
                setState(success(response));
            },
            (error: AjaxError) => {
                console.error(error);
                if (retryState === 'retry') {
                    setRetryState('none');
                }
                setState(failure(error));
            },
        );
        return () => {
            if (!subscription.closed) {
                subscription.unsubscribe();
            }
        };
    }, [entityType, maxResults, retryState]);
    return state.foldL(
        renderLoading,
        renderLoading,
        error => renderFailure(error, retry),
        recentlyVisitedEntities => renderSuccess(recentlyVisitedEntities, retry),
    );
};
export default RecentlyVisited;
