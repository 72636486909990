import { RootAction } from 'actions/rootAction';
import { getType } from 'typesafe-actions';
import { viewItemFilterExpressionsGenerated } from './actions';

export interface ViewItemFilterExpressionsGenerated {
    [viewName: string]: {
        [fieldName: string]: {
            valuesetFieldsRequired: {
                [field: string]: string;
            };
            expression: string;
            fieldName: string;
            searchEntity: string;
            valuesetLiterals: string[];
            fieldsRequired: string[];
        };
    };
}
export default (
    previousState: ViewItemFilterExpressionsGenerated = {},
    action: RootAction,
): ViewItemFilterExpressionsGenerated => {
    switch (action.type) {
        case getType(viewItemFilterExpressionsGenerated):
            return action.payload;
        default:
            return previousState;
    }
};
