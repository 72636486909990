import React from 'react';
import { Button, MenuItem } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import DuplicateIcon from '@material-ui/icons/ControlPointDuplicate';
import PromoteIcon from '@material-ui/icons/ArrowDropUp';
import DemoteIcon from '@material-ui/icons/ArrowDropDown';
import { Subtract } from 'utility-types';
import RGrid from '../../../../components/generics/fields/display/RGrid';
import Labeled from '../../../../components/generics/utils/Labeled';
import deepClone from 'clone';
import { FormFieldUnion } from 'fieldFactory/translation/fromFlowable/types';
import { formContext } from 'bpm/components/TaskDetail/TaskForm/FormContext';
import Hidden from 'components/generics/form/HiddenField';
import { evaluateContext2 } from 'expressions/CachingEvaluator/FormContextEvaluator';
import clone from 'clone';

export interface TableRowContext {
    // current row
    row: number;
    // current table
    tableId: string;
    // current table path (all parent tables, including current table)
    tableIdPath: string[];
    // row index path for all parent tables, including 'current row'
    tableRowIndexes: number[];
    columns: FormFieldUnion[];
    // e.g. tableId[2].tableId2[0]
    rowGetExp: string;
}
export const getTableRowContext = (c: TableRowContext, fc: ReturnType<typeof evaluateContext2>) => {
    const paths = c.tableIdPath.map((e, i) => {
        return [e, c.tableRowIndexes[i]] as const;
    });
    const currentTableRowContext = paths.reduce((prev, [tblId, tblRowIx]) => {
        return prev.tableRowContexts[tblId][tblRowIx];
    }, fc);
    // sometimes returns undefined
    // https://strategicsolutionsgroup.atlassian.net/browse/FISH-2971
    return currentTableRowContext;
};
export const tableRowContext = React.createContext<TableRowContext | null>(null);

type Input = any; // tslint:disable-line
type Meta = any; // tslint:disable-line

/*
    we don't want to push every single change up the component tree and into reduxForm.
    As the Table grows larger, this will result in progressively worse performance.
    Instead, buffer changes from onChange, and propogate the buffed changes when onBlur is called.
*/
interface BufferChangesProps {
    error?: string[] | null;
    // ref: string;
    id: string;
    style: {};
    defaultToggled: boolean;
    label: string;
    disabled: boolean;
    onChange: (eventOrValue: unknown) => void;
    value: unknown;
    render: (
        args: Subtract<BufferChangesProps, Pick<BufferChangesProps, 'render' | 'error' | 'onChange'>> & {
            meta: Meta;
            input: Input;
        },
    ) => JSX.Element;
}
interface BufferChangesState {
    buffer: unknown;
}
class BufferChanges extends React.Component<BufferChangesProps, BufferChangesState> {
    constructor(props: BufferChangesProps) {
        super(props);
        this.state = {
            buffer: props.value || '',
        };
    }
    componentWillReceiveProps(nextProps: BufferChangesProps) {
        if (nextProps.value !== this.props.value) {
            this.setState({ buffer: nextProps.value });
        }
    }
    onBlur = maybeHasValue => {
        const { onChange: propagateChange } = this.props;
        // if a value is provided, propagate,
        // othewise, use the buffer
        if (typeof maybeHasValue === 'undefined') {
            propagateChange(this.state.buffer);
        } else {
            propagateChange(maybeHasValue);
        }
    };
    onChange = eventOrValue => {
        this.setState({
            buffer: eventOrValue && eventOrValue.target ? eventOrValue.target.value : eventOrValue,
        });
    };
    render() {
        const { render, onChange, error, children, ...rest } = this.props;
        return render({
            ...rest,
            input: {
                onFocus: () => null,
                onBlur: this.onBlur,
                value: this.state.buffer,
                onChange: this.onChange,
            },
            meta: {
                touched: !!error,
                error,
            },
        });
    }
}

type SelectOption = string | { key: string; value: unknown };
interface BaseFieldSpec {
    title: string;
    fieldName: string;
    readOnly: boolean;
    defaultValue?: unknown;
    isReadOnly?: (rowData: {}) => boolean;
    width?: string | number;
    span: number;
}
interface TextFieldSpec extends BaseFieldSpec {
    inputType: 'TextField';
}
interface SelectFieldSpec extends BaseFieldSpec {
    inputType: 'SelectField';
    selectOptions: SelectOption[];
}
interface ToggleFieldSpec extends BaseFieldSpec {
    inputType: 'Toggle';
}
interface CustomFieldSpec extends BaseFieldSpec {
    inputType: React.ComponentType<{}> | React.ReactElement<{}>;
}
type FieldSpec = TextFieldSpec | SelectFieldSpec | ToggleFieldSpec | CustomFieldSpec;

const isCustomFieldSpec = (fieldSpec: FieldSpec): fieldSpec is CustomFieldSpec =>
    typeof fieldSpec.inputType !== 'string';
interface MuiEditableTableProps {
    allowRowAddDelete: boolean;
    disabled: boolean;
    columns: FormFieldUnion[];
    containerStyle?: {};
    rowData: {}[];
    colSpec: FieldSpec[];
    reorderable?: boolean;
    onChange: (eventOrValue: unknown) => void;
    errorTable?: {
        [row: string]: {
            [field: string]: string[];
        };
    };
    label: string;
    source: string;
}
interface MuiEditableState {
    key: number;
    containerStyle: {};
    reorderable: boolean;
}
class MuiEditableTable extends React.Component<MuiEditableTableProps, MuiEditableState> {
    constructor(props: MuiEditableTableProps) {
        super(props);

        this.state = {
            containerStyle: props.containerStyle || {},
            reorderable: props.reorderable || false,
            key: 1,
        };

        this.onFieldChange = this.onFieldChange.bind(this);
        this.onAddRow = this.onAddRow.bind(this);
        this.onDeleteRow = this.onDeleteRow.bind(this);
        this.onReorderRow = this.onReorderRow.bind(this);
    }

    onAddRow() {
        const self = this;
        return function() {
            let newRow = {};
            self.props.colSpec.map(column => (newRow[column.fieldName] = column.defaultValue || ''));

            self.props.onChange([...self.props.rowData, newRow]);
        };
    }

    onDeleteRow(rowId: number) {
        const self = this;
        return function() {
            const tempDataRow = [...self.props.rowData];

            tempDataRow.splice(rowId, 1);
            self.props.onChange(tempDataRow);
        };
    }

    onDuplicateRow(rowId: number) {
        const self = this;
        return function() {
            self.props.onChange([
                ...self.props.rowData.slice(0, rowId),
                clone(self.props.rowData[rowId]),
                ...self.props.rowData.slice(rowId),
            ]);
        };
    }

    onReorderRow(rowId: number, direction: -1 | 1) {
        const self = this;
        return function() {
            const tempDataRow = [...self.props.rowData];

            const oldIndex = rowId;
            const newIndex = rowId + direction;

            tempDataRow.splice(newIndex, 0, tempDataRow.splice(oldIndex, 1)[0]);
            self.props.onChange(tempDataRow);
            self.setState(state => ({ ...state, key: state.key + 1 }));
        };
    }

    onFieldChange(rowId: number, fieldName: string, value?: unknown) {
        const self = this;
        const tempDataRow = deepClone(self.props.rowData);

        tempDataRow[rowId][fieldName] = value;
        self.props.onChange(tempDataRow);
    }

    iconButton(
        rowKey: number | string,
        action: string,
        clickEvent: (eventOrValue: unknown) => void,
        muiIcon: JSX.Element,
    ) {
        return (
            <div className="cell action" key={`action${action}${rowKey}`} style={{ width: '45px', display: 'inline' }}>
                <Button
                    aria-label={action}
                    className={`action-button ${action}-row-button${rowKey}`}
                    color="primary"
                    onClick={clickEvent}
                    style={{ minWidth: '45px' }}
                >
                    {muiIcon}
                </Button>
            </div>
        );
    }

    createSelectOption(option: SelectOption) {
        const key = typeof option !== 'string' && option.key ? option.key : option;
        const value = typeof option !== 'string' && option.value ? option.value : option;

        return (
            <MenuItem value={value as any} key={key as any}>
                {value as any}
            </MenuItem>
        ); // tslint:disable-line
    }
    renderRowActions() {
        const { label, disabled, allowRowAddDelete } = this.props;
        const rowActions = !disabled && allowRowAddDelete;
        return (
            rowActions && (
                <div className={'row-cell header-cell action'} style={{ width: '100%' }}>
                    {this.iconButton('', `add ${label}`, this.onAddRow(), <AddIcon />)}
                </div>
            )
        );
    }
    renderHeader() {
        const headerRowStyle = {
            width: '100%',
            display: 'flex',
            flexFlow: 'row nowrap',
            border: '0',
            color: 'rgb(158, 158, 158)',
            fontSize: '12px',
            borderBottom: '1px solid #ccc',
        };
        return (
            <div className="mui-editable-table-row header-row" style={{ ...headerRowStyle }}>
                {/*
                    can add a header here and if we want a 'table' style.
                    Would require NOT using span / Grid (no wrapping)
                    setting labels in the generated colSpec to '', and providing a width
                disabled &&
                    this.state.colSpec.map((col) => (
                    <div
                        className={`row-cell header-cell ${col.fieldName}`}
                        key={col.fieldName}
                        style={{ width: col.width }}
                    >
                        {col.title}
                    </div>
                ))
                    */}
            </div>
        );
    }

    renderRow(dataRow: {}, index: number) {
        const { disabled } = this.props;
        const dataRowStyle = {
            width: '100%',
            display: 'flex',
            // flexFlow: 'row nowrap',
            justifyContent: 'space-between',
            border: '0',
            minHeight: '70px',
            borderBottom: '1px solid rgb(224, 224, 224)',
            flexGrow: 1,
        };

        return (
            <tableRowContext.Consumer>
                {c => {
                    const tableRowContextValue = {
                        row: index,
                        tableId: this.props.source,
                        columns: this.props.columns,
                        tableIdPath: c ? [...c.tableIdPath, this.props.source] : [this.props.source],
                        tableRowIndexes: c ? [...c.tableRowIndexes, index] : [index],
                        rowGetExp: c
                            ? `${c.rowGetExp}.${this.props.source}[${index}]`
                            : `${this.props.source}[${index}]`,
                    };
                    return (
                        <formContext.Consumer>
                            {fc => {
                                return (
                                    <tableRowContext.Provider key={index} value={tableRowContextValue}>
                                        <div className="mui-editable-table-row" key={index} style={dataRowStyle}>
                                            <RGrid
                                                smSize={null}
                                                xsSize={12}
                                                fields={this.props.colSpec.map((col, i) => {
                                                    const currentRowContext = getTableRowContext(
                                                        tableRowContextValue,
                                                        fc,
                                                    );
                                                    const isHidden =
                                                        // sometimes this is undefined,
                                                        // https://strategicsolutionsgroup.atlassian.net/browse/FISH-2971
                                                        // so short circuit.
                                                        currentRowContext &&
                                                        currentRowContext.hiddenFields[col.fieldName];
                                                    let el = (
                                                        <div
                                                            className={`cell ${col.fieldName}`}
                                                            key={col.fieldName + index}
                                                            // style={{ minWidth: `calc(${100.0 /
                                                            // (this.state.colSpec.length)}%)`, width: col.width }}
                                                        >
                                                            {this.renderInputField(col, index, dataRow)}
                                                        </div>
                                                    );
                                                    if (isHidden) {
                                                        el = <Hidden dontShowCol={true}>{el}</Hidden>;
                                                    }
                                                    return React.cloneElement(el, { span: col.span, row: 1, col: i });
                                                })}
                                            />

                                            {!disabled && (
                                                <span style={{ height: '70px' }}>{this.renderRowButtons(index)}</span>
                                            )}
                                        </div>
                                    </tableRowContext.Provider>
                                );
                            }}
                        </formContext.Consumer>
                    );
                }}
            </tableRowContext.Consumer>
        );
    }

    renderInputField(column: FieldSpec, index: number, rowData: {}) {
        if (column.isReadOnly && column.isReadOnly(rowData)) {
            return <div style={{ width: column.width }} />;
        }
        if (React.isValidElement(column.inputType) && isCustomFieldSpec(column)) {
            const CustomComponent: any = column.inputType; // tslint:disable-line
            const renderer =
                typeof CustomComponent.type === 'function'
                    ? props => React.cloneElement(CustomComponent, props) // React Element
                    : props => <CustomComponent {...props} />; // React Component
            return (
                <div style={{ paddingRight: '1em' }}>
                    <BufferChanges
                        error={
                            this.props.errorTable &&
                            this.props.errorTable[index] &&
                            this.props.errorTable[index][column.fieldName]
                        }
                        // ref={column.fieldName + index}
                        id={column.fieldName + index}
                        style={{ width: column.width }}
                        defaultToggled={column.fieldName in rowData ? rowData[column.fieldName] : false}
                        label={column.title}
                        disabled={column.readOnly || CustomComponent.props.disabled}
                        onChange={(eventOrValue?: Event | any) =>
                            this.onFieldChange(
                                // tslint:disable-line
                                index,
                                column.fieldName,
                                eventOrValue && eventOrValue.target ? eventOrValue.target.value : eventOrValue,
                            )
                        }
                        value={rowData[column.fieldName] || ''}
                        render={renderer}
                    />
                </div>
            );
        }
        throw new Error(`Input field type ${column.inputType} not supported`);
    }

    renderRowButtons(index: number) {
        let buttons = this.props.allowRowAddDelete
            ? [
                  this.iconButton(index, 'delete', this.onDeleteRow(index), <DeleteIcon />),
                  this.iconButton(index, 'duplicate', this.onDuplicateRow(index), <DuplicateIcon />),
              ]
            : [];

        if (this.state.reorderable) {
            if (index < this.props.rowData.length - 1 && this.props.rowData.length > 1) {
                buttons.push(this.iconButton(index, 'demote', this.onReorderRow(index, +1), <DemoteIcon />));
            }
            if (index > 0) {
                buttons.push(this.iconButton(index, 'promote', this.onReorderRow(index, -1), <PromoteIcon />));
            }
        }

        return (
            <div style={{ position: 'relative', height: '100%', minWidth: '156px' }}>
                <div style={{ position: 'absolute', bottom: 0, left: 0 }}>{buttons}</div>
            </div>
        );
    }

    render() {
        const editableTableStyle = {
            display: 'flex',
            flexFlow: 'column nowrap',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontFamily: 'Roboto, sans-serif',
        };

        return (
            <Labeled label={this.props.label} source={this.props.source} fullWidth={true} key={this.state.key}>
                <div aria-label={this.props.label} className="container" style={this.state.containerStyle}>
                    <div className="mui-editable-table" style={editableTableStyle}>
                        {this.renderHeader()}

                        {this.props.rowData && this.props.rowData.map((dataRow, i) => this.renderRow(dataRow, i))}
                        {this.renderRowActions()}
                        <input
                            type="hidden"
                            id="mui-editable-table-count"
                            // ref="mui-editable-table-count" // tslint:disable-line
                            value={this.props.rowData ? this.props.rowData.length : 0}
                            readOnly={true}
                        />
                    </div>
                </div>
            </Labeled>
        );
    }
}

export default MuiEditableTable;
