import React from 'react';
import { connect, useSelector } from 'react-redux';
import { withWidth, Typography } from '@material-ui/core';
import compose from 'recompose/compose';
import { stringify } from 'query-string';
import { adjustProps } from '../../../hoc/special/ReferenceManyFieldProvider';
import PopoverCreateButton from '../../../popovers/PopoverCreateButton';
import {
    getDefaultSort,
    getAccessLevelForEntity,
    allowsCreate,
    getAccessLevelForEntityField,
    getLongestRefonePathInPath,
    getPathBackFromFieldPath,
    getRefEntityName,
    getAllPrefilters,
} from '../../../../components/generics/utils/viewConfigUtils';
import { SORT_DESC } from 'components/generics/genericList/queryReducer';
import { GenericListWithPopovers } from 'components/generics/genericList';
import { Sort } from 'components/generics/genericList/List';
import { crudUpdate as crudUpdateAction } from 'sideEffect/crud/update/actions';
import { crudGetList as crudGetListAction } from 'sideEffect/crud/getList/actions';
import { getCustomViewName } from 'components/generics/utils/viewConfigUtils';
import { REF_MANY_PER_PAGE } from 'config';
import { renderTableHead } from 'components/generics/genericList/renderList';
import RaPagination from 'components/generics/genericList/RaPagination';
import { Table } from '@material-ui/core';
import { RootState } from 'reducers/rootReducer';
import { withRefreshContext } from 'components/generics/form/refreshContext';
import { useHideCreateButton } from 'components/generics/genericList/ListActions';
import { activeTabContext } from 'components/generics/form/TabbableForm';
import { formContext as entityFormContext } from 'components/generics/form/EntityFormContext';
import { FieldSubscriberWithDelta } from 'fieldFactory/display/experimental/FieldSubscriber';
import memoizeOne from 'memoize-one';

const DEFAULT_SORT = {
    field: 'id',
    order: SORT_DESC,
};

interface RefManyProps {
    includeTopCreateButton?: boolean;
    openTo?: 'show' | 'edit';
    resourceBasePath: string;
    parentFieldInChild: string;
    record: {
        id: string;
        entityType: string;
    };
    resource: string;
    config: string;
    crudGetList: typeof crudGetListAction;

    parentEntityName: string;
    parentId: string;
    label: string | JSX.Element | null;
    displayOnly: boolean;
    source: string;
    referenceFieldsShouldFetchInitialData: boolean;
    embeddedInFormId: string;
    viewName: string;
    hasCreate: boolean;
    hasEdit?: boolean;
    noClick?: boolean;
    disabled?: boolean;
    ariaInputProps?: {};
    refresh?: () => void;
    hideCreateButton: boolean;
}
const makeMapStateToProps = () => {
    return (state: RootState, props: RefManyProps) => {
        return {
            viewConfig: state.viewConfig,
            printMode: state.printMode,
        };
    };
};
interface RefManyComponentProps extends RefManyProps, ReturnType<ReturnType<typeof makeMapStateToProps>> {}
interface RefManyComponentState {
    location: {
        pathname: string;
        search: string;
    };
    key: number;
}
class RefManyComponent extends React.Component<RefManyComponentProps, RefManyComponentState> {
    _getLongestRefoneChain = memoizeOne(path =>
        getLongestRefonePathInPath(this.props.viewConfig, this.props.parentEntityName, path),
    );
    static defaultProps = {
        displayOnly: false,
        widthStyles: {},
        record: undefined,
        viewConfig: undefined,
        label: 'Show All',
        resourceBasePath: '',
        parentEntityName: '',
        parentFieldInChild: '',
        parentId: '',
    };
    constructor(props: RefManyComponentProps) {
        super(props);
        this.state = {
            location: {
                pathname: this.props.resourceBasePath,
                // search: `?${this.props.parentFieldInChild}%22%3A%22${this.props.record.id}%22%7D`,
                search: `INITIAL`,
            },
            key: 0,
        };
    }
    getDefaultSort = (): Sort => getDefaultSort(this.props.viewConfig, this.getViewName('LIST')) || DEFAULT_SORT;
    getPermanentFilterKey = () => {
        const { parentFieldInChild } = this.props;
        return parentFieldInChild;
    };
    getPermanentFilter = () => ({
        [this.getPermanentFilterKey()]: this.props.record.id,
    });
    getViewName = (viewType: 'LIST' | 'CREATE', resource = this.props.resource, config = this.props.config) =>
        getCustomViewName(viewType)(resource, this.props.viewConfig, config);

    refreshList = () => {
        this.setState(
            state => ({ ...state, key: state.key + 1 }),
            () => {
                if (this.props.refresh) {
                    this.props.refresh();
                }
            },
        );
    };
    getBackrefCreateButtonProps = () => {
        const { parentFieldInChild, parentEntityName, parentId } = this.props;
        return parentFieldInChild && parentFieldInChild.slice(0, -1 * '.id'.length).includes('.')
            ? {} /* Here we will have the 'getHardReference' function '*/
            : {
                  parentEntityName,
                  parentFieldInChild: parentFieldInChild && parentFieldInChild.slice(0, -1 * '.id'.length) + 'Id',
                  parentId,
              };
    };
    getLongestRefOneChain = (pfc = this.props.source) => this._getLongestRefoneChain(pfc);
    renderWithBackrefsToParent = (
        render: (
            args: null | {
                parentEntityName?: string;
                parentId?: string;
                parentFieldInChild?: string;
            },
        ) => JSX.Element | null,
        endWith: '.id' | 'Id' = 'Id', // Id is necessary to pass to the PopoverCreateButton so it sets the data on create
        // .id matches the way our list references are set, so use that to create List props

        // linkedEntityFormat describes how we should name linked-entity fields in "parentFieldInChild" field-path
        // linked<entityType> is used for query parameters,
        // e.g. ?linkedStaff.userId=<foo>&...
        // linkedEntity is used to set the reference on Create.
        // e.g. { "linkedEntityId": "<foo>"}
        linkedEntityFormat: 'linkedEntity' | 'linked<entityType>',
    ) => {
        const { parentEntityName, parentId } = this.props;

        return (
            <entityFormContext.Consumer>
                {efc => {
                    /*
                        In case we want to short circuit here
                        if (efc === defaultEntityFormContext) {
                            ...
                        }
                    */
                    const sourceUntilLastReachableValue = this.getLongestRefOneChain();
                    return (
                        <FieldSubscriberWithDelta
                            id={parentId}
                            source={sourceUntilLastReachableValue ? `${sourceUntilLastReachableValue}.id` : 'id'}
                            resource={parentEntityName}
                            delta={efc.dirtyValues}
                            renderDisplayField={({ value }) => {
                                if (value) {
                                    const newParentEntityName = sourceUntilLastReachableValue
                                        ? getRefEntityName(
                                              this.props.viewConfig,
                                              parentEntityName,
                                              sourceUntilLastReachableValue,
                                              'TRAVERSE_PATH',
                                          )
                                        : parentEntityName;
                                    return render({
                                        parentEntityName: newParentEntityName,
                                        parentId: value,
                                        parentFieldInChild:
                                            getPathBackFromFieldPath(
                                                this.props.viewConfig,
                                                newParentEntityName,
                                                (() => {
                                                    if (!sourceUntilLastReachableValue) {
                                                        return this.props.source;
                                                    }
                                                    if (!this.props.source.startsWith(sourceUntilLastReachableValue)) {
                                                        throw new Error(
                                                            `source "${this.props.source}" doesnt start with sourceUntilLastReachableValue "${sourceUntilLastReachableValue}"`,
                                                        );
                                                    }
                                                    const newFieldPointedToInChild = this.props.source.slice(
                                                        sourceUntilLastReachableValue.length,
                                                    );
                                                    return newFieldPointedToInChild.startsWith('.')
                                                        ? newFieldPointedToInChild.slice(1)
                                                        : newFieldPointedToInChild;
                                                })(),
                                                linkedEntityFormat,
                                            ) + endWith,
                                    });
                                }
                                return render(null);
                            }}
                        />
                    );
                }}
            </entityFormContext.Consumer>
        );
    };
    renderCreateButton = () => {
        const { resource } = this.props;
        const renderCreateButton = (options?: {} | null) => (
            <PopoverCreateButton
                resource={resource}
                resourceBasePath={`/${resource}`}
                label={'Create'}
                onCreateCb={this.refreshList}
                viewName={this.getViewName('CREATE')}
                {...options}
            />
        );
        return this.renderWithBackrefsToParent(renderCreateButton, 'Id', 'linkedEntity');
    };
    getLocation = (loc: { pathname: string; search: string }, parentFieldInChild?: string, parentId?: string) => {
        if (loc.search === 'INITIAL') {
            return {
                ...loc,
                search: `?${stringify({
                    filter: JSON.stringify({
                        [parentFieldInChild]: parentId,
                    }),
                })}`,
            };
        }
        return loc;
    };
    render() {
        const {
            parentEntityName,
            parentId,
            parentFieldInChild: _parentFieldInChild,
            displayOnly,
            source,
            viewConfig,
            referenceFieldsShouldFetchInitialData,
            embeddedInFormId,
            resource,
            hasCreate,
            printMode,
            config,
            disabled,
            hideCreateButton,
            ariaInputProps,
            noClick,
            openTo,
        } = this.props;
        const parentFieldInChild =
            source === 'revisions' && _parentFieldInChild && _parentFieldInChild.endsWith('.id')
                ? _parentFieldInChild.slice(0, -3) + 'Id' // revisions MUST use Id and not .id
                : _parentFieldInChild;
        const { key } = this.state;
        const accessLevel = getAccessLevelForEntity(viewConfig, resource);
        return this.renderWithBackrefsToParent(
            backrefs => {
                if (!backrefs) {
                    // We may want to expose configuration to only render null here.
                    return (
                        <Typography variant="h6" component="div">
                            No{' '}
                            {(() => {
                                try {
                                    return (
                                        viewConfig.entities[
                                            getRefEntityName(
                                                viewConfig,
                                                parentEntityName,
                                                this.getLongestRefOneChain() || this.props.source,
                                                'TRAVERSE_PATH',
                                            )
                                        ].displayName || resource
                                    );
                                } catch {
                                    return resource;
                                }
                            })()}{' '}
                            to get {this.props.label} Data
                        </Typography>
                    );
                }
                const { parentId: dynamic_parentId, parentFieldInChild: dynamic_parentFieldInChild } = backrefs;
                const accessLevelAsField = parentEntityName
                    ? getAccessLevelForEntityField(viewConfig, parentEntityName, source, 'TRAVERSE_PATH')
                    : accessLevel;
                const createAllowed = allowsCreate(Math.min(accessLevel, accessLevelAsField));
                const createBtn = this.renderCreateButton();
                const showCreate = hasCreate && createAllowed && !hideCreateButton && !disabled;
                console.log(`${dynamic_parentFieldInChild} !== ${parentFieldInChild}`);
                return (
                    <GenericListWithPopovers
                        openTo={openTo}
                        noClick={noClick}
                        editViewName={
                            // prevent edit button, otherwise default
                            this.props.hasEdit === false ? -1 : undefined
                        }
                        fetchOnMount={
                            key !== 0 ||
                            printMode ||
                            dynamic_parentFieldInChild !== parentFieldInChild ||
                            // important this is last - if undefined, we do default behavior
                            // (we don't want false if this is undefined)
                            referenceFieldsShouldFetchInitialData
                        }
                        showFilters={false}
                        noRecentlyVisited={true}
                        key={`${key}:${dynamic_parentId} ${printMode ? 'printMode' : ''}`}
                        useCard={false}
                        customTitleElement={
                            this.props.label !== '_NONE_' ? (
                                <Typography
                                    variant="h6"
                                    style={
                                        {
                                            /* fontWeight: 'bold' */
                                        }
                                    }
                                    component="div"
                                >
                                    {this.props.label}
                                </Typography>
                            ) : (
                                <div />
                            )
                        }
                        perPage={printMode ? '100' : `${REF_MANY_PER_PAGE}`}
                        actions={
                            this.props.includeTopCreateButton ? (
                                <span style={{ flexBasis: '100%' }}>{this.renderCreateButton()}</span>
                            ) : null
                        }
                        referencedFromEntity={source !== 'revisions' ? !!parentEntityName : false}
                        referencedByField={source !== 'revisions' ? dynamic_parentFieldInChild : null}
                        formId={`filterForm-${resource}-${this.props.record && this.props.record.id}`}
                        displayRowEditButton={!displayOnly}
                        canSelectRows={displayOnly}
                        hasCreate={hasCreate || !displayOnly}
                        multiSelectable={false}
                        updateUrlFromFilter={false}
                        embeddedInFormId={embeddedInFormId}
                        createRedirectQueryString={`?parentEntity=${parentEntityName}&parentField=${parentFieldInChild}&parentId=${parentId}`}
                        viewName={this.getViewName('LIST')}
                        fakePush={location => {
                            this.setState(state => ({ ...state, location }));
                        }}
                        filter={{
                            [dynamic_parentFieldInChild]: dynamic_parentId,
                            ...getAllPrefilters(viewConfig, resource, this.getViewName('LIST')),
                        }}
                        location={this.getLocation(this.state.location, dynamic_parentFieldInChild, dynamic_parentId)}
                        resource={resource}
                        reference={resource}
                        showCreate={false}
                        renderList={
                            ariaInputProps
                                ? ({ defaultRenderer, ...r }) => {
                                      return defaultRenderer({
                                          ...r,
                                          ariaProps: { ...r.ariaProps, ...ariaInputProps },
                                      });
                                  }
                                : undefined
                        }
                        sort={this.getDefaultSort()}
                        config={config}
                        renderNoResults={({ getDefaultNoResults, ...r }) => {
                            return (
                                <React.Fragment>
                                    <Table aria-hidden={true}>
                                        {renderTableHead(
                                            r,
                                            undefined,
                                            undefined,
                                            0, // extra columns to append.
                                            'DISABLED',
                                        )}
                                    </Table>
                                    {getDefaultNoResults()}
                                    {showCreate ? createBtn : null}
                                </React.Fragment>
                            );
                        }}
                        renderPagination={r => {
                            return (
                                <div>
                                    <div style={{ float: 'left' }}>{showCreate ? createBtn : null}</div>
                                    <div style={{ float: 'right' }}>
                                        <RaPagination {...r} />
                                    </div>
                                    <div style={{ clear: 'both' }} />
                                </div>
                            );
                        }}
                    />
                );
            },
            this.props.source === 'revisions' ? 'Id' : '.id',
            'linked<entityType>',
        );
    }
}

const enhance2 = compose(
    connect(
        makeMapStateToProps,
        {
            crudUpdate: crudUpdateAction,
            crudGetList: crudGetListAction,
        },
    ),
    withRefreshContext,
    withWidth({
        initialWidth: 'md',
    }),
);
const RefMany = enhance2(RefManyComponent);

export default adjustProps()(
    ({
        reference,
        record,
        resource,
        relatedField,
        match,
        basePath,
        label,
        config,
        referenceFieldsShouldFetchInitialData,
        embeddedInFormId,
        source,
        hasCreate,
        disabled,
        hasEdit,
        ariaInputProps,
        printMode,
        noClick,
        renderLabel,
        openTo,
        includeTopCreateButton,
    }) => {
        const listViewName = useSelector((state: RootState) =>
            getCustomViewName('LIST')(reference, state.viewConfig, config),
        );
        const hideCreateButton = useHideCreateButton(listViewName);
        return (
            <activeTabContext.Consumer>
                {tabContext => {
                    // context should have default value 'true' to be safe (only hide this when we know we are in a form, AND passing false)
                    if (
                        !printMode &&
                        tabContext._type === 'NON_ACTIVE_TAB' &&
                        tabContext.status === 'NOT_INITIALIZED'
                    ) {
                        return null;
                    }
                    return (
                        <RefMany
                            includeTopCreateButton={includeTopCreateButton}
                            openTo={openTo}
                            hideCreateButton={hideCreateButton}
                            displayOnly={true}
                            hasCreate={hasCreate}
                            resourceBasePath={`/${reference}`}
                            resource={reference}
                            config={config}
                            noClick={noClick}
                            ariaInputProps={ariaInputProps}
                            label={renderLabel !== false ? label : '_NONE_'}
                            source={source}
                            hasEdit={hasEdit}
                            parentEntityName={resource}
                            parentFieldInChild={`${relatedField}.id`}
                            parentId={(record as any).id}
                            record={record}
                            basePath={basePath}
                            referenceFieldsShouldFetchInitialData={referenceFieldsShouldFetchInitialData}
                            embeddedInFormId={embeddedInFormId}
                            disabled={disabled}
                        />
                    );
                }}
            </activeTabContext.Consumer>
        );
    },
);
