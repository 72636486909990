import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../src/reducers/rootReducer';
import { TextField, InputAdornment } from '@material-ui/core';
import DebouncedTextInput from './DebouncedTextInput';
import FieldTitle from './aor/FieldTitle';
import NumberFormat from 'react-number-format';
import uniqueId from 'lodash/uniqueId';
import { WrappedFieldInputProps } from 'redux-form';
import getFormHelperTextProps from 'fieldFactory/util/getFormHelperTextProps';
import formatError from 'fieldFactory/util/formatError';

export interface CurrencyProps {
    label: String;
    input?: WrappedFieldInputProps;
    disabled?: boolean;
    meta: {
        touched: boolean;
        error: string;
    };
    className?: string;
    isRequired?: boolean;
    options?: {};
    source: string;
    resource: string;
    ariaInputProps?: {};
    renderLabel?: boolean;
    id?: string;
}
const NumberFormatCustom = props => {
    const { inputRef, onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            thousandSeparator={true}
            onValueChange={values => null}
            fixedDecimalScale={true}
            getInputRef={inputRef}
            decimalScale={2}
        />
    );
};
const makeMapStateToProps = () => {
    // create any caches/selectors in this scope
    const mapStateToProps = (state: RootState, props: CurrencyProps) => {
        return {};
    };
    return mapStateToProps;
};
interface CurrencyComponentProps extends CurrencyProps, ReturnType<ReturnType<typeof makeMapStateToProps>> {}

class CurrencyComponent extends React.Component<CurrencyComponentProps> {
    fallBackInputId = uniqueId('currency-');
    errorMessageId = uniqueId('currencyErrorMsg-');
    static defaultProps = {
        ariaInputProps: {},
        renderLabel: true,
    };
    onChange = e => {
        this.props.input.onChange(this.getValue(e));
    };
    onBlur = e => {
        this.props.input.onBlur(this.getValue(e));
    };
    getValue = e => {
        let targetValue = e.target.value;
        if (targetValue === '') {
            return null;
        }
        if (typeof targetValue === 'string' && targetValue.includes(',')) {
            targetValue = targetValue.split(',').join('');
        }
        const value = parseFloat(targetValue);
        return isNaN(value) ? null : value;
    };
    render() {
        const {
            className,
            input,
            meta,
            isRequired,
            label,
            options,
            source,
            resource,
            disabled,
            ariaInputProps,
            renderLabel,
            id,
            // classes,
        } = this.props;

        const touched = meta && meta.touched;
        const error = meta && meta.error;
        const currencyInputId = id || this.fallBackInputId;
        const inputProps = {
            'aria-errormessage': touched && meta.error ? this.errorMessageId : undefined,
        };
        return (
            <DebouncedTextInput
                input={{
                    value: input.value,
                    onBlur: this.onBlur,
                    onChange: this.onChange,
                }}
                emptyInitialValue=""
                renderInput={({ value, onChange, onBlur }) => (
                    <TextField
                        InputLabelProps={{
                            shrink: true,
                            disabled: false,
                        }}
                        {...input}
                        className={className}
                        value={value}
                        onChange={onChange}
                        margin="none"
                        onBlur={onBlur}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <p style={{ color: 'rgba(0, 0, 0, 0.87)' }}>$</p>
                                </InputAdornment>
                            ),
                            inputComponent: NumberFormatCustom,
                            id: currencyInputId,
                        }}
                        label={
                            renderLabel && (
                                <FieldTitle
                                    label={label}
                                    source={source}
                                    resource={resource}
                                    isRequired={isRequired}
                                    htmlFor={currencyInputId}
                                />
                            )
                        }
                        inputProps={{ ...ariaInputProps, ...inputProps }} // eslint-disable-line
                        error={!!(touched && error)}
                        helperText={touched && error ? `Error: ${formatError(error)}` : undefined}
                        FormHelperTextProps={getFormHelperTextProps(inputProps)}
                        disabled={disabled}
                        {...options}
                        style={{ minHeight: '15px' }}
                    />
                )}
            />
        );
    }
}

const Currency: React.ComponentType<CurrencyProps> = connect(makeMapStateToProps)(CurrencyComponent);

export default Currency;
