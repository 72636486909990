import { useMemo } from 'react';
import { createGetEntities } from 'components/generics/form/EntityFormContext/util/getEntities';
import { useSelector } from 'react-redux';

const useEntities = () => {
    const getEntities = useMemo(createGetEntities, []);
    const entities = useSelector(getEntities);
    return entities;
};

export default useEntities;
