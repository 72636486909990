import * as React from 'react';
import { SFC } from 'react';
import { connect, useDispatch } from 'react-redux';
import compose from 'recompose/compose';
import { Button, Card } from '@material-ui/core';
import CommentPanel from '../layout/CommentPanel';
import TwoPanel from '../layout/TwoPanel';
import TaskHeader from './TaskForm/TaskHeader';
import TaskAttributes from './TaskForm/TaskAttributes';
import { initialValuesSelector as taskAssignmentInitialValuesSelector } from './TaskForm/TaskAssignment';
import { taskEventCreator } from '../../actions/taskEvent';
import TaskForm from './TaskForm/TaskForm';
import withAdjustedWidth from '../layout/withAdjustedWidth';
import { RootState } from '../../../reducers/rootReducer';
import { fromNullable } from 'fp-ts/lib/Option';
import { withDateFormat } from '../../../fieldFactory/dateFormat/Broadcasts';
import FormDisplayStatus from 'remoteStatus/one/components/implementations/FormDisplayStatus';

const AdhocTaskLeftPanelComponent: React.SFC<TaskLeftPanelComponentProps> = ({
    taskId,
    task,
    isAdmin,
    completeTask,
    processId,
    currentUser,
    initialAssignee,
    renderLinkedEntity,
    formatDate,
}) => (
    <div>
        <Card style={{ padding: '1%' }}>
            <TaskHeader
                taskId={taskId}
                task={task}
                isAdmin={isAdmin}
                title={task && task.name ? `Task: ${task.name}` : ''}
                processId={processId}
            />
            <div>
                <TaskAttributes processId={processId} taskId={taskId} isAdmin={isAdmin} />
                <div style={{ padding: '16px' }}>
                    {task &&
                        (task.endDate ? (
                            <span>Task Completed on {formatDate(task.endDate)}</span>
                        ) : (
                            <Button
                                disabled={!task || initialAssignee !== currentUser}
                                variant="contained"
                                color="primary"
                                onClick={() => completeTask(taskId)}
                            >
                                Complete Task
                            </Button>
                        ))}
                </div>
            </div>
        </Card>
        {renderLinkedEntity && <div style={{ marginTop: '1em' }}>{renderLinkedEntity({})}</div>}
    </div>
);

const AdhocTaskLeftPanelComponentWithDate = withDateFormat(AdhocTaskLeftPanelComponent);

const TaskLeftPanelComponent: React.SFC<TaskLeftPanelComponentProps> = props => {
    return (
        <FormDisplayStatus
            id={props.taskId}
            resource="TaskInstance"
            showSuccessOffline={!!props.task}
            renderSuccess={() => {
                if (props.task && !props.task.formKey) {
                    return <AdhocTaskLeftPanelComponentWithDate {...props} />;
                }
                if (props.task && props.taskId && props.processId) {
                    return <TaskForm {...props} processId={props.processId} taskId={props.taskId} />;
                }
                return <div />;
            }}
            refresh={null}
        />
    );
};
interface TaskLeftPanelProps {
    taskId: string;
    processId?: string;
    forceNoLinkedEntityArea?: boolean;
    relatedEntityResource?: string;
    relatedEntityId?: string;
    renderLinkedEntity?: (options: { actions?: JSX.Element | null }) => JSX.Element | null;
}

export const getIsAdminFromProcess = (state: RootState, processId: string) => {
    return fromNullable(state.bpm.processInstances.byId[processId])
        .chain(pi => fromNullable(pi.businessKey))
        .chain(businessKey => fromNullable(state.bpm.processDefinitions.byId[businessKey]))
        .fold(false, pd => !!pd.adminUser);
};

export const getDueDateEditableFromProcess = (state: RootState, processId: string) => {
    return fromNullable(state.bpm.processInstances.byId[processId])
        .chain(pi => fromNullable(pi.businessKey))
        .chain(businessKey => fromNullable(state.bpm.processDefinitions.byId[businessKey]))
        .fold(false, pd => !!pd.dueDateEditable);
};

export const getIsAdmin = (state: RootState, taskId: string) => {
    return fromNullable(taskId)
        .map(tId => state.bpm.tasks.byId[tId])
        .chain(fromNullable)
        .chain(_task => fromNullable(_task.processInstanceId))
        .fold(false, pid => getIsAdminFromProcess(state, pid));
};

const mapStateToProps = (state: RootState, ownProps: TaskLeftPanelProps) => {
    const task = ownProps.taskId ? state.bpm.tasks.byId[ownProps.taskId] : undefined;
    return {
        task,
        initialAssignee: taskAssignmentInitialValuesSelector(state, ownProps).assignee,
        isAdmin: getIsAdmin(state, ownProps.taskId),
        currentUser: state.viewConfig && state.viewConfig.user && state.viewConfig.user.login,
    };
};
const dispatches = {
    completeTask: taskEventCreator.completeTask,
};
interface TaskLeftPanelComponentProps
    extends TaskLeftPanelProps,
        ReturnType<typeof mapStateToProps>,
        Pick<typeof dispatches, 'completeTask'> {
    formatDate: (date?: Date | string | null) => string;
}
export const TaskLeftPanel = compose(
    connect(
        mapStateToProps,
        dispatches,
    ),
    BaseComponent => (props: TaskLeftPanelComponentProps) => {
        const dispatch = useDispatch();
        React.useEffect(() => {
            dispatch(taskEventCreator.getTask(props.taskId));
        }, [props.taskId, dispatch]);
        return <BaseComponent {...props} />;
    },
)(TaskLeftPanelComponent);

const TaskView: SFC<{
    taskId: string;
    width: number;
}> = ({ taskId, width }) => (
    <TwoPanel
        width={width}
        leftPanelContent={<TaskLeftPanel taskId={taskId} />}
        rightPanelContent={<CommentPanel taskId={taskId} />}
        offCenterSplit={true}
    />
);

export default withAdjustedWidth(TaskView);
