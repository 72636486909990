import React, { useMemo, useEffect, useState, useContext } from 'react';
import { TaskForm, RootState } from 'reducers/rootReducer';
import { Card, Button, CircularProgress, Theme, CardHeader, CardContent, CardActions } from '@material-ui/core';
import EnhancedRGridWithVis from 'components/generics/form/EnhancedRGridTask';
import { formContext } from 'bpm/components/TaskDetail/TaskForm/FormContext';
import { FieldFactoryContext } from 'fieldFactory/Broadcasts';
import { push as pushAction } from 'connected-react-router';
import { DataSource } from 'fieldFactory/translation/types';
import { Mode } from 'fieldFactory/FieldFactoryProvider';
import createGetOptionsSelector from 'bpm/form-context-utils/etc/getOptionsSelector';
import { useSelector, useDispatch } from 'react-redux';
import { createGetStartFormInitialValues } from 'bpm/components/TaskDetail/TaskForm/getInitialValues';
import { reduxForm, InjectedFormProps, SubmissionError } from 'redux-form';
import Outcomes from 'bpm/components/TaskDetail/TaskForm/Outcomes';
import memoize from 'lodash/memoize';
import { ajax, AjaxError } from 'rxjs/ajax';
import { refreshJwt, getOptions, getUrl } from 'sideEffect/services';
import validate from 'bpm/components/TaskDetail/TaskForm/validate';
import { Helmet } from 'react-helmet';
import adjustValues from 'bpm/components/TaskDetail/TaskForm/adjustSubmissionValues';
import { makeStyles, createStyles } from '@material-ui/styles';
import casetivityViewContext from 'util/casetivityViewContext';
import ErrorPopup from './ValidationErrorPopup';
import BpmFormExpressionTester from 'expression-tester/bpm-form';
import useEntities from 'util/hooks/useEntities';
import useValueSets from 'util/hooks/useValueSets';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        submissionArea: {
            textAlign: 'center',
            overflowWrap: 'break-word',
        },
        pendingSpinner: {
            position: 'absolute',
            left: 0,
            top: 0,
            bottom: 0,
            marginRight: '1em',
            verticalAlign: 'middle',
        },
        buttonSpinner: {
            marginLeft: '1em',
        },
        responseError: {
            color: theme.palette.error.dark,
        },
    }),
);

export const getFields = (fieldFactory, formDefinition: TaskForm) => {
    return fieldFactory({
        dataSource: DataSource.FLOWABLE,
        mode: Mode.INPUT,
        validate: true,
        connected: true,
        options: {},
    })(
        {
            replacePeriodsInFieldName: '_',
            options: {
                fullWidth: true,
            },
            overrideFieldValueIfDisabled: true,
            shouldFetchValueset: false,
        },
        null,
        createGetOptionsSelector(formDefinition),
    )(formDefinition.fields);
};

interface FormProps {
    submissionError?: { [field: string]: string[] };
    children?: React.ReactNode;
}
const Form = reduxForm<{}, FormProps>({
    enableReinitialize: true,
    form: 'current-task-form',
    shouldWarn: ({ values, nextProps, props, initialRender }) => {
        // maybe optimize this eventually if we know what the runtime cost is.
        return true;
    },
})((props: FormProps & InjectedFormProps) => {
    const { submissionError, handleSubmit, children } = props;
    useEffect(() => {
        if (submissionError) {
            handleSubmit(() => {
                throw new SubmissionError(Object.assign({ _error: submissionError }, submissionError));
            })();
        }
    }, [submissionError, handleSubmit]);
    return <form autoComplete="off">{children}</form>;
});

type SubmissionState =
    | {
          type: 'NOT_SUBMITTING';
      }
    | {
          type: 'SUBMITTING_1';
          outcome?: string;
          outcomeDisplay?: string;
      }
    | {
          type: 'SUBMITTING_2';
          outcome?: string;
          outcomeDisplay?: string;
          data: {};
      }
    | {
          type: 'WARNING';
      }
    | {
          type: 'SUCCESS';
      }
    | {
          type: 'VALIDATION_FAILED';
          formErrors: {
              [field: string]: string[];
          };
      }
    | {
          type: 'ERROR';
          error: AjaxError;
      };

interface StartFormProps {
    formDefinition: TaskForm;
    businessKey: string;
    passThroughValues?: {};
}
interface StartFormComponentProps extends StartFormProps {}
const StartFormComponent = (props: StartFormComponentProps) => {
    const { formDefinition } = props;
    const classes = useStyles(props);
    const startFormContext = useContext(formContext);
    const dispatch = useDispatch();
    const fieldFactory = useContext(FieldFactoryContext);
    const entitiesAreLoading = useSelector((state: RootState) => state.admin.loading > 0);
    const [state, setState] = useState<SubmissionState>({ type: 'NOT_SUBMITTING' });
    const entities = useEntities();
    const viewConfig = useSelector((state: RootState) => state.viewConfig);
    const valueSets = useValueSets();
    const fields = useMemo(() => {
        return getFields(fieldFactory, formDefinition);
    }, [fieldFactory, formDefinition]);
    useEffect(() => {
        if (state.type === 'SUBMITTING_1') {
            const validateData = (includeFieldLevelErrors: boolean) =>
                validate({
                    outcome: state.outcome,
                    formDefinition,
                    entities,
                    valuesAfterExpressionsApplied: startFormContext.fieldValues,
                    visibleAndEditableFields: startFormContext.visibleAndEditableFields,
                    viewConfig,
                    ignoreFieldLevel: !includeFieldLevelErrors,
                    fields,
                    valueSets,
                });
            const allErrors = validateData(true);
            const hasErrors = Object.keys(allErrors).length > 0;
            if (hasErrors) {
                setState({ type: 'VALIDATION_FAILED', formErrors: validateData(true) });
            } else {
                const adjustedValues = adjustValues(formDefinition, startFormContext.fieldValues);
                setState({
                    type: 'SUBMITTING_2',
                    outcome: state.outcome,
                    outcomeDisplay: state.outcomeDisplay,
                    data: adjustedValues,
                });
            }
        }
    }, [
        state,
        setState,
        entities,
        valueSets,
        fields,
        startFormContext.fieldValues,
        startFormContext.visibleAndEditableFields,
        viewConfig,
        formDefinition,
    ]);
    useEffect(() => {
        if (state.type === 'SUBMITTING_2') {
            const $ajax = refreshJwt(
                ajax(
                    getOptions(getUrl('api/bpm/process-instances'), 'POST', {
                        processDefinitionKey: props.businessKey,
                        outcome: state.outcome,
                        outcomeDisplay: state.outcomeDisplay,
                        values: {
                            ...state.data,
                            ...props.passThroughValues,
                        },
                    }),
                ),
            );

            const subscription = $ajax.subscribe(
                res => {
                    if (res.status === 201) {
                        if (res.response.redirect) {
                            dispatch(pushAction(res.response.redirect));
                        } else if (viewConfig.user.login === 'anonymousUser') {
                            // assume anonymous user can only start tasks from the dashboard links,
                            // so it makes sense to redirect them back.
                            dispatch(pushAction('/'));
                        } else {
                            dispatch(pushAction(`/processes/${res.response.id}`));
                        }
                        console.log('success', res);
                        setState({ type: 'SUCCESS' });
                    } else {
                        console.log('unexpected non-201 response', res);
                        setState({ type: 'NOT_SUBMITTING' });
                    }
                },
                (error: AjaxError) => {
                    setState({ type: 'ERROR', error });
                },
            );
            return () => {
                if (!subscription.closed) {
                    subscription.unsubscribe();
                }
            };
        }
    }, [state, props.businessKey, dispatch, viewConfig.user.login, props.passThroughValues]);

    const submit = useMemo(() => {
        return memoize((outcome?: string, outcomeDisplay?: string) => {
            // function passed to submit button
            return () => {
                setState({
                    type: 'SUBMITTING_1',
                    outcome,
                    outcomeDisplay,
                });
            };
        });
    }, [setState]);
    const selectInitialValues = useMemo(createGetStartFormInitialValues, []);
    const initialValues = useSelector((state: RootState) => selectInitialValues(state, props));
    const hasOutcomes = formDefinition && formDefinition.outcomes && formDefinition.outcomes.length > 0;

    return (
        <Form
            initialValues={initialValues}
            // even if Object.keys(state.formErrors).length === 0 below, it touches all fields for us to display validations/warnings
            // so lets still mark a submissionError
            submissionError={state.type === 'VALIDATION_FAILED' ? state.formErrors : undefined}
        >
            <Card>
                <CardHeader
                    titleTypographyProps={{ component: 'h1' } as any}
                    title={<span style={{ paddingLeft: 'calc(0.5em + 4px)' }}>{formDefinition.name}</span>}
                />
                <CardContent style={{ paddingTop: 0 }}>
                    {fields && <EnhancedRGridWithVis fields={fields} formDefinition={formDefinition} />}
                </CardContent>
                <CardActions className={classes.submissionArea}>
                    {state.type === 'ERROR' && (
                        <pre className={classes.responseError}>
                            {state.error.status || 'Network Error. Check your connection and retry.'}
                            <br />
                            {state.error.response && JSON.stringify(state.error.response)}
                        </pre>
                    )}
                    <div style={{ display: 'inline-block', width: '100%' }}>
                        {hasOutcomes ? (
                            <Outcomes
                                formDefinition={formDefinition}
                                createButton={(label, outcome, forceDisabled, ButtonProps) => {
                                    return (
                                        <Button
                                            onClick={submit(outcome, label)}
                                            variant="contained"
                                            color="primary"
                                            disabled={
                                                entitiesAreLoading || forceDisabled || state.type === 'SUBMITTING_2'
                                            }
                                            {...ButtonProps}
                                        >
                                            {label}
                                            {state.type === 'SUBMITTING_2' && state.outcome === outcome && (
                                                <CircularProgress size={20} className={classes.buttonSpinner} />
                                            )}
                                        </Button>
                                    );
                                }}
                            />
                        ) : (
                            <Button
                                disabled={entitiesAreLoading || state.type === 'SUBMITTING_2'}
                                onClick={submit()}
                                variant="contained"
                                color="primary"
                            >
                                Complete
                                {state.type === 'SUBMITTING_2' && (
                                    <CircularProgress size={20} className={classes.buttonSpinner} />
                                )}
                            </Button>
                        )}
                        {entitiesAreLoading && (
                            <span style={{ width: 0, position: 'relative' }}>
                                <CircularProgress className={classes.pendingSpinner} size={20} />
                            </span>
                        )}
                    </div>
                </CardActions>
            </Card>
            {state.type === 'VALIDATION_FAILED' && (
                <ErrorPopup formErrors={state.formErrors} formDefinition={formDefinition} />
            )}
        </Form>
    );
};

const StartFormForm = (props: StartFormProps) =>
    props.formDefinition ? (
        <casetivityViewContext.Provider value="START_FORM">
            <Helmet>
                <title>{props.formDefinition.name}</title>
            </Helmet>
            <BpmFormExpressionTester contextType="start-form" formDefinition={props.formDefinition}>
                {({ formDefinition }) => {
                    return <StartFormComponent {...props} formDefinition={formDefinition} />;
                }}
            </BpmFormExpressionTester>
        </casetivityViewContext.Provider>
    ) : null;
export default StartFormForm;
