import { StartupEventType } from '../actions/StartupEvent';
import { fromNullable } from 'fp-ts/lib/Option';

export const getDefault = () => {
    const b = fromNullable(window.CASETIVITY_BASIC_INFO)
        .map(JSON.parse)
        .getOrElse({});
    return b;
};
export default function basicInfoReducer(state = getDefault(), action) {
    switch (action.type) {
        case StartupEventType.LoadedBasicInfoSuccess:
            return action.body;
        default:
            return state;
    }
}
