import clone from 'clone';
import isPojso from '../isPojo';

export function deepExtendPair(destination: {}, source: {}) {
    for (const property in source) {
        if (isPojso(source[property])) {
            if (!destination[property]) {
                destination[property] = source[property];
            } else if (isPojso(destination[property])) {
                deepExtendPair(destination[property], source[property]);
            } else {
                // conflict.
                // source[property] is a pojso but destination[property] is a truthy non-pojso
                destination[property] = source[property];
            }
        } else {
            destination[property] = source[property];
        }
    }
    return destination;
}
const deepExtend = (...args: {}[]) => {
    return args.reduce((prev, curr) => {
        const dsp = deepExtendPair(prev, clone(curr, true));
        return dsp;
    }, {});
};
export default deepExtend;
