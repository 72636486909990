import React from 'react';
import { Field } from 'redux-form';

import { required } from 'fieldFactory/util/validate';
import Labeled from 'components/generics/utils/Labeled';

const isRequired = validate => {
    if (validate === required) return true;
    if (Array.isArray(validate)) {
        return validate.includes(required);
    }
    return false;
};

export const adjustFieldName = (source, replacePeriodsInFieldName) => {
    const adjustedSource = typeof source === 'number' ? `_${source.toString()}` : source;
    return replacePeriodsInFieldName ? adjustedSource.split('.').join(replacePeriodsInFieldName) : adjustedSource;
};

const FormField = ({ input, replacePeriodsInFieldName, forceEditable, ...rest }) => {
    if (input.props.addField) {
        if (input.props.addLabel) {
            return (
                <Field
                    {...rest}
                    {...input.props}
                    disabled={forceEditable ? false : rest.disabled || input.props.disabled}
                    record={input.props.record || rest.record}
                    choices={rest.choices || input.props.choices}
                    name={adjustFieldName(input.props.source, replacePeriodsInFieldName)}
                    component={Labeled}
                    label={input.props.label}
                    isRequired={isRequired(input.props.validate)}
                >
                    {input}
                </Field>
            );
        }
        return (
            <Field
                {...rest}
                {...input.props}
                disabled={forceEditable ? false : rest.disabled || input.props.disabled}
                record={input.props.record || rest.record}
                choices={rest.choices || input.props.choices}
                label={input.props.label || ' '}
                name={adjustFieldName(input.props.source, replacePeriodsInFieldName)}
                component={input.type}
                isRequired={isRequired(input.props.validate)}
            />
        );
    }
    if (input.props.addLabel) {
        return (
            <Labeled
                {...rest}
                label={input.props.label || ' '}
                source={input.props.source}
                isRequired={isRequired(input.props.validate)}
                fullWidth
            >
                {input}
            </Labeled>
        );
    }
    return typeof input.type === 'string' ? input : React.cloneElement(input, rest);
};

export default FormField;
