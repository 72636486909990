import { localsync } from 'localsync';
import { storeRegistry } from './storeRegistry';

export const syncedActionsController = localsync({ channel: 'action-sync-channel' })(
    action => action,
    (current, previous) => {
        storeRegistry['store'].dispatch(current);
    },
);

syncedActionsController.start();
