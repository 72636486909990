import React from 'react';
import { Bar, Line, Pie } from 'react-chartjs-2';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { ChartData, ChartOptions } from 'chart.js';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
    createStyles,
    WithStyles,
    withStyles,
} from '@material-ui/core';
import { getRowsToFlag, getDrillDown } from 'dashboard/utils/ReportWidgetUtil';
import { ReportDefinition } from 'dashboard2/dashboard-config/types';
import { push as pushAction } from 'connected-react-router';
import { RootState } from 'reducers/rootReducer';
import { fromNullable } from 'fp-ts/lib/Option';
import { TabNavRowButton } from 'components/generics/genericList/renderList';
import FalseIcon from '@material-ui/icons/Clear';
import TrueIcon from '@material-ui/icons/Done';

const sequentialColors = [
    '#5899DA',
    '#E8743B',
    '#19A979',
    '#ED4A7B',
    '#945ECF',
    '#13A4B4',
    '#525DF4',
    '#BF399E',
    '#6C8893',
    '#EE6868',
    '#2F6497',
    '#5BD1D7',
    '#348498',
    '#004D61',
    '#FF502F',
    '#FAB95B',
    '#71A0A5',
    '#665C84',
    '#212121',
    '#FCF9EC',
    '#B0F4E6',
    '#67EACA',
    '#12D3CF',
    '#BEEEF7',
    '#6FC2D0',
    '#373A6D',
    '#373A6D',
    '#FF8246',
];

const getRandomColor = () => {
    const letters = '0123456789ABCDEF'.split('');
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

const composeDataForChart = (rawData: {}[], definition: ReportDefinition): ChartData => {
    const labels = rawData.map(obj => obj[Object.keys(obj)[0]]);
    const data = rawData.map(obj => obj[Object.keys(obj)[1]]);
    const { label, backgroundColor, borderColor, borderWidth, hoverBackgroundColor, hoverBorderColor } = definition;
    return {
        labels,
        datasets: [
            {
                label,
                backgroundColor,
                borderColor,
                borderWidth,
                hoverBackgroundColor,
                hoverBorderColor,
                data,
            },
        ],
    };
};

const groupBy = (list: {}[], keyGetter: (item: {}) => any, labelGetter: (item: {}) => string) => {
    const map = new Map();
    const labels = new Set();
    list.forEach(item => {
        const key = keyGetter(item).trim();
        const collection = map.get(key);
        labels.add(labelGetter(item));
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return { map, labels };
};

function composeDataForStackedChart(rawData: {}[], definition: ReportDefinition): ChartData {
    const { map, labels } = groupBy(rawData, item => item[Object.keys(item)[0]], item => item[Object.keys(item)[1]]);
    const colors =
        definition.colors !== undefined && definition.colors.length > 0 ? definition.colors : sequentialColors;
    const axisLabels = [];
    const dataMap = new Map();
    map.forEach(function(value, key) {
        axisLabels.push(key);
        const valuesMap = new Map();
        value.forEach(element => {
            const collection = valuesMap.get(element[Object.keys(element)[1]]);
            if (!collection) {
                valuesMap.set(element[Object.keys(element)[1]], [element[Object.keys(element)[2]]]);
            } else {
                collection.push(element[Object.keys(element)[2]]);
            }
        });
        labels.forEach(function(currentItem) {
            const dataCollection = dataMap.get(currentItem);
            if (!dataCollection) {
                dataMap.set(currentItem, [valuesMap.has(currentItem) ? valuesMap.get(currentItem) : 0]);
            } else {
                dataCollection.push(valuesMap.has(currentItem) ? valuesMap.get(currentItem) : 0);
            }
        });
    });
    return {
        labels: axisLabels,
        datasets: Array.from(dataMap).map(([key, value], index) => {
            const currentBarColor = index >= colors.length ? getRandomColor() : colors[index];
            return {
                label: key,
                backgroundColor: currentBarColor,
                borderColor: currentBarColor,
                borderWidth: definition.borderWidth,
                data: value,
            };
        }),
    };
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
            height: '100%',
        },
        tableHeaderCell: {
            position: 'sticky',
            backgroundColor: theme.palette.background.paper,
            zIndex: 3,
            top: 0,
        },
        flaggedTableRowRoot: {
            backgroundColor: '#ffb3b3',
            cursor: 'pointer',
        },
        flaggedTableRowHover: {
            '&:hover': {
                background: '#ff9999 !important',
            },
        },
    });

interface ContentProps {
    data: {}[];
    definition: ReportDefinition;
}
const dispatches = {
    push: pushAction,
};
type Dispatches = typeof dispatches;

const mapStateToProps = (state: RootState, props: ContentProps) => {
    return {};
};

interface ContentComponentProps
    extends Dispatches,
        ContentProps,
        ReturnType<typeof mapStateToProps>,
        WithStyles<typeof styles> {}
const ContentComponent = ({ data, definition, push, classes }: ContentComponentProps) => {
    const options: ChartOptions = { maintainAspectRatio: false };
    const startPointsAtZero: ChartOptions = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    };
    switch (definition.type) {
        case 'singleValue': {
            const value = fromNullable(data[0])
                .mapNullable(e => Object.entries(e)[0])
                .mapNullable(r => r[1])
                .getOrElse('');
            return <span>{value}</span>;
        }
        case 'bar':
            return (
                <Bar
                    data={composeDataForChart(data, definition)}
                    options={{
                        ...startPointsAtZero,
                        ...options,
                        legend: {
                            onClick: null,
                        },
                    }}
                />
            );
        case 'line':
            return <Line data={composeDataForChart(data, definition)} options={{ ...startPointsAtZero, ...options }} />;
        case 'pie':
            const dataForChart = composeDataForChart(data, definition);
            const archColors: string[] = [];
            const colors =
                definition.colors !== undefined && definition.colors.length > 0 ? definition.colors : sequentialColors;
            if (dataForChart.datasets && dataForChart.datasets[0].data) {
                dataForChart.datasets[0].data.forEach((key, index) => {
                    if (index >= colors.length) {
                        archColors.push(getRandomColor());
                    } else {
                        archColors.push(colors[index]);
                    }
                });
                dataForChart.datasets[0].backgroundColor = archColors;
                dataForChart.datasets[0].borderColor = archColors;
                dataForChart.datasets[0].hoverBackgroundColor = archColors;
                dataForChart.datasets[0].hoverBorderColor = archColors;
            }
            return <Pie data={dataForChart} options={options} />;
        case 'stackedBar':
            const optionsForStackedBarChart: ChartOptions = {
                scales: {
                    xAxes: [{ stacked: true, barThickness: 40 } as any],
                    yAxes: [
                        {
                            stacked: true,
                            ticks: {
                                beginAtZero: true,
                            },
                        },
                    ],
                },
                ...options,
            };
            return <Bar data={composeDataForStackedChart(data, definition)} options={optionsForStackedBarChart} />;
        case 'linktable':
            let intersection: string[];
            if (definition.fields) {
                // All available column fields dictated by the report query
                const allFields: string[] = Object.keys(data[0]);
                // Desired fields dictated by the 'fields' property in the config definition
                const desiredFields = definition.fields.split(',');
                // Intersection of both arrays to filter for nonexistent fields in desired
                intersection = allFields.filter(x => desiredFields.includes(x));
            } else {
                intersection = Object.keys(data[0]); // If no specified fields provide all from original report query
            }
            const rowsToFlag = getRowsToFlag(data, intersection, definition);

            const onRowClick = row => {
                if (!definition.noDrillDown) {
                    const url = getDrillDown(row, definition, data);
                    push(url);
                }
            };
            return (
                <div className={classes.root}>
                    <Table>
                        <caption className="casetivity-off-screen">{definition.reportName}</caption>
                        <TableHead>
                            {definition.wantColumnHeaders ? (
                                <TableRow>
                                    {data &&
                                        data.length > 0 &&
                                        intersection
                                            .map((columnName, i) => (
                                                <TableCell className={classes.tableHeaderCell} key={i}>
                                                    {columnName}
                                                </TableCell>
                                            ))
                                            .concat(
                                                definition.noDrillDown
                                                    ? []
                                                    : [
                                                          <TableCell className={classes.tableHeaderCell} key="go">
                                                              <span
                                                                  style={{
                                                                      position: 'absolute',
                                                                      left: '-10000px',
                                                                      top: 'auto',
                                                                      overflow: 'hidden',
                                                                  }}
                                                              >
                                                                  Row Actions
                                                              </span>
                                                          </TableCell>,
                                                      ],
                                            )}
                                </TableRow>
                            ) : null}
                        </TableHead>
                        <TableBody>
                            {data &&
                                data.length > 0 &&
                                data.map((row, index) => (
                                    <TableRow
                                        // is there a guarantee we have 'id' here?
                                        key={(row as { id: string }).id}
                                        tabIndex={-1}
                                        classes={
                                            rowsToFlag.includes(index)
                                                ? {
                                                      root: classes.flaggedTableRowRoot,
                                                      hover: classes.flaggedTableRowHover,
                                                  }
                                                : undefined
                                        }
                                        onClick={() => {
                                            onRowClick(index);
                                        }}
                                        hover={!definition.noDrillDown}
                                    >
                                        {row &&
                                            Object.keys(row).length > 0 &&
                                            intersection
                                                .map((column, i) => (
                                                    <TableCell
                                                        style={
                                                            definition.noDrillDown
                                                                ? undefined
                                                                : { paddingTop: 0, paddingBottom: 0 }
                                                        }
                                                        key={i}
                                                    >
                                                        {row[column] === true ? (
                                                            <TrueIcon />
                                                        ) : row[column] === false ? (
                                                            <FalseIcon />
                                                        ) : (
                                                            row[column]
                                                        )}
                                                    </TableCell>
                                                ))
                                                .concat(
                                                    definition.noDrillDown
                                                        ? []
                                                        : [
                                                              <TableCell
                                                                  style={{ paddingTop: 0, paddingBottom: 0 }}
                                                                  key="gonav"
                                                              >
                                                                  <TabNavRowButton
                                                                      iconButtonProps={{
                                                                          'aria-label': `Select report result row ${index +
                                                                              1}`,
                                                                      }}
                                                                  />
                                                              </TableCell>,
                                                          ],
                                                )}
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </div>
            );
        default:
            return <div />;
    }
};
const Content: React.ComponentType<ContentProps> = compose(
    connect(
        mapStateToProps,
        dispatches,
    ),
    withStyles(styles),
)(ContentComponent);

export default Content;
