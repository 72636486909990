import { ThrowReporter } from 'io-ts/lib/ThrowReporter';
import * as t from 'io-ts';

const getErrorFromConfig = <T>(ioType: t.PartialType<T>, invalidConfigurationText: string, invalidJsonText: string) => (
    config: string,
) => {
    if (!config) {
        return null;
    }
    try {
        const parsed: {} = JSON.parse(config);
        const decodeResult = ioType.decode(parsed);
        try {
            ThrowReporter.report(decodeResult);
            return null;
        } catch (e) {
            return invalidConfigurationText;
        }
    } catch (e) {
        return invalidJsonText;
    }
};

export default getErrorFromConfig;
