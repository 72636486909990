import getConfiguredValidation from './getConfiguredValidation';
import getInputTypeFromEntityField from './getTypeFromEntityField';
import * as fieldTypes from '../../fieldTypes';
import {
    getLabelForFieldExpr,
    getDescriptionForFieldExpr,
    getDataTypeForFieldExpr,
    isFieldViewField,
    isAddressVerificationField,
    isEventField,
} from '../../../components/generics/utils/viewConfigUtils';
import ViewConfig, { ViewField, EntityField } from '../../../reducers/ViewConfigType';
// import { EntityFormField } from './types';
import { FieldFromEntity as EntityOutputField, DataSource, validationFunction } from '../types';
import { Mode } from 'fieldFactory/FieldFactoryProvider';

export const mapEntityField = (
    viewConfig: ViewConfig,
    viewFieldDefinition: ViewField,
    entityFieldDefinition: EntityField | undefined,
    resource?: string,
    mode?: Mode,
    fieldInstanceIdentifier?: string,
): EntityOutputField => {
    const originalDefinition = JSON.stringify(viewFieldDefinition);
    const { widgetType, column, row, span } = viewFieldDefinition;
    if (isAddressVerificationField(viewFieldDefinition)) {
        const { label = null, config, field } = viewFieldDefinition;
        return {
            name: field || `AddressVerification:${row}:${column}:${span}`,
            label,
            type: fieldTypes.ADDRESS_VERIFICATION,
            config,
            originalDefinition,
            row,
            column,
            span,
            _dataSource: DataSource.ENTITY,
            fieldInstanceIdentifier,
        };
    } else if (isFieldViewField(viewFieldDefinition)) {
        const { searchType, field: name, entity } = viewFieldDefinition;
        const getLabel = () => {
            const entityLabel = entityFieldDefinition && entityFieldDefinition.label;
            const viewLabel =
                (viewFieldDefinition && viewFieldDefinition.label) || getLabelForFieldExpr(viewConfig, entity, name);
            return viewLabel || entityLabel;
        };
        const label = getLabel();
        const refEntityName: string | undefined = entityFieldDefinition
            ? entityFieldDefinition.relatedEntity
            : undefined;
        const validate: validationFunction[] = getConfiguredValidation(viewConfig, entity, name, widgetType);
        const dataType = getDataTypeForFieldExpr(viewConfig, entity, name);
        const description = viewFieldDefinition.description || getDescriptionForFieldExpr(viewConfig, entity, name);
        return {
            name,
            configuredEntity: entity,
            refEntityName,
            label,
            description,
            validate,
            originalDefinition,
            type: getInputTypeFromEntityField(widgetType, dataType, name, mode === 'Input'),
            column,
            row,
            span,
            searchType,
            _dataSource: DataSource.ENTITY,
            // addition of config below for the AddressValidation field is done outside of the type system rightnow.
            // This is because I don't know what widgetTypes will be involved yet.
            // But: It looks like config will be a permentant fixture
            // for editable/disabled fields, so may have to allow all fields to have access.
            config: (viewFieldDefinition as any).config, // tslint:disable-line
            fieldInstanceIdentifier,
        } as any; // tslint:disable-line
    } else if (isEventField(viewFieldDefinition)) {
        const { label = null, config } = viewFieldDefinition;
        return {
            name: viewFieldDefinition.label || `Event:${row}:${column}:${span}`,
            label,
            type: fieldTypes.EVENT,
            config,
            row,
            column,
            originalDefinition,
            span,
            _dataSource: DataSource.ENTITY,
            fieldInstanceIdentifier,
        };
    } else {
        // html expression field
        const { label = null, config: htmlConfig } = viewFieldDefinition;
        return {
            name: viewFieldDefinition.field || `expression:r${row}c${column}`,
            label,
            type: fieldTypes.HTML_EXPRESSION,
            htmlConfig,
            row,
            originalDefinition,
            column,
            span,
            _dataSource: DataSource.ENTITY,
            fieldInstanceIdentifier,
        };
    }
};

// second arg to fit standard format of fieldDefinitions + linkedEntity
export default (viewConfig: ViewConfig, resource?: string, mode?: Mode) => (
    fieldDefinitions: ViewField[] | ([string, ViewField][]),
): (EntityOutputField)[] => {
    const firstEntry = fieldDefinitions[0]; // going to use this to check what format is being passed.
    const _fieldDefinitions: [string | undefined, ViewField][] =
        firstEntry &&
        Array.isArray(firstEntry) &&
        firstEntry.length === 2 &&
        (typeof firstEntry[0] === 'string' || typeof firstEntry[0] === 'undefined')
            ? (fieldDefinitions as [string | undefined, ViewField][])
            : (fieldDefinitions as ViewField[]).map(vf => [undefined, vf] as [undefined, ViewField]);
    return _fieldDefinitions.map(([fieldInstanceIdentifier, viewField], i) => {
        try {
            return mapEntityField(
                // maps entity viewField to the standard fieldDefinition
                viewConfig,
                viewField,
                isFieldViewField(viewField) ? viewConfig.entities[viewField.entity].fields[viewField.field] : undefined,
                resource,
                mode,
                fieldInstanceIdentifier,
            );
        } catch (e) {
            console.log('e caught', e); // tslint:disable-line
            const originalDefinition = JSON.stringify(viewField);
            return {
                name: `${isFieldViewField(viewField) && viewField.field}-${i}`,
                label: `${viewField.label}-${i}`,
                type: fieldTypes.HTML_EXPRESSION as 'html-expression',
                htmlConfig: `${e.message}`, // This is not quite safe.
                row: viewField.row,
                originalDefinition,
                column: viewField.column,
                span: viewField.span,
                _dataSource: DataSource.ENTITY as DataSource.ENTITY,
                fieldInstanceIdentifier,
            };
        }
    });
};
