export interface EntityValidations {
    [resource: string]: {
        expression: string;
        message: string;
        fieldsRequired: string[];
        valuesetLiterals: string[];
        valuesetFieldsRequired: {
            [field: string]: string; // can get the valueset
        };
        // Lets not store the compiled expressions...
        // could be a lot of wasted space in memory, and they aren't serializable anyway)
        // compiledExpression: SpelNode
    }[];
}
export default (previousState: EntityValidations = {}, { type, payload }): EntityValidations => {
    if (type === 'ENTITY_VALIDATIONS_GENERATED') {
        return payload;
    }
    return previousState;
};
