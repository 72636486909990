import React from 'react';
import pure from 'recompose/pure';

interface FieldTitleProps {
    label: string;
    isRequired?: boolean;
}
export const FieldTitle: React.SFC<any> = ({ label, isRequired }: FieldTitleProps) => {
    if (!label) {
        return null;
    }
    return label.endsWith('*') ? (
        <span>
            {label.slice(0, -1)}
            <abbr title="Required">*</abbr>
        </span>
    ) : isRequired ? (
        <span>
            {label} <abbr title="Required">*</abbr>
        </span>
    ) : (
        <span>{label}</span>
    );
};

export default pure(FieldTitle);
