import React from 'react';
import { connect } from 'react-redux';
import WidgetFactory from '../service/WidgetFactory';
import { RootState } from 'reducers/rootReducer';
import { fromNullable } from 'fp-ts/lib/Option';
import { createSelector } from 'reselect';
import { DashboardGrid, LayoutItem } from 'dashboard2/dashboard-config/types';
import memoizeOne from 'memoize-one';

interface WithWidgetsProps {
    dashboardName: string;
    children: (args: {
        grid: DashboardGrid | null;
        widgetElements: null | ReturnType<typeof WidgetFactory.createAllWidgets>;
    }) => JSX.Element | null;
}
const getCurrentDashboard = <Props extends { dashboardName: string }>(state: RootState, props: Props) =>
    fromNullable(state.dashboard.dashboards.configs[props.dashboardName]).map(dc => dc.dashboardConfig);

const createGetWidgetElementsSelector = () => {
    const getLayoutObject = memoizeOne((layoutItems: LayoutItem[]) => {
        return layoutItems.reduce<{
            [i: string]: LayoutItem;
        }>((prev, curr) => {
            prev[curr.i] = curr;
            return prev;
        }, {});
    });
    return createSelector(
        (state: RootState, props: WithWidgetsProps) => getCurrentDashboard(state, props).getOrElse(null),
        dc => {
            if (dc) {
                const layout = dc.grid.layouts && dc.grid.layouts.md && getLayoutObject(dc.grid.layouts.md);
                // so we order thingss in the dom in a way that makes sense, tabbing-wise
                const widgetDefinitions = layout
                    ? Object.values(dc.widgets).sort((a, b) => {
                          const c1 = layout[a.id].y - layout[b.id].y;
                          const c2 = layout[a.id].x - layout[b.id].x;
                          return c1 === 0 ? c2 : c1;
                      })
                    : Object.values(dc.widgets);
                return WidgetFactory.createAllWidgets(widgetDefinitions);
            }
            return null;
        },
    );
};
const makeMapStateToProps = () => {
    const getWidgetElements = createGetWidgetElementsSelector();
    return (state: RootState, props: WithWidgetsProps) => {
        return {
            widgetElements: getWidgetElements(state, props),
            grid: getCurrentDashboard(state, props)
                .map(c => c.grid)
                .getOrElse(null),
        };
    };
};
interface WithWidgetsComponentProps extends WithWidgetsProps, ReturnType<ReturnType<typeof makeMapStateToProps>> {}
class WithWidgetsComponent extends React.Component<WithWidgetsComponentProps> {
    render() {
        const { children, grid, widgetElements } = this.props;
        return children({ widgetElements, grid });
    }
}

export default connect(makeMapStateToProps)(WithWidgetsComponent);
