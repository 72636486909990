import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router';
import aorLoading from './aor/loading';
import aorUi from './aor/ui';
import lists from './lists/index';
import bpmReducer, { BPMState } from '../bpm/reducer/index';
import viewConfigReducer from 'viewConfig/reducers';
import viewConfigIsLoadingReducer from 'viewConfig/reducers/isLoading';
import valueSetsReducer, { ValueSets } from './valueSetsReducer';
import reportDefinitionReducer from '../report/reducers/reportDefinitionsReducer';
import printTemplateReducer from 'printTemplate/reducer';
import taskFormReducer from 'bpm/task-form/reducer/taskForms';
import taskFormStatusReducer from 'bpm/task-form/reducer/remoteStatus';
import dashboardReducer from 'dashboard2/dashboard-config/reducer';
import basicInfoReducer from './basicInfoReducer';
import viewStackReducer from '../popoverStackManagement/reducer';
import ViewConfig, { ApplicationConfig } from './ViewConfigType';
import entities from './entities';
import recentTabReducer, { RecentTab } from './recentTabReducer';
import { FormFieldUnion } from '../fieldFactory/translation/fromFlowable/types/index';
import entityValidationsReducer from './entityValidationsReducer';
import searchValidationsReducer from './searchValidationsReducer';
import expressionReducer from './expressionReducer';
import entityVisibilityReducer from './entityVisibilityReducer';
import debugModeReducer from './debugMode';
import toggleHighlightMui0 from './toggleHighlightMui0';
import dashboardCreateReducer from './dashboardCreate';
import printModeReducer from './printModeReducer';
import lastSearchOrProcessPageReducer from './lastSearchOrProcessPageReducer';
import entityConceptExpReducer from 'viewConfigCalculations/ConceptAvailabilityExpressions/reducer';
import viewItemFilterExpReducer from 'viewConfigCalculations/filterExpressions/reducer';
import actionButtonExpReducer from 'viewConfigCalculations/actionButtonDisplayExpressions/reducer';
import entityEditabilityReducer from './entityEditabilityReducer';
import notistackReducer from 'notistack/reducer';
import { RootAction } from 'actions/rootAction';
import getResource from './lists/list/getResource';
import listIsLoading from './lists/listLoading';
import getOneStatusReducer from 'remoteStatus/one/reducer';
import disableFormSaveNotifierStatusReducer from 'formSaveNotifier/reducer';
import globalAlerts from 'global-alerts/reducer';
import impersonatingReducer from 'impersonate/reducer';
import reauthReducer from 'reauth-interval-and-retries/reducers';
import expressionTesterReducer from 'expression-tester/reducer';
import undisableTaskFormsReducer from 'bpm/undisable-task-form/reducer';
import connectivityStatusReducer from 'connectivity/reducer';

interface Admin {
    loading: number;
    entities: {
        [entitykey: string]: {
            [id: string]: {
                id: string;
                entityType: string;
                title?: string;
                active?: boolean;
            };
        };
    };
    ui: {
        sidebarOpen: boolean; // this is the sidebar menu used in mobile
    };
    resources: {
        [resource: string]: {
            lists: ReturnType<ReturnType<typeof lists>>;
            listIsLoading: ReturnType<ReturnType<typeof listIsLoading>>;
        };
    };
}
export interface TaskForm {
    id: string;
    name: string;
    key: string;
    version: number;
    fields: FormFieldUnion[];
    selectedOutcome?: string;
    viewName?: string;
    outcomes: {
        name: string;
        configs?: {
            visibility?: string;
            editable?: string;
            validation?: string;
            display?: string;
        };
    }[];
}
/* tslint:enable no-any */
export interface RootAppState {
    globalAlerts: ReturnType<typeof globalAlerts>;
    locale?: {};
    form?: {};
    router: ReturnType<ReturnType<typeof connectRouter>>;
    viewConfig?: ViewConfig;
    admin: Admin; // tslint:disable-line
    resources?: { name: string }[];
    processDefinitions?: {};
    valueSets: ValueSets;
    reportDefinitions?: {};
    printTemplates: ReturnType<typeof printTemplateReducer>;
    recentTab: RecentTab;
    entityValidations: ReturnType<typeof entityValidationsReducer>;
    searchValidations: ReturnType<typeof searchValidationsReducer>;
    entityVisibility: ReturnType<typeof entityVisibilityReducer>;
    entityConceptExps: ReturnType<typeof entityConceptExpReducer>;
    viewItemFilterExps: ReturnType<typeof viewItemFilterExpReducer>;
    entityEditability: ReturnType<typeof entityEditabilityReducer>;
    actionButtonExps: ReturnType<typeof actionButtonExpReducer>;
    getOneStatus: ReturnType<typeof getOneStatusReducer>;
    taskForms: {
        [key: string]: TaskForm;
    };
    taskFormStatuses: ReturnType<typeof taskFormStatusReducer>;
    bpm: BPMState;
    undisableTaskForms: ReturnType<typeof undisableTaskFormsReducer>;
    impersonating: ReturnType<typeof impersonatingReducer>;
    dashboard: ReturnType<typeof dashboardReducer>;
    basicInfo?: {
        logo: string;
        title: string;
        debugFeaturesEnabled?: boolean;
        pageNum: number;
        application: ApplicationConfig;
        applicationColor?: string;
        logoutRedirectUrl?: string;
        loginRedirectUrl?: string;
        maxFileSize: string;
    };
    reauth: ReturnType<typeof reauthReducer>;
    dashboardCreator: ReturnType<typeof dashboardCreateReducer>;
    connectivityStatus: ReturnType<typeof connectivityStatusReducer>;
    viewStack: string[];
    expressionEval?: {
        configEntity?: ReturnType<typeof entityVisibilityReducer>;
        configTask?: any; // tslint:disable-line no-any
    };
    debugMode: boolean;
    thm0: boolean;
    printMode: boolean;
    lastSearchOrProcessPage: ReturnType<typeof lastSearchOrProcessPageReducer>;
    notistack: ReturnType<typeof notistackReducer>;
    disableFormSaveNotifier: ReturnType<typeof disableFormSaveNotifierStatusReducer>;
    expressionTesterOpen: ReturnType<typeof expressionTesterReducer>;
}
export interface RootState extends RootAppState {
    viewConfig: ViewConfig;
    viewConfigIsLoading: boolean;
}

const resourcesReducer = (
    previousState: {
        [resource: string]: {
            lists: ReturnType<ReturnType<typeof lists>>;
            listIsLoading: ReturnType<ReturnType<typeof listIsLoading>>;
        };
    } = {},
    action: RootAction,
) => {
    const _resource = getResource(action);
    if (!_resource) {
        return previousState;
    }
    return {
        ...previousState,
        [_resource]: {
            lists: lists(_resource)(previousState[_resource] && previousState[_resource].lists, action),
            listIsLoading: listIsLoading(_resource)(
                previousState[_resource] && previousState[_resource].listIsLoading,
                action,
            ),
        },
    };
};

const adminReducer = combineReducers({
    loading: aorLoading,
    ui: aorUi,
    entities,
    resources: resourcesReducer,
});

const appReducer = history =>
    combineReducers<RootState>({
        globalAlerts,
        impersonating: impersonatingReducer,
        form: formReducer,
        reauth: reauthReducer,
        getOneStatus: getOneStatusReducer,
        router: connectRouter(history),
        viewConfigIsLoading: viewConfigIsLoadingReducer,
        viewConfig: viewConfigReducer,
        bpm: bpmReducer,
        undisableTaskForms: undisableTaskFormsReducer,
        valueSets: valueSetsReducer,
        connectivityStatus: connectivityStatusReducer,
        reportDefinitions: reportDefinitionReducer,
        printTemplates: printTemplateReducer,
        taskForms: taskFormReducer as any,
        taskFormStatuses: taskFormStatusReducer,
        dashboard: dashboardReducer,
        viewStack: viewStackReducer,
        recentTab: recentTabReducer,
        basicInfo: basicInfoReducer as any,
        admin: adminReducer as any, // tslint:disable-line
        entityValidations: entityValidationsReducer,
        searchValidations: searchValidationsReducer,
        entityEditability: entityEditabilityReducer,
        entityVisibility: entityVisibilityReducer,
        entityConceptExps: entityConceptExpReducer,
        actionButtonExps: actionButtonExpReducer,
        expressionEval: expressionReducer,
        debugMode: debugModeReducer,
        thm0: toggleHighlightMui0,
        dashboardCreator: dashboardCreateReducer,
        printMode: printModeReducer,
        lastSearchOrProcessPage: lastSearchOrProcessPageReducer,
        notistack: notistackReducer,
        disableFormSaveNotifier: disableFormSaveNotifierStatusReducer,
        expressionTesterOpen: expressionTesterReducer,
        viewItemFilterExps: viewItemFilterExpReducer,
    });

export default appReducer;
