import React from 'react';
import { push as pushAction } from 'connected-react-router';
import { stringify } from 'query-string';
import compose from 'recompose/compose';
import withPropsOnChange from 'recompose/withPropsOnChange';
import ViewTitle from 'components/generics/ViewTitle';
import { connect } from 'react-redux';
import GenericList from '../genericList/index';
import { Data } from '../genericList/List';
import { lowerCaseFirstLetter } from '../utils/genericUtils';
import createGetData from '../utils/createGetData';
import { withFieldFactory } from '../../../fieldFactory/Broadcasts';
import { GenerateFieldFactory, DataSource, Mode } from '../../../fieldFactory/FieldFactoryProvider';
import getRelatedFieldsFromMrgList from './util/getRelatedFieldsFromMrgList';
import dgStyles from '../utils/datagridStyles';
import memoizeOne from 'memoize-one';
import getRenderList from '../genericList/renderList';
import ViewConfig from '../../../reducers/ViewConfigType';
import { withStyles, Theme } from '@material-ui/core/styles';
import LoadMainRecord from './LoadMainRecord';
import Merge from '../genericSpecificMatchView';
import { Dialog, Card, Typography, Button } from '@material-ui/core';
import Forward from '@material-ui/icons/Forward';
import SsgAppBarMobile from 'components/SsgAppBarMobile';

const styles = (theme: Theme) => ({
    cell: {},
});

export interface PossibleMatchViewProps {
    resource: string;
    title: string | JSX.Element | null;
    viewName: string;
    viewConfig: ViewConfig;
    match: { params: { id: string } };
    editInPopover?: boolean;
    onIdChanged?: (newId: string) => void;
    onDataChange?: () => void;
    createMobileAppBar?: boolean;
}

const addFieldGeneratorProp = (
    props: PossibleMatchViewProps & {
        fieldFactory: ReturnType<GenerateFieldFactory>;
    },
) => {
    const config = {
        dataSource: DataSource.ENTITY,
        mode: Mode.DISPLAY,
        validate: false,
        connected: false,
        options: {},
    };
    return {
        generateFields: props.fieldFactory(config)({
            record: { id: props.match.params.id, entityType: props.resource },
            resource: props.resource,
            basePath: `/${props.resource}`,
            match: props.match,
        }),
    };
};

const makeMapStateToProps = () => {
    const getData = createGetData('Match');
    const mapStateToProps = (state, props) => ({
        id: decodeURIComponent(props.match.params.id),
        // data: state.admin.resources[props.resource].data[decodeURIComponent(props.match.params.id)],
        data: getData(state, props),
        isLoading: state.admin.loading > 0,
    });
    return mapStateToProps;
};

const mapDispatchToProps = {
    push: pushAction,
};

interface PossibleMatchViewComponentProps
    extends PossibleMatchViewProps,
        ReturnType<ReturnType<typeof makeMapStateToProps>>,
        ReturnType<typeof addFieldGeneratorProp> {
    push: (loc: string) => void;
    data: { id: string };
    classes: {
        [key in keyof ReturnType<typeof styles>]: string;
    };
}
interface PossibleMatchViewComponentState {
    popoverUrl: {
        record1Id: string;
        record2Id: string;
    } | null;
    open: boolean;
    location: {
        pathname: string;
        search: string;
    };
    selected: {};
    key: number;
}
class PossibleMatchViewComponent extends React.Component<
    PossibleMatchViewComponentProps,
    PossibleMatchViewComponentState
> {
    _generateFields = memoizeOne(
        (viewConfig, resource, generateFields) =>
            [
                ...generateFields(
                    getRelatedFieldsFromMrgList(viewConfig, resource).sort((a, b) => (a.order || 0) - (b.order || 0)),
                ),
                <div key="spacer making room for score" />,
            ].map(p => React.cloneElement(p, { sortable: false })) as any[],
    ); // tslint:disable-line

    _getFilter = memoizeOne((resource, id) => ({
        'matchDecision.code': 'PENDING',
        matchingId: id,
    }));
    constructor(props: PossibleMatchViewComponentProps) {
        super(props);
        this.state = {
            popoverUrl: null,
            open: false,
            location: {
                pathname: `${props.resource}Mrg`,
                search: `?${stringify({ filter: JSON.stringify(this.getFilter()) })}`,
            },
            selected: {},
            key: 0,
        };
    }
    getFilter = ({ resource, id } = this.props) => this._getFilter(resource, id);
    onRowSelect: <T extends Data>(selected: T[0][], allData: Data) => void = (selectedData, allData) => {
        const { push, data, resource } = this.props;
        if (selectedData && selectedData.length === 1) {
            const id2 = selectedData[0][`${lowerCaseFirstLetter(resource)}Id`];
            const mergeUrl = `/${resource}/${data.id}/merge/${id2}`;
            if (this.props.editInPopover) {
                this.setState({
                    popoverUrl: {
                        record1Id: data.id,
                        record2Id: id2,
                    },
                });
            } else {
                push(mergeUrl);
            }
        }
    };
    renderMergePopover = () => {
        const { resource, onDataChange } = this.props;
        const { popoverUrl } = this.state;
        if (popoverUrl) {
            return (
                <Dialog
                    TransitionProps={
                        {
                            // https://github.com/dequelabs/axe-core/issues/146
                            role: 'presentation',
                        } as any
                    }
                    maxWidth={false}
                    fullWidth={true}
                    open={!!popoverUrl}
                    onClose={() => this.setState({ popoverUrl: null })}
                >
                    <Merge
                        match={{
                            params: {
                                id: popoverUrl.record1Id,
                                id2: popoverUrl.record2Id,
                            },
                        }}
                        resource={resource}
                        viewName={`${resource}Merge`}
                        onMergeSuccess={onDataChange}
                        onNotAMatchSuccess={onDataChange}
                    />
                </Dialog>
            );
        }
        return null;
    };
    render() {
        const {
            isLoading,
            title,
            resource,
            viewConfig,
            id,
            data,
            classes,
            generateFields,
            editInPopover,
            onIdChanged,
        } = this.props;

        const defaultTitle = 'PossibleMatch';
        const titleElement = <span>{title || defaultTitle}</span>;

        const fields = this._generateFields(viewConfig, resource, generateFields);
        const listStyle = {
            cell: {
                width: `${100 / fields.length}%`,
            },
        };
        return (
            <LoadMainRecord
                id={id}
                resource={resource}
                onIdChange={editInPopover && onIdChanged ? onIdChanged : 'REDIRECT'}
            >
                {this.props.createMobileAppBar ? <SsgAppBarMobile title={titleElement} /> : null}
                {this.renderMergePopover()}
                <div className="list-page">
                    <Card style={{ overflowX: 'scroll' }}>
                        {(this.props as any).history && (
                            <Button
                                color="primary"
                                style={{ float: 'right' }}
                                onClick={() => (this.props as any).history.go(-1)}
                            >
                                <Forward className="flip-horizontal" /> Back
                            </Button>
                        )}
                        {title !== null ? (
                            <ViewTitle
                                displayAboveWidth={this.props.createMobileAppBar ? 'xs' : undefined}
                                title={titleElement}
                            />
                        ) : (
                            <div style={{ minHeight: '.5em' }} />
                        )}
                        <Typography
                            component="h2"
                            variant="subtitle1"
                            style={{ fontWeight: 'bold', marginLeft: '1em' }}
                        >
                            {' '}
                            Current Record
                        </Typography>
                        {getRenderList(classes, listStyle)({
                            fields,
                            resource,
                            ids: data && data.id ? [data.id] : [],
                            data: data && data.id ? { [data.id]: data } : {},
                            basePath: `/${resource}`,
                            isLoading,
                            ariaProps: { 'aria-label': 'Current Record' },
                            setSort: () => null,
                        })}
                        <Typography
                            component="h2"
                            variant="subtitle1"
                            style={{ fontWeight: 'bold', marginLeft: '1em', marginBottom: 0 }}
                        >
                            {' '}
                            Possible Matches
                        </Typography>
                        <GenericList
                            {...{
                                title: null,
                                onRowSelect: this.onRowSelect,
                                actions: null,
                                hasCreate: false,
                                multiSelectable: false,
                                updateUrlFromFilter: false,
                                viewName: `${resource}MrgList`,
                                fakePush: location => {
                                    this.setState(state => ({ ...state, location }));
                                },
                                location: this.state.location,
                                resource: `${resource}Mrg`,
                                name: resource,
                                selectedData: this.state.selected,
                                dgOptions: { fixedHeader: true },
                                dgStyles: { ...dgStyles, table: {} },
                                useCard: false,
                                filter: this.getFilter(),
                                renderList: args =>
                                    getRenderList(classes, listStyle)({
                                        ...args,
                                        ariaProps: { 'aria-label': 'Possible Matches' },
                                    }),
                                // rowButtons: <NotAMatchButton parentResource={resource} onSuccess={
                                //    () => { this.setState(state => ({ ...state, key: state.key + 1 })); }} />
                            }}
                            key={this.state.key}
                        />
                    </Card>
                </div>
            </LoadMainRecord>
        );
    }
}

const enhance = compose(
    withFieldFactory,
    withPropsOnChange(['viewName', 'fieldFactory'], addFieldGeneratorProp),
    connect(
        makeMapStateToProps,
        mapDispatchToProps,
    ),
    withStyles(styles),
);
const PossibleMatch: React.SFC<PossibleMatchViewProps> = enhance(PossibleMatchViewComponent);
PossibleMatch.displayName = 'PossibleMatch';

export default PossibleMatch;
