import { success, failure, initial, pending, RemoteData } from '@devexperts/remote-data-ts';
import { ids as idsType } from './ids';
import { total as totalType } from './total';

export interface List {
    ids: idsType;
    total: totalType;
}

export type rd<T> = RemoteData<Error, T>;
export type serialrd<T> =
    | { _tag: 'RemoteFailure'; error: Error }
    | { _tag: 'RemoteSuccess'; value: T }
    | { _tag: 'RemoteInitial' }
    | { _tag: 'RemotePending' };

export const isRemoteData = (rd: any): rd is RemoteData<Error, any> => {
    return rd && rd._tag && rd._tag.startsWith('Remote');
};

export const serialize = <T>(r: rd<T>): serialrd<T> =>
    r.isSuccess()
        ? { _tag: r._tag, value: r.value }
        : r.isFailure()
        ? { _tag: r._tag, error: r.error }
        : r.isInitial()
        ? { _tag: r._tag }
        : { _tag: r._tag };

export const deserialize = <T>(srd: serialrd<T>): rd<T> => {
    if (srd._tag === 'RemoteFailure') {
        return failure(srd.error);
    }
    if (srd._tag === 'RemoteInitial') {
        return initial;
    }
    if (srd._tag === 'RemotePending') {
        return pending;
    }
    return success(srd.value);
};

export const serializeList = (list: rd<List>) => serialize(list);
export const deserializeList = (srd: serialrd<List>) => deserialize(srd);
