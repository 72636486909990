import React from 'react';
import { Link } from 'react-router-dom';
import shouldUpdate from 'recompose/shouldUpdate';
import compose from 'recompose/compose';
import { customShowRedirects } from '../overrides';
import { Subtract } from 'utility-types';
import { Button, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import pure from 'recompose/pure';
import { createSelector } from 'reselect';
import Forward from '@material-ui/icons/Forward';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';
import LinkDialog from './LinkDialog';
import { connect } from 'react-redux';
import ViewConfig from '../../../reducers/ViewConfigType';

const styles = theme => ({
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
});

interface InjectComponentDialogProps {
    buttonLabel: React.ReactNode;
    ariaLabelledBy: string;
    ariaDescribedBy: string;
    content: React.ReactNode;
    classes: {
        rightIcon: string;
        iconSmall: string;
        leftIcon: string;
    };
}
const InjectComponentDialogComponent: React.SFC<InjectComponentDialogProps> = ({
    buttonLabel,
    classes,
    ariaLabelledBy,
    ariaDescribedBy,
    content,
}: InjectComponentDialogProps) => (
    <LinkDialog
        renderLabel={({ onClick }) =>
            buttonLabel ? (
                <Button variant="contained" color="primary" onClick={onClick}>
                    {buttonLabel}
                    <RemoveRedEye className={classNames(classes.rightIcon, classes.iconSmall)} />
                </Button>
            ) : (
                <IconButton color="primary" onClick={onClick} aria-label="preview">
                    <RemoveRedEye />
                </IconButton>
            )
        }
        ariaLabelledBy={ariaLabelledBy}
        ariaDescribedBy={ariaDescribedBy}
        content={content}
    />
);

export const InjectComponentDialog: React.ComponentType<
    Subtract<InjectComponentDialogProps, { classes: InjectComponentDialogProps['classes'] }>
> = withStyles(styles)(InjectComponentDialogComponent as any) as any; // tslint:disable-line

interface ShowButtonProps {
    resource: string;
    record: { id?: string | number };
    basePath: string;
}
interface ShowButtonComponentProps extends ShowButtonProps {
    label: React.ReactNode;
    classes: {
        rightIcon: string;
        leftIcon: string;
        iconSmall: string;
    };
    redirButtons: string; // ; seperates buttons, | seperates label from redirectlocations
    popoverButtons: { initializedContent: React.ReactElement<{}> }[];
    viewConfig: ViewConfig;
}
const ShowButtonComponent: React.SFC<ShowButtonComponentProps> = ({
    basePath,
    label,
    record,
    resource,
    classes,
    popoverButtons,
    redirButtons,
}) => {
    if (typeof record.id === 'undefined') {
        return null;
    }
    if (popoverButtons.length === 0 && !redirButtons) {
        const DefaultLink = props => (
            <Link {...props} to={`${basePath}/${encodeURIComponent(record.id as string)}/show`} />
        );
        return (
            <Button variant="text" color="inherit" style={{ textTransform: 'capitalize' }} component={DefaultLink}>
                {label}
                <RemoveRedEye className={classNames(classes.rightIcon, classes.iconSmall)} />
            </Button>
        );
    }
    const createPopoverButton = customRedirect => {
        return (
            <InjectComponentDialog
                content={customRedirect.initializedContent}
                buttonLabel={customRedirect.label}
                ariaLabelledBy={customRedirect.ariaLabelledBy(record)}
                ariaDescribedBy={customRedirect.ariaDescribedBy(record)}
            />
        );
    };
    const createButtons = (buttons: string) => {
        if (!buttons) {
            return null;
        }
        return buttons.split(';').map((trip, i) => {
            const [btnlabel, link] = trip.split('|');
            const MyLink = props => <Link {...props} to={link} />;
            return (
                <span key={trip}>
                    <Button
                        variant="contained"
                        color="inherit"
                        style={{ textTransform: 'capitalize' }}
                        component={MyLink}
                    >
                        {btnlabel}
                        <Forward className={classNames(classes.rightIcon, classes.iconSmall)} />
                    </Button>
                </span>
            );
        });
    };

    return (
        <span>
            {popoverButtons.map((c, i) => (
                <span key={i} style={{ float: 'left' }}>
                    {i !== 0 && <span>&nbsp;</span>}
                    {createPopoverButton(c)}
                </span>
            ))}
            <div style={{ float: 'left' }}>{createButtons(redirButtons)}</div>
            <div style={{ clear: 'both' }} />
        </span>
    );
};

ShowButtonComponent.defaultProps = {
    record: {},
    basePath: '',
    label: 'View',
};

const makeMapStateToProps = () => {
    const redirButtonsSelector = createSelector(
        (state, props) => state.viewConfig,
        (state, props) => state.admin.entities,
        (state, props) => props.resource,
        (state, props) => props.record,
        (viewConfig, entities, resource, record) => {
            const customRedirect = customShowRedirects[resource];
            if (customRedirect) {
                return (
                    customRedirect
                        .filter(rc => rc.redirectFormula && !rc._isRowClick && !rc.popover)
                        .map(
                            redirectConf =>
                                `${redirectConf.label}|${redirectConf.redirectFormula(record, entities, viewConfig)}`,
                        )
                        .join(';') || null
                );
            }
            return null;
        },
    );
    const popoversSelector = createSelector(
        (state, props) => state.viewConfig,
        (state, props) => state.admin.entities,
        (state, props) => props.resource,
        (state, props) => props.record,
        (viewConfig, entities, resource, record) => {
            const customRedirect = customShowRedirects[resource];
            if (customRedirect) {
                return customRedirect
                    .flatMap((c, i) => {
                        if (c.popover && !c._isRowClick) {
                            const data = c.getDataForPopover(record, entities, viewConfig);
                            if (data) {
                                return [`${i}|${data}`];
                            }
                            return [];
                        }
                        return [];
                    })
                    .join(';');
            }
            return null;
        },
    );
    const popoverContentSelector = createSelector(
        popoversSelector,
        (state, props) => props.resource,
        (dcr, resource) => {
            if (dcr) {
                return dcr.split(';').flatMap(trisection => {
                    const [i, data] = trisection.split('|');
                    const index = parseInt(i, 10);
                    const redir = customShowRedirects[resource][index];
                    if (redir) {
                        return [{ ...redir, initializedContent: redir.popover(data) }];
                    }
                    return [];
                });
            }
            return [];
        },
    );
    const mapStateToProps = (state, props) => ({
        redirButtons: redirButtonsSelector(state, props),
        popoverButtons: popoverContentSelector(state, props),
    });
    return mapStateToProps;
};

const enhance = compose(
    shouldUpdate(
        (props, nextProps) =>
            (props.record && nextProps.record && props.record.id !== nextProps.record.id) ||
            props.basePath !== nextProps.basePath ||
            (props.record == null && nextProps.record != null),
    ),
    withStyles(styles),
    connect(makeMapStateToProps),
    pure,
);

const ShowButton: React.SFC<ShowButtonProps> = enhance(ShowButtonComponent);
export default ShowButton;
