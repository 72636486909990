import { simpleGetConfProperty } from './index';

interface WithViewName {
    viewName?: string;
    editViewName?: string;
    showViewName?: string;
    noSearch?: boolean;
}
export const getViewName = simpleGetConfProperty<WithViewName>('viewName', undefined);
export const getEditViewName = simpleGetConfProperty<WithViewName>('editViewName', undefined);
export const getShowViewName = simpleGetConfProperty<WithViewName>('showViewName', undefined);
export const gethasNoSearch = simpleGetConfProperty<WithViewName>('noSearch', false);

interface WithExpansions {
    expansions?: string[];
}
export const getExpansions = simpleGetConfProperty<WithExpansions>('expansions', []);
