import * as React from 'react';
import { reduxForm } from 'redux-form';
import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import withPropsOnChange from 'recompose/withPropsOnChange';
import { FieldFactorySubscriber } from '../../../fieldFactory/Broadcasts';
import { DataSource, Mode } from '../../../fieldFactory/FieldFactoryProvider';
import withPopoverLock from '../hoc/popoverLock';
import FieldMatrixProps from './interfaces/FieldMatrix';
import { Config, LiveFieldConfig } from '../../../fieldFactory/FieldFactoryProvider';
import ThreeColumnView from './components/ThreeColumnView';
import injectDisplayFieldsForRecord from './hoc/injectDisplayFieldsForRecord';
import injectInputFieldsForMerge from './hoc/injectInputFieldsForMerge';

const GenericMergeView = compose(
    BaseComponent => (props: FieldMatrixProps) => (
        <FieldFactorySubscriber>
            {fieldFactory => <BaseComponent {...props} fieldFactory={fieldFactory} />}
        </FieldFactorySubscriber>
    ),
    withPropsOnChange(
        ['fieldFactory'],
        (
            props: FieldMatrixProps & {
                fieldFactory: (
                    config: Config,
                ) => (lc: LiveFieldConfig) => (fieldDefs: {}[]) => React.ReactElement<{}>[];
            },
        ) => {
            const displayConfig = {
                dataSource: DataSource.ENTITY,
                mode: Mode.DISPLAY,
                validate: false,
                connected: false,
                options: {}, // not used at the moment
            };
            const inputConfig: Config = {
                dataSource: DataSource.ENTITY,
                mode: Mode.INPUT,
                validate: true,
                connected: true,
                options: {},
            };
            return {
                generateInputFields: props.fieldFactory(inputConfig),
                generateDisplayFields: props.fieldFactory(displayConfig),
            };
        },
    ),
    /* now that our field generators are mapped to "generateInputFields" and "generateDisplayFields", respectively */
    injectDisplayFieldsForRecord('record1', 'primaryRecordFields', 'Current Record'),
    injectDisplayFieldsForRecord('record2', 'secondaryRecordFields', 'Matching Record'),
    withPropsOnChange(
        ['generateInputFields', 'record', 'record1', 'record2', 'viewConfig', 'viewName'],
        injectInputFieldsForMerge,
    ),
    withPopoverLock,
    withProps(props => ({
        initialValues: props.record,
    })),
    reduxForm({
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
        form: 'mergeForm',
    }),
)(ThreeColumnView);

export default GenericMergeView;
