import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Dialog, IconButton, Card, CardContent, CardActions, Button } from '@material-ui/core';
import { taskEventCreator } from '../../../../actions/taskEvent';
import { getChangeTaskSchedDateButton } from './changeSchedDate';
import Edit from '@material-ui/icons/Edit';
import { RootState } from '../../../../../reducers/rootReducer';
import { withDateFormat, DateFormatProps } from '../../../../../fieldFactory/dateFormat/Broadcasts';
import DateTimePicker from '../../../../../fieldFactory/input/components/DateTimePicker';

interface EditableTaskSchedDateProps {
    taskId: string;
    rootStyle?: {};
    isAdmin?: boolean;
}
const mapStateToProps = (state: RootState, { taskId }: EditableTaskSchedDateProps) => ({
    task: taskId && state.admin.entities.TaskInstance && (state.admin.entities.TaskInstance[taskId] as any),
});
const mapDispatchToProps = (dispatch, ownProps) => ({
    refresh: () => dispatch(taskEventCreator.getTask(ownProps.taskId)),
});

type DialogOpen =
    | {
          _tag: 'EditTaskSchedDate';
          schedDate: Date | string;
      }
    | false;

type EditSchedDateProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> &
    EditableTaskSchedDateProps &
    DateFormatProps;

interface EditSchedDateState {
    dialogOpen: DialogOpen;
}

class EditTaskSchedDateComponent extends React.Component<EditSchedDateProps, EditSchedDateState> {
    constructor(props: EditSchedDateProps) {
        super(props);
        this.state = {
            dialogOpen: false,
        };
    }
    handleSchedDateChange = e => {
        const endDate = typeof e === 'string' ? e : (e.target && e.target.value) || '';
        this.changeSchedDate(endDate);
    };
    changeSchedDate = (schedDate: Date) => {
        if (schedDate) {
            this.setState(prevState => ({
                dialogOpen: prevState.dialogOpen && {
                    ...prevState.dialogOpen,
                    schedDate,
                },
            }));
        } else {
            this.setState(prevState => ({
                dialogOpen: prevState.dialogOpen && {
                    ...prevState.dialogOpen,
                    schedDate: null,
                },
            }));
        }
    };
    openDialog = (schedDate: Date | string = '') => () => {
        this.setState({
            dialogOpen: {
                _tag: 'EditTaskSchedDate',
                schedDate,
            },
        });
    };
    closeDialog = () => {
        this.setState({ dialogOpen: false });
    };
    renderDialog = () => {
        const { dialogOpen } = this.state;
        const { taskId, refresh } = this.props;
        if (!dialogOpen) {
            return null;
        }
        return (
            <Dialog
                onClose={this.closeDialog}
                open={true}
                TransitionProps={
                    {
                        // https://github.com/dequelabs/axe-core/issues/146
                        role: 'presentation',
                    } as any
                }
            >
                <Card>
                    <CardContent>
                        <DateTimePicker
                            options={{
                                id: 'editSchedDateOfCompletedTask',
                            }}
                            label="Scheduled Date"
                            input={{
                                value: dialogOpen.schedDate || '',
                                onChange: this.handleSchedDateChange,
                                onBlur: this.changeSchedDate,
                            }}
                            meta={{}}
                        />
                    </CardContent>
                    <CardActions>
                        <Button color="default" onClick={this.closeDialog}>
                            Close
                        </Button>
                        {getChangeTaskSchedDateButton(taskId, refresh)(dialogOpen.schedDate)}
                    </CardActions>
                </Card>
            </Dialog>
        );
    };
    render() {
        const { task, rootStyle, isAdmin = true } = this.props;
        if (!task) {
            return null;
        }
        const schedDateString = task.schedDate;

        return (
            <React.Fragment>
                {this.renderDialog()}
                <div style={{ display: 'flex', ...rootStyle } || { paddingLeft: 16 }}>
                    <DateTimePicker
                        disabled={true}
                        options={{
                            id: 'disabled-completed-task-scheddate',
                        }}
                        label="Scheduled Date"
                        meta={{}}
                        input={{
                            value: new Date(schedDateString),
                        }}
                    />
                    {isAdmin && (
                        <IconButton
                            aria-label="scheduled date"
                            onClick={this.openDialog(schedDateString)}
                            style={{ width: 38, height: 38 }}
                        >
                            <Edit />
                        </IconButton>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

const EditTaskEndDate: React.SFC<EditableTaskSchedDateProps> = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withDateFormat,
)(EditTaskSchedDateComponent);
export default EditTaskEndDate;
