import uniq from 'lodash/uniq';
import {
    getDataTypeForFieldExpr,
    isFieldViewField,
    getViewIndexAndAdditionalConfigFields,
    getIsExpensiveForFieldExpr,
    getAllFieldsFromView,
    getCustomViewName,
    getRefEntityName,
} from '../../../components/generics/utils/viewConfigUtils/index';
import ViewConfigType, { FieldViewField, ViewField } from '../../../reducers/ViewConfigType';
import { EntityValidations } from '../../../reducers/entityValidationsReducer';
import * as fieldTypes from '../../../components/generics/utils/fieldDataTypes';
import { ActionButtonExps } from 'viewConfigCalculations/actionButtonDisplayExpressions/reducer';
import { fromNullable, fromPredicate, tryCatch } from 'fp-ts/lib/Option';
import get from 'lodash/get';
import getFieldsRequiredForExpression from '../getFieldsRequiredForExpression';
import { getFields } from 'components/generics/genericList';

type View = ViewConfigType['views'][keyof ViewConfigType['views']];

const referenceFieldsWeMustExpand = [
    fieldTypes.REFONE,
    fieldTypes.REFMANY_JOIN,
    fieldTypes.VALUESET,
    fieldTypes.VALUESET_MANY,
    fieldTypes.REFMANYMANY,
];

const getAdhocExpansionnPathsActuallyUsed = (
    viewConfig: ViewConfigType,
    baseEntity: string,
    fieldsRequiredForValidations: string[] = [],
) => {
    // get fields that require expansions
    return fieldsRequiredForValidations.flatMap(fexp => {
        if (fexp.endsWith('Ids')) {
            return [fexp];
        }
        if (fexp.indexOf('.') !== -1) {
            return [fexp.slice(0, fexp.lastIndexOf('.'))];
        } else if (fexp.endsWith('Id')) {
            return [];
        }
        try {
            // throws on validation paths like '<field>Code'
            const fieldDataType = getDataTypeForFieldExpr(viewConfig, baseEntity, fexp, 'TRAVERSE_PATH');
            if (fieldDataType === 'REFMANY' || fieldDataType === 'REFMANYMANY' || fieldDataType === 'REFONE') {
                return [fexp];
            }
        } catch (e) {
            // since it's a base, not-reference field, no additional expansion is required.
            return [];
        }
        return [];
    });
};
const getExpansionPathsForFieldSet = (viewFields: FieldViewField[], viewConfig: ViewConfigType): string[] => {
    // first get all fields containing a '.' that aren't REFMANY fields (they will fetch their own data)
    const fieldsWithExpansions: FieldViewField[] = viewFields.filter(
        f =>
            getDataTypeForFieldExpr(viewConfig, f.entity, f.field) !== 'REFMANY' &&
            getDataTypeForFieldExpr(viewConfig, f.entity, f.field) !== fieldTypes.REFMANY_JOIN &&
            f.field.indexOf('.') !== -1,
    );
    // then pop off the field so we get all the expansion paths we need
    const expansionPaths: string[] = fieldsWithExpansions.map(f => f.field.slice(0, f.field.lastIndexOf('.')));

    // next, make sure we get all REFONE and ValueSet+valueSet_Many fields expanded
    const referenceFields: FieldViewField[] = viewFields.filter(
        f => referenceFieldsWeMustExpand.indexOf(getDataTypeForFieldExpr(viewConfig, f.entity, f.field)) !== -1,
    );
    const expensiveCalcsFields: string[] = viewFields
        .filter(f => getIsExpensiveForFieldExpr(viewConfig, f.entity, f.field))
        .map(f => f.field);

    // lets expand whatever is necessary for the ManyMany list views:
    // basically ([f.field], with whatever deep expansions necessary appended)
    const fieldsExpandedInManyMany: string[] = referenceFields
        .filter(f => getDataTypeForFieldExpr(viewConfig, f.entity, f.field) === fieldTypes.REFMANYMANY)
        .map(f => {
            const viewUsed = fromPredicate<string>(Boolean)(f.config)
                .chain(c => tryCatch(() => JSON.parse(c)))
                .chain(c => fromPredicate<string>(Boolean)(c.viewName))
                .getOrElseL(() => {
                    return getCustomViewName('LIST', false)(
                        getRefEntityName(viewConfig, f.entity, f.field, 'POP_LAST'),
                        viewConfig,
                        f.config,
                    );
                });
            const fields = getFields(viewConfig, viewUsed, true, f.field);
            const manyManyListViewFieldsWithDepth = fields.filter(vf => {
                return (
                    isFieldViewField(vf) &&
                    (vf.field.indexOf('.') !== -1 ||
                        referenceFieldsWeMustExpand.indexOf(
                            getDataTypeForFieldExpr(viewConfig, vf.entity, vf.field, 'POP_LAST'),
                        ) !== -1 ||
                        getIsExpensiveForFieldExpr(viewConfig, vf.entity, vf.field, 'POP_LAST'))
                );
            }) as FieldViewField[];

            const expandAll = f.field; // `${f.field}.all` if we really want to be safe;
            if (manyManyListViewFieldsWithDepth.length > 0) {
                return (
                    expandAll +
                    `,${f.field}.` +
                    buildExpansionForFieldSet(manyManyListViewFieldsWithDepth, viewConfig)
                        .split(',')
                        .join(`,${f.field}.`)
                );
            }
            return expandAll;
        });
    const configuredForExpansion: string[] = referenceFields
        .filter(f => f.widgetType === 'SELECT')
        .flatMap(f => {
            return fromNullable(f.config)
                .chain(c => tryCatch(() => JSON.parse(c)))
                .mapNullable(c => c.expansions)
                .getOrElse([])
                .map(path => `${f.field}.${path}`);
        });
    const referenceFieldPaths: string[] = [...referenceFields.map(f => f.field), ...fieldsExpandedInManyMany];
    const allPaths = uniq([
        ...expansionPaths,
        ...referenceFieldPaths,
        ...configuredForExpansion,
        ...expensiveCalcsFields,
    ]);
    return allPaths;
};
const combinePathsIntoExpansionString = (paths: string[]): string => {
    const uniquePaths = uniq(paths);
    const expansionPathsWithLongestOnly = uniquePaths.filter(
        path =>
            !uniquePaths.find(
                otherPath =>
                    otherPath.startsWith(path) && otherPath.length > path.length && otherPath[path.length] === '.',
            ),
    );

    // now sort in ascending order + alphabetical tie breaker, and concatenate
    return expansionPathsWithLongestOnly.sort((a, b) => a.length - b.length || b.localeCompare(a)).join(',');
};
export const buildExpansionForFieldSet = (
    viewFields: FieldViewField[],
    viewConfig: ViewConfigType,
    fieldsRequiredForValidations: string[] = [],
): string => {
    return combinePathsIntoExpansionString(
        uniq([...getExpansionPathsForFieldSet(viewFields, viewConfig), ...fieldsRequiredForValidations]),
    );
};

const convertFieldForValidationToUseableExpansion = (valFieldExp: string) => valFieldExp.split('_ALL_').join('all');

export const getRecordFieldsRequiredForActionButtons = (ve: ActionButtonExps[0]) =>
    fromNullable(ve)
        .mapNullable(ve =>
            Object.values(ve)
                .flatMap(e => e.fieldsRequired)
                .map(convertFieldForValidationToUseableExpansion)
                .filter(f => f.startsWith('record.'))
                .map(f => f.slice('record.'.length)),
        )
        .getOrElse([]);

const getFieldsRequiredForAdhocConfigExpressions = (pathInConfig: string) => (fields: ViewField[]) => {
    return fields.flatMap(f => {
        if (!isFieldViewField(f)) {
            return [];
        }
        return fromPredicate<string>(Boolean)(f.config)
            .chain(configStr => tryCatch(() => JSON.parse(configStr)))
            .mapNullable(config => get(config, pathInConfig))
            .chain(exp => fromPredicate(exp => typeof exp === 'string')(exp))
            .map(getFieldsRequiredForExpression)
            .getOrElse([]);
    });
};
export default (
    _viewName: string,
    viewConfig: ViewConfigType,
    entityValidations: EntityValidations = {},
    actionButtonExps: ActionButtonExps = {},
): string => {
    const [viewName, bpmConfigFields] = getViewIndexAndAdditionalConfigFields(
        _viewName,
        viewConfig,
        'ALWAYS_LINKEDENTITY',
    );
    const view: View = viewConfig.views[viewName];

    const fieldsInView = getAllFieldsFromView(viewConfig, viewName);
    const fieldsRequiredForRefManyHasCreate = getFieldsRequiredForAdhocConfigExpressions('hasCreate')(
        fieldsInView.filter(f => {
            return (
                isFieldViewField(f) &&
                f.widgetType === 'MULTISELECT' &&
                getDataTypeForFieldExpr(viewConfig, f.entity, f.field, 'POP_LAST') === 'REFMANY'
            );
        }),
    );

    // any 'nested' fields, e.g. contact.firstName, we need to pull in any validations on the contact that reference 'firstName'
    // and include the fields on those as well.
    const fieldsRequiredForValidationsOnNestedFields =
        viewConfig.views[viewName].viewType === 'EDIT'
            ? uniq(
                  fieldsInView.flatMap(f => {
                      if (isFieldViewField(f) && f.field.includes('.')) {
                          const [reference] = f.field.split('.');
                          const referenceEntity = viewConfig.entities[view.entity].fields[reference].relatedEntity;
                          const validations = fromNullable(entityValidations[referenceEntity]).map(v => {
                              return v.flatMap(validation => {
                                  if (
                                      validation.fieldsRequired.some(fr => {
                                          // if the validation has some field in our view:
                                          return fieldsInView.some(fiv => {
                                              if (isFieldViewField(fiv)) {
                                                  return fiv.field === `${reference}.${fr}`;
                                              }
                                              return false;
                                          });
                                      })
                                  ) {
                                      // if a validation includes a field in our view, include all other fields required for that validation
                                      return validation.fieldsRequired.map(fr => `${reference}.${fr}`);
                                  }
                                  return [];
                              });
                          });
                          return validations.getOrElse([]);
                      }
                      return [];
                  }),
              )
            : []; // gets 'deep' fields.

    const fieldsRequiredForValidationsOnMainEntity = (entityValidations[view.entity] || [])
        .flatMap(conf => conf.fieldsRequired)
        .map(convertFieldForValidationToUseableExpansion);

    const fieldsRequiredForActionButtons = getRecordFieldsRequiredForActionButtons(actionButtonExps[view.name]);

    const viewFields: FieldViewField[] = [...fieldsInView, ...bpmConfigFields].filter(
        f => f.widgetType !== 'EXPRESSION',
    ) as FieldViewField[];
    return buildExpansionForFieldSet(
        viewFields,
        viewConfig,
        getAdhocExpansionnPathsActuallyUsed(viewConfig, view.entity, [
            ...fieldsRequiredForValidationsOnMainEntity,
            ...fieldsRequiredForValidationsOnNestedFields,
            ...fieldsRequiredForActionButtons,
            ...fieldsRequiredForRefManyHasCreate,
        ]),
    );
};
