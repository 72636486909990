import { CRUD_GET_ONE, CRUD_GET_ONE_FAILURE, CRUD_GET_ONE_SUCCESS } from './constants';
import { createCustomAction } from 'typesafe-actions';
import { ErrorsCbs } from '../types';
import { EntityBase } from 'sideEffect/services';
import { Subtract } from 'utility-types';
import { NormalizedResponse } from '../util/normalizeEntityResponse';
import { HttpError } from '../util/HttpError';

export interface GetOneParams {
    id: string;
    resource: string;
    restUrl?: string;
    cb?: <T extends EntityBase>(id?: string, data?: T) => void;
    errorsCbs?: ErrorsCbs;
    // -1 here means 'do not expand anything'
    view: string | null | -1;
    appendExpansions?: string[];
    monitorRequest?: boolean;
}
export const crudGetOne = createCustomAction(CRUD_GET_ONE, type => {
    const defaultArgs: Partial<GetOneParams> = {
        // redirectTo: 'edit',
        errorsCbs: {},
    };
    return (args: GetOneParams, cancelOnRouteChange: boolean = true) => {
        const mergedArgs: GetOneParams = {
            ...defaultArgs,
            ...args,
        };
        return {
            type,
            payload: mergedArgs,
            cancelOnRouteChange,
        };
    };
});

type cleanedPayload = Subtract<GetOneParams, Pick<GetOneParams, 'cb' | 'errorsCbs'>>;
export const crudGetOneFailure = createCustomAction(CRUD_GET_ONE_FAILURE, type => {
    return (error: Error | HttpError, requestPayload: cleanedPayload) => {
        return {
            type,
            payload: error,
            requestPayload,
        };
    };
});

export const crudGetOneSuccess = createCustomAction(CRUD_GET_ONE_SUCCESS, type => {
    return <T>(
        data: NormalizedResponse<T, string> | null,
        requestPayload: cleanedPayload,
        dontOverwrite?: {
            [entityType: string]: {
                [id: string]: {
                    [field: string]: true;
                };
            };
        },
    ) => {
        return {
            type,
            payload: {
                data,
            },
            meta: {
                dontOverwrite,
            },
            requestPayload,
        };
    };
});
