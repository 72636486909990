import { isFieldFromFlowable } from './../Address/util';

const getListSelectProps = (propConfiguration, fieldDefinition) => {
    try {
        const configStr: string | undefined = isFieldFromFlowable(fieldDefinition)
            ? fieldDefinition &&
              fieldDefinition.params &&
              fieldDefinition.params.configs &&
              fieldDefinition.params.configs.fieldConfig
            : fieldDefinition.config;
        let hasCreate = false;
        let singleSelect = false;
        let expansions: string[] = [];
        if (configStr) {
            const parsedConfig = JSON.parse(configStr);
            hasCreate = parsedConfig.hasCreate || false;
            singleSelect = parsedConfig.singleSelect || false;
            expansions = parsedConfig.expansions || [];
        }

        propConfiguration = {
            ...propConfiguration,
            hasCreate,
            singleSelect,
            expansions,
        };
        return propConfiguration;
    } catch (e) {
        // tslint:disable
        console.error(
            `Misconfigured field configuration for ListSelect component! hasCreate will be the component default. Error below`,
        );
        console.error(e);
        // tslint:enable
        return propConfiguration;
    }
};

export default getListSelectProps;
