import React from 'react';
import { ReactElement } from 'react';
import RGridInner from '../fields/display/RGrid';
import Hidden from './HiddenField';
import { formContext } from './EntityFormContext';
import { formContext as showFormContext } from './EntityFormContext/Show';
import { Subtract } from 'utility-types';

interface RGridWithVisProps {
    fields?: ReactElement<{
        source?: string;
        fieldInstanceIdentifier?: string;
    }>[];
    fieldsToHide: {
        [f: string]: boolean;
    };
    lastRowDropdownsFlipUp?: boolean;
}
class RGridWithVis extends React.Component<RGridWithVisProps> {
    static defaultProps = {
        fields: [],
    };
    render() {
        const { fields, fieldsToHide, ...props } = this.props;
        return (
            <RGridInner
                {...props}
                fields={(fields || []).map(f => {
                    const shouldHide = f.props.fieldInstanceIdentifier
                        ? fieldsToHide[f.props.fieldInstanceIdentifier]
                        : f.props.source
                        ? fieldsToHide[f.props.source]
                        : false;
                    return shouldHide ? (
                        <Hidden dontShowCol={true} {...f.props}>
                            {f}
                        </Hidden>
                    ) : (
                        f
                    );
                })}
            />
        );
    }
}

type RGridWithVisibilityProps = Subtract<RGridWithVisProps, Pick<RGridWithVisProps, 'fieldsToHide'>> & {
    isShow?: boolean;
};
const RGridWithVisibility: React.SFC<RGridWithVisibilityProps> = (props: RGridWithVisibilityProps) => {
    const Context = props.isShow ? showFormContext : formContext;
    return <Context.Consumer>{fc => <RGridWithVis fieldsToHide={fc.hiddenFields} {...props} />}</Context.Consumer>;
};

export default RGridWithVisibility;
