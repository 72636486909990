import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { enqueueSnackbar as enqueueSnackbarAction } from 'notistack/actions';
import { asyncEventsInProgressContext } from '../TaskDetail/asyncEventCountContext';
import { getOnDrop } from '../../../fieldFactory/input/components/FileUpload/getOnDrop';
import { getMaxFileSizeNumber, getMaxFileSizeMultiplier } from 'fieldFactory/input/components/FileUpload/FileInput';

class ProcessFileInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        };
    }

    getOnDrop = (
        eventCbs /* : {
        beforeRequest?: () => void;
        afterRequest?: () => void;
    } */,
        taskId,
    ) => {
        const { enqueueSnackbar } = this.props;
        return getOnDrop(
            {
                ...eventCbs,
                onResponse: statusCode => {
                    const didFail = statusCode < 200 || statusCode >= 300;
                    enqueueSnackbar({
                        message: didFail ? 'Upload Failed' : 'Upload Successful',
                        options: {
                            variant: didFail ? 'error' : 'success',
                        },
                    });
                },
                onNetworkError: () => {
                    enqueueSnackbar({
                        message: 'Network error',
                        options: { variant: 'error' },
                    });
                },
                onSuccessWithData: data => {
                    this.props.onChange([data]);
                },
            },
            taskId,
        );
    };

    render() {
        const { value, disabled, showFile = true, taskId, inputRef, maxFileSize, inputProps } = this.props;
        return (
            <div>
                <div style={Object.assign({ marginTop: showFile ? undefined : '1.2em' })}>
                    <asyncEventsInProgressContext.Consumer>
                        {({ increment, decrement }) => {
                            return (
                                <input
                                    ref={inputRef}
                                    aria-label="file"
                                    {...inputProps}
                                    type="file"
                                    disabled={disabled}
                                    onChange={e => {
                                        if (e.target.files) {
                                            if (
                                                maxFileSize &&
                                                e.target.files[0].size >
                                                    parseInt(getMaxFileSizeNumber(maxFileSize), 10) *
                                                        getMaxFileSizeMultiplier(maxFileSize)
                                            ) {
                                                alert('File is too large. Upload limit is ' + maxFileSize);
                                                e.target.value = '';
                                            } else {
                                                this.getOnDrop(
                                                    {
                                                        beforeRequest: increment,
                                                        afterRequest: decrement,
                                                    },
                                                    taskId,
                                                )(e.target.files);
                                            }
                                        }
                                    }}
                                />
                            );
                        }}
                    </asyncEventsInProgressContext.Consumer>
                </div>
                {showFile ? <div>{value.fileFileName || '<file name not returned>'}</div> : null}
            </div>
        );
    }
}

ProcessFileInput.defaultProps = {
    value: [],
};

const enhance = compose(
    connect(
        state => {
            return {
                maxFileSize: state.basicInfo && state.basicInfo.maxFileSize,
            };
        },
        {
            enqueueSnackbar: enqueueSnackbarAction,
        },
    ),
);

export default enhance(ProcessFileInput);
