import React from 'react';
import { RenderListArguments } from './List';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    // TablePagination,
    TableRow,
    Tooltip,
    TableSortLabel,
    Checkbox,
    IconButton,
    withStyles,
    Theme,
    createStyles,
    // Paper,
} from '@material-ui/core';
import Go from '@material-ui/icons/Forward';
import Clear from '@material-ui/icons/HighlightOffTwoTone';
import { adjustLinkedXToLinkedEntity } from '../utils/viewConfigUtils';
import OverriddenRedirectProvider from './WithOverriddenRedirects';
import { IconButtonProps } from '@material-ui/core/IconButton';
import Themed from 'components/Themed';
import WithViewConfig from '../WithViewConfig';

const SelCount: React.SFC<{ numSelected: number }> = ({ numSelected, children }) => (
    <div style={{ position: 'relative', display: 'inline-block' }}>
        {numSelected > 0 && (
            <div
                style={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    color: 'rgba(0, 0, 0, 0.54)',
                    top: '-7px',
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                    overflow: 'visible',
                    textAlign: 'center',
                }}
            >
                {numSelected}
            </div>
        )}
        {children}
    </div>
);
export const rowIdContext = React.createContext<number | string | null>(null);

const range = n => Array.from({ length: n }, (value, key) => key);

export type RenderListArgumentsWithBulkSelection = RenderListArguments & {
    onRowSelectBulk?: Required<RenderListArguments>['onRowSelect'];
    single?: boolean;
    isBulkSelectableRecord?: (data: {}) => boolean;
    renderAtRowEnd?: (
        r: RenderListArguments,
        record: { id: any }, // tslint:disable-line
    ) => React.ReactElement<{}>[];
};

export const renderTableHead = (
    r: RenderListArgumentsWithBulkSelection,
    classes: Classes = {},
    styles: Styles = {},
    columnsToAppendAtEnd: number = 0,
    sorting: 'ENABLED' | 'DISABLED' = 'ENABLED',
) => {
    const rowCount = r.ids.length;
    const numSelectedInCurrentPage = r.selectedData
        ? Object.keys(r.selectedData).filter(id => r.ids.includes(id)).length
        : 0;
    const someSelectedInDifferentPage =
        r.selectedData && Object.entries(r.selectedData).some(([id, d]) => !r.ids.includes(id));
    const numSelected = r.selectedData ? Object.keys(r.selectedData).length : 0;
    const allRowsSelected =
        r.selectedData &&
        (() => {
            const eligableRows = r.ids.filter(id => !r.isBulkSelectableRecord || r.isBulkSelectableRecord(r.data[id]));
            return eligableRows.length > 0 && !eligableRows.some(id => !r.selectedData[id]);
        })();

    const onSelectAllClick = () => {
        if (r.onRowSelectBulk) {
            if (numSelected === 0) {
                r.onRowSelectBulk(
                    r.ids.flatMap(id => {
                        const rec = r.data[id];
                        if (!r.isBulkSelectableRecord || r.isBulkSelectableRecord(rec)) {
                            return [rec];
                        }
                        return [];
                    }),
                    r.data,
                );
            } else {
                r.onRowSelectBulk([], r.data);
            }
        }
    };
    const addAllInPageToSelectionClick = () => {
        if (r.onRowSelectBulk) {
            r.onRowSelectBulk(
                [
                    ...Object.values(r.selectedData),
                    ...r.ids.flatMap(id => {
                        if (r.selectedData[id]) {
                            return [];
                        }
                        const rec = r.data[id];
                        if (!r.isBulkSelectableRecord || r.isBulkSelectableRecord(rec)) {
                            return [rec];
                        }
                        return [];
                    }),
                ],
                {
                    ...r.data,
                    ...r.selectedData,
                },
            );
        }
    };
    const removeCurrentRowsFromSelection = () => {
        if (r.onRowSelectBulk) {
            r.onRowSelectBulk(Object.values(r.selectedData).filter(e => !r.ids.includes(e.id)), {
                ...r.data,
                ...r.selectedData,
            });
        }
    };
    const clearAllSelections = () => {
        if (r.onRowSelectBulk) {
            r.onRowSelectBulk([], r.data);
        }
    };
    const currentSortOrder =
        r.currentSort && r.currentSort.order ? (r.currentSort.order.toLowerCase() as 'asc' | 'desc') : undefined;
    const currentSortField = r.currentSort && r.currentSort.field;
    const createSortHandler = (property: string) => e => {
        e.preventDefault();
        e.stopPropagation();
        r.setSort(property);
    };
    const arCurrPage = !r.single && someSelectedInDifferentPage && (
        <Checkbox
            indeterminate={numSelectedInCurrentPage > 0 && !allRowsSelected}
            checked={allRowsSelected}
            onChange={() => {
                if (numSelectedInCurrentPage === 0) {
                    addAllInPageToSelectionClick();
                } else {
                    removeCurrentRowsFromSelection();
                }
            }}
            inputProps={{
                'aria-label':
                    numSelectedInCurrentPage === 0
                        ? 'add results to existing selection'
                        : 'remove items on current page from existing selection',
            }}
        />
    );
    const addRemoveAllNoOtherPageData = !someSelectedInDifferentPage && (
        <Checkbox
            style={{ visibility: r.single ? 'hidden' : undefined }}
            indeterminate={numSelected > 0 && numSelectedInCurrentPage < rowCount}
            // change below to operate only on current page
            checked={allRowsSelected}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all' }}
        />
    );
    const clearAllSelectionsFromAllPages = !r.single && someSelectedInDifferentPage && (
        <SelCount numSelected={numSelected}>
            <Tooltip title={`Clear entire selection of ${numSelected}`}>
                <IconButton aria-label={`Clear entire selection of ${numSelected}`} onClick={clearAllSelections}>
                    <Clear />
                </IconButton>
            </Tooltip>
        </SelCount>
    );
    return (
        <TableHead>
            <TableRow>
                {r.onRowSelectBulk && (
                    <TableCell padding="checkbox" className={classes.headerCell}>
                        <div style={{ whiteSpace: 'nowrap' }}>
                            {arCurrPage}
                            {addRemoveAllNoOtherPageData}
                            {clearAllSelectionsFromAllPages}
                        </div>
                    </TableCell>
                )}
                {r.fields.map((field, i) => {
                    const needsLeftPadding = !r.onRowSelectBulk && i === 0;

                    if (field.props.sortable === false || sorting === 'DISABLED') {
                        return (
                            <TableCell
                                className={classes.headerCell}
                                classes={{
                                    root: classes.cell,
                                }}
                                padding="none"
                                style={needsLeftPadding ? { paddingLeft: '1em', ...styles.cell } : styles.cell}
                                key={field.props.source}
                            >
                                {field.props.label}
                            </TableCell>
                        );
                    }
                    return (
                        <TableCell
                            className={classes.headerCell}
                            classes={{
                                root: classes.cell,
                            }}
                            padding="none"
                            style={needsLeftPadding ? { paddingLeft: '1em', ...styles.cell } : styles.cell}
                            key={field.props.source}
                            sortDirection={
                                r.currentSort && r.currentSort.field === field.props.source ? currentSortOrder : false
                            }
                        >
                            <Tooltip title="Sort" enterDelay={300}>
                                <TableSortLabel
                                    active={currentSortField ? currentSortField === field.props.source : undefined}
                                    direction={currentSortOrder}
                                    onClick={createSortHandler(field.props.source)}
                                >
                                    {field.props.label}
                                </TableSortLabel>
                            </Tooltip>
                        </TableCell>
                    );
                })}
                {range(columnsToAppendAtEnd).map(i => (
                    <Themed>
                        {({ theme }) => (
                            <TableCell
                                className={classes.headerCell}
                                classes={{
                                    root: classes.cell,
                                }}
                                padding="none"
                                key={`atend-${i}`}
                            >
                                <span className="casetivity-off-screen">
                                    {i === columnsToAppendAtEnd - 1 ? 'Select Row Action' : 'Action'}
                                </span>
                            </TableCell>
                        )}
                    </Themed>
                ))}
            </TableRow>
        </TableHead>
    );
};

const getRowEnds = (r: RenderListArgumentsWithBulkSelection) => {
    return r.ids.map((id, i) => {
        const record = r.data[id];
        const navButtonElem = (
            <TableCell key={`rowEnd nav ${id} ${i}`} padding="none">
                <WithViewConfig>
                    {({ viewConfig }) => {
                        const displayName = viewConfig.entities[r.resource].displayName || r.resource;
                        return (
                            <TabNavRowButton
                                key="navButton"
                                IconComponent={r.SelectIconComponent}
                                iconButtonProps={{
                                    'aria-label': `Select ${displayName}${record.title ? `, ${record.title}` : ''}`,
                                }}
                            />
                        );
                    }}
                </WithViewConfig>
            </TableCell>
        );
        return r.renderAtRowEnd && record
            ? [
                  ...r.renderAtRowEnd(r, record).map((elem, i) => (
                      <TableCell key={`rowEnd ${i}`} padding="none">
                          {elem}
                      </TableCell>
                  )),
                  navButtonElem,
              ]
            : [navButtonElem];
    });
};

export const TabNavRowButton: React.ComponentType<{
    iconButtonProps: IconButtonProps;
    IconComponent?: React.ComponentType<{}>;
}> = withStyles((theme: Theme) =>
    createStyles({
        iconButtonRoot: {
            /*
            position: 'absolute',
            opacity: 0,
            height: 0,
            width: 0,
            right: 0,
            top: 0,
            zIndex: -1000,
            '&:focus': {
                opacity: 1,
                height: 'unset',
                width: 'unset',
                zIndex: 1,
            },
            */
        },
        iconRoot: {
            color: theme.palette.primary.main,
            /*
            '-moz-transform': 'scaleX(-1)',
            '-o-transform': 'scaleX(-1)',
            '-webkit-transform': 'scaleX(-1)',
            transform: 'scaleX(-1)',
            filter: 'FlipH',
            '-ms-filter': 'FlipH',
            */
        },
    }),
)(
    ({
        classes,
        iconButtonProps,
        IconComponent = Go,
    }: {
        classes: { iconRoot: string; iconButtonRoot: string };
        iconButtonProps: IconButtonProps;
        IconComponent?: React.ComponentType<any>;
    }) => {
        return (
            <IconButton className={classes.iconButtonRoot} {...iconButtonProps}>
                <IconComponent className={classes.iconRoot} />
            </IconButton>
        );
    },
);

interface Classes {
    root?: string;
    cell?: string;
    headerCell?: string;
}
interface Styles {
    root?: React.CSSProperties;
    cell?: React.CSSProperties;
}
const getRenderer = (classes: Classes, styles: Styles) => (r: RenderListArgumentsWithBulkSelection) => {
    const isSelected = id => r.selectedData && r.selectedData[id];
    const renderFields = id => {
        return r.fields.map((f, i) => {
            const needsLeftPadding = !r.onRowSelectBulk && i === 0;
            const fieldProps = {
                record: r.data[id],
                basePath: f.props.basePath || r.basePath,
                resource: r.resource,
                source: adjustLinkedXToLinkedEntity(f.props.source),
            };
            return (
                <TableCell
                    classes={{
                        root: classes.cell,
                    }}
                    key={`${f.props.source}_${i}`}
                    padding="none"
                    style={{
                        ...(needsLeftPadding ? { paddingLeft: '1em', ...styles.cell } : styles.cell),
                        position: 'relative',
                    }}
                    // numeric here
                >
                    {React.cloneElement(f, fieldProps)}
                    {/* i === r.fields.length - 1 ? (
                        <TabNavRowButton
                            iconButtonProps={{
                                'aria-label': `Select record ${i + 1}${
                                    fieldProps.record.title ? `: "${fieldProps.record.title}"` : ''
                                }`,
                            }}
                        />
                        ) : null*/}
                </TableCell>
            );
        });
    };
    const rowEnds = r.noClick
        ? r.ids.map((id, i) => [
              <TableCell key={`rowEnd spacer ${id} ${i}`} padding="none">
                  <div style={{ height: 48 }} />
              </TableCell>,
          ])
        : getRowEnds(r);
    const extraColumnsNeeded = rowEnds.reduce((prev, curr) => (curr.length > prev ? curr.length : prev), 0);
    const { 'aria-label': label, ...tableAriaProps } = r.ariaProps as { 'aria-label'?: string };
    return (
        <OverriddenRedirectProvider
            onRowSelect={r.onRowSelect}
            resource={r.resource}
            ids={r.ids}
            data={r.data}
            render={onIndexesSelected => (
                <div className={classes.root} style={styles.root}>
                    {r.resultHeadingText &&
                        (typeof r.resultHeadingText === 'string' ? (
                            <h2 style={{ marginTop: '0.5em', marginBottom: '0.5em' }}>{r.resultHeadingText}</h2>
                        ) : (
                            r.resultHeadingText
                        ))}
                    <Table {...tableAriaProps}>
                        <caption className="casetivity-off-screen">{label}</caption>
                        {renderTableHead(
                            r,
                            classes,
                            styles,
                            extraColumnsNeeded,
                            r.disableSorting ? 'DISABLED' : 'ENABLED',
                        )}
                        <TableBody>
                            {r.ids.map((id, i) => {
                                return (
                                    <TableRow
                                        hover={!r.noClick}
                                        onClick={
                                            r.noClick
                                                ? undefined
                                                : e => {
                                                      e.preventDefault();
                                                      e.stopPropagation();
                                                      onIndexesSelected([i]);
                                                  }
                                        }
                                        // hover={r.bodyOptions && r.bodyOptions.showRowHover}
                                        // onClick={event => this.handleClick(event, n.id)}
                                        // role="checkbox"
                                        // aria-checked={isSelected}
                                        tabIndex={-1}
                                        key={id}
                                        // selected={isSelected}
                                    >
                                        <rowIdContext.Provider value={id}>
                                            {r.onRowSelectBulk && (
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={!!isSelected(id)}
                                                        disabled={
                                                            r.isBulkSelectableRecord &&
                                                            !r.isBulkSelectableRecord(r.data[id])
                                                        }
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            e.preventDefault();
                                                            if (r.selectedData && r.onRowSelectBulk) {
                                                                const otherData = Object.entries(
                                                                    r.selectedData,
                                                                ).flatMap(([sid, sdata]) =>
                                                                    `${id}` !== `${sid}` ? [sdata] : [],
                                                                );
                                                                if (r.selectedData[id]) {
                                                                    r.onRowSelectBulk(otherData, r.data);
                                                                } else {
                                                                    if (r.single) {
                                                                        r.onRowSelectBulk([r.data[id]], r.data);
                                                                    } else {
                                                                        r.onRowSelectBulk(
                                                                            [...otherData, r.data[id]],
                                                                            r.data,
                                                                        );
                                                                    }
                                                                }
                                                            }
                                                        }}
                                                        inputProps={{ 'aria-label': 'select row' }}
                                                    />
                                                </TableCell>
                                            )}
                                            {renderFields(id)}
                                            {rowEnds[i]}
                                        </rowIdContext.Provider>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
            )}
        />
    );
};

export default getRenderer;
