import * as React from 'react';
import { SFC } from 'react';
import compose from 'recompose/compose';
import pure from 'recompose/pure';
import { connect } from 'react-redux';
import withProps from 'recompose/withProps';
import EditForm from './EditForm';
import CustomEdit from './Edit';
import { EditActions as EditActionsImpl } from '../viewActions/ActionsWithOverrides';
import { getAccessLevelForEntity } from '../utils/viewConfigUtils';
import { BaseEditProps } from './interfaces';
import { RootState } from '../../../reducers/rootReducer';
import { fromNullable } from 'fp-ts/lib/Option';
import { taskClosed } from 'bpm/components/TaskDetail/TaskForm/getInitialValues';

/*
    Props are copied in inside Edit/EditForm, so this is will fail when we pass incomplete props below.
    Cast as any to tempfix. Eventually we should refactor.
*/
const EditActions = EditActionsImpl as any; // tslint:disable-line

const GenericEditComponent: SFC<
    {
        viewConfig: RootState['viewConfig'];
        taskId: any; // tslint:disable-line
        optInWriteable: any; // tslint:disable-line
    } & BaseEditProps
> = props => (
    <CustomEdit
        actions={<EditActions accessLevel={getAccessLevelForEntity(props.viewConfig, props.resource) as number} />}
        {...props}
        key={fromNullable(props.match)
            .mapNullable(m => m.params)
            .mapNullable(p => p.id)
            .getOrElse(undefined)}
    >
        <EditForm
            match={props.match}
            location={props.location}
            viewConfig={props.viewConfig}
            redirect={props.redirect}
            taskId={props.taskId}
            optInWriteable={props.optInWriteable}
            // viewName={props.viewName}
            {...props}
        />
    </CustomEdit>
);
const enhance = connect(({ viewConfig }: RootState) => ({ viewConfig }));
const GenericEdit: React.ComponentType<any> = enhance(GenericEditComponent); // tslint:disable-line

export default GenericEdit;

/*
    EditView with 'optInWriteable' - a list of fields that can be written to, bound to a task-form
    (all other fields are disabled, hence optin writeable)

*/
const addOptInWriteableProps = compose(
    pure,
    connect((state: RootState, ownProps: { taskId: string }) => ({
        taskFormDefinition: state.taskForms[ownProps.taskId],
        taskIsClosed: taskClosed(state, ownProps),
    })),
    withProps(props => ({
        optInWriteable:
            !props.taskIsClosed &&
            props.taskFormDefinition &&
            props.taskFormDefinition.fields &&
            props.taskFormDefinition.fields.filter(field => field.params && field.params.entityField)[0]
                ? // optInWriteable is provided only if a linked-Entity field is given.
                  // otherwise, all fields are writeable.
                  // Right now linked fields belong to current-task-form, but can parameterize if more use cases.
                  Object.assign(
                      {},
                      ...props.taskFormDefinition.fields
                          .filter(field => field.params && field.params.entityField)
                          .map(field => ({ [field.params.entityField]: field })),
                  )
                : null,
    })),
    enhance,
);
interface GenericEditLinkedToTaskFormProps extends BaseEditProps {
    taskId: string | number;
}
export const GenericEditLinkedToTaskForm: SFC<GenericEditLinkedToTaskFormProps> = addOptInWriteableProps(
    GenericEditComponent,
);
