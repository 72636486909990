import React, { useMemo } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import Info from '@material-ui/icons/InfoOutlined';
import { Card } from '@material-ui/core';
import { createSelector } from 'reselect';
import { GlobalAlert } from 'global-alerts/definitions';

interface GlobalAlertsBannerComponentProps {
    globalAlerts: string[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        card: {
            backgroundColor: theme.palette.background.paper,
            padding: '.5em 1em',
            borderRadius: 0,
            borderLeft: '4px solid ' + theme.palette.error.main,
            borderBottom: '1px solid grey',
            display: 'flex',
            // justifyContent: 'space-between',
        },
        heading: {
            marginRight: '1em',
            marginLeft: '1em',
            flex: 1,
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
    }),
);
const emptyArray = [];
const makeAlertsSelector: () => (state: RootState, globalAlertIds: string[]) => GlobalAlert[] = () =>
    createSelector(
        (state: RootState) => state.globalAlerts.globalAlertsById,
        (state: RootState, globalAlertIds?: string[]): string[] => globalAlertIds || emptyArray,
        (globalAlertsById, globalAlertIds) => {
            return globalAlertIds.map(id => globalAlertsById[id]);
        },
    );
const GlobalAlertsBannerComponent = (props: GlobalAlertsBannerComponentProps) => {
    const classes = useStyles(props);
    const alertsSelector = useMemo(makeAlertsSelector, []);
    const alerts = useSelector((state: RootState) => alertsSelector(state, props.globalAlerts));
    return (
        <div className={classes.root}>
            {alerts.map((alert, i) => (
                <Card key={i} className={classes.card}>
                    <div style={{ height: '100%', width: '.5em', backgroundColor: 'red' }} />
                    <Info />
                    <Typography className={classes.heading}>{alert.message}</Typography>
                </Card>
            ))}
        </div>
    );
};
export default GlobalAlertsBannerComponent;
