import React from 'react';
import { ReactElement, Component, ReactNode } from 'react';
import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';
import { Card, CardActions, Collapse, CardContent, Tab, Tabs, IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Warning from '@material-ui/icons/Warning';
import { withStyles, Theme } from '@material-ui/core/styles';
import toClass from 'recompose/toClass';
import classnames from 'classnames';
import { Subtract } from 'utility-types';
import { connect } from 'react-redux';
// import './rwt.css';
import { Tabs as RWTabs, Tab as RWTab, TabPanel as RWTabPanel, TabList as RWTabList } from 'react-web-tabs';
import { registerHeightObserver, unregisterHeightObserver } from 'element-height-observer/dist/index';
import { getView, viewHasTabs, getTabsTitlesFromView } from '../utils/viewConfigUtils';
import { RootState } from '../../../reducers/rootReducer';
import tabRecentAction from '../../../actions/tabRecent';
import EnhancedRGridWithVis from './EnhancedRGridTabbable';
import BackTo from '../button/BackTo';
import TogglePrintMode from '../../TogglePrintMode';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import Forward from '@material-ui/icons/Forward';
import { Link } from 'react-router-dom';
import { userAgent } from 'userAgent';
import { Cancelable } from 'lodash';

const getMyLink = ({ entityType, id, isShow }: { entityType: string; id: string; isShow: boolean }) => props => (
    <Link to={`/${entityType}/${id}${isShow ? '/show' : ''}`} {...props} />
);

type InTabProviderState =
    | {
          _type: 'ACTIVE_TAB';
          status: 'INITIALIZED';
      }
    | {
          _type: 'NON_ACTIVE_TAB';
          status: 'INITIALIZED' | 'NOT_INITIALIZED';
      };
type NotInTabContext = {
    _type: 'NOT_IN_A_TAB';
};
type InTabContext = InTabProviderState;

type TabContext = InTabContext | NotInTabContext;
export const activeTabContext = React.createContext<TabContext>({ _type: 'NOT_IN_A_TAB' });

interface TabProviderProps {
    isActive: boolean;
}

interface TabProviderComponentProps extends TabProviderProps {}
class TabProviderComponent extends React.Component<TabProviderComponentProps, InTabProviderState> {
    static getDerivedStateFromProps(
        nextProps: TabProviderComponentProps,
        prevState: InTabProviderState,
    ): InTabProviderState {
        if (nextProps.isActive && prevState._type !== 'ACTIVE_TAB') {
            return {
                _type: 'ACTIVE_TAB',
                status: 'INITIALIZED',
            };
        }
        if (!nextProps.isActive && prevState._type === 'ACTIVE_TAB') {
            return {
                _type: 'NON_ACTIVE_TAB',
                status: 'INITIALIZED',
            };
        }
        return null;
    }
    constructor(props: TabProviderComponentProps) {
        super(props);
        this.state = this.props.isActive
            ? {
                  _type: 'ACTIVE_TAB',
                  status: 'INITIALIZED',
              }
            : {
                  _type: 'NON_ACTIVE_TAB',
                  status: 'NOT_INITIALIZED',
              };
    }

    render() {
        return <activeTabContext.Provider value={this.state}>{this.props.children}</activeTabContext.Provider>;
    }
}
export const TabProvider: React.ComponentType<TabProviderProps> = TabProviderComponent;

const isIos: boolean = userAgent.isIos();

enum TabOptions {
    SECTIONS = 1,
    HORIZONTAL = 2,
    VERTICAL = 3,
}

interface PanesProps {
    paneWidth?: string | number;
    panes: React.ReactNode;
}
const Panes: React.ComponentType<PanesProps> = toClass(props => (
    <div style={{ height: '100%', width: props.paneWidth || '100%' }}>{props.panes}</div>
));

interface RWTLSprops {
    defaultTab: string | null;
    panes: React.ReactNode;
    tabs: React.ReactNode;
    onChange: Function;
    width: Breakpoint;
}

export const tabHasError = (tabsWithErrors: string[], tabKey: string) => (tabKey, tabIx) =>
    (tabsWithErrors || []).includes(tabKey) && tabKey !== tabIx;

export class RWTabListS extends Component<RWTLSprops, { windowOffsetTop: number; recalcKey: number }> {
    div: HTMLDivElement | null;
    thisRect: ClientRect | DOMRect;
    tabList: HTMLElement | null = null;
    tabListRect: ClientRect | DOMRect;
    panes: any;
    panesClientHeight: number;
    handleScroll: ((event: any) => void) & Cancelable;
    _isMounted: boolean = false;
    debouncedOnScrollFn: (() => void) & Cancelable;

    state = {
        windowOffsetTop: 0,
        recalcKey: 0,
    };
    componentDidMount() {
        if (!isIos) {
            this._isMounted = true;
            this.handleScroll = throttle(event => {
                this.getDomInfo();
                let top = window.pageYOffset || document!.documentElement!.scrollTop;
                if (this._isMounted) {
                    this.setState(state =>
                        top !== state.windowOffsetTop
                            ? {
                                  windowOffsetTop: top,
                                  ...state,
                              }
                            : state,
                    );
                }
            }, 10);
            window.addEventListener('scroll', this.handleScroll);
            this.getDomInfo();
            this.debouncedOnScrollFn = debounce(() => {
                this.getDomInfo();
                if (this._isMounted) {
                    this.setState(prev => ({
                        recalcKey: prev.recalcKey + 1,
                        ...prev,
                    }));
                }
            }, 75);
            registerHeightObserver(document.getElementsByTagName('body')[0], this.debouncedOnScrollFn);
            (document.getElementsByClassName('element-height-observer-iframe')[0] as any).title =
                'element-height-observer-iframe'; //tslint:disable-line
        }
    }

    componentWillUnmount() {
        if (!isIos) {
            this._isMounted = false;
            if (this.debouncedOnScrollFn) {
                this.debouncedOnScrollFn.cancel();
            }
            if (this.handleScroll) {
                this.handleScroll.cancel();
            }
            window.removeEventListener('scroll', this.handleScroll);
            unregisterHeightObserver(document.getElementsByTagName('body')[0]);
        }
    }

    getDomInfo = () => {
        if (this._isMounted) {
            if (this.div) {
                this.thisRect = this.div.getBoundingClientRect();
            }
            if (this.tabList) {
                this.tabListRect = this.tabList.getBoundingClientRect();
            }
            if (this.panes) {
                this.panesClientHeight = this.panes.clientHeight;
            }
        }
    };
    getTabItemWidth = () => (this.props.width === 'xl' ? 220 : 200);
    getStyle() {
        if (this.thisRect) {
            /*
            useful for debugging

            console.log( // tslint:disable-line
                `${this.thisRect.top} < ${this.state.windowOffsetTop} &&
                ${this.tabListRect.height} < ${window.innerHeight} &&
                ${this.thisRect.top} + ${this.panesClientHeight} >
                ${this.state.windowOffsetTop} + ${window.innerHeight}
                `
            );
            */
            if (
                this.thisRect.top < this.state.windowOffsetTop && // scrolled into
                this.tabListRect.height < window.innerHeight && // tab list will fit into the window
                this.thisRect.top + this.panesClientHeight > this.state.windowOffsetTop + window.innerHeight
                // ^ bottom of content is on the screen
            ) {
                return {
                    position: 'fixed',
                    top: 0,
                    backgroundColor: 'white',
                    zIndex: 1004,
                    margin: 0,
                    width: /* this.tabListRect.width */ this.getTabItemWidth(),
                };
            }
        }
        return { width: this.getTabItemWidth() /* '100%' */ };
    }
    render() {
        const { onChange, panes, tabs, defaultTab } = this.props;
        return (
            <div
                ref={ref => {
                    this.div = ref;
                }}
                style={{ width: '100%' }}
            >
                <RWTabs defaultTab={defaultTab} vertical={true} onChange={onChange}>
                    <div
                        style={
                            this.tabListRect && this.getStyle().position === 'fixed'
                                ? {
                                      minWidth: this.tabListRect.width,
                                      height: this.tabListRect.height,
                                  }
                                : {}
                        }
                    >
                        <div
                            ref={ref => {
                                this.tabList = ref;
                            }}
                        >
                            <RWTabList style={this.getStyle()} children={tabs} />
                        </div>
                    </div>
                    <div
                        ref={ref => (this.panes = ref)}
                        style={{ overflowY: 'hidden', overflowX: 'auto', width: '100%' }}
                    >
                        <Panes panes={panes} />
                    </div>
                </RWTabs>
            </div>
        );
    }
}

const styles = theme => ({
    actions: {
        cursor: 'pointer',
        display: 'flex',
        paddingLeft: 0,
        paddingTop: 0,
        paddingBottom: 0,
        backgroundColor: 'rgba(13, 71, 161, 0.1)',
    },
    error: {
        color: theme.palette.error.main,
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        marginLeft: 'auto',
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
});
interface TabbableFormProps {
    isPopover?: boolean;
    recentTabResource: RootState['recentTab']['resource'];
    recentTabKey: RootState['recentTab']['tabKey'];
    recentTabRecordId: RootState['recentTab']['id'];
    classes: any; // tslint:disable-line
    title: string | ReactNode;
    actions: ReactElement<{ save: (redirect?: string) => () => void }>;
    viewName: string;
    viewConfig: RootState['viewConfig'];
    toolbar?: ReactElement<{
        handleSubmitWithRedirect: (redirect: string) => Function;
        invalid: boolean;
        submitOnEnter: boolean;
    }>;
    theme: Theme;
    contentContainerStyle?: {};
    useTabs?: boolean;
    baseFields?: ReactElement<{}>[];
    fieldsByTab?: {};
    tabsWithErrors?: string[];
    width: Breakpoint;
    isLoading: boolean;
    printTemplates?: ReactNode;
    key?: number;
    tabRecent: (resource: string | null, viewType: string | null, tabKey: string | null, id: string | null) => void;
    record?: {
        id?: string;
        entityType?: string;
        title?: string;
    };
    printMode: boolean;
}
const getResource = (props: TabbableFormProps) => props.viewConfig.views[props.viewName].entity;
const getViewType = (props: TabbableFormProps) => props.viewConfig.views[props.viewName].viewType;

interface TabbableFormState {
    tabKey: string | null | undefined;
    tabs: TabOptions;
}
const getSavedTabKey = (nextProps: TabbableFormProps) => {
    return getResource(nextProps) === nextProps.recentTabResource &&
        nextProps.record &&
        nextProps.record.id === nextProps.recentTabRecordId &&
        nextProps.fieldsByTab &&
        nextProps.recentTabKey &&
        nextProps.fieldsByTab[nextProps.recentTabKey]
        ? nextProps.recentTabKey
        : null;
};
class TabbableFormComponent extends Component<TabbableFormProps, TabbableFormState> {
    static defaultProps = {
        baseFields: [],
        fieldsByTab: {},
        contentContainerStyle: { borderTop: 'solid 1px #e0e0e0' },
        tabsWithErrors: [],
        useTabs: true,
        activeField: null,
    };
    state = {
        tabKey: undefined,
        tabs: TabOptions.VERTICAL,
    } as TabbableFormState;
    static getDerivedStateFromProps(nextProps: TabbableFormProps, prevState: TabbableFormState) {
        const savedTabKey = getSavedTabKey(nextProps);
        if (savedTabKey !== null && prevState.tabKey === null && savedTabKey !== prevState.tabKey) {
            return {
                ...prevState,
                tabKey: savedTabKey,
            };
        }
        return null;
    }
    componentDidMount() {
        this.setState({
            tabKey: null,
        });
    }
    getResource = (props = this.props) => getResource(props);
    getViewType = (props = this.props) => getViewType(props);
    handleChangeTab = tabKey => {
        this.setState(state => (tabKey !== state.tabKey ? { ...state, tabKey } : state));
    };

    componentWillUnmount() {
        if (this.props.fieldsByTab && this.props.record) {
            this.props.tabRecent(
                this.getResource(),
                this.getViewType() as string,
                this.state.tabKey,
                this.props.record.id || null,
            );
        }
    }
    render() {
        const {
            contentContainerStyle,
            theme,
            toolbar,
            viewConfig,
            viewName,
            actions,
            fieldsByTab = {},
            width,
            classes,
            isLoading,
            printMode,
            record,
            isPopover,
            key, // provided to rerender the entire form if necessary (just provide a different key)
        } = this.props;
        const view = getView(viewConfig, viewName);
        const isShow = view.viewType === 'SHOW';
        const pageHasTabs = viewHasTabs(view);
        const tabTitles = pageHasTabs ? getTabsTitlesFromView(view) : [];
        // const MyLink = props => <Link to={`/${record.entityType}/${record.id}${isShow ? '/show' : ''}`} {...props} />;
        const { entityType, id } = record;
        const MyLink = getMyLink({ entityType, id, isShow });
        return (
            <Card style={{ opacity: isLoading ? 0.8 : 1, padding: '1%' }} key={key}>
                {record && isPopover ? (
                    <IconButton aria-label="Navigate to record" component={MyLink} style={{ float: 'right' }}>
                        <Forward color="primary" />
                    </IconButton>
                ) : null}
                <div style={{ float: 'right', marginTop: '.5em' }}>
                    <BackTo />
                </div>
                {isShow && !isPopover && (
                    <div style={{ float: 'right' }}>
                        <TogglePrintMode />
                    </div>
                )}
                <b>{this.props.title}</b>
                {actions}
                <div style={{ clear: 'both' }} />
                <form autoComplete="off" style={{ marginBottom: '1%' }}>
                    <div>
                        <EnhancedRGridWithVis
                            isShow={isShow}
                            lastRowDropdownsFlipUp={!pageHasTabs}
                            fields={this.props.baseFields}
                        />
                    </div>
                </form>
                {(width === 'xs' || this.state.tabs === TabOptions.SECTIONS || printMode) &&
                    tabTitles.map((tabKey, tabIx) => (
                        <div
                            key={tabIx + (printMode ? 'printMode' : '')}
                            style={{
                                borderTop: tabIx === 0 ? '1px solid rgba(0,0,0,0.4)' : undefined,
                                borderBottom: '1px solid rgba(0,0,0,0.4)',
                                padding: 0,
                            }}
                        >
                            <CardActions
                                className={classes.actions}
                                disableSpacing={true}
                                onClick={() => {
                                    if (!printMode) {
                                        this.handleChangeTab(this.state.tabKey === tabKey ? null : tabKey);
                                    }
                                }}
                            >
                                <span
                                    style={{
                                        flex: 1,
                                        paddingLeft: '1em',
                                        paddingBottom: '5px',
                                        fontSize: 18,
                                        fontWeight: 'bold',
                                        ...(tabHasError(this.props.tabsWithErrors, this.state.tabKey)(tabKey, tabIx)
                                            ? { color: theme.palette.error.main }
                                            : {}),
                                    }}
                                >
                                    {view.tabs![tabKey].label}
                                </span>
                                <IconButton
                                    className={classnames(classes.expand, {
                                        [classes.expandOpen]: printMode || this.state.tabKey === tabKey,
                                    })}
                                    aria-expanded={printMode || this.state.tabKey === tabKey}
                                    aria-label="Show more"
                                >
                                    <ExpandMoreIcon />
                                </IconButton>
                            </CardActions>
                            <Collapse
                                in={printMode || this.state.tabKey === tabKey}
                                timeout={0}
                                mountOnEnter={true}
                                unmountOnExit={false}
                                style={printMode || this.state.tabKey === tabKey ? { overflow: 'unset' } : undefined}
                            >
                                <CardContent>
                                    <TabProvider isActive={this.state.tabKey === tabKey}>
                                        <EnhancedRGridWithVis
                                            key={printMode ? 'printMode' : 'foo'}
                                            isShow={isShow}
                                            fields={fieldsByTab[tabKey]}
                                            lastRowDropdownsFlipUp={tabIx === tabTitles.length - 1}
                                        />
                                    </TabProvider>
                                </CardContent>
                            </Collapse>
                        </div>
                    ))}
                {!printMode && width !== 'xs' && this.state.tabs === TabOptions.HORIZONTAL && pageHasTabs && (
                    <Tabs
                        value={this.state.tabKey}
                        style={contentContainerStyle}
                        onChange={(_, value) => this.handleChangeTab(value)}
                    >
                        {tabTitles.map((tabKey, tabIx) => (
                            <Tab
                                key={`Tab-${tabKey}`}
                                label={
                                    <span
                                        style={
                                            tabHasError(this.props.tabsWithErrors, this.state.tabKey)(tabKey, tabIx)
                                                ? { color: theme.palette.error.main }
                                                : undefined
                                        }
                                    >
                                        {view.tabs![tabKey].label}
                                    </span>
                                }
                                value={tabIx}
                            />
                        ))}
                    </Tabs>
                )}
                {width !== 'xs' && this.state.tabs === TabOptions.HORIZONTAL && pageHasTabs && (
                    <div className={classes.formStyle}>
                        <EnhancedRGridWithVis
                            isShow={isShow}
                            lastRowDropdownsFlipUp={true}
                            fields={fieldsByTab[getTabsTitlesFromView(view)[this.state.tabKey || 0]]}
                        />
                    </div>
                )}
                {!printMode && width !== 'xs' && this.state.tabs === TabOptions.VERTICAL && pageHasTabs && (
                    <RWTabListS
                        defaultTab={this.state.tabKey}
                        width={width}
                        onChange={value => this.handleChangeTab(value)}
                        panes={tabTitles.map((tabKey, tabIx) => {
                            return (
                                <RWTabPanel
                                    tabId={tabKey}
                                    key={tabIx}
                                    style={{ width: '100%' }}
                                    render={() => {
                                        const isActiveTab =
                                            this.state.tabKey === tabKey || (tabIx === 0 && !this.state.tabKey);
                                        return (
                                            <TabProvider isActive={isActiveTab}>
                                                <div className={classes.formStyle} style={{ width: '100%' }}>
                                                    {view.tabs![tabKey].label && (
                                                        <h2 id={`header:${tabKey}`}>{view.tabs![tabKey].label}</h2>
                                                    )}
                                                    <EnhancedRGridWithVis
                                                        // NEW NOTE: We are doing this in the individual problematic fields now.
                                                        // i.e. LongTextInput needs to be remounted when hidden/displayed for some reason
                                                        // remount when visible due to display errors.
                                                        // key={isActiveTab ? 'SELECTED' : 'NOT_SELECTED'}
                                                        isShow={isShow}
                                                        lastRowDropdownsFlipUp={true}
                                                        fields={fieldsByTab[tabKey]}
                                                    />
                                                </div>
                                            </TabProvider>
                                        );
                                    }}
                                />
                            );
                        })}
                        tabs={tabTitles.map((tabKey, tabIx) => {
                            const label = view.tabs![tabKey].label;
                            const hasError = tabHasError(this.props.tabsWithErrors, this.state.tabKey)(tabKey, tabIx);
                            return (
                                <RWTab
                                    style={{
                                        ...(hasError ? { color: theme.palette.error.main } : {}),
                                        textAlign: 'left',
                                    }}
                                    key={tabIx}
                                    tabFor={tabKey}
                                >
                                    {label}
                                    {hasError ? (
                                        <span style={{ position: 'relative' }}>
                                            <Warning
                                                className={hasError ? classes.error : undefined}
                                                style={{ marginLeft: 10, position: 'absolute', bottom: 0 }}
                                            />
                                        </span>
                                    ) : null}
                                </RWTab>
                            );
                        })}
                    />
                )}
                <div style={{ height: '1em' }} />
                {toolbar}
            </Card>
        );
    }
}

type TabbableFormType = React.SFC<
    Subtract<TabbableFormProps, { classes: TabbableFormProps['classes']; theme: TabbableFormProps['theme'] }>
>;
const TabbableForm: TabbableFormType = withStyles(styles, { withTheme: true })(
    TabbableFormComponent,
) as TabbableFormType;

const mapStateToProps = (state: RootState) => {
    return {
        recentTabResource: state.recentTab.resource,
        recentTabKey: state.recentTab.tabKey,
        recentTabRecordId: state.recentTab.id,
        printMode: state.printMode,
    };
};

export default connect(
    mapStateToProps,
    { tabRecent: tabRecentAction },
)(TabbableForm);
