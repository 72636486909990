import React, { useCallback } from 'react';
import { saveAs } from 'file-saver';
import { Button } from '@material-ui/core';
import Save from '@material-ui/icons/Save';

interface JsonDownloadProps {
    json: {} | [];
    title?: string;
}
const JsonDownload: React.SFC<JsonDownloadProps> = ({ json, title }) => {
    const handleClick = useCallback(() => {
        let fileName = prompt('Filename:', '');
        if (fileName.endsWith('.json')) {
            fileName = fileName.slice(0, '.json'.length * -1);
        }
        const stringState = JSON.stringify(json, null, 2);
        saveAs(new Blob([stringState], { type: 'application/json' }), `${fileName}.json`);
    }, [json]);
    return (
        <Button variant="contained" color="primary" onClick={handleClick}>
            {title || 'Export JSON'}&nbsp;
            <Save />
        </Button>
    );
};
export default JsonDownload;
