import ViewConfig from 'reducers/ViewConfigType';
import {
    getAllFieldEntriesFromView,
    isFieldViewField,
    getAdjustedFieldSource,
    getDataTypeForFieldExpr,
} from 'components/generics/utils/viewConfigUtils';

export default (viewConfig: ViewConfig, viewName: string, allFieldsExpected: string[]) => {
    return getAllFieldEntriesFromView(viewConfig, viewName).reduce(
        (prev, [viewFieldInstanceKey, vf]) => {
            // RefManys don't map onto fields in our form data, so we shouldn't include those.
            // We may have to find an alternative way of controlling visibilities for list data.
            if (
                isFieldViewField(vf) &&
                getDataTypeForFieldExpr(viewConfig, vf.entity, vf.field, 'POP_LAST') !== 'REFMANY'
            ) {
                const getAdjusted = getAdjustedFieldSource(viewConfig)(viewConfig.views[viewName]);
                const backingField = getAdjusted(vf);
                if (!prev[backingField]) {
                    prev[backingField] = [];
                }
                prev[backingField].push(getAdjusted(vf, viewFieldInstanceKey));
            }
            return prev;
        },
        allFieldsExpected.reduce((prev, curr) => {
            prev[curr] = [curr]; // used to be = [];. Had to add current because fields NOT IN THE VIEW (fields from "getAdhocFieldsForView")
            // are not included as widgets in phase 2 above, and therefore were never getting added.
            return prev;
        }, {}),
    );
};
