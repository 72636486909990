import { getDataWithExpansion } from '../../../../../../components/generics/form/getFormInitial';
import ViewConfig from '../../../../../../reducers/ViewConfigType';
import {
    isRefOneField,
    getRefEntityName,
    isValueSetField,
} from '../../../../../../components/generics/utils/viewConfigUtils';
import { mapOption } from 'fp-ts/lib/Array';
import { none, some } from 'fp-ts/lib/Option';
import { tryCatch } from 'fp-ts/lib/Either';
import { identity } from 'fp-ts/lib/function';
import { unflatten } from 'flat';

const updateDataOnRefChange = (
    viewConfig: ViewConfig,
    baseEntityType: string,
    entities: {},
    fieldValuePairs: [string, unknown][],
    expressionAndReadOnlyFields: string[], // these are what we need to lookup based on reference changes
) => {
    const expansionPaths = mapOption(fieldValuePairs, ([f, value]) =>
        tryCatch(() => {
            const expansionPath = f.endsWith('Id') ? f.slice(0, -2) : f;
            if (
                isRefOneField(viewConfig, baseEntityType, expansionPath, 'TRAVERSE_PATH') ||
                isValueSetField(viewConfig, baseEntityType, expansionPath, 'TRAVERSE_PATH')
            ) {
                const refEntity = getRefEntityName(viewConfig, baseEntityType, expansionPath, 'TRAVERSE_PATH');
                if (refEntity) {
                    return some({
                        expansionPath,
                        refEntity,
                        value: value as string,
                    });
                }
            }
            return none;
        }).fold(e => {
            return none;
        }, identity),
    );

    const dataAtPaths = expansionPaths.flatMap(({ expansionPath, refEntity, value }) => {
        const remainingAfterBase = (paths: string[]) =>
            paths.flatMap(path => {
                if (path.startsWith(expansionPath) && path[expansionPath.length] === '.') {
                    return [path.slice(expansionPath.length + 1)];
                }
                return [];
            });

        const v = unflatten(
            Object.assign(
                {},
                getDataWithExpansion('FLAT')(
                    viewConfig,
                    refEntity,
                    value,
                    remainingAfterBase(expressionAndReadOnlyFields).filter(p => {
                        try {
                            return (
                                !isRefOneField(viewConfig, refEntity, p, 'TRAVERSE_PATH') &&
                                !isValueSetField(viewConfig, refEntity, p, 'TRAVERSE_PATH')
                            );
                        } catch (e) {
                            return true;
                        }
                    }),
                    entities,
                ),
                ...fieldValuePairs.flatMap(([path, _v]) => {
                    const [remainingPath] = remainingAfterBase([path]);
                    if (remainingPath) {
                        return [{ [remainingPath]: _v }];
                    }
                    return [];
                }),
            ),
        );
        if (refEntity === 'Concept' && Object.keys(v).length === 0) {
            // we never are actually overwriting this data, so never null it out (like we do in the lines below).
            // only update fields like '.group' or '.code' to whatever is needed on the backend.
            return [];
        }
        return [[expansionPath, Object.keys(v).length > 0 ? v : null] as [string, null | {}]];
    });
    return dataAtPaths;
};

export default updateDataOnRefChange;
