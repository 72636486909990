import React from 'react';

interface DeferredSpinnerProps {
    renderSpinner?: () => JSX.Element | null;
    renderWhileDeferred?: () => JSX.Element | string | null;
}
interface DeferredSpinnerState {
    displayMessage: boolean;
}
class DeferredSpinner extends React.Component<DeferredSpinnerProps, DeferredSpinnerState> {
    public timer: NodeJS.Timeout;
    public _isMounted: boolean;
    constructor(props: DeferredSpinnerProps) {
        super(props);
        this.enableMessage = this.enableMessage.bind(this);

        this.state = {
            displayMessage: false,
        };
        this._isMounted = true;
        this.timer = setTimeout(this.enableMessage, 200);
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearTimeout(this.timer);
    }

    enableMessage() {
        if (this._isMounted) {
            this.setState({ displayMessage: true });
        }
    }

    render() {
        const { displayMessage } = this.state;

        if (!displayMessage) {
            if (this.props.renderWhileDeferred) {
                return this.props.renderWhileDeferred();
            }
            return null;
        }
        if (this.props.renderSpinner) {
            return this.props.renderSpinner();
        }

        return (
            <div style={{ minHeight: 65 }}>
                <div className="spinnyloader">Loading...</div>
            </div>
        );
    }
}

export default DeferredSpinner;
