import React from 'react';
import PasswordResetPage from './Page';
import { parse } from 'querystring';
import { PasswordResetTextField, PasswordResetStatus, renderActions } from './shared';
import { Formik } from 'formik';
import * as config from 'config';
import { CardContent, Typography } from '@material-ui/core';

const getKey = (location: Location) => {
    return parse(location.search.slice(1)).key;
};

interface FormData {
    password1: string;
    password2: string;
}

const SelfReset = (props: { location: Location }) => {
    return (
        <PasswordResetPage
            showTitle={true}
            backdrop="dark"
            renderForm={({ attemptAction }) => state => {
                return (
                    <Formik
                        initialValues={{ password1: '', password2: '' }}
                        validate={(values: FormData) => {
                            let errors: Partial<FormData> = {};
                            if (!values.password1) {
                                errors.password1 = 'Required';
                            }
                            if (!values.password2) {
                                errors.password2 = 'Required';
                            }
                            if (values.password1 && values.password2 && values.password1 !== values.password2) {
                                errors.password2 = 'Password must match';
                            }
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            attemptAction({
                                lazyRequest: () =>
                                    fetch(`${config.BACKEND_BASE_URL}api/account/forgot-password/finish`, {
                                        method: 'POST',
                                        body: JSON.stringify({
                                            key: getKey(props.location),
                                            newPassword: values.password1,
                                        }),
                                        credentials: 'same-origin',
                                        headers: new Headers({
                                            Accept: 'application/json',
                                            'Content-Type': 'application/json',
                                        }),
                                    }),
                            });
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            /* and other goodies */
                        }) => (
                            <form autoComplete="off" onSubmit={handleSubmit}>
                                <CardContent>
                                    <Typography variant="h5" component="h2">
                                        Reset Password
                                    </Typography>
                                    <br />
                                    <PasswordResetTextField
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        value={values.password1}
                                        name="password1"
                                        label="New Password"
                                        error={errors.password1}
                                        touched={touched.password1}
                                        type="password"
                                    />
                                    <PasswordResetTextField
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        value={values.password2}
                                        name="password2"
                                        label="Please re-enter password"
                                        error={errors.password2}
                                        touched={touched.password2}
                                        type="password"
                                    />
                                    <PasswordResetStatus state={state} />
                                </CardContent>
                                {renderActions(state)}
                            </form>
                        )}
                    </Formik>
                );
            }}
        />
    );
};

export default SelfReset;
