import React, { useMemo } from 'react';
import ViewConfig, { ViewField, EntityField, FieldViewField } from 'reducers/ViewConfigType';
import { useForm, useFormContext, FormContext, Controller } from 'react-hook-form';
import * as widgetTypes from 'components/generics/utils/widgetTypes';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { createSelector } from 'reselect';
import { fromPredicate } from 'fp-ts/lib/Either';
import { RootState } from 'reducers/rootReducer';
import {
    isRefOneField,
    getDataTypeForFieldExpr,
    isValueSetField,
    getRefEntityName,
    getLabelForFieldExpr,
} from 'components/generics/utils/viewConfigUtils';
import { useSelector } from 'react-redux';
import { ListboxComponent } from '../virtualize';
import { validDataTypeWidgetCombinations } from 'viewConfigValidation/validationRules';
import useViewConfig from 'util/hooks/useViewConfig';

interface FieldPathProps {
    defaultValue?: string;
    resource: string;
    depth: number;
}

const getFieldsForDepth = (depth: number, resource: string, viewConfig: ViewConfig): string[] => {
    if (depth === 0) {
        return [];
    }
    const fieldsAtCurrentLevel = Object.values(viewConfig.entities[resource].fields);
    if (depth === 1) {
        return fieldsAtCurrentLevel.map(f => f.name);
    }
    return fieldsAtCurrentLevel
        .flatMap((field): string[] => {
            return fromPredicate(
                (f: EntityField) =>
                    isRefOneField(viewConfig, resource, f.name, 'TRAVERSE_PATH') ||
                    isValueSetField(viewConfig, resource, f.name, 'TRAVERSE_PATH'),
                f => [f.name],
            )(field).map(f => [
                f.name,
                ...getFieldsForDepth(depth - 1, f.relatedEntity, viewConfig).map(fname => `${field.name}.${fname}`),
            ]).value;
        })
        .sort((a, b) => {
            const numOfDotsInA = a.split('.').length - 1;
            const numOfDotsInB = b.split('.').length - 1;
            if (numOfDotsInA < numOfDotsInB) {
                return -1;
            }
            if (numOfDotsInB < numOfDotsInA) {
                return 1;
            }
            if (a < b) {
                return -1;
            }
            if (b < a) {
                return 1;
            }
            return 0;
        });
};

const createFieldPathsWithDepthSelector = () =>
    createSelector(
        (state: RootState, props: FieldPathProps) => props.depth,
        (state: RootState, props: FieldPathProps) => props.resource,
        (state: RootState, props: FieldPathProps) => state.viewConfig,
        getFieldsForDepth,
    );

const FieldPath2: React.SFC<FieldPathProps> = props => {
    const { register, unregister, setValue } = useFormContext();
    const fieldPathsWithDepthSelector = useMemo(createFieldPathsWithDepthSelector, []);
    const initialFieldPaths = useSelector((state: RootState) => fieldPathsWithDepthSelector(state, props));
    const handleChange = (e, targetName) => {
        setValue(targetName ? targetName : e.target.name, (e && e.target && e.target.value) || e);
    };

    React.useEffect(() => {
        register({ name: 'fieldPath' } /*, { required: 'true' } */);
        return () => {
            unregister('fieldPath');
        };
    }, []); // eslint-disable-line

    return (
        <Autocomplete
            options={initialFieldPaths}
            defaultValue={props.defaultValue}
            autoHighlight
            onChange={(e, value) => handleChange(value, 'fieldPath')}
            getOptionLabel={option => option}
            renderOption={option => option}
            // filterOptions={filterOptions}
            ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
            renderInput={params => (
                <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    label="Field Path"
                    margin="normal"
                    fullWidth
                    inputProps={{
                        ...params.inputProps,
                        draggable: false,
                        autoComplete: 'disabled',
                    }}
                />
            )}
        />
    );
};

interface WidgetTypesProps {
    rootEntity: string;
}
const WidgetTypes: React.SFC<WidgetTypesProps> = props => {
    const { register, watch } = useFormContext<FormData>();
    const fieldPath = watch('fieldPath');

    const viewConfig = useSelector((state: RootState) => state.viewConfig);
    const widgetTypeCodes = useMemo(() => {
        if (!fieldPath) {
            return Object.values(widgetTypes);
        }
        try {
            const dt = getDataTypeForFieldExpr(viewConfig, props.rootEntity, fieldPath, 'TRAVERSE_PATH');
            if (dt) {
                return validDataTypeWidgetCombinations[dt];
            }
        } catch (e) {
            Object.values(widgetTypes);
        }
    }, [fieldPath, props.rootEntity, viewConfig]);
    return (
        <select name="widgetType" ref={register({ required: true })}>
            {widgetTypeCodes.map(v => {
                return <option value={v}>{v}</option>;
            })}
        </select>
    );
};

const Label: React.SFC<{
    defaultValue?: string;
    rootEntity: string;
}> = props => {
    const methods = useFormContext<FormData>();
    const fieldPath = methods.watch('fieldPath');
    const viewConfig = useViewConfig();
    const defaultValue = useMemo(() => {
        if (props.defaultValue) {
            return props.defaultValue;
        }
        if (fieldPath) {
            try {
                const backingLabel = getLabelForFieldExpr(viewConfig, props.rootEntity, fieldPath, 'TRAVERSE_PATH');
                return backingLabel;
            } catch (e) {
                console.error(e);
            }
        }
        return '';
    }, [fieldPath, viewConfig, props.rootEntity, props.defaultValue]);

    return (
        <span>
            <Controller
                key={defaultValue}
                InputLabelProps={{ shrink: true }}
                label="Label"
                defaultValue={defaultValue}
                as={TextField}
                name="label"
                fullWidth
                control={methods.control}
            />
        </span>
    );
};

interface FormData {
    widgetType: (typeof widgetTypes)[keyof typeof widgetTypes];
    fieldPath: string;
    label: string;
    config?: string;
}

interface AddFieldProps {
    rootEntity: string;
    onAdd: (field: ViewField) => void;
    initialValues?: Partial<FormData>;
}
const AddField: React.SFC<AddFieldProps> = props => {
    const methods = useForm<FormData>({
        defaultValues: props.initialValues || {
            fieldPath: '',
        },
    });
    const viewConfig = useSelector((state: RootState) => state.viewConfig);
    const onSubmit = (data: FormData) => {
        console.log('submitting', data);
        props.onAdd({
            widgetType: data.widgetType,
            field: data.fieldPath,
            entity: data.fieldPath.includes('.')
                ? getRefEntityName(
                      viewConfig,
                      props.rootEntity,
                      data.fieldPath.slice(0, data.fieldPath.lastIndexOf('.')),
                      'TRAVERSE_PATH',
                  )
                : props.rootEntity,
            label: data.label,
            config: data.config || undefined,
        } as FieldViewField);
    };

    return (
        <div>
            <FormContext {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    {/* register your input into the hook by invoking the "register" function */}
                    <FieldPath2
                        defaultValue={props.initialValues && props.initialValues['fieldPath']}
                        resource={props.rootEntity}
                        depth={3}
                    />
                    {methods.errors.fieldPath && <span>This field is required</span>}
                    {/* include validation with required or other standard HTML validation rules */}
                    <WidgetTypes rootEntity={props.rootEntity} />
                    {/* errors will return when field validation fails  */}
                    {methods.errors.widgetType && <span>This field is required</span>}
                    <div style={{ marginBottom: '10px' }} />
                    <Label
                        rootEntity={props.rootEntity}
                        defaultValue={props.initialValues && props.initialValues['label']}
                    />
                    <div style={{ marginBottom: '10px' }} />
                    <Controller
                        InputLabelProps={{ shrink: true }}
                        label="Config"
                        as={TextField}
                        defaultValue={props.initialValues && props.initialValues['config']}
                        multiline={true}
                        fullWidth
                        name="config"
                        control={methods.control}
                    />
                    <div style={{ marginBottom: '5px' }} />
                    <input type="submit" value="Create Field" />
                </form>
            </FormContext>
        </div>
    );
};

export default AddField;
