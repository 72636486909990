import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withWidth } from '@material-ui/core';
import mapProps from 'recompose/mapProps';
import { RootState } from 'reducers/rootReducer';

export type Width = 'xl' | 'lg' | 'md' | 'sm' | 'xs';
export default compose(
    connect((state: RootState) => ({
        taskDrawerOpen: state.bpm.taskDrawer,
    })),
    withWidth({
        initialWidth: 'md',
    }),
    mapProps(props => {
        const { taskDrawerOpen, width } = props;
        const createProps = (nw: Width) => ({ ...props, width: nw });
        if (taskDrawerOpen) {
            if (width === 'xl') {
                return createProps('lg');
            }
            if (width === 'lg') {
                return createProps('md');
            }
            if (width === 'md') {
                return createProps('sm');
            }
            if (width === 'sm') {
                return createProps('xs');
            }
            if (width === 'xs') {
                return props;
            }
            return createProps('xs');
        }
        return props;
    }),
);
