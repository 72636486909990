import React from 'react';
import { Card, CardHeader } from '@material-ui/core';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import { saveAs } from 'file-saver';
import SsgAppBarMobile from '../SsgAppBarMobile';
import { dropzoneStyle } from './BulkUpload';
import { storageController } from 'storage';

const config = require('../../config.js');

export const UploadFileComponent = ({ title, dropMessage, resourceUrl }) => {
    const onDrop = files => {
        const data = new FormData();
        data.append('file', files[0]);
        const request = new Request(`${config.API_URL}${resourceUrl}`, {
            method: 'POST',
            credentials: 'same-origin',
            body: data,
            headers: new Headers({
                Authorization: `Bearer ${storageController.getToken()}`,
                Cookie: `${window.document.cookie}`,
            }),
        });
        fetch(request).then(response => {
            if (response.status < 200 || response.status >= 300) {
                // TODO: show error somewhere, notification?
                console.log(response.statusText);
            }
            const disposition = response.headers.get('Content-Disposition');
            if (disposition && disposition.indexOf('inline') !== -1) {
                let fileName;
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    fileName = matches[1].replace(/['"]/g, '');
                }
                response.blob().then(blob => {
                    saveAs(blob, fileName);
                });
            } else {
                response.json().then(data => {
                    console.log(data);
                    // TODO: show success somewhere, notification?
                });
            }
        });
    };
    return (
        <div>
            {<SsgAppBarMobile title={title} />}
            <Card style={{ marginBottom: '1%' }}>
                <CardHeader title={title} />
                <div style={{ padding: '15px' }}>
                    <Dropzone multiple={false} onDrop={onDrop}>
                        {({ getRootProps, getInputProps }) => (
                            <section>
                                <div {...getRootProps()} style={dropzoneStyle}>
                                    <input {...getInputProps({ 'aria-label': 'Upload File' })} />
                                    <p>Drag {dropMessage}</p>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </div>
            </Card>
        </div>
    );
};

const ConnectedUVCC = connect((state, ownProps) => Object.assign({}, ownProps, { viewConfig: state.viewConfig }))(
    UploadFileComponent,
);
export const UploadFile = ConnectedUVCC;
