import isPojo from '../isPojo';

const deepSubmit = (submitValues: {}, insertPartialUpdateAtBase: boolean | 'auto' = 'auto') => {
    const _deepSubmit = (data: {}, insertPartialUpdate: boolean) => {
        const referencesAtThisLevel = Object.assign(
            {},
            ...Object.keys(data)
                .filter(k => k.endsWith('Id'))
                .map(k => ({ [k.slice(0, -2)]: true })),
        );
        const newObjectEntries = Object.entries(data).flatMap(([k, v]) => {
            if (isPojo(v)) {
                const res = _deepSubmit(v, true);
                if (Object.keys(res).length === 0) {
                    return [];
                }
                if (referencesAtThisLevel[k]) {
                    return [
                        {
                            [k]: {
                                ..._deepSubmit(v, true),
                                id: data[`${k}Id`],
                            },
                        },
                    ];
                }
                return [{ [k]: res }];
            }
            if (typeof v === 'undefined') {
                return [];
            }
            return [{ [k]: v }];
        });

        return Object.assign(
            insertPartialUpdate && newObjectEntries.length > 0 ? { partialUpdate: true } : {},
            ...newObjectEntries,
        );
    };

    const preRes = _deepSubmit(submitValues, false);

    if (
        insertPartialUpdateAtBase === true ||
        (insertPartialUpdateAtBase === 'auto' && Object.values(preRes).find(isPojo))
    ) {
        preRes.partialUpdate = true;
    }
    return preRes;
};
export default deepSubmit;
