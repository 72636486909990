import { CRUD_DELETE, CRUD_DELETE_FAILURE, CRUD_DELETE_SUCCESS } from './constants';
import { createCustomAction } from 'typesafe-actions';
import { ErrorsCbs } from '../types';
import { Subtract } from 'utility-types';

interface DeleteParams {
    resource: string;
    id: string;
    restUrl?: string; // for overriding the default from viewConfig.
    cb?: () => void;
    // redirectTo: 'edit', 'show', 'list'
    errorsCbs?: ErrorsCbs;
}
export const crudDelete = createCustomAction(CRUD_DELETE, type => {
    const defaultArgs: Partial<DeleteParams> = {
        // redirectTo: 'edit',
        errorsCbs: {},
    };
    return (args: DeleteParams) => {
        const mergedArgs: DeleteParams = {
            ...defaultArgs,
            ...args,
        };
        return {
            type,
            payload: mergedArgs,
        };
    };
});
export const crudDeleteFailure = createCustomAction(CRUD_DELETE_FAILURE, type => {
    return (error: Error, requestPayload: DeleteParams) => {
        return {
            type,
            payload: error,
            requestPayload,
        };
    };
});

export const crudDeleteSuccess = createCustomAction(CRUD_DELETE_SUCCESS, type => {
    return (id: string, requestPayload: Subtract<DeleteParams, Pick<DeleteParams, 'cb' | 'errorsCbs'>>) => {
        return {
            type,
            payload: {
                id,
            },
            requestPayload,
        };
    };
});
