import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Subtract } from 'utility-types';
import onlyUpdateForKeys from 'recompose/onlyUpdateForKeys';
import { Dialog, DialogContent, DialogContentText } from '@material-ui/core';
import MergeView from '../genericMerge';
import { initialize } from 'redux-form';
import ViewConfig from '../../../reducers/ViewConfigType';
import getConflictingEdits from './getConflictingEdits';
import { RootState } from 'reducers/rootReducer';

const DialogOUFK = onlyUpdateForKeys(['open'])(Dialog);

// These props will be subtracted from original component type
interface WrappedComponentProps {
    hasMergeConflict: boolean;
    mergeNew: {} | null;
    mergeOld: {} | null;
    clearMergeConflict: () => void;
}

export const withDirtyFieldMergeConflict = <P extends WrappedComponentProps>(BaseComponent: React.ComponentType<P>) => {
    // These props will be added to original component type
    interface Props {
        currentFormValues: {};
        form: string;
        initializeValues: (form: string, values: {}, keepDirty: boolean) => void;
        initialValues?: {};
        valuesForRegisteredFieldsOnly?: {};

        resource: string;
        viewName: string;
        viewConfig: ViewConfig;
        record: { id: number };
        activeField?: string;
    }
    interface State {
        hasMergeConflict: boolean;
        mergeOld: {} | null;
        mergeNew: {} | null;
        match: {
            params: {
                id: string | null;
                id2: string | null;
            };
        };
    }
    const enhance = compose(
        connect(
            ({ form }: RootState, props: { form: string }) => {
                const activeField = (form![props.form] || {}).active;
                return {
                    activeField,
                    currentFormValues: !activeField && (form![props.form] || {}).values, // don't keep passing new objects while the user is inputting // used to merge with new values initialization
                };
            },
            {
                initializeValues: initialize,
            },
        ),
    );

    type WrappingProps = Subtract<P, WrappedComponentProps> & Props;
    return enhance(
        class WithState extends React.Component<WrappingProps, State> {
            // Enhance component name for debugging and React-Dev-Tools
            static readonly WrappedComponent = BaseComponent;
            static displayName = `withMergeConflict(${BaseComponent.name})`;
            state: State = {
                hasMergeConflict: false,
                mergeOld: null,
                mergeNew: null,
                match: {
                    params: {
                        id: this.props.record && this.props.record.id ? `${this.props.record.id}` : null,
                        id2: this.props.record && this.props.record.id ? `${this.props.record.id}` : null,
                    },
                },
            };

            shouldComponentUpdate(nextProps: WrappingProps) {
                return !nextProps.activeField;
            }

            componentWillReceiveProps(nextProps: WrappingProps) {
                if (this.props.record && nextProps.record.id && this.props.record.id !== nextProps.record.id) {
                    this.setState({
                        match: {
                            params: {
                                id: `${nextProps.record.id}`,
                                id2: `${nextProps.record.id}`,
                            },
                        },
                    });
                }
                const conflictingEdits =
                    this.props.initialValues && nextProps.initialValues && nextProps.valuesForRegisteredFieldsOnly
                        ? getConflictingEdits(
                              this.props.initialValues,
                              nextProps.initialValues,
                              nextProps.valuesForRegisteredFieldsOnly,
                          )
                        : null;
                if (conflictingEdits) {
                    this.setState({
                        hasMergeConflict: true,
                        mergeNew: conflictingEdits.formValues,
                        mergeOld: conflictingEdits.newInitialValuesConflictedWith,
                    });
                }
            }

            handleClearMergeConflict = () => {
                this.setState({
                    hasMergeConflict: false,
                    mergeOld: null,
                    mergeNew: null,
                });
            };

            render() {
                const { hasMergeConflict, mergeNew, mergeOld } = this.state;
                const { currentFormValues } = this.props;

                return (
                    <div>
                        <BaseComponent
                            {...(this.props as any)} // tslint:disable-line
                            hasMergeConflict={hasMergeConflict}
                            mergeNew={mergeNew}
                            mergeOld={mergeOld}
                            clearMergeConflict={this.handleClearMergeConflict}
                        />
                        <DialogOUFK
                            TransitionProps={
                                {
                                    // https://github.com/dequelabs/axe-core/issues/146
                                    role: 'presentation',
                                } as any
                            }
                            maxWidth={false}
                            fullWidth={true}
                            open={this.state.hasMergeConflict}
                        >
                            <DialogContent>
                                <DialogContentText style={{ textAlign: 'center' }}>
                                    {'The record you are working on has been modified by another user.'}
                                    {'Your submission, and the last saved record are shown below.'}
                                    <br />
                                    <b>{'Changes you have made have not been saved.'}</b>
                                    {' Please make the necessary changes and resubmit.'}
                                </DialogContentText>
                                <MergeView
                                    wrapInCard={false}
                                    record1={this.state.mergeOld}
                                    record2={this.state.mergeNew}
                                    record={this.state.mergeOld}
                                    resource={this.props.resource}
                                    viewConfig={this.props.viewConfig}
                                    viewName={this.props.viewName}
                                    basePath={`/${this.props.resource}`}
                                    match={this.state.match}
                                    primaryRecordTitle="Changes made by another user while you were editing."
                                    altRecordTitle="Your current work."
                                    merge={values => {
                                        this.setState(
                                            {
                                                hasMergeConflict: false,
                                                mergeNew: null,
                                                mergeOld: null,
                                            },
                                            () =>
                                                this.props.initializeValues(
                                                    this.props.form,
                                                    {
                                                        ...(currentFormValues as Props['currentFormValues']),
                                                        ...values,
                                                    },
                                                    false,
                                                ),
                                        );
                                    }}
                                    includeRefManys={false}
                                    onlyFields={this.state.mergeNew && Object.keys(this.state.mergeNew)}
                                />
                            </DialogContent>
                        </DialogOUFK>
                    </div>
                );
            }
        },
    );
};
