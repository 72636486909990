import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { MuiThemeProvider, createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'; // v1.x
import { Card, Typography } from '@material-ui/core';
import getV1InitialTheme from 'components/layouts/getMuiV1Theme';
import { getPrimaryColor, getSecondaryColor, getErrorColor } from 'components/layouts/getThemeColors';
import memoizeOne from 'memoize-one';
import { RootState } from 'reducers/rootReducer';
import AttemptRequest, { AttemptRequestPropsInternal } from 'components/AttemptRequest';
import { Helmet } from 'react-helmet';

const styles = (theme: Theme) =>
    createStyles({
        applicationHeader: {
            color: theme.palette.getContrastText(theme.palette.primary.main),
        },
        backdrop: {
            backgroundColor: theme.palette.primary.dark,
        },
        plainBackdrop: {
            backgroundColor: theme.palette.background.default,
        },
        error: {
            color: theme.palette.error.main,
        },
        main: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: 'calc(100vh - 100px)',
            alignItems: 'center',
            justifyContent: 'center',
        },
        card: {
            minWidth: 300,
        },
        avatar: {
            margin: '1em',
            textAlign: 'center',
        },
        form: {
            padding: '0 1em 1em 1em',
        },
        input: {
            marginTop: 16,
            width: '100%',
            display: 'flex',
        },
    });

interface PasswordResetProps {
    showTitle: boolean;
    backdrop: 'dark' | 'plain';
    renderForm: AttemptRequestPropsInternal['renderer'];
}

const mapStateToProps = (state: RootState) => ({
    displayTitle: (state.basicInfo && state.basicInfo.title) || 'Casetivity',
    appColor: state.basicInfo && state.basicInfo.applicationColor,
});

interface PasswordResetComponentProps
    extends PasswordResetProps,
        ReturnType<typeof mapStateToProps>,
        WithStyles<typeof styles> {}
class PasswordResetComponent extends React.Component<PasswordResetComponentProps> {
    _getV1InitialTheme = memoizeOne(getV1InitialTheme);
    getLoginV1Theme = () =>
        this._getV1InitialTheme(
            getPrimaryColor(this.props.appColor),
            getSecondaryColor(),
            getErrorColor(this.props.appColor),
        );
    componentDidMount() {
        const theme = this.getLoginV1Theme();
        document.body.style.backgroundColor = theme.palette.primary.main;
    }
    render() {
        const { classes, renderForm, showTitle } = this.props;
        return (
            <MuiThemeProvider theme={this.getLoginV1Theme()}>
                <Helmet>
                    <title>Reset Password</title>
                </Helmet>
                <div role="main">
                    {showTitle !== false ? (
                        <div style={{ padding: '1em' }}>
                            <Typography className={classes.applicationHeader} variant="h4" component="h1">
                                {this.props.displayTitle}
                            </Typography>
                        </div>
                    ) : null}
                    <div className={classes.main}>
                        <Card className={classes.card}>
                            <AttemptRequest type="internal" renderer={renderForm} />
                        </Card>
                    </div>
                </div>
            </MuiThemeProvider>
        );
    }
}

const PasswordReset: React.ComponentType<PasswordResetProps> = compose(
    connect(mapStateToProps),
    withStyles(styles),
)(PasswordResetComponent);
export default PasswordReset;
