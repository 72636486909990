import React from 'react';
import menuItems from './menu-generator';
import { MenuProps } from './MenuInterface';
import { ListItem, ListItemText } from '@material-ui/core';

const VerticalMenu: React.SFC<MenuProps & { style?: React.CSSProperties }> = ({ style, children, ...rest }) => (
    <div style={style}>
        {rest.viewConfig && (
            <ListItem button={true} id={'home-link'} onClick={() => rest.pushAction('/')}>
                <ListItemText inset={true} primary={rest.viewConfig.application.title} />
            </ListItem>
        )}
        {menuItems({ ...rest, mobile: true })}
    </div>
);

export default VerticalMenu;
