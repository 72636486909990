import { put, takeEvery, call } from 'redux-saga/effects';
import { fetchJson } from '../../../util/fetchUtils';
import { BACKEND_BASE_URL } from '../../../config';
import { taskEventType as taskEvent, taskEventCreator } from '../../actions/taskEvent';
import buildFetchOptions from '../../../sagas/util/buildFetchOptions';
import { mapToAppType, mapToApiTypePut } from '../../dataAdapters/network/rest/tasks/entities/task';

const tasksEndpoint = 'api/bpm/tasks';

export function* updateTask({ payload: { taskId, data } }) {
    try {
        const options = buildFetchOptions();
        options.method = 'PUT';
        options.body = JSON.stringify(mapToApiTypePut(data));
        const { status, body } = yield call(fetchJson, `${BACKEND_BASE_URL}${tasksEndpoint}/${taskId}`, options);
        if (status <= 200 && status < 300) {
            const task = JSON.parse(body);
            yield put({ type: taskEvent.updateSuccess, payload: mapToAppType(task) });
            yield put(taskEventCreator.getTask(taskId));
        } else {
            yield put({ type: taskEvent.updateFailure, status });
        }
    } catch (error) {
        console.error(error);
        yield put({ type: taskEvent.updateFailure, error });
    }
}

export default function* getTasksSaga() {
    yield takeEvery(taskEvent.update, function*(action) {
        yield call(updateTask, action);
    });
}
