import React from 'react';
import { checkLastRequestTime, checkLastRequestTimeSuccess } from '../actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { Dialog, CardHeader, Card, CardContent, CardActions, Button } from '@material-ui/core';
import useLogout from 'auth/hooks/useLogout';

const NUMBER_OF_FAILURES_AFTER_WHICH_TO_ALERT = 4;

const OfflineAlert = () => {
    const dispatch = useDispatch();
    const logout = useLogout();
    const tooManyFailures = useSelector(
        (state: RootState) => state.reauth.successiveFailureCount >= NUMBER_OF_FAILURES_AFTER_WHICH_TO_ALERT,
    );
    const a401Occurred = useSelector((state: RootState) => state.reauth.a401Occurred);
    const isOnline = useSelector((state: RootState) => state.connectivityStatus);
    if (isOnline && (tooManyFailures || a401Occurred)) {
        return (
            <Dialog
                open={true}
                TransitionProps={
                    {
                        // https://github.com/dequelabs/axe-core/issues/146
                        role: 'presentation',
                    } as any
                }
            >
                <Card>
                    <CardHeader title={a401Occurred ? 'Session timed out.' : 'There appears to be a problem.'} />
                    <CardContent>
                        {a401Occurred
                            ? 'Your session has timed out. To avoid losing your work, log in using another window, then click "Attempt to recover session" below. Otherwise, click "Go to log in"'
                            : 'Attempt to reconnect, or ignore and continue:'}
                    </CardContent>
                    <CardActions>
                        <Button color="primary" variant="contained" onClick={() => dispatch(checkLastRequestTime())}>
                            Attempt to recover session
                        </Button>
                        {a401Occurred ? (
                            <Button color="primary" variant="contained" onClick={logout}>
                                Go to log in
                            </Button>
                        ) : null}
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => dispatch(checkLastRequestTimeSuccess())}
                        >
                            Close
                        </Button>
                    </CardActions>
                </Card>
            </Dialog>
        );
    }
    return null;
};

export default OfflineAlert;
