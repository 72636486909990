import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import compose from 'recompose/compose';
import { Link } from 'react-router-dom';
import { cancelTaskButton } from './cancel';
import { deleteTaskButton } from './delete';
import { reopenTaskButton } from './reopen';
import Popup from '../../../../components/Popup';
import ViewTitle from 'components/generics/ViewTitle';
import UserIsSuper from 'components/UserIsSuper';
import {
    CardActions,
    Card,
    Button,
    CardContent,
    IconButton,
    // WithStyles, used to type props
    createStyles,
    Theme,
    withStyles,
} from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import Restore from '@material-ui/icons/Restore';
import { processPageRefreshContext } from 'bpm/components/ProcessDetail/ProcessPage';
import useHasMinimalHeader from 'bpm/hooks/useHasMinimalHeader';

const styles = ({ palette, spacing }: Theme) =>
    createStyles({
        title: {
            order: 0,
            fontSize: 22,
            marginTop: spacing(3),
            marginLeft: spacing(3),
            marginBottom: spacing(1),
        },
    });

const TaskHeader = ({ processId, title, children, taskId, task, redirect, isAdmin, classes, formDefinition }) => {
    const hasMinimalHeader = useHasMinimalHeader();
    if (hasMinimalHeader) {
        return <div>{title && <ViewTitle title={title} />}</div>;
    }
    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    justifyContent: 'space-between',
                    width: '100%',
                }}
            >
                {title && <ViewTitle title={title} />}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                        justifyContent: 'flex-end',
                    }}
                >
                    {task && !task.endDate && isAdmin && (
                        <Popup
                            renderToggler={({ openDialog }) => (
                                <Button color="primary" style={{ margin: '.5em', order: 2 }} onClick={openDialog()}>
                                    Cancel Task
                                </Button>
                            )}
                            renderDialogContent={({ closeDialog }) => (
                                <Card>
                                    <CardContent>Cancel task?</CardContent>
                                    <CardActions>
                                        <Button onClick={closeDialog}>Close</Button>
                                        {cancelTaskButton(taskId)(() =>
                                            redirect(processId ? `/processes/${processId}/tasks` : '/tasks'),
                                        )}
                                    </CardActions>
                                </Card>
                            )}
                        />
                    )}
                    {task && task.endDate && (
                        <UserIsSuper>
                            <Popup
                                renderToggler={({ openDialog }) => (
                                    <IconButton aria-label="reopen" onClick={openDialog()}>
                                        <Restore />
                                    </IconButton>
                                )}
                                renderDialogContent={({ closeDialog }) => (
                                    <Card>
                                        <CardContent>Reopen task?</CardContent>
                                        <CardActions>
                                            <Button onClick={closeDialog}>Close</Button>
                                            <processPageRefreshContext.Consumer>
                                                {({ refresh }) =>
                                                    reopenTaskButton(taskId)(() =>
                                                        !processId ? redirect('/tasks') : refresh(),
                                                    )
                                                }
                                            </processPageRefreshContext.Consumer>
                                        </CardActions>
                                    </Card>
                                )}
                            />
                        </UserIsSuper>
                    )}
                    {task && task.endDate && isAdmin && (
                        <Popup
                            renderToggler={({ openDialog }) => (
                                <IconButton aria-label="delete" onClick={openDialog()}>
                                    <Delete />
                                </IconButton>
                            )}
                            renderDialogContent={({ closeDialog }) => (
                                <Card>
                                    <CardContent>Delete task?</CardContent>
                                    <CardActions>
                                        <Button onClick={closeDialog}>Close</Button>
                                        {deleteTaskButton(taskId)(() =>
                                            redirect(processId ? `/processes/${processId}/tasks` : '/tasks'),
                                        )}
                                    </CardActions>
                                </Card>
                            )}
                        />
                    )}
                    <Button
                        color="primary"
                        style={{ margin: '.5em', order: 2 }}
                        component={props => <Link to={processId ? `/processes/${processId}` : '/tasks'} {...props} />}
                    >
                        Back to Case
                    </Button>
                </div>
            </div>
        </div>
    );
};

TaskHeader.defaultProps = {
    processId: null,
    title: '',
    children: undefined,
    formDefinition: null,
};

const enhance = compose(
    connect(
        null,
        {
            redirect: push,
        },
    ),
    withStyles(styles),
);

export default enhance(TaskHeader);
