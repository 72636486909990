import * as actions from './actions';
import { isActionOf } from 'typesafe-actions';
import { RootState } from 'reducers/rootReducer';
import { Epic } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { Services } from 'sideEffect/services';
import { filter, flatMap, catchError, map, withLatestFrom } from 'rxjs/operators';
import { of, concat, Observable } from 'rxjs';
import getValuesetsForFormAction from 'bpm/task-form/epic/getValuesetsForFormAction';

const getStartFormFlow: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) =>
    action$.pipe(
        filter(isActionOf(actions.getStartForm)),
        withLatestFrom(state$.pipe(map(state => state.viewConfig))),
        flatMap(
            ([action, viewConfig]): Observable<RootAction> =>
                services.getStartForm(action.payload.processDefinitionId).pipe(
                    flatMap(response =>
                        concat<RootAction>(
                            of(actions.getStartFormSuccess(action.payload, response)),
                            of(getValuesetsForFormAction(response, viewConfig)),
                        ),
                    ),
                    catchError(err => of(actions.getStartFormFailure(action.payload, err))),
                ),
        ),
    );
export default getStartFormFlow;
