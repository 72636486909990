import * as React from 'react';
const ThreeColumnRow = ({ children, style = {} }: { children: JSX.Element[]; style?: React.CSSProperties }) => (
    <div style={{ borderBottom: '1px dashed lightgrey', paddingTop: '1em', paddingBottom: '1em', ...style }}>
        <div style={{ width: '33%', float: 'left' }}> {children[0]}</div>
        <div style={{ width: '33%', float: 'left' }}> {children[1]}</div>
        <div style={{ width: '33%', float: 'left' }}> {children[2]}</div>
        <div style={{ clear: 'both' }} />
    </div>
);
export default ThreeColumnRow;
