import { DeepPartial } from 'redux';
import { createBrowserHistory } from 'history';
import { USER_LOGOUT } from 'actions/aor/types';
import getRootReducer, { RootState } from 'reducers/rootReducer';
import mapResourceToAdminProperty from 'util/mapResourceToAdminProperty';
import getResourcesFromViewConfig from 'util/getResourceListFromConfig';
import ViewConfig from 'reducers/ViewConfigType';

import { EntityValidations } from 'reducers/entityValidationsReducer';
import { SearchValidations } from 'reducers/searchValidationsReducer';
import { ViewEditableExps } from 'reducers/entityEditabilityReducer';
import { EntityVisibilityExps } from 'reducers/entityVisibilityReducer';
import { REPLACE_STATE, CLEAR_STORE, CLEAR_STORE_BUT_KEEP_LOCATION } from 'actions/constants';
import initializeConceptExps from 'viewConfigCalculations/ConceptAvailabilityExpressions/initialize';
import initializeActionButtonExps from 'viewConfigCalculations/actionButtonDisplayExpressions/initialize';
import initializeFilterExps from 'viewConfigCalculations/filterExpressions/initialize';
import storage from 'local-storage-fallback';
import { fromNullable } from 'fp-ts/lib/Option';
import mergeWithViewConfig from 'injectViews';
import { getVisibilityExps } from 'sagas/getEntityVisibilityExpsFromViewConfig';

export const getAnonViewConfig = () => {
    const b = fromNullable((window as any).CASETIVITY_ANON_VIEW_CONFIG)
        .map(JSON.parse)
        .map(mergeWithViewConfig)
        .toNullable();
    return b;
};

export const getInitialState = (): DeepPartial<RootState> | undefined => {
    const serializedVC = storage.getItem('viewconfig');
    let svc: ViewConfig | null = getAnonViewConfig();
    if (serializedVC) {
        svc = JSON.parse(serializedVC);
    }

    const serializedEntityValidations = storage.getItem('entityValidations');
    let entityVals: EntityValidations = {};
    if (serializedEntityValidations) {
        entityVals = JSON.parse(serializedEntityValidations);
    }

    const serializedSearchValidations = storage.getItem('searchValidations');
    let searchVals: SearchValidations = {};
    if (serializedSearchValidations) {
        searchVals = JSON.parse(serializedSearchValidations);
    }

    const serializedEntityVisibility = storage.getItem('entityVisibility');
    const entityVis: EntityVisibilityExps = serializedEntityVisibility
        ? JSON.parse(serializedEntityVisibility)
        : svc
        ? getVisibilityExps(svc)
        : {};

    const serializedEditabilityExpressions = storage.getItem('entityEditability');
    let entityEditabilityExps: ViewEditableExps = {};
    if (serializedEditabilityExpressions) {
        entityEditabilityExps = JSON.parse(serializedEditabilityExpressions);
    }

    let reloadedResources: {}[] = [];
    if (svc) {
        reloadedResources = getResourcesFromViewConfig(svc);
    }
    return svc
        ? {
              viewConfig: svc || {},
              admin: {
                  resources: Object.assign({}, ...reloadedResources.map(r => mapResourceToAdminProperty(r))),
              },
              entityValidations: entityVals,
              entityEditability: entityEditabilityExps,
              searchValidations: searchVals,
              entityVisibility: entityVis,
              entityConceptExps: initializeConceptExps(),
              actionButtonExps: initializeActionButtonExps(),
              viewItemFilterExps: initializeFilterExps(),
          }
        : undefined;
};
export const initialS: DeepPartial<RootState> | undefined = getInitialState();

export const routerHistory = createBrowserHistory({
    basename: (window as any).CASETIVITY_SERVER_CONTEXT_ROOT || '/',
});
const rootReducer = getRootReducer(routerHistory);

const resettableAppReducer = (state: RootState, action) =>
    rootReducer(
        action.type === CLEAR_STORE_BUT_KEEP_LOCATION
            ? {
                  router: state.router,
                  basicInfo: state.basicInfo,
                  form: state.form,
                  globalAlerts: state.globalAlerts,
                  viewConfig: getAnonViewConfig() || {},
              }
            : action.type === USER_LOGOUT || action.type === CLEAR_STORE
            ? { basicInfo: state.basicInfo, viewConfig: getAnonViewConfig() || {} }
            : action.type === REPLACE_STATE
            ? { ...action.payload, debugMode: state.debugMode, viewStack: state.viewStack }
            : state,
        action,
    );

export default resettableAppReducer;
