import { combineReducers, Reducer } from 'redux'; // eslint-disable-line import/no-extraneous-dependencies
import task, { TaskState } from './task';
import submitting from './submitting';
import list, { createList, TaskListState } from './list';
import uniq from 'lodash/uniq';
import { processTasksEventType as tasksForProcessEvent } from '../../actions/tasksForProcessEvent';
import { taskEventType as taskEvent } from '../../actions/taskEvent';

interface TasksById {
    [id: string]: TaskState;
    [id: number]: TaskState;
}
const byId = (state: TasksById = {}, action): TasksById => {
    switch (action.type) {
        case taskEvent.getTasksSuccess:
        case taskEvent.getSidebarTasksSuccess:
        case tasksForProcessEvent.getCompletedSuccess:
        case tasksForProcessEvent.getOpenSuccess:
        case tasksForProcessEvent.getAllSuccess: {
            const { data } = action.payload;
            const taskMappingsList = data.map(t => ({
                [t.id]: task(state[t.id], {
                    type: taskEvent.getSuccess,
                    payload: t,
                }),
            }));
            if (taskMappingsList && taskMappingsList.length > 0) {
                // Important: NOT Object.assign(state, ...). This would mutate the state.
                return Object.assign({}, state, ...taskMappingsList);
            }
            return state;
        }
        case taskEvent.updateSuccess:
        case taskEvent.assignSuccess:
        case taskEvent.getSuccess:
        case taskEvent.getPotentialUsersSuccess: {
            const taskId = action.payload.taskId || action.payload.id;
            return {
                ...state,
                [taskId]: task(state[taskId], action),
            };
        }
        default:
            return state;
    }
};

type allIds = string[];

const allIds = (state: allIds = [], action): allIds => {
    switch (action.type) {
        case taskEvent.getTasksSuccess:
        case taskEvent.getSidebarTasksSuccess:
        case tasksForProcessEvent.getCompletedSuccess:
        case tasksForProcessEvent.getOpenSuccess:
        case tasksForProcessEvent.getAllSuccess: {
            const { data } = action.payload;
            return uniq([...state, ...data.map(t => t.id)]);
        }
        case taskEvent.assignSuccess:
        case taskEvent.updateSuccess:
        case taskEvent.getSuccess: {
            return uniq([...state, action.payload.id]);
        }
        default:
            return state;
    }
};

const sidebarList: Reducer<TaskListState> = createList(
    taskEvent.getSidebarTasks,
    taskEvent.getSidebarTasksSuccess,
    taskEvent.getSidebarTasksFailure,
);

export interface TasksState {
    byId: TasksById;
    allIds: allIds;
    list: TaskListState;
    sidebarList: TaskListState;
    submitting: ReturnType<typeof submitting>;
}
const tasks: Reducer<TasksState> = combineReducers<TasksState>({
    byId,
    allIds,
    list,
    sidebarList,
    submitting,
});

export default tasks;

export const getTasks = (state: TasksState, taskList: string[]) => taskList.map(id => state.byId[id]);
