import { createSelector } from 'reselect';
import uniq from 'lodash/uniq';
import { RootState } from 'reducers/rootReducer';

const emptyArray = [];

export const createValidationVSCodeLiteralsSelector = <P extends { resource: string }>() => {
    const selector = createSelector(
        (state: RootState, props: P) => state.entityValidations[props.resource],
        entityValidations => {
            return entityValidations ? uniq(entityValidations.flatMap(ev => ev.valuesetLiterals)) : emptyArray;
        },
    );
    return selector;
};
export const createVisibilityVSCodeLiteralsSelector = <P extends { viewName: string }>() => {
    const selector = createSelector(
        (state: RootState, props: P) => state.entityVisibility[props.viewName],
        entityVisibility => {
            return entityVisibility
                ? uniq(Object.values(entityVisibility).flatMap(evarr => evarr.flatMap(ev => ev.valuesetLiterals)))
                : emptyArray;
        },
    );
    return selector;
};

export const createConceptAvailVSCodeLiteralsSelector = <P extends { viewName: string }>() => {
    const selector = createSelector(
        (state: RootState, props: P) => state.entityConceptExps[props.viewName],
        conceptAvailability => {
            return conceptAvailability
                ? uniq(Object.values(conceptAvailability).flatMap(ev => ev.valuesetLiterals))
                : emptyArray;
        },
    );
    return selector;
};

export const getEditableViewVSCodeLiteralsSelector = <P extends { viewName: string; resource: string }>() => {
    const vals = createValidationVSCodeLiteralsSelector<P>();
    const vis = createVisibilityVSCodeLiteralsSelector<P>();
    const conc = createConceptAvailVSCodeLiteralsSelector<P>();
    return createSelector(
        vals,
        vis,
        conc,
        (validations, visibility, conceptAvailability) => uniq([...validations, ...visibility, ...conceptAvailability]),
    );
};
