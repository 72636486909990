import { SpelExpressionEvaluator } from 'spel2js';
import { SpelCompiledExpression } from 'expressions/evaluate';
import getFieldsInAst from 'casetivity-shared-js/lib/spel/getFieldsInAst';

const injected = ['viewContext'];
export const convertFieldsRequiredToUseableFields = (fields: string[]) =>
    fields
        .filter(f => injected.indexOf(f) === -1)
        .map(f =>
            f.indexOf('_ALL_') !== -1
                ? // get everything before the first _ALL_
                  f.slice(0, f.indexOf('_ALL_'))
                : f,
        );

const getFieldsRequiredForExpression = (expression: string) => {
    const compiledExpression = (SpelExpressionEvaluator.compile(expression) as SpelCompiledExpression)
        ._compiledExpression;
    const fieldsRequired = getFieldsInAst(expression)(compiledExpression);
    return convertFieldsRequiredToUseableFields(fieldsRequired);
};
export default getFieldsRequiredForExpression;
