import { RootState } from '../../../reducers/rootReducer';
import getFormInitial from '../form/getFormInitial/index';
import ViewConfig from 'reducers/ViewConfigType';
import { createSelector } from 'reselect';
import { createGetEntities } from '../form/EntityFormContext/util/getEntities';
import { isRefOneField, isFieldViewField, isValueSetField } from './viewConfigUtils';

interface Props {
    viewName: string;
    ids: string[];
}
const emptyObj = {};

const createGetListData = <T extends Props>(expandTitlesOnReferences: boolean = false) => {
    const getEntities = createGetEntities();
    const getData = (
        viewConfig: ViewConfig,
        entities: RootState['admin']['entities'],
        viewName: string,
        ids: string[] | null | undefined,
    ) => {
        if (!ids) {
            return emptyObj;
        }
        const toReturnData = {};

        ids.forEach(id => {
            const recordData = getFormInitial(
                viewConfig,
                viewName,
                expandTitlesOnReferences
                    ? Object.entries(viewConfig.views[viewName].fields)
                          .filter(([name, vf]) => {
                              return (
                                  isFieldViewField(vf) &&
                                  (isRefOneField(viewConfig, vf.entity, vf.field, 'POP_LAST') ||
                                      isValueSetField(viewConfig, vf.entity, vf.field, 'POP_LAST'))
                              );
                          })
                          .map(([name]) => `${name}.title`)
                    : [],
                entities,
                id,
                'EXPANDED',
            );
            if (recordData && Object.keys(recordData).length > 0) {
                toReturnData[id] = recordData;
            }
        });
        if (Object.keys(toReturnData).length === 0) {
            return emptyObj;
        }
        return toReturnData;
    };
    const dataSelector = createSelector(
        (state: RootState, props: T) => state.viewConfig,
        getEntities,
        (state: RootState, props: T) => props.viewName,
        (state: RootState, props: T) => props.ids,
        getData,
    );
    return dataSelector;
};
export default createGetListData;
