import memoizeOne from 'memoize-one';
import { fromNullable, Option } from 'fp-ts/lib/Option';
import { TaskForm } from 'reducers/rootReducer';
import stableStringify from 'fast-json-stable-stringify';
import { evaluateContext2 } from 'expressions/CachingEvaluator/FormContextEvaluator';

const createGetOptionsSelector = (formDefinition: TaskForm) => () => {
    const getMaybeOptions = memoizeOne(source => {
        return fromNullable(formDefinition)
            .mapNullable(fd => fd.fields)
            .mapNullable(fields => fields.find(f => f.id === source))
            .filter(f => f.fieldType === 'OptionFormField')
            .mapNullable(f => f.options);
    });
    const cachedChoices = {};
    const getChoices = (
        source: string,
        visAvailableOptions: Option<{
            [strOption: string]: boolean;
        }>,
    ) => {
        if (visAvailableOptions.isNone()) {
            return null;
        }
        const strfiedHiddenKeys = Object.entries(visAvailableOptions.value)
            .flatMap(([key, isAvailable]) => (isAvailable === false ? [key] : []))
            .join(', ');
        const cacheEntry = cachedChoices[strfiedHiddenKeys];
        if (cacheEntry) {
            return cacheEntry;
        }
        cachedChoices[strfiedHiddenKeys] = getMaybeOptions(source)
            .map(allOptions =>
                visAvailableOptions
                    .map(visibilities => {
                        return allOptions.filter(o => visibilities[stableStringify(o)] !== false);
                    })
                    .getOrElse(allOptions),
            )
            .toNullable();
        return cachedChoices[strfiedHiddenKeys];
    };
    return (fc: ReturnType<typeof evaluateContext2>, props) => {
        const disabled: boolean = !!fc.disabledFields[props.source];
        const visAvailableOptions = fromNullable(fc.availableOptions[props.source])
            .map(o => o.options)
            .chain(fromNullable);
        return {
            disabled,
            choices: getChoices(props.source, visAvailableOptions),
        };
    };
};

export default createGetOptionsSelector;
