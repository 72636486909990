import React from 'react';

export const withBox = (
    params: { addTop?: number; addBottom?: number; addLeft?: number; addRight?: number; topFromBottom?: number } = {},
    // disabled: boolean = false
) => BaseComponent => props => (
    <div style={{ position: 'relative' }}>
        <div
            style={{
                backgroundColor: 'rgba(0,0,0,0.05)',
                position: 'absolute',
                top: params.topFromBottom ? `calc(100% - ${params.topFromBottom}px)` : 35 + (params.addTop || 0),
                bottom: 8 + (params.addBottom || props.addBottom_withBox || 0),
                // zIndex: 1,
                pointerEvents: 'none',
                left: -1 + (params.addLeft || 0),
                right: 0 + (params.addRight || 0),
                // border: '1px solid black',
                ...(props.disabled
                    ? /* { background: `repeating-linear-gradient(
                    -55deg,
                    rgba(0,0,0,0.05),
                    rgba(0,0,0,0.05) 10px,
                    rgba(0,0,0,0) 10px,
                    rgba(0,0,0,0) 20px
                  )` } */ {
                          backgroundColor: 'rgba(0,0,0,0)',
                      }
                    : {}),
            }}
        />
        <BaseComponent {...props} />
    </div>
);
export default withBox;
