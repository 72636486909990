import * as t from 'io-ts';
import getErrorFromConfig from '../getErrorFromConfig';

const valuesetDefaultValuesConfig = t.partial({
    defaultCodes: t.array(t.string),
});
type ValuesetDefaultValuesConfig = t.TypeOf<typeof valuesetDefaultValuesConfig>;
const valuesetDefaultValuesExample: ValuesetDefaultValuesConfig = {
    defaultCodes: ['MA', 'NY'],
};
const valuesetDefaultValuesValidation = getErrorFromConfig(
    valuesetDefaultValuesConfig,
    `Invalid configuration for valueset default codes. Example: ${JSON.stringify(valuesetDefaultValuesExample)}`,
    `invalid JSON: example: ${JSON.stringify(valuesetDefaultValuesExample)}`,
);

export default valuesetDefaultValuesValidation;
