import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withPropsOnChange from 'recompose/withPropsOnChange';
import {
    getView,
    getEntityId,
    getTabsTitlesFromView,
    getValueSetCodesRequiredForEntity,
    getFieldInstanceEntriesFromView,
    getViewFieldInstanceEntriesForTab,
} from '../utils/viewConfigUtils/index';
import { loadValueSets as loadValueSetsAction } from '../../../actions/valueSetsActions';
import { getPrintTemplatesByEntityConfId as getPrintTemplateAction } from 'printTemplate/actions';
import { setAsTopView, unsetAsTopView } from '../../../popoverStackManagement/actions';
import labelField from '../utils/labelField';
import { FieldFactorySubscriber } from '../../../fieldFactory/Broadcasts';
import { Config, DataSource, Mode } from '../../../fieldFactory/FieldFactoryProvider';
import { RootState } from '../../../reducers/rootReducer';
import TabbableForm from '../form/TabbableForm';
import { withWidth } from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import getTabToPrefetch from '../form/getTabToPrefetch';
import EntityExpressionTester from 'expression-tester/entity-form';

interface ShowFormProps {
    isLoading: boolean;
    isPopover?: boolean;
    id: string;
    title: any; // tslint:disable-line
    actions: any; // tslint:disable-line
    formId: string;
    setAsTopView: Function;
    unsetAsTopView: Function;
    viewStack: string[];
    useTabs?: boolean;
    toolbar?: React.ReactElement<any>;
    viewConfig: RootState['viewConfig'];
    contentContainerStyle: {};
    record?: { id?: string };
    resource: string;
    basePath: string;
    viewName: string;
    loadValueSets: typeof loadValueSetsAction;
    getPrintTemplate: typeof getPrintTemplateAction;
    getGenerateFields: (tabKey: string | null) => Function;
    width: Breakpoint;
}

interface ShowFormState {
    value: number;
}

class SsgShowLayout extends Component<ShowFormProps, ShowFormState> {
    static defaultProps = {
        formId: null,
        printTemplates: [],
        useTabs: false,
        contentContainerStyle: { borderTop: 'solid 1px #e0e0e0' },
    };

    constructor(props: ShowFormProps) {
        super(props);
        this.state = {
            value: 0,
        };
    }

    componentDidMount() {
        if (this.props.formId) {
            this.props.setAsTopView(this.props.formId);
        }
        const valuesetsToFetch = getValueSetCodesRequiredForEntity(this.props.viewConfig, this.props.viewName).map(
            valueSetCode => ({ valueSet: valueSetCode }),
        );
        this.props.loadValueSets(valuesetsToFetch);
        this.props.getPrintTemplate(getEntityId(this.props.viewConfig, this.props.resource));
    }

    shouldComponentUpdate(nextProps: ShowFormProps) {
        const keysToUpdateOn = ['viewConfig', 'generateFields', 'width', 'viewStack'];
        /*
            this can be done better-
            update when loading reaches 0 if any changes in props were recorded
            (like how we do createGetEnitites - maybe use that to update record prop only when loading reaches 0)
        */
        if (
            this.props.record &&
            nextProps.record &&
            (this.props.record as any).title !== (nextProps.record as any).title
        ) {
            // lets update because we need the title to update
            return true;
        }
        // don't render until data is all there
        if (nextProps.isLoading) {
            return false;
        }
        // don't render if none of the keys above have changed
        if (!keysToUpdateOn.some(k => this.props[k] !== nextProps[k])) {
            return false;
        }

        if (!nextProps.formId && nextProps.viewStack.length > 0) {
            /*
            The view is the base view, and there are popups rendered. Don't update.
            */
            return false;
        } else if (nextProps.formId && nextProps.formId !== nextProps.viewStack[0]) {
            /*
            The view is not the base view, but it also isn't the top popover view. Don't update.
            */
            return false;
        }
        return true;
    }

    componentWillUnmount() {
        if (this.props.formId) {
            this.props.unsetAsTopView(this.props.formId);
        }
    }

    handleChange = value => {
        this.setState({ value });
    };

    fieldExtractor = (record, view, resource, basePath) => {
        if (record) {
            return this.props
                .getGenerateFields(null)(getFieldInstanceEntriesFromView(this.props.viewConfig, this.props.viewName))
                .filter(f => f)
                .map(field => {
                    return (
                        field &&
                        labelField(
                            field,
                            record,
                            resource,
                            basePath,
                            field.props.source || null,
                            field.props.fieldInstanceIdentifier,
                        )
                    );
                });
        }
    };

    fieldExtractorForTabs = (record, view, resource, basePath, tabKey) => {
        if (record) {
            return this.props
                .getGenerateFields(tabKey)(
                    getViewFieldInstanceEntriesForTab(this.props.viewConfig, this.props.viewName, view.tabs[tabKey]),
                )
                .filter(f => f)
                .map(
                    field =>
                        field &&
                        labelField(
                            field,
                            record,
                            resource,
                            basePath,
                            field.props.source || null,
                            field.props.fieldInstanceIdentifier,
                        ),
                );
        }
    };

    render() {
        const {
            viewConfig,
            viewName,
            contentContainerStyle,
            record,
            resource,
            basePath,
            title,
            actions,
            width,
            toolbar,
            id,
            isLoading,
            isPopover,
        } = this.props;

        const view = getView(viewConfig, viewName);
        return (
            <TabbableForm
                isPopover={isPopover}
                isLoading={isLoading}
                record={{ id, entityType: resource, ...record }}
                width={width}
                title={title}
                actions={actions}
                viewName={viewName}
                toolbar={toolbar}
                viewConfig={viewConfig}
                contentContainerStyle={contentContainerStyle}
                useTabs={this.props.useTabs}
                baseFields={this.fieldExtractor(record, view, resource, basePath)}
                fieldsByTab={Object.assign(
                    {},
                    ...getTabsTitlesFromView(view).map(tabKey => ({
                        [tabKey]: this.fieldExtractorForTabs(record, view, resource, basePath, tabKey),
                    })),
                )}
            />
        );
    }
}

const makeMapStateToProps = () => {
    const mapStateToProps = (state, props) => ({
        viewStack: state.viewStack,
        printMode: state.printMode,
        tabToPrefetch: getTabToPrefetch(state, props),
    });
    return mapStateToProps;
};
const enhance = compose(
    withWidth({
        initialWidth: 'md',
    }),
    BaseComponent => props => {
        return (
            <EntityExpressionTester type="SHOW" record={props.record} viewName={props.viewName}>
                <BaseComponent {...props} />
            </EntityExpressionTester>
        );
    },
    connect(
        makeMapStateToProps,
        {
            loadValueSets: loadValueSetsAction,
            getPrintTemplate: getPrintTemplateAction,
            setAsTopView,
            unsetAsTopView,
        },
    ),
    withPropsOnChange(['viewName', 'fieldFactory', 'formId', 'printMode', 'tabToPrefetch'], props => {
        const { referenceFieldsShouldFetchInitialData, printMode } = props;
        const config: Config = {
            dataSource: DataSource.ENTITY,
            mode: Mode.DISPLAY,
            validate: false,
            connected: false,
            options: {
                addUnderlineAndMinHeight: true,
                getOwnData: true,
            },
        };
        return {
            getGenerateFields: tabKey =>
                props.fieldFactory(config)({
                    match: props.match,
                    embeddedInFormId: props.formId,
                    referenceFieldsShouldFetchInitialData: (() => {
                        if (!tabKey || tabKey === props.tabToPrefetch) {
                            return false;
                        }
                        return printMode || referenceFieldsShouldFetchInitialData;
                    })(),
                    shouldFetchValueset: false,
                    isForShow: true,
                }),
        };
    }),
);

const ShowForm = enhance(SsgShowLayout);
const ShowFormWithFieldFactory = props => (
    <FieldFactorySubscriber>
        {fieldFactory => <ShowForm {...props} fieldFactory={fieldFactory} />}
    </FieldFactorySubscriber>
);

export default ShowFormWithFieldFactory;
