import React, { useState } from 'react';
import { RWTabListS, TabProvider, tabHasError } from 'components/generics/form/TabbableForm';
import { View } from 'reducers/ViewConfigType';
import { getTabsTitlesFromView, findTabsWithErrorsFromViewDef } from 'components/generics/utils/viewConfigUtils';
import { Tab as RWTab, TabPanel as RWTabPanel } from 'react-web-tabs';
import { useTheme, IconButton } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import Warning from '@material-ui/icons/Warning';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import Popup from 'components/Popup';

interface TabEditorProps {
    view: View;
    renderTab: (args: { tabKey: string; isActiveTab: boolean }) => JSX.Element;
}
const TabEditor: React.FunctionComponent<TabEditorProps> = props => {
    const [currentTabKey, setCurrentTabKey] = useState<string | null>(null);
    const tabTitles = props.view.tabs ? getTabsTitlesFromView(props.view) : [];
    const theme = useTheme();
    const tabsWithErrors = useSelector((state: RootState) => findTabsWithErrorsFromViewDef(state, props.view));
    return (
        <RWTabListS
            defaultTab={currentTabKey}
            width="lg"
            onChange={setCurrentTabKey}
            panes={tabTitles.map((tabKey, tabIx) => {
                return (
                    <RWTabPanel
                        tabId={tabKey}
                        key={tabIx}
                        style={{ width: '100%' }}
                        render={() => {
                            const isActiveTab = currentTabKey === tabKey || (tabIx === 0 && !currentTabKey);
                            return (
                                <TabProvider isActive={isActiveTab}>
                                    <div style={{ width: '100%' }}>
                                        {props.view.tabs![tabKey].label && (
                                            <h2 id={`header:${tabKey}`}>{props.view.tabs![tabKey].label}</h2>
                                        )}
                                        {props.renderTab({ tabKey, isActiveTab })}
                                    </div>
                                </TabProvider>
                            );
                        }}
                    />
                );
            })}
            tabs={
                <React.Fragment>
                    <div style={{ margin: '20px', marginTop: '45px', padding: '10px', border: '1px dashed black' }}>
                        <Popup
                            renderDialogContent={({ closeDialog }) => {
                                return <div>hello</div>;
                            }}
                            renderToggler={({ openDialog }) => {
                                return (
                                    <span>
                                        Add tab{' '}
                                        <IconButton>
                                            <Add />
                                        </IconButton>
                                    </span>
                                );
                            }}
                        />
                    </div>
                    {tabTitles.map((tabKey, tabIx) => {
                        const label = props.view.tabs![tabKey].label;
                        const hasError = tabHasError(tabsWithErrors, currentTabKey)(tabKey, tabIx);
                        return (
                            <RWTab
                                style={{
                                    ...(hasError ? { color: theme.palette.error.main } : {}),
                                    textAlign: 'left',
                                }}
                                key={tabIx}
                                tabFor={tabKey}
                            >
                                {label}
                                {hasError ? (
                                    <span style={{ position: 'relative' }}>
                                        <Warning
                                            style={{
                                                color: hasError ? theme.palette.error.main : undefined,
                                                marginLeft: 10,
                                                position: 'absolute',
                                                bottom: 0,
                                            }}
                                        />
                                    </span>
                                ) : null}
                            </RWTab>
                        );
                    })}
                </React.Fragment>
            }
        />
    );
};

export default TabEditor;
