import traverseGetData, { Entities } from 'casetivity-shared-js/lib/viewConfigSchema/traverseGetData';
import ViewConfigEntities from 'casetivity-shared-js/lib/view-config/entities';
import { postFixInUrl } from 'clients/utils/translateFieldWithSearchTypeAppended';
import _isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

const compareNumbers = (left: string, comparator: '<' | '>' | '<=' | '>=' | '==', right: string) => {
    const leftFloat = parseFloat(left);
    const rightFloat = parseFloat(right);
    if (isNaN(leftFloat) || isNaN(rightFloat)) {
        return false;
    }
    switch (comparator) {
        case '<':
            return leftFloat < rightFloat;
        case '<=':
            return leftFloat <= rightFloat;
        case '==':
            return leftFloat === rightFloat;
        case '>':
            return leftFloat > rightFloat;
        case '>=':
            return leftFloat >= rightFloat;
    }
    return false;
};

const isEqual = (v1, v2) => {
    if (typeof v1 === 'string' && typeof v2 === 'string') {
        return v1.toLowerCase() === v2.toLowerCase();
    }
    if ((v1 === 'true' || v2 === 'true') && (v1 === true || v2 === true)) {
        return true;
    }
    if ((v1 === 'false' || v2 === 'false') && (v1 === false || v2 === false)) {
        return true;
    }
    if ((v1 === 'null' || v2 === 'null') && (v1 === null || v2 === null || v1 === undefined || v2 === undefined)) {
        return true;
    }
    if (compareNumbers(v1, '==', v2)) {
        return true;
    }
    return _isEqual(v1, v2);
};

const compares = (realValue: any = null, qualifier: keyof typeof postFixInUrl, _valueToQualifyAgainst: any = null) => {
    const valueToQualifyAgainst = Array.isArray(_valueToQualifyAgainst)
        ? _valueToQualifyAgainst.map(v => (v === '_NULL_' ? null : v))
        : _valueToQualifyAgainst === '_NULL_'
        ? null
        : _valueToQualifyAgainst;
    switch (qualifier) {
        case 'CONTAINS': {
            if (
                (typeof realValue === 'string' || Array.isArray(realValue)) &&
                typeof valueToQualifyAgainst === 'string'
            ) {
                return realValue.includes(valueToQualifyAgainst);
            }
            // if either value isn't a string, return false.
            return false;
        }
        case 'EXACT': {
            if (Array.isArray(realValue)) {
                return realValue.includes(valueToQualifyAgainst);
            }
            return isEqual(realValue, valueToQualifyAgainst);
        }
        case 'GREATER': {
            return compareNumbers(realValue, '>', valueToQualifyAgainst);
        }
        case 'GREATER_EQUAL': {
            return compareNumbers(realValue, '>=', valueToQualifyAgainst);
        }
        case 'IN': {
            if (Array.isArray(valueToQualifyAgainst)) {
                return valueToQualifyAgainst.includes(realValue);
            }
            if (valueToQualifyAgainst && typeof valueToQualifyAgainst === 'string') {
                const listToCheckIn = valueToQualifyAgainst
                    .split(',')
                    .map(s => s.trim())
                    .map(s => (s === '_NULL_' ? null : s));
                if (Array.isArray(realValue)) {
                    return realValue.some(e => listToCheckIn.includes(e));
                }
                return listToCheckIn.includes(realValue);
            }
            // false if valueToQualifyAgainst isn't an array
            return false;
        }
        case 'LESS': {
            return compareNumbers(realValue, '<', valueToQualifyAgainst);
        }
        case 'LESS_EQUAL': {
            return compareNumbers(realValue, '<=', valueToQualifyAgainst);
        }
        case 'NOT_EMPTY': {
            if (valueToQualifyAgainst && valueToQualifyAgainst !== 'false') {
                return !isEmpty(realValue);
            }
            return isEmpty(realValue);
        }
        case 'STARTS_WITH': {
            if (typeof realValue === 'string' && typeof valueToQualifyAgainst === 'string') {
                return realValue.toLowerCase().startsWith(valueToQualifyAgainst.toLowerCase());
            }
            return false;
        }
        default: {
            // console.error(`BAD SEARCH MODIFIER ${qualifier}`);
            // consider it exact
            if (Array.isArray(realValue)) {
                return realValue.includes(valueToQualifyAgainst);
            }
            return isEqual(realValue, valueToQualifyAgainst);
        }
    }
};

/*
representation would be something like:
{
    name__CONTAINS: 'foo',
    label__CONTAINS: 'bar',
    order__GREATER: '10',
    'parent.name__EXACT': 'qux',
    'view.name__CONTAINS': 'quux',
},
*/
const filterEntityByQueryRepresentation = <ViewConfig extends { entities: ViewConfigEntities }>(
    viewConfig: ViewConfig,
) => (representation: { [fieldAndSearch: string]: string }) => {
    return (
        entity: {
            id: string;
            entityType: string;
        },
        entities: Entities,
    ) => {
        return Object.entries(representation).reduce((prev, [fieldAndSearch, value]) => {
            const [fieldPath, searchModifier] = fieldAndSearch.split('__');
            // get the value from fieldPath
            const realValue = traverseGetData(viewConfig, fieldPath, entity, entities, true).getOrElse(null);
            const matches = compares(realValue, searchModifier as keyof typeof postFixInUrl, value);
            return prev && matches;
        }, true);
    };
};

export default filterEntityByQueryRepresentation;
