import * as React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { createSelector } from 'reselect';
import { Field, reduxForm, formValueSelector, InjectedFormProps } from 'redux-form';
import { Button } from '@material-ui/core';
import { withStyles, createStyles, Theme, WithStyles } from '@material-ui/core/styles';
import { taskEventCreator } from '../../../actions/taskEvent';
import { RootState } from '../../../../reducers/rootReducer';
import { InputLabel, MenuItem, FormControl, Select } from '@material-ui/core';
import traverseGetData from 'casetivity-shared-js/lib/viewConfigSchema/traverseGetData';
import { fromNullable } from 'fp-ts/lib/Option';
import { getIsAdmin } from '../Task';
import {
    getCanClaimTask,
    getPotentialUsersByLogin,
    getPotentialUsers,
    getCanAssignTask,
} from 'bpm/taskPotentialUsers/selectors';
import { getPotentialUsers as getAllPotentialUsers } from 'bpm/potentialUsers/actions';
import { createGetEntities } from 'components/generics/form/EntityFormContext/util/getEntities';
/*
    Selectors that may be useful to export below
*/

const selector = formValueSelector('assign-task-form');

export const initialValuesSelector = <T extends { taskId: string }>(
    state: RootState,
    props: T,
): {
    assignee?: string;
} => {
    const task = fromNullable(state.admin.entities.TaskInstance)
        .map(taskBag => taskBag[props.taskId])
        .chain(fromNullable)
        .getOrElse({
            id: props.taskId,
            entityType: 'TaskInstance',
        });
    return {
        assignee: traverseGetData(state.viewConfig, 'assignee.login', task, state.admin.entities).getOrElse(undefined),
    };
};

export const getCurrentUserCanClaimTask = () =>
    createSelector(
        (state: RootState) => state.viewConfig.user.login,
        getCanClaimTask(),
        getPotentialUsersByLogin(),
        (currentUser, canClaimTask, potentialUsersByLogin) => {
            return /* potentialUsersByLogin[currentUser] && */ canClaimTask;
        },
    );

/*
     Composing the component below
*/

const styles = (theme: Theme) =>
    createStyles({
        formControl: {
            minWidth: 180,
        },
    });

interface AssignUserProps {
    taskId: string | number;
}

const makeMapStateToProps = () => {
    const canClaimTask = getCurrentUserCanClaimTask();
    const canAssignTask = getCanAssignTask();
    const potentialUsers = getPotentialUsers();
    const getEntities = createGetEntities();
    const getInitialAssignee = createSelector(
        initialValuesSelector,
        initialValues => initialValues.assignee,
    );
    const getAssignedUser = createSelector(
        getEntities,
        getInitialAssignee,
        (entities, initialAssigneeLogin) => {
            return fromNullable(entities['User'])
                .map(Object.values)
                .mapNullable(userRecords => userRecords.find(u => u.login === initialAssigneeLogin))
                .toNullable();
        },
    );
    const getUserList = createSelector(
        getAssignedUser,
        potentialUsers,
        (user, potentialUsers) => {
            if (!user) {
                return potentialUsers;
            }
            if (potentialUsers.some(pu => pu.login === user.login)) {
                return potentialUsers;
            }
            return [...potentialUsers, user];
        },
    );
    const getUserListOfOne = createSelector(
        getAssignedUser,
        user => {
            if (user) {
                return [user];
            }
            return [];
        },
    );

    const mapStateToProps = (state: RootState, ownProps) => {
        const task = ownProps.taskId && state.bpm.tasks.byId[ownProps.taskId];
        const isEditable = task && !task.endDate;
        const initialValues = initialValuesSelector(state, ownProps);
        return {
            initialValues,
            assignmentSelected: selector(state, 'assignee'),
            currentUser: state.viewConfig.user.login,
            isEditable,
            potentialUsers: isEditable ? getUserList(state, ownProps) : getUserListOfOne(state, ownProps),
            canClaimTask: canClaimTask(state, ownProps) && isEditable,
            canAssignTask: canAssignTask(state, ownProps),
            isAdmin: getIsAdmin(state, ownProps.taskId),
        };
    };
    return mapStateToProps;
};

interface Values {
    submissionType: 'claim' | 'assign';
    assignee: string | null;
}
const submit = <V extends Values>(values: V, taskId: string) => {
    const { submissionType } = values;
    if (submissionType === 'claim') {
        return taskEventCreator.claimTask(taskId);
    }
    const { assignee } = values;
    return taskEventCreator.assignTask(taskId, assignee);
};
const mapDispatchToProps = <V extends Values, T extends { taskId: string }>(
    dispatch: React.Dispatch<ReturnType<typeof submit> | ReturnType<typeof getAllPotentialUsers>>,
    ownProps: T,
) => ({
    onSubmit: values => dispatch(submit(values, ownProps.taskId)),
    getAllPotentialUsers: () => dispatch(getAllPotentialUsers()),
});

type MappedFromState = ReturnType<ReturnType<typeof makeMapStateToProps>>;
interface AssignUserComponentProps
    extends InjectedFormProps<MappedFromState['initialValues'], AssignUserProps, string>,
        WithStyles<typeof styles>,
        MappedFromState,
        ReturnType<typeof mapDispatchToProps>,
        AssignUserProps {}

class AssignUserComponent extends React.Component<AssignUserComponentProps> {
    componentDidMount() {
        this.props.getAllPotentialUsers();
    }
    renderSubmitButtons = () => {
        const {
            isEditable,
            canAssignTask,
            assignmentSelected,
            canClaimTask,
            handleSubmit,
            onSubmit,
            currentUser,
            initialValues,
            reset,
        } = this.props;
        const clean = initialValues.assignee === assignmentSelected;
        return (
            <div>
                {isEditable && canAssignTask && (
                    <Button
                        onClick={handleSubmit(values =>
                            onSubmit({
                                ...values,
                                submissionType: 'assign',
                            }),
                        )}
                        disabled={
                            // disable if selection is same
                            clean || undefined
                        }
                        variant="outlined"
                        style={{ marginLeft: '.5em' }}
                    >
                        Assign
                    </Button>
                )}
                {isEditable && canClaimTask && (
                    <Button
                        onClick={handleSubmit(values =>
                            onSubmit({
                                ...values,
                                assignee: currentUser,
                                submissionType: 'assign',
                            }),
                        )}
                        variant="outlined"
                        style={{
                            // Do not display if a due date is set, or assignee is set.
                            display: initialValues.assignee === currentUser ? 'none' : undefined,
                            marginLeft: '.5em',
                        }}
                    >
                        Claim
                    </Button>
                )}
                {isEditable && canAssignTask && (
                    <Button
                        onClick={reset}
                        variant="outlined"
                        style={{
                            display: clean ? 'none' : undefined,
                            marginLeft: '.5em',
                        }}
                    >
                        Reset
                    </Button>
                )}
            </div>
        );
    };
    render() {
        const { classes, initialValues, potentialUsers, isEditable, canAssignTask } = this.props;
        return (
            <div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', marginTop: '16px' }}>
                    {
                        <Field
                            name="assignee"
                            component={({ meta: { touched, error }, input }) => (
                                <FormControl margin="none" className={classes.formControl}>
                                    <InputLabel shrink={true} id="task-assignee-dropdown-label">
                                        Assignee
                                    </InputLabel>
                                    <Select
                                        disabled={!isEditable || !canAssignTask}
                                        value={input.value}
                                        onChange={event => input.onChange(event.target.value)}
                                        name="Assignee"
                                        SelectDisplayProps={{ 'aria-describedby': 'task-assignee-dropdown-label' }}
                                        inputProps={{
                                            name: 'assignee',
                                            id: 'assignee',
                                        }}
                                    >
                                        <MenuItem value={''}>
                                            <em>None</em>
                                        </MenuItem>
                                        {potentialUsers
                                            .filter(
                                                user =>
                                                    user.active ||
                                                    (initialValues.assignee && initialValues.assignee === user.login),
                                            )
                                            .map(user => (
                                                <MenuItem key={user.id} value={user.login}>
                                                    {user.title}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            )}
                        />
                    }
                    {this.renderSubmitButtons()}
                </div>
            </div>
        );
    }
}

// composition
const enhance = compose(
    connect(
        makeMapStateToProps,
        mapDispatchToProps,
    ),
    reduxForm({
        enableReinitialize: true,
        form: 'assign-task-form',
    }),
    withStyles(styles),
);

const AssignUser: React.ComponentType<AssignUserProps> = enhance(AssignUserComponent);
export default AssignUser;
