import * as React from 'react';
import { ReactElement } from 'react';
import { connect } from 'react-redux';
import { push as pushAction } from 'connected-react-router';
import compose from 'recompose/compose';
import ViewTitle from '../ViewTitle';
import { crudGetOne as crudGetOneAction } from 'sideEffect/crud/getOne/actions';
import { crudUpdate as crudUpdateAction } from 'sideEffect/crud/update/actions';
import { crudGetList as crudGetListAction } from 'sideEffect/crud/getList/actions';
import EntityPageTitle from '../fields/display/EntityPageTitle';
import { BaseEditProps } from './interfaces';
import prefetchLists from '../form/prefetchLists';
import { loadValueSets as loadValueSetsAction } from '../../../actions/valueSetsActions';
import { getPrintTemplatesByEntityConfId as getPrintTemplateAction } from 'printTemplate/actions';
import ViewConfig from '../../../reducers/ViewConfigType';
import { getEntityId, getValueSetCodesRequiredForEntity } from '../utils/viewConfigUtils/index';
import { Provider } from '../form/refreshContext';
import uniq from 'lodash/uniq';
import { getEditableViewVSCodeLiteralsSelector } from '../form/valuesetCodeExpressionLiteralsSelector';
import createGetInitialFormValues from '../form/getFormInitial/createGetInitialValues';
import DeferredSpinner from 'components/DeferredSpinner';
import FormDisplayStatus from 'remoteStatus/one/components/implementations/FormDisplayStatus';
import SsgAppBarMobile from 'components/SsgAppBarMobile';
import { RootState } from 'reducers/rootReducer';
import getTabToPrefetch from '../form/getTabToPrefetch';
import formTypeContext from '../form/formTypeContext';
import { AjaxError } from 'rxjs/ajax';
import { processPageRefreshContext } from 'bpm/components/ProcessDetail/ProcessPage';

const dispatches = {
    crudGetOne: crudGetOneAction,
    crudUpdate: crudUpdateAction,
    crudGetList: crudGetListAction,
    push: pushAction,
    loadValueSets: loadValueSetsAction,
    getPrintTemplate: getPrintTemplateAction,
};
type Dispatches = typeof dispatches;
interface EditProps extends BaseEditProps, Dispatches {
    refreshProcessPage?: () => void;
    viewConfig: ViewConfig;
    children: ReactElement<{}>;
    id: string;
    data?: { id: string; title?: string };
    isLoading: boolean;
    valueSetCodeLiterals: string[];
    tabToPrefetch?: string;
}
interface EditState {
    key: number;
}

export class Edit extends React.Component<EditProps, EditState> {
    previousKey: number;
    fullRefresh: boolean;
    constructor(props: EditProps) {
        super(props);
        this.state = {
            key: 0,
        };
        this.previousKey = 0;
    }

    componentDidMount() {
        this.updateAllData();
    }

    componentWillReceiveProps(nextProps: EditProps) {
        if (
            /* this.props.data !== nextProps.data */
            this.props.isLoading &&
            !nextProps.isLoading
        ) {
            if (this.fullRefresh) {
                this.fullRefresh = false;
                this.setState({ key: this.state.key + 1 });
            }
        }
        if (this.props.id !== nextProps.id) {
            this.updateAllData(nextProps);
        }
    }

    getBasePath() {
        /*
            const { location } = this.props;
            return location.pathname.split('/').slice(0, -1).join('/');
        */
        const { resource } = this.props;
        return `/${resource}`;
    }

    defaultRedirectRoute() {
        // return 'list';
        return `${this.props.id}/show`;
    }
    updateAllData = (props = this.props) => {
        const { viewConfig, viewName, resource, id, crudGetList, valueSetCodeLiterals } = props;
        this.updateData(resource, id);

        const valueSetCodes = uniq(
            getValueSetCodesRequiredForEntity(viewConfig, viewName).concat(valueSetCodeLiterals),
        ).map(valueset => ({ valueSet: valueset }));
        if (valueSetCodes.length > 0) {
            this.props.loadValueSets(valueSetCodes);
        }
        this.props.getPrintTemplate(getEntityId(viewConfig, resource));

        prefetchLists(viewConfig, viewName, id, crudGetList, this.props.tabToPrefetch);
    };
    updateData(resource: string = this.props.resource, id: string = this.props.id) {
        this.props.crudGetOne({
            monitorRequest: true,
            resource,
            id,
            view: this.props.viewName,
            cb: (responseId, responseData) => {
                if (`${id}` !== `${responseId}`) {
                    // redirect if id is different (due to merge)
                    console.log('Merge Occured.\n ourId: ', id, ', responseId: ', responseId); // tslint:disable-line
                    if (!this.props.noRedirectOnIdChange) {
                        this.props.push(`/${this.props.resource}/${responseId}`);
                    } else if (this.props.refreshProcessPage) {
                        this.props.refreshProcessPage();
                    }
                }
            },
        });
    }

    refresh = event => {
        // console.log('refresh called'); // tslint:disable-line
        if (event) {
            event.stopPropagation();
        }
        // this.fullRefresh = true;
        this.updateAllData();
    };

    save = (record, redirect, afterSaveOrFailureCb?: (err?: AjaxError) => void) => {
        const { onSaveCb, push } = this.props;
        const onSuccessCb =
            onSaveCb || afterSaveOrFailureCb
                ? (...args) => {
                      if (onSaveCb) {
                          (onSaveCb as any)(...args); // tslint:disable-line
                      }
                      if (redirect) {
                          push(redirect);
                      }

                      if (afterSaveOrFailureCb) {
                          afterSaveOrFailureCb();
                      }
                  }
                : undefined;
        this.props.crudUpdate({
            resource: this.props.resource,
            data: record,
            previousData: this.props.data,
            cb: onSuccessCb,
            errorsCbs: {
                409: () => {
                    alert('Stale data submitted. Record was updated from another session.');
                    this.updateData();
                },
                '*': afterSaveOrFailureCb,
            },
        });
    };

    render() {
        const { actions, children, data, hasDelete, hasShow, id, isLoading, resource } = this.props;
        const { key } = this.state;
        const basePath = this.getBasePath();

        const defaultTitle = `${resource} ${id}`;
        const titleElement = <EntityPageTitle record={data} resource={resource} defaultTitle={defaultTitle} />;
        const renderSuccess = () =>
            data && !isRefreshing ? (
                React.cloneElement(children, {
                    title: (
                        <ViewTitle
                            title={titleElement}
                            displayAboveWidth={this.props.createMobileAppBar ? 'xs' : undefined}
                        />
                    ),
                    actions:
                        actions &&
                        React.cloneElement(actions, {
                            basePath,
                            data,
                            hasDelete,
                            hasShow,
                            refresh: this.refresh,
                            resource,
                            viewName: `${resource}Edit`,
                        }),
                    save: this.save,
                    resource,
                    basePath,
                    record: data,
                    isLoading,
                    key,
                    tabToPrefetch: this.props.tabToPrefetch,
                    referenceFieldsShouldFetchInitialData: true,
                    redirect:
                        typeof children.props.redirect === 'undefined'
                            ? this.defaultRedirectRoute()
                            : children.props.redirect,
                })
            ) : (
                <DeferredSpinner />
            );
        // using this.previousKey instead of this.fullRefresh makes
        // the new form mount, the old form unmount, and the new form update appear in the same frame
        // so the form doesn't disappear while refreshing
        const isRefreshing = key !== this.previousKey;
        this.previousKey = key;
        return (
            <Provider value={this.refresh}>
                <formTypeContext.Provider value="EDIT">
                    {this.props.createMobileAppBar ? <SsgAppBarMobile title={titleElement} /> : null}
                    <FormDisplayStatus
                        id={id}
                        resource={resource}
                        showSuccessOffline={!!data}
                        renderSuccess={renderSuccess}
                        refresh={this.refresh}
                    />
                </formTypeContext.Provider>
            </Provider>
        );
    }
}

const makeMapStateToProps = () => {
    // const getData = createGetData('Edit');
    const getData = createGetInitialFormValues({
        getIdFromProps: (p: any) => p.match.params.id, // tslint:disable-line
    });
    const getValueSetCodeLiterals = getEditableViewVSCodeLiteralsSelector();
    const mapStateToProps = (state: RootState, props) => {
        const id = decodeURIComponent(props.match.params.id);
        return {
            valueSetCodeLiterals: getValueSetCodeLiterals(state, props),
            id,
            data: getData(state, props),
            isLoading: state.admin.loading > 0,
            tabToPrefetch: getTabToPrefetch(state, { ...props, id }),
        };
    };
    return mapStateToProps;
};

const enhance = compose(
    connect(
        makeMapStateToProps,
        dispatches,
    ),
    BaseComponent => props => {
        return (
            <processPageRefreshContext.Consumer>
                {({ refresh: refreshProcessPage }) => (
                    <BaseComponent {...props} refreshProcessPage={refreshProcessPage} />
                )}
            </processPageRefreshContext.Consumer>
        );
    },
);

export default enhance(Edit);
