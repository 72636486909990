import { loadValueSets } from 'actions/valueSetsActions';
import { TaskForm } from 'reducers/rootReducer';
import getTaskFormValueSets from 'bpm/components/TaskDetail/TaskForm/fetchValueSets';
import ViewConfig from 'reducers/ViewConfigType';

const getValuesetsForFormAction = (taskForm: TaskForm, viewConfig: ViewConfig): ReturnType<typeof loadValueSets> => {
    // if group, formatted <valueset>.<group>
    const valueSetStrReps: string[] = getTaskFormValueSets(taskForm, viewConfig);
    const valueSets = valueSetStrReps.map(s => {
        if (s.indexOf('.') !== -1) {
            const [valueSet, group] = s.split('.');
            return { valueSet, group };
        }
        return { valueSet: s };
    });
    return loadValueSets(valueSets);
};
export default getValuesetsForFormAction;
