import { put, takeEvery, call } from 'redux-saga/effects';
import { fetchJson } from 'util/fetchUtils';
import { BACKEND_BASE_URL } from '../../config';
import {
    GET_REPORT_DEFINITIONS,
    GET_REPORT_DEFINITIONS_SUCCESS,
    GET_REPORT_DEFINITIONS_FAILURE,
} from '../../actions/constants';
import buildFetchOptions from '../../sagas/util/buildFetchOptions';

const reportDefinitionEndpoint = 'api/reports';

export function* getReportDefinition({ payload }) {
    if (payload) {
        try {
            const options = buildFetchOptions();
            const { status, body } = yield call(
                fetchJson,
                payload
                    ? `${BACKEND_BASE_URL}${reportDefinitionEndpoint}?name=${payload}`
                    : `${BACKEND_BASE_URL}${reportDefinitionEndpoint}`,
                options,
            );

            if (status <= 200 && status < 300) {
                const object = JSON.parse(body);
                console.log(payload);
                if (payload) {
                    const reportDefinitions = yield call(getFields, object);
                    yield put({ type: GET_REPORT_DEFINITIONS_SUCCESS, payload: reportDefinitions });
                } else {
                    const reportDefinitions = object;
                    yield put({ type: GET_REPORT_DEFINITIONS_SUCCESS, payload: reportDefinitions });
                }
            } else {
                yield put({ type: GET_REPORT_DEFINITIONS_FAILURE, status });
            }
        } catch (error) {
            yield put({ type: GET_REPORT_DEFINITIONS_FAILURE, error });
        }
    }
}

export default function* getReportDefinitionSaga() {
    yield takeEvery(GET_REPORT_DEFINITIONS, function*(action) {
        yield call(getReportDefinition, action);
    });
}

export function getFields(reportDefinitionWithParams) {
    const fields = [];

    if (reportDefinitionWithParams) {
        reportDefinitionWithParams.params.forEach(param => {
            const field = {};

            if (param.type) {
                field.type = param.type;
                field.fieldType = 'FormField';
                field.id = param.name;
                field.name = param.label;
                field.value = null;
                field.required = param.required;
                field.readOnly = false;
                field.overrideId = false;
                field.placeholder = null;
                field.layout = null;
                field.params = param.params;

                fields.push(field);
            }
        });
    }

    const result = reportDefinitionWithParams.reportDefinitionDTO;
    result.fields = fields;
    return result;
}
