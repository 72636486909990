import React, { useState } from 'react';
import SearchSelectDialog from '../SearchAndSelectDialog';
import { getFilterFromFilterString } from '../../input/components/ListSelect';
import ReferenceDisplay, { Record } from './ReferenceDisplay';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { crudGetOne as crudGetOneAction } from 'sideEffect/crud/getOne/actions';
import { tableRowContext } from 'fieldFactory/input/components/EditableTable/MuiEditableTable';
import CreateDialog from './CreateDialog';
import { getPathBackFromFieldPath, allowsCreate } from 'components/generics/utils/viewConfigUtils';
import EntityInspect from 'components/generics/hoc/EntityInspect';

type Input = any; // tslint:disable-line
type Meta = any; // tslint:disable-line

interface PopoverRefInputProps {
    viewName?: string;
    isHardDisabled?: boolean;
    editViewName?: string;
    showViewName?: string;
    input: Input;
    disabled?: boolean;
    basePath: string;
    label?: string | null;
    meta: Meta;
    record: Record;
    reference: string;
    resource: string;
    source: string;
    isRequired?: boolean;
    options: { id?: string };
    resourceBasePath: string;
    filterString?: string;
    ariaInputProps?: {};
    renderLabel?: boolean;
    expansions?: string[];
    fetchOwnData?: boolean;
    noSearch?: boolean;
}

const PopoverRefInput: React.SFC<PopoverRefInputProps> = props => {
    const {
        input,
        disabled,
        basePath,
        label = 'Create',
        meta,
        record,
        source,
        reference,
        isRequired,
        options,
        resource,
        resourceBasePath,
        viewName,
        filterString,
        ariaInputProps,
        renderLabel,
        expansions,
        editViewName,
        noSearch,
        showViewName,
        isHardDisabled,
        fetchOwnData,
    } = props;
    const dispatch = useDispatch();
    const viewConfig = useSelector((state: RootState) => state.viewConfig);
    const [searchOpen, setSearchOpen] = useState(false);
    const [addOpen, setAddOpen] = useState(false);
    const hasCreate = allowsCreate(viewConfig.entities[reference].accessLevel);

    const setReference = data => {
        if (expansions && expansions.length > 0) {
            dispatch(
                crudGetOneAction({
                    id: data.id,
                    resource: reference,
                    appendExpansions: expansions,
                    cb: (id, data) => {
                        setImmediate(() => {
                            input.onBlur(data.id);
                            setSearchOpen(false);
                        });
                    },
                    view: -1,
                }),
            );
        } else {
            input.onBlur(data.id);
            setSearchOpen(false);
        }
    };

    const handleOpen = () => {
        if (!disabled) {
            if (noSearch && !hasCreate) {
                return;
            }
            if (noSearch) {
                setAddOpen(true);
            } else {
                setSearchOpen(true);
            }
        }
    };

    return (
        <tableRowContext.Provider value={null}>
            <EntityInspect
                reference={reference}
                editViewName={editViewName}
                showViewName={showViewName}
                formId={`OuterIdSelect from ${viewName} ${source}`}
                renderComponent={args => (
                    <div>
                        <ReferenceDisplay
                            openAdd={() => {
                                if (hasCreate) {
                                    setAddOpen(true);
                                }
                            }}
                            noSearch={noSearch}
                            openSearch={handleOpen}
                            isOpen={searchOpen}
                            openDetail={() => args.selectId(input.value)}
                            label={label && isRequired && !label.endsWith('*') ? label + ' *' : label}
                            disabled={disabled}
                            reference={reference}
                            input={input}
                            meta={meta}
                            record={record}
                            htmlId={(options || {}).id}
                            renderLabel={renderLabel}
                            ariaInputProps={ariaInputProps}
                            expansions={expansions}
                            isHardDisabled={isHardDisabled}
                            fetchOwnData={fetchOwnData}
                        />
                        <SearchSelectDialog
                            filter={getFilterFromFilterString(filterString)}
                            formId={`vn:${viewName}-src:${source}`}
                            viewName={viewName}
                            reference={reference}
                            basePath={basePath}
                            values={[input.value]}
                            isOpen={searchOpen}
                            handleClose={() => setSearchOpen(false)}
                            resourceBasePath={resourceBasePath}
                            setReference={setReference}
                            onCreateCb={setReference}
                        />
                        {// This Create (setting the backref) works (for now) only in the context of an entity.
                        resource && reference && reference !== resource && noSearch && hasCreate && (
                            <CreateDialog
                                open={addOpen}
                                setOpen={setAddOpen}
                                resource={reference}
                                onCreateCb={setReference}
                                parentEntityName={resource}
                                parentFieldInChild={`${getPathBackFromFieldPath(
                                    viewConfig,
                                    resource,
                                    source.endsWith('Id') ? source.slice(0, -2) : source,
                                )}.id`}
                                parentId={(record as any).id}
                            />
                        )}
                    </div>
                )}
            />
        </tableRowContext.Provider>
    );
};

export default PopoverRefInput;
