import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ListItem, Button } from '@material-ui/core';
import { push as pushAction } from 'connected-react-router';

const LogoutButton = ({ toLogoutAndRedirect, mobile, ...props }) => {
    if (mobile) {
        return <ListItem onClick={toLogoutAndRedirect} {...props} />;
    }
    return (
        <Button
            component="a"
            role="menuitem"
            onClick={toLogoutAndRedirect}
            {...props}
            style={{ padding: '16px 24px', cursor: 'pointer' }}
        />
    );
};

LogoutButton.propTypes = {
    toLogoutAndRedirect: PropTypes.func.isRequired,
    mobile: PropTypes.bool.isRequired,
};

export default connect(
    null,
    { toLogoutAndRedirect: () => pushAction('/logout-redirect') },
)(LogoutButton);
