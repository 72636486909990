import React, { ReactElement, useRef, useEffect, useState, useCallback, useMemo, useReducer } from 'react';
import { fromPredicate } from 'fp-ts/lib/Option';
import { Responsive } from 'react-grid-layout';
import Clear from '@material-ui/icons/Clear';
import ReactResizeDetector from 'react-resize-detector';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import fromEntries from 'fromentries';
import uniq from 'lodash/uniq';
import deepEql from 'deep-eql';
import get from 'lodash/get';
import { correctLayout, compactLeft, removeSpaceBetweenRows } from './correctLayout';
import { Button, Card, CardActions, IconButton } from '@material-ui/core';
import layoutReducer, { LayoutState, LayoutStateItem } from 'layout-editor/demo/layoutReducer';
import DraggableSource from 'layout-editor/demo/DraggableSource';
import GridElement from 'layout-editor/demo/GridElement';
import { ViewField, FieldViewField } from 'reducers/ViewConfigType';
import Popup from 'components/Popup';
import Edit from '@material-ui/icons/Edit';

const DialogLayoutFieldEdit: React.SFC<{
    addField: (fieldDefinition: any) => void;
    renderFieldAdder: (args: { addField: (fieldDefinition: any) => void }) => JSX.Element;
}> = props => {
    return (
        <Popup
            renderDialogContent={({ closeDialog }) => {
                return (
                    <div>
                        {props.renderFieldAdder({
                            addField: fieldDefinition => {
                                closeDialog();
                                props.addField(fieldDefinition);
                            },
                        })}
                    </div>
                );
            }}
            renderToggler={({ openDialog }) => {
                return (
                    <IconButton size="small" onClick={openDialog()}>
                        <Edit />
                    </IconButton>
                );
            }}
        />
    );
};

export type FieldElem = ReactElement<{
    row?: number;
    column?: number;
    span?: number;
    source?: number;
    originalDefinition: string;
}>;
interface MovableGridProps {
    recalculateHeightKey?: string;
    columnStartsAt: 1 | 0;
    fields?: FieldElem[];
    onLayoutChange?: (args: { layout: LayoutState }) => void;
    createField: (fieldDefinition: any) => FieldElem;
    renderFieldAdder: (args: {
        addField: (fieldDefinition: any) => void;
        initialValues?: Partial<FieldViewField>;
    }) => JSX.Element;
}
const getRowKey = (row, defaultValue = -1) =>
    fromPredicate((n: number) => !isNaN(n))(parseInt(row, 10)).getOrElse(defaultValue);

const getLayoutFromFields = (
    fields: ReactElement<{
        row?: number;
        column?: number;
        span?: number;
        source?: number;
        originalDefinition: string;
    }>[],
    options: {
        columnStartsAt: 0 | 1;
    },
): LayoutState => {
    /*
        get the number of unique rows (including 'no row' as unique))
        that number is the row index of 'no rows',
        order the rest.
    */
    const fieldsPerRow = fields
        .map(f => f.props.row)
        .reduce(
            (prev, curr) => {
                const currRowKey = getRowKey(curr);

                if (typeof prev[currRowKey] === 'number') {
                    prev[currRowKey] += 1;
                } else {
                    prev[currRowKey] = 1;
                }

                return prev;
            },
            {} as {
                [row: number]: number;
            },
        );

    const fieldOrderingsPerRow: {
        [row: number]: {
            [fieldIndex: number]: {
                index: number;
                column: number;
                overflowedRows: number;
                span: number;
            };
        };
    } = fromEntries(
        Object.entries(groupBy(fields.map((f, i) => [f, i] as const), t => parseInt(get(t, '[0].props.row'), 10))).map(
            ([row, fieldIndexes]) => {
                return [
                    row,
                    sortBy(fieldIndexes, t => parseInt(get(t, '[0].props.column'), 10)).reduce(
                        (prev, [currField, i]) => {
                            const accumulatedSpans = Object.values(prev).reduce((_prev, { span = 4 }) => {
                                return span + _prev;
                            }, 0);
                            const overflowedRows = Math.floor(accumulatedSpans / 12);
                            prev[i] = {
                                index: i,
                                column: accumulatedSpans % 12,
                                overflowedRows,
                                span: currField.props.span,
                            };
                            return prev;
                        },
                        {} as {
                            [fieldIndex: number]: {
                                index: number;
                                column: number;
                                overflowedRows: number;
                                span: number;
                            };
                        },
                    ),
                ] as const;
            },
        ),
    );
    /*
    TODO: Put those without rows to the bottom.
    */

    const il = fields.map((f, index) => {
        const w = f.props.span || 12;
        return {
            i: `${index}`,
            x: fieldOrderingsPerRow[parseInt(f.props.row as any, 10)][index].column, // getRowKey((f.props.column - 1) * 4, options.columnStartsAt),
            y:
                getRowKey(
                    f.props.row,
                    Object.keys(fieldsPerRow).reduce((prev, curr) => {
                        return Math.max(prev, getRowKey(curr));
                    }, 0) + 1,
                ) + fieldOrderingsPerRow[parseInt(f.props.row as any, 10)][index].overflowedRows,
            w,
            h: 1, // Maybe read in from textArea height config? Or don't provide?
            content: f,
            originalDefinition: f.props.originalDefinition,
        };
    });
    const rows = uniq(il.map(e => e.y)).sort((a, b) => a - b);
    rows.forEach((r, i) => {
        il.filter(e => e.y === r).forEach(e => {
            e.y = i;
        });
    });
    return il;
};

const MovableGrid: React.SFC<MovableGridProps> = props => {
    const { onLayoutChange, columnStartsAt, createField } = props;
    const rootDivRef = useRef<HTMLDivElement>();

    const itemRefs = useRef<Array<HTMLDivElement | null>>([]);
    const [heightIsCalculated, setHeightIsCalculated] = useState(true);
    useEffect(() => {
        setHeightIsCalculated(false);
    }, [props.recalculateHeightKey]);
    const initialLayout = useMemo(() => {
        return getLayoutFromFields(props.fields, {
            columnStartsAt,
        });
    }, [props.fields, columnStartsAt]);
    const [layout, dispatch] = useReducer(layoutReducer, initialLayout);
    const entitiesAreLoading = useSelector((state: RootState) => state.admin.loading > 0);
    const layouts = useMemo(
        () => ({
            lg: layout,
        }),
        [layout],
    );
    useEffect(() => {
        if (entitiesAreLoading) {
            return;
        }
        dispatch({
            type: 'newLayout',
            layout: getLayoutFromFields(props.fields, { columnStartsAt }),
        });
        const to = setTimeout(() => {
            setHeightIsCalculated(false);
        }, 100);
        return () => {
            clearTimeout(to);
        };
    }, [props.fields, entitiesAreLoading, columnStartsAt]);
    useEffect(() => {
        if (!heightIsCalculated) {
            const to = setTimeout(() => {
                // wait for render, then set heights based on calculated element heights.
                dispatch({
                    type: 'transformLayout',
                    transform: layout => {
                        const l = layout.map(l => {
                            const widgetElem = itemRefs.current[parseInt(l.i, 10)].firstElementChild as HTMLDivElement;
                            const offsetHeight = widgetElem.offsetHeight;
                            const h = Math.ceil(offsetHeight / 67.0);
                            return {
                                ...l,
                                h,
                                isResizeable: false,
                            };
                        });
                        return l;
                    },
                });
                setHeightIsCalculated(true);
            }, 500);
            return () => {
                clearTimeout(to);
            };
        }
    }, [heightIsCalculated]);
    const propagateLayout = useCallback(() => {
        onLayoutChange({
            layout,
        });
    }, [layout, onLayoutChange]);

    const setLayoutHandler = useCallback(
        (_layout: LayoutState) => {
            const correctedLayout: LayoutState = correctLayout<LayoutStateItem>(
                compactLeft<LayoutStateItem>(removeSpaceBetweenRows<LayoutStateItem>(_layout)),
            );
            if (
                !deepEql(
                    correctedLayout.map(({ content, mouseEvent, ...e }) => e),
                    layout.map(({ content, mouseEvent, ...e }) => e),
                )
            ) {
                dispatch({
                    type: 'newLayout',
                    layout: correctedLayout,
                });
            }
        },
        [layout, dispatch],
    );
    const fieldElements = useMemo(() => {
        return layout.map(item => (
            <GridElement key={'' + item.i} {...item}>
                <div
                    style={{ position: 'relative' }}
                    ref={ref => {
                        itemRefs.current.push(ref);
                    }}
                >
                    {item.content}
                    {/* pass renderFieldAddr (or a variation) to a component which has dialog state controlled. Like 'GridElementActionsPopup' */}
                    <span
                        onMouseDown={e => {
                            e.stopPropagation();
                        }}
                        style={{ position: 'absolute', top: 0, right: 24 }}
                    >
                        <DialogLayoutFieldEdit
                            addField={fieldDefinition => {
                                dispatch({
                                    type: 'transformLayout',
                                    transform: layout =>
                                        layout.map(
                                            (e): LayoutStateItem =>
                                                e.i === item.i
                                                    ? {
                                                          ...e,
                                                          originalDefinition: JSON.stringify(fieldDefinition),
                                                          // todo: test when 'appCases' is selected
                                                          // or was it documents? one of those made it crash
                                                          content: createField(fieldDefinition),
                                                      }
                                                    : e,
                                        ),
                                });
                            }}
                            renderFieldAdder={({ addField }) =>
                                props.renderFieldAdder({ addField, initialValues: JSON.parse(item.originalDefinition) })
                            }
                        />
                    </span>
                    <IconButton
                        size="small"
                        onClick={() => {
                            dispatch({
                                type: 'transformLayout',
                                transform: layout => layout.filter(e => e.i !== item.i),
                            });
                        }}
                        style={{ position: 'absolute', top: 0, right: 0 }}
                    >
                        <Clear />
                    </IconButton>
                </div>
            </GridElement>
        ));
    }, [layout, createField, props]);
    const [fieldToAdd, setFieldToAdd] = useState<ViewField | null>();
    return (
        <Card>
            {!fieldToAdd ? (
                <div
                    style={{
                        padding: '10px',
                        marginLeft: '20px',
                        marginBottom: '20px',
                        marginRight: '10px',
                        border: '1px dashed black',
                    }}
                >
                    {props.renderFieldAdder({
                        addField: f => {
                            setFieldToAdd(f);
                        },
                    })}
                </div>
            ) : (
                <div>
                    <div style={{ border: '1px dashed black' }}>
                        <DraggableSource
                            targetRef={rootDivRef}
                            dispatch={action => {
                                if (action.type === 'finaliseTemporaryItem') {
                                    setFieldToAdd(null);
                                }
                                dispatch(action);
                            }}
                            key="1"
                        >
                            <div style={{ padding: '10px' }}>{props.createField(fieldToAdd)}</div>
                        </DraggableSource>
                    </div>
                    <span>
                        <IconButton aria-label="clear" onClick={() => setFieldToAdd(null)}>
                            <Clear />
                        </IconButton>{' '}
                        Clear
                    </span>
                    <br />
                </div>
            )}
            <div ref={rootDivRef}>
                <ReactResizeDetector handleWidth>
                    {({ width }) => (
                        <div>
                            <Responsive
                                compactType={null}
                                breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                                cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
                                width={width}
                                rowHeight={67}
                                className="layout"
                                style={{ minHeight: 100, minWidth: 100 }}
                                layouts={layouts}
                                margin={[5, 10]}
                                onLayoutChange={setLayoutHandler}
                            >
                                {fieldElements}
                            </Responsive>
                        </div>
                    )}
                </ReactResizeDetector>
            </div>
            <CardActions>
                <Button variant="contained" color="primary" onClick={propagateLayout}>
                    Apply layout
                </Button>
            </CardActions>
        </Card>
    );
};
export default MovableGrid;
