import { createSelector } from 'reselect';
import { RootState } from '../../../../reducers/rootReducer';
import getAllFieldsExpected, { getAdhocFieldsForView, EXTERNALGISID } from './util/getAllFieldsExpected';
import createRecordSelector from './util/recordSelector';
import forceBooleanFieldsBoolean from './util/enforceBoolValues';
import { getAllValuesetFields, getExpressions } from './util/entityVisExp';
import createFormContext from './util/createFormContext';
import { createGetEntities, createGetValueSets } from './util/getEntities';
import createDeepEqlSelector from './util/createDeepEqlSelector';
import uniq from 'lodash/uniq';
import { EntityFieldConceptExps } from 'viewConfigCalculations/ConceptAvailabilityExpressions/reducer';
import { EntityVisibilityExps } from 'reducers/entityVisibilityReducer';
import { getValueSetFieldsRequiredForEntity } from 'components/generics/utils/viewConfigUtils';
import mapFieldsToWidgets from './util/mapFieldsToWidgets';
import { CasetivityViewContext } from 'util/casetivityViewContext';
import getProviderAndHocWithViewContext from './util/getProviderAndHocWithViewContext';
import { ViewEditableExps } from 'reducers/entityEditabilityReducer';
import fromEntries from 'util/fromentries';
import { ViewItemFilterExpressionsGenerated } from 'viewConfigCalculations/filterExpressions/reducer';
import { FormContextEvaluator } from 'expressions/CachingEvaluator/FormContextEvaluator';

export interface FCPProps {
    viewName: string;
    formId?: string;
    record: { id: string; entityType: string };
    overrides?: {
        visibilityExps?: EntityVisibilityExps[0];
        editableExps?: ViewEditableExps[0];
        conceptExps?: EntityFieldConceptExps[0];
    };
    viewContext: CasetivityViewContext;
}

const getExpressionsSelector = () => {
    return createSelector(
        (state: RootState, props: FCPProps): EntityVisibilityExps[0] =>
            (props.overrides && props.overrides.visibilityExps) || state.entityVisibility[props.viewName] || emptyObj,
        (state: RootState, props: FCPProps): EntityFieldConceptExps[0] =>
            (props.overrides && props.overrides.conceptExps) || state.entityConceptExps[props.viewName] || emptyObj,
        (state: RootState, props: FCPProps) =>
            (props.overrides && props.overrides.editableExps) || state.entityEditability[props.viewName] || emptyObj,
        (state: RootState, props: FCPProps): ViewItemFilterExpressionsGenerated[0] =>
            state.viewItemFilterExps[props.viewName] || emptyObj,
        (visibilityConfig, entityConceptExps, viewFieldEditability, viewItemFilterExps) => {
            return {
                visibilityConfig,
                entityConceptExps,
                viewFieldEditability,
                viewItemFilterExps,
            };
        },
    );
};
const getFormContextEvaluatorSelector = () => {
    const expressionsSelector = getExpressionsSelector();
    return createSelector(
        expressionsSelector,
        (state: RootState, props: FCPProps) => state.viewConfig,
        (state: RootState, props: FCPProps) => props.viewName,
        (state: RootState, props: FCPProps) =>
            (state.form![props.formId || 'record-form'] || {}).registeredFields || emptyObj,
        (state: RootState, props: FCPProps) => state.entityValidations,
        (state: RootState, props: FCPProps) => props.viewContext,
        (expressions, viewConfig, viewName, registeredFields, entityValidations, viewContext) => {
            const { visibilityConfig, entityConceptExps, viewFieldEditability, viewItemFilterExps } = expressions;
            const allFieldsExpected = getAllFieldsExpected(
                viewConfig,
                viewName,
                registeredFields,
                getAdhocFieldsForView,
            );
            const fieldsToWidgets = mapFieldsToWidgets(viewConfig, viewName, allFieldsExpected);

            const allValueset1Fields = {
                ...getValueSetFieldsRequiredForEntity(viewConfig, viewName, 'ONES'),
                ...getAllValuesetFields(visibilityConfig, viewFieldEditability, entityConceptExps, viewItemFilterExps),
            };
            const visibilityExpressions = getExpressions(visibilityConfig);
            const editabilityExpressions = getExpressions(viewFieldEditability);

            const basedOnEntity = viewConfig.views[viewName].entity;
            const fieldsUsedInExpressions = uniq([
                ...Object.values(visibilityConfig).flatMap(c => c.flatMap(cc => cc.fieldsRequired)),
                ...Object.values(viewFieldEditability).flatMap(c => c.flatMap(cc => cc.fieldsRequired)),
                ...Object.values(entityValidations[basedOnEntity] || {}).flatMap(c => c.fieldsRequired),
                ...Object.values(viewItemFilterExps || {}).flatMap(c => c.fieldsRequired),
                ...Object.values(entityConceptExps || {}).flatMap(c => c.fieldsRequired),
            ]);
            return new FormContextEvaluator({
                basedOnEntityOptions: {
                    basedOnEntity,
                    fieldsUsedInExpressions,
                },
                evaluationFactors: {
                    fieldWidgets: fieldsToWidgets,
                    dropdownAvailableOptionsExpressions: {},
                    valueset1AvailableConceptsExpressions: Object.assign(
                        {},
                        ...Object.values(entityConceptExps).map(ca => ({ [ca.fieldName]: ca.expression })),
                    ),
                    valueset1Fields: allValueset1Fields,
                    visibilityExpressions,
                    editabilityExpressions,
                    tableExpressions: {},
                    reference1EntityFilterExpressions:
                        viewItemFilterExps &&
                        fromEntries(
                            Object.entries(viewItemFilterExps).map(([field, e]) => {
                                return [
                                    field,
                                    {
                                        entityType: e.searchEntity,
                                        expression: e.expression,
                                    },
                                ] as [string, { entityType: string; expression: string }];
                            }),
                        ),
                },
                options: {
                    viewContext,
                    dateFormat: (viewConfig && viewConfig.application && viewConfig.application.dateFormat) || '',
                },
                useBackingValuesRegardlessOfDisplayStatus: {
                    [EXTERNALGISID]: true,
                },
                viewConfig,
            });
        },
    );
};
const emptyObj = {};
const createFormContextSelector = () => {
    const getEntities = createGetEntities();
    const getValueSets = createGetValueSets();
    const recordSelector = createRecordSelector();
    const formContextEvaluatorSelector = getFormContextEvaluatorSelector();
    const formContextSelector = createSelector(
        formContextEvaluatorSelector,
        (state: RootState, props: FCPProps) => state.viewConfig,
        (state: RootState, props: FCPProps) => props.viewName,
        (state: RootState, props: FCPProps) => (state.form![props.formId || 'record-form'] || {}).initial || emptyObj,
        (state: RootState, props: FCPProps) =>
            (state.form![props.formId || 'record-form'] || {}).values || recordSelector(state, props),
        getEntities,
        getValueSets,
        (formContextEvaluator, viewConfig, viewName, initial: {}, values: {}, entities: {}, valueSets) => {
            const result = formContextEvaluator.evaluate(
                forceBooleanFieldsBoolean(viewConfig, viewName)(values),
                valueSets,
                initial,
                entities,
            );
            const { availableOptions, tableRowContexts, ...rest } = result;
            return { ...rest, viewName };
        },
    );

    return createDeepEqlSelector(formContextSelector);
};

export const defaultFormContext: ReturnType<ReturnType<typeof createFormContextSelector>> = {
    hiddenFields: {},
    disabledFields: {},
    fieldValues: {},
    registeredValues: {},
    visibleAndEditableFields: [],
    isDirty: false,
    dirtyValues: {},
    initialValues: {},
    nullFilteredRefOneFields: [],
    valuesetFieldAvailableConceptIds: {},
    viewName: undefined,
};

const { formContext, FormContextProvider: _EntityFormContextProvider } = createFormContext(
    createFormContextSelector,
    defaultFormContext,
);

const { FormContextProvider: EntityFormContextProvider, formContextHoc } = getProviderAndHocWithViewContext(
    _EntityFormContextProvider,
);
export { formContext, formContextHoc, EntityFormContextProvider };
