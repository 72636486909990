import React from 'react';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';
import { Radio, RadioGroup, createStyles, Theme } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import FormControlLabel from 'fieldFactory/input/components/mui/FormControlLabel';
import uniqueId from 'lodash/uniqueId';

const styles = (theme: Theme) =>
    createStyles({
        checkBox: {
            width: 24,
            height: 24,
            marginLeft: 0,
        },
        addPadding: {
            paddingTop: 0,
            paddingBottom: 0,
        },
        error: { color: theme.palette.error.main, fontSize: '0.75rem' },
    });

const hideIfNoInput = branch(
    props => !props.input, // input is not provided
    renderNothing,
);
const getFlexDirection = (direction: 'VERTICAL' | 'HORIZONTAL'): 'row' | 'column' => {
    return direction === 'HORIZONTAL' ? 'row' : 'column';
};
const RadioSelect = hideIfNoInput(
    ({
        choices,
        meta: { touched, error },
        label,
        input,
        disabled,
        theme,
        direction = 'VERTICAL',
        classes,
        ariaInputProps,
    }) => {
        const idRef = React.useRef(uniqueId('RadioSelect'));
        return (
            <div>
                <RadioGroup
                    aria-label={label}
                    {...ariaInputProps}
                    onChange={(event, value) => {
                        input.onBlur(choices.find(({ name }) => name === value));
                    }}
                    onBlur={() => input.onBlur(undefined)}
                    value={input.value ? input.value.name : ''}
                    tabIndex={0}
                    style={{
                        display: 'flex',
                        flexDirection: getFlexDirection(direction),
                        justifyContent: getFlexDirection(direction) === 'row' ? 'flex-start' : undefined,
                        flexWrap: 'wrap',
                    }}
                >
                    {choices.map(
                        (object, i) =>
                            object.name && (
                                <FormControlLabel
                                    htmlFor={`${idRef.current} (${i})`}
                                    control={
                                        <Radio
                                            id={`${idRef.current} (${i})`}
                                            className={classnames(classes.checkBox, {
                                                [classes.addPadding]:
                                                    getFlexDirection(direction) === 'row' ? true : false,
                                            })}
                                        />
                                    }
                                    disabled={disabled}
                                    label={object.name}
                                    value={object.name}
                                    key={object.name}
                                />
                            ),
                    )}
                </RadioGroup>
                {touched && error && <span className={classes.error}>Error: {error}</span>}
            </div>
        );
    },
);

export default withStyles(styles)(RadioSelect);
