import React, { useContext, useMemo } from 'react';
import { formContext as taskFormContext } from 'bpm/components/TaskDetail/TaskForm/FormContext';
import { formContext as entityFormContext } from 'components/generics/form/EntityFormContext';
import { formContext as entityShowFormContext } from 'components/generics/form/EntityFormContext/Show';
import { evaluateFilterString } from '../evaluteFilterString';
import { processContext } from 'bpm/components/processContext';
import { RootState } from 'reducers/rootReducer';
import { fromNullable } from 'fp-ts/lib/Option';
import { createSelector } from 'reselect';
import { connect, useSelector } from 'react-redux';
import { createGetEntities } from 'components/generics/form/EntityFormContext/util/getEntities';
import { flowablePreprocessValuesForEval, entityPreprocessValuesForEval } from 'expressions/formValidation';
import {
    tableRowContext,
    getTableRowContext,
    TableRowContext,
} from 'fieldFactory/input/components/EditableTable/MuiEditableTable';
import get from 'lodash/get';
import { evaluateContext2 } from 'expressions/CachingEvaluator/FormContextEvaluator';
import { purifyHtmlMoreStrict } from 'fieldFactory/display/components/HtmlDisplay';
import { denormalizeEntitiesByPaths } from 'casetivity-shared-js/lib/viewConfigSchema/denormalizing/buildEntityMappingsFromPaths';
import useViewConfig from 'util/hooks/useViewConfig';
import formTypeContext from 'components/generics/form/formTypeContext';

interface WithTaskFormFieldProps {
    taskId: string;
    trc?: TableRowContext;
    formContext: ReturnType<typeof evaluateContext2>;
    children: (processedFieldValues: {}) => JSX.Element | null;
}
const makeMapStateToProps = () => {
    const emptyArray = [];
    const getEntities = createGetEntities();
    const getValuesSelector = createSelector(
        (state: RootState, props: WithTaskFormFieldProps) =>
            fromNullable(state.taskForms[props.taskId])
                .mapNullable(f => f.fields)
                .getOrElse(emptyArray),
        getEntities,
        (state: RootState, props: WithTaskFormFieldProps) => state.valueSets,
        (state: RootState) => state.viewConfig.application.dateFormat,
        (state: RootState, props: WithTaskFormFieldProps) => props.formContext,
        (state: RootState, props: WithTaskFormFieldProps) => props.trc,
        (formFields, entities, valueSets, dateFormat, formContext, tableRowContext) => {
            const rowData = tableRowContext ? getTableRowContext(tableRowContext, formContext).fieldValues : {};

            const res = flowablePreprocessValuesForEval(
                { ...formContext.fieldValues, ...rowData },
                formFields,
                entities,
                { dateFormat },
                valueSets,
            );
            return res;
        },
    );
    const mapStateToProps = (state: RootState, props: WithTaskFormFieldProps) => {
        return {
            processedValues: getValuesSelector(state, props),
        };
    };
    return mapStateToProps;
};
interface WithTaskFormFieldsComponentProps
    extends WithTaskFormFieldProps,
        ReturnType<ReturnType<typeof makeMapStateToProps>> {}
const WithTaskFormFieldsComponent: React.SFC<WithTaskFormFieldsComponentProps> = props => {
    const { trc } = props;
    if (trc) {
        const { tableId, columns, rowGetExp } = trc;
        const nullInitializedRowData = {};
        columns.forEach(c => {
            nullInitializedRowData[`${tableId}_c_${c.id}`] = null;
        });
        const tableRowMappedData = fromNullable(get(props.processedValues, rowGetExp, null))
            .map(rowData => {
                const newData = {};
                Object.entries(rowData).forEach(([k, v]) => {
                    newData[`${tableId}_c_${k}`] = typeof v === 'undefined' ? null : v;
                });
                return newData;
            })
            .getOrElse({});

        return props.children({
            ...nullInitializedRowData,
            ...props.processedValues,
            ...tableRowMappedData,
        });
    }
    return props.children(props.processedValues);
};
const WithTaskFormFields = connect(makeMapStateToProps)(WithTaskFormFieldsComponent);

const _evaluate = (fieldValues, filterString) => {
    return evaluateFilterString(filterString, fieldValues, purifyHtmlMoreStrict);
};

const RenderChildrenTaskForm: React.SFC<{
    templateString: string;
    children: (evaluatedString: string) => JSX.Element | null;
}> = props => {
    const fc = useContext(taskFormContext);
    const trc = useContext(tableRowContext);
    const { taskId } = useContext(processContext);
    return (
        <WithTaskFormFields taskId={taskId} formContext={fc} trc={trc}>
            {fv => props.children(_evaluate(fv, props.templateString))}
        </WithTaskFormFields>
    );
};
type RenderChildrenEntityFormProps = {
    templateString: string;
    children: (evaluatedString: string) => JSX.Element | null;
    context:
        | {
              type: 'source';
              source: string;
          }
        | {
              type: 'adhoc';
              fieldsRequired: string[];
              valuesetFieldsRequired: {
                  [key: string]: string;
              };
          };
};
const RenderChildrenEntityForm: React.SFC<RenderChildrenEntityFormProps> = props => {
    const efc = useContext(entityFormContext);
    const sfc = useContext(entityShowFormContext);
    const currentFormTypeContext = useContext(formTypeContext);
    const fc = currentFormTypeContext === 'SHOW' ? sfc : efc;
    const getEntities = useMemo(createGetEntities, []);
    const entities = useSelector(getEntities);
    const valueSets = useSelector((state: RootState) => state.valueSets);
    const filterEvaluationContext = useSelector((state: RootState) =>
        fromNullable(state.viewItemFilterExps[fc.viewName])
            .mapNullable(ve => (props.context.type === 'source' ? ve[props.context.source] : undefined))
            .toNullable(),
    );
    const viewConfig = useViewConfig();
    const values =
        fc === sfc // we are in a show form
            ? denormalizeEntitiesByPaths(
                  entities,
                  (props.context.type === 'adhoc'
                      ? props.context.fieldsRequired
                      : filterEvaluationContext
                      ? filterEvaluationContext.fieldsRequired
                      : []
                  ).flatMap(p => (p.includes('.') ? [p.slice(0, p.lastIndexOf('.'))] : [])),
                  viewConfig,
                  (fc.fieldValues as any).entityType,
                  (fc.fieldValues as any).id,
              )
            : fc.fieldValues;
    const context =
        props.context.type === 'adhoc'
            ? entityPreprocessValuesForEval(
                  values,
                  props.context.fieldsRequired,
                  props.context.valuesetFieldsRequired,
                  entities,
                  { viewContext: 'ENTITY' },
                  valueSets,
              )
            : filterEvaluationContext
            ? entityPreprocessValuesForEval(
                  values,
                  filterEvaluationContext.fieldsRequired,
                  filterEvaluationContext.valuesetFieldsRequired,
                  entities,
                  { viewContext: 'ENTITY' },
                  valueSets,
              )
            : {};
    const res = _evaluate(context, props.templateString);
    return props.children(res);
};

export type WithStringEvaluatedInFormContextProps =
    | {
          from: 'Flowable';
          templateString: string;
          children: (evaluatedString: string) => JSX.Element | null;
      }
    | {
          from: 'Entity';
          context: RenderChildrenEntityFormProps['context'];
          templateString: string;
          children: (evaluatedString: string) => JSX.Element | null;
      };
const WithStringEvaluatedInFormContext: React.SFC<WithStringEvaluatedInFormContextProps> = props => {
    return props.from === 'Flowable' ? (
        <RenderChildrenTaskForm templateString={props.templateString}>{props.children}</RenderChildrenTaskForm>
    ) : (
        <RenderChildrenEntityForm templateString={props.templateString} context={props.context}>
            {props.children}
        </RenderChildrenEntityForm>
    );
};
export default WithStringEvaluatedInFormContext;
