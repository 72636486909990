import * as ca from './actions';
import { RootAction } from 'actions/rootAction';
import { getType } from 'typesafe-actions';

type CurrentDashboard = string | -1;
export default function currentDashboardReducer(state: CurrentDashboard = -1, action: RootAction): CurrentDashboard {
    switch (action.type) {
        case getType(ca.setCurrentDash):
            return action.dashboardName;
        default:
            return state;
    }
}
