import { MERGE } from './constants';
/* tslint:disable no-any */
export default (
    resource: string,
    id: string,
    matchId: string,
    result: {},
    cb: (...args: any[]) => void = () => null,
    errorCb,
) => ({
    type: MERGE,
    payload: {
        resource,
        id,
        matchId,
        result,
    },
    cb,
    errorCb,
});

/*
{
    "id": 50001,
    "matchId": 102601,
      "result": {
        "firstName": "Josh",
        "lastName": "Davis",
        "birthDate": "2010-11-11"
      }
  }
*/
