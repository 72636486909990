import { crudCreateSuccess, crudCreateFailure } from 'sideEffect/crud/create/actions';
import { crudUpdateSuccess, crudUpdateFailure } from 'sideEffect/crud/update/actions';
import { crudDeleteSuccess, crudDeleteFailure } from 'sideEffect/crud/delete/actions';
import { crudGetListFailure } from 'sideEffect/crud/getList/actions';
import { crudGetOneFailure } from 'sideEffect/crud/getOne/actions';
import { isActionOf } from 'typesafe-actions';
import { Epic, StateObservable } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { RootState } from 'reducers/rootReducer';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { Services } from 'sideEffect/services';
import { combineEpics } from 'redux-observable';
import { enqueueSnackbar as enqueueSnackbarAction } from 'notistack/actions';
import { AjaxError } from 'rxjs/ajax';
import { pipe } from 'rxjs';

export const translateAjaxError = (error: AjaxError) => {
    return error.message === 'ajax error'
        ? (() => {
              if (navigator.onLine) {
                  return 'Server communication error.';
              }
              return 'You are not connected to the internet.';
          })()
        : (error.response && error.response.description) ||
              (() => {
                  const { message } = error;
                  if (message) {
                      if (message.includes('ajax error 4')) {
                          return 'Client Error. Please try again.';
                      }
                      if (message.includes('ajax error 5')) {
                          return 'Server Error. Please try again.';
                      }
                      return 'System Error. Please try again.';
                  }
              })();
};

const filterBySecure = (state$: StateObservable<RootState>) =>
    pipe(
        withLatestFrom(state$.pipe(map(state => (state.basicInfo ? !state.basicInfo.debugFeaturesEnabled : true)))),
        filter(([action, secure]) => !secure),
        map(([action]) => action),
    );

const crudUpdateSuccessNotification: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) =>
    action$.pipe(
        filter(isActionOf(crudUpdateSuccess)),
        filterBySecure(state$),
        map(action => enqueueSnackbarAction({ message: 'Updated', options: { variant: 'success' } })),
    );

const crudCreateSuccessNotification: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) =>
    action$.pipe(
        filter(isActionOf(crudCreateSuccess)),
        filterBySecure(state$),
        map(action => enqueueSnackbarAction({ message: 'Created', options: { variant: 'success' } })),
    );

const crudDeleteSuccessNotification: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) =>
    action$.pipe(
        filter(isActionOf(crudDeleteSuccess)),
        filterBySecure(state$),
        map(action => enqueueSnackbarAction({ message: 'Deleted', options: { variant: 'success' } })),
    );

const crudGetOneFailureNotification: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) =>
    action$.pipe(
        filter(isActionOf(crudGetOneFailure)),
        filterBySecure(state$),
        map(action => enqueueSnackbarAction({ message: 'Does not exist', options: { variant: 'warning' } })),
    );

const crudFailureNotification: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) =>
    action$.pipe(
        filter(isActionOf([crudCreateFailure, crudGetListFailure, crudUpdateFailure, crudDeleteFailure])),
        // Just a preference not to show a notification that flashes as the page redirects.
        filterBySecure(state$),
        filter(
            ({ payload: error }) =>
                !(
                    error &&
                    (error as AjaxError).status &&
                    ((error as AjaxError).status === 401 || (error as AjaxError).status === 409)
                ),
        ),
        map(action => {
            const { payload: error } = action;
            const errorMessage = translateAjaxError(error as AjaxError);
            const detail = (error as AjaxError).response ? (error as AjaxError).response.detail : undefined;
            return enqueueSnackbarAction({
                message: errorMessage,
                options: { variant: 'error', style: { whiteSpace: 'pre-wrap' } },
                detail,
            });
        }),
    );
export default combineEpics(
    crudUpdateSuccessNotification,
    crudCreateSuccessNotification,
    crudDeleteSuccessNotification,
    crudGetOneFailureNotification,
    crudFailureNotification,
);
