import React from 'react';
import compose from 'recompose/compose';
import memoizeOne from 'memoize-one';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';
import { connect } from 'react-redux';
import invert from 'lodash/invert';
import { change, formValueSelector } from 'redux-form';
import withPropsOnChange from 'recompose/withPropsOnChange';
import { RootState } from '../../../../../src/reducers/rootReducer';

import { Dispatch, AnyAction } from 'redux';
import { WithStyles, createStyles, Theme, withStyles, InputLabel, FormControl } from '@material-ui/core';
import withFormContext, { InjectedProps as ReduxFormContextProps } from '../hoc/withFormContext';

import moment from 'moment';
import { EventConfiguration } from './types';
import NumberInput from '../NumberInput';
import EventCalendar from '../BigCalendar';
import { isArray } from 'util';
import { withDateFormat, DateFormatProps } from 'fieldFactory/dateFormat/Broadcasts';
import DateTimePicker from '../DateTimePicker';
import { processContext } from 'bpm/components/processContext';
import { RefProviderProps } from '../RefmanyMultiselectIdsList/index';

type Meta = any; // tslint:disable-line

const components: {
    manymany?: React.SFC<RefProviderProps>;
} = {};

export function registerComponent(name: keyof typeof components, Component: React.SFC<RefProviderProps>) {
    components[name] = Component;
}

const isEmpty = value =>
    typeof value === 'undefined' || value === null || value === '' || (isArray(value) && value.length === 0);
const requiredValidation = (value, _, props) => (isEmpty(value) ? 'Required' : undefined);

const styles = ({ palette, spacing }: Theme) =>
    createStyles({
        root: {
            /* ... */
        },
        formControl: {
            marginTop: spacing(2),
        },
        manymany: {
            marginTop: spacing(1),
        },
        error: {
            color: palette.error.main,
        },
    });
const withPropsOnConfigChange = ({ config }: EventProps) => {
    const fieldConfig = config.fieldMapping;
    const invertedFieldConfig: {
        [writeToField: string]: string;
    } = invert(fieldConfig);
    return {
        fieldConfig,
        invertedFieldConfig,
    };
};

export interface EventProps {
    disabled?: boolean;
    meta: Meta;
    config: EventConfiguration;
}
const makeMapStateToProps = () => {
    const selectors: { [formId: string]: Function } = {};
    const getValuesByStdName = memoizeOne((dateValue, durationValue, attendeesIdsValue): {
        [fname in keyof EventConfiguration['fieldMapping']]: unknown;
    } => {
        return {
            date: dateValue,
            duration: durationValue,
            attendeesIds: attendeesIdsValue,
        };
    });
    const mapStateToProps = (
        state: RootState,
        props: EventProps & ReduxFormContextProps & ReturnType<typeof withPropsOnConfigChange>,
    ) => {
        if (!selectors[props.meta.form]) {
            selectors[props.meta.form] = formValueSelector(props.meta.form);
        }
        const stdFieldNames = Object.keys(props.fieldConfig) as (keyof EventConfiguration['fieldMapping'])[];
        const valuesByStdName: {
            [fname in (typeof stdFieldNames)[0]]: unknown;
        } = getValuesByStdName(
            selectors[props.meta.form](state, props.fieldConfig.date),
            selectors[props.meta.form](state, props.fieldConfig.duration),
            selectors[props.meta.form](state, props.fieldConfig.attendeesIds),
        );

        const valuesByConfiguredName: {
            [configuredName: string]: unknown;
        } = selectors[props.meta.form](state, ...stdFieldNames.map(fname => props.fieldConfig[fname]));

        const formFields: {
            [fname: string]: {
                visited: boolean;
                touched: boolean;
            };
        } | null = (state.form![props.meta.form] || {}).fields || null;
        const syncErrors: {
            [fname: string]: string;
        } | null = (state.form![props.meta.form] || {}).syncErrors || null;

        return {
            valuesByStdName,
            valuesByConfiguredName,
            syncErrors,
            formFields,
        };
    };
    return mapStateToProps;
};
const mapDispatchToProps = (
    dispatch: Dispatch<AnyAction>,
    ownProps: EventProps & ReduxFormContextProps & ReturnType<typeof withPropsOnConfigChange>,
) => {
    const stdFieldKeys = Object.keys(ownProps.fieldConfig) as (keyof EventConfiguration['fieldMapping'])[];
    const changeActionsStandard: {
        [field in keyof EventConfiguration['fieldMapping']]: (value: unknown) => void;
    } = Object.assign(
        {},
        ...stdFieldKeys.map(fname => ({
            [fname]: value => dispatch(change(ownProps.meta.form, ownProps.fieldConfig[fname], value)),
        })),
    );
    const changeActionsFromFieldPointedTo: {
        [fieldPointedTo: string]: (value: unknown) => void;
    } = Object.assign(
        {},
        ...stdFieldKeys.map(fname => ({
            [ownProps.fieldConfig[fname]]: changeActionsStandard[fname],
        })),
    );
    return {
        changeActionsStandard,
        changeActionsFromFieldPointedTo,
    };
};

export interface EventComponentProps
    extends ReturnType<ReturnType<typeof makeMapStateToProps>>,
        ReturnType<typeof withPropsOnConfigChange>,
        ReturnType<typeof mapDispatchToProps>,
        WithStyles<typeof styles>,
        ReduxFormContextProps,
        DateFormatProps,
        EventProps {}

class EventComponent extends React.Component<EventComponentProps> {
    componentDidMount() {
        const { changeActionsFromFieldPointedTo, config, _reduxForm } = this.props;
        Object.keys(changeActionsFromFieldPointedTo).forEach(fname => {
            _reduxForm.registerField(fname, 'Field');
        });
        config.requiredFields.forEach(f => {
            const fnamePointedTo = config.fieldMapping[f];
            _reduxForm.register(fnamePointedTo, 'Field', () => [requiredValidation]);
        });
    }
    renderDateField = () => {
        const {
            syncErrors,
            _reduxForm,
            disabled,
            changeActionsStandard,
            valuesByStdName,
            fieldConfig,
            formFields,
        } = this.props;
        return (
            <DateTimePicker
                // elStyle={{ width: '100%' }}
                // fullWidth={true}
                options={{ fullWidth: true }}
                label="Date"
                source={fieldConfig.date}
                input={{
                    value: valuesByStdName.date || '',
                    onBlur: value => {
                        changeActionsStandard.date(value);
                        _reduxForm.touch(fieldConfig.date);
                    },
                    onChange: value => {
                        changeActionsStandard.date(value);
                    },
                }}
                meta={{
                    touched: formFields && formFields[fieldConfig.date],
                    error: syncErrors && syncErrors[fieldConfig.date],
                }}
                disabled={disabled}
            />
        );
    };
    renderDurationField = () => {
        const {
            syncErrors,
            _reduxForm,
            disabled,
            changeActionsStandard,
            valuesByStdName,
            fieldConfig,
            formFields,
        } = this.props;
        return (
            <NumberInput
                options={{ fullWidth: true }}
                isInteger={true}
                label="Duration (minutes)"
                source={fieldConfig.duration}
                input={{
                    value: valuesByStdName.duration || '',
                    onBlur: e => {
                        const value: string = e.target.value;
                        const n = parseInt(value, 10);
                        changeActionsStandard.duration(n);
                        _reduxForm.touch(fieldConfig.duration);
                    },
                    onChange: e => {
                        const value: string = e.target.value;
                        const n = parseInt(value, 10);
                        changeActionsStandard.duration(n);
                    },
                }}
                meta={{
                    touched: formFields && formFields[fieldConfig.duration],
                    error: syncErrors && syncErrors[fieldConfig.duration],
                }}
                disabled={disabled}
            />
        );
    };
    renderAttendeesField = () => {
        const {
            // syncErrors,
            _reduxForm,
            disabled,
            changeActionsStandard,
            valuesByStdName,
            fieldConfig,
            classes,
            config: { attendeesInModal = false },
            // formFields
        } = this.props;
        if (!attendeesInModal) {
            return null;
        }
        const RefmanyMultiselectIdsList = components.manymany as React.SFC<RefProviderProps>;
        return (
            <div>
                <FormControl className={classes.formControl}>
                    <InputLabel shrink={true}>Attendees</InputLabel>
                    <div className={classes.manymany}>
                        <RefmanyMultiselectIdsList
                            type="Input(NoBackingEntity)"
                            renderer="CHIP"
                            label="Attendees"
                            input={{
                                value: valuesByStdName.attendeesIds || [],
                                onBlur: (ids: string[]) => {
                                    changeActionsStandard.attendeesIds(ids);
                                    _reduxForm.touch(fieldConfig.attendeesIds);
                                },
                                onChange: changeActionsStandard.attendeesIds,
                            }}
                            meta={{}}
                            source={fieldConfig.attendeesIds}
                            reference="User"
                            commitChanges={false}
                            disabled={disabled}
                        />
                    </div>
                </FormControl>
            </div>
        );
    };
    getDateTimeFormat = () => {
        const { dateFormat } = this.props;
        return `${dateFormat} h:mm A`;
    };
    getDateTimeText = () => {
        const { valuesByStdName, classes } = this.props;
        return valuesByStdName.date ? (
            moment(valuesByStdName.date as Date).format(this.getDateTimeFormat())
        ) : (
            <span className={classes.error}>(no start time)</span>
        );
    };
    getDurationText = () => {
        const { valuesByStdName, classes } = this.props;
        return valuesByStdName.duration ? (
            moment.duration(valuesByStdName.duration as number, 'minutes').humanize()
        ) : (
            <span className={classes.error}>(no duration)</span>
        );
    };
    getEndDateText = () => {
        const {
            valuesByStdName: { date, duration },
        } = this.props;
        if (!date || !duration) {
            return '';
        }
        return moment(date as Date)
            .add(duration as number, 'minutes')
            .format(this.getDateTimeFormat());
    };
    getParticipantsSummaryText = () => {
        const {
            valuesByStdName: { attendeesIds = [] },
        } = this.props;
        const numberOfParticipants = (attendeesIds as string[]).length;
        const pluralPostFix = numberOfParticipants > 1 ? 's' : '';
        return numberOfParticipants > 0 ? (
            <span>
                <b>{numberOfParticipants}</b> Participant{pluralPostFix}
            </span>
        ) : (
            'No participants'
        );
    };
    renderEventDescription = () => {
        const dateTxt = this.getDateTimeText();
        const endTxt = this.getEndDateText();
        const participantsTxt = this.getParticipantsSummaryText();
        return (
            <div>
                <div>
                    From <b>{dateTxt}</b>
                </div>
                <div>
                    {endTxt ? (
                        <span>
                            To <b>{endTxt}</b>
                        </span>
                    ) : (
                        ''
                    )}
                </div>
                <div>{participantsTxt}</div>
            </div>
        );
    };
    render() {
        const {
            changeActionsStandard,
            valuesByStdName,
            config: { displayInputsOnBase },
            disabled,
        } = this.props;
        return (
            <div style={{ width: '100%' }}>
                {this.renderEventDescription()}
                {displayInputsOnBase.date ? this.renderDateField() : null}
                {displayInputsOnBase.duration ? this.renderDurationField() : null}
                {displayInputsOnBase.attendeesIds ? this.renderAttendeesField() : null}
                <processContext.Consumer>
                    {({ id, taskId }) => {
                        return (
                            <EventCalendar
                                disabled={disabled}
                                processId={id}
                                taskId={taskId}
                                changeActionsStandard={changeActionsStandard}
                                currentStart={valuesByStdName.date as Date | undefined}
                                currentDuration={valuesByStdName.duration as number | undefined}
                                renderAttendeesField={this.renderAttendeesField}
                            />
                        );
                    }}
                </processContext.Consumer>
            </div>
        );
    }
}

export const withFieldConfigs = withPropsOnChange(['config'], withPropsOnConfigChange);

const Event: React.SFC<EventProps> = compose(
    branch(props => !props.config, renderNothing),
    withFieldConfigs,
    withFormContext,
    connect(
        makeMapStateToProps,
        mapDispatchToProps,
    ),
    withStyles(styles),
    withDateFormat,
)(EventComponent);

export default Event;
