import { RootState } from 'reducers/rootReducer';
import { createSelector } from 'reselect';
import { fromNullable, fromPredicate } from 'fp-ts/lib/Option';
import { evaluateFilterString } from 'fieldFactory/popovers/PopoverRefInput/evaluteFilterString';
import { currentUserProperties } from 'expressions/contextUtils/currentUser';

interface EnvConfig {
    css?: string;
    loginText?: string;
    taskDrawerTaskLinkUrl?: string;
    noAnonWelcomeText?: boolean;
    footerText?: string;
    appTitle?: string;
    loginHtml?: string;
}
type BasicInfo = RootState['basicInfo'];

const getEnvConfig = (basicInfo: BasicInfo) =>
    fromNullable(basicInfo)
        .mapNullable(bi => bi.application)
        .chain(a => fromPredicate<EnvConfig>(Boolean)(a.config));

const getEnvConfigCss = (basicInfo: BasicInfo): string | null =>
    getEnvConfig(basicInfo)
        .chain(envConfig => fromPredicate<string | null>(Boolean)(envConfig.css))
        .getOrElse(null);

const getLoginConfiguredText = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .chain(envConfig => fromPredicate<string>(Boolean)(envConfig.loginText))
        .getOrElse(null);

const getFooterText = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .chain(envConfig => fromPredicate<string>(Boolean)(envConfig.footerText))
        .getOrElse(null);

const getLoginHtml = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .chain(envConfig => fromPredicate<string>(Boolean)(envConfig.loginHtml))
        .getOrElse(null);

const getAppTitle = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .chain(envConfig => fromPredicate<string>(Boolean)(envConfig.appTitle))
        .getOrElse(null);

const getTaskDrawerTaskLinkTemplate = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .chain(envConfig => fromPredicate<string>(Boolean)(envConfig.taskDrawerTaskLinkUrl))
        .getOrElse(null);

const getHasNoAnonWelcomeMessage = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .map(envConfig => envConfig.noAnonWelcomeText || false)
        .getOrElse(false);

export const getHasNoAnonWelcomeMessageSelector = createSelector(
    (state: RootState) => state.basicInfo,
    getHasNoAnonWelcomeMessage,
);
export const getEnvConfigCssSelector = createSelector(
    (state: RootState) => state.basicInfo,
    getEnvConfigCss,
);

export const getLoginConfiguredTextSelector = createSelector(
    (state: RootState) => state.basicInfo,
    getLoginConfiguredText,
);

export const getFooterTextSelector = createSelector(
    (state: RootState) => state.basicInfo,
    getFooterText,
);

export const getAppTitleSelector = createSelector(
    (state: RootState) => state.basicInfo,
    getAppTitle,
);

export const getLoginButtonSelector = createSelector(
    (state: RootState) => state.basicInfo,
    getLoginHtml,
);

const getTaskDrawerTaskLinkTemplateSelector = createSelector(
    (state: RootState) => state.basicInfo,
    getTaskDrawerTaskLinkTemplate,
);

export const getWillHaveSubmitButton = (state: RootState) =>
    fromNullable(state.viewConfig.views._TASK_LIST)
        .mapNullable(v => v.searchFields)
        .mapNullable(sf => Object.keys(sf).length > 0)
        .getOrElse(false);

export const getTaskDrawerTaskLinkUrlSelector = createSelector(
    getTaskDrawerTaskLinkTemplateSelector,
    (state: RootState) => state.viewConfig,
    getWillHaveSubmitButton,
    (template, viewConfig, taskPageWillHaveSubmitButton) => {
        if (template) {
            return evaluateFilterString(template, {
                currentUser: viewConfig.user && viewConfig.user.login,
                ...currentUserProperties(viewConfig),
                taskPageWillHaveSubmitButton,
            });
        }
        return null;
    },
);
