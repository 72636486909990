import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { changeOnline } from 'connectivity/actions';
import Banner from './Banner';
import { checkLastRequestTime } from 'reauth-interval-and-retries/check-last-request/actions';

interface DetectConnectivityProps {}
const DetectConnectivity: React.SFC<DetectConnectivityProps> = props => {
    const dispatch = useDispatch();
    const isOnline = useSelector((state: RootState) => state.connectivityStatus);

    const connectivityHandler = useCallback(() => {
        dispatch(changeOnline(navigator.onLine));
        if (navigator.onLine) {
            // try to salvage session immediately to beat other 401s if our token expired.
            dispatch(checkLastRequestTime());
        }
    }, [dispatch]);
    useEffect(() => {
        window.addEventListener('online', connectivityHandler);
        window.addEventListener('offline', connectivityHandler);
        return () => {
            window.removeEventListener('online', connectivityHandler);
            window.removeEventListener('offline', connectivityHandler);
        };
    }, [connectivityHandler]);

    return (
        <div style={{ position: 'fixed', left: 0, right: 0, top: 0, zIndex: 5000 }}>
            <Banner isOnline={isOnline} />
        </div>
    );
};
export default DetectConnectivity;
