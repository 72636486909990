import React, { useEffect, useState } from 'react';
import { Dialog, makeStyles, Theme, createStyles, Button, Typography } from '@material-ui/core';
import TaskFormErrorLIs from 'bpm/components/TaskDetail/TaskForm/util/TaskFormErrorLIs';
import { TaskForm } from 'reducers/rootReducer';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        error: {
            color: theme.palette.error.main,
        },
    }),
);

interface ErrorPopupProps {
    formErrors: {
        [field: string]: string[];
    } | null;
    formDefinition: TaskForm;
}

const ErrorPopup: React.SFC<ErrorPopupProps> = props => {
    const { formErrors, formDefinition } = props;
    const classes = useStyles(props);
    const [open, setOpen] = useState(Boolean(formErrors));
    useEffect(() => {
        if (formErrors) {
            setOpen(true);
        }
    }, [formErrors]);
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            TransitionProps={
                {
                    // https://github.com/dequelabs/axe-core/issues/146
                    role: 'presentation',
                } as any
            }
        >
            <div style={{ padding: '.5em', paddingRight: '1em' }}>
                <Typography variant="h5" component="div">
                    Errors:
                </Typography>
                <ul>
                    <TaskFormErrorLIs
                        classes={{
                            li: classes.error,
                        }}
                        formErrors={formErrors}
                        formDefinition={formDefinition}
                    />
                </ul>
            </div>
            <Button style={{ margin: '2px' }} variant="contained" color="primary" onClick={() => setOpen(false)}>
                Close
            </Button>
        </Dialog>
    );
};
export default ErrorPopup;
