import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@material-ui/core';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import uniqueId from 'lodash/uniqueId';

const styles = (theme: Theme) =>
    createStyles({
        formControl: {
            width: '100%',
        },
    });

interface SelectProps extends WithStyles<typeof styles> {
    input: WrappedFieldInputProps;
    meta?: WrappedFieldMetaProps;
    label: string;
    source?: string;
    renderLabel?: boolean;
}
const NullableBooleanComponent: React.SFC<SelectProps> = ({
    source,
    classes,
    input,
    meta: { touched, error },
    renderLabel = true,
    label,
}) => {
    const ariaErrormessageRef = React.useRef(uniqueId('nullable-boolean-ErrorMsg'));
    const SelectProps: React.InputHTMLAttributes<HTMLDivElement> = {
        'aria-label': label,
        'aria-invalid': error,
        'aria-describedby': error ? ariaErrormessageRef.current : undefined,
    };
    const inputId = `${source}-nullable-boolean`;
    const inputName = `${source}`;
    return (
        <FormControl fullWidth={true} /* className={classes.formControl} */>
            {renderLabel && (
                <InputLabel shrink={true} htmlFor={inputId}>
                    {label}
                </InputLabel>
            )}
            <Select
                value={input.value ? 'true' : input.value === false ? 'false' : 'null'}
                onChange={e => {
                    const value = e.target.value;
                    console.log(value);
                    switch (value) {
                        case 'null': {
                            input.onBlur(null);
                            break;
                        }
                        case 'true': {
                            input.onBlur(true);
                            break;
                        }
                        case 'false': {
                            input.onBlur(false);
                            break;
                        }
                    }
                }}
                SelectDisplayProps={SelectProps}
                inputProps={{
                    name: inputName,
                    id: inputId,
                }}
            >
                <MenuItem value="null">
                    <em>None</em>
                </MenuItem>
                <MenuItem className="dropdownMenuItem" value="true">
                    True
                </MenuItem>
                <MenuItem className="dropdownMenuItem" value="false">
                    False
                </MenuItem>
            </Select>
            {touched && error && (
                <FormHelperText id={SelectProps['aria-describedby']} error={Boolean(error)}>
                    Error: {error}
                </FormHelperText>
            )}
        </FormControl>
    );
};
const NullableBoolean = withStyles(styles)(NullableBooleanComponent);

export default NullableBoolean;
