import ViewConfig from '../../reducers/ViewConfigType';
import { fromEither, Option, fromPredicate } from 'fp-ts/lib/Option';
import { tryCatch } from 'fp-ts/lib/Either';
import { mapOption } from 'fp-ts/lib/Array';
import { getValidationFromConfig } from '../../expressions/formValidation/evaluateFromJson';
import { SpelCompiledExpression /* SpelNode */ } from '../../expressions/evaluate';
import { parsingOrValidationErrMsg } from '../../expressions/formValidation';
import getFieldsInAst from 'casetivity-shared-js/lib/spel/getFieldsInAst';
import { SpelExpressionEvaluator } from 'spel2js';
import setupValuesetFieldsRequired from 'viewConfigCalculations/util/setupValuesetFieldsRequired';
import getValueSetCodeLiterals from 'expressions/getFieldsInAst/getValuesetCodeLiterals';
import removeMethodHashes from 'casetivity-shared-js/lib/spel/getFieldsInAst/removeMethodHashes';
const getValidation = (key: string, resource: string, viewConfig: ViewConfig, viewConfiguration: Option<string>) =>
    viewConfiguration
        .chain(fromPredicate<string>(Boolean))
        .map(getValidationFromConfig)
        // prints errors for the failed configs
        .map(e =>
            e.mapLeft(error => {
                console.error(parsingOrValidationErrMsg(error)); // tslint:disable-line
                return error;
            }),
        )
        .chain(fromEither)
        .map(varr => varr.map(({ message, expression }) => ({ message, expression: removeMethodHashes(expression) })))
        .map(varr =>
            mapOption(varr, ({ message, expression }) =>
                fromEither(
                    tryCatch(
                        () => {
                            const compiledExpression = (SpelExpressionEvaluator.compile(
                                expression,
                            ) as SpelCompiledExpression)._compiledExpression;
                            return {
                                expression,
                                message,
                                fieldsRequired: getFieldsInAst(expression)(compiledExpression),
                                valuesetLiterals: getValueSetCodeLiterals(expression)(compiledExpression),
                            };
                        },
                        (e: Error) => {
                            // prints errors for the failed SPEL compilation
                            console.error('Error parsing SPEL entityConfig validation expression', e); // tslint:disable-line
                            return e;
                        },
                    ).chain(setupValuesetFieldsRequired(viewConfig, resource, expression)),
                ),
            ),
        )
        .map(v => ({ [key]: v }));

export default getValidation;
