import * as taskPotentialUsers from '../actions';
import { ActionType, getType } from 'typesafe-actions';
import { RemoteData, failure, pending, success } from '@devexperts/remote-data-ts';
import orderBy from 'lodash/orderBy';
import { PotentialUser } from 'bpm/potentialUsers/reducer';
import { RootAction } from 'actions/rootAction';

export type taskPotentialUsersAction = ActionType<typeof taskPotentialUsers>;

export interface PotentialUsers {
    byLogin: {
        [login: string]: PotentialUser;
    };
    logins: string[];
}
type TaskPotentialUsers = {
    [taskId: string]: RemoteData<string, PotentialUsers>;
};

const reducer = (state: TaskPotentialUsers = {}, action: RootAction): TaskPotentialUsers => {
    switch (action.type) {
        case getType(taskPotentialUsers.getTaskPotentialUsers):
            const {
                payload: { taskId },
            } = action;
            return {
                ...state,
                // lets keep around the last result even while loading and overwrite only on success.
                [taskId]: state[taskId] && state[taskId].isSuccess() ? state[taskId] : pending,
            };
        case getType(taskPotentialUsers.getTaskPotentialUsersFailure):
            return {
                ...state,
                // on failure, lets just resort to the list we already have for this task.
                [action.requestPayload.taskId]:
                    state[taskId] && state[taskId].isSuccess() ? state[taskId] : failure(action.error.message),
            };
        case getType(taskPotentialUsers.getTaskPotentialUsersSuccess):
            const sorted = orderBy(action.payload.potentialUsers, [u => (u.title || '').toLowerCase()], ['asc']);
            const logins = sorted.map(s => s.login);
            const byLogin = {};
            sorted.forEach(potentialUser => {
                byLogin[potentialUser.login] = potentialUser;
            });
            return {
                ...state,
                [action.requestPayload.taskId]: success({
                    byLogin,
                    logins,
                }),
            };
        default:
            return state;
    }
};
export default reducer;
