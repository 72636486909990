import * as potentialUsers from './actions';
import { getType } from 'typesafe-actions';
import { initial, RemoteData, failure, pending, success } from '@devexperts/remote-data-ts';
import { ErrorDetails } from 'sagas/util/sagaFactory';
import orderBy from 'lodash/orderBy';
import { RootAction } from 'actions/rootAction';

export interface PotentialUser {
    id: string;
    login: string;
    title: string;
    active: boolean;
}

interface AllPotentialUsers {
    byLogin: {
        [login: string]: PotentialUser;
    };
    byId: {
        [id: string]: PotentialUser;
    };
    logins: string[];
}
type PotentialUsersState = RemoteData<ErrorDetails, AllPotentialUsers>;
const reducer = (state: PotentialUsersState = initial, action: RootAction): PotentialUsersState => {
    switch (action.type) {
        case getType(potentialUsers.getPotentialUsers):
            if (state.isInitial()) {
                return pending;
            }
            // we only fetch once.
            return state;
        case getType(potentialUsers.getPotentialUsersFailure):
            return failure(action.error);
        case getType(potentialUsers.getPotentialUsersSuccess):
            const sorted = orderBy(action.payload, [u => (u.title || '').toLowerCase()], ['asc']);
            const logins = sorted.map(s => s.login);
            const byLogin = {};
            sorted.forEach(potentialUser => {
                byLogin[potentialUser.login] = potentialUser;
            });
            const byId = {};
            sorted.forEach(potentialUser => {
                byId[potentialUser.id] = potentialUser;
            });
            return success({
                byLogin,
                logins,
                byId,
            });
        default:
            return state;
    }
};
export default reducer;
