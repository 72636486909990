import React, { useState, useEffect, useCallback, CSSProperties } from 'react';
import { FormContextProvider } from 'bpm/components/TaskDetail/TaskForm/FormContext';
import { TaskForm, RootState } from 'reducers/rootReducer';
import { useSelector } from 'react-redux';
import { Button, Card } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import ExpressionTestArea from '../util/ExpressionTestArea';
import DownArrow from '@material-ui/icons/ArrowDownward';
import JSONEditorDemo from 'expression-tester/JsonEditorReact';
import { dropzoneStyle } from 'components/custom/BulkUpload';
import JsonDownload from 'expression-tester/util/JsonDownload';

export const stagedFormDefinitionContext = React.createContext<{
    formDefinition: TaskForm;
    setFormDefinition: (formDefinition: TaskForm) => void;
} | null>(null);

type FAC = (args: { formDefinition: TaskForm }) => JSX.Element;
type BpmFormExpressionTesterProps =
    | {
          contextType: 'start-form';
          formDefinition: TaskForm;
          children: FAC;
      }
    | {
          contextType: 'existing-task-form';
          taskId: string;
          relatedEntityResource?: string;
          relatedEntityId?: string;
          children: FAC;
      };
const BpmFormExpressionTester: React.SFC<BpmFormExpressionTesterProps> = props => {
    const open = useSelector((state: RootState) => state.expressionTesterOpen);
    const formDefinition = useSelector((state: RootState) => {
        if (props.contextType === 'start-form') {
            return props.formDefinition;
        }
        return state.taskForms[props.taskId];
    });
    const [formJson, setFormJson] = useState<TaskForm | null>(formDefinition || null);

    const [formJsonInUse, setFormJsonInUse] = useState<TaskForm | null>(formDefinition || null);
    useEffect(() => {
        setFormJson(formDefinition);
        setFormJsonInUse(formDefinition);
    }, [formDefinition]);

    const onDrop = useCallback(
        acceptedFiles => {
            if (acceptedFiles.length === 0) {
                return;
            }
            var reader = new FileReader();
            reader.onload = function(event: any) {
                setFormJson(JSON.parse(event.target.result));
            };
            reader.readAsText(acceptedFiles[0]);
        },
        [setFormJson],
    );
    const {
        getRootProps: dropzone_getRootProps,
        getInputProps: dropzone_getInputProps,
        isDragActive: dropzone_isDragActive,
    } = useDropzone({ onDrop });
    return (
        <div>
            {open && (
                <Card>
                    <div style={{ padding: '1em' }}>
                        Edit the form definition below, and click on "Load into form" to apply your changes.
                    </div>
                    <JSONEditorDemo json={formJson} onChangeJSON={setFormJson} />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            padding: '1em',
                        }}
                    >
                        <Button
                            disabled={formJsonInUse === formJson}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setFormJsonInUse(formJson);
                            }}
                        >
                            Load into form&nbsp;
                            <DownArrow />
                        </Button>
                        <div style={{ display: 'flex' }}>
                            <div
                                {...dropzone_getRootProps()}
                                style={{ ...dropzoneStyle, padding: '0px 1em' } as CSSProperties}
                            >
                                <input {...dropzone_getInputProps()} />
                                {dropzone_isDragActive ? (
                                    <p> >>>>>>>>>>>>>> Drop your file here {'<<<<<<<<<<<<<<'} </p>
                                ) : (
                                    <p>Drag and drop, or click to select JSON file import</p>
                                )}
                            </div>
                            {formJsonInUse === formJson && <JsonDownload json={formJsonInUse} />}
                        </div>
                    </div>
                </Card>
            )}
            {open && <br />}
            <stagedFormDefinitionContext.Provider
                value={{
                    setFormDefinition: setFormJson,
                    formDefinition,
                }}
            >
                {props.contextType === 'start-form' ? (
                    <FormContextProvider contextType={props.contextType} formDefinition={formJsonInUse}>
                        {open && <ExpressionTestArea contextType={props.contextType} formDefinition={formJsonInUse} />}
                        {props.children({ formDefinition: formJsonInUse })}
                    </FormContextProvider>
                ) : (
                    <FormContextProvider
                        contextType={props.contextType}
                        taskId={props.taskId}
                        relatedEntityId={props.relatedEntityId}
                        relatedEntityResource={props.relatedEntityResource}
                        overrideFormDefinition={formJsonInUse}
                    >
                        {open && <ExpressionTestArea contextType={props.contextType} formDefinition={formJsonInUse} />}
                        {props.children({ formDefinition: formJsonInUse })}
                    </FormContextProvider>
                )}
            </stagedFormDefinitionContext.Provider>
        </div>
    );
};
export default BpmFormExpressionTester;
