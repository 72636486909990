import React, { useMemo, useEffect } from 'react';
import { RootState } from 'reducers/rootReducer';
import { fromNullable } from 'fp-ts/lib/Option';
import { getValueSetForFieldExpr } from '../utils/viewConfigUtils';
import { useSelector, useDispatch } from 'react-redux';
import DeferredSpinner from 'components/DeferredSpinner';
import { createSelector } from 'reselect';
import uniq from 'lodash/uniq';
import { loadValueSets } from 'actions/valueSetsActions';

const createGetSearchValuesetsSelector = () => {
    return createSelector(
        (state: RootState, viewName: string) => state.viewConfig,
        (state: RootState, viewName: string) => viewName,
        (viewConfig, viewName) => {
            return fromNullable(viewConfig.views[viewName])
                .mapNullable(v => v.searchFields)
                .map(sf =>
                    Object.values(sf).flatMap(sf => {
                        const valueset =
                            sf.entity && getValueSetForFieldExpr(viewConfig, sf.entity, sf.field, 'POP_LAST');
                        if (valueset) {
                            return [valueset];
                        }
                        return [];
                    }),
                )
                .getOrElse([]);
        },
    );
};
const createGetAllSearchValuesetsLoadedSelector = (state: RootState, searchValuesets: string[]) => {
    return searchValuesets.reduce((prev, vsCode) => {
        const vs = state.valueSets && state.valueSets[vsCode];
        return Boolean(!!vs && !!vs.allConceptsLoaded && prev);
    }, true);
};

interface BranchSearchValuesetsLoadingProps {
    viewName: string;
    fetchValuesets?: boolean;
}
const BranchSearchValuesetsLoading: React.SFC<BranchSearchValuesetsLoadingProps> = props => {
    const dispatch = useDispatch();
    const getSearchValuesets = useMemo(createGetSearchValuesetsSelector, []);
    const searchValuesets = useSelector((state: RootState) => getSearchValuesets(state, props.viewName));
    const allValuesetsLoaded = useSelector((state: RootState) =>
        createGetAllSearchValuesetsLoadedSelector(state, searchValuesets),
    );
    useEffect(() => {
        if (!allValuesetsLoaded && props.fetchValuesets) {
            const valueSetCodes = uniq(searchValuesets).map(valueset => ({ valueSet: valueset }));
            if (valueSetCodes.length > 0) {
                dispatch(loadValueSets(valueSetCodes));
            }
        }
    }, []); // eslint-disable-line
    return (
        <div>
            {!allValuesetsLoaded && <DeferredSpinner />}
            <div
                style={{
                    visibility: allValuesetsLoaded ? undefined : 'hidden',
                }}
            >
                {props.children}
            </div>
        </div>
    );
};
export default BranchSearchValuesetsLoading;
