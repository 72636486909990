import React from 'react';
import { stringify } from 'query-string';
import { Link } from 'react-router-dom';
import Clear from '@material-ui/icons/Clear';
import { IconButton } from '@material-ui/core';
import { crudGetOne as crudGetOneAction } from 'sideEffect/crud/getOne/actions';
import GenericList, { getRenderAtRowEnd } from '../../../../../components/generics/genericList/index';
import getRenderer from '../../../../../components/generics/genericList/renderList';
import { customShowRedirects } from '../../../../../components/generics/overrides';
import ViewConfig from 'reducers/ViewConfigType';
import difference from 'lodash/difference';
import EntityInspect from 'components/generics/hoc/EntityInspect';
import { allowsEdit, getAccessLevelForEntity } from 'components/generics/utils/viewConfigUtils';

const mapToSelectedData = (value: string[], allData: {} = {}): {} =>
    Object.assign({}, ...value.filter(id => allData[id]).map(id => ({ [id]: allData[id] })));

const RemoveFromList: React.SFC<{ onClick: () => void }> = props => (
    <IconButton
        onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            props.onClick();
        }}
        aria-label={'Remove from list'}
    >
        <Clear />
    </IconButton>
);
export interface ListComponentProps {
    expansions: string[];
    noRecentlyVisited?: boolean;
    basePath: string;
    singleSelect?: boolean;
    disabled?: boolean;
    label: string;
    actions: any; // tslint:disable-line
    hasCreate: boolean;
    multiSelectable: boolean;
    updateUrlFromFilter: boolean;
    viewName: string;
    name: string;
    resource: string;
    filter: {};
    viewConfig: ViewConfig;
    source: string;
    allData: {
        [id: number]: { id: number };
    };
    input: {
        value: string[];
        onBlur: (value: string[]) => void;
    };
    crudGetOne: typeof crudGetOneAction;
    useCheckboxes?: boolean;
}
interface ListComponentState {
    location: { pathname: string; search: string };
    selected: {
        [key: string]: {
            title?: string;
        };
    };
}
class ListComponent extends React.Component<ListComponentProps, ListComponentState> {
    static listRenderer = getRenderer({}, {});
    state: ListComponentState = {
        location: {
            pathname: `/${this.props.resource}`,
            search: `?${this.props.filter ? stringify({ filter: JSON.stringify(this.props.filter) }) : ''}`,
        },
        selected: this.getSelectedValues(this.props),
    };

    componentDidMount() {
        this.fetchInitialValues();
    }
    componentWillReceiveProps(nextProps: ListComponentProps) {
        const incomingValue: string[] =
            typeof nextProps.input.value === 'string'
                ? nextProps.input.value
                    ? JSON.parse(nextProps.input.value)
                    : []
                : nextProps.input.value || [];

        const fetchNecessaryData = (state = this.state) => {
            incomingValue.forEach(id => {
                if (!state.selected[id]) {
                    this.getOne(id);
                }
            });
        };
        if (
            this.props.allData !== nextProps.allData ||
            (this.props.input.value || []).length !== (nextProps.input.value || []).length ||
            (this.props.input.value && this.props.input.value.some(v => nextProps.input.value.indexOf(v) === -1))
        ) {
            this.setState(
                {
                    selected: this.getSelectedValues(nextProps),
                },
                fetchNecessaryData,
            );
        } else {
            fetchNecessaryData();
        }
    }

    fetchInitialValues(props: ListComponentProps = this.props) {
        if (!props.input.value) {
            return;
        }
        let initialValues: string[] = [];
        if (typeof props.input.value === 'string') {
            initialValues = props.input.value ? JSON.parse(props.input.value) : [];
        } else {
            initialValues = props.input.value;
        }
        initialValues.forEach(id => this.getOne(id));
    }
    getOne(id: string) {
        this.props.crudGetOne({
            id,
            resource: this.props.resource,
            view: -1,
            appendExpansions: this.props.expansions,
        });
    }
    getSelectedValues(props: ListComponentProps = this.props) {
        return !props.input.value
            ? {}
            : typeof props.input.value === 'string'
            ? mapToSelectedData(props.input.value ? JSON.parse(props.input.value) : [], props.allData)
            : mapToSelectedData(props.input.value, props.allData);
    }

    viewHasSearchDefined = () => {
        const { viewName, viewConfig } = this.props;
        return (
            viewConfig.views[viewName] &&
            viewConfig.views[viewName].searchFields &&
            Object.keys(viewConfig.views[viewName].searchFields).length > 0
        );
    };
    hasEditPermission = () => {
        const { resource, viewConfig } = this.props;
        return allowsEdit(getAccessLevelForEntity(viewConfig, resource));
    };
    render() {
        const { disabled = false, useCheckboxes, filter } = this.props;
        const hasSearchDefined = Object.keys(filter).length > 0;
        return (
            <div>
                <EntityInspect
                    reference={this.props.resource}
                    formId={`task-form-list-${this.props.viewName}-${this.props.label}`}
                    renderComponent={({
                        formId,
                        reference,
                        keyForReload,
                        onRowSelect, // this we WONT use here.
                        selectId,
                        selectedId,
                    }) => (
                        <GenericList
                            {...{
                                ...this.props,
                                isPopover: false,
                                showImmediately: hasSearchDefined,
                                alwaysPreventInitialSearch: !hasSearchDefined && this.viewHasSearchDefined(),
                                hasCreate: !disabled && this.props.hasCreate,
                                formId: formId,
                                multiSelectable: !disabled && useCheckboxes,
                                selectedData: !disabled && useCheckboxes ? this.getSelectedValues() : undefined,
                                onRowSelect:
                                    customShowRedirects[reference] &&
                                    customShowRedirects[reference].find(r => r._isRowClick)
                                        ? undefined
                                        : (selectedData, allData) => selectedData[0] && selectId(selectedData[0].id),
                                renderList: r =>
                                    ListComponent.listRenderer({
                                        ...r,
                                        single: this.props.singleSelect,
                                        renderAtRowEnd: getRenderAtRowEnd({
                                            displayPopoverEditButton: false,
                                            rowButtons: null,
                                        }),
                                        onRowSelectBulk:
                                            useCheckboxes && !disabled
                                                ? (selected, allData) => {
                                                      difference(
                                                          selected.map(e => e.id),
                                                          Object.keys(this.state.selected),
                                                      ).forEach(newId => {
                                                          this.getOne(newId);
                                                      });
                                                      this.setState(
                                                          {
                                                              selected: Object.assign(
                                                                  {},
                                                                  ...selected.map(data => ({ [data.id]: data })),
                                                              ),
                                                          },
                                                          () =>
                                                              this.props.input.onBlur(Object.keys(this.state.selected)),
                                                      );
                                                  }
                                                : undefined,
                                    }),
                                fakePush: location => {
                                    this.setState(state => ({ ...state, location }));
                                },
                                location: this.state.location,
                                title: this.props.label,
                            }}
                        />
                    )}
                />
                <ul>
                    {Object.keys(this.state.selected).map((key: string) => (
                        <li key={key}>
                            <div style={{ display: 'flex' }}>
                                <Link
                                    to={`/${this.props.resource}/${key}${this.hasEditPermission() ? '' : '/show'}`}
                                    style={{ paddingTop: 15 }}
                                >
                                    {this.state.selected[key].title}
                                </Link>
                                {!disabled && (
                                    <RemoveFromList
                                        onClick={() => {
                                            const { [key]: toRemove, ...rest } = this.state.selected;
                                            this.setState({ selected: rest }, () =>
                                                this.props.input.onBlur(Object.keys(rest)),
                                            );
                                        }}
                                    />
                                )}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}

export default ListComponent;
