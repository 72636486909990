import React from 'react';
import get from 'lodash/get';
import {
    FormGroup,
    FormControlLabel,
    Checkbox,
    InputLabel,
    FormControl,
    WithStyles,
    withStyles,
    Theme,
    createStyles,
    FormHelperText,
} from '@material-ui/core';
import { Concept } from './ValueSelectDownshift';
import { valueSetsManyHoc } from './ValuesetManySelectDownshift';
import { ValuesetKeeperArounder } from './ValuesetKeeperArounder';
import compose from 'recompose/compose';
import uniqueId from 'lodash/uniqueId';

type Input = any; // tslint:disable-line
type Meta = any; // tslint:disable-line

const styles = ({ palette, spacing }: Theme) =>
    createStyles({
        checkBox: {
            width: 24,
            height: 24,
        },
        error: {
            color: palette.error.main,
            fontSize: '0.75rem',
        },
    });

interface ConnectedComponentProps {
    valueSet?: string;
    resource?: string;
    source: string;
    shouldFetchValueset?: boolean;
    disabled?: boolean;
    record?: {};
    label: string;
    direction?: 'HORIZONTAL' | 'VERTICAL';
    ariaInputProps?: {};
    renderLabel?: boolean;
}

interface MultiCheckboxProps extends ConnectedComponentProps, WithStyles<typeof styles> {
    input: Input;
    meta: Meta;
    dataTableByDisplay: {
        [display: string]: Concept;
    };
}

interface MultiCheckboxState {
    selectedIds: (string | number)[];
    inputValue: string;
}

class MultiCheckbox extends React.Component<MultiCheckboxProps, MultiCheckboxState> {
    private helperTextId = uniqueId('multi-valueset-checkbox-errorid');
    static defaultProps = {
        ariaInputProps: {},
        renderLabel: true,
    };
    public valuesetKeeperArounder: ValuesetKeeperArounder = new ValuesetKeeperArounder();

    getSelectedIds = () => {
        const { input, record, source } = this.props;
        const conceptIds = (input && input.value) || get(record, source) || [];
        this.valuesetKeeperArounder.addAll(typeof conceptIds === 'string' ? JSON.parse(conceptIds) : conceptIds);
        return conceptIds;
    };
    isChecked = checkBox => {
        return this.getSelectedIds().includes(checkBox);
    };
    handleChange = item => {
        const oldSelectedIds = this.getSelectedIds();

        if (oldSelectedIds.indexOf(item.id) === -1) {
            // Item is not checked
            const selectedIds = [...oldSelectedIds, item.id];
            this.props.input.onBlur(selectedIds);
        } else {
            // Item is checked
            const selectedIds = [...oldSelectedIds];
            selectedIds.splice(oldSelectedIds.indexOf(item.id), 1);
            this.props.input.onBlur(selectedIds);
        }
    };
    getFlexDirection = (): 'row' | 'column' => {
        const { direction = 'VERTICAL' } = this.props;
        return direction === 'HORIZONTAL' ? 'row' : 'column';
    };
    makeCheckBoxes = dataTableByDisplay => {
        // Make the checkboxes from the datatable
        return Object.keys(dataTableByDisplay)
            .filter(
                key =>
                    dataTableByDisplay[key].active ||
                    this.valuesetKeeperArounder.encountered(dataTableByDisplay[key].id),
            )
            .map(key => {
                const label = this.props.dataTableByDisplay[key].display;
                return (
                    <div key={key}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    inputProps={{
                                        'aria-label': label,
                                    }}
                                    checked={this.isChecked(this.props.dataTableByDisplay[key].id)}
                                    onChange={() => this.handleChange(this.props.dataTableByDisplay[key])}
                                    disabled={this.props.disabled}
                                    className={this.props.classes.checkBox}
                                    style={{ height: 15 }}
                                />
                            }
                            label={label}
                            key={key}
                        />
                    </div>
                );
            });
    };
    render() {
        const { meta: { touched, error } = { touched: false, error: '' } } = this.props;
        this.getSelectedIds();
        return (
            <React.Fragment>
                <FormControl margin="none" fullWidth={true} error={touched && error}>
                    {this.props.renderLabel && (
                        <InputLabel style={{ fontSize: '1.2em', fontWeight: 'bold' }} shrink={true}>
                            {this.props.label}
                        </InputLabel>
                    )}
                    <FormGroup
                        {...this.props.ariaInputProps}
                        onBlur={() => this.props.input.onBlur(undefined)}
                        aria-invalid={touched && error}
                        aria-describedby={touched && error ? this.helperTextId : undefined}
                        style={{
                            display: 'flex',
                            flexDirection: this.getFlexDirection(),
                            justifyContent: this.getFlexDirection() === 'row' ? 'flex-start' : undefined,
                            flexWrap: 'wrap',
                            marginLeft: 0,
                            marginTop: '16px',
                        }}
                    >
                        {this.makeCheckBoxes(this.props.dataTableByDisplay)}
                    </FormGroup>
                    {touched && error && <FormHelperText id={this.helperTextId}>Error: {error}</FormHelperText>}
                </FormControl>
            </React.Fragment>
        );
    }
}

const Multicheckbox = compose(
    valueSetsManyHoc,
    withStyles(styles),
)(MultiCheckbox);

export default Multicheckbox;
