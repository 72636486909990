import { merge, keyBy } from 'lodash';

export interface LayoutStateItem {
    x: number;
    y: number;
    h: number;
    w: number;
    content: React.ReactNode;
    temp?: boolean;
    minW?: number;
    maxW?: number;
    static?: boolean;
    mouseEvent?: {
        clientX: number;
        clientY: number;
    };
    i: string;
    originalDefinition: string;
}
export type LayoutState = LayoutStateItem[];
export type LayoutAction =
    | {
          type: 'addTemp';
          mouseEvent: {
              clientX: number;
              clientY: number;
          };
          content: React.ReactNode;
          h?: number;
          w?: number;
      }
    | {
          type: 'clearTemp';
      }
    | {
          type: 'finaliseTemporaryItem';
      }
    | {
          type: 'newLayout';
          layout: LayoutState;
      }
    | {
          type: 'transformLayout';
          transform: (layout: LayoutState) => LayoutState;
      };
const layoutReducer = (state: LayoutState, action: LayoutAction): LayoutState => {
    switch (action.type) {
        //add a temporary item (if none already exists)
        //this is used when hovering with a DraggableSource over the grid
        //the action is expected to have an mouseEvent attribute with clientX and clientY attributes
        //this is used to tell react-grid-layout where the newly created item in the grid should be moved
        //after the mocked mousedown event.
        case 'addTemp':
            if (state.findIndex(item => item.temp) !== -1) {
                return state;
            }
            const maxX = state.reduce((val, item) => (item.x + item.h > val ? item.x + item.h : val), 0);
            const maxY = state.reduce((val, item) => (item.y + item.w > val ? item.y + item.w : val), 0);
            return [
                ...state,
                {
                    x: maxX + 1,
                    y: maxY + 1,
                    h: action.h ? action.h : 1,
                    w: action.w ? action.w : 1,
                    content: action.content,
                    originalDefinition: (action.content as React.ReactElement<{ originalDefinition: string }>).props
                        .originalDefinition,
                    temp: true,
                    mouseEvent: action.mouseEvent,
                    i: '' + state.length,
                },
            ];
        //removes temporary elements
        //this is used when dragging a DraggableSource outside the grid
        case 'clearTemp':
            return state.filter(item => !item.temp);
        //finalise the temporary item
        //this is used when dragging a draggablesource over the grid and letting go (mouseup)
        case 'finaliseTemporaryItem':
            return state.map(item => ({ ...item, temp: false }));
        //when the whole layout shall be replaced
        //used on onLayoutChange from grid layou
        case 'newLayout': {
            if (state.findIndex(item => item.temp) !== -1) {
                return state;
            }
            //react-grid-layout only returns the required keys for layouting
            //we have to merge the previous state, if we want to save other data in the array
            var merged = merge({}, keyBy(state, 'i'), keyBy(action.layout, 'i'));
            return Object.values(merged);
        }
        case 'transformLayout': {
            const newLayout = action.transform(state);
            return newLayout;
        }
        default:
            return state;
    }
};

export default layoutReducer;
