import { mapToAppType } from 'bpm/dataAdapters/network/rest/processInstances/entities/processInstance';
import * as processInstance from './actions';
import { isActionOf } from 'typesafe-actions';
import { RootState } from 'reducers/rootReducer';
import { Epic } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { Services } from 'sideEffect/services';
import { filter, map, catchError, flatMap } from 'rxjs/operators';
import { of } from 'rxjs';

const fetchPotentialUsersFlow: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) =>
    action$.pipe(
        filter(isActionOf(processInstance.getProcessInstance)),
        flatMap(action =>
            services.getProcessInstance(action.payload).pipe(
                map(response => {
                    const processInstanceData = mapToAppType(response);
                    return processInstance.getProcessInstanceSuccess(action.payload, processInstanceData);
                }),
                catchError(err => of(processInstance.getProcessInstanceFailure(action.payload, err))),
            ),
        ),
    );
export default fetchPotentialUsersFlow;
