import React from 'react';
import {
    FormControlLabel,
    FormControl,
    InputLabel,
    RadioGroup,
    Radio,
    WithStyles,
    withStyles,
    Theme,
    createStyles,
    FormHelperText,
} from '@material-ui/core';
import compose from 'recompose/compose';
import { Concept, valuesetOneHoc } from '../ValueSelectDownshift';
import get from 'lodash/get';
import { ValuesetKeeperArounder } from '../ValuesetKeeperArounder';
import classnames from 'classnames';
import { fromNullable } from 'fp-ts/lib/Option';
import uniqueId from 'lodash/uniqueId';

type Input = any; // tslint:disable-line
type Meta = any; // tslint:disable-line

const styles = ({ palette, spacing }: Theme) =>
    createStyles({
        checkBox: {
            width: 24,
            height: 24,
            marginLeft: 0,
        },
        addPadding: {
            paddingTop: 0,
            paddingBottom: 0,
        },
        formControlLabelVertical: {
            // this prevents whitespace to right being clickable
            // when radio options are listed vertically
            alignSelf: 'flex-start',
            marginRight: 'auto',
        },
        error: {
            color: palette.error.main,
            fontSize: '0.75rem',
        },
    });

export interface ConnectedComponentProps {
    labelledBy?: string;
    id: string;
    direction?: 'HORIZONTAL' | 'VERTICAL';
    valueSet?: string;
    resource: string;
    source: string;
    shouldFetchValueset?: boolean;
    disabled?: boolean;
    record?: {};
    label: string | null;
    input: Input;
    meta: Meta;
    ariaInputProps?: {};
    renderLabel?: boolean;
}

interface ValuesetCheckboxProps extends ConnectedComponentProps, WithStyles<typeof styles> {
    dataTableByDisplay: {
        [display: string]: Concept;
    };
}
const removeId = (name: string) => (name && name.endsWith('Id') ? name.slice(0, -2) : name);

class ValuesetCheckbox extends React.Component<ValuesetCheckboxProps> {
    private helperTextId = uniqueId('valueset-checkbox-errorid');
    static defaultProps = {
        ariaInputProps: {},
        renderLabel: true,
    };
    public valuesetKeeperArounder: ValuesetKeeperArounder = new ValuesetKeeperArounder();

    getValue = () => {
        const conceptIds = fromNullable(this.props.input && this.props.input.value).getOrElse(
            get(this.props.record, this.props.source),
        );
        if (conceptIds !== undefined) {
            if (conceptIds instanceof Array) {
                conceptIds.map(conceptId => this.valuesetKeeperArounder.add(conceptId));
            } else {
                this.valuesetKeeperArounder.add(conceptIds);
            }
        }
        return conceptIds;
    };
    isChecked = id => {
        return this.getValue() && `${this.getValue()}` === `${id}`;
    };
    handleChange = event => {
        const id: string | number | null | undefined = event.target.value;
        if (!this.props.input) {
            return;
        }
        if (id === this.getValue()) {
            this.props.input.onBlur(null);
        } else {
            this.props.input.onBlur(id);
        }
    };
    getFlexDirection = (): 'row' | 'column' => {
        const { direction = 'VERTICAL' } = this.props;
        return direction === 'HORIZONTAL' ? 'row' : 'column';
    };
    makeCheckBoxes = dataTableByDisplay => {
        // Make the checkboxes from the datatable
        const { classes, meta: { touched, error } = { touched: false, error: '' } } = this.props;
        const options = Object.keys(dataTableByDisplay).filter(
            key =>
                this.props.dataTableByDisplay[key].active ||
                this.valuesetKeeperArounder.encountered(this.props.dataTableByDisplay[key].id),
        );
        return options.length === 0 ? null : (
            <RadioGroup
                {...this.props.ariaInputProps}
                aria-invalid={!!(touched && error)}
                aria-describedby={touched && error ? this.helperTextId : undefined}
                /*
                REMOVED_HTML_ID
                id={this.props.id || removeId(this.props.source)}
                */
                tabIndex={0}
                name={removeId(this.props.source)}
                value={this.getValue() ? `${this.getValue()}` : ''}
                onChange={this.handleChange}
                onBlur={() => this.props.input && this.props.input.onBlur(undefined)}
                style={{
                    display: 'flex',
                    flexDirection: this.getFlexDirection(),
                    justifyContent: this.getFlexDirection() === 'row' ? 'flex-start' : undefined,
                    flexWrap: 'wrap',
                }}
            >
                {options.map(key => (
                    <FormControlLabel
                        control={
                            <Radio
                                className={classnames(classes.checkBox, {
                                    [classes.addPadding]: this.getFlexDirection() === 'row' ? true : false,
                                })}
                            />
                        }
                        disabled={this.props.disabled}
                        label={this.props.dataTableByDisplay[key].display}
                        value={`${this.props.dataTableByDisplay[key].id}`}
                        key={key}
                        className={
                            this.getFlexDirection() === 'column'
                                ? classnames(classes.formControlLabelVertical)
                                : undefined
                        }
                    />
                ))}
            </RadioGroup>
        );
    };
    render() {
        const {
            label,
            dataTableByDisplay,
            renderLabel,
            meta: { touched, error } = { touched: false, error: '' },
            classes,
        } = this.props;
        return (
            <React.Fragment>
                <FormControl margin="none" style={{ width: '100%' }} error={touched && error}>
                    {label && renderLabel && (
                        <InputLabel style={{ position: 'relative' }} shrink={true}>
                            {label}
                        </InputLabel>
                    )}
                    {this.makeCheckBoxes(dataTableByDisplay)}
                </FormControl>
                {touched && error && (
                    <FormHelperText id={this.helperTextId} className={classes.error}>
                        Error: {error}
                    </FormHelperText>
                )}
            </React.Fragment>
        );
    }
}

const Multicheckbox = compose(
    valuesetOneHoc,
    withStyles(styles),
)(ValuesetCheckbox);

export default Multicheckbox;
