import { put, takeEvery, call } from 'redux-saga/effects';
import { enqueueSnackbar as enqueueSnackbarAction } from 'notistack/actions';
import { saveAs } from 'file-saver';
import { API_URL } from '../config';
import { EXPORT_VIEW_CONFIG, VIEW_CONFIG_NOT_EXPORTED, PROCESSES_NOT_EXPORTED } from '../actions/constants';
import { ExportViewConfigEventType } from '../actions/exportViewConfig';
import { storageController } from 'storage';

const getUrl = eventType => {
    const exportUrl = `${API_URL}view-config/export`;
    if (eventType === ExportViewConfigEventType.ExportConfigFull) {
        return `${exportUrl}`;
    }
    if (eventType === ExportViewConfigEventType.ExportConfigCore) {
        return `${exportUrl}?coreOnly=true`;
    }
    if (eventType === ExportViewConfigEventType.ExportConfigUserExcluded) {
        return `${exportUrl}?userExcluded=true`;
    }
    if (eventType === ExportViewConfigEventType.ExportConfigCoreUserExcluded) {
        return `${exportUrl}?coreOnly=true&userExcluded=true`;
    }
};

export function* fetchDataFull(action) {
    try {
        const request = new Request(getUrl(action), {
            method: 'GET',
            credentials: 'same-origin',
            headers: new Headers({
                Authorization: `Bearer ${storageController.getToken()}`,
                Cookie: `${window.document.cookie}`,
            }),
        });
        let fileName;
        fetch(request)
            .then(response => {
                const disposition = response.headers.get('content-disposition');
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    const matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) {
                        fileName = matches[1].replace(/['"]/g, '');
                    }
                }
                return response.blob();
            })
            .then(blob => {
                saveAs(blob, fileName);
            });
    } catch (error) {
        yield put({ type: VIEW_CONFIG_NOT_EXPORTED, error });
    }
}

export function* fetchDataCore(action) {
    try {
        const request = new Request(`${API_URL}view-config/export-core-entity-config`, {
            method: 'GET',
            credentials: 'same-origin',
            headers: new Headers({
                Authorization: `Bearer ${storageController.getToken()}`,
                Cookie: `${window.document.cookie}`,
            }),
        });
        let fileName;
        fetch(request)
            .then(response => {
                const disposition = response.headers.get('content-disposition');
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    const matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) {
                        fileName = matches[1].replace(/['"]/g, '');
                    }
                }
                return response.blob();
            })
            .then(blob => {
                saveAs(blob, fileName);
            });
    } catch (error) {
        yield put({ type: VIEW_CONFIG_NOT_EXPORTED, error });
    }
}

export function* fetchDataProcesses(action) {
    try {
        const request = new Request(`${API_URL}bpm/deployment`, {
            method: 'GET',
            credentials: 'same-origin',
            headers: new Headers({
                Authorization: `Bearer ${storageController.getToken()}`,
                Cookie: `${window.document.cookie}`,
            }),
        });
        let fileName;
        fetch(request)
            .then(response => {
                const disposition = response.headers.get('content-disposition');
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    const matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) {
                        fileName = matches[1].replace(/['"]/g, '');
                    }
                }
                return response.blob();
            })
            .then(blob => {
                saveAs(blob, fileName);
            });
    } catch (error) {
        yield put({ type: PROCESSES_NOT_EXPORTED, error });
    }
}

export default function* exportViewConfig() {
    yield takeEvery(EXPORT_VIEW_CONFIG, function*(authPayload) {
        yield put(enqueueSnackbarAction({ message: 'Exporting view configuration' }));
        yield call(fetchDataFull, ExportViewConfigEventType.ExportConfigFull);
    });
    yield takeEvery(ExportViewConfigEventType.ExportConfigCore, function*(authPayload) {
        yield put(enqueueSnackbarAction({ message: 'Exporting view configuration' }));
        yield call(fetchDataCore, 'fetchData called');
    });
    yield takeEvery(ExportViewConfigEventType.ExportConfigUserExcluded, function*(authPayload) {
        yield put(enqueueSnackbarAction({ message: 'Exporting view configuration' }));
        yield call(fetchDataFull, ExportViewConfigEventType.ExportConfigUserExcluded);
    });
    yield takeEvery(ExportViewConfigEventType.ExportConfigCoreUserExcluded, function*(authPayload) {
        yield put(enqueueSnackbarAction({ message: 'Exporting view configuration' }));
        yield call(fetchDataFull, ExportViewConfigEventType.ExportConfigCoreUserExcluded);
    });
    yield takeEvery(ExportViewConfigEventType.ExportProcesses, function*(authPayload) {
        yield put(enqueueSnackbarAction({ message: 'Exporting processes' }));
        yield call(fetchDataProcesses, 'fetchData called');
    });
}
