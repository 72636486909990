import * as get from './actions';
import ViewConfig from 'reducers/ViewConfigType';
import { expansionQuery } from '../util/queryBuilderUtils';
import createExpansion from 'clients/utils/createExpansionQuery/buildCommaSeperatedExpansions';
import { EntityValidations } from 'reducers/entityValidationsReducer';
import { ActionButtonExps } from 'viewConfigCalculations/actionButtonDisplayExpressions/reducer';

const buildUrl = (
    params: get.GetOneParams,
    viewConfig: ViewConfig,
    entityValidations: EntityValidations,
    actionButtonExps: ActionButtonExps,
) => {
    const url =
        (params.restUrl || viewConfig.entities[params.resource].restUrl) +
        '/' +
        params.id +
        (() => {
            if (params.view && typeof params.view === 'string') {
                return `?expand=${createExpansion(params.view, viewConfig, entityValidations, actionButtonExps)}`;
            } else if (params.view !== -1) {
                return expansionQuery('?', params.resource, viewConfig, params.appendExpansions);
            } else if (params.appendExpansions && params.appendExpansions.length > 0) {
                return `?expand=${Array.prototype.join.call(params.appendExpansions, ',')}`;
            }
            return '';
        })();
    return url;
};
export default buildUrl;
