import * as t from 'io-ts';
import { Either, either } from 'fp-ts/lib/Either';
import { fromEither } from 'fp-ts/lib/EitherT';
import { array } from 'fp-ts/lib/Array';
import runtimeValidatedJSONParse from '../../util/runtimeValidatedJSONParse';
import { EvaluationOptions } from '../formValidation/definitions.d';
import { curriedEvalExpression, processExpr } from '../evaluate';
import { traverse } from 'fp-ts/lib/Array';
import memoize from 'lodash/memoize';
import {
    getContainsCodes,
    getLookupEntityField,
    getLookupConceptIdsFromValuesetGroup,
    getGetConceptIdsFromCodes,
    getValuesetReverseLookupUtilities,
} from '../contextUtils';
import ViewConfig from '../../reducers/ViewConfigType';
import { RootState } from '../../reducers/rootReducer';
import { fromNullable } from 'fp-ts/lib/Option';
import { currentUserProperties } from '../contextUtils/currentUser';

export type parsingErrors =
    | { tag: 'ValidationErrors'; validationErrors: t.ValidationError[] }
    | { tag: 'ParsingError'; input: string; errorMessage: string };
export type ErrorUnion = { message: string; name: string } | parsingErrors;

const expressionArrayConfig = t.array(t.string);
type configType = t.TypeOf<typeof expressionArrayConfig>;

export const evaluateExpressionArray = (expressions: configType) => (
    context: {},
    localVariablesAndMethods: {},
): Either<{ message: string; name: string }, boolean>[] =>
    expressions.flatMap(exp =>
        fromEither(array)(
            curriedEvalExpression(exp)
                .chain(f => f(context, localVariablesAndMethods))
                .map(res => !!res),
        ),
    );

export const traverseEvalExpressionArray = (expressions: configType) => (
    context: {},
    localVariablesAndMethods: {},
): Either<{ message: string; name: string }, boolean[]> =>
    traverse(either)(expressions, exp =>
        curriedEvalExpression(exp)
            .chain(f => f(context, localVariablesAndMethods))
            .map(res => !!res),
    );

export const entityAndConceptLookupUtils = (
    entities: { Concept?: {} },
    viewConfig: ViewConfig,
    valueSets: RootState['valueSets'],
) => {
    const lookupEntityData = getLookupEntityField(viewConfig, entities);
    return {
        containsCodes: getContainsCodes(entities.Concept || {}),
        lookupEntityData,
        mapToEntityData: (entityType: string, ids: null | string[], path: string) => {
            if (!ids) {
                return [];
            }
            return ids.map(id => lookupEntityData(entityType, id, path));
        },
        lookupConceptIdsFromValuesetGroup: getLookupConceptIdsFromValuesetGroup(valueSets, entities),
        ...getValuesetReverseLookupUtilities(valueSets, fromNullable(entities.Concept).getOrElse({})),
        ...currentUserProperties(viewConfig),
        getConceptIdsFromCodes: getGetConceptIdsFromCodes(entities.Concept || {}),
    };
};
export const traverseEvalExpressionArrayWithEntityAndConceptLookups = (
    entities: { Concept?: {} },
    viewConfig: ViewConfig,
    valueSets: RootState['valueSets'],
) => (expressions: configType) => (
    context: {},
    localVariablesAndMethods: {},
): Either<{ message: string; name: string }, boolean[]> =>
    traverse(either)(expressions, exp =>
        curriedEvalExpression(exp)
            .chain(f =>
                f(
                    {
                        ...context,
                        ...entityAndConceptLookupUtils(entities, viewConfig, valueSets),
                    },
                    localVariablesAndMethods,
                ),
            )
            .map(res => !!res),
    );

export const getExpressionsFromConfig = (json: string) =>
    runtimeValidatedJSONParse<configType>(expressionArrayConfig)(json);

const memoizedGetExpressions = memoize(getExpressionsFromConfig);

export function memoizedTraverseEval(
    expression: string,
    options: EvaluationOptions,
): (values: {}, localVarsAndMethods?: {}) => Either<ErrorUnion, boolean[]>;
export function memoizedTraverseEval(
    expression: string,
    options: EvaluationOptions,
    entities: {},
    viewConfig: ViewConfig,
    valueSets: RootState['valueSets'],
): (values: {}, localVarsAndMethods?: {}) => Either<ErrorUnion, boolean[]>;

export function memoizedTraverseEval(
    expression: string,
    options: EvaluationOptions = {},
    entities?: {},
    viewConfig?: ViewConfig,
    valueSets?: RootState['valueSets'],
) {
    const ready = (memoizedGetExpressions(processExpr(options)(expression)) as Either<ErrorUnion, string[]>).map(
        entities && viewConfig && valueSets
            ? traverseEvalExpressionArrayWithEntityAndConceptLookups(entities, viewConfig, valueSets)
            : traverseEvalExpressionArray,
    );
    return (values: {}, localVarsAndMethods = {}) => {
        return ready.chain(fn => fn(values, localVarsAndMethods));
    };
}
