import * as create from './actions';
import { isActionOf } from 'typesafe-actions';
import { Services } from 'sideEffect/services';
import { Epic } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { RootState } from 'reducers/rootReducer';
import { filter, withLatestFrom, map, flatMap } from 'rxjs/operators';
import crudCreateUpdateGetFlow from 'sideEffect/crud/util/epics/CoreCrud/createUpdate';

const cleanData = (data: {}) => {
    const cleanedData = Object.assign({}, data); // set up new object to delete object references from safely
    Object.keys(cleanedData).forEach(
        key =>
            typeof cleanedData[key] === 'object' &&
            cleanedData[key] &&
            Object.prototype.hasOwnProperty.call(cleanedData[key], 'id') && // if it's an object like { id: x }
            // delete the object and set the {objectName}Id field with the id from above
            Object.assign(cleanedData, { [`${key}Id`]: cleanedData[key].id }) &&
            delete cleanedData[key],
    );
    return cleanedData;
};

const crudCreateFlow: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) =>
    action$.pipe(
        filter(isActionOf(create.crudCreate)),
        withLatestFrom(state$.pipe(map(state => state.viewConfig))),
        flatMap(([action, viewConfig]) => {
            const { resource, data, cb, restUrl: _restUrl, errorsCbs } = action.payload;
            const restUrl = _restUrl || viewConfig.entities[resource].restUrl;
            return crudCreateUpdateGetFlow(
                {
                    data: cleanData(data),
                    restUrl,
                },
                {
                    service: services.crudCreate,
                    failureAction: create.crudCreateFailure,
                    successAction: create.crudCreateSuccess,
                    successCb: cb,
                    errorsCbs,
                },
                {
                    resource,
                    viewConfig,
                    initialRequestPayload: action.payload,
                    restUrl,
                },
            );
        }),
    );
export default crudCreateFlow;
