import React, { useContext, useMemo, useCallback } from 'react';
import { TaskForm } from 'reducers/rootReducer';
import { FieldFactoryContext } from 'fieldFactory/Broadcasts';
import { getFields } from 'bpm/start-form/components/StartForm';
import { Card } from '@material-ui/core';
import produce from 'immer';
import set from 'lodash/set';
import MovableGrid, { Layout } from './MovableGrid';

interface EditableTaskFormLayoutProps {
    formDefinition: TaskForm;
    onFormDefinitionChange: (args: { formDefinition: TaskForm }) => void;
}
const EditableTaskFormLayout: React.SFC<EditableTaskFormLayoutProps> = ({ formDefinition, onFormDefinitionChange }) => {
    const fieldFactory = useContext(FieldFactoryContext);
    const fields = useMemo(() => {
        return getFields(fieldFactory, formDefinition);
    }, [fieldFactory, formDefinition]);

    const handleLayoutChange = useCallback(
        (args: { layout: Layout }) => {
            const newFormDefinition = produce(formDefinition, draftState => {
                args.layout.forEach(({ i, x, y, w }) => {
                    set(draftState.fields[parseInt(i, 10)], 'params.row', y + 1);
                    set(draftState.fields[parseInt(i, 10)], 'params.column', x);
                    set(draftState.fields[parseInt(i, 10)], 'params.span', w);
                });
                return draftState;
            });
            onFormDefinitionChange({ formDefinition: newFormDefinition });
        },
        [formDefinition, onFormDefinitionChange],
    );
    return (
        <Card style={{ paddingTop: 50 }}>
            {fields && <MovableGrid fields={fields} onLayoutChange={handleLayoutChange} columnStartsAt={0} />}
        </Card>
    );
};
export default EditableTaskFormLayout;
