import { combineReducers } from 'redux';
import { processInstanceEventType as process } from '../../actions/processInstanceEvent';

const ids = (state: string[] = [], action): string[] => {
    switch (action.type) {
        case process.getProcessInstancesSuccess: {
            return action.payload.data.result.map(p => p.id);
        }
        default:
            return state;
    }
};

const isLoading = (state: boolean = false, action): boolean => {
    switch (action.type) {
        case process.getProcessInstances: {
            return true;
        }
        case process.getProcessInstancesFailure:
        case process.getProcessInstancesSuccess: {
            return false;
        }
        default:
            return state;
    }
};

export interface List {
    ids: string[];
    isLoading: boolean;
}
const list = combineReducers<List>({
    ids,
    isLoading,
});

export default list;
