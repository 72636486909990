import React from 'react';
import { tryCatch } from 'fp-ts/lib/Option';
import { useGetProcessConf } from './useGetProcessConfItem';
import useViewConfig from 'util/hooks/useViewConfig';
import { evaluateExpression } from 'casetivity-shared-js/lib/spel/evaluate';
import { currentUserProperties } from 'expressions/contextUtils/currentUser';

const useHasHideProcessHeader = () => {
    const conf = useGetProcessConf();
    const viewConfig = useViewConfig();
    const res = React.useMemo(() => {
        return conf
            .mapNullable(c => c.hideProcessHeader)
            .chain(mth =>
                tryCatch(() => {
                    if (typeof mth === 'boolean') {
                        return mth;
                    }
                    return evaluateExpression(mth, {
                        ...currentUserProperties(viewConfig),
                    });
                }),
            )
            .getOrElse(false);
    }, [conf, viewConfig]);
    return res;
};
export default useHasHideProcessHeader;
