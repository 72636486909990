import storage from 'local-storage-fallback';

const TOKEN_KEY = 'jhi-authenticationtoken';
const IMPERSONATION_KEY = 'jhi-authenticationtoken-impersonate';

class StorageController {
    clear() {
        storage.clear();
    }
    setToken(id_token: string) {
        if (storage.getItem(IMPERSONATION_KEY)) {
            storage.setItem(IMPERSONATION_KEY, id_token);
        } else {
            storage.setItem(TOKEN_KEY, id_token);
        }
    }
    getToken() {
        return storage.getItem(IMPERSONATION_KEY) || storage.getItem(TOKEN_KEY);
    }
    startImpersonating(new_token: string) {
        storage.setItem(IMPERSONATION_KEY, new_token);
    }
    stopImpersonating() {
        storage.removeItem(IMPERSONATION_KEY);
    }
}

export const storageController = new StorageController();
