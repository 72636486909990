import { parse, stringify } from 'query-string';

const keepOnlyKeys = (filter: {}, keepOnly: string[]) => {
    return Object.assign({}, ...Object.entries(filter).map(([k, v]) => (keepOnly.indexOf(k) !== -1 ? { [k]: v } : {})));
};

export const merge = (
    locationSearch: string,
    filter: {},
    keepOnly?: string[],
    stripAllNonFilterParams?: boolean | 'STRIP_PAGE',
) => {
    const current = locationSearch ? parse(locationSearch) : {};

    const currentFilter = current.filter ? JSON.parse(current.filter) : {};
    const filterToKeep = keepOnly ? keepOnlyKeys(currentFilter, keepOnly) : currentFilter;

    const newFilter = {
        ...filterToKeep,
        ...filter,
    };
    const result = {
        ...(stripAllNonFilterParams === 'STRIP_PAGE'
            ? (() => {
                  const { page, ...rest } = current;
                  return rest;
              })()
            : stripAllNonFilterParams
            ? {}
            : current),
        filter:
            Object.values(newFilter).filter(v => typeof v !== 'undefined').length > 0
                ? JSON.stringify(newFilter, Object.keys(newFilter).sort())
                : undefined,
    };
    return `?${stringify(result)}`;
};
