import React, { Component } from 'react';
import { reduxForm, InjectedFormProps } from 'redux-form'; // eslint-disable-line import/no-extraneous-dependencies
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import withPropsOnChange from 'recompose/withPropsOnChange';
import Toolbar from 'components/generics/form/Toolbar.aor';
import { parse } from 'query-string';
import withProps from 'recompose/withProps';
import { getFieldsFromView, isFieldViewField, getFieldInstanceEntriesFromView } from '../utils/viewConfigUtils';
import createGetDefaultValues from '../utils/getDefaultValues';
import { FieldFactorySubscriber } from '../../../fieldFactory/Broadcasts';
import { DataSource, Mode } from '../../../fieldFactory/FieldFactoryProvider';
import validate from '../form/validate';
import getBooleanFieldsPreloadedFalse from '../utils/getBooleanFieldsPreloadedFalse';
import EnhancedRGridWithVis from '../form/EnhancedRGridTabbable';
import { EntityFormContextProvider, formContext } from '../form/EntityFormContext';
import { createIsPopoverSelector } from 'components/generics/hoc/popoverLock';
import { Location } from 'history';
import { RootState } from 'reducers/rootReducer';
import { View } from 'reducers/ViewConfigType';
import { SpelOptions } from 'expressions/evaluate';
import { AjaxError } from 'rxjs/ajax';
import ErrorDialog from '../form/SubmissionFailureDialog';

export interface SimpleFormProps {
    location: Location;
    view: View;
    resource: string;
    basePath: string;
    match: {};
    formId?: string;
    options: SpelOptions;
    injectValues?: {};
    viewName: string;
    save: (record: {}, redirect: string | false | undefined, alwaysCb: (err?: AjaxError) => void) => void;
    redirect?: string | false;
    submitOnEnter?: boolean;
    toolbar?: JSX.Element;
}

interface SimpleFormProps1 extends SimpleFormProps {
    fieldFactory: any;
}

const makeGetPopoverProps = () => {
    const isPopoverSelector = createIsPopoverSelector();
    const mapStateToProps = (state: RootState, props: SimpleFormProps1) => {
        const isPopover = isPopoverSelector(state, props);
        return {
            viewConfig: state.viewConfig,
            isPopover,
        };
    };
    return mapStateToProps;
};

interface SimpleFormProps2 extends SimpleFormProps1, ReturnType<ReturnType<typeof makeGetPopoverProps>> {}

const withParentProps = ({ location }: SimpleFormProps2) => {
    const query = parse(location.search);
    return {
        parentEntityIdValue: query.parentId as string | undefined,
        parentEntityName: query.parentEntity as string | undefined,
        parentField: query.parentField as string | undefined,
    };
};

interface SimpleFormProps3 extends SimpleFormProps2, ReturnType<typeof withParentProps> {}

const getFields = ({
    view,
    fieldFactory,
    parentEntityIdValue,
    parentEntityName,
    parentField,
    resource,
    basePath,
    match,
    isPopover,
    viewConfig,
    viewName,
}: SimpleFormProps3) => {
    const record = parentEntityIdValue && parentEntityName && parentField ? { [parentField]: parentEntityIdValue } : {};
    const config = {
        dataSource: DataSource.ENTITY,
        mode: Mode.INPUT,
        validate: true,
        connected: true,
        options: {
            parentField,
            parentEntityIdValue,
            parentEntityName,
        },
    };
    return {
        fields: fieldFactory(config)({
            record,
            resource,
            basePath,
            match,
            referenceFieldsShouldFetchInitialData: false,
            shouldFetchValueset: false,
            isPopover,
            overrideFieldValueIfDisabled: true,
            isForCreate: true,
        })(getFieldInstanceEntriesFromView(viewConfig, viewName)),
        record,
    };
};

interface SimpleFormProps4 extends SimpleFormProps3, ReturnType<typeof getFields> {}

const makeMapStateToProps = () => {
    const getDefaultValues = createGetDefaultValues();
    const mapStateToProps = (state: RootState, props: SimpleFormProps4) => ({
        entityConfigValidations: state.entityValidations,
        initialValues: getDefaultValues(state, props) as {},
        form: props.formId || 'record-form',
        viewConfig: state.viewConfig,
        activeField: state.form[props.formId || 'record-form'] ? state.form[props.formId || 'record-form'].active : '',
        concepts: state.admin.entities.Concept,
        valueSets: state.valueSets,
        entities: state.admin.entities,
    });
    return mapStateToProps;
};
interface SimpleFormProps5 extends SimpleFormProps4, ReturnType<ReturnType<typeof makeMapStateToProps>> {}

interface SimpleFormProps6 extends SimpleFormProps5 {
    adjustedFormValues: {};
    fieldValues: {};
}

const handlers = {
    save: (props: SimpleFormProps6 & InjectedFormProps) => (
        unadjustedFormValues: {},
        redirect: string | false | undefined,
        cb: (err?: AjaxError) => void,
    ): void => {
        const values = props.adjustedFormValues || unadjustedFormValues;
        const { parentEntityIdValue, parentEntityName, view, injectValues, viewConfig, viewName } = props;
        let submissionValues = {
            ...getBooleanFieldsPreloadedFalse(viewConfig, viewName),
            ...values,
        };
        if (injectValues) {
            submissionValues = {
                ...submissionValues,
                ...injectValues,
            };
        }
        if (
            getFieldsFromView(view)
                .filter(isFieldViewField)
                .find(({ field }) => field === 'linkedEntity')
        ) {
            submissionValues = {
                ...submissionValues,
                linkedEntityId: parentEntityIdValue,
                linkedEntityType: parentEntityName,
            };
        }
        props.save(submissionValues, redirect, cb);
    },
};

type Handlers = typeof handlers;
type AppliedHandlers = {
    [func in keyof Handlers]: ReturnType<Handlers[func]>;
};

interface SimpleFormComponentProps extends SimpleFormProps6, InjectedFormProps<{}>, AppliedHandlers {}

interface SimpleFormComponentState {
    isSaving: boolean;
    alertError: AjaxError | null;
}
export class SimpleFormComponent extends Component<SimpleFormComponentProps, SimpleFormComponentState> {
    static defaultProps = {
        fields: [],
        submitOnEnter: true,
        toolbar: <Toolbar />,
    };

    _isMounted = false; // eslint-disable-line
    state = {
        isSaving: false,
        alertError: null,
    };
    getSetSaving = (isSaving, cb?: () => void) => () => {
        if (this._isMounted) {
            this.setState({ isSaving }, cb);
        }
    };
    handleSubmitWithRedirect = (redirect = this.props.redirect) => {
        return this.props.handleSubmit(values => {
            this.getSetSaving(true, () =>
                this.props.save(values, redirect, err => {
                    this.getSetSaving(false, () => {
                        this.setState({ alertError: err });
                    })();
                }),
            )();
        });
    };
    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    clearAlert = () => {
        this.setState({ alertError: null });
    };
    render() {
        const { invalid, submitOnEnter, toolbar } = this.props;
        return (
            <React.Fragment>
                <ErrorDialog alertError={this.state.alertError} clearAlert={this.clearAlert} />
                <form autoComplete="off" className="simple-form">
                    <div>
                        <EnhancedRGridWithVis fields={this.props.fields} lastRowDropdownsFlipUp />
                    </div>
                    <div style={{ height: '1em' }} />
                    {toolbar &&
                        React.cloneElement(toolbar, {
                            handleSubmitWithRedirect: this.handleSubmitWithRedirect,
                            invalid,
                            submitOnEnter,
                            saving: this.state.isSaving,
                        })}
                </form>
            </React.Fragment>
        );
    }
}

const enhance = compose(
    BaseComponent => props => (
        <FieldFactorySubscriber>
            {fieldFactory => <BaseComponent {...props} fieldFactory={fieldFactory} />}
        </FieldFactorySubscriber>
    ),
    withProps(withParentProps),
    connect(makeGetPopoverProps),
    withPropsOnChange(
        ['view', 'fieldFactory', 'parentEntityIdValue', 'parentEntityName', 'parentField', 'isPopover'],
        getFields,
    ),
    connect(makeMapStateToProps),
    BaseComponent => (props: SimpleFormProps5) => (
        <EntityFormContextProvider record={undefined} viewName={props.view.name} formId={props.form || 'record-form'}>
            <BaseComponent {...props} />
        </EntityFormContextProvider>
    ),
    BaseComponent => props => (
        <formContext.Consumer>
            {fc => (
                <BaseComponent
                    {...props}
                    adjustedFormValues={fc.registeredValues} // for submission
                    fieldValues={fc.fieldValues} // for validation
                />
            )}
        </formContext.Consumer>
    ),
    reduxForm({
        enableReinitialize: true,
        shouldError: ({ values, props, nextProps, initialRender }: any) => {
            return initialRender || !props.anyTouched || nextProps.fieldValues !== props.fieldValues;
        },
        validate: (values: {}, props: SimpleFormProps6) => validate(props.fieldValues, props),
    }),
    withHandlers(handlers),
);

const SimpleForm: React.SFC<SimpleFormProps> = enhance(SimpleFormComponent);
export default SimpleForm;
