import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { disableFormsaveNotifierDec, disableFormsaveNotifierInc } from 'formSaveNotifier/actions';

interface FormSaveNotifierProps {
    children: (args: {
        formSaveNotificationDisabled: boolean;
        disable: () => void;
        reenable: () => void;
    }) => JSX.Element | null;
}
const mapStateToProps = (state: RootState) => {
    return {
        formSaveNotificationDisabled: state.disableFormSaveNotifier > 0,
    };
};

const dispatches = {
    disable: disableFormsaveNotifierDec,
    reenable: disableFormsaveNotifierInc,
};

const FormSaveNotifierStatusComponent: React.SFC<
    FormSaveNotifierProps & ReturnType<typeof mapStateToProps> & typeof dispatches
> = props => {
    const { formSaveNotificationDisabled, disable, reenable } = props;
    return props.children({
        formSaveNotificationDisabled,
        disable,
        reenable,
    });
};
const FormSaveNotifierStatus: React.ComponentType<FormSaveNotifierProps> = connect(
    mapStateToProps,
    dispatches,
)(FormSaveNotifierStatusComponent);
export default FormSaveNotifierStatus;
