import React from 'react';
import { change } from 'redux-form';
import { compose, withPropsOnChange } from 'recompose';
import { connect } from 'react-redux';
import FilterForm from './FilterForm';
import {
    getSearchFieldsFromView,
    getRefEntityName,
    isRefOneField,
    getDataTypeForFieldExpr,
} from '../utils/viewConfigUtils';
import { DataSource, Mode } from '../../../fieldFactory/FieldFactoryProvider';
import { withFieldFactory } from '../../../fieldFactory/Broadcasts';
import filterFixesFieldValue from './filter/permanentFilterFixesValueOfField';
import ViewConfig from '../../../reducers/ViewConfigType';
import { Subtract } from 'utility-types';
import { RootState } from 'reducers/rootReducer';

export interface LocalFilterProps {
    resource: string;
    filterValues: {};
    setFilters: (filters: {}) => void;
    submitFilters: (state: { filters: {} }) => void;
    clearFilters: () => void;
    permanentFilter: {};
    referencedFromEntity?: boolean;
    viewConfig: ViewConfig;
    viewName: string;
    formId: string;
    showButtons: boolean;
}
export type LocalFilterComponentProps = Subtract<LocalFilterProps, Pick<LocalFilterProps, 'viewName'>> & {
    viewName?: string;
    fields: React.ReactElement<{
        source: string;
        replacePeriodsInFieldName?: boolean;
        searchType?: string; // e.g. CONTAINS, EQUAL etc.
        style?: {};
    }>[];
    filtersAttributes?: {
        source: string;
        searchType?: string;
    }[];
    clearValuesInForm: () => void;
};

const LocalFilterComponent: React.SFC<LocalFilterComponentProps> = ({
    fields,
    filtersAttributes,
    filterValues,
    ...props
}) => (
    <div className="filter-group">
        <FilterForm {...props} filters={fields} filtersAttributes={filtersAttributes} initialValues={filterValues} />
    </div>
);

LocalFilterComponent.defaultProps = {
    submitFilters: () => {
        throw Error('submitFilters function not provided as prop');
    },
    clearFilters: () => {
        throw Error('clearFilters function not provided as prop');
    },
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    clearValuesInForm: (fieldNames: string[]) => {
        /*
        const permanentFilterNames = Object.keys(ownProps.permanentFilter);
        const keysToClear = Object.keys(ownProps.filterValues)
            .filter(filterKey => permanentFilterNames.indexOf(filterKey) === -1);
        keysToClear.forEach((filterKey) => {
            dispatch(change(ownProps.formId || 'filterForm', filterKey, ''));
        });
        */
        fieldNames.forEach(field => {
            dispatch(change(ownProps.formId || 'filterForm', field, ''));
        });
    },
});

export const LocalFilterPreFields: React.ComponentType<LocalFilterComponentProps> = connect(
    null,
    mapDispatchToProps,
)(LocalFilterComponent);

const LocalFilter: React.SFC<LocalFilterProps> = compose(
    withFieldFactory,
    withPropsOnChange(
        ['viewName', 'fieldFactory', 'permanentFilter', 'referencedFromEntity'],
        ({ viewName, fieldFactory, permanentFilter, referencedFromEntity, viewConfig }) => {
            const view = viewConfig.views[viewName];
            const searchFields = getSearchFieldsFromView(view)
                .filter(f => (permanentFilter ? !filterFixesFieldValue(permanentFilter)(f.field) : true))
                .filter(
                    f =>
                        !referencedFromEntity ||
                        !isRefOneField(viewConfig, view.entity, f.field.split('.')[0]) ||
                        referencedFromEntity !== getRefEntityName(viewConfig, view.entity, f.field.split('.')[0]),
                );

            const nonNullableBooleanSearchFields = searchFields.flatMap(f => {
                const dataType = getDataTypeForFieldExpr(viewConfig, f.entity, f.field, 'POP_LAST');
                if (dataType === 'BOOLEAN' && f.widgetType !== 'NULLABLE_BOOLEAN') {
                    return [f.field];
                }
                return [];
            });
            const fields = fieldFactory({
                dataSource: DataSource.ENTITY,
                mode: Mode.INPUT,
                validate: false,
                connected: false,
                options: { fullWidth: true },
            })({
                replacePeriodsInFieldName: '_~_',
                alwaysOn: true,
                isForSearch: true,
                neverDisabled: true,
                neverShowRequiredAsterisk: true,
                shouldFetchValueset: false,
            })(searchFields);
            return {
                nonNullableBooleanSearchFields,
                fields,
                filtersAttributes: fields.map(f => ({
                    source: f.props.source,
                    searchType: f.props.searchType,
                })),
            };
        },
    ),
    withPropsOnChange(
        ['nonNullableBooleanSearchFields', 'filterValues'],
        ({ nonNullableBooleanSearchFields, filterValues }) => {
            const newFilterValues = Object.assign(
                {},
                ...nonNullableBooleanSearchFields.map(f => ({ [f]: false })),
                filterValues,
            );
            return {
                filterValues: newFilterValues,
            };
        },
    ),
    connect(
        ({ valueSets }: RootState) => ({ valueSets }),
        mapDispatchToProps,
    ),
)(LocalFilterComponent);

export default LocalFilter;
