import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import CasetivityIdleTimer from 'idle-timer/components/IdleTimer';

// 1 hour
const DEFAULT_TIME_TO_WARNING = 59 * 60 * 1000;
const DEFAULT_WARNING_TIME = 60 * 1000;

const ConnectedIdleTimer: React.SFC<{}> = props => {
    const isAnonymous = useSelector((state: RootState) => {
        return state.viewConfig && state.viewConfig.user && state.viewConfig.user.login === 'anonymousUser';
    });
    const _timeToWarning = useSelector((state: RootState) => {
        return (
            (state.viewConfig && state.viewConfig.application && parseInt(state.viewConfig.application.timeout, 10)) ||
            DEFAULT_TIME_TO_WARNING
        );
    });
    const warningTime = (window as any).CASETIVITY_OVERRIDE_WARNING_TIME || DEFAULT_WARNING_TIME;
    const timeToWarning = (window as any).CASETIVITY_OVERRIDE_TIME_TO_WARNING || _timeToWarning;
    return isAnonymous ? (
        <React.Fragment>{props.children}</React.Fragment>
    ) : (
        <CasetivityIdleTimer timeToWarning={timeToWarning} warningTime={warningTime} {...props} />
    );
};
export default ConnectedIdleTimer;
