import { combineReducers } from 'redux'; // eslint-disable-line import/no-extraneous-dependencies
import taskDrawer from './taskDrawer';
import tasks, * as fromTasks from './tasks';
import processInstances, * as fromProcessInstances from './processInstances';
import processDefinitions from 'bpm/processDefinitions/reducer';
import processActivities, { ProcessActivities } from './processActivities';
import currentProcessSearch, { CurrentProcessSearch } from './currentProcessSearch';
import currentProcessTaskSearch, { CurrentProcessTaskSearch } from './currentProcessTaskSearch';
import potentialUsers from 'bpm/potentialUsers/reducer';
import taskPermissions from 'bpm/taskPotentialUsers/reducer';
import startForms from 'bpm/start-form/get-start-form/reducer';

export interface BPMState {
    tasks: fromTasks.TasksState;
    processInstances: fromProcessInstances.ProcessInstances;
    processDefinitions: ReturnType<typeof processDefinitions>;
    taskDrawer: boolean;
    processActivities: ProcessActivities;
    currentProcessSearch: CurrentProcessSearch;
    currentProcessTaskSearch: CurrentProcessTaskSearch;
    potentialUsers: ReturnType<typeof potentialUsers>;
    taskPermissions: ReturnType<typeof taskPermissions>;
    startForms: ReturnType<typeof startForms>;
}

const bpm = combineReducers<BPMState>({
    taskPermissions,
    processDefinitions,
    processInstances,
    tasks,
    taskDrawer,
    processActivities,
    currentProcessSearch,
    currentProcessTaskSearch,
    potentialUsers,
    startForms,
});

export default bpm;

export const getAllProcessInstances = state => fromProcessInstances.getAllProcessInstances(state.processInstances);

export const getListProcessInstances = state => fromProcessInstances.getListProcessInstances(state.processInstances);

export const getActiveTasksForProcess = (state, processId) =>
    processId &&
    state.processInstances.byId[processId] &&
    state.processInstances.byId[processId].openTasks &&
    state.processInstances.byId[processId].openTasks.map(id => state.tasks.byId[id]);

export const getAllTasksForProcess = (state, processId) =>
    processId &&
    state.processInstances.byId[processId] &&
    state.processInstances.byId[processId].tasks &&
    state.processInstances.byId[processId].tasks.map(id => state.tasks.byId[id]);

export const getCompletedTasksForProcess = (state, processId) =>
    processId &&
    state.processInstances.byId[processId] &&
    state.processInstances.byId[processId].completedTasks &&
    state.processInstances.byId[processId].completedTasks.map(id => state.tasks.byId[id]);

export const getTasks = (state, taskList) => fromTasks.getTasks(state.tasks, taskList);

export const getAllTasks = (state, taskList) => fromTasks.getTasks(state.tasks, state.tasks.allIds);

export const listIsLoading = state => fromProcessInstances.getListIsLoading(state.processInstances);
