import React from 'react';
import { Link } from 'react-router-dom';
import SwapVerticalCircle from '@material-ui/icons/SwapVerticalCircle';
import IconButton from '@material-ui/core/IconButton';

const ImportExportViewConfigBtn = ({ mobile }) => (
    <IconButton color="inherit" component={Link} to={'/admin/manage-view-config'} aria-label="Configure Viewconfig">
        <SwapVerticalCircle />
    </IconButton>
);

export default ImportExportViewConfigBtn;
