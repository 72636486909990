import React from 'react';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { storageController } from 'storage';

const config = require('../../../config.js');

const postDashboard = (dashConfig, name) => {
    const finalPayload = { name: name, displayName: name, config: dashConfig };
    const request = new Request(`${config.API_URL}dashboards`, {
        method: 'POST',
        credentials: 'same-origin',
        body: JSON.stringify(finalPayload),
        headers: new Headers({
            Authorization: `Bearer ${storageController.getToken()}`,
            Cookie: `${window.document.cookie}`,
            Accept: 'application/json',
            'Content-type': 'application/json',
        }),
    });
    fetch(request);
};

const putDashboard = (dashConfig, name, id) => {
    const finalPayload = { id: id, name: name, displayName: name, config: dashConfig };
    const request = new Request(`${config.API_URL}dashboards`, {
        method: 'PUT',
        credentials: 'same-origin',
        body: JSON.stringify(finalPayload),
        headers: new Headers({
            Authorization: `Bearer ${storageController.getToken()}`,
            Cookie: `${window.document.cookie}`,
            Accept: 'application/json',
            'Content-type': 'application/json',
        }),
    });
    fetch(request);
};

const generateDashboard = (name, dashInfo, isEdit, dashId) => {
    const dashboard = {};
    const widgets = dashInfo.widgets;

    dashboard['loaded'] = false; //tslint:disable-line
    const widgetDefinitions = Object.keys(widgets).map(widgetId => ({
        id: widgetId,
        type: widgets[widgetId].type,
        title: widgets[widgetId].title,
        definition: widgets[widgetId].definition,
    }));

    const dashboardConfig = {
        name: name,
        grid: {
            itemBreakpoints: {
                width: {
                    lg: 1200,
                    md: 996,
                    sm: 768,
                    xs: 480,
                    xxs: 240,
                },
                height: {
                    lg: 800,
                    md: 600,
                    sm: 400,
                    xs: 200,
                    xxs: 140,
                },
            },
            breakpoints: {
                md: 708,
                sm: 0,
            },
            cols: {
                md: 12,
                sm: 2,
            },
            layouts: {
                md: dashInfo.layout,
            },
            rowHeight: 30,
        },
        widgets: widgetDefinitions,
    };

    const finalDash = {
        loaded: false,
        dashboardConfig: dashboardConfig,
    };
    if (isEdit) {
        putDashboard(JSON.stringify(finalDash), name, dashId);
    } else {
        postDashboard(JSON.stringify(finalDash), name);
    }

    // console.log(JSON.stringify(finalDash)) //tslint:disable-line
};

const DashCreateButton = props => {
    const { name, dashboardInfo, isEdit, dashId } = props;
    return <Button onClick={() => generateDashboard(name, dashboardInfo, isEdit, dashId)}>Generate Dashboard</Button>;
};

const mapStateToProps = (state, props) => ({
    dashboardInfo: state.dashboardCreator,
    dashId:
        state.dashboardConfig &&
        state.dashboardConfig.configs &&
        state.dashboardConfig.configs[props.name] &&
        state.dashboardConfig.configs[props.name].id,
});

const DashboardCreateButton = compose(
    connect(
        mapStateToProps,
        null,
    ),
)(DashCreateButton);

export default DashboardCreateButton;
