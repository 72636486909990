import React from 'react';
import { parse } from 'query-string';
import GenericList from 'components/generics/genericList';
import { RouterState } from 'connected-react-router';
import { fromNullable } from 'fp-ts/lib/Option';
import { merge } from 'bpm2/replaceInLocation';
import memoizeOne from 'memoize-one';
import { stringify } from 'querystring';
import { RenderListArguments } from 'components/generics/genericList/List';

const getViewName = () => '_TASK_LIST';

interface TaskListProps {
    overrideViewName?: string;
    fakePush?: (search: string) => void;
    readOnly?: boolean;
    processId?: string;
    location: RouterState['location'];
    renderList: (args: RenderListArguments) => JSX.Element | null;
    renderActions?: () => JSX.Element | null;
}

interface TaskListState {
    selectedData: null | {};
}

interface TaskListComponentProps extends TaskListProps {}
class TaskList extends React.Component<TaskListComponentProps, TaskListState> {
    _getFilterFromLocation = memoizeOne(search => fromNullable((parse(search) || {}).filter).map(JSON.parse));
    render() {
        const props = this.props;
        const renderActions = props.renderActions || (() => null);
        return (
            <GenericList
                hasCreate={false}
                renderActions={renderActions}
                updateUrlFromFilter={false}
                fakePush={props.fakePush}
                multiSelectable={false}
                renderFilter={() => null}
                isPopover={false}
                {...props}
                filter={{
                    schedDate__NOT_EMPTY: true,
                    'processInstance.id': props.processId,
                }}
                renderList={props.renderList}
                renderNoResults={props.renderList}
                title={'Schedule Event'}
                resource={'TaskInstance'}
                viewName={props.overrideViewName || getViewName()}
                formId={props.overrideViewName || getViewName()}
                perPage={'100'}
                renderPagination={() => null}
            />
        );
    }
}

interface ContainedTaskListProps {
    readOnly?: boolean;
    processId?: string;
    requireProcessId: boolean;
    overrideViewName?: string;
    renderList: (arg: RenderListArguments) => JSX.Element | null;
    renderActions?: () => JSX.Element | null;
}
interface ContainedTaskListState {
    search: string;
}

interface ContainedTaskListComponentProps extends ContainedTaskListProps {
    initialSearch?: string | null;
}

const createContainedLocation = (processId, search) => {
    if (!processId) {
        return {
            search,
        };
    }
    return {
        search: merge(search, {
            schedDate__NOT_EMPTY: true,
            'processInstance.id': processId,
        }),
    };
};
class ContainedTaskListComponent extends React.Component<ContainedTaskListComponentProps, ContainedTaskListState> {
    memoizedCreateContainedLocation = memoizeOne(createContainedLocation);
    constructor(props: ContainedTaskListComponentProps) {
        super(props);
        this.state = {
            search:
                this.props.initialSearch ||
                `?${stringify({
                    sort: 'endTime',
                    order: 'ASC',
                })}`,
        };
    }
    fakePush = (search: string | { search: string }) => {
        this.setState(typeof search === 'string' ? { search } : search);
    };
    render() {
        const { renderList, renderActions, readOnly, processId, requireProcessId, overrideViewName } = this.props;
        if (!processId && requireProcessId) {
            return <div>Loading...</div>;
        }
        return (
            <TaskList
                renderActions={renderActions}
                location={this.memoizedCreateContainedLocation(processId, this.state.search)}
                fakePush={this.fakePush}
                overrideViewName={overrideViewName}
                readOnly={readOnly}
                processId={processId}
                renderList={renderList}
            />
        );
    }
}
export default ContainedTaskListComponent;
