import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import ownerDocument from '@material-ui/core/utils/ownerDocument';
import List, { ListProps, ListClassKey } from '@material-ui/core/List';
import getScrollbarSize from '@material-ui/core/utils/getScrollbarSize';
import useForkRef from '@material-ui/core/utils/useForkRef';
import { StandardProps } from '@material-ui/core';

export interface MenuListProps extends StandardProps<ListProps, MenuListClassKey> {
    autoFocus?: boolean;
    autoFocusItem?: boolean;
    disableListWrap?: boolean;
    variant?: 'menu' | 'selectedMenu';
    // actions wasn't documented but it's in the code...
    actions?: any;
}

export type MenuListClassKey = ListClassKey;

function nextItem(list: HTMLUListElement, item: HTMLElement, disableListWrap) {
    // if (list === item) {
    if (list === item || !item.parentElement || item.parentElement.parentElement !== list) {
        return list.firstChild.firstChild;
    }
    if (item && item.parentElement.nextElementSibling) {
        return item.parentElement.nextElementSibling.firstChild;
    }
    return disableListWrap ? null : list.firstChild.firstChild;
}

function previousItem(list, item, disableListWrap) {
    // if (list === item) {
    if (list === item || !item.parentElement || item.parentElement.parentElement !== list) {
        return disableListWrap ? list.firstChild.firstChild : list.lastChild.firstChild;
    }
    if (item && item.parentElement.previousElementSibling) {
        return item.parentElement.previousElementSibling.firstChild;
    }
    return disableListWrap ? null : list.lastChild.firstChild;
}

function textCriteriaMatches(nextFocus, textCriteria) {
    if (textCriteria === undefined) {
        return true;
    }
    let text = nextFocus.innerText;
    if (text === undefined) {
        // jsdom doesn't support innerText
        text = nextFocus.textContent;
    }
    if (text === undefined) {
        return false;
    }
    text = text.trim().toLowerCase();
    if (text.length === 0) {
        return false;
    }
    if (textCriteria.repeating) {
        return text[0] === textCriteria.keys[0];
    }
    return text.indexOf(textCriteria.keys.join('')) === 0;
}

function moveFocus(
    list,
    currentFocus,
    disableListWrap,
    traversalFunction,
    textCriteria?: {
        keys: any[];
        repeating: boolean;
        previousKeyMatched: boolean;
        lastTime: any;
    },
) {
    let wrappedOnce = false;
    let nextFocus = traversalFunction(list, currentFocus, currentFocus ? disableListWrap : false);

    while (nextFocus) {
        // Prevent infinite loop.
        if (nextFocus === list.firstChild.firstChild) {
            if (wrappedOnce) {
                return false;
            }
            wrappedOnce = true;
        }
        // Move to the next element.
        if (
            !nextFocus.hasAttribute('tabindex') ||
            nextFocus.disabled ||
            nextFocus.getAttribute('aria-disabled') === 'true' ||
            !textCriteriaMatches(nextFocus, textCriteria)
        ) {
            nextFocus = traversalFunction(list, nextFocus, disableListWrap);
        } else {
            nextFocus.focus();
            return true;
        }
    }

    return false;
}

const useEnhancedEffect = typeof window === 'undefined' ? React.useEffect : React.useLayoutEffect;

/**
 * A permanently displayed menu following https://www.w3.org/TR/wai-aria-practices/#menubutton
 * It's exposed to help customization of the [`Menu`](/api/menu/) component. If you
 * use it separately you need to move focus into the component manually. Once
 * the focus is placed inside the component it is fully keyboard accessible.
 */
const MenuList: React.ComponentType<MenuListProps> = React.forwardRef(function MenuList(props, ref) {
    const {
        actions,
        autoFocus = false,
        autoFocusItem = false,
        children,
        className,
        onKeyDown,
        disableListWrap = false,
        variant = 'selectedMenu',
        ...other
    } = props;
    const listRef = React.useRef(null);
    const textCriteriaRef = React.useRef({
        keys: [],
        repeating: true,
        previousKeyMatched: true,
        lastTime: null,
    });

    useEnhancedEffect(() => {
        if (autoFocus) {
            listRef.current.focus();
        }
    }, [autoFocus]);

    React.useImperativeHandle(
        actions,
        () => ({
            adjustStyleForScrollbar: (containerElement, theme) => {
                // Let's ignore that piece of logic if users are already overriding the width
                // of the menu.
                const noExplicitWidth = !listRef.current.style.width;
                if (containerElement.clientHeight < listRef.current.clientHeight && noExplicitWidth) {
                    const scrollbarSize = `${getScrollbarSize(true)}px`;
                    listRef.current.style[theme.direction === 'rtl' ? 'paddingLeft' : 'paddingRight'] = scrollbarSize;
                    listRef.current.style.width = `calc(100% + ${scrollbarSize})`;
                }
                return listRef.current;
            },
        }),
        [],
    );

    const handleKeyDown = event => {
        event.stopPropagation();
        const list = listRef.current;
        const key = event.key;
        /**
         * @type {Element} - will always be defined since we are in a keydown handler
         * attached to an element. A keydown event is either dispatched to the activeElement
         * or document.body or document.documentElement. Only the first case will
         * trigger this specific handler.
         */
        const currentFocus = ownerDocument(list).activeElement;

        if (key === 'ArrowDown') {
            // Prevent scroll of the page
            event.preventDefault();
            moveFocus(list, currentFocus, disableListWrap, nextItem);
        } else if (key === 'ArrowUp') {
            event.preventDefault();
            moveFocus(list, currentFocus, disableListWrap, previousItem);
        } else if (key === 'Home') {
            event.preventDefault();
            moveFocus(list, null, disableListWrap, nextItem);
        } else if (key === 'End') {
            event.preventDefault();
            moveFocus(list, null, disableListWrap, previousItem);
        } else if (key.length === 1) {
            const criteria = textCriteriaRef.current;
            const lowerKey = key.toLowerCase();
            const currTime = performance.now();
            if (criteria.keys.length > 0) {
                // Reset
                if (currTime - criteria.lastTime > 500) {
                    criteria.keys = [];
                    criteria.repeating = true;
                    criteria.previousKeyMatched = true;
                } else if (criteria.repeating && lowerKey !== criteria.keys[0]) {
                    criteria.repeating = false;
                }
            }
            criteria.lastTime = currTime;
            criteria.keys.push(lowerKey);
            const keepFocusOnCurrent =
                currentFocus && !criteria.repeating && textCriteriaMatches(currentFocus, criteria);
            if (
                criteria.previousKeyMatched &&
                (keepFocusOnCurrent || moveFocus(list, currentFocus, false, nextItem, criteria))
            ) {
                event.preventDefault();
            } else {
                criteria.previousKeyMatched = false;
            }
        }

        if (onKeyDown) {
            onKeyDown(event);
        }
    };

    const handleOwnRef = React.useCallback(instance => {
        // #StrictMode ready
        listRef.current = ReactDOM.findDOMNode(instance);
    }, []);
    const handleRef = useForkRef(handleOwnRef, ref);

    /**
     * the index of the item should receive focus
     * in a `variant="selectedMenu"` it's the first `selected` item
     * otherwise it's the very first item.
     */
    let activeItemIndex = -1;
    // since we inject focus related props into children we have to do a lookahead
    // to check if there is a `selected` item. We're looking for the last `selected`
    // item and use the first valid item as a fallback
    React.Children.forEach(children as React.ReactElement<any>[], (child: React.ReactElement<any>, index) => {
        if (!React.isValidElement(child)) {
            return;
        }

        if (process.env.NODE_ENV !== 'production') {
            if (child.type === React.Fragment) {
                console.error(
                    [
                        "Material-UI: the Menu component doesn't accept a Fragment as a child.",
                        'Consider providing an array instead.',
                    ].join('\n'),
                );
            }
        }

        if (!(child.props as any).disabled) {
            if (variant === 'selectedMenu' && (child.props as any).selected) {
                activeItemIndex = index;
            } else if (activeItemIndex === -1) {
                activeItemIndex = index;
            }
        }
    });

    const items = React.Children.map(children, (child, index) => {
        if (index === activeItemIndex) {
            const newChildProps: {
                autoFocus?: true;
                tabIndex?: 0;
            } = {};
            if (autoFocusItem) {
                newChildProps.autoFocus = true;
            }
            if ((child as React.ReactElement<any>).props.tabIndex === undefined && variant === 'selectedMenu') {
                newChildProps.tabIndex = 0;
            }

            if (newChildProps !== null) {
                return React.cloneElement(child as React.ReactElement<any>, newChildProps);
            }
        }

        return child;
    });

    return (
        <List
            role="menu"
            ref={handleRef}
            className={className}
            onKeyDown={handleKeyDown}
            tabIndex={autoFocus ? 0 : -1}
            {...other}
        >
            {items}
        </List>
    );
});

MenuList.propTypes = {
    /**
     * @ignore
     */
    actions: PropTypes.shape({ current: PropTypes.object }),
    /**
     * If `true`, will focus the `[role="menu"]` container and move into tab order
     */
    autoFocus: PropTypes.bool,
    /**
     * If `true`, will focus the first menuitem if `variant="menu"` or selected item
     * if `variant="selectedMenu"`
     */
    autoFocusItem: PropTypes.bool,
    /**
     * MenuList contents, normally `MenuItem`s.
     */
    children: PropTypes.node,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * If `true`, the menu items will not wrap focus.
     */
    disableListWrap: PropTypes.bool,
    /**
     * @ignore
     */
    onKeyDown: PropTypes.func,
    /**
     * The variant to use. Use `menu` to prevent selected items from impacting the initial focus
     * and the vertical alignment relative to the anchor element.
     */
    variant: PropTypes.oneOf(['menu', 'selectedMenu']),
};

export default MenuList;
