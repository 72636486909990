import { useEffect, useRef } from 'react';
import { localsync } from 'localsync';
import useIdleTimerState, { IdleTimerState } from 'idle-timer/hooks/useIdleTimerState';
import { Controller } from 'localsync-core';
import IdleTimer from 'react-idle-timer';
import produce from 'immer';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';

function publisher(action: IdleTimerState) {
    return action;
}

const useIdleSync = (idleTimer: React.RefObject<IdleTimer>) => {
    const env = useSelector((state: RootState) => {
        return state.basicInfo && state.basicInfo.application && state.basicInfo.application.environment;
    });
    const syncController = useRef<Controller>();
    const [state, dispatch] = useIdleTimerState();
    useEffect(() => {
        // mount
        const syncCreator = localsync({ channel: `casetivity-${env}-channel` });
        syncController.current = syncCreator(publisher, (current, previous) => {
            dispatch(
                produce(current, draftState => {
                    if (draftState.status === 'IDLE') {
                        draftState.date = moment(draftState.date).toDate();
                    }
                    return draftState;
                }),
            );
            if (current.status === 'ACTIVE') {
                if (idleTimer.current) {
                    idleTimer.current.reset();
                }
            }
        });
        syncController.current.start();
        return () => {
            if (syncController.current) {
                syncController.current.stop();
            }
        };
    }, []); // eslint-disable-line
    return [state, dispatch, syncController] as const;
};

export default useIdleSync;
