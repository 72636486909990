import React from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import get from 'lodash/get';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';
import NumberFormat from 'react-number-format';

const NumberFormatCustom = props => {
    const { inputRef, ...other } = props;
    return (
        <NumberFormat
            {...other}
            fixedDecimalScale={true}
            thousandSeparator={true}
            getInputRef={inputRef}
            decimalScale={2}
        />
    );
};

const getValue = (record, source) => {
    return get(record, source);
};

const hideIfNoRecord = branch(
    props => !props.record || (!getValue(props.record, props.source) && props.hideLabel), // record is not provided
    renderNothing,
);

const CurrencyDisplay = ({ record, source, ariaInputProps }) => (
    <TextField
        InputLabelProps={{
            shrink: true,
            disabled: false,
        }}
        value={getValue(record, source)}
        InputProps={{
            inputComponent: NumberFormatCustom,
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        inputProps={{ ...ariaInputProps, style: { padding: '2px 0' } }} // eslint-disable-line
        disabled={true}
    />
);

export default hideIfNoRecord(CurrencyDisplay);
