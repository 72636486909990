import getFormInitial from './index';
import { createSelector } from 'reselect';
import { RootState } from 'reducers/rootReducer';
import { fromNullable } from 'fp-ts/lib/Option';
import { createGetEntities } from '../EntityFormContext/util/getEntities';

interface Props {
    resource: string;
    viewName: string;
}

const createGetConceptExpressionFieldsRequired = <P extends Props>() => {
    return createSelector(
        (state: RootState, props: P) => state.entityConceptExps[props.viewName],
        ec =>
            fromNullable(ec)
                .map(e => Object.values(e).flatMap(ex => ex.fieldsRequired || []))
                .getOrElse([]),
    );
};
const createGetVisibilityExpressionFieldsRequired = <P extends Props>() => {
    return createSelector(
        (state: RootState, props: P) => state.entityVisibility[props.viewName],
        ev =>
            fromNullable(ev)
                .map(e =>
                    Object.values(e)
                        .flatMap(ex => ex)
                        .flatMap(ey => ey.fieldsRequired),
                )
                .getOrElse([]),
    );
};
const createGetValidationExpressionFieldsRequired = <P extends Props>() => {
    return createSelector(
        (state: RootState, props: P) => state.entityValidations[props.resource],
        ev =>
            fromNullable(ev)
                .map(e => e.flatMap(ey => ey.fieldsRequired))
                .getOrElse([]),
    );
};

const createGetExpressionFields = () => {
    const getVisFields = createGetVisibilityExpressionFieldsRequired();
    const getConcFields = createGetConceptExpressionFieldsRequired();
    const getValidationFields = createGetValidationExpressionFieldsRequired();
    const getExpressionFields = createSelector(
        getVisFields,
        getConcFields,
        getValidationFields,
        (visfields, concFields, valFields) => {
            return [...visfields, ...concFields, ...valFields];
        },
    );
    return getExpressionFields;
};

const createGetInitialFormValues = <P extends Props>(conf: { getIdFromProps: (props: P) => string }) => {
    const getExpressionFields = createGetExpressionFields();
    const getEntities = createGetEntities();
    const getInitialValues = createSelector(
        getExpressionFields,
        getEntities,
        (state: RootState) => state.viewConfig,
        (state: RootState, props: P) => props.viewName,
        (state: RootState, props: P) => conf.getIdFromProps(props),
        (expressionFields, entities, viewConfig, viewName, entityId) => {
            return getFormInitial(viewConfig, viewName, expressionFields, entities, entityId, 'EXPANDED');
        },
    );
    return getInitialValues;
};
export default createGetInitialFormValues;
