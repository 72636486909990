import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import AttemptRequest from '../../../components/AttemptRequest';
import * as config from '../../../config';
import { storageController } from 'storage';

const getLazyR = (processId: string) => () =>
    fetch(`${config.BACKEND_BASE_URL}api/bpm/process-instances/${processId}/delete`, {
        method: 'DELETE',
        credentials: 'same-origin',
        headers: new Headers({
            Authorization: `Bearer ${storageController.getToken()}`,
            Cookie: `${window.document.cookie}`,
            Accept: 'application/json',
            'Content-type': 'application/json',
        }),
    });

export const deleteProcessButton = (processId: string) => (onSuccessCb: () => void) => (
    <AttemptRequest
        type="external"
        renderer={({ attemptAction }) => state =>
            state._tag === 'failure' ? (
                <div>
                    Action failed.
                    <a // eslint-disable-line
                        href="javascript:;" // eslint-disable-line
                        onClick={attemptAction}
                    >
                        try again?
                    </a>
                </div>
            ) : state._tag === 'pending' ? (
                <Button style={{ marginLeft: 'auto' }} variant="contained" color="default" disabled={true}>
                    Delete Process
                    <CircularProgress style={{ marginLeft: '1em', height: 20, width: 20 }} />
                </Button>
            ) : state._tag === 'unsubmitted' ? (
                <Button style={{ marginLeft: 'auto' }} variant="contained" color="primary" onClick={attemptAction}>
                    Delete Process
                </Button>
            ) : (
                <span style={{ marginLeft: 'auto' }}>Task {processId} has been deleted successfully</span>
            )}
        lazyRequest={getLazyR(processId)}
        onSuccess={onSuccessCb}
    />
);
