import React from 'react';
import Axios from './axios';
import { MenuItem } from '@material-ui/core';
import { BACKEND_BASE_URL } from 'config';
import { GetItemPropsOptions } from 'downshift';

interface EntityAutocompleteDropdownProps {
    setItemCount: (count: number) => void;
    restUrl: string;
    inputValue: string;
    filter: {};
    refEntityDisplayNamePlural: string;
    getItemProps: (options: GetItemPropsOptions<any>) => any;
    highlightedIndex: number;
    selectedItem: any;
}

const EntityAutocompleteDropdown: React.SFC<EntityAutocompleteDropdownProps> = ({
    setItemCount,
    restUrl,
    inputValue,
    filter,
    getItemProps,
    refEntityDisplayNamePlural,
    selectedItem,
    highlightedIndex,
}) => (
    <Axios<any>
        onSuccess={setItemCount}
        url={BACKEND_BASE_URL + restUrl}
        params={{
            'title.equals': inputValue ? `*${inputValue}*` : '*',
            sort: 'title%2CASC',
            size: 10,
            ...filter,
        }}
    >
        {({ loading, error, data: items = [], total }) => {
            if (loading) {
                return (
                    <MenuItem component="div" aria-live="polite" disabled key="status">
                        Loading...
                    </MenuItem>
                );
            }

            if (error) {
                return (
                    <MenuItem component="div" aria-live="polite" disabled key="status">
                        Error! ${error}
                    </MenuItem>
                );
            }

            if (!items.length) {
                return (
                    <MenuItem component="div" aria-live="polite" disabled key="status">
                        No {refEntityDisplayNamePlural || 'results'} found
                    </MenuItem>
                );
            }
            return items
                .map((record, index) => (
                    <MenuItem
                        key={record.id}
                        {...getItemProps({
                            item: record,
                            index,
                            selected: highlightedIndex === index,
                            isSelected: selectedItem && selectedItem.id === record.id,
                        })}
                        component="div"
                    >
                        {record.title}
                    </MenuItem>
                ))
                .concat([
                    <MenuItem component="div" aria-live="polite" key="status" disabled>
                        Showing {items.length} out of {total} result
                        {total === 1 ? '' : 's'}.
                    </MenuItem>,
                ]);
        }}
    </Axios>
);

export default EntityAutocompleteDropdown;
