import { CRUD_GET_LIST, CRUD_GET_LIST_FAILURE, CRUD_GET_LIST_SUCCESS, CRUD_GET_LIST_CANCELLED } from './constants';
import { createCustomAction } from 'typesafe-actions';
import { NormalizedResponse } from '../util/normalizeEntityResponse';
import { ErrorsCbs } from '../types';

export interface GetListParams {
    resource: string;
    overrideApi?: string;
    pagination: {
        page: number;
        perPage: number;
    };
    sort: {
        field: string;
        order: 'ASC' | 'DESC';
    };
    filter: {};
    view: string | null;
    cb?: (args: { response: any[]; total: number }) => void;
    errorsCbs?: ErrorsCbs;
    appendExpansions?: string[];
}
export const crudGetList = createCustomAction(CRUD_GET_LIST, type => {
    return (payload: GetListParams, cancelOnRouteChange: boolean = true) => {
        const defaultArgs: Partial<GetListParams> = {
            errorsCbs: {},
        };
        return {
            type,
            payload: {
                ...defaultArgs,
                ...payload,
            },
            cancelOnRouteChange,
        };
    };
});

export const crudGetListFailure = createCustomAction(CRUD_GET_LIST_FAILURE, type => {
    return (error: Error, requestPayload: GetListParams) => {
        return {
            type,
            payload: error,
            requestPayload,
        };
    };
});

export const crudGetListCancel = createCustomAction(CRUD_GET_LIST_CANCELLED, type => {
    return (requestPayload: GetListParams) => {
        return {
            type,
            requestPayload,
        };
    };
});

export const crudGetListSuccess = createCustomAction(CRUD_GET_LIST_SUCCESS, type => {
    return <T>(
        data: NormalizedResponse<T, string[]>,
        requestPayload: GetListParams,
        total: number,
        dontOverwrite?: {
            [entityType: string]: {
                [id: string]: {
                    [field: string]: true;
                };
            };
        },
    ) => {
        return {
            type,
            payload: {
                data,
                total,
            },
            meta: {
                dontOverwrite,
            },
            requestPayload,
        };
    };
});
