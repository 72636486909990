import { useCallback, useState } from 'react';
import { View } from 'reducers/ViewConfigType';
import { services } from 'sideEffect/services';
import { AjaxError } from 'rxjs/ajax';
import produce from 'immer';

type SubmitViewState =
    | {
          type: 'SUCCESS';
      }
    | { type: 'NOT_SUBMITTING' }
    | { type: 'SUBMITTING' }
    | {
          type: 'ERROR';
          error: AjaxError;
      };
const useSubmitView = (_view: View) => {
    const view = produce(_view, (draftView: any) => {
        draftView.viewDefName = draftView.name;
        delete draftView.name;
        draftView.fields = Object.values(draftView.fields);
        if (draftView.tabs) {
            draftView.tabs = Object.values(draftView.tabs);
            draftView.tabs.forEach(tab => {
                tab.fields = Object.values(tab.fields);
            });
        }
    });
    const [state, setState] = useState<SubmitViewState>({ type: 'NOT_SUBMITTING' });
    const submit = useCallback(() => {
        setState({ type: 'SUBMITTING' });
        const $ajax = services.updateView(view);
        const subscription = $ajax.subscribe(
            res => {
                if (res.status >= 200 && res.status < 300) {
                    setState({ type: 'SUCCESS' });
                } else {
                    setState({ type: 'NOT_SUBMITTING' });
                }
            },
            (error: AjaxError) => {
                setState({ type: 'ERROR', error });
            },
        );
        return () => {
            if (!subscription.closed) {
                subscription.unsubscribe();
            }
        };
    }, [view]);
    return [state, submit] as const;
};
export default useSubmitView;
