import { put, takeEvery, call, select } from 'redux-saga/effects';
import { loadValueSets } from 'actions/valueSetsActions';
import { fetchJson } from '../util/fetchUtils';
import { FETCH_START, FETCH_END, FETCH_ERROR } from 'actions/aor/types';
import { RootState } from '../reducers/rootReducer';
import { API_URL } from '../config';
import { fromNullable } from 'fp-ts/lib/Option';
import {
    LOAD_MULTIPLE_VALUE_SETS,
    MULTIPLE_VALUE_SETS_NOT_RECEIVED,
    MULTIPLE_VALUE_SETS_RECEIVED,
} from '../actions/constants';
import buildFetchOptions from './util/buildFetchOptions';
import { getTransformConcept, ConceptInApp } from './valueSetsSaga';

interface ConceptFromMultipleVSEndpoint {
    active: boolean;
    code: string;
    description?: string;
    display?: string;
    id: string;
    sortOrder?: number;
}

interface ValueSetFromMultipleVsEndpoint {
    code: string;
    concepts: ConceptFromMultipleVSEndpoint[];
    group?: string;
    display: string;
    id: string;
}

export interface MultipleValuesetsReceivedAction {
    type: typeof MULTIPLE_VALUE_SETS_RECEIVED;
    valueSetsFetched: { valueSet: string; group?: string }[];
    payload: {
        response: ValueSetFromMultipleVsEndpoint[];
        data: {
            entities: {
                Concept: {
                    [id: string]: ConceptInApp;
                };
            };
            result: string[];
        };
    };
    requestPayload: ReturnType<typeof loadValueSets>['payload'];
}
export function* loadValueSetGroup(action: ReturnType<typeof loadValueSets>) {
    const { valuesets } = action.payload;
    const valuesetsToFetch: { valueSet: string; group?: string }[] = yield select((state: RootState): {
        valueSet: string;
        group?: string;
    }[] =>
        valuesets.filter(({ valueSet, group }) =>
            fromNullable(state.valueSets[valueSet]).fold(
                true,
                vs => vs.invalid || (group && fromNullable(vs.groups[group]).fold(true, vsgroup => vsgroup.invalid)),
            ),
        ),
    );
    if (valuesetsToFetch.length === 0) {
        return;
    }
    yield put({ type: FETCH_START });
    try {
        const options = buildFetchOptions();
        // e.g. /api/value-set?values=TestConceptOne.G2,TestConceptTwo,TestConceptOne.G1,
        // TestConceptOne,FieldDataType&includeInActive=true
        const { status, body } = yield call(
            fetchJson,
            `${API_URL}value-set?values=${valuesetsToFetch
                .map(({ valueSet, group }) => (group ? `${valueSet}.${group}` : valueSet))
                .join(',')}&includeInActive=true`,
            options,
        );
        if (status === 200) {
            const response: ValueSetFromMultipleVsEndpoint[] = JSON.parse(body);
            const payload: MultipleValuesetsReceivedAction['payload'] = {
                response,
                data: {
                    entities: {
                        Concept: Object.assign(
                            {},
                            ...response.flatMap(vs =>
                                vs.concepts.map(c => ({ [c.id]: getTransformConcept(vs.id, vs.code)(c) })),
                            ),
                        ),
                    },
                    result: response.flatMap(vs => vs.concepts.map(c => c.id)),
                },
            };
            yield put({
                type: MULTIPLE_VALUE_SETS_RECEIVED,
                valueSetsFetched: valuesetsToFetch,
                payload,
                requestPayload: action.payload,
            });
            yield put({ type: FETCH_END });
        } else {
            yield put({ type: MULTIPLE_VALUE_SETS_NOT_RECEIVED, status });
            yield put({ type: FETCH_ERROR });
        }
    } catch (error) {
        yield put({ type: MULTIPLE_VALUE_SETS_NOT_RECEIVED, error });
        yield put({ type: FETCH_ERROR, error });
    }
}

export default function* getViewConfigOnLoginSaga() {
    yield takeEvery(LOAD_MULTIPLE_VALUE_SETS, function*(action: ReturnType<typeof loadValueSets>) {
        yield call(loadValueSetGroup, action);
    });
}
