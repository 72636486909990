import { put } from 'redux-saga/effects';
import { enqueueSnackbar as enqueueSnackbarAction } from 'notistack/actions';
import { saveAs } from 'file-saver';
import sagaFactory, { configType, HttpVerb } from './util/sagaFactory';

const postConfig: configType = {
    actionType: 'CUSTOM_POST',
    verb: HttpVerb.POST,
    url: (payload: { url: string }) => payload.url,
    body: (payload: { body: {} }) => payload.body,
    success: function*(payload: string, responseBody: {}) {
        yield put({ type: 'CUSTOM_POST_SUCCESS', payload });
        yield put(enqueueSnackbarAction({ message: 'Action succeeded', options: { variant: 'success' } }));
    },
    failure: function*(payload: {}, errorDetails: {}) {
        yield put({ type: 'CUSTOM_POST_FAILURE', payload: errorDetails });
        yield put(enqueueSnackbarAction({ message: 'Action failed', options: { variant: 'error' } }));
    },
    callCb: (payload, responseBody, cb) => {
        cb(payload, responseBody);
    },
    callErrorCb: cb => {
        cb();
    },
};
export const customPostSaga = sagaFactory(postConfig);

const getConfig: configType = {
    actionType: 'CUSTOM_GET',
    verb: HttpVerb.GET,
    url: (payload: { url: string }) => payload.url,
    success: function*(payload: string, responseBody: {}, headers: Headers) {
        yield put({ type: 'CUSTOM_GET_SUCCESS', payload });
        yield put(enqueueSnackbarAction({ message: 'Retrieval succeeded', options: { variant: 'success' } }));
        const disposition = headers.get('content-disposition');
        if (disposition && disposition.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
                const fileName = matches[1].replace(/['"]/g, '');
                saveAs(new Blob([JSON.stringify(responseBody, null, 2)]), fileName);
            }
        }
    },
    failure: function*(payload: {}, errorDetails: {}) {
        yield put({ type: 'CUSTOM_GET_FAILURE', payload: errorDetails });
        yield put(enqueueSnackbarAction({ message: 'Retrieval failed', options: { variant: 'error' } }));
    },
    callCb: (payload, responseBody, cb) => {
        cb(payload, responseBody);
    },
    callErrorCb: cb => {
        cb();
    },
};
export const customGetSaga = sagaFactory(getConfig);

const putConfig: configType = {
    actionType: 'CUSTOM_PUT',
    verb: HttpVerb.PUT,
    url: (payload: { url: string }) => payload.url,
    body: (payload: { body: {} }) => payload.body,
    success: function*(payload: string, responseBody: {}) {
        yield put({ type: 'CUSTOM_PUT_SUCCESS', payload });
        yield put(enqueueSnackbarAction({ message: 'Update succeeded', options: { variant: 'success' } }));
    },
    failure: function*(payload: {}, errorDetails: {}) {
        yield put({ type: 'CUSTOM_PUT_FAILURE', payload: errorDetails });
        yield put(enqueueSnackbarAction({ message: 'Update failed', options: { variant: 'error' } }));
    },
    callCb: (payload, responseBody, cb) => {
        cb(payload, responseBody);
    },
    callErrorCb: cb => {
        cb();
    },
};
export const customPutSaga = sagaFactory(putConfig);
