import React from 'react';
import {
    Values,
    ValuesL1L2,
    IFieldMapping,
    mappedActions,
    IFieldMapping1,
    ValuesIndivL1L2Fields,
    AddressPopoverValue,
} from '../types';
import { Field, DataSource, FieldFromFlowable } from '../../../../translation/types';
import Check from '@material-ui/icons/Check';
import { Concept } from '../../ValueSelectDownshift';

export const isFieldFromFlowable = (field: Field): field is FieldFromFlowable =>
    field._dataSource === DataSource.FLOWABLE;

export const getSource = (fieldDefinition: Field) =>
    isFieldFromFlowable(fieldDefinition)
        ? fieldDefinition.name
        : !fieldDefinition.name.endsWith('Code')
        ? `${fieldDefinition.name}Code`
        : fieldDefinition.name;

export const prependZerosTo3And4DigitZipCodes = zip =>
    zip.length === 4 ? `0${zip}` : zip.length === 3 ? `00${zip}` : zip;

export const mapValuesToOriginalKeys = (values: {}, invertedAddrFieldConfig: { [key: string]: string }): Values =>
    Object.assign({}, ...Object.entries(values).map(([key, value]) => ({ [invertedAddrFieldConfig[key]]: value })));

export const valuesIsLine1Line2 = (values: Values): values is ValuesL1L2 => typeof values['line1'] !== 'undefined'; // tslint:disable-line

export const changeActionsAreLine1Line2 = (
    changeActions: mappedActions<IFieldMapping>,
): changeActions is mappedActions<IFieldMapping1> => typeof changeActions['line1'] !== 'undefined'; // tslint:disable-line

export const configIsLine1Line2 = (config: IFieldMapping): config is IFieldMapping1 =>
    typeof config['line1'] !== 'undefined'; // tslint:disable-line

export const BadConfig = ({ title, e }) => (
    <div
        style={{
            background: `repeating-linear-gradient(
                -55deg,
                rgba(0,0,0,0.05),
                rgba(0,0,0,0.05) 10px,
                rgba(0,0,0,0) 10px,
                rgba(0,0,0,0) 20px
            )`,
        }}
    >
        <h3>{title}</h3>
        <details style={{ whiteSpace: 'pre-wrap' }}>{e}</details>
    </div>
);

export const addressFieldLabels: {
    [key in keyof (ValuesL1L2 & ValuesIndivL1L2Fields)]: string;
} = {
    casetivityLine1: 'Line 1',
    casetivityLine2: 'Line 2',

    casetivityHouse: 'Street Number',
    casetivityStreet: 'Street',
    casetivityUnit: 'Unit',

    casetivityNeighborhoodId: 'Neighborhood/City',
    casetivityNeighborhoodCode: 'Neighborhood/City',

    casetivityStateId: 'State',
    casetivityStateCode: 'State',

    casetivityCityId: 'City (GIS)',
    casetivityCityCode: 'City (GIS)',

    casetivityZip: 'Zip',
};

export const renderVerificationMessage = (
    classes: { primary: string; error: string },
    verificationStatus: AddressPopoverValue,
    verifStatusConceptsByCode: {
        [code: string]: Concept;
    },
) => {
    const verifStatusConcept =
        verificationStatus && typeof verificationStatus === 'string'
            ? verifStatusConceptsByCode[verificationStatus.toLowerCase()]
            : undefined;

    if (!verificationStatus) {
        return null;
    }
    const notVerified = verificationStatus === 'ERROR' || verificationStatus === 'NO_MATCH';
    return (
        <span className={notVerified ? classes.error : classes.primary}>
            Verification Status: {verifStatusConcept ? verifStatusConcept.display : verificationStatus}
            {!notVerified && <Check />}
        </span>
    );
};
