import { taskFormEventType as taskFormEvent } from '../../actions/taskFormEvent';

export default (prevState = {}, action) => {
    switch (action.type) {
        case taskFormEvent.save:
            return {
                ...prevState,
                [action.payload[0]]: 'save',
            };
        case taskFormEvent.saveFailure:
        case taskFormEvent.saveSuccess:
            return {
                ...prevState,
                [action.payload[0]]: false,
            };
        case taskFormEvent.submit:
            return {
                ...prevState,
                [action.payload.taskId]: 'submit',
            };
        case taskFormEvent.submitFailure:
        case taskFormEvent.submitSuccess:
            return {
                ...prevState,
                [action.payload.taskId]: false,
            };
        default:
            return prevState;
    }
};
