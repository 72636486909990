import React from 'react';
import { ReactNode } from 'react';
import ToolbarDropdown from './ToolbarDropdown';
import SidebarDropdown from './SidebarDropdown';
import ThemedMenuItemLink from './SsgMenuItemLink';
import renderNavMenuItems from './submenu-generators/customMenuItems';
import RefreshViewConfigButton from 'viewConfig/components/Refresh';
import AccountMenu from './AccountMenu';
import { getMenus, translateRoute } from '../../generics/utils/viewConfigUtils';
import { MenuProps } from '../MenuInterface';
import ViewConfig from '../../../reducers/ViewConfigType';
import ImportExportViewConfigBtn from '../../importExportViewConfig';
import { Button } from '@material-ui/core';
import useLogout from 'auth/hooks/useLogout';
import { getLoginButtonSelector } from 'util/applicationConfig';
import { connect } from 'react-redux';
import SafeHtmlAsReact from 'templatePage/components/SafeHtmlAsReact';

const LogInButton = props => {
    const logoutFn = useLogout();
    return props.loginHtml ? (
        <SafeHtmlAsReact html={props.loginHtml} />
    ) : (
        <Button variant="outlined" color="inherit" onClick={logoutFn}>
            Log in
        </Button>
    );
};

const mapping = (state, props) => ({
    loginHtml: getLoginButtonSelector(state),
});

const LoginButtonEdited = connect(mapping)(LogInButton);

type navChildren = ViewConfig['menus'][0]['children'];
type passedMenuProps = MenuProps;
const Dropdown: React.SFC<
    passedMenuProps & {
        label: ReactNode;
        id: string;
        mobile: boolean;
        navChildren: navChildren;
        to?: string | null;
        renderMenuItems: (
            args: passedMenuProps & {
                style: React.CSSProperties;
                mobile: boolean;
                navChildren: navChildren;
                onClick: (loc: string) => void;
            },
        ) => any; // tslint:disable-line
        nestedMenuDisplaysFrom?: 'left' | 'right';
    }
> = ({ mobile, ...props }) => (mobile ? <SidebarDropdown {...props} /> : <ToolbarDropdown {...props} />);

Dropdown.defaultProps = {
    mobile: false,
};

export default (props: passedMenuProps & { mobile: boolean }) =>
    props.viewConfig && Object.keys(props.viewConfig).length > 0
        ? [
              getMenus(props.viewConfig).map((menuItem, i) =>
                  !menuItem.children ? (
                      <ThemedMenuItemLink
                          id={menuItem.name}
                          key={`entitydropdown-${menuItem.name}`}
                          to={(() => {
                              const route = translateRoute(props.viewConfig, menuItem.route, menuItem.view);
                              return route.endsWith('/') ? route : `/${route}`;
                          })()}
                          onClick={props.onMenuTap}
                          mobile={props.mobile}
                      >
                          {menuItem.label}
                      </ThemedMenuItemLink>
                  ) : (
                      <Dropdown
                          nestedMenuDisplaysFrom={i > getMenus(props.viewConfig).length - 3 ? 'left' : 'right'}
                          key={`${menuItem.name}-navdropdown`}
                          renderMenuItems={renderNavMenuItems}
                          label={menuItem.label}
                          id={`${menuItem.name}-dropdown`}
                          to={
                              menuItem.route || menuItem.view
                                  ? (() => {
                                        const route = translateRoute(props.viewConfig, menuItem.route, menuItem.view);
                                        return route.endsWith('/') ? route : `/${route}`;
                                    })()
                                  : null
                          }
                          navChildren={menuItem.children}
                          {...props}
                      />
                  ),
              ),
              ...(props.viewConfig && props.viewConfig.user.login !== 'anonymousUser'
                  ? [<AccountMenu key="accountdropdown" {...props} />]
                  : []),
              ...(props.viewConfig && props.viewConfig.user.login === 'super'
                  ? [<ImportExportViewConfigBtn key="viewconfigbtn" mobile={props.mobile} />]
                  : []),
              <RefreshViewConfigButton key="refreshviewconfigbtn" mobile={props.mobile} />,
              ...(props.viewConfig && props.viewConfig.user.login === 'anonymousUser' ? [<LoginButtonEdited />] : []),
          ]
        : [];
