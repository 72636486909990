import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card } from '@material-ui/core';
import compose from 'recompose/compose';
import ViewTitle from '../ViewTitle';
import { push as pushAction } from 'connected-react-router';
import { crudCreate as crudCreateAction } from 'sideEffect/crud/create/actions';
import { EntityBase } from 'sideEffect/services';
import { RootState } from 'reducers/rootReducer';
import SimpleForm from './SimpleForm';
import { View } from 'reducers/ViewConfigType';
import { SpelOptions } from 'expressions/evaluate';
import BackTo from '../button/BackTo';
import SsgAppBarMobile from 'components/SsgAppBarMobile';
import formTypeContext from '../form/formTypeContext';
import { AjaxError } from 'rxjs/ajax';

interface CreateProps {
    hasEdit?: boolean;
    hasShow?: boolean;
    title?: string | JSX.Element;
    toolbar?: JSX.Element;
    onCreateCb?: <T extends EntityBase>(id: string, data: T) => void;
    actions?: JSX.Element | null;
    location: {
        pathname: string;
    };
    resource: string;
    redirect?: string | false;
    view?: View;
    formId?: string;
    injectValues?: {};
    viewName: string;
    match: {};
    options: SpelOptions;
}

const dispatches = {
    crudCreate: crudCreateAction,
    push: pushAction,
};
type Dispatches = typeof dispatches;

function mapStateToProps(state: RootState) {
    return {
        isLoading: state.admin.loading > 0,
        viewConfig: state.viewConfig,
    };
}

interface CreateComponentProps extends CreateProps, Dispatches, ReturnType<typeof mapStateToProps> {}
class Create extends Component<CreateComponentProps> {
    static defaultProps = {
        hasEdit: false,
        hasShow: false,
    };
    getBasePath() {
        const { location } = this.props;
        return location.pathname
            .split('/')
            .slice(0, -1)
            .join('/');
    }

    defaultRedirectRoute() {
        const { hasShow, hasEdit } = this.props;
        if (hasEdit) return 'edit';
        if (hasShow) return 'show';
        return 'list';
    }
    isClppp = () => {
        return this.props.viewConfig.application.name === 'clppp';
    };

    save = (record, redirect, alwaysCb: (err?: AjaxError) => void) => {
        const onCreateCb =
            this.props.onCreateCb || alwaysCb
                ? (id, data) => {
                      if (this.props.onCreateCb) {
                          this.props.onCreateCb(id, data);
                      }
                      if (redirect) {
                          const recordPath = `/${this.props.resource}/${id}`;
                          this.props.push(redirect === 'show' ? recordPath + '/show' : recordPath);
                      }
                      if (alwaysCb) {
                          alwaysCb(); //(id, data);
                      }
                  }
                : undefined;
        const errorsCbs = alwaysCb ? { '*': alwaysCb } : {};
        const { resource } = this.props;
        this.props.crudCreate({
            resource,
            data: record,
            cb: onCreateCb,
            errorsCbs,
            restUrl: resource === 'User' && !this.isClppp() ? 'api/new-user' : undefined,
        });
    };

    render() {
        const {
            actions = null,
            isLoading,
            resource,
            title,
            viewConfig,
            location,
            redirect,
            view,
            formId,
            viewName,
            options,
            match,
            injectValues,
        } = this.props;
        const basePath = this.getBasePath();
        const viewTitle = (viewConfig.entities[resource] && viewConfig.entities[resource].displayName) || resource;
        const titleElement = <span>{title || `Create ${viewTitle}`}</span>;

        return (
            <formTypeContext.Provider value="CREATE">
                <SsgAppBarMobile title={titleElement} />
                <div className="create-page">
                    <Card style={{ opacity: isLoading ? 0.8 : 1, padding: '1%' }}>
                        {actions &&
                            React.cloneElement(actions, {
                                basePath,
                                resource,
                            })}
                        <div style={{ float: 'right' }}>
                            <BackTo />
                        </div>
                        <ViewTitle displayAboveWidth="xs" title={titleElement} />
                        <SimpleForm
                            viewName={viewName}
                            match={match}
                            options={options}
                            formId={formId}
                            view={view}
                            location={location}
                            injectValues={injectValues}
                            save={this.save}
                            resource={resource}
                            basePath={basePath}
                            toolbar={this.props.toolbar}
                            redirect={typeof redirect === 'undefined' ? this.defaultRedirectRoute() : redirect}
                        />
                    </Card>
                </div>
            </formTypeContext.Provider>
        );
    }
}

const enhance = compose(
    connect(
        mapStateToProps,
        dispatches,
    ),
);

export default enhance(Create);
