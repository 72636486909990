export const alertOnceOutcome = (type: string, defaultStatus: string) => (outcomeName, visExpress, l) => {
    const key = `__alerted_casetivity_${type}:${outcomeName}_${visExpress}`;
    if (!window[key]) {
        alert(
            // tslint:disable-line
            `Failed outcome ${type} expression "${visExpress}" for outcome "${outcomeName}"
            ${JSON.stringify(l)}

            The outcome is ${defaultStatus}.
            `,
        );
        window[key] = true;
    }
};

/* For alerting the user exactly once about eval errors */
export const alertOnceEditable = (fieldName, editableExpr, l) => {
    const key = `__alerted_casetivity_${fieldName}_${editableExpr}`;
    if (!window[key]) {
        alert(
            // tslint:disable-line
            `Failed editable expression "${editableExpr}" on field ${fieldName}
            ${JSON.stringify(l)}

            The field is editable.
            `,
        );
        window[key] = true;
    }
};
export const alertOnceVisibleOption = (fieldName, option, visExpr, l) => {
    const key = `__alerted_casetivity_${fieldName}_option_${JSON.stringify(option)}_${visExpr}`;
    if (!window[key]) {
        alert(
            // tslint:disable-line
            `Failed option visibility expression "${visExpr}" on
            field ${fieldName}: option: ${JSON.stringify(option)}
            ${JSON.stringify(l)}

            The option is visible.
            `,
        );
        window[key] = true;
    }
};
