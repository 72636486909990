import React from 'react';
import { Button, withWidth, Dialog } from '@material-ui/core';
import ContentAdd from '@material-ui/icons/Add';
import compose from 'recompose/compose';
import Toolbar from 'components/generics/form/Toolbar.aor';
import SaveButton from 'components/generics/button/SaveButton';
import { connect } from 'react-redux';
import CloseButton from './PopoverCloseButton';
import GenericCreate from '../../components/generics/genericCreate';

const styles = {
    floating: {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 60,
        left: 'auto',
        position: 'fixed',
    },
    flat: {
        overflow: 'inherit',
    },
};

class PopoverCreateButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    }

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const {
            parentId,
            parentEntityName,
            parentFieldInChild,
            resourceBasePath,
            label,
            width,
            widthStyles,
            resourceDisplayName,
        } = this.props;
        // const location = `${resourceBasePath}/create${parentId !== '' ? `?parentEntity=${parentEntityName}&parentField=${parentFieldInChild}&parentId=${parentId}` : ''}`;
        const resourceName = resourceBasePath.slice(1);
        return (
            <React.Fragment>
                <Button
                    color="primary"
                    onClick={this.handleOpen}
                    aria-label={`Create ${resourceDisplayName}`}
                    style={width === 'xs' ? { ...styles.flat, ...((widthStyles || {}).small || {}) } : styles.flat}
                >
                    {label}&nbsp;
                    <ContentAdd />
                </Button>
                <Dialog
                    TransitionProps={{
                        // https://github.com/dequelabs/axe-core/issues/146
                        role: 'presentation',
                    }}
                    open={this.state.open}
                    onClose={this.handleClose}
                    maxWidth={false}
                    fullWidth={true}
                >
                    <div>
                        <GenericCreate
                            {...{
                                injectValues: this.props.injectCreateValues,
                                viewName: this.props.viewName || `${resourceName}Create`,
                                formId: `popover-create-form-${resourceName}-Parent:${parentEntityName}:${parentId}`,
                                redirect: false,
                                location: {
                                    pathname: `${resourceBasePath}/create`,
                                    search: `?parentEntity=${parentEntityName}&parentField=${parentFieldInChild}&parentId=${parentId}`,
                                },
                                resource: resourceName,
                                onCreateCb: (id, data) => {
                                    this.handleClose();
                                    this.props.onCreateCb(id, data);
                                },
                                name: resourceName,
                                toolbar: (
                                    <Toolbar>
                                        <SaveButton />
                                        <CloseButton handleClose={this.handleClose} />
                                    </Toolbar>
                                ),
                            }}
                        />
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }
}

PopoverCreateButton.defaultProps = {
    record: undefined,
    label: 'Create',
    resourceBasePath: '',
    parentEntityName: '',
    parentFieldInChild: '',
    parentId: '',
    onCreateCb: () => null,
};

const enhance = compose(
    connect((state, props) => ({
        resourceDisplayName:
            (state.viewConfig &&
                state.viewConfig.entities[props.resource] &&
                state.viewConfig.entities[props.resource].displayName) ||
            props.resource,
    })),
    withWidth({
        initialWidth: 'md',
    }),
);

export default enhance(PopoverCreateButton);
