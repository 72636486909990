import * as taskPotentialUsers from '../actions';
import { isActionOf } from 'typesafe-actions';
import { RootState } from 'reducers/rootReducer';
import { Epic } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { Services } from 'sideEffect/services';
import { filter, map, catchError, flatMap } from 'rxjs/operators';
import { of } from 'rxjs';

const fetchPotentialUsersFlow: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) =>
    action$.pipe(
        filter(isActionOf(taskPotentialUsers.getTaskPotentialUsers)),
        flatMap(action =>
            services.getTaskPotentialUsers(action.payload.taskId).pipe(
                map(response => taskPotentialUsers.getTaskPotentialUsersSuccess(response, action.payload)),
                catchError(err => of(taskPotentialUsers.getTaskPotentialUsersFailure(err, action.payload))),
            ),
        ),
    );
export default fetchPotentialUsersFlow;
