import { isFieldViewField, getDataTypeForFieldExpr, getAllFieldsFromView } from '../../../utils/viewConfigUtils/index';
import ViewConfig from '../../../../../reducers/ViewConfigType';

const forceBooleanFieldsBoolean = (viewConfig: ViewConfig, viewName: string) => values => {
    const booleanFields = getAllFieldsFromView(viewConfig, viewName).flatMap(f =>
        isFieldViewField(f) &&
        getDataTypeForFieldExpr(viewConfig, f.entity, f.field, 'POP_LAST') === 'BOOLEAN' &&
        f.widgetType !== 'NULLABLE_BOOLEAN'
            ? [f.field]
            : [],
    );
    return Object.assign({}, values, ...booleanFields.map(fexp => ({ [fexp]: !!values[fexp] })));
};
export default forceBooleanFieldsBoolean;
