import deepEql from 'deep-eql';
import { kvDiffInBoth } from './entryDifferentInBothObjects';
import flatten from 'flat';
import flatclone from 'fclone';

const toObj = ([key, value]) => ({ [key]: value });

const entryExistsIn = obj => ([key, value]) => Object.prototype.hasOwnProperty.call(obj, key);

const getConflictingEdits = (
    previousInitialValues: {},
    newInitialValues: {},
    currentFormData: {},
): null | {
    formValues: {};
    newInitialValuesConflictedWith: {};
} => {
    if (previousInitialValues === newInitialValues) {
        return null;
    }
    const flatPrevInitial = flatten(flatclone(previousInitialValues));
    const currentDirty = Object.entries(flatten(flatclone(currentFormData))).filter(
        ([key, value]) => previousInitialValues && !deepEql(flatPrevInitial[key], value),
    );

    const currentDirtyObj = Object.assign({}, ...currentDirty.map(toObj));

    const newInitialEntries = Object.entries(newInitialValues);

    const reinitialized = !previousInitialValues
        ? newInitialEntries
        : newInitialEntries.filter(kvDiffInBoth(previousInitialValues, currentDirtyObj));

    const reinitializedObj = Object.assign({}, ...reinitialized.map(toObj));

    const current = Object.assign({}, ...currentDirty.filter(entryExistsIn(reinitializedObj)).map(toObj));
    const prev = Object.assign({}, ...reinitialized.filter(entryExistsIn(currentDirtyObj)).map(toObj));
    if (Object.keys(current).length > 0 && Object.keys(prev).length > 0) {
        return {
            formValues: current,
            newInitialValuesConflictedWith: prev,
        };
    }
    return null;
};

export default getConflictingEdits;
