import { useMemo } from 'react';
import { RootState } from 'reducers/rootReducer';
import { createSelector } from 'reselect';
import isEqual from 'lodash/isEqual';
import { useSelector } from 'react-redux';
import { fromNullable } from 'fp-ts/lib/Option';

const emptyArray = [];
const getTaskIsDirtySelector = () => {
    const taskIsDirtySelector = createSelector(
        (state: RootState) =>
            fromNullable(state.form)
                .mapNullable(f => f['task-attributes-form'])
                .mapNullable(f => f.anyTouched)
                .getOrElse(undefined),
        (state: RootState) =>
            fromNullable(state.form)
                .mapNullable(f => f['task-attributes-form'])
                .mapNullable(f => f.values)
                .getOrElse(undefined),
        (state: RootState) =>
            fromNullable(state.form)
                .mapNullable(f => f['task-attributes-form'])
                .mapNullable(f => f.initial)
                .getOrElse(undefined),
        (anyTouched, values, initial) => {
            // keys aren't going to be added or removed here...
            const dirtyKeys: string[] =
                anyTouched && values && initial
                    ? Object.entries(values)
                          .filter(([key, value]) => !isEqual(initial[key], value) && !(!initial[key] && !value))
                          .map(([key]) => key)
                    : emptyArray;
            return {
                taskAttributesDirtyKeys: dirtyKeys,
                taskAttributesAreDirty: dirtyKeys.length > 0,
            };
        },
    );
    return taskIsDirtySelector;
};
const assigneeIsDirtySelector = (state: RootState) => {
    const currentAssignee = fromNullable(state.form)
        .mapNullable(f => f['assign-task-form'])
        .mapNullable(f => f.values)
        .mapNullable(v => v.assignee)
        .getOrElse(undefined);
    const initialAssignee = fromNullable(state.form)
        .mapNullable(f => f['assign-task-form'])
        .mapNullable(f => f.initial)
        .mapNullable(v => v.assignee)
        .getOrElse(undefined);
    return currentAssignee !== initialAssignee;
};

const useTaskAttributesAreDirty = () => {
    const taskIsDirtySelector = useMemo(getTaskIsDirtySelector, []);
    const { taskAttributesAreDirty, taskAttributesDirtyKeys } = useSelector(taskIsDirtySelector);
    const assigneeIsDirty = useSelector(assigneeIsDirtySelector);
    return { taskAttributesAreDirty, taskAttributesDirtyKeys, assigneeIsDirty };
};
export default useTaskAttributesAreDirty;
