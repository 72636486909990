import { put, takeEvery } from 'redux-saga/effects';
import { LOAD_VIEW_CONFIG_SUCCESS } from 'viewConfig/constants';
import ViewConfig from '../reducers/ViewConfigType';
import { fromNullable, fromEither } from 'fp-ts/lib/Option';
import { mapOption } from 'fp-ts/lib/Array';
import { EntityValidations } from '../reducers/entityValidationsReducer';
import getValidation from './util/getValidation';
import { getValidationExpForSearch } from '../components/generics/utils/viewConfigUtils';
import { tryCatch } from 'fp-ts/lib/Either';
import storage from 'local-storage-fallback';

export default function*() {
    yield takeEvery(LOAD_VIEW_CONFIG_SUCCESS, function*({
        payload: { viewConfig },
    }: {
        type: typeof LOAD_VIEW_CONFIG_SUCCESS;
        payload: { viewConfig: ViewConfig };
    }) {
        const validConfigs = mapOption(Object.entries(viewConfig.views), ([viewName, view]) =>
            getValidation(
                viewName,
                view.entity,
                viewConfig,
                fromEither(
                    // this should take viewName
                    tryCatch(() => getValidationExpForSearch(viewConfig, viewName)).mapLeft(e => console.log(e)), // tslint:disable-line
                ).chain(fromNullable),
                /*
                        fromNullable(getValidationExpForSearch(viewConfig, view.entity))
                        */
            ),
        );
        const searchValidations: EntityValidations = Object.assign({}, ...validConfigs);
        storage.setItem('searchValidations', JSON.stringify(searchValidations));
        yield put({ type: 'SEARCH_VALIDATIONS_GENERATED', payload: searchValidations });
    });
}
