import React from 'react';
import { Card, CardContent, CardActions, Button } from '@material-ui/core';
import FileDownload from '@material-ui/icons/SaveAlt';

import Popup from '../../components/Popup';

const FileDownloadButton = props => {
    const { fileType, longRunning, handleSubmit, onSubmit } = props;
    const submitReport = handleSubmit(values => onSubmit(props.fileType, values));

    return (
        <div style={{ marginRight: '1em', paddingBottom: '1em' }}>
            {longRunning && (
                <Popup
                    renderToggler={({ openDialog }) => (
                        <Button onClick={openDialog()}>
                            {fileType}&nbsp;
                            <FileDownload />
                        </Button>
                    )}
                    renderDialogContent={({ closeDialog }) => {
                        const submitReportAndClose = () => {
                            submitReport();
                            closeDialog();
                        };

                        return (
                            <Card>
                                <CardContent>
                                    This is a long running report and will be emailed to you when complete, do you want
                                    to proceed?
                                </CardContent>
                                <CardActions>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                        onClick={submitReportAndClose}
                                        style={{ marginRight: 15 }}
                                    >
                                        Yes
                                    </Button>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        onClick={closeDialog}
                                        style={{ marginRight: 15 }}
                                    >
                                        No
                                    </Button>
                                </CardActions>
                            </Card>
                        );
                    }}
                />
            )}

            {!longRunning && (
                <Button type="submit" onClick={submitReport}>
                    {fileType}&nbsp;
                    <FileDownload />
                </Button>
            )}
        </div>
    );
};

export default FileDownloadButton;
