import { connect } from 'react-redux';
import withPropsOnChange from 'recompose/withPropsOnChange';
import compose from 'recompose/compose';
import {
    getRefEntityLabel,
    getRefEntityName,
    getAccessLevelForEntity,
    getAsRefFields,
    getPathBackFromFieldPath,
} from '../../../components/generics/utils/viewConfigUtils';
import applySortableOverrides from '../../../components/generics/fields/applySortableOverrides';
import { withFieldFactory } from '../../Broadcasts';
import pipe from '../../util/pipe';

/*
    Copied this in so there's no dependency on fieldFactoryProvider.
    TODO: copy these options into a seperate file
*/
const DataSource = {
    ENTITY: 'Entity',
    FLOWABLE: 'Flowable',
};
const Mode = {
    DISPLAY: 'Display',
    INPUT: 'Input',
};
export const adjustProps = (overrideLabel = false) =>
    connect(({ viewConfig, printMode }, props) => {
        const source = props.source.endsWith('Ids') ? props.source.slice(0, props.source.length - 3) : props.source;
        const reference = props.overrideReference || getRefEntityName(viewConfig, props.resource, source);
        const relatedField = props.overrideRelatedField || getPathBackFromFieldPath(viewConfig, props.resource, source);
        const label =
            overrideLabel && !props.useOwnLabel ? getRefEntityLabel(viewConfig, props.resource, source) : props.label;
        const referenceAccessLevel = getAccessLevelForEntity(viewConfig, reference);
        return {
            reference,
            label,
            relatedField,
            referenceAccessLevel,
            viewConfig,
            source,
            printMode,
        };
    });
const enhance = compose(
    adjustProps(true),
    withFieldFactory,
    withPropsOnChange(['reference', 'fieldFactory'], props => {
        const config = {
            dataSource: DataSource.ENTITY,
            mode: Mode.DISPLAY,
            validate: false,
            connected: false,
            options: {},
        };
        return {
            generateFields: props.fieldFactory(config)({
                record: props.record,
                resource: props.reference,
                basePath: `/${props.reference}`,
                match: props.match,
            }),
        };
    }),
    withPropsOnChange(
        ['reference', 'relatedField', 'viewConfig', 'source', 'generateFields'],
        ({ reference, relatedField, viewConfig, source, generateFields }) => {
            const applySortOverrides = applySortableOverrides(reference);
            return {
                // pipeline generates the field display components.
                // slightly slower, but this should run infrequently.
                // Also a bit clearer in my opinion since Parameter names are clues as to input/outputs here...
                fields: pipe(
                    refEntityName => getAsRefFields(viewConfig, refEntityName),
                    viewFields =>
                        viewFields.filter(
                            field => relatedField.split('.')[0] !== field.field.split('.')[0] || source === 'revisions',
                        ), // don't filter revisions fields
                    generateFields,
                    fieldComponents => fieldComponents.map(applySortOverrides),
                )(reference), // apply pipeline to the reference name.
            };
        },
    ),
);

export default enhance;
