import React from 'react';
import branch from 'recompose/branch';
import compose from 'recompose/compose';
import renderNothing from 'recompose/renderNothing';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    FormHelperText,
    Theme,
    createStyles,
    withStyles,
    WithStyles,
} from '@material-ui/core';
import { WrappedFieldMetaProps, WrappedFieldInputProps } from 'redux-form';
import uniqueId from 'lodash/uniqueId';

const hideIfNoInput = branch(props => !props.input, renderNothing);

const styles = (theme: Theme) => createStyles({});

interface ChoicesSelectProps {
    choices: { name: string }[];
    meta: WrappedFieldMetaProps;
    input: WrappedFieldInputProps;
    label: string;
    required?: boolean;
    disabled?: boolean;
    renderLabel?: boolean;
}
interface ChoicesSelectComponentProps extends ChoicesSelectProps, WithStyles<typeof styles> {}
const ChoicesSelectComponent: React.SFC<ChoicesSelectComponentProps> = ({
    classes,
    choices,
    meta: { touched, error },
    label,
    input,
    disabled,
    required,
    renderLabel = true,
}) => {
    const inputLabelRef = React.useRef(uniqueId('inputLabel'));
    const ariaErrormessageRef = React.useRef(uniqueId('toggleErrorMsg'));
    const SelectProps: React.InputHTMLAttributes<HTMLDivElement> = {
        'aria-invalid': Boolean(error),
        'aria-describedby': error ? `${inputLabelRef.current} ${ariaErrormessageRef.current}` : inputLabelRef.current,
    };
    return (
        <FormControl required={required} error={touched && error} style={{ width: '100%' }}>
            {renderLabel && <InputLabel id={inputLabelRef.current}>{label}</InputLabel>}
            <Select
                value={(input.value && input.value.name) || ''}
                SelectDisplayProps={SelectProps}
                disabled={disabled}
                onBlur={() => input.onBlur(undefined)}
                onChange={event => {
                    input.onBlur(choices.find(({ name }) => name === event.target.value));
                }}
            >
                {choices.length === 0 && (
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                )}
                {choices.map(object => (
                    <MenuItem key={object.name} value={object.name}>
                        {object.name}
                    </MenuItem>
                ))}
            </Select>
            {touched && error && <FormHelperText id={SelectProps['aria-describedby']}>Error: {error}</FormHelperText>}
        </FormControl>
    );
};

const ChoicesSelect = compose(
    withStyles(styles),
    hideIfNoInput,
)(ChoicesSelectComponent);
export default ChoicesSelect;
