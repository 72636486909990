import { combineReducers } from 'redux'; // eslint-disable-line import/no-extraneous-dependencies
import { taskEventType as taskEvent } from '../../actions/taskEvent';
// import { TaskFromAppTasks } from '../../schemas/api/task-instances';
// import ITask from '../../types/task';
import { TaskFromAppTasks } from '../../dataAdapters/network/api/task-instances/entities/task';

type idList = string[];

export type sortPrefixType = keyof TaskFromAppTasks; // we can sort on all keys from  api/task-instances.
export type sortOrder = 'asc' | 'desc';
export type sort = string; /*
    Perhaps in the future, refactor to use a seperate sortPrefix and sortOrder field.
    This way sort can be represented as a combination of two enums
*/

export interface Filter {
    processDefinitionKey?: string;
    state?: 'open' | 'completed' | 'overdue' | 'all';
    sort?: sort;
    assignee?: string;
}

export interface TaskListState {
    ids: idList;
    filter: Filter;
    isLoading: boolean;
}
export const createList = (getTasksAction, getTasksSuccessAction, getTasksFailureAction) => {
    const ids = (state: idList = [], action): idList => {
        switch (action.type) {
            case getTasksSuccessAction: {
                return action.payload.data.map(p => p.id);
            }
            default:
                return state;
        }
    };

    const filter = (state: Filter = {}, action): Filter => {
        switch (action.type) {
            case getTasksAction: {
                return action.payload;
            }
            default:
                return state;
        }
    };

    const isLoading = (state: boolean = false, action): boolean => {
        switch (action.type) {
            case getTasksAction: {
                return true;
            }
            case getTasksFailureAction:
            case getTasksSuccessAction: {
                return false;
            }
            default:
                return state;
        }
    };

    const list = combineReducers<TaskListState>({
        ids,
        isLoading,
        filter,
    });
    return list;
};

export default createList(taskEvent.getTasks, taskEvent.getTasksSuccess, taskEvent.getTasksFailure);
