import React, { useRef, useEffect } from 'react';
import pick from 'lodash/pick';

const createDragStartEvent = (
    element: HTMLDivElement,
    mouseEvent: {
        clientX: number;
        clientY: number;
    },
) => {
    const event = document.createEvent('HTMLEvents');
    event.initEvent('mousedown', true, true);

    // fake getBoundingClientRect for one call
    // this way, we can influence where the drag action is started
    const original = element.getBoundingClientRect;
    element.getBoundingClientRect = () => {
        element.getBoundingClientRect = original;
        return {
            left: mouseEvent.clientX,
            top: mouseEvent.clientY,
        } as DOMRect;
    };
    element.dispatchEvent(event);
};

const createDragStopEvent = (element: HTMLDivElement) => {
    const event = document.createEvent('HTMLEvents');
    event.initEvent('mouseup', true, true);
};

interface GridElementProps {
    temp?: boolean;
    mouseEvent?: {
        clientX: number;
        clientY: number;
    };
}
const GridElement: React.SFC<GridElementProps> = ({ temp, children, mouseEvent, ...rest }) => {
    const forwardProps = pick(rest, ['style', 'className', 'onMouseDown', 'onMouseUp', 'onTouchEnd', 'onTouchStart']);

    const ref = useRef<HTMLDivElement>();

    // fake the drag start event if its a new element with property temp
    useEffect(() => {
        const refCur = ref && ref.current;
        if (refCur && temp) {
            createDragStartEvent(refCur, mouseEvent);
        }
        return () => refCur && temp && createDragStopEvent(refCur);
    }, [ref, temp, mouseEvent]);

    return (
        <div ref={ref} {...forwardProps}>
            {children}
        </div>
    );
};

export default GridElement;
