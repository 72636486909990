import React, { useContext } from 'react';
import { formContext } from 'components/generics/form/EntityFormContext';
import { fromNullable } from 'fp-ts/lib/Option';
import { getLabelForFieldExpr } from 'components/generics/utils/viewConfigUtils';
import { createStyles, Theme, CardContent, CardActions, Card, makeStyles } from '@material-ui/core';
import { Dialog, Button } from '@material-ui/core';
import { ViewField } from 'reducers/ViewConfigType';
import { tryCatch } from 'fp-ts/lib/Either';
import flatten from 'flat';
import { identity } from 'fp-ts/lib/function';
import TaskFormErrorLIs from 'bpm/components/TaskDetail/TaskForm/util/TaskFormErrorLIs';
import { OpenDialogData } from '../';
import useLinkedEntitySyncErrors from './hooks/useLinkedEntitySyncErrors';
import useResourceDisplayName from 'util/hooks/useResourceDisplayName';
import useViewConfig from 'util/hooks/useViewConfig';

const useStyles = makeStyles(({ palette, spacing }: Theme) =>
    createStyles({
        error: {
            color: palette.error.main,
        },
    }),
);

interface ErrorDialogProps {
    resource: string;
    errorDialogOpen: OpenDialogData | false;
    closeDialog: () => void;
}
const ErrorDialog: React.FunctionComponent<ErrorDialogProps> = props => {
    const { errorDialogOpen } = props;
    const classes = useStyles(props);
    const formSyncErrors = useLinkedEntitySyncErrors();
    const resourceDisplayName = useResourceDisplayName(props.resource);
    const viewConfig = useViewConfig();
    const { viewName } = useContext(formContext);
    return (
        <Dialog
            onClose={props.closeDialog}
            open={!!errorDialogOpen}
            TransitionProps={
                {
                    // https://github.com/dequelabs/axe-core/issues/146
                    role: 'presentation',
                } as any
            }
        >
            <Card>
                <CardContent>
                    <ul>
                        {Object.values(flatten(formSyncErrors)).length > 0 ? (
                            <h4>{resourceDisplayName} Errors</h4>
                        ) : null}
                        {Object.entries(flatten(formSyncErrors)).map(([k, e]) => {
                            let label: string | null | undefined;
                            try {
                                label = fromNullable(viewConfig)
                                    .mapNullable(vc => vc.views[viewName])
                                    .map(v => {
                                        const fields: {
                                            [fieldId: string]: ViewField;
                                        } = fromNullable(v.tabs)
                                            .map(tabs => Object.values(tabs).map(t => t.fields))
                                            .fold(v.fields, tabFields => Object.assign({}, v.fields, ...tabFields));
                                        return fields;
                                    })
                                    .map(f => {
                                        return (
                                            f[k] ||
                                            (k.endsWith('Code')
                                                ? f[k.slice(0, -4)]
                                                : k.endsWith('Id')
                                                ? f[k.slice(0, -2)]
                                                : k.endsWith('Ids')
                                                ? f[k.slice(0, -3)]
                                                : f[k])
                                        );
                                    })
                                    .chain(fromNullable)
                                    .map(f => f.label)
                                    .chain(fromNullable)
                                    .foldL(
                                        () => {
                                            return tryCatch(() =>
                                                getLabelForFieldExpr(viewConfig, props.resource, k, 'TRAVERSE_PATH'),
                                            ).fold(l => {
                                                if (k.endsWith('Code')) {
                                                    return tryCatch(() =>
                                                        getLabelForFieldExpr(
                                                            viewConfig,
                                                            props.resource,
                                                            k.slice(0, -4),
                                                            'TRAVERSE_PATH',
                                                        ),
                                                    ).getOrElse(k);
                                                } else if (k.endsWith('Id')) {
                                                    return tryCatch(() =>
                                                        getLabelForFieldExpr(
                                                            viewConfig,
                                                            props.resource,
                                                            k.slice(0, -2),
                                                            'TRAVERSE_PATH',
                                                        ),
                                                    ).getOrElse(k);
                                                } else if (k.endsWith('Ids')) {
                                                    return tryCatch(() =>
                                                        getLabelForFieldExpr(
                                                            viewConfig,
                                                            props.resource,
                                                            k.slice(0, -3),
                                                            'TRAVERSE_PATH',
                                                        ),
                                                    ).getOrElse(k);
                                                }
                                                return k;
                                            }, identity);
                                        },
                                        l => l,
                                    );
                            } catch (e) {
                                console.error(e); // tslint:disable-line
                            }
                            return <li key={k} className={classes.error}>{`${label || k}: ${e}`}</li>;
                        })}
                        {errorDialogOpen &&
                        errorDialogOpen.submissionType !== 'save' &&
                        Object.keys(errorDialogOpen.getTaskFormErrors()).length > 0 ? (
                            <h4>Task Errors</h4>
                        ) : null}
                        {errorDialogOpen && errorDialogOpen.submissionType !== 'save' ? (
                            <TaskFormErrorLIs
                                classes={{
                                    li: classes.error,
                                }}
                                formDefinition={errorDialogOpen.formDefinition}
                                formErrors={errorDialogOpen.getTaskFormErrors()}
                            />
                        ) : null}
                    </ul>
                </CardContent>
                <CardActions>
                    <Button style={{ margin: '2px' }} variant="contained" color="primary" onClick={props.closeDialog}>
                        Close
                    </Button>
                </CardActions>
            </Card>
        </Dialog>
    );
};

export default ErrorDialog;
