import React from 'react';
import useEvaluateInEntityContext from 'expressions/hooks/useEvaluateInEntityContext';

interface EvaluateInEntityContextProps {
    expression: string;
    defaultOnException: any;
    children: (arg: { expressionResult: any }) => JSX.Element;
}
const EvaluateInEntityContext: React.SFC<EvaluateInEntityContextProps> = props => {
    const expressionResult = useEvaluateInEntityContext(props.expression, false, props.defaultOnException);
    return props.children({ expressionResult });
};

export default EvaluateInEntityContext;
