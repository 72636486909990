import * as t from 'io-ts';
import getErrorFromConfig from '../getErrorFromConfig';

const valuesetDefaultValueConfig = t.partial({
    defaultCode: t.string,
    defaultValue: t.any,
});
type ValuesetDefaultValueConfig = t.TypeOf<typeof valuesetDefaultValueConfig>;
const valuesetDefaultValueExample: ValuesetDefaultValueConfig = {
    defaultCode: 'FOO',
};
const valuesetDefaultValueValidation = getErrorFromConfig(
    valuesetDefaultValueConfig,
    `Invalid configuration for valueset default code. Example: ${JSON.stringify(valuesetDefaultValueExample)}`,
    `invalid JSON: example: ${JSON.stringify(valuesetDefaultValueExample)}`,
);

export default valuesetDefaultValueValidation;
