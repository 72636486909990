import React from 'react';
import { AttemptRequestState } from 'components/AttemptRequest';
import { CardActions, Button, CircularProgress, Link as _Link, withTheme, Theme } from '@material-ui/core';
import Done from '@material-ui/icons/Done';
import TextInput from 'fieldFactory/input/components/TextInput';
import { Link as RouterLink } from 'react-router-dom';
import { InputProps } from '@material-ui/core/Input';
import { PasswordResetButton } from 'auth/components/Login';

const Link: any = _Link;

export const PasswordResetStatus = withTheme((props: { state: AttemptRequestState; theme: Theme }) => {
    const { state, theme } = props;
    return state._tag === 'failure' ? (
        <span style={{ color: theme.palette.error.main }}>
            {state.body && typeof state.body === 'object' ? (
                Object.values(state.body).map((msg, i) => <p key={i}>{msg}</p>)
            ) : (
                <p>
                    Request failed
                    {state.status ? ` with status ${state.status}` : ''}: {state.message}
                </p>
            )}
        </span>
    ) : state._tag === 'success' ? (
        <span>
            <Done /> Success!{' '}
            <Link aria-label="Go to login" component={RouterLink} to="/login">
                Go to login
            </Link>{' '}
        </span>
    ) : null;
});

export const PasswordResetTextField = (props: {
    disabled?: boolean;
    handleChange: InputProps['onChange'];
    handleBlur: InputProps['onBlur'];
    value: string;
    name: string;
    error?: string;
    touched?: boolean;
    label: string;
    type?: 'password';
}) => {
    const { handleChange, handleBlur, value, disabled, name, error, touched, label, type } = props;
    return (
        <TextInput
            randomizeNameAsBrowserAutocompleteHack={false}
            disabled={disabled}
            noDebounce={true}
            resource=""
            source=""
            validate={() => null}
            label={label}
            type={type}
            input={{
                onChange: handleChange,
                onBlur: handleBlur,
                value,
            }}
            options={{
                name,
                margin: 'normal',
            }}
            meta={{
                error,
                touched,
            }}
        />
    );
};

export const renderActions = (state: AttemptRequestState) => (
    <CardActions>
        <Button
            variant="contained"
            color="primary"
            type="submit"
            aria-label="Submit"
            disabled={state._tag === 'pending'}
        >
            Submit
            {state._tag === 'pending' ? <CircularProgress style={{ height: 15, width: 15 }} /> : null}
        </Button>
        {state._tag === 'failure' &&
        state.body &&
        Object.values(state.body).find(
            m => m && typeof m === 'string' && (m.includes('expired') || m.includes('not found')),
        ) ? (
            <PasswordResetButton
                renderButton={({ openDialog }) => (
                    <Button variant="outlined" color="primary" fullWidth={false} onClick={openDialog}>
                        Request Password Reset Key
                    </Button>
                )}
            />
        ) : null}
    </CardActions>
);
