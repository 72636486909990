import React from 'react';
import compose from 'recompose/compose';
import { withStyles, Theme, createStyles, WithStyles, CircularProgress } from '@material-ui/core';
import Content from './Content';
import AttemptRequest from 'components/AttemptRequest';
import { API_URL } from 'config';
import { ReportDefinition } from 'dashboard2/dashboard-config/types';
import { storageController } from 'storage';

const getLazyR = (reportName: string, parameters?: {}) => () =>
    fetch(`${API_URL}reports?name=${reportName}&file-type=JSON&from-dashboard=true`, {
        method: 'POST',
        credentials: 'same-origin',
        headers: new Headers({
            Authorization: `Bearer ${storageController.getToken()}`,
            Cookie: `${window.document.cookie}`,
            Accept: 'application/json',
            'Content-type': 'application/json',
        }),
        body: parameters && JSON.stringify(parameters),
    });

const styles = (theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
            height: '100%',
        },
        tableHeaderCell: {
            position: 'sticky',
            zIndex: 3,
            top: 0,
        },
        flaggedTableRowRoot: {
            backgroundColor: '#ffb3b3',
            cursor: 'pointer',
        },
        flaggedTableRowHover: {
            '&:hover': {
                background: '#ff9999 !important',
            },
        },
    });

const chartHolderChildStyle: React.CSSProperties = {
    position: 'relative',
    height: 'calc(100% - 55px)',
};

interface ReportWidgetProps {
    definition: ReportDefinition;
}
interface ReportWidgetComponentProps extends ReportWidgetProps, WithStyles<typeof styles> {}
class ReportWidgetComponent extends React.Component<ReportWidgetComponentProps> {
    renderData = (data?: {}[]) => {
        console.log('data', data);
        if (data && data.length > 0) {
            return (
                <div style={chartHolderChildStyle}>
                    <Content data={data} definition={this.props.definition} />
                </div>
            );
        }

        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'rgba(0,0,0,0.6)',
                }}
            >
                <span style={{ flexShrink: 0 }}>No data available</span>
            </div>
        );
    };
    render() {
        const { definition } = this.props;
        return (
            <AttemptRequest<{ results: {}[] } | undefined>
                type="external"
                lazyRequest={getLazyR(definition.reportName, definition.parameters)}
                requestOnMount={true}
                renderer={({ attemptAction }) => requestState => {
                    switch (requestState._tag) {
                        case 'failure': {
                            return (
                                <div>
                                    Request failed.
                                    <button aria-label="Retry?" onClick={attemptAction}>
                                        Retry?
                                    </button>
                                </div>
                            );
                        }
                        case 'unsubmitted':
                        case 'pending': {
                            return (
                                <div style={{ height: '100%', position: 'relative' }}>
                                    <div
                                        style={{
                                            margin: 0,
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                        }}
                                    >
                                        <CircularProgress />
                                    </div>
                                </div>
                            );
                        }
                        case 'success': {
                            return this.renderData(requestState.data && requestState.data.results);
                        }
                    }
                }}
            />
        );
    }
}

const enhance = compose(withStyles(styles));

const ReportWidget: React.ComponentType<ReportWidgetProps> = enhance(ReportWidgetComponent);

export default ReportWidget;
