import { isActionOf } from 'typesafe-actions';
import { RootState } from 'reducers/rootReducer';
import { Epic } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { Services } from 'sideEffect/services';
import { filter, map, tap } from 'rxjs/operators';
import getActionButtonDisplays from './getActionButtonDisplays';
import { mapOption } from 'fp-ts/lib/Array';
import { loadSuccess } from 'viewConfig/actions';
import { fromNullable, fromEither } from 'fp-ts/lib/Option';
import { getViewConfiguration } from 'components/generics/utils/viewConfigUtils';
import { tryCatch } from 'fp-ts/lib/Either';
import { actionButtonExpressionsGenerated } from './actions';
import { ACTIONBUTTON_LOCALSTORAGE_KEY } from './constants';
import { ActionButtonExps } from './reducer';
import storage from 'local-storage-fallback';

const generateConceptAvailabilities: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) =>
    action$.pipe(
        filter(isActionOf(loadSuccess)),
        map(({ payload: { viewConfig } }) => {
            const validConfigs = mapOption(Object.entries(viewConfig.views), ([viewName, view]) =>
                getActionButtonDisplays(
                    viewName,
                    view.entity,
                    viewConfig,
                    fromEither(
                        // this should take viewName
                        tryCatch(() => getViewConfiguration(viewConfig, viewName)).mapLeft(e => console.log(e)), // tslint:disable-line
                    ).chain(fromNullable),
                ),
            );
            const visibilityExps: ActionButtonExps = Object.assign({}, ...validConfigs);
            return visibilityExps;
        }),
        tap((vExps: ActionButtonExps) => {
            storage.setItem(ACTIONBUTTON_LOCALSTORAGE_KEY, JSON.stringify(vExps));
        }),
        map(e => {
            return actionButtonExpressionsGenerated(e);
        }),
    );
export default generateConceptAvailabilities;
