import * as viewConfig from './actions';
import { isActionOf } from 'typesafe-actions';
import { RootState } from 'reducers/rootReducer';
import { Epic } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { Services } from 'sideEffect/services';
import { filter, catchError, map, flatMap, tap, withLatestFrom } from 'rxjs/operators';
import { of, concat, Observable } from 'rxjs';
import { enqueueSnackbar as enqueueSnackbarAction } from 'notistack/actions';
import { fetchEnd, fetchStart } from 'actions/aor/fetchActions';
import injectViews from 'injectViews';
import stripUnusedItems from 'stripUnusedItemsFromViewConfig';
import ViewConfig from 'reducers/ViewConfigType';
import { rebuildSchema } from 'sideEffect/crud/util/normalizeEntityResponse';
import { getProcessDefinitions } from 'bpm/processDefinitions/actions';
import storage from 'local-storage-fallback';
import { fromNullable } from 'fp-ts/lib/Option';
import { push as pushAction } from 'connected-react-router';
import { getAnonViewConfig } from 'configureStore/reducer';
import { syncedActionsController } from 'configureStore/syncedActionsController';

let buildIdentifier: string | number | null;

const storeViewConfig = tap(vc => {
    storage.setItem('viewconfig', JSON.stringify(vc));
});

const refreshIfNewBuildDetectedOtherwiseStoreBuildID = tap((vc: ViewConfig) => {
    if (buildIdentifier && buildIdentifier !== vc.application.build) {
        storage.clear();
        window.location.href = '/';
    } else {
        buildIdentifier = vc.application.build;
    }
});

export const reloadViewConfigFromLocalstorageFlow: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    services,
) => {
    return action$.pipe(
        filter(isActionOf(viewConfig.reloadFromLocalstorage)),
        map(action => {
            return fromNullable(storage.getItem('viewconfig'))
                .map(JSON.parse)
                .getOrElseL(getAnonViewConfig);
        }),
        tap(rebuildSchema),
        map(vc => {
            return viewConfig.loadSuccess(vc);
        }),
    );
};

const loadViewConfigFlow: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) => {
    return action$.pipe(
        filter(isActionOf(viewConfig.load)),
        withLatestFrom(state$.pipe(map(state => state.viewConfigIsLoading))),
        filter(([action, viewConfigIsLoading]) => !viewConfigIsLoading),
        flatMap(([action]) =>
            concat<Observable<RootAction>>(
                of(fetchStart()),
                of(viewConfig.loading()),
                services.getViewConfig().pipe(
                    map(injectViews),
                    map(stripUnusedItems),
                    refreshIfNewBuildDetectedOtherwiseStoreBuildID,
                    storeViewConfig,
                    tap(rebuildSchema),
                    tap(vc => {
                        // tell other tabs they can update viewConfig from localstorage
                        (syncedActionsController.trigger as any)(viewConfig.reloadFromLocalstorage());
                    }),
                    flatMap((vc: ViewConfig) => {
                        if (!action.notifyOnSuccess) {
                            return concat(
                                ...([
                                    of(viewConfig.loadSuccess(vc)),
                                    of(fetchEnd()),
                                    of(getProcessDefinitions(false)),
                                ] as Observable<RootAction>[]).concat(
                                    action.redirectTo ? [of(pushAction(action.redirectTo))] : [],
                                ),
                            );
                        }
                        return concat(
                            ...([
                                of(viewConfig.loadSuccess(vc)),
                                of(fetchEnd()),
                                of(
                                    enqueueSnackbarAction({
                                        message: 'Configuration loaded',
                                        options: { variant: 'success' },
                                    }),
                                ),
                                of(getProcessDefinitions()),
                            ] as Observable<RootAction>[]).concat(
                                action.redirectTo ? [of(pushAction(action.redirectTo))] : [],
                            ),
                        );
                    }),
                    catchError(err => {
                        return concat(
                            of(viewConfig.loadFailure(err)),
                            of(fetchEnd()),
                            of(
                                enqueueSnackbarAction({
                                    message: 'View configuration failed to load.',
                                    options: { variant: 'error' },
                                }),
                            ),
                        );
                    }),
                ),
            ),
        ),
    );
};
export default loadViewConfigFlow;
