import { GET_PRIMARY_DASHBOARD, GET_PRIMARY_DASHBOARD_SUCCESS, GET_PRIMARY_DASHBOARD_FAILURE } from './constants';
import { createCustomAction } from 'typesafe-actions';
import { UserPrimaryDashboardResponse } from 'sideEffect/services';

export const getPrimaryDash = createCustomAction(GET_PRIMARY_DASHBOARD, type => {
    return () => ({ type });
});
export const getPrimaryDashSuccess = createCustomAction(GET_PRIMARY_DASHBOARD_SUCCESS, type => {
    return (user: UserPrimaryDashboardResponse) => ({ type, user });
});
export const getPrimaryDashFailure = createCustomAction(GET_PRIMARY_DASHBOARD_FAILURE, type => {
    return (error: Error) => ({ type, error });
});
