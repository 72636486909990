import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import GenericList from 'components/generics/genericList';
import getListRenderer from 'components/generics/genericList/renderList';
import { push as pushAction } from 'connected-react-router';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core';
import { RootState } from 'reducers/rootReducer';

const styles = (theme: Theme) =>
    createStyles({
        headerCell: {
            position: 'sticky',
            zIndex: 3,
            backgroundColor: theme.palette.background.paper,
            top: 0,
        },
        listResults: {
            position: 'relative',
            overflowY: 'auto',
            maxHeight: 270,
            '@media print': {
                overflowY: 'unset',
                maxHeight: 'unset',
            },
        },
    });
interface ClaimableTasksWidgetProps {
    processKey: string[];
    taskKey: string[];
    roles: string[];
    viewName: string;
    onlyUnassigned: boolean;
}

const mapStateToProps = (state: RootState, props: ClaimableTasksWidgetProps) => {
    return {
        viewNames: state.viewConfig && state.viewConfig.views,
    };
};

const dispatches = {
    redirect: pushAction,
};
type Dispatches = typeof dispatches;

interface ClaimableTasksWidgetComponentProps
    extends Dispatches,
        ClaimableTasksWidgetProps,
        ReturnType<typeof mapStateToProps>,
        WithStyles<typeof styles> {}
interface ClaimableTasksWidgetState {
    location: {
        pathname: string;
        search: string;
    };
}
class ClaimableTasksWidgetComponent extends Component<ClaimableTasksWidgetComponentProps, ClaimableTasksWidgetState> {
    state: ClaimableTasksWidgetState = {
        location: {
            pathname: '/task-instances',
            search: '',
        },
    };
    static renderer = getListRenderer({}, {});
    render() {
        const { processKey, taskKey, roles, viewName, onlyUnassigned } = this.props;
        const viewNameModified = viewName ? viewName : '_TASK_LIST_DASHBOARD';
        let filterObject = { endTime__NOT_EMPTY: false };
        if (processKey !== undefined && processKey.length > 0) {
            filterObject['processInstance.businessKey__IN'] = `${processKey}`;
        }
        if (taskKey !== undefined && taskKey.length > 0) {
            filterObject['taskKey__IN'] = `${taskKey}`;
        }
        if (roles !== undefined) {
            filterObject['roleNames'] = `${roles}`;
        }
        if (onlyUnassigned !== undefined && onlyUnassigned) {
            filterObject['assigneeId__NOT_EMPTY'] = false;
        }
        if (!(Object.keys(this.props.viewNames).filter(key => key === viewNameModified).length === 1)) {
            return (
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'rgba(0,0,0,0.6)',
                    }}
                >
                    <span style={{ flexShrink: 0 }}>View name is incorrect or not specified</span>
                </div>
            );
        } else {
            return (
                <div style={{ height: '100%' }}>
                    <GenericList
                        useCard={false}
                        actions={null}
                        onRowSelect={([task]: any) => {
                            this.props.redirect(
                                task.processInstanceId
                                    ? `/processes/${task.processInstanceId}/tasks/${task.id}/start`
                                    : `/tasks/${task.id}`,
                            );
                        }}
                        overrideApi={'api/claimable-tasks'}
                        filter={filterObject}
                        hasCreate={false}
                        multiSelectable={true}
                        updateUrlFromFilter={false}
                        viewName={viewNameModified}
                        showFilters={false}
                        resource={'TaskInstance'}
                        showImmediately={true}
                        perPage={'25'}
                        renderList={r =>
                            getListRenderer(this.props.classes, {})({
                                ...r,
                                ariaProps: { ...r.ariaProps, 'aria-label': 'My Tasks' },
                            })
                        }
                        location={this.state.location}
                        fakePush={location => {
                            this.setState(state => ({ ...state, location }));
                        }}
                    />
                </div>
            );
        }
    }
}

const enhance = compose(
    connect(
        mapStateToProps,
        dispatches,
    ),
    withStyles(styles),
);

const ClaimableTasksWidget: React.ComponentType<ClaimableTasksWidgetProps> = enhance(ClaimableTasksWidgetComponent);
export default ClaimableTasksWidget;
