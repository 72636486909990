import { RootAction } from 'actions/rootAction';
import { getType } from 'typesafe-actions';
import { entityConceptExpressionsGenerated } from './actions';

export interface EntityFieldConceptExps {
    [viewName: string]: {
        [fieldName: string]: {
            valuesetFieldsRequired: any; //tslint:disable-line
            expression: string;
            fieldName: string;
            valuesetLiterals: string[];
            fieldsRequired: string[];
        };
    };
}
export default (previousState: EntityFieldConceptExps = {}, action: RootAction): EntityFieldConceptExps => {
    switch (action.type) {
        case getType(entityConceptExpressionsGenerated):
            return action.payload;
        default:
            return previousState;
    }
};
