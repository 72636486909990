import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardContent, CardActions, Toolbar, Button } from '@material-ui/core';
import AlertError from '@material-ui/icons/Error';
import ActionCheck from '@material-ui/icons/Check';
import compose from 'recompose/compose';
import ViewTitle from '../ViewTitle';
import ListButton from 'components/generics/button/ListButton';
import { crudDelete as crudDeleteAction } from 'sideEffect/crud/delete/actions';
import { crudGetOne as crudGetOneAction } from 'sideEffect/crud/getOne/actions';
import EntityPageTitle from '../fields/display/EntityPageTitle';
import withPopoverLock from '../hoc/popoverLock';
import { push as pushAction } from 'connected-react-router';
import SsgAppBarMobile from 'components/SsgAppBarMobile';
import withProps from 'recompose/withProps';

/*
    DON'T MODIFY THIS!!
    This file will go away soon.

    This is the Delete component that renders on /delete
    (not in popovers)
    Had to pull this out to match the existing AoR data shape.

    TODO: pull out behaviors relating to history, to reduce code dedup
    with the popover delete component.
*/
const styles = {
    actions: { zIndex: 2, display: 'inline-block', float: 'right' },
    toolbar: { clear: 'both' },
    button: { position: 'relative' },
};

class Delete extends Component {
    static defaultProps = {
        createMobileAppBar: true,
    };
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    componentDidMount() {
        this.props.crudGetOne({
            resource: this.props.resource,
            id: this.props.id,
        });
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.id !== nextProps.id) {
            this.props.crudGetOne({
                resource: nextProps.resource,
                id: nextProps.id,
            });
        }
    }

    getBasePath() {
        const { location } = this.props;
        return location.pathname
            .split('/')
            .slice(0, -2)
            .join('/');
    }

    handleSubmit(event) {
        event.preventDefault();
        this.props.crudDelete({
            resource: this.props.resource,
            id: this.props.id,
            cb: () => this.props.push(this.getBasePath()),
        });
    }

    goBack() {
        this.props.history.goBack();
    }

    render() {
        const { id, data, isLoading, resource } = this.props;
        const basePath = this.getBasePath();
        const defaultTitle = `Delete ${resource} ${id}`;
        const titleElement = (
            <b>
                <EntityPageTitle record={data} resource={resource} defaultTitle={defaultTitle} viewName="Delete " />
            </b>
        );

        return (
            <React.Fragment>
                {this.props.createMobileAppBar ? <SsgAppBarMobile title={titleElement} /> : null}
                <div>
                    <Card style={{ opacity: isLoading ? 0.8 : 1, padding: '1%' }}>
                        <CardActions style={styles.actions}>
                            <ListButton basePath={basePath} />
                        </CardActions>
                        <ViewTitle
                            title={titleElement}
                            displayAboveWidth={this.props.createMobileAppBar ? 'xs' : undefined}
                        />
                        <form autoComplete="off" onSubmit={this.handleSubmit}>
                            <CardContent>Are you sure?</CardContent>
                            <Toolbar style={styles.toolbar}>
                                <Button type="submit" variant="contained" color="primary" style={styles.button}>
                                    Delete <ActionCheck />
                                </Button>
                                &nbsp;&nbsp;
                                <Button variant="contained" onClick={this.goBack} style={styles.button}>
                                    Cancel <AlertError />
                                </Button>
                            </Toolbar>
                        </form>
                    </Card>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        id: decodeURIComponent(props.match.params.id),
        data: (state.admin.entities[props.resource] || {})[decodeURIComponent(props.match.params.id)],
        isLoading: state.admin.loading > 0,
    };
}

const enhance = compose(
    connect(
        mapStateToProps,
        { crudGetOne: crudGetOneAction, crudDelete: crudDeleteAction, push: pushAction },
    ),
    withProps(props => {
        return {
            formId: `Delete:${props.resource}:${props.id}`,
        };
    }),
    withPopoverLock,
);

export default enhance(Delete);
