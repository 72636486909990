import React, { useContext, useMemo, useCallback } from 'react';
import { RootState } from 'reducers/rootReducer';
import { FieldFactoryContext } from 'fieldFactory/Broadcasts';
import produce from 'immer';
import MovableGrid, { FieldElem } from './MovableGrid2';
import { View, FieldViewField } from 'reducers/ViewConfigType';
import { fromNullable } from 'fp-ts/lib/Option';
import Clear from '@material-ui/icons/Clear';
import Add from '@material-ui/icons/Add';
import fromentries from 'fromentries';
import getFields, { getGetGenerateFields } from 'components/generics/genericEdit/getFields';
import { useSelector } from 'react-redux';
import { createGetEntities } from 'components/generics/form/EntityFormContext/util/getEntities';
import { LayoutState } from 'layout-editor/demo/layoutReducer';
import { ViewField } from 'casetivity-shared-js/lib/view-config/views';
import { isFieldViewField } from 'components/generics/utils/viewConfigUtils';
import AddField from 'layout-editor/add-field/components/AddViewField';
import TabEditor from './tabs/TabEditor';
import { IconButton } from '@material-ui/core';
import Popup from 'components/Popup';

import EntityPageTitle from 'components/generics/fields/display/EntityPageTitle';
import ViewTitle from 'components/generics/ViewTitle';

interface EditableViewFormLayoutProps {
    mode: 'EDIT' | 'SHOW';
    record?: {
        id: string;
        entityType: string;
    };
    view: View;
    onViewChange: (args: { view: View }) => void;
}
const emptyObject = {};

interface FieldGridProps extends Pick<EditableViewFormLayoutProps, 'view' | 'onViewChange'> {
    fields: React.ReactElement[];
    tab?: string;
    recalculateHeightKey?: string;
    createField: (fieldDefinition: ViewField) => FieldElem;
}
const FieldGrid: React.SFC<FieldGridProps> = ({
    view,
    onViewChange,
    fields,
    tab,
    createField,
    recalculateHeightKey,
}) => {
    const handleLayoutChange = useCallback(
        (args: { layout: LayoutState }) => {
            const newView = produce(view, draftState => {
                const fields = fromentries(
                    args.layout.map(({ originalDefinition, x, y, w }) => {
                        const viewField: ViewField = JSON.parse(originalDefinition);
                        const id = isFieldViewField(viewField) ? viewField.field : `field_${y}_${x}`;
                        return [
                            id,
                            {
                                ...viewField,
                                row: y,
                                column: x,
                                span: w,
                            } as ViewField,
                        ];
                    }),
                );
                if (tab) {
                    draftState.tabs[tab].fields = fields;
                } else {
                    draftState.fields = fields;
                }
                return draftState;
            });
            onViewChange({ view: newView });
        },
        [view, onViewChange, tab],
    );
    return (
        <div style={{ paddingTop: 10 }}>
            {fields && (
                <MovableGrid
                    recalculateHeightKey={recalculateHeightKey}
                    createField={createField}
                    renderFieldAdder={({ addField, initialValues }) => (
                        <Popup
                            renderToggler={({ openDialog }) => {
                                return (
                                    <span>
                                        Add a field{' '}
                                        <IconButton onClick={openDialog()}>
                                            <Add />
                                        </IconButton>
                                    </span>
                                );
                            }}
                            renderDialogContent={({ closeDialog }) => {
                                return (
                                    <div
                                        style={{
                                            width: '512px',
                                            padding: '1em',
                                            border: '1px solid black',
                                        }}
                                    >
                                        <b>Add Field</b>
                                        <IconButton style={{ float: 'right' }} size="small" onClick={closeDialog}>
                                            <Clear />
                                        </IconButton>
                                        <AddField
                                            initialValues={
                                                initialValues && {
                                                    ...initialValues,
                                                    // thing with label is that it defaults back to backing field label.
                                                    fieldPath: initialValues.field,
                                                }
                                            }
                                            rootEntity={view.entity}
                                            onAdd={addField}
                                        />
                                    </div>
                                );
                            }}
                        />
                    )}
                    fields={fields}
                    onLayoutChange={handleLayoutChange}
                    columnStartsAt={1}
                />
            )}
        </div>
    );
};

const EditableViewFormLayout: React.SFC<EditableViewFormLayoutProps> = ({ view, onViewChange, record }) => {
    const fieldFactory = useContext(FieldFactoryContext);
    const viewConfig = useSelector((state: RootState) => state.viewConfig);
    const getEntities = useMemo(createGetEntities, []);
    const currRecord = useSelector((state: RootState) => {
        return fromNullable(getEntities(state)[view.entity])
            .mapNullable(e => record && e[record.id])
            .getOrElse(emptyObject);
    });
    const createField = (viewField: ViewField) =>
        getGetGenerateFields(fieldFactory, {
            isPopover: false,
            referenceFieldsShouldFetchInitialData: false,
            resource: view.entity,
            record: currRecord,
            basePath: `/${view.entity}`,
            match: {
                params: {
                    id: currRecord.id,
                    basePath: `/${view.entity}`,
                },
            },
        })(false)([[(viewField as FieldViewField).field || Math.random().toString(), viewField]])[0];
    const { baseFields, fieldsByTab } = useMemo(() => {
        // return getFields(fieldFactory, formDefinition);
        return getFields(fieldFactory, {
            viewName: view.name,
            viewConfig,
            isPopover: false,
            referenceFieldsShouldFetchInitialData: false,
            resource: view.entity,
            record: currRecord,
            basePath: `/${view.entity}`,
            match: {
                params: {
                    id: currRecord.id,
                    basePath: `/${view.entity}`,
                },
            },
        });
    }, [fieldFactory, view, viewConfig, currRecord]);

    return (
        <div style={{ padding: 10, paddingLeft: 20 }}>
            <ViewTitle
                title={
                    <EntityPageTitle
                        record={record as any}
                        resource={view.entity}
                        defaultTitle={record && `${record.entityType} ${record.id}`}
                    />
                }
            />
            {baseFields && (
                <FieldGrid createField={createField} fields={baseFields} view={view} onViewChange={onViewChange} />
            )}
            <TabEditor
                view={view}
                renderTab={({ tabKey, isActiveTab }) => {
                    return (
                        <FieldGrid
                            recalculateHeightKey={isActiveTab.toString()}
                            createField={createField}
                            tab={tabKey}
                            fields={fieldsByTab[tabKey]}
                            view={view}
                            onViewChange={onViewChange}
                        />
                    );
                }}
            />
        </div>
    );
};
export default EditableViewFormLayout;
