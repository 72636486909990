/* eslint jsx-a11y/anchor-is-valid: 0 */
/* eslint no-script-url: 0 */
import React from 'react';
import { connect } from 'react-redux';
import { Card, CardHeader } from '@material-ui/core';
import Refresh from '@material-ui/icons/Refresh';
import Save from '@material-ui/icons/Save';
import IconButton from '@material-ui/core/IconButton';
import compose from 'recompose/compose';
import { UploadFile } from '../components/custom';
import ExportViewConfigButton from './exportViewConfig';
import { ExportViewConfigEventCreator } from '../actions/exportViewConfig';
import { Typography, CircularProgress } from '@material-ui/core';
import AttemptRequest from './AttemptRequest';
import { Helmet } from 'react-helmet';
import { storageController } from 'storage';

const config = require('../config.js');

const refreshCaches = () => {
    const request = new Request(`${config.BACKEND_BASE_URL}api/refresh-caches`, {
        method: 'POST',
        credentials: 'same-origin',
        headers: new Headers({
            Authorization: `Bearer ${storageController.getToken()}`,
            Cookie: `${window.document.cookie}`,
        }),
    });
    return fetch(request);
};

const saveViewConfig = () => {
    const request = new Request(`${config.BACKEND_BASE_URL}api/view-config/save`, {
        method: 'POST',
        credentials: 'same-origin',
        headers: new Headers({
            Authorization: `Bearer ${storageController.getToken()}`,
            Cookie: `${window.document.cookie}`,
        }),
    });
    return fetch(request);
};

const ViewConfigManager = props => (
    <div>
        <Helmet>
            <title>Admin</title>
        </Helmet>
        <Card style={{ marginBottom: '1em' }}>
            <CardHeader title={'App Info'} />
            <div style={{ padding: '1em 3em' }}>
                <pre>{props.application && JSON.stringify(props.application, null, 4)}</pre>
            </div>
        </Card>
        <div>
            <UploadFile
                title="Import View Config"
                dropMessage="View Config File Here"
                resourceUrl="view-config/import"
            />
        </div>
        <Card style={{ marginBottom: '1em' }}>
            <CardHeader title={'Caches'} />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'baseline' }}>
                <Typography> Refresh Caches: &nbsp;</Typography>
                <AttemptRequest
                    type="external"
                    lazyRequest={refreshCaches}
                    renderer={({ attemptAction }) => state => {
                        if (state._tag === 'failure') {
                            return (
                                <span>
                                    <br />
                                    Refresh Caches failed.
                                    <a href="javascript:;" onClick={attemptAction}>
                                        Retry?
                                    </a>
                                </span>
                            );
                        }
                        if (state._tag === 'pending') {
                            return <CircularProgress style={{ height: 20, width: 20 }} />;
                        }
                        return (
                            <React.Fragment>
                                {state._tag === 'success' ? ' Success!' : ''}
                                <IconButton color="inherit" onClick={attemptAction} aria-label="Refresh Caches">
                                    <Refresh />
                                </IconButton>
                            </React.Fragment>
                        );
                    }}
                />
            </div>
        </Card>
        <Card style={{ marginBottom: '1em' }}>
            <CardHeader title={'Export ViewConfig'} />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'baseline' }}>
                <Typography> Export Full ViewConfig: </Typography>
                <ExportViewConfigButton key="exportviewconfigbtn" mobile={false} onClick={props.exportConfigFull} />
                <Typography> Export Core ViewConfig: </Typography>
                <ExportViewConfigButton key="exportviewconfigcorebtn" mobile={false} onClick={props.exportConfigCore} />
                <Typography> Export ViewConfig Without Users: </Typography>
                <ExportViewConfigButton
                    key="exportviewconfiguserexcludedbtn"
                    mobile={false}
                    onClick={props.exportConfigUserExcluded}
                />
                <Typography> Export Core ViewConfig Without Users: </Typography>
                <ExportViewConfigButton
                    key="exportviewconfigcoreuserexcludedbtn"
                    mobile={false}
                    onClick={props.exportConfigCoreUserExcluded}
                />
                <div
                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'baseline' }}
                >
                    <Typography>Save View Config: &nbsp;</Typography>
                    <AttemptRequest
                        type="external"
                        lazyRequest={saveViewConfig}
                        renderer={({ attemptAction }) => state => {
                            if (state._tag === 'failure') {
                                return (
                                    <span>
                                        <br />
                                        Save failed.
                                        <a href="javascript:;" onClick={attemptAction}>
                                            Retry?
                                        </a>
                                    </span>
                                );
                            }
                            if (state._tag === 'pending') {
                                return <CircularProgress style={{ height: 20, width: 20 }} />;
                            }
                            return (
                                <React.Fragment>
                                    {state._tag === 'success' ? ' Success!' : ''}
                                    <IconButton color="inherit" onClick={attemptAction} aria-label="Save View Config">
                                        <Save />
                                    </IconButton>
                                </React.Fragment>
                            );
                        }}
                    />
                </div>
            </div>
        </Card>
        <Card style={{ marginBottom: '1em' }}>
            <CardHeader title={'Export Processes'} />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'baseline' }}>
                <Typography> Export Process: </Typography>
                <ExportViewConfigButton key="exportviewconfigbtn" mobile={false} onClick={props.exportProcesses} />
            </div>
        </Card>
    </div>
);
const mapDispatchToProps = {
    exportConfigFull: ExportViewConfigEventCreator.exportViewConfigFull,
    exportConfigCore: ExportViewConfigEventCreator.exportViewConfigCore,
    exportConfigUserExcluded: ExportViewConfigEventCreator.exportViewConfigUserExcluded,
    exportConfigCoreUserExcluded: ExportViewConfigEventCreator.exportViewConfigCoreUserExcluded,
    exportProcesses: ExportViewConfigEventCreator.exportProcesses,
};

const enhance = compose(
    connect(
        state => {
            return {
                application: state.viewConfig && state.viewConfig.application,
            };
        },
        mapDispatchToProps,
    ),
);
export default enhance(ViewConfigManager);
