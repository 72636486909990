import React, { useState, createContext } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { EntityFormContextProvider, FCPProps as EditFCPProps } from 'components/generics/form/EntityFormContext';
import {
    FormContextProvider as ShowFormContextProvider,
    FCPProps as ShowFCPProps,
} from 'components/generics/form/EntityFormContext/Show';
import { Card, Button } from '@material-ui/core';
import { some } from 'fp-ts/lib/Option';
import JSONEditorDemo from 'expression-tester/JsonEditorReact';
import makeGetExpression from 'sagas/util/makeGetExpression';
import ExpressionTestArea from 'expression-tester/util/ExpressionTestArea';
import getConceptAvailability from 'viewConfigCalculations/ConceptAvailabilityExpressions/getConceptAvailability';
import uniq from 'lodash/uniq';
import flatten from 'lodash/flatten';
import { getAllValuesetFields } from 'components/generics/form/EntityFormContext/util/entityVisExp';
import ViewConfig from 'reducers/ViewConfigType';
import { getValueSetFieldsRequiredForEntity } from 'components/generics/utils/viewConfigUtils';
import getAllFieldsExpected, {
    getAdhocFieldsForView,
} from 'components/generics/form/EntityFormContext/util/getAllFieldsExpected';
import JsonDownload from 'expression-tester/util/JsonDownload';
import { EntityViewConfig } from 'expressions/entityViewConfig/type';
import TestCalc from 'expression-tester/server-side';

type EntityExpressionTesterProps =
    | ({
          type: 'EDIT';
      } & Pick<EditFCPProps, 'record' | 'viewName' | 'formId'>)
    | ({
          type: 'SHOW';
      } & Pick<ShowFCPProps, 'record' | 'viewName'>);

const getOverrides = (viewName: string, viewConfig: ViewConfig, viewConfiguration) => {
    const editableExp = makeGetExpression('editableField')(
        viewName,
        viewConfig.views[viewName].entity,
        viewConfig,
        some(JSON.stringify(viewConfiguration)),
    );
    const visibleExp = makeGetExpression('visibleField')(
        viewName,
        viewConfig.views[viewName].entity,
        viewConfig,
        some(JSON.stringify(viewConfiguration)),
    );
    const conceptExp = getConceptAvailability(
        viewName,
        viewConfig.views[viewName].entity,
        viewConfig,
        some(JSON.stringify(viewConfiguration)),
    );
    const fieldsInExpressions = (() => {
        return uniq([
            ...editableExp.fold([], v => flatten(Object.values(v[viewName])).flatMap(c => c.fieldsRequired)),
            ...visibleExp.fold([], v => flatten(Object.values(v[viewName])).flatMap(c => c.fieldsRequired)),
            ...conceptExp.fold([], v => Object.values(v[viewName]).map(c => (c as any).fieldsRequired)),
        ]);
    })();

    const allValueset1Fields = {
        ...getValueSetFieldsRequiredForEntity(viewConfig, viewName, 'ONES'),
        ...getAllValuesetFields(
            visibleExp.mapNullable(ve => ve[viewName]).getOrElse({}),
            editableExp.mapNullable(ve => ve[viewName]).getOrElse({}),
            conceptExp.mapNullable(ve => ve[viewName]).getOrElse({}),
            {},
        ),
    };

    return {
        formContextOverrides: {
            editableExps: editableExp.map(e => e[viewName]).toUndefined(),
            visibilityExps: visibleExp.map(e => e[viewName]).toUndefined(),
            conceptExps: conceptExp.map(e => e[viewName]).toUndefined(),
        },
        fieldsInExpressions,
        allValueset1Fields,
    };
};

export const expressionTesterProvidedViewConfigurationContext = createContext<{ [viewName: string]: EntityViewConfig }>(
    {},
);

const emptyObj = {};
const EntityExpressionTester: React.SFC<EntityExpressionTesterProps> = props => {
    const open = useSelector((state: RootState) => state.expressionTesterOpen);
    const [expanded, setExpanded] = useState(false);
    const viewConfig = useSelector((state: RootState) => state.viewConfig);
    const rootViewConfiguration = useSelector((state: RootState) => state.viewConfig.views[props.viewName].config);
    const registeredFields = useSelector((state: RootState) => {
        if (props.type === 'EDIT') {
            return (state.form![props.formId || 'record-form'] || {}).registeredFields || emptyObj;
        }
        return emptyObj;
    });

    const getViewFields = () =>
        getAllFieldsExpected(viewConfig, props.viewName, registeredFields, getAdhocFieldsForView);
    const [viewConfiguration, setViewConfiguration] = useState(
        rootViewConfiguration ? JSON.parse(rootViewConfiguration) : {},
    );
    // short-circuit on 'open' so we don't do these expensive calcs unless the tester is open
    const overrides = React.useMemo(() => {
        return open && getOverrides(props.viewName, viewConfig, viewConfiguration);
    }, [open, props.viewName, viewConfig, viewConfiguration]);
    const providerChildren = (
        <div>
            {open && expanded && (
                <Card>
                    <ExpressionTestArea
                        type={props.type}
                        contextType="entity-view"
                        viewName={props.viewName}
                        record={props.record}
                        fieldsInExpressions={
                            overrides && uniq(flatten([...overrides.fieldsInExpressions, ...getViewFields()]))
                        }
                        valueset1Fields={overrides && overrides.allValueset1Fields}
                        belowEvaluator={
                            props.record && (
                                <TestCalc
                                    entityType={viewConfig.views[props.viewName].entity}
                                    entityId={props.record.id}
                                />
                            )
                        }
                    />
                    <Button onClick={() => setExpanded(false)}>Close</Button>
                </Card>
            )}
            <expressionTesterProvidedViewConfigurationContext.Provider value={{ [props.viewName]: viewConfiguration }}>
                {props.children}
            </expressionTesterProvidedViewConfigurationContext.Provider>
        </div>
    );
    return (
        <div>
            {open && <Button onClick={() => setExpanded(true)}>Inspect Form Data</Button>}
            {open && expanded && (
                <Card>
                    <Button onClick={() => setExpanded(false)}>Close</Button>
                    <JSONEditorDemo json={viewConfiguration} onChangeJSON={setViewConfiguration} />
                    <JsonDownload json={viewConfiguration} />
                </Card>
            )}
            {props.type === 'EDIT' ? (
                <EntityFormContextProvider
                    record={props.record}
                    viewName={props.viewName}
                    formId={props.formId}
                    overrides={overrides ? overrides.formContextOverrides : undefined}
                >
                    {providerChildren}
                </EntityFormContextProvider>
            ) : (
                <ShowFormContextProvider
                    record={props.record}
                    viewName={props.viewName}
                    overrides={overrides ? overrides.formContextOverrides : undefined}
                >
                    {providerChildren}
                </ShowFormContextProvider>
            )}
        </div>
    );
};
export default EntityExpressionTester;
