import { isActionOf } from 'typesafe-actions';
import { RootState } from 'reducers/rootReducer';
import { Epic } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { Services } from 'sideEffect/services';
import { filter, map, tap } from 'rxjs/operators';
import { loadSuccess } from 'viewConfig/actions';
import { isFieldViewField, getAllFieldsFromView, getRefEntityName } from 'components/generics/utils/viewConfigUtils';
import { tryCatch } from 'fp-ts/lib/Option';
import { viewItemFilterExpressionsGenerated } from './actions';
import { VIEWITEM_FILTER_EXPRESSIONS_LOCALSTORAGE_KEY } from './constants';
import storage from 'local-storage-fallback';
import { ViewItemFilterExpressionsGenerated } from './reducer';

import getValueSetCodeLiterals from 'expressions/getFieldsInAst/getValuesetCodeLiterals';
import { SpelExpressionEvaluator } from 'spel2js';
import { getNextBracketedExpression } from 'casetivity-shared-js/lib/spel/getFieldsInAst/getSelectionExpression';
import { SpelCompiledExpression } from 'expressions/evaluate';
import getFieldsInAst from 'casetivity-shared-js/lib/spel/getFieldsInAst';
import uniq from 'lodash/uniq';
import setupValuesetFieldsRequired from 'viewConfigCalculations/util/setupValuesetFieldsRequired';
import ViewConfig from 'reducers/ViewConfigType';
import fromEntries from 'util/fromentries';

export const getSubExpressionsOfFilterTemplate = (filterString: string): string[] => {
    let subExpressions: string[] = [];
    const parse = getNextBracketedExpression({
        bracketClosesOn: ']',
        bracketNaivelyOpensOn: '[',
        bracketOpensOn: '$[',
    });
    const parseForFields = (str: string) => {
        parse(str).fold(str, ({ before, inner, after }) => {
            subExpressions.push(inner);
            parseForFields(after);
            return '';
        });
    };
    parseForFields(filterString);
    return uniq(subExpressions);
};

export const parseFilter = (
    filterString: string,
    viewConfig: ViewConfig,
    resource: string,
): Pick<
    ViewItemFilterExpressionsGenerated[0][0],
    'fieldsRequired' | 'valuesetFieldsRequired' | 'valuesetLiterals' | 'expression'
> => {
    const parse = getNextBracketedExpression({
        bracketClosesOn: ']',
        bracketNaivelyOpensOn: '[',
        bracketOpensOn: '$[',
    });
    const res: Pick<
        ViewItemFilterExpressionsGenerated[0][0],
        'fieldsRequired' | 'valuesetFieldsRequired' | 'valuesetLiterals' | 'expression'
    > = {
        expression: filterString,
        fieldsRequired: [],
        valuesetFieldsRequired: {},
        valuesetLiterals: [],
    };
    const parseForFields = (str: string) => {
        parse(str).fold(str, ({ before, inner, after }) => {
            const compiledExpression = (SpelExpressionEvaluator.compile(inner) as SpelCompiledExpression)
                ._compiledExpression;

            const fieldsRequired = getFieldsInAst(inner)(compiledExpression);
            const valuesetLiterals = getValueSetCodeLiterals(inner)(compiledExpression);
            res.fieldsRequired = uniq([...res.fieldsRequired, ...fieldsRequired]);
            res.valuesetLiterals = uniq([...res.valuesetLiterals, ...valuesetLiterals]);
            setupValuesetFieldsRequired(viewConfig, resource, inner)({ fieldsRequired }).fold(
                error => {
                    console.error('Failed to get Valuesets needed', error);
                },
                ({ valuesetFieldsRequired }) => {
                    res.valuesetFieldsRequired = {
                        ...res.valuesetFieldsRequired,
                        ...valuesetFieldsRequired,
                    };
                },
            );
            parseForFields(after);
            return '';
        });
    };
    parseForFields(filterString);
    return res;
};

const generateViewItemFilterExpressions: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    services,
) =>
    action$.pipe(
        filter(isActionOf(loadSuccess)),
        map(({ payload: { viewConfig } }) => {
            return fromEntries(
                Object.entries(viewConfig.views).flatMap(([viewName, view]) => {
                    const entries = getAllFieldsFromView(viewConfig, viewName)
                        .flatMap(f => {
                            if (
                                isFieldViewField(f) &&
                                f.config &&
                                (f.widgetType === 'SELECT' || f.widgetType === 'ENTITY_TYPEAHEAD')
                            ) {
                                return tryCatch(() => JSON.parse(f.config))
                                    .mapNullable(c => c.filter)
                                    .map(filter => parseFilter(filter, viewConfig, view.entity))
                                    .map((e): ViewItemFilterExpressionsGenerated[0][0][] => [
                                        {
                                            ...e,
                                            fieldName: f.field.endsWith('Id') ? f.field : `${f.field}Id`,
                                            searchEntity: getRefEntityName(viewConfig, f.entity, f.field, 'POP_LAST'),
                                        },
                                    ])
                                    .getOrElse([]);
                            }
                            return [];
                        })
                        .map(e => [e.fieldName, e] as [string, typeof e]);
                    if (entries.length > 0) {
                        return [[viewName, fromEntries(entries)]];
                    }
                    return [];
                }),
            );
        }),
        tap((vExps: ViewItemFilterExpressionsGenerated) => {
            storage.setItem(VIEWITEM_FILTER_EXPRESSIONS_LOCALSTORAGE_KEY, JSON.stringify(vExps));
        }),
        map(e => {
            return viewItemFilterExpressionsGenerated(e);
        }),
    );
export default generateViewItemFilterExpressions;
