export interface EntityVisibilityExps {
    [viewName: string]: {
        [fieldName: string]: {
            valuesetFieldsRequired: any; //tslint:disable-line
            expression: string;
            fieldName: string;
            valuesetLiterals: string[];
            fieldsRequired: string[];
        }[];
    };
}
export default (previousState: EntityVisibilityExps = {}, { type, payload }): EntityVisibilityExps => {
    if (type === 'ENTITY_VISIBILITY_GENERATED') {
        return payload;
    }
    return previousState;
};
