const DashboardCreateEventType = {
    CreateDefinition: 'CREATE_DEFINITION',
    UpdateLayout: 'UPDATE_LAYOUT',
    DeleteDefinition: 'DELETE_DEFINITION',
    ClearAll: 'CLEAR_ALL',
    SetEdited: 'SET_EDITED',
};

const DashboardCreateEventCreator = {
    createDefinition: (id, widgetType, title, definition) => ({
        type: DashboardCreateEventType.CreateDefinition,
        id,
        widgetType,
        title,
        definition,
    }),
    updateLayout: layout => ({
        type: DashboardCreateEventType.UpdateLayout,
        layout,
    }),
    deleteDefinition: id => ({
        type: DashboardCreateEventType.DeleteDefinition,
        id,
    }),
    clearAll: () => ({
        type: DashboardCreateEventType.ClearAll,
    }),
    setEdited: id => ({
        type: DashboardCreateEventType.SetEdited,
        id,
    }),
};

export { DashboardCreateEventCreator, DashboardCreateEventType };
