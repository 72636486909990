import { RemoteData, pending, success, failure } from '@devexperts/remote-data-ts';
import { TaskForm } from 'reducers/rootReducer';
import { RootAction } from 'actions/rootAction';
import { getType } from 'typesafe-actions';
import * as actions from './actions';
import { AjaxError } from 'rxjs/ajax';

type StartForm = {
    [processDefinitionId: string]: RemoteData<AjaxError, TaskForm>;
};
const startFormReducer = (state: StartForm = {}, action: RootAction): StartForm => {
    switch (action.type) {
        case getType(actions.getStartForm):
            if (state[action.payload.processDefinitionId] && state[action.payload.processDefinitionId].isSuccess()) {
                return state;
            }
            return {
                ...state,
                [action.payload.processDefinitionId]: pending,
            };
        case getType(actions.getStartFormSuccess): {
            return {
                ...state,
                [action.requestPayload.processDefinitionId]: success(action.payload.form),
            };
        }
        case getType(actions.getStartFormFailure): {
            if (
                state[action.requestPayload.processDefinitionId] &&
                state[action.requestPayload.processDefinitionId].isSuccess()
            ) {
                return state;
            }
            return {
                ...state,
                [action.requestPayload.processDefinitionId]: failure(action.error),
            };
        }
        default:
            return state;
    }
};
export default startFormReducer;
