import { crudGetListSuccess } from 'sideEffect/crud/getList/actions';
import { crudDeleteSuccess } from 'sideEffect/crud/delete/actions';
import { getType, ActionType } from 'typesafe-actions';
import getResource from './getResource';

export type total = number;
export default (resource: string) => (
    previousState: total = 0,
    action: ActionType<typeof crudGetListSuccess> | ActionType<typeof crudDeleteSuccess>,
): total => {
    if (getResource(action) !== resource) {
        return previousState;
    }
    switch (action.type) {
        case getType(crudGetListSuccess): {
            return action.payload.total;
        }
        default:
            return previousState;
    }
};
