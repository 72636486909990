import React from 'react';
import compose from 'recompose/compose';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';
import { WithStyles, createStyles, Theme, withStyles } from '@material-ui/core';
import moment from 'moment';
import { fromNullable } from 'fp-ts/lib/Option';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { withDateFormat } from '../../dateFormat/Broadcasts';
import { IconButton } from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';
import uniqueId from 'lodash/uniqueId';
import getFormHelperTextProps from 'fieldFactory/util/getFormHelperTextProps';
import formatError from 'fieldFactory/util/formatError';

type Input = any; // tslint:disable-line
type Meta = any; // tslint:disable-line

const hideIfNoInput = branch(
    props => !props.input, // input is not provided
    renderNothing,
);

const styles = ({ palette, spacing }: Theme) =>
    createStyles({
        root: {
            /* ... */
        },
    });

export interface DateTimePickerProps {
    label: string;
    input: Input;
    meta: Meta;
    ariaInputProps?: {};
    renderLabel?: boolean;
    disabled?: boolean;
    options?: {};
    source?: string;
}

interface DateTimePickerComponentProps extends WithStyles<typeof styles>, DateTimePickerProps {
    dateFormat: string;
}
interface DateTimePickerComponentState {
    key: number;
}
export const dateToDateTimeString = value => {
    return fromNullable(value)
        .map(v => moment(v).toISOString())
        .chain(fromNullable)
        .map<string | null>(isoStr => isoStr.split('.')[0] + 'Z')
        .getOrElse(null);
};
class DateTimePickerComponent extends React.Component<DateTimePickerComponentProps, DateTimePickerComponentState> {
    private errorMessageId = uniqueId('datetimepickerErrormsg');
    pickerInputRef: any;
    static defaultProps = {
        ariaInputProps: {},
        renderLabel: true,
    };
    state = { key: 0 };
    changeValue = value => {
        const iso = dateToDateTimeString(value);
        this.props.input.onBlur(iso);
    };
    onBlur = event => {
        const isValidDate = moment(event.target.value, this.getDisplayFormat(), true).isValid();
        if (isValidDate) {
            this.changeValue(event.target.value);
        } else {
            if (
                event.relatedTarget &&
                event.relatedTarget.nodeName === 'BUTTON' &&
                this.pickerInputRef === event.target
            ) {
                // skip clearing input
            } else {
                this.setState(({ key }) => ({ key: key + 1 }));
            }
        }
    };
    onChange = c => {
        this.changeValue(c);
    };
    clearInput = () => {
        this.props.input.onBlur('');
    };

    getDisplayFormat = () => `${this.props.dateFormat} hh:mm A`;
    render() {
        const {
            renderLabel,
            input,
            ariaInputProps,
            label,
            meta: { touched, error },
            disabled,
            options,
            // dateFormat,
        } = this.props;
        const inputProps = {
            'aria-errormessage': touched && error && this.errorMessageId,
        };
        return (
            <div style={{ position: 'relative', display: 'inline-block' }}>
                <KeyboardDateTimePicker
                    key={this.state.key}
                    keyboard={true}
                    inputRef={ref => {
                        this.pickerInputRef = ref;
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        inputProps: {
                            'aria-label': label,
                            ...inputProps,
                            ...ariaInputProps,
                        },
                    }}
                    KeyboardButtonProps={{
                        'aria-label': `Select date from calendar for ${label}`,
                    }}
                    {...input}
                    onChange={this.onChange}
                    value={input.value ? moment(input.value).format(moment.HTML5_FMT.DATETIME_LOCAL) : null}
                    onBlur={this.onBlur}
                    margin="none"
                    label={renderLabel && label}
                    error={!!(touched && error)}
                    helperText={touched && error ? `Error: ${formatError(error)}` : undefined}
                    disabled={disabled}
                    format={this.getDisplayFormat()}
                    FormHelperTextProps={getFormHelperTextProps(inputProps)}
                    leftArrowButtonProps={{
                        'aria-label': 'Previous month',
                    }}
                    rightArrowButtonProps={{
                        'aria-label': 'Next month',
                    }}
                    {...options}
                />
                {!disabled && (input.value && input.value.length > 0) && (
                    <IconButton
                        aria-label={`Clear date for ${label}`}
                        style={{
                            position: 'absolute',
                            right: 30,
                            top: renderLabel ? 8 : -8,
                        }}
                        onClick={this.clearInput}
                    >
                        <Clear />
                    </IconButton>
                )}
            </div>
        );
    }
}

const DateTimeInput: React.SFC<DateTimePickerProps> = compose(
    hideIfNoInput,
    withDateFormat,
    withStyles(styles),
)(DateTimePickerComponent);

export default DateTimeInput;
