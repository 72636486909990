import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import ActionList from '@material-ui/icons/List';

const ListButton = ({ basePath, label, icon }) => (
    <Button color="primary" component={props => <Link to={basePath} {...props} />} style={{ overflow: 'inherit' }}>
        {label}&nbsp;{icon}
    </Button>
);

ListButton.defaultProps = {
    basePath: '',
    label: 'List',
    icon: <ActionList />,
};

export default ListButton;
