import get from 'lodash/get';

function isArray<T>(data: T | Array<T>): data is Array<T> {
    return data instanceof Array;
}

const createDataMappedExpression = (urlPattern, data: string | {}, dataContext: {}, failIfPropertyNotFound = true) => {
    const startingSeparator = typeof data === 'string' ? `\${${dataContext}` : `\${${dataContext}_`;
    const endingSeparator = '}';
    const splitByStartingSep = urlPattern.split(startingSeparator);
    const [lead, ...rest] = splitByStartingSep;
    return [
        lead,
        ...rest.map(s => {
            const innerExp = s.slice(0, s.indexOf(endingSeparator));
            const remainingExp = s.slice(s.indexOf(endingSeparator) + 1);
            if (typeof data === 'object') {
                if (get(data, innerExp)) {
                    return `${get(data, innerExp)}${remainingExp}`;
                }
            } else if (typeof data === 'string') {
                return `${data}${remainingExp}`;
            }
            if (failIfPropertyNotFound) {
                throw Error(
                    `Expression failed: field ${innerExp} not found on record ${data}. This should be fixed in the 'config' configuration for this EntityView`,
                );
            } else {
                return '__FAILURE_PROPERTY_NOT_FOUND_IN_DATA__';
            }
        }),
    ].join('');
};

export const mapDataToExpression = (
    urlPattern: string,
    data: string | {} | (string | {})[],
    dataContext: string | string[] = 'record',
    failIfPropertyNotFound = true,
) => {
    if (isArray<{} | string>(data) && isArray<string>(dataContext)) {
        if (data.length !== dataContext.length) {
            throw Error('If arrays are provided for data and dataContext, they must be of matching length');
        }
        let url = urlPattern;
        dataContext.forEach((context, i) => {
            url = createDataMappedExpression(url, data[i], context, failIfPropertyNotFound);
        });
        return url;
    } else if (!isArray<{} | string>(data) && !isArray<string>(dataContext)) {
        return createDataMappedExpression(urlPattern, data, dataContext, failIfPropertyNotFound);
    }
    throw Error('data and dataContext must either both be arrays, or both not be arrays.');
};
