import React from 'react';
import DisplayStatus from '../DisplayStatus';
import { NetworkUnavailable, ServerError } from '../pages';
import DeferredSpinner from 'components/DeferredSpinner';
import ErrorPage from '../pages/BaseErrorPage';
import Warning from '@material-ui/icons/Warning';

interface FormDisplayStatusProps {
    resource: string;
    id: string;
    showSuccessOffline: boolean;
    renderSuccess: () => JSX.Element;
    renderSpinner?: () => JSX.Element;
    refresh?: (e: any) => void;
}
const FormDisplayStatus: React.SFC<FormDisplayStatusProps> = ({
    resource,
    id,
    showSuccessOffline,
    renderSuccess,
    refresh,
    renderSpinner = () => <DeferredSpinner />,
}) => (
    <DisplayStatus
        resource={resource}
        id={id}
        renderUnhandledError={() => {
            return (
                <ErrorPage headingText="Unhandled Error" Icon={Warning} subText={'Check console for error details'} />
            );
        }}
        renderSuccess={renderSuccess}
        renderLoading={prevStatus => {
            if (prevStatus === 'initial' || prevStatus._type === 'error') {
                return renderSpinner();
            }
            return renderSuccess();
        }}
        renderMoved={newId => <div>Element moved to id: {newId}</div>}
        renderNetworkError={() => {
            // render any existing/cached data if possible
            return showSuccessOffline ? renderSuccess() : <NetworkUnavailable retry={refresh} />;
        }}
        renderServerError={(code, message) => {
            return <ServerError code={code} message={message} />;
        }}
    />
);
export default FormDisplayStatus;
