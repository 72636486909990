import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import compose from 'recompose/compose';
import { Omit } from 'utility-types';
import { crudGetOne as crudGetOneAction } from 'sideEffect/crud/getOne/actions';
import { RootState } from '../../../../reducers/rootReducer';
import PopoverCreateButton from '../../../popovers/PopoverCreateButton';
import ListComponent, { ListComponentProps } from './Components/ContainedList';
import { getAccessLevelForEntity, allowsCreate } from 'components/generics/utils/viewConfigUtils';

type ListSelectComponentProps = Pick<
    ListComponentProps,
    | 'expansions'
    | 'source'
    | 'viewName'
    | 'resource'
    | 'singleSelect'
    | 'input'
    | 'allData'
    | 'label'
    | 'useCheckboxes'
    | 'disabled'
> & {
    crudGetOne: typeof crudGetOneAction;
    options?: { disabled?: boolean };
    viewConfig: RootState['viewConfig'];
    hasCreate?: boolean;
    filter: string;
};
const emptyObj = {};

export const getFilterFromFilterString = (filterString?: string): {} => {
    if (filterString) {
        return Object.assign(
            {},
            ...(filterString as string).split(',').map(kvpair => ({
                [kvpair.split('=')[0]]: kvpair.split('=')[1].replace(new RegExp(';', 'g'), ','),
            })),
        );
    }
    return emptyObj;
};
const ListSelectComponent: React.SFC<ListSelectComponentProps> = ({
    source,
    viewName,
    resource,
    filter: filterString,
    input,
    allData,
    crudGetOne,
    label,
    options,
    viewConfig,
    useCheckboxes,
    disabled,
    singleSelect,
    expansions = [],
    hasCreate = true,
}) => {
    const [refreshKey, updateRefreshKey] = useState(0);
    const dispatch = useDispatch();
    const filter = getFilterFromFilterString(filterString);
    const hasCreatePermission = allowsCreate(getAccessLevelForEntity(viewConfig, resource));
    const parentEntityName = (() => {
        const referenceField = Object.keys(filter).find(k => k.endsWith('Id'));
        if (referenceField) {
            const f = viewConfig.entities[resource].fields[referenceField.slice(0, 'Id'.length * -1)];
            return f && f.relatedEntity;
        }
        return undefined;
    })();
    const parentFieldInChild = Object.keys(filter).find(k => k.endsWith('Id'));
    const parentId =
        Object.keys(filter).find(k => k.endsWith('Id')) &&
        filter[Object.keys(filter).find(k => k.endsWith('Id')) as string];
    // in the context of a task-form, we should ensure this entity is there
    useEffect(() => {
        // if it's CasetivityEntity we won't be creating or anything so we can ignore.
        if (parentId && parentEntityName && parentEntityName !== 'CasetivityEntity') {
            dispatch(
                crudGetOne({
                    id: parentId,
                    resource: parentEntityName,
                    view: -1,
                }),
            );
        }
    }, []); // eslint-disable-line
    return (
        <ListComponent
            expansions={expansions}
            viewConfig={viewConfig}
            noRecentlyVisited={true}
            key={refreshKey}
            label={label}
            useCheckboxes={useCheckboxes}
            actions={
                hasCreate && !disabled && hasCreatePermission ? (
                    <PopoverCreateButton
                        resource={resource}
                        resourceBasePath={`/${resource}`}
                        label={'Create'}
                        parentEntityName={parentEntityName}
                        parentFieldInChild={parentFieldInChild}
                        parentId={parentId}
                        onCreateCb={() => updateRefreshKey(refreshKey + 1)}
                    />
                ) : null
            }
            hasCreate={false}
            multiSelectable={true}
            singleSelect={singleSelect}
            updateUrlFromFilter={false}
            viewName={viewName}
            basePath={`/${resource}`}
            source={source}
            resource={resource}
            name={resource}
            filter={filter}
            input={input}
            allData={allData}
            crudGetOne={crudGetOne}
            disabled={disabled || (options || {}).disabled}
        />
    );
};

type ListSelectProps = Omit<ListSelectComponentProps, 'resource'>;
const ListSelect: React.SFC<ListSelectProps> = compose(
    connect(
        (state: RootState, props: ListSelectComponentProps) => {
            const resource = state.viewConfig.views[props.viewName].entity;
            return {
                resource,
                allData: resource && state.admin.entities[resource],
                viewConfig: state.viewConfig,
            };
        },
        {
            crudGetOne: crudGetOneAction,
        },
    ),
)(ListSelectComponent);
export default ListSelect;
