import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { RootState } from '../../src/reducers/rootReducer';
import { fromNullable } from 'fp-ts/lib/Option';

export interface UserIsSuperProps {}
const makeMapStateToProps = () => {
    // create any caches/selectors in this scope
    const mapStateToProps = (state: RootState, props: UserIsSuperProps) => {
        return {
            userIsSuper:
                state.debugMode ||
                fromNullable(state.viewConfig)
                    .mapNullable(vc => vc.user)
                    .mapNullable(u => u.roles)
                    .map(r => r.includes('ROLE_SUPER'))
                    .getOrElse(false),
        };
    };
    return mapStateToProps;
};

interface UserIsSuperComponentProps extends ReturnType<ReturnType<typeof makeMapStateToProps>>, UserIsSuperProps {}

class UserIsSuperComponent extends React.Component<UserIsSuperComponentProps> {
    render() {
        const { children, userIsSuper } = this.props;
        if (userIsSuper) {
            return children;
        }
        return null;
    }
}

const UserIsSuper: React.SFC<UserIsSuperProps> = compose(connect(makeMapStateToProps))(UserIsSuperComponent);

export default UserIsSuper;
