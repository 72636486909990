import React from 'react';
import { Filter, ProcessStatus } from '../../types';
import {
    getStateFromFilter,
    getPartialFilterFromState,
    getAssigneeFromFilter,
    getPartialFilterFromAssignee,
} from '../../util';
import { connect } from 'react-redux';
import SelectInput from 'bpm2/sharedComponents/ChoicesSelect2';
import { RootState } from '../../../../reducers/rootReducer';
import { createSelector } from 'reselect';
import { PotentialUser } from 'bpm/potentialUsers/reducer';

interface CaseTypeDropdownProps {
    processDefinitionKey?: string;
    disabled?: boolean;
    onChange: (value: string) => void;
}

const makeMapStateToProps = () => {
    const sortedProcessDefinitionsSelector = createSelector(
        (state: RootState) => state.bpm.processDefinitions.byId,
        processDefinitions =>
            Object.assign(
                {},
                ...Object.entries(processDefinitions)
                    .sort(([keyA, a], [keyB, b]) => a.name.localeCompare(b.name))
                    .map(([k, v]) => ({ [k]: v })),
            ),
    );
    return (state: RootState, props: CaseTypeDropdownProps) => ({
        processDefinitions: sortedProcessDefinitionsSelector(state),
    });
};
interface CaseTypeDropdownComponentProps
    extends CaseTypeDropdownProps,
        ReturnType<ReturnType<typeof makeMapStateToProps>> {}
const CaseTypeDropdownComponent = ({
    processDefinitionKey,
    onChange,
    processDefinitions,
    disabled,
}: CaseTypeDropdownComponentProps) => (
    <SelectInput
        disabled={disabled}
        label="Case Type"
        input={{
            value: processDefinitionKey,
            onBlur: onChange,
        }}
        choices={processDefinitions}
    />
);
export const CaseTypeDropdown: React.ComponentType<CaseTypeDropdownProps> = connect(makeMapStateToProps)(
    CaseTypeDropdownComponent,
);

export const StateDropdown: React.SFC<{
    filter?: Filter;
    onChange: (value: Filter) => void;
}> = ({ onChange, filter = {} }) => (
    <SelectInput
        showNoneChoice={true}
        noneChoiceText="All"
        label="Case Status"
        input={{
            value: getStateFromFilter(filter),
            onBlur: s => onChange(getPartialFilterFromState(s)),
        }}
        choices={{
            [ProcessStatus.OPEN]: {
                name: 'Open',
            },
            [ProcessStatus.CLOSED]: {
                name: 'Closed',
            },
        }}
    />
);

export const AssignedDropdown: React.SFC<{
    filter?: Filter;
    currentUser: string;
    onChange: (value: Filter) => void;
    users?: {
        [login: string]: PotentialUser;
    };
}> = ({ onChange, filter, users = {}, currentUser }) => (
    <SelectInput
        label="Staff Assignee"
        input={{
            value:
                filter && filter['processInstance.tasks.assignee.id']
                    ? getAssigneeFromFilter(filter)
                    : // filter && filter['processInstance.tasks.assignee__NOT_EMPTY'] === false ? null :
                    filter && filter.assignee_ANY
                    ? null
                    : currentUser,
            onBlur: s => onChange(getPartialFilterFromAssignee(s)),
        }}
        nameProp="title"
        valueProp="id"
        choices={users}
    />
);
