import React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Clear from '@material-ui/icons/Clear';
import { datify, getTzOffset } from './datify';
import { withDateFormat, DateFormatProps } from '../../../dateFormat/Broadcasts';
import moment from 'moment';
import { IconButton } from '@material-ui/core';
import uniqueId from 'lodash/uniqueId';
import getFormHelperTextProps from 'fieldFactory/util/getFormHelperTextProps';
import formatError from 'fieldFactory/util/formatError';

type Input = any; // tslint:disable-line
type Meta = any; // tslint:disable-line

export interface DateInputProps {
    input: Input;
    meta: Meta;
    isRequired?: boolean;
    label: string | null;
    options?: {};
    source: string;
    elStyle?: {};
    resource?: string;
    disabled?: boolean;
    fullWidth?: boolean;
    ariaInputProps?: {};
    renderLabel?: boolean;
    mode?: 'YYYYMMDD' | 'ISO';
}

interface DateInputComponentProps extends DateInputProps, DateFormatProps {}
interface DateInputComponentState {
    key: number;
}

export class DateInputComponent extends React.Component<DateInputComponentProps, DateInputComponentState> {
    private errorMessageId = uniqueId('date-errormsg');
    pickerInputRef: any;
    initialFocusedDate = moment(new Date())
        .hour(0)
        .toDate();
    state: DateInputComponentState = {
        key: 0,
    };
    datePicker: any; // tslint:disable-line
    onChange = (e, inputValue) => {
        const {
            input: { onChange, onBlur },
        } = this.props;
        if (!e) {
            onChange(null);
            onBlur(null);
            return;
        }
        if (e.isValid && !e.isValid()) {
            return;
        }
        const date = e.toDate();
        if (getTzOffset() < 0) {
            // negative offset means picked UTC date got converted to previous day
            date.setDate(date.getDate() + 1);
        }
        const regexp = /(\d{4})-(\d{2})-(\d{2})/;
        const match = regexp.exec(date.toISOString());
        if (!match) {
            onChange(null);
            onBlur(null);
        } else {
            const year = match[1];
            const month = match[2];
            const day = match[3];
            const d = [year, month, day].join('-');

            onChange(d);
            onBlur(d);
        }
    };
    clearInput = () => {
        const { input } = this.props;
        input.onBlur(null);
    };
    onDismiss = () => this.props.input.onBlur();
    onBlur = e => {
        const text = e.target.value;
        const m = moment(text, this.props.dateFormat, true);
        if (m.isValid()) {
            this.onChange(m, text);
        } else if (!text) {
            this.onChange(null, text);
        } else {
            if (e.relatedTarget && e.relatedTarget.nodeName === 'BUTTON' && this.pickerInputRef === e.target) {
                // skip clear mask because the calendar button was clicked
            } else {
                this.setState(({ key }) => ({ key: key + 1 }));
                // maybe this can be implemented by reassigning the original text value to the ref.
            }
        }
    };
    handleClose = () => {
        this.setState(({ key }) => ({ key: key + 1 }));
    };
    render() {
        const {
            input,
            label,
            meta,
            options,
            ariaInputProps,
            elStyle,
            dateFormat,
            disabled = false,
            // ariaInputProps,
            renderLabel,
            mode = 'YYYYMMDD',
        } = this.props;
        if (typeof meta === 'undefined') {
            throw new Error(
                `The DateInput component wasn't called within a redux-form <Field>.
                Did you decorate it and forget to add the addField prop to your component?
                See https://marmelab.com/admin-on-rest/Inputs.html#writing-your-own-input-component for details.`,
            );
        }
        const { touched, error } = meta;
        const inputProps = {};
        const errorMessageId = touched && error && this.errorMessageId;
        if (errorMessageId) {
            inputProps['aria-errormessage'] = errorMessageId;
        }
        return (
            <div>
                <div style={{ position: 'relative', ...elStyle }}>
                    <KeyboardDatePicker
                        color="primary"
                        size="medium"
                        rows={undefined}
                        rowsMax={undefined}
                        onFocus={undefined}
                        className={undefined}
                        ref={undefined}
                        style={undefined}
                        innerRef={undefined}
                        /*
                            Have to apply the above as undefined due to its current typings
                        */
                        onChange={this.onChange}
                        value={datify(input.value, mode) || null}
                        allowKeyboardControl={true}
                        disabled={Boolean(disabled)}
                        format={dateFormat}
                        initialFocusedDate={this.initialFocusedDate}
                        KeyboardButtonProps={{
                            'aria-label': `Select date from calendar for ${label}`,
                        }}
                        inputRef={ref => {
                            this.pickerInputRef = ref;
                        }}
                        key={this.state.key}
                        onClose={this.handleClose}
                        leftArrowButtonProps={{
                            'aria-label': 'Previous month',
                        }}
                        rightArrowButtonProps={{
                            'aria-label': 'Next month',
                        }}
                        InputLabelProps={{
                            disabled: false, // keep the label dark
                            shrink: true,
                        }}
                        InputProps={{
                            inputProps: {
                                'aria-label': label,
                                ...inputProps,
                                ...ariaInputProps,
                            },
                        }}
                        onBlur={this.onBlur}
                        margin="none"
                        label={renderLabel && label}
                        error={!!(touched && error)}
                        helperText={touched && error ? `Error: ${formatError(error)}` : undefined}
                        FormHelperTextProps={getFormHelperTextProps(inputProps)}
                        {...options}
                    />
                    {!disabled && (input.value && input.value.length > 0) && (
                        <IconButton
                            aria-label={`Clear date for ${label}`}
                            style={{
                                position: 'absolute',
                                right: 30,
                                top: renderLabel ? 8 : -8,
                            }}
                            onClick={this.clearInput}
                        >
                            <Clear />
                        </IconButton>
                    )}
                </div>
            </div>
        );
    }
}

const DateInput: React.SFC<DateInputProps> = withDateFormat(DateInputComponent);

(DateInput.defaultProps as Partial<DateInputProps> & { addField: true }) = {
    elStyle: {},
    isRequired: false,
    addField: true,
    options: {},
    ariaInputProps: {},
    renderLabel: true,
};

export default DateInput;
