import { evaluateContext2 } from 'expressions/CachingEvaluator/FormContextEvaluator';

const defaultFormContext: ReturnType<typeof evaluateContext2> = {
    hiddenFields: {}, // refers to widgets
    disabledFields: {},
    fieldValues: {},
    nullFilteredRefOneFields: [],
    registeredValues: {},
    visibleAndEditableFields: [], // can refer to either (only used in TaskForms where they are the same.) Right now refer to widgets.
    isDirty: false,
    dirtyValues: {},
    initialValues: {},
    valuesetFieldAvailableConceptIds: {},
    availableOptions: {},
    tableRowContexts: {},
};
export default defaultFormContext;
