import React, { useMemo, useContext } from 'react';
// import ReactTooltip from 'react-tooltip';
import { getRefEntityName } from '../../components/generics/utils/viewConfigUtils/index';
import DateInput from './components/DateInput';
import * as fieldTypes from '../fieldTypes';
import FileUpload from './components/FileUpload/index';
import ProcessFileUpload from './components/ProcessFileUpload';
import ChoicesSelect from './components/ChoicesSelect';
import RefManyMultiSelectIdsList, { RefProviderProps } from './components/RefmanyMultiselectIdsList';
import HardReference from './components/HardReference';
import { getCustomViewName } from 'components/generics/utils/viewConfigUtils';
import RadioSelect from './components/ChoicesRadio';
import DateTimePicker from './components/DateTimePicker';
import PopoverRefInput from '../popovers/PopoverRefInput';
import PopoverRefJoinInput from '../popovers/PopoverRefJoinInput';
import Checkbox from './components/Checkbox';
import bindFieldToOtherForm from './components/hoc/bindFieldToOtherForm';
import ListSelect, { getFilterFromFilterString } from './components/ListSelect';
import PrintTemplateNameLookupField from 'printTemplate/component/PrintTemplateField';
import EditableTable from './components/EditableTable/index';
import SelectOneOf from './components/SelectOneOf';
import ViewConfigType from '../../reducers/ViewConfigType';
import { Field, FlowableDataField, FlowableTableField, DataSource } from '../translation/types/index';
// added for valuesetList from flowable expression
import { connect } from 'react-redux';
import ValueSetListField, { ValueSetListFieldComponent } from '../display/components/ValueSetListField';
import labelField from '../../components/generics/utils/labelField';
import WYSIWYG from './components/HtmlWysiwyg';
import TextInput from './components/TextInput';
import LongTextInput from './components/LongTextInput';
import ValueSelectDownshift from './components/ValueSelectDownshift';
import ValuesetManySelectDownshift from './components/ValuesetManySelectDownshift';
import expressionElement from '../display/expressionElement';
import getPopoverAddress from './components/Address/getPopoverAddress';
import MultiCheckbox from './components/MultiCheckbox';
import getValuesetCheckbox from './components/ValuesetOneCheckbox/getValuesetCheckbox';
import BooleanInput from './components/BooleanInput';
import get from 'lodash/get';
import {
    isDisabledEntityField,
    hasConfiguredEntity,
    isEntityDataField,
    isFieldFromFlowable,
    getConfigProperty,
    isRequired,
    getFlowableFieldConfigProperty,
} from './fieldUtils';
import FormHelperTextNoErr from '../input/components/TextField/FormHelperTextNoErr';
import { tryCatch, fromNullable } from 'fp-ts/lib/Either';
import memoizeOne from 'memoize-one';
import { formContext as taskFormContext } from '../../bpm/components/TaskDetail/TaskForm/FormContext';
import { GenericProps } from './props';
import { getDirection } from './fieldUtils/checkboxAndRadio';
import { getViewName, getExpansions, getShowViewName, getEditViewName, gethasNoSearch } from './fieldUtils/reference';
import BigCalendar from './components/BigCalendar';
import Currency from './components/Currency';
import { getLabel } from './fieldUtils';
import getListSelectProps from './components/ListSelect/getListSelectProps';
import GetPossibleMatchView from './components/PossibleMatches/getPossibleMatchView';
import NumberInput from './components/NumberInput';
import getEventFieldGroup from './components/Event/getEvent';
import { Subtract } from 'utility-types';
import { getAriaSupportProperties } from '../ariaSupport';
import { formContext as entityFormContext } from 'components/generics/form/EntityFormContext';
import { injectErrorsToAllFieldsContext } from 'components/generics/form/forceInjectErrorsForAllFieldsContext';
import { Typography } from '@material-ui/core';
import { processContext } from 'bpm/components/processContext';
import { RootState } from 'reducers/rootReducer';
import EntityTypeahead from './components/EntityTypeahead';
import DmsDoc from './components/DmsDoc';
import WithStringEvaluatedInFormContext from 'fieldFactory/popovers/PopoverRefInput/EvaluateStringTemplateInFormContext';
import NullableBoolean from './components/NullableBoolean';
import ValuesetSuggest from './components/ValuesetSuggest/Controller';
import { tableRowContext, getTableRowContext } from './components/EditableTable/MuiEditableTable';
import { evaluateContext2 } from 'expressions/CachingEvaluator/FormContextEvaluator';
import getExpansionsFromFilter from 'isomorphic-query-filters/expand';
import ServerTemplatedPrintTemplateField from 'printTemplate/serverTemplating/component/ServerTemplatedPrintTemplateField';
import { printTemplatePrefix, printTemplatePdfPrefix } from 'fieldFactory/util/expressionConstants';
import TimeInput from './components/TimePicker';
import MultipleEntityTypeahead from './components/EntityTypeahead/Multiple';
import formTypeContext from 'components/generics/form/formTypeContext';
import uniq from 'lodash/uniq';

type Input = any; // tslint:disable-line
type Meta = any; // tslint:disable-line

const withEvaluatedFilter = (BaseComponent: any, filterString: string, from: 'Flowable' | 'Entity') => props => {
    const expansions = useMemo(() => {
        if (filterString) {
            return uniq([...getExpansionsFromFilter(filterString), ...props.expansions]);
        }
        return props.expansions;
    }, [props.expansions]);
    const childFn = (evaldFilter: string) => {
        return <BaseComponent {...props} expansions={expansions} filterString={evaldFilter} />;
    };
    if (from === 'Entity') {
        return (
            <WithStringEvaluatedInFormContext
                context={{ type: 'source', source: props.source }}
                from="Entity"
                templateString={filterString}
            >
                {childFn}
            </WithStringEvaluatedInFormContext>
        );
    }
    return (
        <WithStringEvaluatedInFormContext from="Flowable" templateString={filterString}>
            {childFn}
        </WithStringEvaluatedInFormContext>
    );
};

const withNullFilteredRefoneOverride = (from: 'Flowable' | 'Entity') => BaseComponent => {
    return class WithNullFilteredRefoneOverride extends React.Component<{ input: Input; source: string }> {
        emptyArray = [];
        getInput = memoizeOne(overrideValue => {
            return {
                ...this.props.input,
                value: overrideValue,
            };
        });
        render() {
            const props = this.props;
            const formContext = from === 'Flowable' ? taskFormContext : entityFormContext;
            return (
                <tableRowContext.Consumer>
                    {c => {
                        return (
                            <formContext.Consumer>
                                {fc => {
                                    const isNulled = (() => {
                                        if (c) {
                                            const currentTableRowContext = getTableRowContext(c, fc as ReturnType<
                                                typeof evaluateContext2
                                            >);
                                            return (
                                                currentTableRowContext &&
                                                currentTableRowContext.nullFilteredRefOneFields.includes(props.source)
                                            );
                                        }
                                        return fc.nullFilteredRefOneFields.includes(props.source);
                                    })();
                                    const newInputProp = isNulled ? this.getInput(null) : props.input;
                                    return <BaseComponent {...props} input={newInputProp} key={isNulled ? '1' : '2'} />;
                                }}
                            </formContext.Consumer>
                        );
                    }}
                </tableRowContext.Consumer>
            );
        }
    };
};

const withConceptIdsOverride = (from: 'Flowable' | 'Entity') => BaseComponent => {
    return class WithConceptIdsOverride extends React.Component<{ input: Input; source: string }> {
        emptyArray = [];
        getInput = memoizeOne(overrideValue => {
            return {
                ...this.props.input,
                value: overrideValue,
            };
        });
        getConceptIds = memoizeOne((conceptIdsStr: string) => {
            if (conceptIdsStr === '') {
                return [];
            }
            return conceptIdsStr.split(',');
        });
        getOverriddenEmptyValue = () => {
            return this.isMany() ? this.emptyArray : null;
        };
        isMany = () => this.props.source.endsWith('Ids');
        render() {
            const props = this.props;
            const formContext = from === 'Flowable' ? taskFormContext : entityFormContext;
            return (
                <tableRowContext.Consumer>
                    {c => {
                        return (
                            <formContext.Consumer>
                                {fc => {
                                    const source = props.source.endsWith('Id')
                                        ? props.source.slice(0, -2)
                                        : this.isMany()
                                        ? props.source.slice(0, -3)
                                        : props.source;
                                    const availableConceptsEntry = (() => {
                                        if (c) {
                                            const currentTableRowContext = getTableRowContext(c, fc as ReturnType<
                                                typeof evaluateContext2
                                            >);
                                            return (
                                                currentTableRowContext &&
                                                currentTableRowContext.valuesetFieldAvailableConceptIds[source]
                                            );
                                        }
                                        return fc.valuesetFieldAvailableConceptIds[source];
                                    })();
                                    const conceptIds =
                                        availableConceptsEntry && availableConceptsEntry !== '*'
                                            ? Object.keys(availableConceptsEntry)
                                            : undefined;

                                    const overrideValue = (() => {
                                        if (this.isMany()) {
                                            if (!props.input.value) {
                                                return this.emptyArray;
                                            }
                                            if (
                                                conceptIds &&
                                                availableConceptsEntry !== '*' &&
                                                Array.isArray(props.input.value)
                                            ) {
                                                // we need to filter value
                                                return props.input.value.filter(
                                                    conceptId => availableConceptsEntry[conceptId],
                                                );
                                            }
                                            return props.input.value;
                                        }
                                        return conceptIds &&
                                            availableConceptsEntry !== '*' &&
                                            !availableConceptsEntry[props.input.value]
                                            ? this.getOverriddenEmptyValue()
                                            : props.input.value;
                                    })();

                                    const newInputProp = this.getInput(overrideValue);
                                    return (
                                        <BaseComponent
                                            {...props}
                                            input={newInputProp}
                                            conceptIds={conceptIds && this.getConceptIds(conceptIds.join(','))}
                                        />
                                    );
                                }}
                            </formContext.Consumer>
                        );
                    }}
                </tableRowContext.Consumer>
            );
        }
    };
};

const withInputFromFormContextValues = BaseComponent => props => {
    const fc = useContext(taskFormContext);
    const valueInFormContext = fc.fieldValues[props.source];
    const input = useMemo(() => {
        return {
            ...props.input,
            value: valueInFormContext,
        };
    }, [valueInFormContext, props.input]);
    return <BaseComponent {...props} input={input} />;
};
const withOptionsOverride = BaseComponent => {
    return class WithOptionsOverride extends React.Component<{ input: Input; source: string }> {
        getInput = memoizeOne(overrideValue => {
            return {
                ...this.props.input,
                value: overrideValue,
            };
        });
        render() {
            const props = this.props;
            return (
                <taskFormContext.Consumer>
                    {({ availableOptions, fieldValues }) => {
                        const availableOptionsEntry = availableOptions[props.source];
                        let input = props.input;
                        if (availableOptionsEntry) {
                            input = this.getInput(fieldValues[props.source]);
                        }
                        return <BaseComponent {...props} input={input} />;
                    }}
                </taskFormContext.Consumer>
            );
        }
    };
};
const overrideFieldValueIfDisabled = (type: 'Flowable' | 'Entity') => BaseComponent => {
    class WithFieldValueOverriddenIfDisabled extends React.Component<{
        input: {};
        disabled?: boolean;
        source: string;
    }> {
        getDisabledInput = memoizeOne(realValue => {
            return {
                ...this.props.input,
                value: realValue,
            };
        });

        render() {
            const formContext = type === 'Flowable' ? taskFormContext : entityFormContext;
            return (
                <tableRowContext.Consumer>
                    {c => {
                        return (
                            <formContext.Consumer>
                                {fc => {
                                    const disabledByExpression = (() => {
                                        if (c) {
                                            // we know we are in Task context
                                            const currentTableRowContext = getTableRowContext(c, fc as ReturnType<
                                                typeof evaluateContext2
                                            >);
                                            return (
                                                currentTableRowContext &&
                                                !!currentTableRowContext.disabledFields[this.props.source]
                                            );
                                        }
                                        return !!fc.disabledFields[this.props.source];
                                    })();
                                    const disable = this.props.disabled || disabledByExpression;
                                    const input = (() => {
                                        if (c) {
                                            const currentTableRowContext = getTableRowContext(c, fc as ReturnType<
                                                typeof evaluateContext2
                                            >);
                                            return this.getDisabledInput(
                                                currentTableRowContext &&
                                                    get(currentTableRowContext.fieldValues, this.props.source),
                                            );
                                        }
                                        return disabledByExpression
                                            ? this.getDisabledInput(get(fc.fieldValues, this.props.source))
                                            : this.props.input;
                                    })();
                                    return (
                                        <BaseComponent
                                            {...this.props}
                                            isHardDisabled={type === 'Flowable' && this.props.disabled}
                                            input={input}
                                            disabled={disable}
                                            key={disable ? 'disabled' : 'editable'}
                                        />
                                    );
                                }}
                            </formContext.Consumer>
                        );
                    }}
                </tableRowContext.Consumer>
            );
        }
    }
    return WithFieldValueOverriddenIfDisabled;
};

const mapWarningsToErrors = BaseComponent => {
    return ({ meta = {}, ...props }: { meta: Meta }) => {
        const injected = useContext(injectErrorsToAllFieldsContext);
        const overrideError = meta.error || meta.warning || injected.error || undefined;
        const formType = useContext(formTypeContext);
        const touched = formType === 'EDIT' ? true : meta.touched;
        const newMeta = useMemo(() => {
            return {
                ...meta,
                touched,
                error: overrideError,
            };
        }, [overrideError, touched, meta]);

        return <BaseComponent {...props} meta={newMeta} />;
    };
};

const appendRequiredLabel = (viewConfig: ViewConfigType, fieldDefinition: Field, liveProps) => {
    const label = fieldDefinition.label;
    if (label === 'Radio') {
        console.warn(`my label: ${label}`);
        console.warn(fieldDefinition);
    }

    return label &&
        !liveProps.neverShowRequiredAsterisk &&
        (isRequired(viewConfig, fieldDefinition) || fieldDefinition.name === 'Radio')
        ? label + ' *'
        : label;
};

const makeConsoleError = (msg: string) => () => {
    console.error(msg); // tslint:disable-line
    return undefined;
};

const getLayoutParam = (fieldDefinition: Field, name: 'row' | 'column' | 'span') => {
    const defaultValue = name === 'row' ? 1000 : name === 'span' ? 8 : 0;
    return isFieldFromFlowable(fieldDefinition)
        ? fieldDefinition.params && fieldDefinition.params[name]
            ? +fieldDefinition.params[name]
            : defaultValue
        : fieldDefinition[name];
};

const withoutIdOrIdsPostfix = source =>
    source.endsWith('Ids') ? source.slice(0, -3) : source.endsWith('Id') ? source.slice(0, -2) : source;

export default (
    // inner field component level
    fieldDefinition: Field,
    viewConfig: ViewConfigType,
    configuration,
    liveProps,
) => {
    const t = fieldTypes;
    let RComponent;
    const row = getLayoutParam(fieldDefinition, 'row');
    const column = getLayoutParam(fieldDefinition, 'column');
    const ariaSupportProps = getAriaSupportProperties(row, column, fieldDefinition);
    const ariaInputProps = {};
    let renderLabel = true;
    if (ariaSupportProps && ariaSupportProps.labelledBy) {
        ariaInputProps['aria-labelledby'] = ariaSupportProps.labelledBy;
        renderLabel = false;
    }
    let propConfiguration: GenericProps & any = {
        originalDefinition: fieldDefinition.originalDefinition,
        row,
        column,
        span: getLayoutParam(fieldDefinition, 'span'),
        source: fieldDefinition.name,
        label: appendRequiredLabel(viewConfig, fieldDefinition, liveProps),
        searchType: isEntityDataField(fieldDefinition) ? fieldDefinition.searchType : undefined,
        ariaInputProps,
        renderLabel,
        options: {
            fullWidth: true,
        },
        elStyle: { width: '100%' },
    };
    if (isEntityDataField(fieldDefinition)) {
        getConfigProperty<{ defaultValue: string }>('defaultValue')(fieldDefinition).fold(null, defaultValue => {
            propConfiguration.defaultValue = defaultValue;
        });
    }

    if (fieldDefinition.type === fieldTypes.ADDRESS_VERIFICATION) {
        console.log(fieldDefinition); // tslint:disable-line
        return getPopoverAddress(propConfiguration, fieldDefinition, liveProps, viewConfig);
    }

    if (fieldDefinition.type === fieldTypes.HTML_EXPRESSION) {
        return expressionElement(
            fieldDefinition,
            {
                ...propConfiguration,
                ...liveProps,
                id: `expression:r${row}c${column}`,
            },
            fieldDefinition._dataSource,
        );
    }

    switch (fieldDefinition.type) {
        case t.TEXT:
            RComponent = TextInput;
            if (isEntityDataField(fieldDefinition)) {
                propConfiguration.mask = getConfigProperty('mask')(fieldDefinition).toUndefined();
            } else if (fieldDefinition.params && fieldDefinition.params.mask) {
                propConfiguration.mask = fieldDefinition.params.mask;
            }
            break;
        case t.MULTILINE_TEXT:
            RComponent = LongTextInput;
            break;
        case t.FLOAT:
            RComponent = props => <NumberInput isInteger={false} {...props} />;

            if (isFieldFromFlowable(fieldDefinition)) {
                const isDisabled = fieldDefinition.readOnly || false;
                if (isDisabled) {
                    RComponent = TextInput;
                }
            }
            break;
        case t.INTEGER:
            RComponent = props => <NumberInput isInteger={true} {...props} />;

            if (isFieldFromFlowable(fieldDefinition)) {
                const isDisabled = fieldDefinition.readOnly || false;
                if (isDisabled) {
                    RComponent = TextInput;
                }
            }
            break;
        case t.TOGGLE:
            RComponent = BooleanInput;
            propConfiguration = {
                ...propConfiguration,
                defaultValue: false,
            };
            break;
        case t.TIME:
            RComponent = TimeInput;
            break;
        case t.DATETIME:
            RComponent = DateTimePicker;
            break;
        case t.DATE:
            RComponent = DateInput;
            break;
        case t.NULLABLE_BOOLEAN:
            RComponent = NullableBoolean;
            break;
        case t.ZONE_DATE:
            RComponent = props => <div>ZONE_DATE is unsupported.</div>;
            break;
        case t.FILE_UPLOAD:
            RComponent = FileUpload;
            break;
        case t.PROCESS_FILE_UPLOAD:
            if (isFieldFromFlowable(fieldDefinition) && fieldDefinition.params && fieldDefinition.params.multiple) {
                propConfiguration = {
                    ...propConfiguration,
                    multiple: true,
                };
            }
            if (isFieldFromFlowable(fieldDefinition)) {
                propConfiguration.dontShowUploadInput = !!fieldDefinition.readOnly;
            }
            RComponent = ProcessFileUpload;
            break;
        case t.EMAIL:
            RComponent = props => <TextInput type={'email'} {...props} />;
            break;
        case t.VALUESET_CHECKBOX:
            const fi = getValuesetCheckbox(fieldDefinition, propConfiguration);
            RComponent = withConceptIdsOverride(fieldDefinition._dataSource)(fi.Component);
            propConfiguration = fi.props;
            break;
        case t.VALUESET_SELECT:
            /*
            fieldDefinition <- read
            propConfiguration <- mutate or return new

            return
                propConfiguration
                RComponent
        */
            if (isEntityDataField(fieldDefinition)) {
                getConfigProperty<{ defaultCode: string }>('defaultCode')(fieldDefinition).fold(null, defaultCode => {
                    propConfiguration.defaultCode = defaultCode;
                });
                getConfigProperty<{ group: string }>('group')(fieldDefinition).fold(null, group => {
                    propConfiguration.group = group;
                });
            }
            RComponent = withConceptIdsOverride(fieldDefinition._dataSource)(ValueSelectDownshift); // ValuesetSelect;
            if (
                /* VALUESET_CHECKBOX NOT YET IMPLEMENTED FOR FLOWABLE. Checking here to do the cast properly */
                fieldDefinition.type === t.VALUESET_SELECT &&
                isFieldFromFlowable(fieldDefinition) &&
                fieldDefinition.params &&
                fieldDefinition.params.valueSet
            ) {
                const valueSet = fieldDefinition.params.valueSet;
                const group = fieldDefinition.params.group;
                propConfiguration = {
                    fetchOwnData: true,
                    ...propConfiguration,
                    group,
                    valueSet,
                };
            } else {
                propConfiguration = {
                    fetchOwnData: false,
                    ...propConfiguration,
                    source: !fieldDefinition.name.endsWith('Id') ? `${fieldDefinition.name}Id` : fieldDefinition.name,
                };
                if (isEntityDataField(fieldDefinition) && fieldDefinition.type === t.VALUESET_CHECKBOX) {
                    const confEither = fromNullable({})(fieldDefinition.config).chain(conf =>
                        tryCatch(() => JSON.parse(conf)),
                    );

                    propConfiguration.direction = confEither
                        .map<'HORIZONTAL' | 'VERTICAL' | undefined>(conf => conf.direction)
                        .getOrElse(undefined);
                    propConfiguration.label = confEither
                        .map(conf => (conf.suppressLabel ? '' : fieldDefinition.label))
                        .getOrElse(fieldDefinition.label);
                }
            }
            break;
        case t.VALUESET_MULTISELECT:
            RComponent = withConceptIdsOverride(fieldDefinition._dataSource)(ValuesetManySelectDownshift); // ValuesetMultiselect;
            if (isEntityDataField(fieldDefinition)) {
                getConfigProperty<{ defaultCodes: string[] }>('defaultCodes')(fieldDefinition).fold(
                    null,
                    defaultCodes => {
                        propConfiguration.defaultCodes = defaultCodes;
                    },
                );
                getConfigProperty<{ group: string }>('group')(fieldDefinition).fold(null, group => {
                    propConfiguration.group = group;
                });
            }
            const source = !propConfiguration.source.endsWith('Ids')
                ? `${propConfiguration.source}Ids`
                : propConfiguration.source;

            if (
                isFieldFromFlowable(fieldDefinition) &&
                fieldDefinition.params &&
                fieldDefinition.params.multiSelectValueSet
            ) {
                const valueSet = fieldDefinition.params.multiSelectValueSet;
                const group = fieldDefinition.params.group;
                propConfiguration = {
                    fetchOwnData: true,
                    ...propConfiguration,
                    group,
                    source,
                    valueSet,
                };
            } else {
                propConfiguration = {
                    fetchOwnData: false,
                    ...propConfiguration,
                    source: liveProps.isForSearch ? source.slice(0, -3) + '.id' : source,
                };
            }
            break;
        case t.MULTIPLE_ENTITY_TYPEAHEAD:
        case t.ENTITY_TYPEAHEAD: {
            const filter = isFieldFromFlowable(fieldDefinition)
                ? fieldDefinition.params.filter
                : getConfigProperty('filter')(fieldDefinition).getOrElse(null);
            RComponent =
                fieldDefinition.type === t.MULTIPLE_ENTITY_TYPEAHEAD ? MultipleEntityTypeahead : EntityTypeahead;
            propConfiguration.allowEmptyQuery = true;
            propConfiguration.expansions = filter
                ? [...getExpansionsFromFilter(filter), ...getExpansions(fieldDefinition)]
                : getExpansions(fieldDefinition);
            if (fieldDefinition.type === t.MULTIPLE_ENTITY_TYPEAHEAD && isEntityDataField(fieldDefinition)) {
                propConfiguration.mode = 'SetRelationshipOnAdd';
                propConfiguration.source = `${fieldDefinition.name}Ids`;
            } else {
                propConfiguration.source = fieldDefinition.name.endsWith('.id')
                    ? fieldDefinition.name
                    : `${fieldDefinition.name}${isEntityDataField(fieldDefinition) ? 'Id' : ''}`;
            }
            propConfiguration.reference = isFieldFromFlowable(fieldDefinition)
                ? fieldDefinition.params.entity[0].toUpperCase() + fieldDefinition.params.entity.slice(1)
                : fieldDefinition.name.endsWith('.id')
                ? fieldDefinition.configuredEntity // in case we are a string field pointing to .id
                : getRefEntityName(viewConfig, fieldDefinition.configuredEntity, fieldDefinition.name, 'POP_LAST');

            if (filter) {
                RComponent = withNullFilteredRefoneOverride(
                    isFieldFromFlowable(fieldDefinition) ? 'Flowable' : 'Entity',
                )(
                    withEvaluatedFilter(
                        RComponent,
                        filter,
                        isFieldFromFlowable(fieldDefinition) ? 'Flowable' : 'Entity',
                    ),
                );
            }
            break;
        }
        case t.REFONE_JOIN_SELECT:
        case t.REFONE_SELECT: {
            /*
                Three possibilities here:
                1. hidden reference to a linkedEntity
                2. Hardcoded reference back to a parent entity on Create view
                3. Popover selector
            */
            propConfiguration.viewName = getViewName(fieldDefinition);
            propConfiguration.showViewName = getShowViewName(fieldDefinition);
            propConfiguration.editViewName = getEditViewName(fieldDefinition);
            propConfiguration.noSearch = gethasNoSearch(fieldDefinition);
            if (
                fieldDefinition.name === configuration.parentField ||
                `${fieldDefinition.name}Id` === `${configuration.parentField}`
            ) {
                // backreference set
                if (fieldDefinition.name === 'linkedEntity') {
                    // linkedEntity - hidden hardcoded field
                    /*
                        The below is not used anymore for setting the form value.
                        Leaving it as a dummy field for now.
                    */
                    RComponent = NumberInput;
                    propConfiguration = {
                        ...propConfiguration,
                        style: { display: 'none' },
                        source: `${fieldDefinition.name}Id`,
                        defaultValue: configuration.parentEntityIdValue,
                    };
                } else {
                    // hard backreference e.g. on Create for a reference entity.
                    RComponent = HardReference;
                    propConfiguration = {
                        ...propConfiguration,
                        parentEntityIdValue: configuration.parentEntityIdValue,
                        source: `${fieldDefinition.name}Id`,
                    };
                }
            } else {
                // popover reference picker
                RComponent = fieldDefinition.type === t.REFONE_JOIN_SELECT ? PopoverRefJoinInput : PopoverRefInput;

                let refEntityName: string | null = null;
                const filterString = isFieldFromFlowable(fieldDefinition)
                    ? fieldDefinition.params.filter
                    : getConfigProperty('filter')(fieldDefinition).getOrElse(null);

                if (filterString) {
                    propConfiguration.filterString = filterString;
                    RComponent = withEvaluatedFilter(
                        RComponent,
                        propConfiguration.filterString,
                        isFieldFromFlowable(fieldDefinition) ? 'Flowable' : 'Entity',
                    );
                }
                if (isEntityDataField(fieldDefinition)) {
                    propConfiguration = {
                        fetchOwnData: false,
                        ...propConfiguration,
                    };
                    if (fieldDefinition.configuredEntity) {
                        refEntityName = getRefEntityName(
                            viewConfig,
                            fieldDefinition.configuredEntity,
                            fieldDefinition.name,
                            'POP_LAST',
                        );
                    }
                }
                if (isFieldFromFlowable(fieldDefinition)) {
                    propConfiguration = {
                        fetchOwnData: true,
                        ...propConfiguration,
                    };
                    // evaluate the filterString in the form context
                    if (fieldDefinition.params && fieldDefinition.params.entity) {
                        refEntityName =
                            fieldDefinition.params.entity[0].toUpperCase() + fieldDefinition.params.entity.slice(1);
                    }
                }

                if (refEntityName) {
                    propConfiguration = {
                        ...propConfiguration,
                        source: `${fieldDefinition.name}${isEntityDataField(fieldDefinition) ? 'Id' : ''}`, // append Id only for entities
                        basePath: `/${refEntityName}`,
                        reference: refEntityName,
                        expansions: propConfiguration.filterString
                            ? (() => {
                                  const filterExpansions = getExpansionsFromFilter(propConfiguration.filterString);
                                  const customExpansions = getExpansions(fieldDefinition);
                                  return [...filterExpansions, ...customExpansions];
                              })()
                            : getExpansions(fieldDefinition),
                    };
                }
                RComponent = withNullFilteredRefoneOverride(fieldDefinition._dataSource)(RComponent);
            }
            break;
        }
        case t.ONEOF: {
            RComponent = SelectOneOf;
            propConfiguration = {
                ...propConfiguration,
                addLabel: true,
                source: `${propConfiguration.source}Id`,
            };
            break;
        }
        case t.VALUESET_SUGGEST: {
            if (isEntityDataField(fieldDefinition)) {
                getConfigProperty<{ valueSet: string }>('valueSet')(fieldDefinition).fold(null, valueSet => {
                    propConfiguration.valueSet = valueSet;
                });
                getConfigProperty<{ group: string }>('group')(fieldDefinition).fold(null, group => {
                    propConfiguration.group = group;
                });
            }
            RComponent = withConceptIdsOverride(fieldDefinition._dataSource)(ValueSelectDownshift); // ValuesetSelect;
            if (isFieldFromFlowable(fieldDefinition) && fieldDefinition.params && fieldDefinition.params.valueSet) {
                const valueSet = fieldDefinition.params.valueSet;
                const group = fieldDefinition.params.group;
                propConfiguration = {
                    fetchOwnData: true,
                    ...propConfiguration,
                    shouldFetchValueset: true,
                    group,
                    valueSet,
                };
            } else {
                propConfiguration = {
                    ...propConfiguration,
                    shouldFetchValueset: true,
                    fetchOwnData: true,
                };
            }
            RComponent = ValuesetSuggest;
            break;
        }
        case t.REFMANYMANY_CHIP:
            RComponent = RefManyMultiSelectIdsList;
            propConfiguration.viewName = getViewName(fieldDefinition);

            const adjustedSource = propConfiguration.source.endsWith('Ids')
                ? propConfiguration.source
                : `${propConfiguration.source}Ids`;
            const mmchipprops: Subtract<
                RefProviderProps & { input: Input; meta: Meta },
                { input: Input; meta: Meta }
            > = isFieldFromFlowable(fieldDefinition)
                ? {
                      ...propConfiguration,
                      filter: getFilterFromFilterString(fieldDefinition.params.filter),
                      type: 'Input(NoBackingEntity)',
                      reference:
                          (fieldDefinition.params as any).entity[0].toUpperCase() + // tslint:disable-line
                          (fieldDefinition.params as any).entity.slice(1), // tslint:disable-line
                      renderer: 'CHIP',
                      source: adjustedSource,
                      commitChanges: false,
                      overrideRenderNoResultsText: (
                          <div style={{ padding: '.25em', paddingLeft: '1em' }}>
                              <Typography gutterBottom={true} variant="subtitle1" component="div">
                                  None Selected
                              </Typography>
                          </div>
                      ),
                  }
                : liveProps.isForSearch
                ? {
                      ...propConfiguration,
                      type: 'Input(NoBackingEntity)',
                      reference: fieldDefinition.refEntityName,
                      renderer: 'CHIP',
                      source: adjustedSource.slice(0, -3) + '.id',
                      commitChanges: false,
                      overrideRenderNoResultsText: (
                          <div style={{ padding: '.25em', paddingLeft: '1em' }}>
                              <Typography gutterBottom={true} variant="subtitle1" component="div">
                                  None Selected
                              </Typography>
                          </div>
                      ),
                  }
                : {
                      ...propConfiguration,
                      type: 'Input(FromEntity)',
                      renderer: 'CHIP',
                      source: adjustedSource,
                      commitChanges: true,
                  };
            propConfiguration = {
                ...mmchipprops,
                addLabel: false,
                allowEmpty: true,
            };
            break;
        case t.REFMANY_MULTISELECT_IDSLIST: {
            RComponent = RefManyMultiSelectIdsList;
            const mmprops: Subtract<
                RefProviderProps & { input: Input; meta: Meta },
                { input: Input; meta: Meta }
            > = isFieldFromFlowable(fieldDefinition)
                ? {
                      ...propConfiguration,
                      type: 'Input(NoBackingEntity)',
                      reference:
                          (fieldDefinition.params as any).entity[0].toUpperCase() + // tslint:disable-line
                          (fieldDefinition.params as any).entity.slice(1), // tslint:disable-line
                      renderer: 'LIST',
                      source: `${propConfiguration.source}Ids`,
                      commitChanges: false,
                  }
                : liveProps.isForSearch
                ? {
                      ...propConfiguration,
                      type: 'Input(NoBackingEntity)',
                      reference: fieldDefinition.refEntityName,
                      renderer: 'LIST',
                      source: `${propConfiguration.source}.id`,
                      commitChanges: false,
                  }
                : (() => {
                      if (liveProps.isForCreate) {
                          return {
                              ...propConfiguration,
                              type: 'Input(NoBackingEntity)',
                              renderer: 'LIST',
                              reference:
                                  viewConfig.entities[fieldDefinition.configuredEntity].fields[propConfiguration.source]
                                      .relatedEntity,
                              source: `${propConfiguration.source}Ids`,
                              commitChanges: false,
                          };
                      } else {
                          return {
                              ...propConfiguration,
                              type: 'Input(FromEntity)',
                              renderer: 'LIST',
                              source: `${propConfiguration.source}Ids`,
                              commitChanges: true,
                          };
                      }
                  })();
            const getViewNameOf = (type: 'LIST' | 'EDIT' | 'SHOW') =>
                getViewName(fieldDefinition) ||
                getCustomViewName(type, false)(
                    isFieldFromFlowable(fieldDefinition)
                        ? propConfiguration.reference
                        : getRefEntityName(
                              viewConfig,
                              fieldDefinition.configuredEntity,
                              fieldDefinition.name,
                              'TRAVERSE_PATH',
                          ),
                    viewConfig,
                    (fieldDefinition as any).config,
                );
            propConfiguration = {
                ...mmprops,
                viewName: getViewNameOf('LIST'),
                editViewName: getViewNameOf('EDIT'),
                showViewName: getViewNameOf('SHOW'),
                allowEmpty: true,
                addLabel: false,
            };
            break;
        }
        case t.CHOICES_SELECT: {
            RComponent = withOptionsOverride(ChoicesSelect);
            propConfiguration = {
                ...propConfiguration,
                choices: isFieldFromFlowable(fieldDefinition)
                    ? fieldDefinition.options
                    : makeConsoleError('Choices Select choices not defined for Non-flowable data sources')(),
            };
            break;
        }
        case t.RADIO: {
            const label = getLabel(fieldDefinition);
            RComponent = withOptionsOverride(RadioSelect);
            propConfiguration = {
                ...propConfiguration,
                direction: getDirection(fieldDefinition),
                label,
                choices: isFieldFromFlowable(fieldDefinition)
                    ? fieldDefinition.options
                    : makeConsoleError('Radio choices not defined for Non-flowable data sources')(),
                addLabel: true,
            };
            break;
        }
        case t.CHECKBOX: {
            RComponent = Checkbox;
            propConfiguration = {
                ...propConfiguration,
                defaultValue: false,
            };
            break;
        }
        case t.DMS_DOCUMENT: {
            RComponent = DmsDoc;
            if (isFieldFromFlowable(fieldDefinition) && fieldDefinition.params && fieldDefinition.params.entity) {
                propConfiguration.reference =
                    fieldDefinition.params.entity[0].toUpperCase() + fieldDefinition.params.entity.slice(1);
            }
            propConfiguration.addLabel = true;
            propConfiguration.type = isFieldFromFlowable(fieldDefinition) ? 'Input(NoBackingEntity)' : 'Input(Entity)';
            break;
        }
        case t.LIST: {
            RComponent = fieldDefinition.params.filter
                ? withNullFilteredRefoneOverride('Flowable')(
                      withEvaluatedFilter(
                          ({ filterString, ...props }: any) => <ListSelect {...props} filter={filterString} />,
                          fieldDefinition.params.filter,
                          'Flowable',
                      ),
                  )
                : ListSelect;
            RComponent = withInputFromFormContextValues(RComponent);

            propConfiguration = {
                ...propConfiguration,
                ...fieldDefinition.params,
                ...getListSelectProps(propConfiguration, fieldDefinition),
            };

            break;
        }
        case t.TABLE: {
            if (isFieldFromFlowable(fieldDefinition)) {
                RComponent = EditableTable;
                propConfiguration = {
                    ...propConfiguration,
                    disabled: fieldDefinition.readOnly,
                    allowRowAddDelete: getFlowableFieldConfigProperty<{ allowRowAddDelete?: boolean }>(
                        'allowRowAddDelete',
                    )(fieldDefinition as FlowableTableField).getOrElse(true),
                    colSpec: fieldDefinition.params.columnObj,
                };
            } else {
                makeConsoleError('Table is only defined for Flowable fields at the moment.');
                RComponent = props => <div {...props} />;
            }
            break;
        } /* eslint-disable no-fallthrough */
        case t.EXPRESSION: {
            const getView = (expression: string): null | [string, string] => {
                if (!expression || typeof expression !== 'string') {
                    return null;
                }
                const pre = 'view_$';
                if (expression.startsWith(pre)) {
                    const rest = expression.slice(pre.length);
                    const [view, id] = rest.split('__');
                    return [view, id];
                }
                return null;
            };
            const maybeViewExpression = getView(fieldDefinition.value);
            if (maybeViewExpression) {
                const [viewName, id] = maybeViewExpression;
                // only handling possibleMatchView right now.
                RComponent = props => {
                    const [currId, setCurrId] = React.useState(id);
                    return (
                        <processContext.Consumer>
                            {c => (
                                <GetPossibleMatchView
                                    onDataChange={c.refresh}
                                    onIdChanged={setCurrId}
                                    editInPopover={true}
                                    viewName={viewName}
                                    id={currId}
                                />
                            )}
                        </processContext.Consumer>
                    );
                };
                break;
            }
            // PRINT TEMPLATE CASE
            const valueSetManyDisplayPrefix = 'VS_MANY__';
            if (typeof fieldDefinition.value === 'string' && fieldDefinition.value.startsWith(printTemplatePrefix)) {
                // this should be a printTemplate download.
                const printTemplateName = fieldDefinition.value.slice(printTemplatePrefix.length);
                RComponent = PrintTemplateNameLookupField;
                propConfiguration = {
                    ...propConfiguration,
                    dontConnect: true,
                    printTemplateName,
                };
                break;
            } else if (
                typeof fieldDefinition.value === 'string' &&
                fieldDefinition.value.startsWith(printTemplatePdfPrefix)
            ) {
                const printTemplateName = fieldDefinition.value.slice(printTemplatePdfPrefix.length);
                RComponent = ServerTemplatedPrintTemplateField;
                propConfiguration = {
                    ...propConfiguration,
                    dontConnect: true,
                    printTemplateName,
                };
                break;
            }
            // valuesetManyDisplay
            // format: VS_MANY__[entityId]:[entityType]__[field]
            else if (
                typeof fieldDefinition.value === 'string' &&
                fieldDefinition.value.startsWith(valueSetManyDisplayPrefix)
            ) {
                const [entityId, rest] = fieldDefinition.value.slice(valueSetManyDisplayPrefix.length).split(':');
                const [entityType, field] = rest.split('__');
                console.log(entityType, entityId, field); // tslint:disable-line
                RComponent = connect((state: RootState) => ({
                    record: (state.admin.entities[entityType] || {})[entityId],
                }))((props =>
                    labelField(
                        <ValueSetListField addLabel={true} label={propConfiguration.label} {...props} />,
                        props.record,
                        entityType,
                        `/${entityType}`,
                    )) as React.SFC<{ record: {} }>);
                propConfiguration = {
                    ...propConfiguration,
                    source: field,
                };
                break;
            } else if (fieldDefinition.params && fieldDefinition.params.expressionCustom === 'VSM') {
                RComponent = props =>
                    labelField(
                        <ValueSetListFieldComponent
                            addLabel={true}
                            label={propConfiguration.label}
                            {...propConfiguration}
                            {...props}
                        />,
                        { fakeSource: fieldDefinition.value },
                        '',
                        '',
                    );
                propConfiguration = {
                    ...propConfiguration,
                    concepts: Object.assign({}, ...fieldDefinition.value.map(e => ({ [e.id]: e }))),
                    source: 'fakeSource',
                };
                break;
            } else {
                let text;
                const isErrorRelated =
                    Array.isArray(fieldDefinition.value) &&
                    fieldDefinition.value.every(
                        obj =>
                            Object.prototype.hasOwnProperty.call(obj, 'errorMessage') &&
                            Object.prototype.hasOwnProperty.call(obj, 'row'),
                    );
                if (isErrorRelated) {
                    text = `${fieldDefinition.value.length} errors:`;
                } else {
                    text = fieldDefinition.value;
                }
                let C;
                switch (fieldDefinition.params && fieldDefinition.params.size) {
                    case '5':
                        C = `<h2>${fieldDefinition.value}</h2>`;
                        break;
                    case '4':
                        C = `<h3>${fieldDefinition.value}</h3>`;
                        break;
                    case '3':
                        C = `<h4>${fieldDefinition.value}</h4>`;
                        break;
                    case '2':
                        C = `<h5>${fieldDefinition.value}</h5>`;
                        break;
                    default:
                        C = fieldDefinition.value;
                }
                propConfiguration = {
                    ...propConfiguration,
                    value: text,
                    isExpression: true,
                };
                const newDefinition: any = {}; //tslint:disable-line
                newDefinition.htmlConfig = C;

                return expressionElement(
                    newDefinition as any,
                    {
                        ...propConfiguration,
                        ...liveProps,
                        id: `${
                            fieldDefinition._dataSource === DataSource.FLOWABLE ? 'flowable' : ''
                        }expression:r${row}c${column}`,
                    },
                    fieldDefinition._dataSource,
                ); //tslint:disable-line
            }
        }
        case t.WYSIWYG: {
            RComponent = WYSIWYG;
            break;
        }
        case t.BIGCALENDAR: {
            RComponent = BigCalendar; // props => <div>Big calendar...</div>;
            break;
        }
        case t.VALUESET_MULTICHECKBOX: {
            RComponent = MultiCheckbox;
            const _source = !propConfiguration.source.endsWith('Ids')
                ? `${propConfiguration.source}Ids`
                : propConfiguration.source;

            if (
                isFieldFromFlowable(fieldDefinition) &&
                fieldDefinition.params &&
                fieldDefinition.params.multiSelectValueSet
            ) {
                const valueSet = fieldDefinition.params.multiSelectValueSet;
                const group = fieldDefinition.params.group;
                propConfiguration = {
                    ...propConfiguration,
                    group,
                    source: _source,
                    valueSet,
                    addLabel: false,
                    fetchOwnData: true,
                    direction: getDirection(fieldDefinition),
                    label: getLabel(fieldDefinition),
                };
            } else {
                propConfiguration = {
                    ...propConfiguration,
                    source: _source,
                    disabled: false,
                    addLabel: false,
                    fetchOwnData: false,
                    direction: getDirection(fieldDefinition),
                    label: getLabel(fieldDefinition),
                };
            }
            break;
        }
        case t.CURRENCY: {
            RComponent = Currency;
            break;
        }
        case t.EVENT: {
            return getEventFieldGroup(propConfiguration, fieldDefinition, liveProps);
        }
        default:
            throw Error(`uncaught field: ${JSON.stringify(fieldDefinition)}`);
    }

    const disabled =
        (isFieldFromFlowable(fieldDefinition) && fieldDefinition.readOnly) ||
        (hasConfiguredEntity(fieldDefinition) &&
            (isDisabledEntityField(fieldDefinition, liveProps, viewConfig) ||
                (configuration.optInWriteable &&
                    Object.keys(configuration.optInWriteable).indexOf(
                        withoutIdOrIdsPostfix(propConfiguration.source),
                    ) === -1)));

    if (configuration.optInWriteable && configuration.optInWriteable[withoutIdOrIdsPostfix(propConfiguration.source)]) {
        const nameInOtherForm = configuration.optInWriteable[withoutIdOrIdsPostfix(propConfiguration.source)].id;
        RComponent = bindFieldToOtherForm('current-task-form', nameInOtherForm)(RComponent);
    }

    if (liveProps.overrideFieldValueIfDisabled) {
        RComponent = overrideFieldValueIfDisabled(fieldDefinition._dataSource)(RComponent);
    }
    RComponent = mapWarningsToErrors(RComponent);
    if (!propConfiguration.ariaInputProps['aria-labelledby'] && typeof propConfiguration.label === 'string') {
        propConfiguration.ariaInputProps['aria-label'] = propConfiguration.label;
    }
    // key for the unique widget - "source" won't be unique if there are multiple fields pointing to the same piece of data
    const fieldInstanceIdentifier =
        fieldDefinition._dataSource === 'Entity' && fieldDefinition.fieldInstanceIdentifier
            ? fieldDefinition.fieldInstanceIdentifier
            : propConfiguration.source;
    return (
        <RComponent
            {...propConfiguration}
            fieldInstanceIdentifier={fieldInstanceIdentifier}
            key={`${propConfiguration.source}-${propConfiguration.label}`}
            validate={fieldDefinition.validate}
            warn={(fieldDefinition as FlowableDataField).warn}
            addField={true}
            renderDesc={() =>
                fieldDefinition.description && <FormHelperTextNoErr>{fieldDefinition.description}</FormHelperTextNoErr>
            }
            {...liveProps}
            disabled={disabled || liveProps.disabled}
        />
    );
};
