import {
    FormFieldUnion,
    EntityTypeaheadField,
    EntityLookupField,
    EntityMultipleTypeaheadField,
    ListField,
} from 'fieldFactory/translation/fromFlowable/types/index';
import { EvaluationFactors } from 'expressions/expressionArrays/formValuesInDynamicContext';
import {
    getValueset1Fields,
    wrapEachValueInArray,
    fieldsToForceInsertion,
    getExpressionsFromFields,
    getConceptsAvailableExpressionsFromFields,
    getOptionAvailabilityExpressionsFromFields,
} from 'bpm/form-context-utils';
import { ValueSets } from 'reducers/valueSetsReducer';
import { FormContextEvaluator } from 'expressions/CachingEvaluator/FormContextEvaluator';
import ViewConfig from 'reducers/ViewConfigType';

export const getTableExpressions = (fields: FormFieldUnion[]): EvaluationFactors['tableExpressions'] => {
    return fields.reduce((prev, f): EvaluationFactors['tableExpressions'] => {
        if (f.type === 'table' && f.params && f.params.columnObj) {
            prev[f.id] = {
                fieldWidgets: f.params.columnObj
                    .map(c => c.id)
                    .reduce((prev, curr) => {
                        prev[curr] = [curr];
                        return prev;
                    }, {}),
                tableExpressions: getTableExpressions(f.params.columnObj),
                visibilityExpressions: wrapEachValueInArray(
                    getExpressionsFromFields('visibility')(f.params.columnObj) || {},
                ),
                editabilityExpressions: wrapEachValueInArray(
                    getExpressionsFromFields('editable')(f.params.columnObj) || {},
                ),
                valueset1AvailableConceptsExpressions:
                    getConceptsAvailableExpressionsFromFields(f.params.columnObj) || {},
                dropdownAvailableOptionsExpressions:
                    getOptionAvailabilityExpressionsFromFields(f.params.columnObj) || {},
                valueset1Fields: getValueset1Fields(f.params.columnObj),
                reference1EntityFilterExpressions: getRef1FilterExpressions(f.params.columnObj || []),
            };
        }
        return prev;
    }, {});
};

export const getRef1FilterExpressions = (
    fields: FormFieldUnion[],
): EvaluationFactors['reference1EntityFilterExpressions'] => {
    const isDynamicFilterableRefOneField = (
        field: FormFieldUnion,
    ): field is EntityTypeaheadField | EntityLookupField => {
        return field.type === 'entity-lookup' || field.type === 'entity-typeahead';
    };
    return fields.reduce((prev: EvaluationFactors['reference1EntityFilterExpressions'], f) => {
        if (isDynamicFilterableRefOneField(f) && f.params && f.params.filter) {
            prev[f.id] = {
                entityType: f.params.entity,
                expression: f.params.filter,
            };
        }
        return prev;
    }, {});
};
export const getRefManyFilterExpressions = (
    fields: FormFieldUnion[],
    viewConfig: ViewConfig,
): EvaluationFactors['referenceManyEntityFilterExpressions'] => {
    const isDynamicFilterableRefManyField = (
        field: FormFieldUnion,
    ): field is EntityMultipleTypeaheadField | ListField => {
        return (
            field.type === 'entity-multi-select-chip' ||
            field.type === 'multiple-entity-typeahead' ||
            field.type === 'list-view'
        );
    };
    return fields.reduce((prev: EvaluationFactors['referenceManyEntityFilterExpressions'], f) => {
        if (isDynamicFilterableRefManyField(f) && f.params && f.params.filter) {
            prev[f.id] = {
                entityType: f.type === 'list-view' ? viewConfig.views[f.params.viewName].entity : f.params.entity,
                expression: f.params.filter,
            };
        }
        return prev;
    }, {});
};
const combiner = (
    evaluator: FormContextEvaluator,
    fields: FormFieldUnion[],
    values: {},
    entities: {},
    valueSets: ValueSets,
    initialValues: {},
): ReturnType<FormContextEvaluator['evaluate']> => {
    const result = evaluator.evaluate(values || initialValues || {}, valueSets, initialValues || {}, entities);
    // could just return the above 'result', however formValues need to include fields that are 'faked'
    // for example address widget fields.
    // TODO: can also do this for FileUpload fields (mimeType + filename)
    return {
        ...result,
        fieldValues: Object.assign(
            {},
            result.fieldValues,
            // override with contents of address widget
            ...(values ? fieldsToForceInsertion(fields).map(f => ({ [f]: values[f] })) : []),
        ),
    };
};
export default combiner;
