type anyAction = { type: string; payload: any }; // tslint:disable-line
const createSkipCurrentlyTrying = (acceptType: string, allowOneOfActionBasedOn: (action: anyAction) => string) => {
    let currentlyTrying: {
        [key: string]: true;
    } = {};

    return {
        takeAction(action: anyAction) {
            if (action.type !== acceptType) {
                return false;
            }
            const key = allowOneOfActionBasedOn(action);
            if (currentlyTrying[key]) {
                return false;
            }
            currentlyTrying[key] = true;
            return true;
        },
        actionFinished(key: string) {
            delete currentlyTrying[key];
        },
    };
};
export default createSkipCurrentlyTrying;
