import React from 'react';
import withHandlers from 'recompose/withHandlers';
import { saveAs } from 'file-saver';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { TOGGLE_DEBUG_MODE } from '../actions/constants';
import BugReport from '@material-ui/icons/BugReport';
import FileDownload from '@material-ui/icons/SaveAlt';
import Wifi from '@material-ui/icons/Wifi';
import WifiLock from '@material-ui/icons/WifiLock';
import Ballot from '@material-ui/icons/Ballot';
import BallotOutlined from '@material-ui/icons/BallotOutlined';
import compose from 'recompose/compose';
import mapProps from 'recompose/mapProps';
import pure from 'recompose/pure';
import { toSerializableState, rehydrateState } from '../reducers/serializeDeserialize';
import { RootState } from '../reducers/rootReducer';
import { REPLACE_STATE } from '../actions/constants';
import branch from 'recompose/branch';
import TogglePrintMode from './TogglePrintMode';
import { startNewRootEpic } from 'configureStore';
import withState from 'recompose/withState';
import { setExpressionTesterOpen } from 'expression-tester/actions';
import { undisableTaskForms } from 'bpm/undisable-task-form/actions';
import { Button } from '@material-ui/core';
import { push as pushAction } from 'connected-react-router';

const styles = theme => ({
    warning: {
        color: theme.palette.error.main,
    },
    hideExceptOnHover: {
        width: '100%',
        height: '100%',
        display: 'flex',
        cursor: 'pointer',
        '&:not(:hover)': {
            color: 'transparent',
        },
        '&:hover': {},
    },
    transparent: {
        color: 'transparent',
    },
    whenPopover: {
        color: 'white',
    },
});
interface DebugAndReportingHelperProps {
    classes: {
        hideExceptOnHover: string;
        warning: string;
        whenPopover: string;
        transparent: string;
    };
    secure: boolean;
    replaceState: (newState: RootState) => void;
    toggleDebugMode: () => void;
    toggleHighlightMui0: () => void;
    endEpic: () => void;
    report: () => void;
    debugMode: boolean;
    somePopover: boolean;
    push: (loc: string) => void;
    isOpen: boolean;
    undisableTaskForms: typeof undisableTaskForms;
    undisabledTaskForms: boolean;
    setExpressionTesterOpen: typeof setExpressionTesterOpen;
    expressionTesterOpen: boolean;
    setOpen: (opn: boolean) => void;
}
interface DebugAndReportingHelperState {}
class DebugAndReportingHelper extends React.Component<DebugAndReportingHelperProps, DebugAndReportingHelperState> {
    toggleOpen = () => {
        this.props.setOpen(!this.props.isOpen);
    };
    importState = (file: File) => {
        const success = content => {
            const strState = JSON.parse(content);
            this.props.replaceState(rehydrateState(strState));
        };
        const fileReader = new FileReader();
        fileReader.onload = (evt: ProgressEvent & { target: { result?: string } | null }) => {
            if (evt && evt.target && evt.target.result) {
                success(evt.target.result);
            }
        };
        fileReader.readAsText(file);
    };
    render() {
        return (
            <div
                className={this.props.somePopover ? this.props.classes.whenPopover : undefined}
                style={{ display: 'flex', flexDirection: 'row-reverse' }}
            >
                {this.props.debugMode && !this.props.isOpen && (
                    <span className={this.props.classes.warning} style={{ whiteSpace: 'nowrap' }}>
                        Side-effects Disabled <WifiLock />
                    </span>
                )}
                <div
                    style={{ color: this.props.isOpen ? 'unset' : undefined }}
                    className={
                        this.props.secure ? this.props.classes.transparent : this.props.classes.hideExceptOnHover
                    }
                >
                    {this.props.secure ? (
                        <BugReport onDoubleClick={this.toggleOpen} />
                    ) : (
                        <BugReport onClick={this.toggleOpen} />
                    )}
                    {this.props.isOpen && <FileDownload onClick={this.props.report} />}
                    {this.props.isOpen && (
                        <input
                            type="file"
                            style={{
                                marginBottom: '1em',
                            }}
                            accept="application/json"
                            onChange={e => e.target.files && this.importState(e.target.files[0])}
                        />
                    )}
                    {this.props.isOpen && this.props.debugMode && (
                        <WifiLock
                            onClick={() => {
                                this.props.toggleDebugMode();
                                startNewRootEpic();
                            }}
                        />
                    )}
                    {this.props.isOpen && !this.props.debugMode && (
                        <Wifi
                            onClick={() => {
                                this.props.toggleDebugMode();
                                this.props.endEpic();
                            }}
                        />
                    )}
                    {this.props.isOpen && this.props.expressionTesterOpen && (
                        <Ballot
                            onClick={() => {
                                this.props.setExpressionTesterOpen(false);
                            }}
                        />
                    )}
                    {this.props.isOpen && !this.props.expressionTesterOpen && (
                        <BallotOutlined
                            onClick={() => {
                                this.props.setExpressionTesterOpen(true);
                            }}
                        />
                    )}
                    {this.props.isOpen && (
                        <Button
                            onClick={() => {
                                this.props.undisableTaskForms(!this.props.undisabledTaskForms);
                            }}
                        >
                            {this.props.undisabledTaskForms ? 'Forms undisabled' : 'Undisable forms'}
                        </Button>
                    )}
                    {this.props.isOpen && <TogglePrintMode />}
                    {this.props.isOpen && (
                        <Button
                            onClick={e => {
                                e.stopPropagation();
                                e.preventDefault();
                                let loc = prompt('Location: ', '');
                                if (loc) {
                                    this.props.push(loc);
                                }
                            }}
                        >
                            PushNav
                        </Button>
                    )}
                </div>
            </div>
        );
    }
}

export default compose(
    withStyles(styles),
    connect(
        (state: RootState) => ({
            state,
            debugMode: state.debugMode,
            secure: state.basicInfo ? !state.basicInfo.debugFeaturesEnabled : true,
            somePopover: state.viewStack && state.viewStack.length > 0,
            expressionTesterOpen: state.expressionTesterOpen,
            undisabledTaskForms: state.undisableTaskForms,
        }),
        {
            replaceState: (state: RootState) => ({ type: REPLACE_STATE, payload: state }),
            toggleDebugMode: () => ({ type: TOGGLE_DEBUG_MODE }),
            endEpic: () => ({ type: 'EPIC_END' }),
            setExpressionTesterOpen,
            undisableTaskForms: undisableTaskForms,
            push: pushAction,
        },
    ),
    withState('isOpen', 'setOpen', false),
    branch(
        props => props.isOpen,
        withHandlers({
            report: ({ state }) => () => {
                const stringState = JSON.stringify(toSerializableState(state));
                saveAs(new Blob([stringState], { type: 'application/json' }), 'debug_state.json');
            },
        }),
    ),
    mapProps(({ state, ...props }) => props),
    pure,
)(DebugAndReportingHelper);
