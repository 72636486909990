import { useEffect } from 'react';
// import { CLEAR_STORE_BUT_KEEP_LOCATION } from 'actions/constants';
import { useSelector /*, useDispatch */ } from 'react-redux';
import { RootState } from '../../../src/reducers/rootReducer';
import { fromNullable } from 'fp-ts/lib/Option';
import storage from 'local-storage-fallback';

export interface UserLogoutRedirectRouteProps {
    location: Location;
}

const UserLogoutRedirectRoute = (props: UserLogoutRedirectRouteProps) => {
    const logoutRedirectUrl = useSelector((state: RootState) => {
        return fromNullable(state.basicInfo)
            .mapNullable(basicInfo => basicInfo.logoutRedirectUrl)
            .getOrElse('');
    });
    // we re-request the entire app, so no need to dispatch anything to clear store.
    // const dispatch = useDispatch();
    useEffect(() => {
        // Lets push this onto the event queue because we want cleanup to happen for FormSaveNotifier (otherwise it prevents navigation away).
        const to = setTimeout(() => {
            storage.clear();
            if (logoutRedirectUrl) {
                window.location.href =
                    // so, '/login', '/clppp/login', etc.
                    logoutRedirectUrl && logoutRedirectUrl.startsWith('/') && logoutRedirectUrl.endsWith('login')
                        ? // props.location.search here is something like
                          // ?redirectTo=%2Fprocesses%3Ffilter%3D%257B%2522processInstance.businessKey%2522%253A%2522ei_enrollment%2522%257D
                          // we will pass this to the login page
                          logoutRedirectUrl + `${props.location.search || ''}`
                        : logoutRedirectUrl;
            } else {
                // Window close works only in IE, not Chrome or Firefox.
                window.open('', '_self', '');
                window.close();
            }
        }, 0);
        // dispatch({ type: CLEAR_STORE_BUT_KEEP_LOCATION });
        return () => {
            clearTimeout(to);
        };
    }, []); // eslint-disable-line
    return null;
};

export default UserLogoutRedirectRoute;
