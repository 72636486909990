import ViewConfig from 'reducers/ViewConfigType';
import { fromEither, Option, fromNullable } from 'fp-ts/lib/Option';
import { tryCatch } from 'fp-ts/lib/Either';
import { mapOption } from 'fp-ts/lib/Array';
import { SpelCompiledExpression /* SpelNode */ } from 'expressions/evaluate';
import { parsingOrValidationErrMsg } from 'expressions/formValidation';
import getFieldsInAst from 'casetivity-shared-js/lib/spel/getFieldsInAst';
import { SpelExpressionEvaluator } from 'spel2js';
import { parseConfig } from 'expressions/entityViewConfig/parse';
// import setupValuesetFieldsRequired from 'viewConfigCalculations/util/setupValuesetFieldsRequired';
import getValueSetCodeLiterals from 'expressions/getFieldsInAst/getValuesetCodeLiterals';
import removeMethodHashes from 'casetivity-shared-js/lib/spel/getFieldsInAst/removeMethodHashes';
import { getDataTypeForFieldExpr } from 'components/generics/utils/viewConfigUtils';
import maybeLogError from 'viewConfigCalculations/util/logErrorIfPermissioned';

const getActionButtonDisplays = (
    key: string,
    resource: string,
    viewConfig: ViewConfig,
    viewConfiguration: Option<string>,
) =>
    viewConfiguration
        .map(parseConfig)
        .map(e => e.map(c => c.entityActions))
        // prints errors for the failed configs
        .map(e =>
            e.mapLeft(error => {
                maybeLogError(viewConfig)(parsingOrValidationErrMsg(error));
                return error;
            }),
        )
        .chain(fromEither)
        .chain(fromNullable)
        .map(actionButtons => {
            // Either empty object or array object
            return mapOption(
                actionButtons
                    .filter(buttonConfig => !!buttonConfig.displayRule)
                    .map(buttonConfig => [buttonConfig.key, removeMethodHashes(buttonConfig.displayRule)]),
                ([buttonKey, expression]) =>
                    fromEither(
                        tryCatch(
                            () => {
                                const compiledExpression = (SpelExpressionEvaluator.compile(
                                    expression,
                                ) as SpelCompiledExpression)._compiledExpression;
                                const fields = getFieldsInAst(expression)(compiledExpression);
                                return {
                                    expression,
                                    buttonKey,
                                    fieldsRequired: fields,
                                    valuesetLiterals: getValueSetCodeLiterals(expression)(compiledExpression),
                                };
                            },
                            (e: Error) => {
                                // prints errors for the failed SPEL compilation
                                maybeLogError(viewConfig)('Error parsing SPEL entityConfig validation expression', e);
                                return e;
                            },
                        ).chain(d =>
                            tryCatch(
                                () => {
                                    d.fieldsRequired
                                        .flatMap(df => (df.startsWith('record.') ? [df.slice('record.'.length)] : []))
                                        .forEach(df => {
                                            const source = df.endsWith('Id')
                                                ? df.slice(0, -2)
                                                : df.endsWith('Ids')
                                                ? df.slice(0, -3)
                                                : df.endsWith('Code')
                                                ? df.slice(0, -4)
                                                : df;
                                            getDataTypeForFieldExpr(viewConfig, resource, source, 'TRAVERSE_PATH');
                                        });
                                    return d;
                                },
                                (e: Error) => {
                                    maybeLogError(viewConfig)(
                                        `the expression "${d.expression}" for button "${d.buttonKey}" contains unknown data fields. This could be due to not having the appropriate permissions. The button will be removed. Error below:`,
                                    );
                                    maybeLogError(viewConfig)(e);
                                    return e;
                                },
                            ),
                        ), // .chain(setupValuesetFieldsRequired(viewConfig, resource, expression))
                    ),
            );
        })
        .map(
            v =>
                ({
                    [key]: Object.assign({}, ...v.map(c => ({ [c.buttonKey]: c }))),
                } as {
                    [key: string]: typeof v[0];
                }),
        );

export default getActionButtonDisplays;
