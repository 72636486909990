import React from 'react';
import LinkDialog from '../button/LinkDialog';
import Show from '../genericShow';

interface PopoverRecordTitleProps {
    text: string;
    recordId: string | number;
    resource: string;
}
const PopoverRecordTitle: React.SFC<PopoverRecordTitleProps> = (props: PopoverRecordTitleProps) => {
    const ariaLabelledBy = `open-${props.recordId}`;
    const ariaDescribedBy = 'opens dialog';
    return (
        <LinkDialog
            renderLabel={({ onClick }) => (
                <a // eslint-disable-line
                    href="javascript:;" // eslint-disable-line
                    id={ariaLabelledBy}
                    onClick={onClick}
                >
                    {props.text}
                </a>
            )}
            ariaLabelledBy={ariaLabelledBy}
            ariaDescribedBy={ariaDescribedBy}
            content={
                <div style={{ minWidth: '85vw' }}>
                    <Show
                        {...{
                            isPopover: true,
                            actions: null,
                            formId: `merge-popover-${props.recordId}`,
                            viewName: `${props.resource}Show`,
                            location: {
                                pathname: `/${props.resource}`,
                                search: '',
                            },
                            match: {
                                isExact: true,
                                params: {
                                    id: props.recordId,
                                    resourceBasePath: `/${props.resource}`,
                                },
                            },
                            resource: props.resource,
                        }}
                    />
                </div>
            }
        />
    );
};
export default PopoverRecordTitle;
