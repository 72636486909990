import React from 'react';
import {
    GenericShow,
    GenericList,
    GenericEdit,
    GenericCreate,
    GenericPossibleMatchView,
    GenericSpecificMatchView,
} from '../components/generics';

import { Helmet } from 'react-helmet';
import ViewConfig, { View } from '../reducers/ViewConfigType';
import Delete from '../components/generics/genericDelete/Delete';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { fromNullable } from 'fp-ts/lib/Option';

const Resource: React.SFC<any> = () => (
    // tslint:disable-line
    <span>&lt;Resource&gt; elements are for configuration only and should not be rendered</span>
);

Resource.defaultProps = {
    options: {},
};

type ViewComponentType = React.SFC<{ viewName: string; location: any; match: any }> | null; // tslint:disable-line

const useResourceDisplay = (resource: string, plural: boolean) => {
    const resourceDisplay = useSelector((state: RootState) => {
        return fromNullable(state.viewConfig.entities[resource])
            .mapNullable(r => (plural ? r.displayNamePlural : r.displayName))
            .getOrElse(resource);
    });
    return resourceDisplay;
};
const useEntityTitle = (id: string, resource: string, fallbackValue: null | string | undefined) => {
    const title = useSelector((state: RootState) => {
        return fromNullable(state.admin.entities[resource])
            .mapNullable(eb => eb[id])
            .mapNullable(e => e.title)
            .getOrElse(fallbackValue);
    });
    return title;
};
const renderWithTitle = (BaseElement: JSX.Element, title: string) => (
    <React.Fragment>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        {BaseElement}
    </React.Fragment>
);
const withDocumentTitle = (type: 'Edit' | 'View' | 'List' | 'Create' | 'Matches' | 'Merge') => {
    if (type === 'Edit' || type === 'View') {
        return BaseComponent => props => {
            const id = props.match.params.id;
            const resource = props.resource;
            const title = useEntityTitle(id, resource, 'Casetivity');
            const resourceDisplay = useResourceDisplay(resource, false);
            return renderWithTitle(<BaseComponent {...props} />, `${type} ${resourceDisplay}: ${title}`);
        };
    }
    if (type === 'List') {
        return BaseComponent => props => {
            const resourceDisplay = useResourceDisplay(props.resource, true);
            return renderWithTitle(<BaseComponent {...props} />, `Search ${resourceDisplay}`);
        };
    }
    if (type === 'Create') {
        return BaseComponent => props => {
            const resourceDisplay = useResourceDisplay(props.resource, false);
            return renderWithTitle(<BaseComponent {...props} />, `Create ${resourceDisplay}`);
        };
    }
    if (type === 'Matches') {
        return BaseComponent => props => {
            const title = useEntityTitle(props.match.params.id, props.resource, 'record');
            const resourceDisplay = useResourceDisplay(props.resource, true);
            return renderWithTitle(<BaseComponent {...props} />, `Matching ${resourceDisplay} for ${title}`);
        };
    }
    if (type === 'Merge') {
        return BaseComponent => props => {
            return renderWithTitle(<BaseComponent {...props} />, `Resolve Possible Match`);
        };
    }
    return i => i;
};
const getComponentFromViewType = (type: View['viewType']) => {
    switch (type) {
        case 'EDIT':
            return withDocumentTitle('Edit')(GenericEdit);
        case 'SHOW':
            return withDocumentTitle('View')(GenericShow);
        case 'LIST':
            return withDocumentTitle('List')(GenericList);
        case 'CREATE':
            return withDocumentTitle('Create')(GenericCreate);
        case 'MATCH':
            return withDocumentTitle('Matches')(GenericPossibleMatchView);
        case 'MERGE':
            return withDocumentTitle('Merge')(GenericSpecificMatchView);
        default:
            console.error('unknown viewType', type); // tslint:disable-line
            return props => null; // don't let fail
    }
};

export const getViewComponent = (view: View) => {
    const ViewComponent = getComponentFromViewType(view.viewType);
    return props => <ViewComponent {...props} resource={view.entity} createMobileAppBar={true} viewName={view.name} />;
};

/*
Background info:
    1. We are dynamically creating components with viewName set below.
    2. Each time search/pagination etc parameters change for list view, a push is dispatched;
The problem with 1. and 2:
    The route sees a new component and we get components unmounting/remounting between search changes.
    Besides being unecessary/bad for performance, this means we can't deduce much from a component mounting/unmounting.
    (I would like to know that when a List view is mounted/unmounted,
        we can start with a fresh filter, and not have to persist the old one.)

In order to keep referential integrity, we will memoize
    components in the dictionary below, indexing by defaultListView value.

*/
/*
    Memoization also added for Show, Edit, Create to prevent Form values + open
        tabs from being lost due to window resize, push events, etc.
*/

const getResourceStateFromViewConfig = (viewConfig: ViewConfig) =>
    Object.values(viewConfig.entities ? viewConfig.entities : [])
        .map(entityDef => {
            const entityName = entityDef.name;
            const entityPlural = entityDef.name;

            if (entityDef.defaultViews && Object.keys(entityDef.defaultViews).length !== 0) {
                let List: ViewComponentType = null;
                let Edit: ViewComponentType = null;
                let Create: ViewComponentType = null;
                let Show: ViewComponentType = null;
                let PossibleMatchView: ViewComponentType = null;
                let SpecificMatchView: ViewComponentType = null;
                if (entityDef.defaultViews.LIST) {
                    const defaultListView = entityDef.defaultViews.LIST.name;
                    List = getViewComponent(viewConfig.views[defaultListView]);
                }
                if (entityDef.defaultViews.EDIT) {
                    const defaultEditView = entityDef.defaultViews.EDIT.name;
                    Edit = getViewComponent(viewConfig.views[defaultEditView]);
                }
                if (entityDef.defaultViews.CREATE) {
                    const defaultCreateView = entityDef.defaultViews.CREATE.name;
                    Create = getViewComponent(viewConfig.views[defaultCreateView]);
                }
                if (entityDef.defaultViews.SHOW) {
                    const defaultShowView = entityDef.defaultViews.SHOW.name;
                    Show = getViewComponent(viewConfig.views[defaultShowView]);
                }
                if (entityDef.defaultViews.MATCH) {
                    const defaultMatchView = entityDef.defaultViews.MATCH.name;
                    PossibleMatchView = getViewComponent(viewConfig.views[defaultMatchView]);
                }
                if (entityDef.defaultViews.MERGE) {
                    const defaultMergeView = entityDef.defaultViews.MERGE.name;
                    SpecificMatchView = getViewComponent(viewConfig.views[defaultMergeView]);
                }

                return (
                    // entityIndex: used to index into viewConfig
                    <Resource
                        key={`ResourceTemplate-${entityName}`}
                        entityIndex={entityName}
                        name={entityPlural}
                        list={List}
                        edit={Edit}
                        create={Create}
                        remove={Delete}
                        show={Show}
                        possibleMatchView={PossibleMatchView}
                        specificMatchView={SpecificMatchView}
                    />
                );
            }
            return (
                // entityIndex: used to index into viewConfig
                <Resource key={`ResourceTemplate-${entityName}`} entityIndex={entityName} name={entityPlural} />
            );
        })
        .map(({ props }) => props) || [];

export default getResourceStateFromViewConfig;
