import range from 'lodash/range';
import * as FieldDataType from '../../fieldDataTypes';
import { RootState } from '../../../../../reducers/rootReducer';
import { EntityField, Entity } from '../../../../../reducers/ViewConfigType';
import { fromNullable } from 'fp-ts/lib/Option';
import ViewConfigEntities from 'casetivity-shared-js/lib/view-config/entities';

type ViewConfig = RootState['viewConfig'];

export function isString(data: undefined | string): data is string {
    return typeof data === 'string';
}
export const getLastFieldInExpression = (codedFieldName: string): string =>
    codedFieldName.split('.').pop() || codedFieldName;

export const isValidEntityFieldExpression = (viewConfig: ViewConfig, entity: string, fieldExpr: string) => {
    if (!viewConfig.entities[entity]) {
        return false;
    }
    const viewFieldRefPath = fieldExpr.split('.');
    const entityField = viewConfig.entities[entity].fields[viewFieldRefPath[0]];
    if (!entityField) {
        return false;
    }
    if (viewFieldRefPath.length === 1) {
        return true;
    } else {
        const nextEntity = viewConfig.entities[entity].fields[viewFieldRefPath[0]].relatedEntity;
        if (isString(nextEntity)) {
            return isValidEntityFieldExpression(
                viewConfig,
                nextEntity,
                range(1, viewFieldRefPath.length)
                    .map(i => viewFieldRefPath[i])
                    .join('.'),
            );
        }
        return false;
    }
};
/*
    @param entity: the base entity of the field we are looking at
    @param fieldExpr: the fieldExpression, ending in a ref field (last field in chain needs a 'relatedEntity' property.
*/
export const getRefEntity = (viewConfig: ViewConfig, entity: string, fieldExpr: string): Entity => {
    if (!viewConfig.entities[entity]) {
        throw Error(`Entity "${entity}" does not exist`);
    }
    const viewFieldRefPath = fieldExpr.split('.');
    const entityField = viewConfig.entities[entity].fields[viewFieldRefPath[0]];
    if (!entityField) {
        throw Error(`field "${viewFieldRefPath[0]}" in "${fieldExpr}" not found on entity "${entity}"`);
    }
    if (viewFieldRefPath.length === 1) {
        const relEntity: string | undefined = viewConfig.entities[entity].fields[fieldExpr].relatedEntity;
        if (isString(relEntity)) {
            return viewConfig.entities[relEntity];
        }
        throw Error(`No related Entity for field: "${fieldExpr}" on entity "${entity}"`);
    } else {
        const nextEntity = viewConfig.entities[entity].fields[viewFieldRefPath[0]].relatedEntity;
        if (isString(nextEntity)) {
            return getRefEntity(
                viewConfig,
                nextEntity,
                range(1, viewFieldRefPath.length)
                    .map(i => viewFieldRefPath[i])
                    .join('.'),
            );
        }
        throw Error(`relatedEntity not found on the path "${fieldExpr}":
        "${viewFieldRefPath[0]}": "${viewConfig.entities[entity].fields[viewFieldRefPath[0]]}"
        has no relatedEntity field`);
    }
};

export const getAttrOfTraversedFieldExpr = <T extends keyof EntityField>(
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    attribute: keyof EntityField,
): EntityField[T] => {
    const entity = viewConfig.entities[entityName];
    if (!entity) {
        throw Error(`Entity "${entityName}" not found in getAttributeOfEntityField call for attribute "${attribute}"`);
    }
    const fieldPath = fieldName.split('.');
    if (fieldPath.length > 1) {
        // we need to look up the field
        const relatedEntityToNestedField = getRefEntity(
            viewConfig,
            entityName,
            range(0, fieldPath.length - 1)
                .map(i => fieldPath[i])
                .join('.'),
        );
        return relatedEntityToNestedField.fields[fieldPath[fieldPath.length - 1]][attribute] as EntityField[T];
    }
    if (!entity.fields[fieldName]) {
        throw Error(`field "${fieldName}" not found on entity "${entityName}"`);
    }
    return entity.fields[fieldName][attribute] as EntityField[T];
};

/*
    This allows us to also accomodate the "linkedXXXX.restof.field.expressions..."" syntax,
    such as when we need to get the valueSetCode of a field in the linkedEntity search on the process list.
    In this case entity can be undefined, since we get the entity name from XXXX in the example above
*/
const nextIfLinked = (entityName: string | undefined, fieldName: string) => {
    const linkedPrefix = 'linked';
    if (
        (typeof entityName === 'undefined' || entityName === 'AppCase' || entityName === 'RelatedCase') &&
        fieldName.startsWith(linkedPrefix) &&
        fieldName.charAt(linkedPrefix.length).toUpperCase() === fieldName.charAt(linkedPrefix.length)
    ) {
        return {
            nextEntity: fieldName.includes('.')
                ? fieldName.slice(linkedPrefix.length, fieldName.indexOf('.'))
                : fieldName.slice(linkedPrefix.length),
            nextField: fieldName.includes('.') ? fieldName.slice(fieldName.indexOf('.') + 1) : '',
        };
    }
    return null;
};
export const getAttrOfTraversedFieldExprIncludingLinkedX = <T extends keyof EntityField>(
    viewConfig: ViewConfig,
    entityName: string | undefined,
    fieldName: string,
    attribute: keyof EntityField,
): EntityField[T] => {
    const linkedNext = nextIfLinked(entityName, fieldName);
    if (linkedNext) {
        if (!linkedNext.nextField) {
            if (!entityName) {
                throw new Error('traversing path ending with "linked_" without a base entity');
            }
            // we are at the end of the chain, so lets retry on 'linkedEntity' where we will get whatever static data we can
            return getAttrOfTraversedFieldExpr<T>(viewConfig, entityName, 'linkedEntity', attribute);
        }
        return getAttrOfTraversedFieldExpr<T>(viewConfig, linkedNext.nextEntity, linkedNext.nextField, attribute);
    } else if (entityName) {
        return getAttrOfTraversedFieldExpr<T>(viewConfig, entityName, fieldName, attribute);
    } else {
        throw new Error(
            `entityName is undefined, and fieldName ${fieldName} does not start with "linked[entityName]."`,
        );
    }
};

const POP_LAST = 'POP_LAST';
const TRAVERSE_PATH = 'TRAVERSE_PATH';
/*
    !!!IMPORTANT REGARDING USAGE!!!

    "POP_LAST" and "TRAVERSE_PATH" describe the relationship between entityName and fieldName/fieldExpression

    =================================================================================================================
    POP_LAST
    =================================================================================================================
    POP_LAST indexes:
        entities[entityName].fields[fieldName.split('.').last()]

    [WHEN TO USE]:
        when we have access to a view field (ViewField). e.g:

    viewConfig;
    {
        views:  {
            [ClientList]: {
                fields: {
                    [client.currentAddress.state]: {         <------ this is the viewField (as opposed to entity field)
                        entity: 'Address',
                        field: 'client.currentAddress.state',
                        widgetType: ValueSet
                        ...
                    }
                }
            }
        }
    }
    since we already know 'state' belongs to 'Address', we simpy get (Address['state'])

    =================================================================================================================
    TRAVERSE_PATH
    =================================================================================================================
    TRAVERSE_PATH gets us our target field recursively, based on an entity given.

    e.g. ('Client', 'currentAddress.state) => returns Address['state'] (due to chained lookup)

    [WHEN TO USE]:
        When we know the entity at the base of the field expression, but DON'T know the viewField.entity.
        (basically, the component level of, say, a ValueSetSelect field, we only know
            1. the resource (root entity)
            2. the fieldExpression.
        We don't know the entity of the final field in the field expression, like we do when we have the ViewField.

*/
type EtF = 'POP_LAST' | 'TRAVERSE_PATH';

const propertyOfLastEntityFieldInExpr = <T extends keyof EntityField>(
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    property: keyof EntityField,
): EntityField[T] =>
    (viewConfig.entities[entityName].fields[getLastFieldInExpression(fieldName)] || {})[property] as EntityField[T];

/* LABEL */
export const getLabelForFieldExpr = (
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = POP_LAST,
): string | null =>
    relationship === POP_LAST
        ? propertyOfLastEntityFieldInExpr<'label'>(viewConfig, entityName, fieldName, 'label')
        : getAttrOfTraversedFieldExpr<'label'>(viewConfig, entityName, fieldName, 'label');

/* DESCRIPTION */
export const getDescriptionForFieldExpr = (
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = POP_LAST,
): string | undefined =>
    relationship === POP_LAST
        ? propertyOfLastEntityFieldInExpr<'description'>(viewConfig, entityName, fieldName, 'description')
        : getAttrOfTraversedFieldExpr<'description'>(viewConfig, entityName, fieldName, 'description');

/* REQUIRED */
export const getRequiredForFieldExpr = (
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = POP_LAST,
): boolean | undefined =>
    relationship === POP_LAST
        ? propertyOfLastEntityFieldInExpr<'required'>(viewConfig, entityName, fieldName, 'required')
        : getAttrOfTraversedFieldExpr<'required'>(viewConfig, entityName, fieldName, 'required');

/* MIN_SIZE*/
export const getMinSizeForFieldExpr = (
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = POP_LAST,
): number | undefined =>
    relationship === POP_LAST
        ? propertyOfLastEntityFieldInExpr<'minSize'>(viewConfig, entityName, fieldName, 'minSize')
        : getAttrOfTraversedFieldExpr<'minSize'>(viewConfig, entityName, fieldName, 'minSize');

/* MAX_SIZE */
export const getMaxSizeForFieldExpr = (
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = POP_LAST,
): number | undefined =>
    relationship === POP_LAST
        ? propertyOfLastEntityFieldInExpr<'maxSize'>(viewConfig, entityName, fieldName, 'maxSize')
        : getAttrOfTraversedFieldExpr<'maxSize'>(viewConfig, entityName, fieldName, 'maxSize');

/* PATTERN */
export const getPatternForFieldExpr = (
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = POP_LAST,
): string | undefined =>
    relationship === POP_LAST
        ? propertyOfLastEntityFieldInExpr<'pattern'>(viewConfig, entityName, fieldName, 'pattern')
        : getAttrOfTraversedFieldExpr<'pattern'>(viewConfig, entityName, fieldName, 'pattern');

/* DATA_TYPE */
export const getDataTypeForFieldExpr = (
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = POP_LAST,
): (typeof FieldDataType)[keyof (typeof FieldDataType)] =>
    relationship === POP_LAST
        ? propertyOfLastEntityFieldInExpr<'dataType'>(viewConfig, entityName, fieldName, 'dataType')
        : getAttrOfTraversedFieldExpr<'dataType'>(viewConfig, entityName, fieldName, 'dataType');

export const getIsExpensiveForFieldExpr = (
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = POP_LAST,
): boolean =>
    relationship === POP_LAST
        ? propertyOfLastEntityFieldInExpr<'expensive'>(viewConfig, entityName, fieldName, 'expensive')
        : getAttrOfTraversedFieldExpr<'expensive'>(viewConfig, entityName, fieldName, 'expensive');

/* Calculated And not GENERATED_ID */
export const isCalculatedUnsortableField = (
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = POP_LAST,
): boolean => {
    const calcType =
        relationship === POP_LAST
            ? propertyOfLastEntityFieldInExpr<'calcType'>(viewConfig, entityName, fieldName, 'calcType')
            : getAttrOfTraversedFieldExpr<'calcType'>(viewConfig, entityName, fieldName, 'calcType');
    return calcType === 'CALC_DYNAMIC';
};
export const fieldIsSortable = (
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = POP_LAST,
): boolean => {
    return (
        !isCalculatedUnsortableField(viewConfig, entityName, fieldName, relationship) &&
        getDataTypeForFieldExpr(viewConfig, entityName, fieldName, relationship) !== 'TEXTBLOB'
    );
};

/* VALUE_SET */
export const getValueSetForFieldExpr = (
    viewConfig: ViewConfig,
    entityName: string | undefined,
    fieldName: string,
    relationship: EtF = POP_LAST,
): string | undefined => {
    if (relationship === POP_LAST) {
        if (entityName) {
            return propertyOfLastEntityFieldInExpr<'valueSet'>(viewConfig, entityName, fieldName, 'valueSet');
        }
        throw new Error('entityName cannot be undefined with POP_LAST relationship in getValueSetForFieldExpr.');
    }
    return getAttrOfTraversedFieldExprIncludingLinkedX<'valueSet'>(viewConfig, entityName, fieldName, 'valueSet');
};

/* RELATED_FIELD */
export const getRelatedField = (
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = POP_LAST,
): string | undefined =>
    relationship === POP_LAST
        ? propertyOfLastEntityFieldInExpr<'relatedField'>(viewConfig, entityName, fieldName, 'relatedField')
        : getAttrOfTraversedFieldExpr<'relatedField'>(viewConfig, entityName, fieldName, 'relatedField');

export const getPathBackFromFieldPath = <ViewConfig extends { entities: ViewConfigEntities }>(
    viewConfig: ViewConfig,
    baseEntity: string,
    path: string,
    linkedEntityFormat: 'linkedEntity' | 'linked<entityType>' = 'linked<entityType>',
): string => {
    const entity = viewConfig.entities[baseEntity];
    if (!entity) {
        throw Error(`Entity "${baseEntity}" not found in getPathBackFromFieldPath call`);
    }
    const fieldPath = path.split('.');
    let currentEntity: string = baseEntity;
    return fieldPath.reduce((prev, curr) => {
        const currentRelatedFieldBack = (() => {
            const fieldBack = getRelatedField(viewConfig as any, currentEntity, curr, 'TRAVERSE_PATH');
            return fieldBack === 'linkedEntity' && linkedEntityFormat === 'linked<entityType>'
                ? `linked${currentEntity}`
                : fieldBack;
        })();

        currentEntity = getRefEntityName(viewConfig as any, currentEntity, curr, 'TRAVERSE_PATH');
        return currentRelatedFieldBack + (prev ? '.' : '') + prev;
    }, '');
};
/* ACCESS_LEVEL */
export const getAccessLevelForEntityField = (
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = POP_LAST,
): number =>
    relationship === POP_LAST
        ? propertyOfLastEntityFieldInExpr<'accessLevel'>(viewConfig, entityName, fieldName, 'accessLevel')
        : getAttrOfTraversedFieldExpr<'accessLevel'>(viewConfig, entityName, fieldName, 'accessLevel');

/*
    Is - a utilities
*/

export const isValueSetField = (
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = POP_LAST,
): boolean => getDataTypeForFieldExpr(viewConfig, entityName, fieldName, relationship) === FieldDataType.VALUESET;

export const isValueSetManyField = (
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = POP_LAST,
): boolean => getDataTypeForFieldExpr(viewConfig, entityName, fieldName, relationship) === FieldDataType.VALUESET_MANY;

export const isBooleanField = (
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = POP_LAST,
): boolean => getDataTypeForFieldExpr(viewConfig, entityName, fieldName, relationship) === FieldDataType.BOOLEAN;

export const isValueSetOrValueSetManyField = (
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = POP_LAST,
): boolean =>
    isValueSetField(viewConfig, entityName, fieldName, relationship) ||
    isValueSetManyField(viewConfig, entityName, fieldName, relationship);

export const isRefOneField = (
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = POP_LAST,
): boolean => getDataTypeForFieldExpr(viewConfig, entityName, fieldName, relationship) === FieldDataType.REFONE;

export const isRefManyField = (
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = POP_LAST,
): boolean => getDataTypeForFieldExpr(viewConfig, entityName, fieldName, relationship) === FieldDataType.REFMANY;

export const isRefManyManyField = (
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = POP_LAST,
): boolean => getDataTypeForFieldExpr(viewConfig, entityName, fieldName, relationship) === FieldDataType.REFMANYMANY;

export const getRefEntityName = (
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = TRAVERSE_PATH,
): string => {
    if (relationship === POP_LAST) {
        const fieldParts = fieldName.split('.');
        const lastFieldPart = fieldParts[fieldParts.length - 1];
        const relatedEntity = fromNullable(viewConfig.entities[entityName].fields[lastFieldPart])
            .mapNullable(f => f.relatedEntity)
            .getOrElse(undefined);
        if (relatedEntity) {
            return fromNullable(viewConfig.entities[relatedEntity])
                .mapNullable(re => re.name)
                .getOrElse(undefined);
        }
    }
    return getRefEntity(viewConfig, entityName, fieldName).name;
};

export const getRefEntityLabel = (
    viewConfig: ViewConfig,
    entityName: string,
    fieldName: string,
    relationship: EtF = TRAVERSE_PATH,
): string => {
    if (relationship === POP_LAST) {
        const relatedEntity = fromNullable(viewConfig.entities[entityName].fields[fieldName])
            .mapNullable(f => f.relatedEntity)
            .getOrElse(undefined);
        if (relatedEntity) {
            return fromNullable(viewConfig.entities[relatedEntity])
                .mapNullable(e => e.displayName)
                .getOrElse(undefined);
        }
    }
    return getRefEntity(viewConfig, entityName, fieldName).displayName;
};

export const getLongestRefonePathInPath = <ViewConfig extends { entities: ViewConfigEntities }>(
    { entities }: ViewConfig,
    entityName: string,
    path: string,
    depth: number = 0,
) => {
    if (path === '') {
        return '';
    }
    const entity = entities[entityName];
    if (!entity) {
        throw Error(
            `Entity "${entityName}" not found in getLongestRefonePathInPath(viewConfig,"${entityName}", "${path}")`,
        );
    }
    const fieldPath = path.split('.');
    if (fieldPath.length > 0) {
        const [field] = fieldPath;
        const adjustedPath = field.endsWith('Id') ? field.slice(0, -2) : field;

        const linkedNext = nextIfLinked(entityName, path);
        try {
            if (linkedNext) {
                const rest = linkedNext.nextField
                    ? getLongestRefonePathInPath({ entities }, linkedNext.nextEntity, linkedNext.nextField, depth + 1)
                    : '';
                return rest ? adjustedPath + '.' + rest : adjustedPath;
            }
            const entityField = entity.fields[adjustedPath];
            if (entityField) {
                if (
                    entityField.dataType === 'REFONE' ||
                    entityField.dataType === 'VALUESET' ||
                    entityField.dataType === 'REFONEJOIN'
                ) {
                    const nextEntity = entityField.relatedEntity;

                    const rest = getLongestRefonePathInPath(
                        { entities },
                        nextEntity,
                        fieldPath.slice(1).join('.'),
                        depth + 1,
                    );
                    return rest ? adjustedPath + '.' + rest : adjustedPath;
                }
                return '';
            }
            throw new Error(
                `field ${
                    adjustedPath !== field ? `${adjustedPath}/${field}` : field
                } not found on entity ${entityName}`,
            );
        } catch (e) {
            if (depth > 0) {
                throw e;
            } else {
                console.error(e);
                throw Error(
                    `Error above occurred in getLongestRefonePathInPath(viewConfig,"${entityName}", "${path}")`,
                );
            }
        }
    }
    return '';
};
