import React, { useEffect } from 'react';
import GlobalAlertsBannerComponent from 'global-alerts/components/BannerComponent';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { getPublicGlobalAlerts } from '../actions';

const PublicGlobalAlerts = props => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getPublicGlobalAlerts());
    }, []); // eslint-disable-line
    const publicAlerts = useSelector((state: RootState) => {
        return state.globalAlerts.publicAlerts;
    });
    return publicAlerts.fold(
        null,
        null,
        err => null,
        data => (data ? <GlobalAlertsBannerComponent globalAlerts={data} /> : null),
    );
};
export default PublicGlobalAlerts;
