import React from 'react';
import { connect } from 'react-redux';
import { withWidth } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import compose from 'recompose/compose';
import { toggleSidebar as toggleSidebarAction } from 'actions/aor/uiActions';

const styles = {
    root: {
        flexGrow: 1,
    },
    flex: {
        flex: 1,
        fontSize: '2em',
    },
};

class AppBarMobileComponent extends React.Component {
    handleLeftIconButtonTouchTap = event => {
        event.preventDefault();
        this.props.toggleSidebar();
    };

    render() {
        const { title, classes } = this.props;
        return (
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        className={classes.menuButton}
                        onClick={this.handleLeftIconButtonTouchTap}
                        color="inherit"
                        aria-label="Menu"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h1" color="inherit" className={classes.flex}>
                        {title}
                    </Typography>
                </Toolbar>
            </AppBar>
        );
    }
}

const enhance = compose(
    withStyles(styles),
    connect(
        null,
        {
            toggleSidebar: toggleSidebarAction,
        },
    ),
);

const AppBarMobile = enhance(AppBarMobileComponent);

// Based on Admin-on-Rest's ViewTitle- only renders the appbar (no title on desktop widths)
const ViewTitle = ({ title, width }) => (width === 'xs' ? <AppBarMobile title={title} /> : null);

ViewTitle.defaultProps = {
    title: '',
};

export default withWidth({
    initialWidth: 'md',
})(ViewTitle);
