import React from 'react';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import RDDDropdownMenu, { NestedDropdownMenu } from './ReactDD';
import './ReactDD/react-dd-menu.css';
import { Button, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { translateRoute } from '../../generics/utils/viewConfigUtils';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Subtract } from 'utility-types';
import { DropdownMenuProps } from './DropdownInterface';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { startProcessFromKey } from 'bpm/create-process-instance/actions';

const ButtonAsAny: any = Button; // tslint:disable-line

const styles = theme => ({
    button: {
        width: 24,
        height: 24,
        padding: 0,
    },
});

type ToolbarDropdownProps = {
    nestedMenuDisplaysFrom?: 'left' | 'right';
    classes: { button: string };
    createProcessInstanceFromKey: typeof startProcessFromKey;
    icon?: ReactNode;
    id: string;
    to?: string | null;
} & Subtract<DropdownMenuProps, { mobile: DropdownMenuProps['mobile'] }>;
class ToolbarDropdown extends React.Component<ToolbarDropdownProps, { readonly open: boolean }> {
    static defaultProps = {
        style: {
            display: 'block',
            position: 'relative',
            textAlign: 'center',
            top: '50%',
            transform: 'translateY(-50%)',
            height: '100%',
        },
        icon: null,
    };
    constructor(props: ToolbarDropdownProps) {
        super(props);
        this.state = {
            open: false,
        };
    }

    onMenuItemClick = route => {
        this.handleRequestClose();
        this.props.setSidebarVisibility(false);
        if (route.startsWith('startProcess(') && route.endsWith(')')) {
            this.props.createProcessInstanceFromKey(
                {
                    processDefinitionKey: route.slice('startProcess('.length, -1),
                },
                {
                    handleRedirect: true,
                    successCb: () => null,
                    errorsCbs: {},
                },
            );
        } else {
            this.props.pushAction(`/${route}`);
        }
    };

    getLabelFloated = (
        label = this.props.label,
        icon = <ArrowDropDown color="inherit" />,
        link: string | null = null,
    ) => {
        const { classes } = this.props;
        const entitiesLabel = (
            <span
                className={link ? 'ddToggleWithLink' : undefined}
                style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    verticalAlign: 'middle',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
                onClick={e => {
                    if (link) {
                        e.preventDefault();
                        e.stopPropagation();
                        this.onMenuItemClick(link);
                    }
                }}
            >
                {label}
                <IconButton
                    className={classnames(classes.button, 'ddexpandarrow')}
                    aria-label="expand-dropdown"
                    onFocus={e => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                    onKeyDown={e => {
                        e.stopPropagation();
                    }}
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        if ((e as any).which === 13) {
                            // tslint:disable-line
                            // keyboard was used
                            this.handleClick(e);
                        }
                    }}
                >
                    {icon}
                </IconButton>
            </span>
        );
        return entitiesLabel;
    };

    getLabel = (label = this.props.label, icon: ReactNode | null = <ArrowDropDown color="inherit" />) => {
        const entitiesLabel = (
            <span
                style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    verticalAlign: 'middle',
                }}
            >
                {label} {icon}
            </span>
        );
        return entitiesLabel;
    };

    handleClick = event => {
        // This prevents ghost click.
        event.preventDefault();

        this.setState({
            open: true,
        });
    };
    handleRequestClose = () => {
        this.setState({
            open: false,
        });
    };

    renderMenuItems = () => {
        const { navChildren, viewConfig, nestedMenuDisplaysFrom = 'left' } = this.props;

        return navChildren
            ? navChildren.map((subMenuItem, i) => {
                  const subItems =
                      subMenuItem.children &&
                      subMenuItem.children.map(nestedMenuItem => ({
                          caption: nestedMenuItem.label,
                          id: nestedMenuItem.name,
                          onClick: () =>
                              this.onMenuItemClick(
                                  translateRoute(viewConfig, nestedMenuItem.route, nestedMenuItem.view),
                              ),
                      }));

                  const translatedRoute = translateRoute(viewConfig, subMenuItem.route, subMenuItem.view);
                  const label = subItems
                      ? this.getLabelFloated(
                            subMenuItem.label,
                            <KeyboardArrowRight color="inherit" style={{ float: 'right' }} />,
                            subMenuItem.route,
                        )
                      : subMenuItem.label;
                  const menuItemProps = {
                      className: 'dropdownMenuItem',
                      key: `menu-item-nav-${subMenuItem.name}`,
                      onClick: event => {
                          if (translatedRoute) {
                              event.preventDefault();
                              this.onMenuItemClick(translatedRoute);
                          } else {
                              // this used to be from when we had to manually close an existing popover
                              //  when clicking a different menu item
                              // const selectedLabel = event.nativeEvent.target.textContent;
                              // setSelectedSubmenu && this.setSelectedSubmenu(selectedLabel);
                          }
                      },
                      primaryTogglesNestedList: true,
                      nestedItems: subItems,
                      id: subMenuItem.name,
                  };
                  const isLink = !subItems || subItems.length === 0;
                  return isLink ? (
                      <li role="presentation" key={i}>
                          <Button
                              component="a"
                              role="menuitem"
                              style={{ textTransform: 'capitalize' }}
                              color={'inherit'}
                              variant={'text'}
                              onClick={menuItemProps.onClick}
                              id={menuItemProps.id}
                          >
                              {label}
                          </Button>
                      </li>
                  ) : (
                      <NestedDropdownMenu
                          ulProps={{
                              id: `${menuItemProps.id}-menu`,
                              role: 'menu',
                              'aria-labelledby': menuItemProps.id,
                          }}
                          key={i}
                          nested={nestedMenuDisplaysFrom}
                          toggle={
                              <Button
                                  aria-haspopup="true"
                                  component="a"
                                  role="menuitem"
                                  style={{ position: 'relative', textTransform: 'capitalize' }}
                                  color={'inherit'}
                                  variant={'text'}
                                  id={menuItemProps.id}
                              >
                                  {label}
                              </Button>
                          }
                          delay={0}
                          enterTimeout={0}
                          leaveTimeout={0}
                      >
                          {subItems &&
                              subItems.map(({ caption, onClick, id }, i) => (
                                  <li role="presentation" key={i}>
                                      <Button
                                          id={id}
                                          component="a"
                                          role="menuitem"
                                          style={{ textTransform: 'capitalize' }}
                                          color={'inherit'}
                                          variant={'text'}
                                          onClick={onClick}
                                      >
                                          {caption}
                                      </Button>
                                  </li>
                              ))}
                      </NestedDropdownMenu>
                  );
              })
            : this.props.renderMenuItems({
                  pushAction: Function,
                  logout: this.props.logout,
                  onMenuTap: this.props.onMenuTap,
                  viewConfig: this.props.viewConfig,
                  mobile: false,
                  navChildren: this.props.navChildren,
                  onClick: this.onMenuItemClick,
              });
    };

    render() {
        if (!this.props.viewConfig || Object.keys(this.props.viewConfig).length === 0) {
            return null;
        }
        const menuOptions = {
            ulProps: {
                id: `${this.props.id}-menu`,
                role: 'menu',
                'aria-labelledby': this.props.id,
            },
            isOpen: this.state.open,
            close: this.handleRequestClose,
            toggle: this.props.to ? (
                <IconButton
                    // color="secondary"
                    className={this.props.classes.button}
                    onClick={this.handleClick}
                    id={`${this.props.id}-dropdown`}
                >
                    {/* this.props.icon /*
                    && React.cloneElement(this.props.icon as any, { color: 'secondary' })} {/* tslint:disable-line
                    */}
                    <ArrowDropDown color="inherit" />
                </IconButton>
            ) : (
                <Button
                    aria-haspopup="true"
                    aria-controls={`${this.props.id}-menu`}
                    aria-expanded={this.state.open ? 'true' : 'false'}
                    style={{ textTransform: 'capitalize' }}
                    color={'inherit'}
                    variant={'text'}
                    onClick={this.handleClick}
                    id={this.props.id}
                >
                    {this.getLabel()}
                    {this.props.icon}
                </Button>
            ),
            align: 'left',
            style: { marginRight: '1em' },
            animate: true,
            enterTimeout: 0,
            leaveTimeout: 0,
        };
        return (
            <React.Fragment>
                {this.props.to ? (
                    <ButtonAsAny
                        component={Link}
                        to={this.props.to}
                        color={'inherit'}
                        variant={'text'}
                        id={`${this.props.id}-link`}
                    >
                        {this.getLabel(this.props.label, null)}
                    </ButtonAsAny>
                ) : null}
                <RDDDropdownMenu {...menuOptions}>{this.renderMenuItems()}</RDDDropdownMenu>
            </React.Fragment>
        );
    }
}

export default compose(
    withStyles(styles),
    connect(
        null,
        { createProcessInstanceFromKey: startProcessFromKey },
    ),
)(ToolbarDropdown);
