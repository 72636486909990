import React, { useState } from 'react';
import { stringify } from 'query-string';
import GenericList from 'components/generics/genericList';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';

const useListLocation = (resource: string, filter?: {}) => {
    const [location, setLocation] = useState({
        pathname: `/${resource}`,
        search: `?${filter ? stringify({ filter: JSON.stringify(filter) }) : ''}`,
    });
    return [location, setLocation] as const;
};

const ListView: React.SFC<{
    viewName: string;
    noClick?: boolean;
}> = ({ viewName, noClick }) => {
    const resource = useSelector((state: RootState) => {
        return state.viewConfig && state.viewConfig.views[viewName] && state.viewConfig.views[viewName].entity;
    });
    const [location, setLocation] = useListLocation(resource);
    return (
        <GenericList
            noClick={noClick}
            useCard={false}
            cancelRequestOnRouteChange={true}
            hasCreate={false}
            renderActions={() => null}
            showFilters={true}
            updateUrlFromFilter={false}
            fakePush={setLocation}
            location={location}
            isPopover={false}
            title={null}
            resource={resource}
            viewName={viewName}
        />
    );
};
export default ListView;
