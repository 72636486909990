import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import AttemptRequest from '../../../../components/AttemptRequest';
import * as config from '../../../../config';
import FormSaveNotifierStatus from 'formSaveNotifier/components/FormSaveNotifierStatus';
import { storageController } from 'storage';

const getLazyR = (taskId: string) => () =>
    fetch(`${config.BACKEND_BASE_URL}api/bpm/tasks/${taskId}/delete`, {
        method: 'DELETE',
        credentials: 'same-origin',
        headers: new Headers({
            Authorization: `Bearer ${storageController.getToken()}`,
            Cookie: `${window.document.cookie}`,
            Accept: 'application/json',
            'Content-type': 'application/json',
        }),
    });

export const deleteTaskButton = (taskId: string) => (onSuccessCb: () => void) => (
    <FormSaveNotifierStatus>
        {({ disable, reenable }) => (
            <AttemptRequest
                type="external"
                renderer={({ attemptAction: _attemptAction }) => {
                    const attemptAction = () => {
                        disable();
                        _attemptAction();
                    };
                    return state =>
                        state._tag === 'failure' ? (
                            <div>
                                Action failed.
                                <a // eslint-disable-line
                                    href="javascript:;" // eslint-disable-line
                                    onClick={attemptAction}
                                >
                                    try again?
                                </a>
                            </div>
                        ) : state._tag === 'pending' ? (
                            <Button style={{ marginLeft: 'auto' }} variant="contained" color="default" disabled={true}>
                                Delete Task
                                <CircularProgress style={{ marginLeft: '1em', height: 20, width: 20 }} />
                            </Button>
                        ) : state._tag === 'unsubmitted' ? (
                            <Button
                                style={{ marginLeft: 'auto' }}
                                variant="contained"
                                color="primary"
                                onClick={attemptAction}
                            >
                                Delete Task
                            </Button>
                        ) : (
                            <span style={{ marginLeft: 'auto' }}>Task {taskId} has been deleted successfully</span>
                        );
                }}
                lazyRequest={getLazyR(taskId)}
                onSuccess={() => {
                    onSuccessCb();
                    reenable();
                }}
                onErrorsCbs={{
                    '*': reenable,
                }}
            />
        )}
    </FormSaveNotifierStatus>
);
