import { FormFieldUnion } from 'fieldFactory/translation/fromFlowable/types';

const setupFieldValuesFromTable = (
    tableId: string,
    tableData: { [field: string]: any }[],
    columnObj?: FormFieldUnion[], // if provided, null-initialize fields.
): { [newId: string]: any } =>
    Object.assign(
        {},
        ...tableData.map((row, i) => {
            const rowObject = {};
            if (columnObj) {
                columnObj.forEach(f => {
                    rowObject[`${tableId}_c_${f.id}_${i}`] = null;
                });
            }
            Object.entries(row).forEach(([fieldId, value]) => {
                rowObject[`${tableId}_c_${fieldId}_${i}`] = value === '' || typeof value === 'undefined' ? null : value;
            });
            return rowObject;
        }),
    );

export default setupFieldValuesFromTable;
