import React, { useState } from 'react';
import RecentlyVisited from './RecentlyVisited';
import {
    CircularProgress,
    Typography,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    makeStyles,
} from '@material-ui/core';
import LinkButton from 'components/links/LinkButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles({
    root: {
        display: 'inline-block',
    },
    expansionPanelSummaryContent: {
        margin: '0px!important' as any,
    },
});

const renderFailure = (error, retry) => (
    <div>
        {error.status ? `${error.status} error` : 'network error'}
        <button onClick={retry}>Retry</button>
    </div>
);
const Success = props => {
    const { recentlyVisitedEntities } = props;
    const classes = useStyles(props);
    const [expanded, setExpanded] = useState(true);
    return (
        <div>
            {recentlyVisitedEntities.length > 0 ? (
                <ExpansionPanel
                    classes={{
                        root: classes.root,
                    }}
                    expanded={expanded}
                    onChange={(event, isExpanded) => {
                        setExpanded(isExpanded);
                    }}
                    elevation={0}
                    TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
                >
                    <ExpansionPanelSummary
                        classes={{
                            content: classes.expansionPanelSummaryContent,
                        }}
                        style={{ paddingBottom: 0, paddingLeft: 0, minHeight: 0 }}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography component="h2" variant="h6">
                            Recently Visited{expanded ? '' : ` (${recentlyVisitedEntities.length})`}
                        </Typography>
                        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails
                        style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 'calc(0.5em + 4px)', display: 'block' }}
                    >
                        {recentlyVisitedEntities.map((d, i) => (
                            <div key={i}>
                                <LinkButton to={`/${d.entityType}/${d.id}/show`}>{d.title}</LinkButton>
                            </div>
                        ))}
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            ) : null}
        </div>
    );
};
const renderSuccess = (recentlyVisitedEntities, refresh) => {
    return <Success recentlyVisitedEntities={recentlyVisitedEntities} refresh={refresh} />;
};
const renderLoading = () => <CircularProgress />;

interface RecentlyVisitedLinksProps {
    entityType: string;
    maxResults?: number;
}
const RecentlyVisitedLinks = ({ entityType, maxResults }: RecentlyVisitedLinksProps) => {
    return (
        // <ExpansionPanel TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}>
        //     <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        //         <Typography>Expansion Panel 1</Typography>
        //     </ExpansionPanelSummary>
        //     <ExpansionPanelDetails>
        //         <RecentlyVisited
        //             entityType={entityType}
        //             maxResults={maxResults}
        //             renderLoading={renderLoading}
        //             renderFailure={renderFailure}
        //             renderSuccess={renderSuccess}
        //         />
        //     </ExpansionPanelDetails>
        // </ExpansionPanel>
        <RecentlyVisited
            entityType={entityType}
            maxResults={maxResults}
            renderLoading={renderLoading}
            renderFailure={renderFailure}
            renderSuccess={renderSuccess}
        />
    );
};

export default RecentlyVisitedLinks;
