import * as React from 'react';
import { Component, useState } from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import pure from 'recompose/pure';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import mapProps from 'recompose/mapProps';
import { CardHeader, IconButton, Collapse, CardContent, Card, CardActions, Button } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import Close from '@material-ui/icons/Close';
import { processTasksEventCreator } from '../../actions/tasksForProcessEvent';
import { GenericEditLinkedToTaskForm as _GenericEdit } from '../../../components/generics/genericEdit';
import GenericShow from '../../../components/generics/genericShow';
import withAdjustedWidth from '../layout/withAdjustedWidth';
// import { toMmDdYyHhMi } from '../../../fieldFactory/display/components/DateTimeField';
import { withDateFormat } from '../../../fieldFactory/dateFormat/Broadcasts';
import { RootState } from '../../../reducers/rootReducer';
import ViewConfig, { ViewField } from '../../../reducers/ViewConfigType';
import { ProcessInstanceState } from '../../reducer/processInstances/processInstance';
import { adjustLinkedXToLinkedEntity } from '../../../components/generics/utils/viewConfigUtils';
import { isFieldViewField } from '../../../components/generics/utils/viewConfigUtils/getFieldProperties/viewFields';
import { getAccessLevelForEntity, allowsEdit } from '../../../components/generics/utils/viewConfigUtils';
import { withFieldFactory } from '../../../fieldFactory/Broadcasts';
// import { DataSource, Mode } from '../../../fieldFactory/FieldFactoryProvider';
import RGrid from '../../../components/generics/fields/display/RGrid';
import { fieldSubscriberHoc } from '../../../fieldFactory/display/experimental/FieldSubscriber';
import Popup from '../../../components/Popup';
import TextField from 'fieldFactory/display/components/aor/TextField';
import { fetchVariables } from './ProcessVariables/getProcessVariables';
import { push } from 'connected-react-router';
import { deleteProcessButton } from './delete';
import { closeProcessButton } from './close';
import getAppCaseByProcessAction from '../../actions/getAppCaseByProcess';
import CurrentProcessSearch from '../../components/CurrentProcessSearch';
import CommentPanel from '../layout/CommentPanel';
import { TaskLeftPanel } from '../TaskDetail/Task';
import CurrentProcessTaskSearch from '../CurrentProcessTaskSearch';
import { ContainedTaskList } from 'bpm2/TaskList';
import TogglePrintMode from '../../../components/TogglePrintMode';
import { fromNullable } from 'fp-ts/lib/Option';
import traverseGetData from 'casetivity-shared-js/lib/viewConfigSchema/traverseGetData';
import ValuesetSelect from 'fieldFactory/input/components/ValueSelectDownshift';
import { getProcessInstance as getProcessInstanceAction } from 'bpm/processInstance/actions';
import ProcessInstanceDisplayStatus from 'bpm/processInstance/components/Status';
import Toolbar from 'components/generics/form/Toolbar.aor';
import CloseButton from 'fieldFactory/popovers/PopoverCloseButton';
import SaveButton from 'components/generics/button/SaveButton';
import { Helmet } from 'react-helmet';
import casetivityViewContext from 'util/casetivityViewContext';
import { storageController } from 'storage';
import useHasHideProcessHeader from 'bpm/hooks/useHasHideProcessHeader';

interface ProcessPageRefresh {
    key: number;
    refresh: () => void;
}

export const processPageRefreshContext = React.createContext<ProcessPageRefresh>({
    key: 0,
    refresh: () => {
        console.error('ProcessPage refresh() called outside of refresh context');
    },
});
const ProcessPageRefreshContextProvider = props => {
    const [key, setKey] = React.useState(1);
    const refresh = React.useCallback(() => {
        setKey(key + 1);
    }, [key, setKey]);
    return (
        <processPageRefreshContext.Provider key={key} value={{ key, refresh }}>
            {props.children}
        </processPageRefreshContext.Provider>
    );
};
const withProcessPageRefreshContext = BaseComponent => props => {
    return (
        <ProcessPageRefreshContextProvider>
            <BaseComponent {...props} />
        </ProcessPageRefreshContextProvider>
    );
};

const SubscribedTextField = fieldSubscriberHoc(TextField);

const WithKey = (props: { children: (args: { key: number; incKey: () => void }) => JSX.Element | null }) => {
    const [key, setKey] = useState(1);
    return props.children({
        key,
        incKey: () => {
            setTimeout(() => setKey(key + 1), 0);
        },
    });
};

const GenericEdit = pure(_GenericEdit);
const config = require('../../../config.js');

const HeaderItem: React.SFC<{
    exp: string;
    label: string | null;
    row?: number;
    column?: number;
    span?: number;
}> = props => (
    <div
        style={
            {
                /* whiteSpace: 'nowrap' */
            }
        }
        key={props.exp}
    >
        <b style={{ float: 'left', marginRight: '.5em' }}>{props.label}: </b>
        <div>{props.children}</div>
        <div style={{ clear: 'both' }} />
    </div>
);

const ENTITY_DISPLAY_CONF = {
    dataSource: 'Entity', // DataSource.ENTITY,
    mode: 'Display', // Mode.DISPLAY,
    validate: false,
    connected: false,
    options: {
        getOwnData: true,
    },
};
// const PIP = 'processInstance.';

function WithCloseReason(props: {
    children: (arg: { reason: string; setReason: (reson: string) => void }) => JSX.Element | null;
}) {
    const [reason, setReason] = useState('');
    return props.children({ reason, setReason });
}

const getHeaderFields = (
    headerFields: ProcessPageProps['headerFields'],
    fieldFactory: ProcessPageProps['fieldFactory'],
    appCase: ProcessPageProps['appCase'],
) => {
    return (
        headerFields &&
        sortBy(Object.entries(headerFields), '[1].order').map(([exp, field]) => {
            const createRow = fieldElem => (
                <HeaderItem
                    exp={exp}
                    label={fieldElem.props.label}
                    row={field.row}
                    column={field.column}
                    span={field.span}
                >
                    {fieldElem}
                </HeaderItem>
            );

            const create = expandedRecord =>
                fieldFactory(ENTITY_DISPLAY_CONF)({
                    record: expandedRecord,
                    resource: 'AppCase',
                    basePath: '/AppCase',
                    match: {
                        isExact: true,
                        params: {
                            id: appCase.id,
                            basePath: '/AppCase',
                        },
                    },
                })([
                    {
                        ...field,
                        field: isFieldViewField(field)
                            ? adjustLinkedXToLinkedEntity(field.field)
                            : (field as any).field, // tslint:disable-line
                    },
                ])[0];

            return createRow(create(appCase));
        })
    );
};

interface ProcessPageProps {
    hideProcessHeader?: boolean;
    readOnly?: boolean;
    showLinkedEntity?: boolean;
    headerFields?: { [exp: string]: ViewField };
    formatDate: (date?: string | null) => string;
    taskId?: string;
    width: number;
    roles: string[];
    processId?: string;
    getProcessInstance: Function;
    getAppCaseByProcess: (processId: string, businessKey: string) => void;
    appCase: {
        id: number;
        caseNumber: string;
        linkedEntityType: string;
        linkedEntityId: string;
        title: string;
        linkedEntity: {
            title: string;
        };
    };
    processInstance: ProcessInstanceState;
    processDefinitionsById: RootState['bpm']['processDefinitions']['byId'];
    processDefinition: RootState['bpm']['processDefinitions']['byId'][0];
    overrideLinkedEntity?: { entityType?: string; id?: string; displayed?: boolean } | null;
    overrideLinkedEntityId?: string;
    overrideLinkedEntityType?: string;
    overrideLinkedEntityDisplayed?: boolean;
    viewConfig: ViewConfig;
    fieldFactory: Function;
    redirect: Function;
}
interface ProcessPageState {
    completedTasks: {}[];
    uploadedFiles: {}[];
    processDiagram?: string;
    diagramExpanded: boolean;
    diagramLoaded: boolean;
}
interface LinkedEntityProps {
    match: {
        isExact: true;
        params: {
            id: string | number;
            basePath: string;
        };
    };
    location: {
        pathname: string;
    };
    key?: string;
}
const _getLinkedEntityProps = (relatedEntityName: string, relatedEntityId: string): LinkedEntityProps => {
    const id = relatedEntityId;
    const type = relatedEntityName;
    return {
        match: {
            isExact: true,
            params: {
                id,
                basePath: type,
            },
        },
        location: {
            pathname: `/${type}/${id}`,
        },
        key: `${type}:${id}`,
    };
};

class ProcessPage extends Component<ProcessPageProps, ProcessPageState> {
    static defaultProps = {
        taskId: null,
        offCenterSplit: true,
        width: null,
        leftPanelContent: null,
        rightPanelContent: null,
        appCase: undefined,
        processInstance: undefined,
    };

    constructor(props: ProcessPageProps) {
        super(props);
        this.state = {
            completedTasks: [],
            uploadedFiles: [],
            diagramExpanded: false,
            diagramLoaded: false,
        };
    }

    forceNoLinkedEntityArea = () => {
        const { overrideLinkedEntity, overrideLinkedEntityDisplayed } = this.props;
        return (overrideLinkedEntity && overrideLinkedEntity.displayed === false) || !overrideLinkedEntityDisplayed;
    };

    getRelatedEntityName = () => {
        const { appCase, overrideLinkedEntityType, overrideLinkedEntity } = this.props;
        return (
            overrideLinkedEntityType ||
            fromNullable(overrideLinkedEntity)
                .mapNullable(le => le.entityType)
                .getOrElse(
                    fromNullable(appCase)
                        .mapNullable(ac => ac.linkedEntityType)
                        .getOrElse(undefined),
                )
        );
    };
    getRelatedEntityId = () => {
        const { appCase, overrideLinkedEntityId, overrideLinkedEntity } = this.props;
        return (
            overrideLinkedEntityId ||
            fromNullable(overrideLinkedEntity)
                .mapNullable(le => le.id)
                .getOrElse(
                    fromNullable(appCase)
                        .mapNullable(ac => ac.linkedEntityId)
                        .getOrElse(undefined),
                )
        );
    };

    getLinkedEntityProps = () => {
        return _getLinkedEntityProps(this.getRelatedEntityName(), this.getRelatedEntityId());
    };

    componentWillReceiveProps(nextProps: ProcessPageProps) {
        const { processId, processInstance } = nextProps;
        if (processId !== this.props.processId) {
            this.props.getProcessInstance(processId);
        }
        if (
            processId &&
            processInstance &&
            processInstance.businessKey &&
            (!this.props.processInstance || this.props.processInstance.businessKey !== processInstance.businessKey)
        ) {
            this.props.getAppCaseByProcess(processId, processInstance.businessKey);
        }
    }

    componentDidMount() {
        const { processId, processInstance } = this.props;
        if (processId) {
            this.props.getProcessInstance(processId);
            if (processInstance && processInstance.businessKey) {
                this.props.getAppCaseByProcess(processId, processInstance.businessKey);
            }
        }
    }

    getLinkedEntityViewName = (entityType: string) => {
        const type = this.hasEditPermission(entityType) ? 'EDIT' : 'SHOW';
        return this.props.viewConfig.entities[entityType].defaultViews[type].name;
    };
    hasEditPermission = (entityType: string) => {
        return allowsEdit(getAccessLevelForEntity(this.props.viewConfig, entityType));
    };
    renderLinkedEntity = ({
        viewName,
        ...options
    }: {
        viewName?: string;
        actions?: JSX.Element | null;
        toolbar?: JSX.Element | null;
    } = {}) => {
        const getOptions = (entityType: string) => {
            return this.hasEditPermission(entityType) ? options : {};
        };
        const { appCase, overrideLinkedEntity, overrideLinkedEntityDisplayed, taskId } = this.props;
        const sharedProps = {
            noRedirectOnIdChange: true,
            taskId,
            redirect: false,
            hasList: false,
            ...this.getLinkedEntityProps(),
        };

        if (overrideLinkedEntity) {
            const { displayed, entityType, id: overriddenId } = overrideLinkedEntity;
            if (displayed === false || !overrideLinkedEntityDisplayed) {
                return <div />;
            } else if (entityType && overriddenId) {
                const genericProps = {
                    viewName: viewName || this.getLinkedEntityViewName(entityType),
                    resource: entityType,
                    ...getOptions(entityType),
                    ...sharedProps,
                };
                if (this.hasEditPermission(entityType)) {
                    return (
                        <WithKey key={genericProps.key}>
                            {({ key, incKey }) => (
                                <GenericEdit
                                    hasShow={false}
                                    hasDelete={false}
                                    {...genericProps}
                                    key={key}
                                    onSaveCb={incKey}
                                />
                            )}
                        </WithKey>
                    );
                }
                return <GenericShow {...genericProps} />;
            }
        } else {
            if (!overrideLinkedEntityDisplayed) {
                return <div />;
            }
        }
        const res = this.getRelatedEntityName();
        const id = this.getRelatedEntityId();
        if (res && id) {
            const genericP = {
                viewName: viewName || this.getLinkedEntityViewName(res),
                resource: res,
                ...getOptions(res),
                ...sharedProps,
            };
            return (
                !overrideLinkedEntity &&
                appCase &&
                (this.hasEditPermission(res) ? (
                    <WithKey key={genericP.key}>
                        {({ key, incKey }) => (
                            <GenericEdit hasShow={false} hasDelete={false} {...genericP} onSaveCb={incKey} key={key} />
                        )}
                    </WithKey>
                ) : (
                    <GenericShow {...genericP} />
                ))
            );
        }
        return null;
    };
    renderDefaultSummary() {
        const { appCase, processInstance, formatDate, processDefinitionsById } = this.props;
        const relRes = this.getRelatedEntityName();
        const relEId = this.getRelatedEntityId();
        const caseType =
            processInstance &&
            processInstance.businessKey &&
            fromNullable(processDefinitionsById[processInstance.businessKey])
                .mapNullable(pd => pd.name)
                .getOrElse(undefined);

        const pEnded = processInstance && processInstance.endTime;
        return (
            <div>
                <b>Case Type: </b> {caseType}
                <br />
                <b>Case Number: </b> {appCase.caseNumber}
                <br />
                {relRes && relEId && (
                    <span>
                        <b>{relRes}:</b>{' '}
                        <SubscribedTextField source="linkedEntity.title" record={appCase} resource="AppCase" />
                        <br />
                    </span>
                )}
                <b>Case Started: </b>{' '}
                {processInstance && (processInstance.startTime ? formatDate(processInstance.startTime) : 'New')}
                <br />
                {pEnded && <b>Case Ended: </b>}
                {formatDate(pEnded)}
                {pEnded && <br />}
            </div>
        );
    }
    renderSummary() {
        const { appCase, headerFields, fieldFactory } = this.props;
        if (!appCase) {
            return null;
        }
        return (
            <div style={{ fontSize: 16, lineHeight: '150%', marginBottom: 16 }}>
                {headerFields ? (
                    <RGrid
                        rowMargin="none"
                        flexStart={true}
                        fields={getHeaderFields(headerFields, fieldFactory, appCase)}
                    />
                ) : (
                    this.renderDefaultSummary()
                )}
            </div>
        );
    }

    handleExpandDiagram() {
        if (!this.state.diagramLoaded) {
            fetch(`${config.BACKEND_BASE_URL}api/bpm/process-instances/${this.props.processId}/diagram`, {
                method: 'GET',
                credentials: 'same-origin',
                headers: new Headers({
                    Authorization: `Bearer ${storageController.getToken()}`,
                    Cookie: `${window.document.cookie}`,
                }),
            }).then(response => {
                if (response && response.status !== 404 && response.status !== 500) {
                    response.arrayBuffer().then(buffer => {
                        const base64Flag = 'data:image/jpeg;base64,';
                        const imageStr = arrayBufferToBase64(buffer);
                        this.setState({
                            processDiagram: base64Flag + imageStr,
                        });
                    });
                }
            });
            this.setState({ diagramLoaded: true });
        }
        this.setState({ diagramExpanded: !this.state.diagramExpanded });
    }
    isAdmin() {
        const { processId, processInstance, processDefinitionsById } = this.props;
        return (
            processId &&
            processInstance &&
            processInstance.businessKey &&
            processDefinitionsById[processInstance.businessKey] &&
            processDefinitionsById[processInstance.businessKey].adminUser
        );
    }
    renderProcessVariablesPopup() {
        const { processId, roles = [] } = this.props;
        if (!processId || !this.isAdmin() || roles.indexOf('ROLE_SUPER') === -1) {
            return null;
        }
        return (
            <Popup
                renderToggler={({ openDialog }) => (
                    <Button variant="outlined" onClick={openDialog()} style={{ marginRight: 15 }}>
                        Process Variables &nbsp;
                        <Edit />
                    </Button>
                )}
                renderDialogContent={({ closeDialog }) => (
                    <Card>
                        <CardContent>{fetchVariables(processId)()}</CardContent>
                        <CardActions>
                            <Button onClick={closeDialog}>Close</Button>
                        </CardActions>
                    </Card>
                )}
            />
        );
    }
    renderAppCasePopup() {
        const { processId, appCase } = this.props;
        if (!processId || !appCase || !this.isAdmin() || !this.hasEditPermission('AppCase')) {
            return null;
        }
        return (
            <Popup
                renderToggler={({ openDialog }) => (
                    <Button variant="outlined" onClick={openDialog()} style={{ marginRight: 15 }}>
                        Case &nbsp;
                        <Edit />
                    </Button>
                )}
                renderDialogContent={({ closeDialog }) => (
                    <GenericEdit
                        viewName="AppCaseEdit"
                        formId="appCasePopoverEdit"
                        redirect={false}
                        hasList={false}
                        location={{ pathname: `AppCase/${appCase.id}` }}
                        match={{
                            isExact: true,
                            params: {
                                id: appCase.id,
                                basePath: '/AppCase',
                            },
                        }}
                        resource="AppCase"
                        onSaveCb={closeDialog}
                        name="AppCase"
                        toolbar={
                            <Toolbar>
                                <CloseButton handleClose={closeDialog} />
                                <SaveButton />
                            </Toolbar>
                        }
                    />
                )}
            />
        );
    }
    renderCloseProcessPopup() {
        const { processId, appCase, processInstance } = this.props;
        if (!processId || !appCase || !this.isAdmin() || !processInstance || processInstance.endTime) {
            return null;
        }
        return (
            <Popup
                paperStyle={{ overflowY: 'visible' }}
                renderToggler={({ openDialog }) => (
                    <Button variant="outlined" onClick={openDialog()} style={{ marginRight: 15 }}>
                        Close Case &nbsp;
                        <Close />
                    </Button>
                )}
                renderDialogContent={({ closeDialog }) => (
                    <WithCloseReason>
                        {({ reason: reasonId, setReason }) => (
                            <Card style={{ minWidth: '40vw', overflowY: 'visible' }}>
                                <CardContent>
                                    Close Case?
                                    <br />
                                    <div style={{ height: '1em' }} />
                                    <div>
                                        <ValuesetSelect
                                            meta={{}}
                                            source="foo"
                                            valueSet="CaseCloseReason"
                                            input={{
                                                onBlur: v => {
                                                    if (typeof v !== 'undefined') {
                                                        setReason(v);
                                                    }
                                                },
                                                value: reasonId,
                                            }}
                                            label="Reason"
                                            shouldFetchValueset={true}
                                            isRequired={true}
                                        />
                                    </div>
                                </CardContent>
                                <CardActions>
                                    <div>
                                        <Button onClick={closeDialog} style={{ marginRight: 15 }}>
                                            Return
                                        </Button>
                                        {reasonId ? (
                                            <processPageRefreshContext.Consumer>
                                                {({ refresh }) => (
                                                    <CurrentProcessSearch
                                                        render={({ currentSearch }) =>
                                                            closeProcessButton(processId, reasonId)(() => {
                                                                // just in case we want to redirect on close.
                                                                // Asked to be disabled in
                                                                // https://strategicsolutionsgroup.atlassian.net/browse/FISH-2894
                                                                // tihs.props.redirect(`/processes${currentSearch || ''}`),
                                                                refresh();
                                                            })
                                                        }
                                                    />
                                                )}
                                            </processPageRefreshContext.Consumer>
                                        ) : (
                                            <Button
                                                style={{ marginLeft: 'auto' }}
                                                variant="contained"
                                                color="primary"
                                                disabled={true}
                                            >
                                                Close Process
                                            </Button>
                                        )}
                                    </div>
                                </CardActions>
                            </Card>
                        )}
                    </WithCloseReason>
                )}
            />
        );
    }
    renderDeleteProcessPopup() {
        const { processId, appCase, redirect, processInstance } = this.props;
        if (!processId || !appCase || !this.isAdmin() || !processInstance || !processInstance.endTime) {
            return null;
        }
        return (
            <Popup
                renderToggler={({ openDialog }) => (
                    <Button style={{ marginRight: 15 }} variant="outlined" onClick={openDialog()}>
                        Delete Case &nbsp;
                        <Delete />
                    </Button>
                )}
                renderDialogContent={({ closeDialog }) => (
                    <Card>
                        <CardContent>Delete process?</CardContent>
                        <CardActions>
                            <Button onClick={closeDialog}>Close</Button>
                            <CurrentProcessSearch
                                render={({ currentSearch }) =>
                                    deleteProcessButton(processId)(() => redirect(`/processes${currentSearch || ''}`))
                                }
                            />
                        </CardActions>
                    </Card>
                )}
            />
        );
    }
    renderPage = () => {
        const {
            processId,
            width,
            taskId,
            showLinkedEntity = true,
            processDefinition,
            readOnly,
            hideProcessHeader,
        } = this.props;
        if (!processId || width === null) {
            return null;
        }
        return (
            <div>
                {!hideProcessHeader && (
                    <Card>
                        <CardContent>
                            {this.renderSummary()}
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                {this.renderProcessVariablesPopup()}
                                {this.renderAppCasePopup()}
                                {this.renderDeleteProcessPopup()}
                                {this.renderCloseProcessPopup()}
                                <TogglePrintMode />
                            </div>
                        </CardContent>

                        {processDefinition && processDefinition.processDiagramUser && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: 'inherit',
                                    flexDirection: 'column',
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '`100`%',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <CardHeader
                                        style={{ paddingTop: 5, paddingLeft: 0, paddingRight: 0 }}
                                        disableTypography={true}
                                        title={<h1>Process Diagram</h1>}
                                    />
                                    <IconButton
                                        aria-label="expand process diagram"
                                        onClick={() => this.handleExpandDiagram()}
                                    >
                                        <ExpandMoreIcon />
                                    </IconButton>
                                </div>
                                <Collapse
                                    in={this.state.diagramExpanded}
                                    style={!this.state.diagramExpanded ? { width: 0 } : {}}
                                >
                                    <CardContent>
                                        {this.state.processDiagram && (
                                            <img
                                                src={this.state.processDiagram}
                                                alt="Process diagram"
                                                style={{
                                                    display: 'block',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto',
                                                    maxWidth: '100%',
                                                }}
                                            />
                                        )}
                                    </CardContent>
                                </Collapse>
                            </div>
                        )}
                    </Card>
                )}
                {!hideProcessHeader && <div style={{ marginTop: '1em' }} />}
                <CommentPanel taskId={taskId} processId={processId} />
                {taskId ? (
                    <TaskLeftPanel
                        taskId={taskId}
                        forceNoLinkedEntityArea={this.forceNoLinkedEntityArea()}
                        processId={processId}
                        relatedEntityResource={this.getRelatedEntityName()}
                        relatedEntityId={this.getRelatedEntityId()}
                        renderLinkedEntity={showLinkedEntity && this.renderLinkedEntity}
                    />
                ) : (
                    <React.Fragment>
                        <Helmet>
                            <title>Case{this.props.appCase ? `: ${this.props.appCase.title}` : ''}</title>
                        </Helmet>
                        <CurrentProcessTaskSearch
                            processId={processId}
                            render={({ currentSearch }) => (
                                <ContainedTaskList
                                    initialSearch={currentSearch}
                                    readOnly={readOnly}
                                    processId={processId}
                                    requireProcessId={true}
                                    overrideViewName="_TASK_LIST_FOR_PROCESS"
                                />
                            )}
                        />
                        <div style={{ marginTop: '1em' }} key={`_process:${processId}_task:${taskId}`}>
                            {showLinkedEntity && this.renderLinkedEntity()}
                        </div>
                    </React.Fragment>
                )}
            </div>
        );
    };
    render() {
        const { processId, processInstance, taskId } = this.props;
        return (
            <casetivityViewContext.Provider value={taskId ? 'PROCESS_TASK' : 'PROCESS'}>
                <ProcessInstanceDisplayStatus
                    id={processId}
                    showSuccessOffline={!!processInstance}
                    renderSuccess={this.renderPage}
                />
            </casetivityViewContext.Provider>
        );
    }
}

/*
    This component is not getting its width from Redux!
    Why:
    This page will be embedded in a <ToggleDrawerWrapper> component.
    Since we want to behave differently according to whether the Drawer is toggled open or not,
    width will be passed as a prop from ToggleDrawerWrapper (or other parent.)

    E.g. if Width == 2, but the Drawer is open, ToggleDrawerWrapper may pass a width of 1 instead of 2.
*/

function arrayBufferToBase64(buffer: ArrayBuffer) {
    let binary = '';
    const bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach(b => {
        binary += String.fromCharCode(b);
    });
    return window.btoa(binary);
}

const appCaseEntities = state => state.admin.entities.AppCase;
const processIdSel = (state, ownProps) => ownProps.processId;

const makeMapStateToProps = () => {
    const appCaseSelector = createSelector(
        appCaseEntities,
        processIdSel,
        // eventually can just build inverted indexes for things like this
        (appCaseEnt: RootState['admin']['entities'][0], processId): undefined | { id: string } =>
            Object.values(appCaseEnt || {}).filter((ac: any) => ac && ac.processInstanceId === processId)[0], // tslint:disable-line
    );

    const displayLinkedEntityForCurrentTaskForm = (TaskInstance, taskForms) => {
        if (TaskInstance && !isEmpty(taskForms)) {
            const taskForm: any = Object.values(taskForms) // tslint:disable-line
                .filter((form: any) => form.key === TaskInstance.formKey)[0]; // tslint:disable-line
            if (taskForm && taskForm.fields) {
                const hideLinkedEntity = taskForm.fields.filter(field => field.id === 'hideLinkedEntity');
                return hideLinkedEntity.length === 0;
            }
        }

        return true;
    };

    const getTaskInstance = (
        {
            admin: {
                entities: { TaskInstance },
            },
        }: RootState,
        props,
    ) => TaskInstance && TaskInstance[props.taskId];
    const getTaskLinkedEntity = createSelector(
        getTaskInstance,
        (state: RootState, props) => state.admin.entities,
        (state: RootState, props) => state.viewConfig,
        (TaskInstance, entities, viewConfig) => {
            return traverseGetData(viewConfig, 'linkedEntity', TaskInstance, entities, false);
        },
    );
    const overrideLinkedEntitySelector = createSelector(
        getTaskInstance,
        getTaskLinkedEntity,
        (state: RootState) => state.taskForms || [],
        (TaskInstance, TaskLinkedEntity, taskForms) => {
            const displayLinkedEntity = displayLinkedEntityForCurrentTaskForm(TaskInstance, taskForms);
            const overrideLinkedEntity = TaskLinkedEntity.getOrElse(undefined);
            const overrideLinkedEntityType = TaskLinkedEntity.map(e => e.linkedEntityType).getOrElse(undefined);
            const overrideLinkedEntityId = TaskLinkedEntity.map(e => e.linkedEntityId).getOrElse(undefined);
            return {
                overrideLinkedEntity,
                overrideLinkedEntityId,
                overrideLinkedEntityType,
                overrideLinkedEntityDisplayed: displayLinkedEntity,
            };
        },
    );
    const emptyArr = [];
    const mapStateToProps = (state: RootState, ownProps) => {
        /*
        const processInstance: ProcessInstance | undefined =
            ((state.admin.entities || {}).ProcessInstance || {})[ownProps.processId] as ProcessInstance | undefined;
        */
        const processInstance = state.bpm.processInstances.byId[ownProps.processId];
        const headerFields: { [field: string]: ViewField } | undefined = fromNullable(state.viewConfig.processes)
            .chain(p =>
                fromNullable(processInstance)
                    .mapNullable(pi => pi.businessKey)
                    .mapNullable(bk => p[bk]),
            )
            .mapNullable(pdc => pdc.views)
            .mapNullable(pde => pde.SUMMARY)
            .mapNullable(e => e.headers)
            .getOrElse(undefined);
        const roles: string[] = fromNullable(state.viewConfig)
            .mapNullable(vc => vc.user)
            .mapNullable(u => u.roles)
            .getOrElse(emptyArr);
        const processDefinitionsById = state.bpm.processDefinitions.byId;
        return {
            processInstance,
            headerFields,
            processDefinitionsById,
            processDefinition:
                processInstance && processInstance.businessKey
                    ? processDefinitionsById[processInstance.businessKey]
                    : undefined,
            appCase: appCaseSelector(state, ownProps),
            viewConfig: state.viewConfig,
            ...overrideLinkedEntitySelector(state, ownProps),
            roles,
        };
    };
    return mapStateToProps;
};
const enhance = compose(
    withProcessPageRefreshContext,
    connect(
        makeMapStateToProps,
        {
            getTasksForProcess: processTasksEventCreator.getTasksForProcess,
            getProcessInstance: getProcessInstanceAction,
            redirect: push,
            getAppCaseByProcess: getAppCaseByProcessAction,
        },
    ),
    mapProps(props => ({
        // map appCase to the props getLinkedEntity takes in order to resolve the linked entity
        ...props,
        linkedEntityType: props.appCase && props.appCase.linkedEntityType,
        linkedEntityId: props.appCase && props.appCase.linkedEntityId,
    })),
    withAdjustedWidth,
    withDateFormat,
    pure,
    withFieldFactory,
    BaseComponent => props => {
        const hideProcessHeader = useHasHideProcessHeader();
        return <BaseComponent {...props} hideProcessHeader={hideProcessHeader} />;
    },
);
export default enhance(ProcessPage);
