import ViewConfig, { View } from '../../../../reducers/ViewConfigType';
import { fromNullable, some, none, option, Option } from 'fp-ts/lib/Option';
import { tryCatch } from 'fp-ts/lib/Either';
import {
    isFieldViewField,
    getAdjustedFieldSource,
    isRefOneField,
} from '../../../../components/generics/utils/viewConfigUtils';
import { getAdhocFieldsForView } from '../EntityFormContext/util/getAllFieldsExpected';
import traverseGetData from 'casetivity-shared-js/lib/viewConfigSchema/traverseGetData';
import { traverse, mapOption } from 'fp-ts/lib/Array';
import { identity } from 'fp-ts/lib/function';
import { unflatten } from 'flat';
import uniq from 'lodash/uniq';
import getFieldPathsAlongPath from './getAllPathsAlongPath';

const getViewTupleFromView = (v: View) => {
    const fields: View['fields'] = Object.assign({}, v.fields, ...Object.values(v.tabs || {}).map(t => t.fields));
    return [v, fields] as [typeof v, typeof fields];
};
type ViewTuple = ReturnType<typeof getViewTupleFromView>;

/*
    In a view we need some:
    1. basic flat info
        (id, entityVersion, (entityType?))* DONE
    2. fieldsRequired by the view DONE
    3. fields Required ad-hoc by fields (Address + FileUpload) DONE

    4. validation fields required, <- lookup HOOK ADDED
    5. visibility fields required, <- lookup HOOK ADDED
    6. concept availability fields required, <- lookup HOOK ADDED

    TODO:
    use getFormInitial and pass in expression fields above. DONE
    for validation we will:
        0. see what fields we need based on 'expressions'
            (viewConfig, pathsRequired) => [
                path to update on,
                specific data path that needs to be updated based on reference change, <- can memoize based on this
            ]
            (pathToUpdateOn, updatedDataPath) => lookupData (constant time),
            insert into nested object structure, before merge with existing

    // dirty fields that are Id changes/refone changes
    // look up [path, entityData, pathToExpandOnData]

    [allPathsUsed, fieldPathsToIdsThatChange, theData] => {
        map fieldPathsToIdsThatChange to [fieldPath, get([allPathsUsed, theData[valueOfId]])]

    }
    merge(
        base expansions,
        looked up expansions (overwrite old),
        overwrite specific dirty field paths that are specific pieces of data
    )

*/

const alwaysExpandOnBase = ['id', 'entityVersion', 'entityType', 'title', 'hasPossibleMatches'];

const getPathValuePairs = (
    viewConfig: ViewConfig,
    entityType: string,
    entityId: string,
    fieldPaths: string[],
    entities: {},
): [string, unknown][] => {
    const ret = mapOption(
        fieldPaths,
        (fieldPath): Option<[string, unknown]> =>
            tryCatch(() =>
                traverseGetData(viewConfig, fieldPath, { entityType, id: entityId }, entities, false).fold(none, v =>
                    some<[string, unknown]>([fieldPath, v]),
                ),
            ).fold(e => {
                console.log(e); // tslint:disable-line
                return none;
            }, identity),
    );
    return ret;
};

const fieldValuesToObj = (representation: 'FLAT' | 'EXPANDED' = 'EXPANDED') => (fieldValues: [string, unknown][]) => {
    const flatObj = Object.assign({}, ...fieldValues.map(([path, v]) => ({ [path]: v })));
    return representation === 'FLAT' ? flatObj : unflatten(flatObj);
};

export const getDataWithExpansion = (representation: 'FLAT' | 'EXPANDED') => (
    viewConfig: ViewConfig,
    entityType: string,
    entityId: string,
    fieldPaths: string[],
    entities: {},
) => fieldValuesToObj(representation)(getPathValuePairs(viewConfig, entityType, entityId, fieldPaths, entities));

/*
    e.g. references to objects we don't want.
    Only the data we actually need.
    for now, map reference objects to
    .entityVersion
    .entityId
    .entityType
*/
const getViewO = (viewConfig: ViewConfig, viewName: string) => {
    return fromNullable(viewConfig)
        .map(vc => vc.views[viewName])
        .chain(fromNullable);
};

export const mapFieldPathsToFieldsNecessary = (viewConfig: ViewConfig, viewName: string, fieldPaths: string[]) => {
    return getViewO(viewConfig, viewName)
        .map(getViewTupleFromView)
        .map(([view, fields]) => {
            return fieldPaths.flatMap(fp => {
                const refOnesAlongPaths = getFieldPathsAlongPath(fp, 'EXCLUDE_ORIGINAL').flatMap(subPath => {
                    try {
                        if (isRefOneField(viewConfig, view.entity, subPath, 'TRAVERSE_PATH')) {
                            return [`${subPath}.id`, `${subPath}.entityVersion`, `${subPath}.entityType`];
                        }
                    } catch (e) {
                        return [];
                    }
                    return [];
                });
                // we have to construct the correct ids an entityVersions for the path
                try {
                    if (isRefOneField(viewConfig, view.entity, fp, 'TRAVERSE_PATH')) {
                        return refOnesAlongPaths;
                    }
                } catch (e) {
                    return [fp, ...refOnesAlongPaths];
                }
                return [fp, ...refOnesAlongPaths];
            });
        })
        .getOrElse([]);
};
const getFormInitial = (
    viewConfig: ViewConfig,
    viewName: string,
    expressionFieldsRequired: string[],
    entities: {},
    entityId: string,
    representation: 'FLAT' | 'EXPANDED' = 'EXPANDED',
) => {
    const viewO = getViewO(viewConfig, viewName);
    const recordEntry = viewO
        .map(v => entities[v.entity])
        .chain(fromNullable)
        .map(e => e[entityId])
        .chain(fromNullable);
    if (recordEntry.isNone()) {
        return undefined;
    }
    const fields: [string, unknown][] = viewO
        .map(getViewTupleFromView)
        .chain(d => traverse(option)(d, fromNullable) as Option<ViewTuple>)
        .map(([view, flds]) => {
            const fieldPaths = Object.values(flds).flatMap(f => {
                if (isFieldViewField(f)) {
                    return [getAdjustedFieldSource(viewConfig)(view)(f)];
                }
                return [];
            });
            const allPaths = [...fieldPaths, ...expressionFieldsRequired, ...alwaysExpandOnBase];
            return [view, mapFieldPathsToFieldsNecessary(viewConfig, viewName, allPaths)] as [
                typeof view,
                typeof fieldPaths,
            ];
        })
        .map(
            ([view, fieldPaths]) =>
                [view, uniq([...fieldPaths, ...getAdhocFieldsForView(view)])] as [typeof view, string[]],
        )
        .map(([view, fieldPaths]) => getPathValuePairs(viewConfig, view.entity, entityId, fieldPaths, entities))
        .getOrElse([]);
    return fieldValuesToObj(representation)(fields);
};
export default getFormInitial;
