import { fetchJson } from 'util/fetchUtils';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import { API_URL } from '../config';
import buildFetchOptions from './util/buildFetchOptions';
import { StartupEventType } from '../actions/StartupEvent';
import { storageController } from 'storage';

export function* getBasicInfo() {
    try {
        let { body } = yield call(fetchJson, `${API_URL}basicInfo`, buildFetchOptions());
        body = JSON.parse(body);
        if (body.title) {
            document.title = body.title;
        }
        const pathname = yield select(state => state.router.location.pathname);
        if (
            body.loginRedirectUrl &&
            !storageController.getToken() &&
            process.env.NODE_ENV !== 'development' &&
            pathname !== '/reset/finish'
        ) {
            window.location.href = body.loginRedirectUrl;
        }
        yield put({ type: StartupEventType.LoadedBasicInfoSuccess, body });
    } catch (e) {
        console.log(e);
    }
}

export default function* basicAppInfoSaga() {
    yield takeEvery(StartupEventType.GetBasicInfo, function*(action) {
        yield call(getBasicInfo, action);
    });
}
