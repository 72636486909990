export interface ViewEditableExps {
    [viewName: string]: {
        [fieldName: string]: {
            valuesetFieldsRequired: any; //tslint:disable-line
            expression: string;
            fieldName: string;
            valuesetLiterals: string[];
            fieldsRequired: string[];
        }[];
    };
}
export default (previousState: ViewEditableExps = {}, { type, payload }): ViewEditableExps => {
    if (type === 'ENTITY_EDITIBILITY_GENERATED') {
        return payload;
    }
    return previousState;
};
