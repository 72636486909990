import { LOAD_DASHBOARD_CONFIGS, LOAD_DASHBOARD_CONFIGS_FAILURE, LOAD_DASHBOARD_CONFIGS_SUCCESS } from './constants';
import { createCustomAction } from 'typesafe-actions';
import { Dashboard } from '../types';

export const loadAllDashboards = createCustomAction(LOAD_DASHBOARD_CONFIGS, type => {
    return () => ({ type });
});
export const loadAllDashboardsSuccess = createCustomAction(LOAD_DASHBOARD_CONFIGS_SUCCESS, type => {
    return (payload: Dashboard[]) => ({ type, payload });
});
export const loadAllDashboardFailure = createCustomAction(LOAD_DASHBOARD_CONFIGS_FAILURE, type => {
    return (error: Error) => ({ type, error });
});
