import * as React from 'react';
import { createSelector } from 'reselect';
import compose from 'recompose/compose';
import mapProps from 'recompose/mapProps';
import { connect } from 'react-redux';
import { RootState } from '../../../../reducers/rootReducer';
import { formContext } from './FormContext';
import { flowablePreprocessValuesForEval } from '../../../../expressions/formValidation';
import { memoizedTraverseEval } from '../../../../expressions/expressionArrays';
import { alertOnceOutcome } from './alertOnce';
import { Theme, withTheme, WithTheme } from '@material-ui/core';
import { asyncEventsInProgressContext } from '../asyncEventCountContext';
import stableStringify from 'fast-json-stable-stringify';
import { createGetEntities } from 'components/generics/form/EntityFormContext/util/getEntities';
import { evaluateContext2 } from 'expressions/CachingEvaluator/FormContextEvaluator';
import { Subtract } from 'utility-types';
import { ButtonProps } from '@material-ui/core/Button';
import memoize from 'lodash/memoize';
import { evaluateExpression } from 'casetivity-shared-js/lib/spel/evaluate';

const and = (prev, curr) => prev && curr;

export type CreateCompleteButton = (
    label: string,
    outcome?: string,
    forceDisabled?: boolean,
    ButtonProps?: ButtonProps,
) => JSX.Element;

interface OutcomesProps {
    formDefinition: null | RootState['taskForms'][0];
    entityFormHasErrors?: boolean;
    taskLoaded?: boolean;
    taskOutcome?: string;
    createButton: CreateCompleteButton;
}

interface InjectedProps {
    fieldValues: ReturnType<typeof evaluateContext2>['fieldValues'];
}
const withFormContextFieldValues = <BaseProps extends InjectedProps>(
    _BaseComponent: React.ComponentType<BaseProps>,
) => {
    // fix for TypeScript issues: https://github.com/piotrwitek/react-redux-typescript-guide/issues/111
    const BaseComponent = _BaseComponent as React.ComponentType<InjectedProps>;
    type HocProps = Subtract<BaseProps, InjectedProps>;
    return class Hoc extends React.Component<HocProps> {
        static displayName = `withState(${BaseComponent.name})`;
        static readonly WrappedComponent = BaseComponent;
        render() {
            return (
                <formContext.Consumer>
                    {({ fieldValues }) => {
                        return <BaseComponent {...this.props} fieldValues={fieldValues} />;
                    }}
                </formContext.Consumer>
            );
        }
    };
};

interface OutcomesWrappedProps extends OutcomesProps, InjectedProps {}
const makeMapStateToProps = () => {
    const getEntities = createGetEntities();
    const visibleOutcomesSelector = createSelector(
        (state: RootState, props: OutcomesWrappedProps) => props.fieldValues,
        (_, props: OutcomesWrappedProps) => props.formDefinition, // tslint:disable-line
        getEntities,
        (_, props: OutcomesWrappedProps) => props.entityFormHasErrors,
        (state: RootState) => state.viewConfig,
        (state: RootState) => state.valueSets,
        (values, formDefinition, entities, entityFormHasErrors, viewConfig, valueSets) => {
            const setupValues = flowablePreprocessValuesForEval(
                { ...values, entityFormHasErrors },
                formDefinition.fields,
                entities,
                {
                    dateFormat: viewConfig && viewConfig.application && viewConfig.application.dateFormat,
                },
                valueSets,
            );
            return (formDefinition.outcomes || [])
                .filter(({ name, configs }) => {
                    if (!name) {
                        return false;
                    }
                    if (name.toLowerCase() === '_save' || name.toLowerCase() === '_cancel') {
                        return false;
                    }
                    if (configs && configs.visibility) {
                        return memoizedTraverseEval(configs.visibility, {}, entities, viewConfig, valueSets)(
                            setupValues,
                        ).fold(
                            l =>
                                (() => {
                                    alertOnceOutcome('visibility', 'shown')(name, configs.visibility, l);
                                    return true;
                                })(),
                            r => r.reduce(and, true),
                        );
                    }
                    return true;
                })
                .map(outcome => {
                    if (outcome.configs && outcome.configs.editable) {
                        const editable = memoizedTraverseEval(
                            outcome.configs.editable,
                            {},
                            entities,
                            viewConfig,
                            valueSets,
                        )(setupValues).fold(
                            l =>
                                (() => {
                                    alertOnceOutcome('editable', 'clickable')(
                                        outcome.name,
                                        outcome.configs.editable,
                                        l,
                                    );
                                    return true;
                                })(),
                            r => r.reduce(and, true),
                        );

                        const evaluatedOutcome = Object.assign({}, outcome, {
                            configs: {
                                editable,
                            },
                        });
                        return evaluatedOutcome;
                    }

                    return outcome;
                });
        },
    );
    const visibleOutcomeStrSelector = createSelector(
        visibleOutcomesSelector,
        stableStringify as (arg: any) => string, // tslint:disable-line
    );
    const visibleOutcomesEqlSelector = createSelector(
        visibleOutcomeStrSelector,
        (strOutcomes: string) => {
            return JSON.parse(strOutcomes);
        },
    );
    const mapStateToProps1 = (state: RootState, props: OutcomesWrappedProps) => ({
        visibleOutcomes: visibleOutcomesEqlSelector(state, props) as ReturnType<typeof visibleOutcomesSelector>,
    });
    return mapStateToProps1;
};

interface OutcomesComponentProps
    extends OutcomesWrappedProps,
        ReturnType<ReturnType<typeof makeMapStateToProps>>,
        WithTheme {}
class OutcomesComponent extends React.Component<OutcomesComponentProps> {
    getDisplayExpression = memoize((expression: string, fieldValues: {}) => {
        return evaluateExpression(expression, fieldValues, {});
    });
    getButtonLabel = (outcome: OutcomesComponentProps['visibleOutcomes'][0]) => {
        const { name, configs } = outcome;
        if (configs && configs.display) {
            const expressionResult = this.getDisplayExpression(configs.display, this.props.fieldValues);
            if (expressionResult) {
                return `${expressionResult}`;
            }
        }
        return name;
    };
    render() {
        const { taskLoaded, taskOutcome, visibleOutcomes, createButton, theme } = this.props;
        return (
            <React.Fragment>
                {visibleOutcomes.map(outcome => {
                    const { name, configs } = outcome;
                    const buttonLabel = this.getButtonLabel(outcome);
                    return (
                        <span key={name}>
                            <asyncEventsInProgressContext.Consumer>
                                {({ eventCount }) => {
                                    return (
                                        <span
                                            style={{
                                                border:
                                                    taskLoaded && taskOutcome === name
                                                        ? `1px solid ${(theme as Theme).palette.primary.dark}`
                                                        : undefined,
                                            }}
                                        >
                                            {createButton(
                                                buttonLabel,
                                                name,
                                                configs && // I think this is meant to be editable === false?
                                                    configs.editable != null &&
                                                    configs.editable !== '' &&
                                                    !configs.editable,
                                                {
                                                    color: 'primary',
                                                    style: { marginBottom: '5px', marginTop: '5px' },
                                                },
                                            )}
                                        </span>
                                    );
                                }}
                            </asyncEventsInProgressContext.Consumer>
                            &nbsp;&nbsp;
                        </span>
                    );
                })}
            </React.Fragment>
        );
    }
}

const Outcomes: React.ComponentType<OutcomesProps> = compose(
    withFormContextFieldValues,
    connect(makeMapStateToProps),
    mapProps(({ fieldValues, ...props }) => props),
    withTheme,
)(OutcomesComponent);

export default Outcomes;
