import * as t from 'io-ts';
import { jsonDecodeString, JsonDecodeError } from 'decode-ts';
import { Either } from 'fp-ts/lib/Either';

/*
    Someone implemented this for us!
    Keeping this file around with our own copy of unit tests in case we want our own modifications.
*/
const runtimeValidatedJSONParse: <T>(
    runtimeType: t.Type<T>,
) => (jsonStr: string) => Either<JsonDecodeError, T> = jsonDecodeString;

export default runtimeValidatedJSONParse;
