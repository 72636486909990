import React, { useState } from 'react';
import {
    IconButton,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
} from '@material-ui/core';
import Refresh from '@material-ui/icons/Refresh';
import AttemptRequest from '../../../../components/AttemptRequest';
import * as config from '../../../../config';
import EditProcessVariable from './EditProcessVariable';
import { storageController } from 'storage';

const getLazyR = (processId: string) => () =>
    fetch(`${config.BACKEND_BASE_URL}api/bpm/process-instances/${processId}/variables`, {
        method: 'GET',
        credentials: 'same-origin',
        headers: new Headers({
            Authorization: `Bearer ${storageController.getToken()}`,
            Cookie: `${window.document.cookie}`,
            Accept: 'application/json',
            'Content-type': 'application/json',
        }),
    });
/*
[{"id":"overrideValues","type":"localdate"},
{"id":"initiator","type":"string","value":"super"},{"id":"field1","type":"localdate"}]
*/
interface ProcessVariableEntry {
    id: string;
    type: 'localdate' | 'string' | 'casetivity-entity' | 'serializable';
    value?: any;
    active: boolean;
    executionId: string;
    executionActivityId?: string;
}
const renderVariableValueCell = (processInstanceId, refreshData) => ({
    id,
    type,
    value,
    active,
}: ProcessVariableEntry) => {
    if (type === 'casetivity-entity') {
        if (value) {
            return JSON.stringify(value);
        }
        return '<none>';
    }
    if (type === 'serializable') {
        if (value) {
            return JSON.stringify(value);
        }
        return '<none>';
    }
    if (value instanceof Object) {
        return `uncaught value of type ${type}: ${JSON.stringify(value)}`;
    }
    if (!active) {
        return <React.Fragment>{value === true ? 'true' : value === false ? 'false' : value}</React.Fragment>;
    }
    return (
        <React.Fragment>
            {value === true ? 'true' : value === false ? 'false' : value}
            <EditProcessVariable
                onSuccess={refreshData}
                processInstanceId={processInstanceId}
                variableName={id}
                initialValue={value}
                variableType={type}
            />
        </React.Fragment>
    );
};

export const WithProcessVariableState: React.SFC<{
    initial: boolean;
    children: (args: { status: boolean; setStatus: (status: boolean) => void }) => JSX.Element | null;
}> = props => {
    const [status, setStatus] = useState(props.initial);
    return props.children({ status, setStatus });
};

const renderProcessVariableTable = (processInstanceId: string, refreshData: () => void) => (
    data: ProcessVariableEntry[],
) => {
    const renderCell = renderVariableValueCell(processInstanceId, refreshData);
    const activeData = data.filter(data => data.active === true);
    const inactiveData = data.filter(data => data.active === false);
    const initial = activeData.length === 0 ? false : true;
    return (
        <WithProcessVariableState initial={initial}>
            {({ status, setStatus }) => (
                <div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '40%', position: 'relative' }}>
                        <Button
                            variant="contained"
                            color={status ? 'primary' : 'default'}
                            onClick={() => setStatus(true)}
                        >
                            Active
                        </Button>
                        <Button
                            variant="contained"
                            color={status ? 'default' : 'primary'}
                            onClick={() => setStatus(false)}
                        >
                            Inactive
                        </Button>
                    </div>
                    {status ? (
                        <Table style={{ minWidth: '1000px' }}>
                            <caption className="casetivity-off-screen">Active Process Variables</caption>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>ExecutionId</TableCell>
                                    <TableCell>ActivityId</TableCell>
                                    <TableCell>Value</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {activeData.map(entry => (
                                    <TableRow key={entry.id}>
                                        <TableCell component="th" scope="row">
                                            {entry.id}
                                        </TableCell>
                                        <TableCell>{entry.type}</TableCell>
                                        <TableCell>
                                            {processInstanceId === entry.executionId ? '' : entry.executionId}
                                        </TableCell>
                                        <TableCell>
                                            {entry.executionActivityId ? entry.executionActivityId : ''}
                                        </TableCell>
                                        <TableCell>{renderCell(entry)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    ) : (
                        <Table style={{ minWidth: '1000px' }}>
                            <caption className="casetivity-off-screen">Inactive Process Variables</caption>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>ExecutionId</TableCell>
                                    <TableCell>Value</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {inactiveData.map(entry => (
                                    <TableRow key={entry.id}>
                                        <TableCell component="th" scope="row">
                                            {entry.id}
                                        </TableCell>
                                        <TableCell>{entry.type}</TableCell>
                                        <TableCell>
                                            {processInstanceId === entry.executionId ? '' : entry.executionId}
                                        </TableCell>
                                        <TableCell>{renderCell(entry)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </div>
            )}
        </WithProcessVariableState>
    );
};

export const fetchVariables = (processId: string) => (onSuccessCb: () => void = () => null) => (
    <AttemptRequest<ProcessVariableEntry[]>
        type="external"
        requestOnMount={true}
        renderer={({ attemptAction }) => {
            const refreshBtn = (loading: boolean) =>
                loading ? (
                    <CircularProgress style={{ marginLeft: '1em', height: 20, width: 20 }} />
                ) : (
                    <IconButton aria-label="refresh" style={{ marginLeft: 'auto' }} onClick={attemptAction}>
                        <Refresh />
                    </IconButton>
                );
            return state => (
                <span>
                    {' '}
                    <span style={{ fontWeight: 'bold', fontSize: '1.5em' }}>Process Variables</span>{' '}
                    {state._tag === 'failure' ? (
                        <div>
                            Action failed.
                            <a // eslint-disable-line
                                href="javascript:;" // eslint-disable-line
                                onClick={attemptAction}
                            >
                                try again?
                            </a>
                        </div>
                    ) : state._tag === 'pending' ? (
                        refreshBtn(true)
                    ) : state._tag === 'unsubmitted' ? (
                        refreshBtn(false)
                    ) : (
                        <span>
                            {refreshBtn(false)}
                            <br />
                            {renderProcessVariableTable(processId, attemptAction)(state.data)}
                        </span>
                    )}
                </span>
            );
        }}
        lazyRequest={getLazyR(processId)}
        onSuccess={onSuccessCb}
    />
);
