import { getDataTypeForFieldExpr } from '../getFieldProperties';
import * as widgetTypes from '../../widgetTypes';
import { VALUESET_MANY, VALUESET, REFMANYMANY } from '../../fieldDataTypes';
import ViewConfig, {
    ViewField,
    FieldViewField,
    AddressVerificationField,
    ExpressionViewField,
    EventField,
} from '../../../../../reducers/ViewConfigType';

export const isFieldViewField = (f: ViewField): f is FieldViewField =>
    f.widgetType !== widgetTypes.EXPRESSION && f.widgetType !== widgetTypes.EVENT;
export const isAddressVerificationField = (f: ViewField): f is AddressVerificationField =>
    f.widgetType === widgetTypes.ADDRESS;
export const isEventField = (f: ViewField): f is EventField => f.widgetType === widgetTypes.EVENT;
export const isExpressionViewField = (f: ViewField): f is ExpressionViewField =>
    f.widgetType === widgetTypes.EXPRESSION;

export const isNotSelectOrMultiSelect: (f: ViewField) => boolean = (f: ViewField) =>
    f.widgetType !== widgetTypes.SELECT && f.widgetType !== widgetTypes.MULTISELECT;

export const getIsEntityJoinMultiSelect: (vc: ViewConfig) => (f: ViewField) => boolean = (viewConfig: ViewConfig) => (
    f: ViewField,
) =>
    isFieldViewField(f) &&
    getDataTypeForFieldExpr(viewConfig, f.entity, f.field) !== VALUESET_MANY &&
    getDataTypeForFieldExpr(viewConfig, f.entity, f.field) !== REFMANYMANY &&
    f.widgetType === widgetTypes.MULTISELECT;

export const getIsEntityManyMany: (vc: ViewConfig) => (f: ViewField) => boolean = (viewConfig: ViewConfig) => (
    f: ViewField,
) => {
    return isFieldViewField(f) && getDataTypeForFieldExpr(viewConfig, f.entity, f.field) === REFMANYMANY;
};

export const getIsValueSetMultiSelect: (vc: ViewConfig) => (f: ViewField) => boolean = (viewConfig: ViewConfig) => f =>
    isFieldViewField(f) &&
    getDataTypeForFieldExpr(viewConfig, f.entity, f.field) === VALUESET_MANY &&
    f.widgetType === widgetTypes.MULTISELECT;

export const isSelect: (f: ViewField) => boolean = (f: ViewField) => f.widgetType === widgetTypes.SELECT;

export const getIsValueSetSelect: (vc: ViewConfig) => (f: ViewField) => boolean = (viewConfig: ViewConfig) => f =>
    isFieldViewField(f) &&
    getDataTypeForFieldExpr(viewConfig, f.entity, f.field) === VALUESET &&
    f.widgetType === widgetTypes.SELECT;
