import React, { useCallback, useState, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../src/reducers/rootReducer';
import {
    createStyles,
    Theme,
    Button,
    IconButton,
    DialogContent,
    DialogContentText,
    DialogActions,
    makeStyles,
    Typography,
    CircularProgress,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { crudDelete as crudDeleteAction } from 'sideEffect/crud/delete/actions';
import Popup from 'components/Popup';
import { fromNullable } from 'fp-ts/lib/Option';
import { allowsDelete } from 'components/generics/utils/viewConfigUtils';
import { AjaxError } from 'rxjs/ajax';
import { RemoteData, initial, failure, success, pending } from '@devexperts/remote-data-ts';
import { createGetEntities } from '../form/EntityFormContext/util/getEntities';
import { EntityBase } from 'sideEffect/services';
import ViewConfig from 'reducers/ViewConfigType';
import useViewConfig from 'util/hooks/useViewConfig';

const useStyles = makeStyles(({ palette, spacing }: Theme) =>
    createStyles({
        del: {
            color: palette.error.dark,
        },
        delNegative: {
            backgroundColor: palette.error.dark,
            color: palette.error.contrastText,
        },
    }),
);
interface RendererArgs {
    viewConfig: ViewConfig;
    resource: string;
    title: string;
    record: (EntityBase & { title?: string }) | null;
    classes: {
        del: string;
        delNegative: string;
    };
}

export interface InlineDeleteButtonProps {
    resource: string;
    id: string;
    title?: string;
    renderIcon?: (
        args: RendererArgs & { handleClick: (e: React.MouseEvent<HTMLButtonElement>) => void },
    ) => JSX.Element;
    onDeleteSuccess?: () => void;
    renderInitial?: (args: RendererArgs) => JSX.Element | string;
    renderPending?: (args: RendererArgs) => JSX.Element | string;
    renderSuccess?: (args: RendererArgs) => JSX.Element | string;
    renderFailure?: (args: RendererArgs, e: AjaxError) => JSX.Element | string;
}
export const DeleteDialogContent: React.SFC<{ title?: React.ReactNode; content?: React.ReactNode }> = ({
    title,
    content,
}) => {
    return (
        <React.Fragment>
            <Typography variant="h5">{title}</Typography>
            <br />
            <DialogContentText>{content}</DialogContentText>
        </React.Fragment>
    );
};

const defaultRenderInitial: InlineDeleteButtonProps['renderInitial'] = ({ title }) => {
    return <DeleteDialogContent title={title} content="Are you sure?" />;
};
const defaultRenderPending: InlineDeleteButtonProps['renderPending'] = ({ title }) => {
    return <DeleteDialogContent title={title} content={<CircularProgress />} />;
};
const defaultRenderFailure: InlineDeleteButtonProps['renderFailure'] = ({ classes, title }, e) => {
    const message = (() => {
        if (e.response && e.response.description) {
            return e.response.description;
        }
        return 'Failed. Please try again.';
    })();
    return <DeleteDialogContent title={title} content={<div className={classes.del}>{message}</div>} />;
};
const defaultRenderSuccess: InlineDeleteButtonProps['renderSuccess'] = ({ title }) => {
    return <DeleteDialogContent title={title} content={'Success!'} />;
};

const defaultRenderIcon: InlineDeleteButtonProps['renderIcon'] = ({ classes, handleClick }) => (
    <IconButton className={classes.del} aria-label="delete" onClick={handleClick}>
        <DeleteIcon />
    </IconButton>
);

const hasPermissionSelector = (state: RootState, resource: string) => {
    return fromNullable(state.viewConfig)
        .map(vc => vc.entities)
        .chain(fromNullable)
        .map(e => e[resource])
        .chain(fromNullable)
        .map(e => e.accessLevel)
        .chain(fromNullable)
        .map(allowsDelete)
        .getOrElse(false);
};
const InlineDeleteButton: React.SFC<InlineDeleteButtonProps> = props => {
    const {
        resource,
        id,
        onDeleteSuccess,
        renderIcon = defaultRenderIcon,
        renderFailure = defaultRenderFailure,
        renderInitial = defaultRenderInitial,
        renderSuccess = defaultRenderSuccess,
        renderPending = defaultRenderPending,
    } = props;
    const attemptedOnce = useRef(false);
    const classes = useStyles(props);
    const viewConfig = useViewConfig();
    const dispatch = useDispatch();
    const getEntities = useMemo(createGetEntities, []);
    const record: (EntityBase & { title?: string }) | null = useSelector((state: RootState) => {
        return fromNullable(getEntities(state))
            .mapNullable(e => e[resource])
            .mapNullable(re => re[id])
            .getOrElse(null);
    });
    const hasPermission = useSelector((state: RootState) => hasPermissionSelector(state, resource));
    const [state, setState] = useState<RemoteData<AjaxError, undefined>>(initial);

    const deleteCb = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            if (e) {
                e.stopPropagation();
                e.preventDefault();
            }
            setState(pending);
            dispatch(
                crudDeleteAction({
                    resource,
                    id,
                    cb: () => {
                        setState(success(undefined));
                        if (onDeleteSuccess) {
                            onDeleteSuccess();
                        }
                    },
                    errorsCbs: {
                        '*': e => {
                            attemptedOnce.current = true;
                            setState(failure(e));
                        },
                    },
                }),
            );
        },
        [resource, id, onDeleteSuccess, setState, dispatch],
    );
    const rendererArgs: RendererArgs = {
        title: props.title || `Delete ${viewConfig.entities[resource].displayName}${record ? `: ${record.title}` : ''}`,
        viewConfig,
        resource,
        record,
        classes,
    };
    if (!hasPermission) {
        return null;
    }
    return (
        <Popup
            onClose={() => {
                setState(initial);
                attemptedOnce.current = false;
            }}
            renderDialogContent={({ closeDialog }) => {
                return (
                    <React.Fragment>
                        <DialogContent>
                            {state.fold(
                                renderInitial(rendererArgs),
                                renderPending(rendererArgs),
                                e => renderFailure(rendererArgs, e),
                                () => renderSuccess(rendererArgs),
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={e => {
                                    if (e) {
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }
                                    closeDialog();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                className={classes.delNegative}
                                onClick={deleteCb}
                                disabled={state.isPending()}
                            >
                                {attemptedOnce.current ? 'Retry' : 'Continue'}
                            </Button>
                        </DialogActions>
                    </React.Fragment>
                );
            }}
            renderToggler={({ openDialog }) => {
                const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
                    if (e) {
                        e.stopPropagation();
                        e.preventDefault();
                    }
                    openDialog()();
                };
                return renderIcon({ ...rendererArgs, handleClick });
            }}
        />
    );
};
export default InlineDeleteButton;
