import { uniq } from 'fp-ts/lib/Array';
import { setoidString } from 'fp-ts/lib/Setoid';
import {
    isFieldViewField,
    getAdjustedFieldSource,
    getIsEntityJoinMultiSelect,
} from '../../../utils/viewConfigUtils/index';
import ViewConfig, { View } from '../../../../../reducers/ViewConfigType';
import decodeAddressConfig from '../../../../../fieldFactory/input/components/Address/util/decodeAddressConfig';

/*
    For the Show form we may have to infer fields from AddressFields + configs and FileUpload fields
    REUSE WHAT WE CAN BUT CREATE A SEPERATE METHOD or a more generic one
*/
const getAllFieldsFromView = (view: View) => [
    ...Object.values(view.fields),
    ...Object.values(view.tabs || {}).flatMap(tab => Object.values(tab.fields)),
];

export const EXTERNALGISID = 'gisIdentifier';

export const getAdhocFieldsForView = (view: View): string[] => {
    const fields = getAllFieldsFromView(view);
    const fileInputFields = fields.flatMap(f =>
        f.widgetType === 'FILEUPLOAD' ? [`${f.field}FileName`, `${f.field}ContentType`] : [],
    );
    const addressFields = fields.filter(f => f.widgetType === 'ADDRESS');
    const addressConfFields: string[] = addressFields.flatMap(af => {
        const addrConf = af.config ? decodeAddressConfig(af.config, 'RETURN_NULL') : null;
        if (addrConf) {
            return Object.values(addrConf.fieldMapping);
        }
        return [];
    });
    const res = [...fileInputFields, ...addressConfFields];
    if (addressConfFields.length > 0) {
        res.push(EXTERNALGISID);
    }
    return res;
};

const getAllFieldsExpected = (
    viewConfig: ViewConfig,
    viewName: string,
    registeredFields: {},
    getAdhocFields?: (view: View) => string[],
): string[] => {
    const view = viewConfig.views[viewName];
    const getSource = getAdjustedFieldSource(viewConfig)(view);
    const fieldsExpectedInViewConfig = Object.values(getAllFieldsFromView(viewConfig.views[viewName]))
        .filter(isFieldViewField)
        .filter(f => !getIsEntityJoinMultiSelect(viewConfig)(f))
        .map(f => getSource(f));
    const adhocFields = getAdhocFields ? getAdhocFields(view) : [];
    return uniq(setoidString)([...fieldsExpectedInViewConfig, ...adhocFields, ...Object.keys(registeredFields || {})]);
};

export default getAllFieldsExpected;
