import { RootState } from 'reducers/rootReducer';
import { useSelector } from 'react-redux';
import { fromNullable } from 'fp-ts/lib/Option';

const useUserIsSuper = () => {
    return useSelector((state: RootState) => {
        return fromNullable(state.viewConfig)
            .mapNullable(vc => vc.user)
            .mapNullable(u => u.roles)
            .map(roles => roles.includes('ROLE_SUPER'))
            .getOrElse(false);
    });
};
export default useUserIsSuper;
