import React from 'react';
import PrintTemplateNameLookupField from 'printTemplate/component/PrintTemplateField';
import HtmlDisplay from '../display/components/HtmlDisplay';
import { Tooltip } from '@material-ui/core';
import get from 'lodash/get';
import { EntityExpressionField } from '../translation/fromEntity/types/index';
import ServerTemplatedPrintTemplateField from 'printTemplate/serverTemplating/component/ServerTemplatedPrintTemplateField';
import { printTemplatePrefix, printTemplatePdfPrefix } from 'fieldFactory/util/expressionConstants';
import WithStringEvaluatedInFormContext from 'fieldFactory/popovers/PopoverRefInput/EvaluateStringTemplateInFormContext';
import { getSubExpressionsOfFilterTemplate } from 'viewConfigCalculations/filterExpressions/epic';
import getFieldsRequiredForExpression from 'clients/utils/getFieldsRequiredForExpression';

const tooltipTextPre = 'tooltipText[';
const getTooltipText = (htmlConfig?: string): string | null => {
    if (
        htmlConfig &&
        typeof htmlConfig === 'string' &&
        htmlConfig.toLowerCase().indexOf(tooltipTextPre.toLowerCase()) !== -1
    ) {
        const textStartIndex = htmlConfig.toLowerCase().indexOf(tooltipTextPre.toLowerCase()) + tooltipTextPre.length;
        const textEndIndex = htmlConfig.indexOf(']', textStartIndex);
        const tooltipText = htmlConfig.slice(textStartIndex, textEndIndex);
        return tooltipText;
    }
    return null;
};

export default (fieldDefinition: EntityExpressionField, props, from: 'Entity' | 'Flowable') => {
    if (fieldDefinition.htmlConfig && typeof fieldDefinition.htmlConfig === 'string') {
        if (fieldDefinition.htmlConfig.startsWith(printTemplatePrefix)) {
            return (
                <PrintTemplateNameLookupField
                    {...props}
                    printTemplateName={fieldDefinition.htmlConfig.slice(printTemplatePrefix.length)}
                />
            );
        }
        if (fieldDefinition.htmlConfig.startsWith(printTemplatePdfPrefix)) {
            return (
                <ServerTemplatedPrintTemplateField
                    {...props}
                    printTemplateName={fieldDefinition.htmlConfig.slice(printTemplatePdfPrefix.length)}
                />
            );
        }
    }
    const getFromRecord = () => {
        if (props.record && props.source) {
            return get(props.record, props.source);
        }
        return undefined;
    };
    const getFromInput = () => {
        if (props.input && props.input.value) {
            return props.input.value;
        }
        return undefined;
    };
    const propsForGrid = {
        id: fieldDefinition.name,
        addField: true,
        addLabel: false,
    };
    const childFn = (evaluatedTemplate: string) => <HtmlDisplay {...propsForGrid} html={evaluatedTemplate} />;
    const templateString = fieldDefinition.htmlConfig || getFromRecord() || getFromInput();

    if (!templateString) {
        const DivComponent = props => <div />;
        return <DivComponent {...propsForGrid} />;
    }
    const expansions = getSubExpressionsOfFilterTemplate(templateString).flatMap(getFieldsRequiredForExpression);
    const HtmlDisplayComponent = (props: { id?: string; addField?: boolean; addLabel?: boolean }) =>
        from === 'Flowable' ? (
            <WithStringEvaluatedInFormContext from={from} templateString={templateString}>
                {childFn}
            </WithStringEvaluatedInFormContext>
        ) : (
            <WithStringEvaluatedInFormContext
                from={from}
                context={{ type: 'adhoc', fieldsRequired: expansions, valuesetFieldsRequired: {} }}
                templateString={templateString}
            >
                {childFn}
            </WithStringEvaluatedInFormContext>
        );

    const tooltipText = getTooltipText(fieldDefinition.htmlConfig);
    if (tooltipText) {
        return (
            <Tooltip {...propsForGrid} {...props} title={tooltipText} placement="top-end">
                <span>
                    <HtmlDisplayComponent {...propsForGrid} />
                </span>
            </Tooltip>
        );
    }
    return <HtmlDisplayComponent {...props} {...propsForGrid} />;
};
