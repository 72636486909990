import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { RootState } from 'reducers/rootReducer';
import { connect } from 'react-redux';
import { getFooterTextSelector } from 'util/applicationConfig';
import HtmlDisplayComponent from 'fieldFactory/display/components/HtmlDisplay';

const useStyles = makeStyles(theme => ({
    footer: {
        position: 'fixed',
        left: 0,
        bottom: 0,
        width: '100%',
        height: '35px',
        backgroundColor: theme.palette.primary.main,
        zIndex: 1,
    },
    applicationHeader: {
        color: theme.palette.getContrastText(theme.palette.primary.main),
    },
}));

const mapStateToProps = (state: RootState) => ({
    footerText: getFooterTextSelector(state),
});

interface footerProps {
    footerText: string;
}

const AppFooter: React.SFC<footerProps> = props => {
    const classes = useStyles(props);
    return props.footerText ? (
        <footer className={classes.footer}>
            <Container maxWidth="sm">
                <Typography align="center" className={classes.applicationHeader}>
                    <HtmlDisplayComponent
                        noMargin={true}
                        elStyle={{
                            padding: '0px 16px',
                        }}
                        html={props.footerText}
                    />
                </Typography>
            </Container>
        </footer>
    ) : null;
};

export default connect(mapStateToProps)(AppFooter);
