import React from 'react';
import { Route } from 'react-router-dom';
import { UploadFile } from '../components/custom';
import UploadDeployment from '../bpm/components/UploadDeployment';
import BulkUpload from '../components/custom/BulkUpload';
import ProcessView from '../bpm/components/ProcessDetail';
import ReportPage from '../report/components/Report';
import SsgAppBarMobile from '../components/SsgAppBarMobile';
// import ProcessStartPage from '../components/custom/StartProcess';
import AdhocTask from '../bpm/components/TaskDetail/Task';
import ValidateViewConfigEntities from '../viewConfigValidation/ValidateViewConfigEntities';
import ValidateViewConfigViews from '../viewConfigValidation/ValidateViewConfigViews';
import ViewConfigManager from '../components/viewConfigManager';
import ProcessList from '../bpm2/ProcessList';
import TasksList, { ContainedTaskList } from '../bpm2/TaskList';
import RoleShow from '../components/custom/RoleEditor';
import DashCreator from '../components/custom/DashboardCreator/DashboardCreator';
import UserLogoutRedirectRoute from '../components/custom/UserLogoutRedirectRoute';
import MishasTest from 'temp_testAjaxCookies/TestComponents';
import PrintTemplatePlayground from 'printTemplate/playground/LazyPlayground';
import AdminPasswordReset from 'auth/password-reset/components/AdminReset';
import TextTemplatePage from 'templatePage/components/index';
import UploadPage from 'bpm/upload-definitions/components';
import ProcessStartPage from 'bpm/start-form/components';
import { Helmet } from 'react-helmet';
import { parse } from 'query-string';
import StartProcessRoute from 'bpm/start-process-route';
import DeferredSpinner from 'components/DeferredSpinner';
import { useSelector } from 'react-redux';

export default [
    <Route
        path="/start-process/:processDefinitionKey"
        component={({ match, location }) => (
            <StartProcessRoute processDefinitionKey={match.params.processDefinitionKey} searchStr={location.search} />
        )}
    />,
    <Route
        path="/process-definition/:processKey/start-form"
        component={({ match, location }) => (
            <ProcessStartPage businessKey={match.params.processKey} queryParams={parse(location.search)} />
        )}
    />,
    <Route
        path="/pages/:textTemplateName"
        component={({ match }) => (
            <React.Fragment>
                <SsgAppBarMobile title={null} />
                <TextTemplatePage textTemplateName={match.params.textTemplateName} />
            </React.Fragment>
        )}
    />,
    <Route path="/tasks/:tkey" component={({ match }) => <AdhocTask taskId={match.params.tkey} />} />,
    <Route
        path="/processes/:pkey/tasks/:tkey/start/"
        component={({ match }) => (
            <ProcessView title={match.params.tkey} process={match.params.pkey} task={match.params.tkey} />
        )}
    />,
    <Route
        path="/processes/:pkey"
        component={({ match }) => <ProcessView title={match.params.pkey} process={match.params.pkey} />}
    />,
    <Route path="/processes" component={ProcessList} />,
    <Route path="/testCookies" component={MishasTest} />,
    <Route path="/tasks" component={TasksList} />,
    <Route path="/controlledTasks" component={props => <ContainedTaskList {...props} processId={2474} />} />,
    <Route
        path="/run-report/:key/"
        component={props => (
            <div>
                <SsgAppBarMobile title={props.match.params.key} />
                <ReportPage key={props.match.params.key} reportName={props.match.params.key} />
            </div>
        )}
    />,
    <Route path="/admin/update-definitions" component={UploadPage} />,
    <Route path="/admin/upload-deployment/" component={UploadDeployment} />,
    <Route
        path="/admin/upload-view-config/"
        component={props => (
            <UploadFile
                title="Import View Config"
                dropMessage="View Config File Here"
                resourceUrl="view-config/import"
            />
        )}
    />,
    <Route path="/admin/manage-view-config/" component={props => <ViewConfigManager />} />,
    <Route
        path="/admin/analyze-addresses/"
        component={props => (
            <React.Fragment>
                <Helmet>
                    <title>Analyze Addresses</title>
                </Helmet>
                <UploadFile
                    title="Upload Addresses to Analyze"
                    dropMessage="Address File Here"
                    resourceUrl="util/analyze-addresses"
                />
            </React.Fragment>
        )}
    />,
    <Route
        path="/import/demo-data/"
        component={props => (
            <React.Fragment>
                <Helmet>
                    <title>Import Demo Data</title>
                </Helmet>
                <UploadFile
                    title="Import Demo Data"
                    dropMessage="Entity Excel sheet here"
                    resourceUrl="demo-data/import"
                />
            </React.Fragment>
        )}
    />,
    <Route path="/reset-user-password" component={props => <AdminPasswordReset />} />,
    <Route
        path="/import/demo-links/"
        component={props => (
            <React.Fragment>
                <Helmet>
                    <title>Import Demo Data Linkage</title>
                </Helmet>
                <UploadFile
                    title="Import Demo Data linkage file"
                    dropMessage="Entity Excel sheet here"
                    resourceUrl="demo-data/import-links"
                />
            </React.Fragment>
        )}
    />,
    <Route
        path="/import/:entity/"
        component={props => (
            <div>
                <BulkUpload entityType={props.match.params.entity} />
            </div>
        )}
    />,
    <Route path="/admin/validation/entities" component={props => <ValidateViewConfigEntities />} />,
    <Route path="/admin/validation/views" component={props => <ValidateViewConfigViews />} />,
    <Route path="/role-editor" component={props => <RoleShow {...props} />} />,
    <Route path="/dash-editor" component={props => <DashCreator {...props} />} />,
    <Route path="/ptp" component={PrintTemplatePlayground} />,
    <Route
        path="/_temp"
        component={() => {
            const viewConfigIsLoading = useSelector(state => state.viewConfigIsLoading);
            if (!viewConfigIsLoading) {
                return null;
            }
            return (
                <div style={{ height: '600px', width: '100%' }}>
                    <div style={{ textAlign: 'center', width: 200, height: 200, margin: 'auto' }}>
                        <div style={{ height: '50px' }} />
                        <h2>Please wait...</h2>
                        <div style={{ height: '50px' }} />
                        <DeferredSpinner />
                    </div>
                </div>
            );
        }}
    />,
    <Route path="/logout-redirect" component={props => <UserLogoutRedirectRoute {...props} />} />,
];
