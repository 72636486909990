import ViewConfig from '../reducers/ViewConfigType';

class DefaultViews {
    private static defaultViewNames: { [viewName: string]: true } | null = null;
    private static vcReference: ViewConfig | null = null;

    static isDefaultView(viewConfig: ViewConfig, viewName: string) {
        this.initializeIfNecessary(viewConfig);
        return this.defaultViewNames && this.defaultViewNames[viewName];
    }
    private static initializeIfNecessary(viewConfig: ViewConfig): void {
        if (this.vcReference !== viewConfig) {
            this.reset(viewConfig);
        }
    }
    private static reset(viewConfig: ViewConfig): void {
        const defaultViewNameList: string[] = Array<{ name: string } | undefined>()
            .concat(
                ...Object.values(viewConfig.entities).map(e => (e.defaultViews ? Object.values(e.defaultViews) : [])),
            )
            .map(o => o && o.name)
            .filter(o => o) as string[];

        let defaultViewNames = {};
        defaultViewNameList.forEach(n => (defaultViewNames[n] = true));
        this.defaultViewNames = defaultViewNames;
        this.vcReference = viewConfig;
    }
}

export { DefaultViews };
