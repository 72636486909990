import * as t from 'io-ts';
import getErrorFromConfig from '../getErrorFromConfig';

const primativeDefaultValueConfig = t.partial({
    defaultValue: t.any,
});
type PrimativeDefaultValueConfig = t.TypeOf<typeof primativeDefaultValueConfig>;
const primativeDefaultValueExample: PrimativeDefaultValueConfig = {
    defaultValue: 'foo',
};
const primativeDefaultValueValidation = getErrorFromConfig(
    primativeDefaultValueConfig,
    `Invalid configuration for default value. Example: ${JSON.stringify(primativeDefaultValueExample)}`,
    `invalid JSON: example: ${JSON.stringify(primativeDefaultValueExample)}`,
);

export default primativeDefaultValueValidation;
