import { put, call, select } from 'redux-saga/effects';
import {
    taskFormEventType as taskFormEvent,
    TaskFormSubmitPayload,
    TaskFormSavePayload,
} from '../actions/taskFormEvent';
import sagaFactory, { configType, HttpVerb } from '../../sagas/util/sagaFactory';
import { RootState } from 'reducers/rootReducer';
import { fromNullable } from 'fp-ts/lib/Option';

interface ErrorDetails {
    error?: string | { status?: number };
    body?: string;
}

export const getFailureHandler = (
    getFailureAction: (
        payload: TaskFormSubmitPayload | TaskFormSavePayload,
        errorDetails: ErrorDetails,
    ) => { type: string; payload: any }, // tslint:disable-line
) =>
    function*(payload: TaskFormSubmitPayload, errorDetails: ErrorDetails) {
        const errTxt = (errorDetails.error && errorDetails.error.toString()) || errorDetails.body;
        try {
            if (errorDetails && typeof errorDetails.error === 'object') {
                const taskName = yield select((state: RootState) =>
                    fromNullable(state.bpm.tasks.byId[payload.taskId])
                        .map(t => t.name)
                        .chain(fromNullable)
                        .map(name => `Task "${name}"`)
                        .getOrElse('Task'),
                );
                if (errorDetails.error.status === 409) {
                    yield call(alert, `${taskName} is already claimed by someone else.`);
                } else if (errorDetails.error.status === 410) {
                    yield call(alert, `${taskName} is already completed.`);
                }
            } else {
                yield call(prompt, 'Copy to clipboard: Ctrl+C, Enter', errTxt);
            }
        } catch (e) {
            console.error(e); // tslint:disable-line
        } finally {
            yield put(getFailureAction(payload, errorDetails));
        }
    };

const config: configType = {
    actionType: taskFormEvent.submit,
    verb: HttpVerb.POST,
    url: ({ taskId }: TaskFormSubmitPayload) => `api/bpm/task-forms/${taskId}/complete-form`,
    body: ({ requestBody }: TaskFormSubmitPayload) => requestBody,
    success: (payload: TaskFormSubmitPayload, responseBody: { nextTaskId: string }) => {
        return [
            {
                type: taskFormEvent.submitSuccess,
                payload,
            },
        ];
    },
    failure: getFailureHandler((payload, errorDetails) => {
        return { type: taskFormEvent.submitFailure, payload, errorDetails };
    }),
    callCb: (payload, responseBody, cb) => {
        cb(responseBody);
    },
    callErrorCb: cb => {
        cb();
    },
};

const saga = sagaFactory(config);
export default saga;
